import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { CaldaiaService } from '../services/caldaia.service';
import { Trascodifica } from '../../../../../classes/trascodifica';
import { BrandService } from '../../../../../../services/brand/brand.service';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';
import { DtoStufe } from '../../../../../classes/stufe';
import { CaldaiaDto } from '../../../../../classes/caldaie/caldaia-dto';

@Component({
  selector: 'app-tipi-caldaie',
  templateUrl: './tipi-caldaie.component.html',
  styleUrls: ['./tipi-caldaie.component.scss']
})
export class TipiCaldaieComponent implements OnInit {
  caldaiaResponse: any;
  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  caldaie: [];
  MODCALDECOW: Trascodifica[];
  MODCALDECOWD: Trascodifica[];
  MODCALDECOWAU: Trascodifica[];
  MODCALDECOWWALL: Trascodifica[];
  MODCALDHOTWIND: Trascodifica[];
  MODCALDCAMAP: Trascodifica[];
  constructor(
      private caldaiaService: CaldaiaService,
      private brandService: BrandService,
      private formController: FormControllerService,
  ) {
  }

  ngOnInit(): void {
    this.MODCALDECOW = undefined;
    this.form.sections.caldaie = [];
    this.getCaldaie();
    // this.getTranscodifiche();
    
  }

  getCaldaie(): void {
    this.caldaiaService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
      this.caldaiaResponse = res;
      this.getMODCALDECOW();
    });
  }
  getTranscodifiche(): void {
    this.brandService.getDataByProduct(Number(localStorage.getItem('id_product'))).subscribe(res => {
      this.trascodifiche = res;
      this.getMODCALDECOW();
    });
  }

  getMODCALDECOW(): void {
    this.MODCALDECOW = undefined;
    this.formController.getTrascodificaByCodicPromise('MODCALDECOW', this.trascodifiche).then(data => {
      data.forEach(s => {
        s.checked = false;
      });
      this.managestufe(data, 'MODCALDECOW');
      this.MODCALDECOW = data;
      this.getMODCALDECOWD();
    });
  }

  getMODCALDECOWD(): void {
    this.MODCALDECOWD = undefined;
    this.formController.getTrascodificaByCodicPromise('MODCALDECOWD', this.trascodifiche).then(data => {
      data.forEach(s => {
        s.checked = false;
      });
      this.managestufe(data, 'MODCALDECOWD');
      this.MODCALDECOWD = data;
      this.getMODCALDECOWAU();
    });
  }

  getMODCALDECOWAU(): void {
    this.MODCALDECOWAU = undefined;
    this.formController.getTrascodificaByCodicPromise('MODCALDECOWAU', this.trascodifiche).then(data => {
      data.forEach(s => {
        s.checked = false;
      });
      this.managestufe(data, 'MODCALDECOWAU');
      this.MODCALDECOWAU = data;
      this.getMODCALDECOWWALL();
    });
  }

  getMODCALDECOWWALL(): void {
    this.MODCALDECOWWALL = undefined;
    this.formController.getTrascodificaByCodicPromise('MODCALDECOWWALL', this.trascodifiche).then(data => {
      data.forEach(s => {
        s.checked = false;
      });
      this.managestufe(data, 'MODCALDECOWWALL');
      this.MODCALDECOWWALL = data;
      this.getMODCALDHOTWIND();
    });
  }

  getMODCALDHOTWIND(): void {
    this.MODCALDHOTWIND = undefined;
    this.formController.getTrascodificaByCodicPromise('MODCALDHOTWIND', this.trascodifiche).then(data => {
      data.forEach(s => {
        s.checked = false;
      });
      this.managestufe(data, 'MODCALDHOTWIND');
      this.MODCALDHOTWIND = data;
      this.getMODCALDCAMAP();
    });
  }

  getMODCALDCAMAP(): void {
    this.MODCALDCAMAP = undefined;
    this.formController.getTrascodificaByCodicPromise('MODCALDCAMAP', this.trascodifiche).then(data => {
      data.forEach(s => {
        s.checked = false;
      });
      this.managestufe(data, 'MODCALDCAMAP');
      this.MODCALDCAMAP = data;
    });
  }

  managestufe(element, mod): void{
    element.forEach(s => {
      s.checked = false;
      this.form.sections.caldaie.push(this.mapCaldaiaToForm(s, mod, new CaldaiaDto()));
      this.checkResponse();
    });
  }

  checkResponse(): void {
    if (this.caldaiaResponse.length > 0) {
      this.caldaiaResponse.forEach(st => {
        if (st.modcaldacqhwId) {
          const ind = this.form.sections.caldaie.findIndex(stOr => stOr.modcaldacqhwId === st.modcaldacqhwId);
          if (ind !== -1) {
            this.form.sections.caldaie[ind].id = st.id;
            this.form.sections.caldaie[ind].checked = true;
            if (st.coloreId){
              this.form.sections.caldaie[ind].coloreId = st.coloreId;
            }
            if (st.tipologiaId){
              this.form.sections.caldaie[ind].tipologiaId = st.tipologiaId;
            }
          }
        }
        if (st.modcaldcondcamId) {
          const ind = this.form.sections.caldaie.findIndex(stOr => stOr.modcaldcondcamId === st.modcaldcondcamId);
          if (ind !== -1) {
            this.form.sections.caldaie[ind].id = st.id;
            this.form.sections.caldaie[ind].checked = true;
            if (st.coloreId){
              this.form.sections.caldaie[ind].coloreId = st.coloreId;
            }
            if (st.tipologiaId){
              this.form.sections.caldaie[ind].tipologiaId = st.tipologiaId;
            }
          }
        }
        if (st.modcaldcondecowId) {
          const ind = this.form.sections.caldaie.findIndex(stOr => stOr.modcaldcondecowId === st.modcaldcondecowId);
          if (ind !== -1) {
            this.form.sections.caldaie[ind].id = st.id;
            this.form.sections.caldaie[ind].checked = true;
            if (st.coloreId){
              this.form.sections.caldaie[ind].coloreId = st.coloreId;
            }
            if (st.tipologiaId){
              this.form.sections.caldaie[ind].tipologiaId = st.tipologiaId;
            }
          }
        }
        if (st.modcaldcondecowauId) {
          const ind = this.form.sections.caldaie.findIndex(stOr => stOr.modcaldcondecowauId === st.modcaldcondecowauId);
          if (ind !== -1) {
            this.form.sections.caldaie[ind].id = st.id;
            this.form.sections.caldaie[ind].checked = true;
            if (st.coloreId){
              this.form.sections.caldaie[ind].coloreId = st.coloreId;
            }
            if (st.tipologiaId){
              this.form.sections.caldaie[ind].tipologiaId = st.tipologiaId;
            }
          }
        }
        if (st.modcaldcondecowdId) {
          const ind = this.form.sections.caldaie.findIndex(stOr => stOr.modcaldcondecowdId === st.modcaldcondecowdId);
          if (ind !== -1) {
            this.form.sections.caldaie[ind].id = st.id;
            this.form.sections.caldaie[ind].checked = true;
            if (st.coloreId){
              this.form.sections.caldaie[ind].coloreId = st.coloreId;
            }
            if (st.tipologiaId){
              this.form.sections.caldaie[ind].tipologiaId = st.tipologiaId;
            }
          }
        }
        if (st.modcaldcondecowallId) {
          const ind = this.form.sections.caldaie.findIndex(stOr => stOr.modcaldcondecowallId === st.modcaldcondecowallId);
          if (ind !== -1) {
            this.form.sections.caldaie[ind].id = st.id;
            this.form.sections.caldaie[ind].checked = true;
            if (st.coloreId){
              this.form.sections.caldaie[ind].coloreId = st.coloreId;
            }
            if (st.tipologiaId){
              this.form.sections.caldaie[ind].tipologiaId = st.tipologiaId;
            }
          }
        }
      });
    }
  }

  mapCaldaiaToForm(dto: CaldaiaDto, mod , form: CaldaiaDto): CaldaiaDto{
    if (dto !== undefined) {
      form.checked = dto.checked || false;
      form.ordineId = this.form.idOrder;
      form.coloreId = dto.coloreId || undefined;
      // form.coloreDescrizione = this.returnColDescr(dto.coloreId, null) || undefined;
      form.id = undefined;
      if (mod === 'MODCALDECOW') {
        form.modcaldcondecowId = dto.id;
        // form.modcaldpelletDescrizione = this.returnModDescr(dto.id);
      } else {
        form.modcaldcondecowId = undefined;
        // form.modcaldpelletDescrizione = undefined;
      }
      if (mod === 'MODCALDECOWD') {
        form.modcaldcondecowdId = dto.id;
        // form.modstufepelletDescrizione = this.returnModDescr(dto.id);
      } else {
        form.modcaldcondecowdId = undefined;
        // form.modstufepelletDescrizione = undefined;
      }
      if (mod === 'MODCALDECOWAU') {
        form.modcaldcondecowauId = dto.id;
        // form.modtermopelletDescrizione = this.returnModDescr(dto.id);
      } else {
        form.modcaldcondecowauId = undefined;
        // form.modtermopelletDescrizione = undefined;
      }
      if (mod === 'MODCALDCAMAP') {
        form.modcaldcondcamId = dto.id;
        // form.modtermopelletDescrizione = this.returnModDescr(dto.id);
      } else {
        form.modcaldcondcamId = undefined;
        // form.modtermopelletDescrizione = undefined;
      }
      if (mod === 'MODCALDECOWWALL') {
        form.modcaldcondecowallId = dto.id;
        // form.modtermopelletDescrizione = this.returnModDescr(dto.id);
      } else {
        form.modcaldcondecowallId = undefined;
        // form.modtermopelletDescrizione = undefined;
      }
      if (mod === 'MODCALDHOTWIND') {
        form.modcaldacqhwId = dto.id;
        // form.modtermopelletDescrizione = this.returnModDescr(dto.id);
      } else {
        form.modcaldacqhwId = undefined;
        // form.modtermopelletDescrizione = undefined;
      }
      // form.volriscDescrizione = this.returnVolDesc(dto.id);
      form.tipologiaId = undefined;
    }
    return form;
  }

  returnTypes(id): any {
    return this.formController.returnTypeCald(id, this.trascodifiche);
  }

  returnTypeDescr(event, ind): string {
    const index = this.trascodifiche.findIndex(r => r.codice === 'TIPOALIMCALD' && r.id === event );
    if (index !== -1) {
      if (ind !== undefined) {
        this.form.sections.caldaie[ind].descrizioneTipo = this.trascodifiche[index].descrizione;
        if (this.form.sections.caldaie[ind].coloreId) {
          delete this.form.sections.caldaie[ind].coloreId;
          delete this.form.sections.caldaie[ind].descrizioneColore;
        }
      }
      return this.trascodifiche[index].descrizione;
    } else {
      return undefined;
    }
  }

  returnColorDescr(event, ind): string {
    const index = this.trascodifiche.findIndex(r => r.codice === 'COLORECALD' && r.id === event );
    if (index !== -1) {
      if (ind !== undefined) {
        this.form.sections.caldaie[ind].descrizioneColore = this.trascodifiche[index].descrizione;
      }
      return this.trascodifiche[index].descrizione;
    } else {
      return undefined;
    }
  }

  returnColors(id): any {
    return this.formController.returnColorsCald(id, this.trascodifiche);
  }

  removeType(event, i): void{
    if (!event) {
      delete this.form.sections.caldaie[i].tipologiaId;
      delete this.form.sections.caldaie[i].descrizioneTipo;
      delete this.form.sections.caldaie[i].coloreId;
      delete this.form.sections.caldaie[i].descrizioneColore;
    }
  }

}
