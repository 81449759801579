<div class="pt-5 text-center mb-4">
</div>

<div class="row buttonRow mb-3">
    <div class="col-6">
        <button *ngIf="currentStep>0" [disabled]="currentStep == totalSteps.length && openSign" class="btnMainColor btnColor" mat-flat-button (click)="setStep(currentStep-1)"
            mat-button>
            < Indietro</button>
    </div>
    <div class="col-6 text-right" *ngIf="currentStep < totalSteps.length">
        <button               
            [disabled]="generatingPdf && isLoaded || stepController()"
            class="btnMainColor btnColor" mat-flat-button (click)="saveModule()">Avanti ></button>
        <div>
            <div class="smallFont text-danger"
                *ngIf="(!validAnagrafica && currentStep === 0) || (currentStep === 4 && !this.formController.validPagamento(this.form, true))"> 
                Compila tutti i campi obbligatori
            </div>
            <div class="smallFont text-danger" *ngIf="(currentStep === 5 && !validPrivacy())">
                &egrave; necessario accettare tutte le informative per procedere alla firma
            </div>
        </div>
    </div>
    <div class="col-6" *ngIf="currentStep < totalSteps.length"></div>
    <div class="col-6 text-right">
    </div>
</div>

<div class="formContainer">
    <div class="text-center mb-4">
        <h4>
            <span class="imgModulo">
                <img alt="" class="" src="assets/img/loghi/zucchetti-logo_1.jpg"/>
            </span>
            Modulo d'ordine poltrone Zucchetti
        </h4>
    </div>
    <hr />
    <div class="moduleContainer mb-3">
            <div class="" *ngIf="currentStep == 0">
                <app-anagrafica-sh [brand]="'ZUCCHETTI'" [validAnagrafica]="validAnagrafica" [form]="form">
                </app-anagrafica-sh>
            </div>

            <div class="" *ngIf="currentStep == 1">
                <poltrone-promo [form]="form" [trascodifiche]="trascodifiche"></poltrone-promo>
            </div>

            <div class="" *ngIf="currentStep == 2 && this.form.isLoading">
                <prodotti-poltrone [form]="form" [trascodifiche]="trascodifiche"></prodotti-poltrone>
            </div>

            <div class="" *ngIf="currentStep == 3">
                <div class="text-center mb-3">
                </div>
                <div>
					<app-omaggio-sh [form]="form"  [trascodifiche]="trascodifiche" [brand]="'zucchetti'" [prodotto]="'poltrone'"></app-omaggio-sh>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 4">
                <div class="text-center mb-3">
                </div>
                <div>
                    <app-pay-one [form]="form" [brand]="'zucchetti'" [prodotto]="'poltrone'"
                        [trascodifiche]="trascodifiche">
                    </app-pay-one>                
                </div>
            </div>
            
			<div class="lastStep" *ngIf="currentStep == 5">
				<app-privacy [form]="form" [brand]="'zucchetti'"></app-privacy>
			</div>

            <div *ngIf="currentStep == 6">
                <app-contacts-check [validAnagrafica]="validAnagrafica" [form]="form"></app-contacts-check>
            </div>

            <div class="" *ngIf="currentStep == 7 && isFinanziamentoPayment">
                <app-finanziamento [form]="form" [listFinanziarie]="listFinanziarie" [pdfDetails]="finanziamentoPdfHeader" [productColor]="'#0a2e5e'"></app-finanziamento>
            </div>

            <ng-container *ngIf="currentStep == 7 && !isFinanziamentoPayment; else nextStepWithFinance">
                <ng-container *ngTemplateOutlet="signatureAndPrint"></ng-container>
            </ng-container>
            
            <ng-template #nextStepWithFinance>
                <ng-container *ngIf="currentStep == 8">
                    <ng-container *ngTemplateOutlet="signatureAndPrint"></ng-container>
                </ng-container>
            </ng-template>
            
            <ng-template #signatureAndPrint>
                <div class="lastStep">
                    <div class="text-center">
                        <div class="digitalSignatureButtons" *ngIf="openSign" style="height: 124px">
                            <div class="signButton" (click)="edit()">
                                <span class="material-icons d-block">edit</span>
                                <span class="d-block mb-2">Modifica Contratto</span>
                            </div>
                        </div>
                        <div class="" *ngIf="form.idOrder">                        
                            <app-print-poltrone [anagrafica]="anagrafica" [idOrder]="form.idOrder"
                                [trascodifiche]="trascodifiche" [form]="form" (reload)="reload($event)">
                            </app-print-poltrone>                   
                        </div>
                    </div>
                </div>
            </ng-template>
    </div>
</div>

<div class="row buttonRow">
<div class="col-6">
    <button *ngIf="currentStep>0" [disabled]="currentStep == totalSteps.length && openSign" class="btnMainColor btnColor" mat-flat-button (click)="setStep(currentStep-1)"
        mat-button>
        < Indietro</button>
</div>
<div class="col-6 text-right" *ngIf="currentStep < totalSteps.length">  
    <button               
        [disabled]="generatingPdf || stepController() "
        class="btnMainColor btnColor" mat-flat-button (click)="saveModule()">Avanti ></button>
    <div>
        <div class="smallFont text-danger"
            *ngIf="(!validAnagrafica && currentStep === 0) || (currentStep === 4 && !this.formController.validPagamento(this.form, true))"> 
            Compila tutti i campi obbligatori
        </div>
        <div class="smallFont text-danger" *ngIf="(currentStep === 5 && !validPrivacy())">
            &egrave; necessario accettare tutte le informative per procedere alla firma
        </div>
    </div>
</div>
<div class="col-6" *ngIf="currentStep < totalSteps.length">
</div>
</div>

<div class="stepper">
<div class="row">
    <div class="stepCol align-self-center" *ngFor="let step of totalSteps">
        <div class="stepNumber" [ngClass]="
        {
        'doneStep': currentStep > step,
        'currentStep': currentStep == step,
        'errorStep': (!validAnagrafica && currentStep === step) ||(currentStep === step && !validPrivacy()) || (currentStep === step && !this.formController.validPagamento(this.form, true))
        }">
            <span class="number">{{step+1}}</span>
        </div>
    </div>

    <div class="stepCol align-self-center">
        <div class="stepNumber" [ngClass]="{'currentStep': currentStep == totalSteps.length}">
            
            <span class="number">
                <span class="material-icons">
                    check
                </span>
            </span>
        </div>
    </div>
</div>
</div>

<div class="modal fade" id="errorModal" tabindex="-1">
    <app-error-modal [error]="error"></app-error-modal>
</div>