<div class="topbar">
  <div class="row">
    <div class="col-4">
      <button *ngIf="!getRoute()" mat-button (click)="goBack()">
        <span class="material-icons">undo</span> Indietro
      </button>
      <!--
      <button *ngIf="toDashboard()" mat-button (click)="goBack()">
        <span class="material-icons">undo</span> Dashboard
      </button>
      -->
    </div>
    <div class="col-4 text-center">
      <img class="mb-3 logImg cursorPointer" alt="logo" routerLink="{{redirectTo}}" title="remail" src="/assets/img/gdl.png">
    </div>
    <div class="col-4 text-right">
      <button mat-button [matMenuTriggerFor]="menu">
        <span class="material-icons mr-1" style="font-size: 24px;vertical-align: text-bottom;">
          account_circle
        </span>
        {{loggedUser.lastName}} {{loggedUser.firstName}}
        <!--
        {{loggedUser.firstName | slice: 0 :1}}.
        {{loggedUser.lastName | slice: 0 :1}}
        -->
      </button>
      <mat-menu #menu="matMenu">
        <!--
        <div mat-menu-item>
          {{loggedUser.firstName}} {{loggedUser.lastName}}
        </div> -->
        <button mat-menu-item routerLink="/">
          Esci <span class="material-icons smIcon">logout</span>
        </button>
      </mat-menu>

    </div>
  </div>

</div>
<div class="mainContent mb-5" style="min-height: 75%">
  <router-outlet></router-outlet>
</div>

<div class="logoFooter text-center d-none d-lg-block" *ngIf="!checkRole(loggedUser)">
  <h4 class="mb-5">Scopri tutti i nostri brand e rimani sempre aggiornato sulle nostre promozioni </h4>
  <div class="row text-center">
    <div class="col-lg-2 mb-2 align-self-center">
      <a href="https://www.remail.it/" target="_blank"><img class="footerLogos" src="assets/img/loghi/restyling_logo_Remail_ok_cmyk.jpg"/></a>
      <!-- <p><a href="https://www.remail.it/" target="_blank">https://www.remail.it/</a></p> -->
    </div>
    <div class="col-lg-2 mb-2 align-self-center">
      <a href="https://www.bagnitaliani.it/" target="_blank"><img class="footerLogos" src="assets/img/loghi/logo-bagni-italiani.jpg"/></a>
      <!-- <p><a href="https://www.bagnitaliani.it/" target="_blank">https://www.bagnitaliani.it/</a></p> -->
    </div>
    <div class="col-lg-2 mb-2 align-self-center">
      <a href="https://www.lamantin.it/" target="_blank"><img class="footerLogos" src="assets/img/loghi/lamantin-bianco.jpg"/></a>
      <!-- <p><a href="https://www.lamantin.it/" target="_blank">https://www.lamantin.it/</a></p> -->
    </div>
    <div class="col-lg-2 mb-2 align-self-center">
      <a href="https://www.zucchettipoltrone.it/" target="_blank"><img class="footerLogos" src="assets/img/loghi/zucchetti-logo_1.jpg"/></a>
      <!-- <p><a href="https://www.zucchettipoltrone.it/" target="_blank">https://www.zucchettipoltrone.it/</a></p> -->
    </div>
    <div class="col-lg-2 mb-2 align-self-center">
      <a href="https://www.arielenergia.it/" target="_blank"><img class="footerLogos" src="assets/img/loghi/logo-ariel.jpg"/></a>
      <!-- <p><a href="https://www.arielenergia.it/" target="_blank">https://www.arielenergia.it/</a></p> -->
    </div>
    <div class="col-lg-2 mb-4 align-self-center">
      <a href="https://www.infixa.it/" target="_blank"><img class="footerLogos" src="assets/img/loghi/logo_infixa_pergole_CMYK.jpg"/></a>
      <!-- <p><a href="https://www.infixa.it/" target="_blank">https://www.infixa.it/</a></p> -->
    </div>
    <div class="col-lg-12 mb-2 align-self-center">
      <a href="https://www.zucchettimaterassi.it/" target="_blank"><img class="footerLogos" src="assets/img/loghi/MATERASSI ZUCCHETTI.jpg"/></a>
      <!-- <p><a href="https://www.infixa.it/" target="_blank">https://www.infixa.it/</a></p> -->
    </div>
    <hr class="w-100 d-block d-sm-none"/>
    <div class="w-100 d-block d-sm-none text-right">
      <button mat-button (click)="showBrands()">Chiudi</button>
    </div>
  </div>
</div>
<div class="logoFooterBackDrop" (click)="showBrands()"></div>

<div class="bottombar text-center">
  <span class="brands-link mb-2 d-block d-sm-none" (click)="showBrands()"> Scopri i nostri marchi</span><br/>
  <img style="max-width: 100px;" class="mb-3" alt="logo" title="remail" src="/assets/img/gdl.png">
  <p>
    © 2021 GDL SpA - Via Orbetello 54/D - 10148 Torino<br/>
    Partita Iva: 10062800015 - REA: TO-1102436 - Capitale Sociale 2.000.000€ interamente versati
  </p>
</div>
<div class="toTop">
  <button mat-mini-fab color="warn" aria-label="torna su" (click)="scrollTop()">
    <mat-icon>expand_less</mat-icon>
  </button>
</div>
