<div id="headingOne">
    <h5 class="mb-0">
        <div class="text-center blueBg">

            NUOVO PIATTO DOCCIA<br />
            PIATTO A BORDO LARGO
        </div>


    </h5>
</div>

<div class="blueCont"
    [ngClass]="{'onlyRedBorder': form.sections.piattoDoccia.largo.coloreId && !form.sections.piattoDoccia.largo.dimensioneId}">
    <div class="d-inline-block">
        <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
            (ngModelChange)="changeModel($event)" [(ngModel)]="form.sections.piattoDoccia.largo.coloreId">
            <mat-radio-button class="mb-2 mr-2" [value]="colore.id" *ngFor="let colore of listPiattoDocciaColor">
                {{colore.descrizione}}</mat-radio-button>
            <!-- <mat-radio-button class="mb-2 mr-2" value="BIANCO">BIANCO</mat-radio-button>
                            <mat-radio-button class="mr-2" value="NERO">NERO</mat-radio-button> -->
            <span class="undo material-icons" matTooltip="Cancella selezione"
                *ngIf="form.sections.piattoDoccia.largo.coloreId" (click)="form.sections.piattoDoccia.largo = {}">
                backspace
            </span>
        </mat-radio-group>
    </div>

    <div *ngIf="form.sections.piattoDoccia.largo.coloreId">
        <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
            [(ngModel)]="form.sections.piattoDoccia.largo.dimensioneId">
            <mat-radio-button *ngFor="let piatto of listPiattoDocciaDim" class="mb-2 mr-2" [value]="piatto.id">
                {{piatto.descrizione}}</mat-radio-button>
        </mat-radio-group>
        <div class="smallFont text-danger"
            *ngIf="form.sections.piattoDoccia.largo.coloreId && !form.sections.piattoDoccia.largo.dimensioneId">
            Selezione obbligatoria
        </div>
    </div>
</div>