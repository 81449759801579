<div class="h-100">
    <div class="my-auto">
        <div class="pt-5 text-center mb-4">
            <!-- <img class="mb-3" alt="logo" title="remail" src="/assets/img/logo-remail.png"> -->
        </div>
        
        <div style="padding:80px 20px 20px;">
            <h4 class="mb-5">Benvenuto {{currentUser.firstName}} {{currentUser.lastName}}</h4>
            <div class="singleButtonDash" matRipple routerLink="/order-list" *ngIf="userService.returnUserAut('ROLE_SELLER')">
                <div>
                    <mat-icon>list</mat-icon>
                </div>
                <div>
                    Lista ordini
                </div>
            </div>
            <div class="singleButtonDash" matRipple routerLink="/order-frozen-list" *ngIf="userService.returnUserAut('ROLE_SELLER')">
                <div>
                    <mat-icon>list</mat-icon>
                </div>
                <div>
                    Lista ordini congelati
                </div>
            </div>
            <div class="singleButtonDash" matRipple routerLink="/new-order" *ngIf="userService.returnUserAut('ROLE_SELLER')">
                <div>
                    <mat-icon>edit</mat-icon>
                </div>
                <div>
                    Nuovo ordine
                </div>
            </div>
            <div class="singleButtonDash" matRipple routerLink="/new-order" *ngIf="userService.returnUserAut('ROLE_TECH')">
                <div>
                    <mat-icon>list</mat-icon>
                </div>
                <div>
                    Lista ordini
                </div>
            </div>
            <div class="singleButtonDash" matRipple routerLink="/search-order" *ngIf="userService.returnUserAut('ROLE_CUSTOMER_CARE')">
                <div>
                    <mat-icon>search</mat-icon>
                </div>
                <div>
                    Ricerca ordini
                </div>
            </div>            
        </div>
    </div>
</div>



