import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-useractivation',
  templateUrl: './useractivation.component.html',
  styleUrls: ['./useractivation.component.scss']
})
export class UseractivationComponent implements OnInit {
  key: string;
  loading: boolean;
  errorKey: boolean;
  errorSend: boolean;
  sending: boolean;
  success: boolean;
  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private snackBar: MatSnackBar,
      private authService: AuthenticationService,
  ) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.key = params.key;
    });
    setTimeout(() => {
      if (this.key) {
        this.loading = false;
        this.errorKey = false;
        this.sendActivation();
      } else {
        this.loading = false;
        this.errorKey = true;
      }
    }, 1000);
  }

  goToRegistration(): void {
    this.router.navigate(['registration']);
  }

  goToLogin(): void {
    this.router.navigate(['login']);
  }

  sendActivation(): void {
    
    this.sending = true;
    const body = this.key;
    this.authService.sendActivation(body).subscribe(response => {
      this.sending = false;
      this.snackBar.open('Il tuo utente è stato abilitato', 'ok', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      this.success = true;
      setTimeout(() => {
        this.goToLogin();
      }, 3000);
    }, error => {
      this.sending = false;
      this.errorSend = true;
      this.snackBar.open('Si è verificato un errore, ti preghiamo di riprovare', 'ok', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: 'snackerror'
      });
      console.error('Error => ', error);
    });
  }

}
