import { DatiTecniciAttService } from '../../../bagni-italiani/main-bagni/services/datiTecniciAtt/datiTecniciAtt.service';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { Trascodifica } from '../../../../classes/trascodifica'
import { constants } from 'src/app/modules/classes/constants';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dati-tecnici-lorem',
  templateUrl: './dati-tecnici.component.html',
  styleUrls: ['./dati-tecnici.component.scss']
})
export class DatiTecniciComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche:Trascodifica[];
  @Input() brand:string;
  
  public constants = constants;

  constructor(
    private datiTecniciService :DatiTecniciAttService,
    private formController: FormControllerService
  ) { }

  ngOnInit(): void {
    //recupero i dati tecnici se presenti ordineId.equals=
	  this.datiTecniciService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => { 	
      this.form.sections.datiTecnici = this.formController.mappingDatiTecniciLorenDTOtoForm(res[0],this.form.sections.datiTecnici, this.form.idOrder);
    })
  }

}
