import { Component, Input, OnInit } from '@angular/core';
import { DatiTecniciAttService }from '../services/datiTecniciAtt/datiTecniciAtt.service';
import { Trascodifica } from '../../../../classes/trascodifica'
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { constants } from 'src/app/modules/classes/constants';
@Component({
  selector: 'app-form-dati-tecnici-att',
  templateUrl: './form-dati-tecnici-att.component.html',
  styleUrls: ['./form-dati-tecnici-att.component.scss']
})
export class FormDatiTecniciAttComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche:Trascodifica[];
  @Input() brand:string;

  tipiAbitazione:Trascodifica[];
  tipiBagno:Trascodifica[];
  tipiSanitari:Trascodifica[];
  tipiScaricoWc:Trascodifica[];
  tipiCassettaScaricoWc:Trascodifica[];
  tipiAdesionePiastrelle:Trascodifica[];
  tipiMuriPeri:Trascodifica[];
  tipiRiscaldamento:Trascodifica[];
  tipiImpianto:Trascodifica[];
  classBG = '';
  classCont= '';
  
  constructor(
	private datiTecniciService :DatiTecniciAttService,
	private formController: FormControllerService
  ) {}

  ngOnInit(): void {	
    if(this.brand === 'bagniHelp75'){
      this.classBG = 'help75Bg';
      this.classCont = 'hepl75Cont'
    }else if (this.brand === 'bagno-pratico'){
      this.classBG = 'bagnoPraticoBg';
      this.classCont = 'bagnoPraticoCont';
    }else{
      this.classBG = 'blueBg';
      this.classCont = 'blueCont';
    }

	//recupero i dati tecnici se presenti ordineId.equals=
	this.datiTecniciService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {			
			this.form.sections.datiTecnici = this.formController.mappingDatiTecniciDTOtoForm(res[0],this.form.sections.datiTecnici, this.form.idOrder);
			this.tipiAbitazione = this.formController.getTrascodificaByCodice(constants.COD_TIPO_ABITAZIONE ,  this.trascodifiche);
			this.tipiBagno = this.formController.getTrascodificaByCodice(constants.COD_TIPO_BAGNO, this.trascodifiche);
			this.tipiSanitari = this.formController.getTrascodificaByCodice(constants.COD_TIPO_SANITARI ,this.trascodifiche);
			this.tipiScaricoWc = this.formController.getTrascodificaByCodice(constants.COD_TIPO_SCARICO_WC,this.trascodifiche);
			this.tipiCassettaScaricoWc = this.formController.getTrascodificaByCodice(constants.COD_TIPO_CASS_SC_WC,this.trascodifiche);
			this.tipiAdesionePiastrelle = this.formController.getTrascodificaByCodice(constants.COD_ADESIONE_PIASTRELLE,this.trascodifiche);
			this.tipiMuriPeri = this.formController.getTrascodificaByCodice(constants.COD_MURI_PERIMETRALI,this.trascodifiche);
			this.tipiRiscaldamento = this.formController.getTrascodificaByCodice(constants.COD_TIPO_RISCALDAMENTO,this.trascodifiche);
			this.tipiImpianto = this.formController.getTrascodificaByCodice(constants.COD_IMPIANTO,this.trascodifiche);
	 });
  }
}
