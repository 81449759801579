<div class="promoContainer mb-4">
    <div class="text-center text-success mb-4 promoTitle">
      <a>PROMOZIONE</a>
    </div>
    <div class="row originalRow">
      <div class="col-4">
        <mat-form-field class="full-width">
          <mat-label>Seleziona promozione</mat-label>
          <mat-select [(ngModel)]="form.sections.promo" (selectionChange)="onPromoChange($event.value)">
            <mat-option [value]="null">Seleziona promozione</mat-option>
            <mat-option *ngFor="let p of promo" [value]="p.id">{{ p.descrizione }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-4 align-self-center">
        <mat-form-field class="full-width">
          <mat-label>Seleziona modello</mat-label>
          <mat-select [(ngModel)]="form.ordine.promoId" (click)="searchModello = ''">
            <div class="searchInSelect">
                <input type="text" [(ngModel)]="searchModello" placeholder="cerca"/>
              </div>
            <mat-option *ngFor="let modello of modelli" [value]="modello.id" [hidden]="includeModello(modello.descrizione)">{{ modello.descrizione }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class=""
    *ngIf="this.form.ordine.promoId && form.sections.promo && promo.length > 0 && modelli.length > 0">
    <hr />
    <div class="littleFont">
        <div class="row originalRow">
            <div class="col-5">
                Promozione selezionata:  {{returnPromoDesc(form.sections.promo)}} {{" - "}} {{returnPromoDesc(this.form.ordine.promoId)}}
            </div>
            <div class="col-2">
                <button class="btnMediumZucchetti" mat-flat-button (click)="this.form.ordine.promoId = null; form.sections.promo = null;">Rimuovi</button>
            </div>
        </div>
    </div>
</div>
</div>