import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AccessoriAllarmeDto } from 'src/app/modules/classes/allarme/accessoriAllarme.dto';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class AccessoriAllarmeService extends HttpService {

  endpoint: string = 'accessoriallarmes';
  constructor(
    public router: Router,
    public http: HttpClient
  ) {
    super(router, http);
  }

  save(accessori: AccessoriAllarmeDto): Observable<any> {
    return this.http.post<any>(this.apiUrl + this.endpoint, accessori, this.setHttpOptions()).pipe(
      retry(0),
      catchError(err => this.handleError(err))
    );
  }

  update(accessori: any): Observable<any> {
    return this.http.put<any>(this.apiUrl + this.endpoint, accessori, this.setHttpOptions()).pipe(
      retry(0),
      catchError(err => this.handleError(err))
    );
  }

  findByKey(data: any, key: string): Observable<any> {
    return this.http
      .get<any>(this.apiUrl + this.endpoint + '?' + key + data, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(this.handleError)
      );
  }

  delete(id: any): Observable<any> {
    return this.http.delete<any>(this.apiUrl + this.endpoint + '/' + id, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(this.handleError)
      );
  }
}
