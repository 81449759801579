import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EstimateFotovoltaicoService } from 'src/app/services/estimate-fotovoltaico/estimate-fotovoltaico.service';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { PagamentoDTO } from '../../../bagni-italiani/main-bagni/classes/dati-tecnici';
import { AnagraficaService } from '../../../bagni-italiani/main-bagni/services/anagrafica/anagrafica.service';
import { FotovoltaicoUT } from 'src/app/modules/classes/fotovoltaicoUT';
import { AnagraficaForm } from 'src/app/modules/classes/anagrafica';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { Ordine } from 'src/app/modules/classes/ordine';
import { BrandService } from 'src/app/services/brand/brand.service';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { FotovoltaicoService } from 'src/app/services/fotovoltaico/fotovoltaico.service';
import { fotovoltaicoProd } from '../classes/fotovltaicoprod.model';
import { catchError } from 'rxjs/operators';
import { Fotovoltaico } from '../classes/fotovoltaico-dto.model';
import { PaymentService } from 'src/app/services/payment/payment.service';
import * as $ from 'jquery';
declare var jQuery: any;

@Component({
  selector: 'app-fotovoltaico-ut',
  templateUrl: './fotovoltaico-ut.component.html',
  styleUrls: ['./fotovoltaico-ut.component.scss']
})
export class FotovoltaicoUTComponent implements OnInit {

  validAnagrafica: boolean = false;
  preventivoFound: boolean = true;
  showAnagrafica: boolean = false;
  openSign: boolean = false;
  checkContractNumber: boolean = false;

  preventivoId: number;
  idCliente: number;
  currentStep: number = 0;

  fotovoltaicoUtError: string = ''
  content: string = ''; 
  formattedText: string = '';

  form: any;
  obj1: any;
  obj2: any;
  error: any;
  originalForm: any; 
  currentUser:any; 

  totalSteps: number[];
  trascodifiche: Trascodifica[];

  ordine: Ordine;
  anagrafica: AnagraficaForm;
  fotovoltaicoUt: FotovoltaicoUT;

  text: string = '';

  hasValues = (obj) => Object.values(obj).some(v => v !== null && typeof v !== "undefined");

  @ViewChild('prodText') stringElement: ElementRef;

  constructor(
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private estimateFotovoltaicoService: EstimateFotovoltaicoService,
    private anagraficaService: AnagraficaService,
    private orderService: OrdersService,
    private formController: FormControllerService,
    private snackBar: MatSnackBar,
    private fotovoltaicoService: FotovoltaicoService,
    private brandService: BrandService,
    private paymentService: PaymentService
  ) {
    this.validAnagrafica = false;
    this.form = {
      idOrder: Number(this.route.snapshot.paramMap.get('id')),
      anagrafica: new AnagraficaForm(),
      sections: {
        fotovoltaico:Fotovoltaico,
        fotovoltaicoProd: fotovoltaicoProd,
        pagamento: [new PagamentoDTO()]
      },
      ordine: null
    }
    this.totalSteps = Array(5).fill(1).map((x, i) => i); // [0,1,2]
  }

  ngOnInit(): void {
    
    this.originalForm = JSON.parse(JSON.stringify(this.form));
    if (localStorage.getItem('id_client') !== null) {
      this.idCliente = Number(localStorage.getItem('id_client'));
      this.form.anagrafica.anagrafica.id = this.idCliente;
    }

    if (this.currentStep > 0) {
      $('.imgModulo').addClass('yesShow');
    } else {
      $('.imgModulo').removeClass('yesShow');
    }

    this.getOrder(this.form.idOrder);
  }

  preCompilationContract(res): void {
    
    if(this.preventivoId &&  this.preventivoFound && this.showAnagrafica){
        if(res){
          this.fotovoltaicoUt = res;
        }
  
    if(this.fotovoltaicoUt){

      this.currentUser = JSON.parse(localStorage.getItem('or_profile'));

      this.anagrafica = {
          agente: '',
          anagrafica : null,
          infoContratto:  {
              consulente: this.currentUser.login,
              tel: '',
              data: new Date()
          }
      } 

      const indirizzoCompleto = this.fotovoltaicoUt.Indirizzo;
      const indirizzoArray = indirizzoCompleto.split(' '); // Dividi la stringa per spazi

      this.anagrafica.anagrafica = {
          id: null,
          nome: this.fotovoltaicoUt.Descrizione,
          cognome: null,
          email: this.fotovoltaicoUt.Email,
          tel1: this.fotovoltaicoUt.Cellulare,
          tel2: null,
          citta: this.fotovoltaicoUt.Citta,
          indirizzo: indirizzoArray.slice(0, -1).join(' '),  // Prendi tutte le parti tranne l'ultima e uniscile nuovamente
          indirizzoinst: null,
          numero: indirizzoArray.slice(-1).toString(),
          provincia: this.fotovoltaicoUt.Provincia,
          cap: Number(this.fotovoltaicoUt.CAP),
          ztl: null,
          cf: this.fotovoltaicoUt.CodiceFiscale,
          ragionesociale: null,
          piva: this.fotovoltaicoUt.PartitaIva,
          centrostorico: null,
          piano: null,
          ascensore: null,
          ritirousato: null,
          scalachiocc: null,
          portonestr: null,
          scalastr: null,
          diversoind: null
      }
      
      this.anagraficaService.save(JSON.stringify(this.anagrafica.anagrafica)).subscribe(res => {
          this.ordine.clienteId = res.id;
          this.ordine.cognome = res.cognome;
          this.ordine.nome = res.nome;
          this.ordine.idordine = Number(this.fotovoltaicoUt.NumeroContratto);
          localStorage.setItem('id_order', this.fotovoltaicoUt.NumeroContratto);
          this.form.anagrafica.anagrafica.id = res.id;
          this.orderService.update(JSON.stringify(this.ordine)).subscribe();
      }, error => {
          console.error('Error => ', error);
          this.openSnackBar('Errore in fase di salvataggio del modulo');
      });

      let fotovoltaico: Fotovoltaico = {
          id: null,
          praticaallacciamento: null,
          smartcharger: null,
          note: null,
          ordineId: this.form.idOrder
      }

      this.fotovoltaicoService.save(fotovoltaico)
        .subscribe(result => {
          if(result.id && this.fotovoltaicoUt.Prodotto){
              let fotovoltaicoProd:fotovoltaicoProd = {
                id: null,
                prodottoId: null,
                prodottoDescrizione: '',
                tagliakwId: null,
                tagliakwDescrizione: '',
                battkwId: null,
                battkwDescrizione: '',
                inverterkwId: null,
                inverterkwDescrizione: '',
                potenzakwId: null,
                potenzakwDescrizione: '',
                fotovoltaicoId: result.id,
                dettaglio: this.fotovoltaicoUt.Prodotto
            }
            this.form.sections.fotovoltaicoProd = fotovoltaicoProd;
    
            this.fotovoltaicoService.saveProd(this.form.sections.fotovoltaicoProd).subscribe(res => {
                this.form.sections.fotovoltaicoProd.id = res.id;
                this.form.sections.fotovoltaicoProd.dettaglio = res.dettaglio;

                this.formattedText = this.getFormattedText(this.form.sections.fotovoltaicoProd.dettaglio);
            });
          }
        })
     }
    this.form.anagrafica = this.anagrafica;
   } 
  }

  getOrder(idOrder): void {
  
    this.orderService.getSingleOrder(idOrder).subscribe(res => {

      this.ordine = res;
      this.form.ordine = res;
      
      if(res.clienteId && res.prodottoId){
        this.showAnagrafica = true;
        this.preventivoFound = true;
        this.preventivoId = 1;
        this.form.anagrafica.anagrafica.id = res.clienteId;
          localStorage.setItem('id_product', res.prodottoId);
          localStorage.setItem('id_client', res.clienteId);
          localStorage.setItem('id_order', res.idordine);
          this.form.idOrder = res.id;
        this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
      }else{
        this.showAnagrafica = false;
        this.preventivoFound = false;
      }
      this.brandService.getDataByProduct(Number(localStorage.getItem('id_product'))).subscribe(res => {
        this.trascodifiche = res;
      });
    });

    this.fotovoltaicoService.findByKey(idOrder, 'ordineId.equals=' , 'fotovoltaicos').subscribe(res => {
      if(res[0]?.id){
          this.fotovoltaicoService.findByKey(res[0]?.id, 'fotovoltaicoId.equals=', 'fotovoltaicoprods').subscribe(resProd => {
              this.form.sections.fotovoltaicoProd = resProd[0];
              if (this.form.sections.fotovoltaicoProd.dettaglio) {
                this.formattedText = this.getFormattedText(this.form.sections.fotovoltaicoProd.dettaglio);
              }
          })
        }
     })
  }

  getFormattedText(dettaglio: string): string {
    // const preText = 'Contratto di Vendita e Installazione del seguente impianto fotovoltaico così composto:';
  
    // Verifica se il dettaglio inizia già con preText
    /*if (dettaglio.startsWith(preText)) {
      return dettaglio;
    }*/ // evita di duplicare la sezione.
  
    //return `${preText}\n\n${dettaglio}\n`;
    return `${dettaglio}\n`;
  }
  

  ngDoCheck(): void {
    this.obj1 = JSON.stringify(this.originalForm);
    this.obj2 = JSON.stringify(this.form);
      if (this.obj1 !== this.obj2) {
        this.checkAnagrafica();
        this.originalForm = JSON.parse(JSON.stringify(this.form));
      }
  }

  ngAfterViewInit(): void{
      //console.log('origina form', this.originalForm);
  }
  
  setStep(num): void {
    this.currentStep = num;
      if (this.currentStep > 0) {
        $('.imgModulo').addClass('yesShow');
      } else {
        $('.imgModulo').removeClass('yesShow');
      }
    this.scrollTop();
  }

  scrollTop(): void {
      $('html, body').animate({ scrollTop: 0 }, 300);
  }

  saveModule(): void {
      $('body').removeClass('noScr');
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }

      if (this.currentStep === 0) {
        // Step salvataggio anagrafica
        this.anagrafica = this.form.anagrafica.anagrafica;
        if (this.form.anagrafica.anagrafica.id) {
          this.anagraficaService.update(JSON.stringify(this.anagrafica)).subscribe(res => {
            this.ordine.clienteId = res.id;
            this.ordine.cognome = res.cognome;
            this.ordine.nome = res.nome;
            this.ordine.accettprivacy = this.form.ordine.accettprivacy;
            this.ordine.accettprivacynd = this.form.ordine.accettprivacynd;
            this.ordine.accettprivacyrd = this.form.ordine.accettprivacyrd;
            this.orderService.update(JSON.stringify(this.ordine)).subscribe();
          }, error => {
            console.error('Error => ', error);
            this.openSnackBar('Errore in fase di salvataggio del modulo');
          });
        } 
      } else if (this.currentStep === 1) {
        if (this.form.sections.fotovoltaicoProd.id) {
          this.form.sections.fotovoltaicoProd.dettaglio =  this.formattedText;
            this.fotovoltaicoService.updateProd(this.form.sections.fotovoltaicoProd).subscribe();
        }
      } else if (this.currentStep === 2) {
            // Step salvataggio pagamento
            if (this.hasValues(this.form.sections.pagamento[0])) {
              this.form.sections.pagamento[0].ordineId = this.form.idOrder;
                const payment = this.form.sections.pagamento[0];
            if (payment.id === undefined || payment.id === null) {
              this.paymentService.save(JSON.stringify(payment)).subscribe();
            } else {
              this.paymentService.update(JSON.stringify(payment)).subscribe();
          }
        }
      } else if (this.currentStep === 3) {
        //Step salvataggio privacy
        this.orderService.update(JSON.stringify(this.form.ordine)).subscribe();
      } else if (this.currentStep === 4) {
        this.anagraficaService.update(JSON.stringify(this.form.anagrafica.anagrafica)).subscribe(res => {
          this.ordine.clienteId = res.id;
          this.ordine.cognome = res.cognome;
          this.ordine.nome = res.nome;
        }, error => {
          console.error('Error => ', error);
          this.openSnackBar('Errore in fase di salvataggio del modulo');
        });
      }
    this.setStep(this.currentStep + 1);
  }

  onSubmit(): void {
    this.estimateFotovoltaicoService.findById(this.preventivoId)
      .pipe(
        catchError((error) => {
          console.error('Nessun contratto trovato', error);
          this.preventivoFound = false;
          return [];
        })
      )
      .subscribe(
        (res) => {
          // Gestioni la risposta di successo
          this.preventivoFound = true;
          this.showAnagrafica = true;
  
          if (!res.NumeroContratto) {
            this.snackBar.open('Numero contratto non trovato', 'ok', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
              panelClass: 'snackerror'
            });
            return;
          } else {
            this.orderService.getCheckNordine(res.NumeroContratto).subscribe(
              (resp) => {

                if(resp[0] && (resp[0].idordine === res.NumeroContratto)){
                  this.snackBar.open('Contratto già presente sui nostri sistemi', 'ok', {
                    duration: 3000,
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                    panelClass: 'snackerror'
                  });
                  this.showAnagrafica = false;
                  this.preventivoFound = true;
                }else{
                  this.preCompilationContract(res);
                }
              })
      }})
  }

  checkAnagrafica(): boolean {
    this.validAnagrafica = this.formController.checkAnagrafica(this.form);
    return this.validAnagrafica;
  }
  
  validPrivacy(): boolean {
    if(this.form.ordine === undefined || this.form.ordine === null){
     return false;
    } 
     if (!this.form.ordine.accettprivacy || !this.form.ordine.accettprivacynd || !this.form.ordine.accettprivacyrd) {
       return false;
     } else
       return true;
   }

  openSnackBar(text): void {
    this.snackbar.open(text, 'Ok', {
      duration: 4000
    });
  }

  onInsertPreventivo(): void {
    this.preventivoFound = true;
  }

	edit() {
		this.currentStep = 0;
		this.openSign = false;
		this.orderService.getSingleOrder(this.form.idOrder).subscribe((res) => {
			res.statopraticaCodice = "A";
			res.statopraticaId = 2;
			this.orderService.update(JSON.stringify(res)).subscribe( res => {
				this.getOrder(this.form.idOrder);
			});
		})
	}

  reload(value: boolean) {
    this.openSign = value;
  }

}