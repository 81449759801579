import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {HttpService} from '../../../../../services/http.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {
  catchError,
  retry,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DettaglioService extends HttpService {

	constructor(
		public router: Router,
		public http: HttpClient
	) {
		super(router, http);
	}
	
	
	save(abitazione: any): Observable<any> {
		return this.http.post<any>(this.apiUrl + 'dettagliocondizionatores', abitazione, this.setHttpOptions()).pipe(
			retry(0),
			catchError(err => this.handleError(err))
		);
	}

	update(abitazione: any): Observable<any> {
		return this.http.put<any>(this.apiUrl + 'dettagliocondizionatores', abitazione, this.setHttpOptions()).pipe(
			retry(0),
			catchError(err => this.handleError(err))
		);
	}
	
	findByKey(data:any , key:string): Observable<any>{
	    return this.http
           .get<any>(this.apiUrl + 'dettagliocondizionatores?'+key+data ,this.setHttpOptions())
           .pipe(
               retry(0),
               catchError(this.handleError)
           );
	}
}
