import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { PreviewComponent } from 'src/app/modules/shared/pdf/preview/preview.component';
import { PrintComponent } from 'src/app/modules/shared/pdf/print/print.component';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { HttpService } from 'src/app/services/http.service';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { PrintserviceService } from 'src/app/services/print/printservice.service';
import { environment } from 'src/environments/environment';
import { OmaggioService } from '../../../bagni-italiani/main-bagni/services/omaggio/omaggio.service';
import { PoltronaService } from 'src/app/services/poltrone/poltrone.service';
import { PoltroneDto } from 'src/app/modules/classes/zucchetti/poltrone/poltrone';
declare var jQuery: any;

@Component({
  selector: 'app-print-poltrone',
  templateUrl: './print-poltrone.component.html',
  styleUrls: ['./print-poltrone.component.scss']
})
export class PrintPoltroneComponent extends PrintComponent implements OnInit {

  @Input() idOrder: number;
  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  @Input() anagrafica: any;
  @Output('reload') reload: EventEmitter<any> = new EventEmitter<any>();

  generatingPdf: boolean = false;
  creatingPdf: boolean = false;
  validAnagrafica: boolean = false;
  loadAll: boolean = false;
  sendingPdf: boolean = false;
  openSign: boolean = false;
  showFrame: boolean = false;
  showModalPrint: boolean = false;

  privacyText: string = '';
  urlFrame: string = '';
  colorString: string = '#0a2e5e';

  timeLeft: number = 60;
  hTotalPageSpace: number = 280;

  rgbColorLine: number[] = [10, 46, 94];

  ordine: any;
  listPoltrone: PoltroneDto[] = [];
  defineColumn: string[] = [];
  pagamento: any;
  omaggio:any;
  error: any;
  member: any;

  integratedSignature = environment.integratedSignature;
  isApiV3 = environment.isV3;

  constructor(
    private router: Router,
    public formController: FormControllerService,
    private orderService: OrdersService,
    public dialog: MatDialog,
    private paymentService: PaymentService,
    public printService: PrintserviceService,
    private omaggioService: OmaggioService,
    private poltronaService: PoltronaService,
    private httpService: HttpService,
  ) {
    super(printService);
  }

  ngOnInit(): void {
    this.getOrder(this.idOrder);
    this.checkAnagrafica();
    this.getPoltrone();
    this.getOmaggio()
    this.getPagamento();
    this.getPrivacy();

    this.defineColumn = [
			"Nome",
			"Colore",
      "Note",
      "Optional"
		];
  }

  getOrder(idOrder): void {
    this.orderService.getSingleOrder(idOrder).subscribe(res => {
      this.ordine = res;
      this.form.ordine = res;
    });
  }

  generatePdf(firma: boolean): void {
    let y = this.initPdf(this.ordine, this.anagrafica, '/assets/img/loghi/ZUCCHETTI.jpg', 'Modulo d\'ordine poltrone Zucchetti', this.colorString);

    this.printService.writeFooterPage(
      this.doc,
      this.ordine,
      this.currentPdfPage,
      6
    );

      // PROMO
      if (this.ordine.promoId) {
          let tableTitle = 'PROMOZIONE';
          let column = [
            "DESCRIZIONE"
        ]
    
        this.poltronePromoPrint(this.doc, this.ordine.promoId, tableTitle, column, y, this.trascodifiche, this.colorString)
    
      }
    
      y = this.printService.returnCurrentY();

    if(this.listPoltrone.length > 0){
      let tableTitle = 'PRODOTTI'
      this.prodottoPoltronaPrint(this.doc, this.listPoltrone, tableTitle, this.defineColumn, y, this.trascodifiche, this.colorString)
    }

    if (this.ordine && this.ordine.note && this.ordine.note !== '') {
      const title = "NOTE";
      this.createTableTextNoheader(title, this.ordine.note, this.printService.returnCurrentY(), this.doc, this.rgbColorLine);
    }

    if (this.omaggio.length > 0) {
      this.genericOmaggio(this.doc, this.omaggio, this.printService.returnCurrentY(), this.colorString, true);
    }

    y = this.printService.returnCurrentY();

    if (this.pagamento) {
      this.createTablePagamento(this.doc, this.pagamento, y, 0, this.colorString);
    }

    y = this.printService.addNewPage(
      this.doc,
      true,
      this.img,
      this.ordine,
      this.currentPdfPage,
      this.title
    );

    this.printService.writePrivacyOne(
      this.doc,
      y,
      this.ordine,
      this.img,
      this.title,
      this.currentPdfPage,
      this.privacyText
    );
    y = this.printService.returnCurrentY();

    this.creatingPdf = false;
    if (firma) {
      if (!this.integratedSignature) {
        this.createPdf(this.doc);
      } else {
        this.toggleSignPdf(this.doc);
      }
    } else {
      const pdfBlob = this.doc.output('blob');

      // Crea un URL dall'oggetto Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);
      this.openDialog(pdfUrl);
    }
  }

  togglePrintPreview(): void {
    this.generatePdf(false);
  }

  openDialog(pdf: any): void {
    this.dialog.open(PreviewComponent, {
      width: '800px',
      maxWidth: '100%',
      data: {
        pdf,
      },
    });
  }

  private toggleSignPdf(pdf): void {
    const interval = this.startTimer();
    const title = localStorage.getItem('id_order');
    const newPdf = btoa(pdf.output());
    const pages = this.printService.returnCurrentPage();
    const coordinates = this.printService.returnCoordinates();
    this.showModalPrint = !this.showModalPrint;
    this.openSign = true;
    this.showFrame = false;
    this.generatingPdf = true;
    this.urlFrame = '';
    jQuery('#signModal').modal({ backdrop: 'static', keyboard: false });
    jQuery('#signModal').modal('toggle');

    if (this.isApiV3) {
      const newPdf = new Blob([pdf.output('blob')], { type: "application/pdf" });
      var split = this.printService.returnCoordinates().split(",");
      const coordinates = {
        width: 120,
        height: 37,
        x: split[0],
        y: split[3],
      }
      this.formController.manageSignature(newPdf, this.form.idOrder, this.form, pages, coordinates, title, environment.signGenericId).then((res: any) => {
        this.urlFrame = res.signers[0].signature_link + '&disable_domain_validation=true';
        setTimeout(() => {
          this.showFrame = true;
          this.sendingPdf = false;
          this.generatingPdf = false;

        }, 2000);
        clearInterval(interval);
      }, error => {
        this.generatingPdf = false;
        this.sendingPdf = false;
        this.ordine.statopraticaCodice = "A";
        clearInterval(interval);
        this.closeSignPdf();
        jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
        jQuery("#errorModal").modal("toggle");
      });
    } else {
      this.formController
        .sendSignature(
          newPdf,
          this.idOrder,
          this.form,
          pages,
          coordinates,
          title,
          true
        )
        .then(
          (res) => {
            this.member = res.members[0].id;
            this.urlFrame =
              environment.apiUrl +
              '/procedure/sign?members=' +
              this.member +
              '&signatureUi=' +
              environment.signMontascale; // "https://staging-app.yousign.com/procedure/sign?members="+this.member;
            this.showFrame = true;
            this.sendingPdf = false;
            this.generatingPdf = false;

            clearInterval(interval);
            // this.router.navigate(['order-list']);
          },
          (error) => {
            this.generatingPdf = false;
            this.sendingPdf = false;
            this.ordine.statopraticaCodice = 'A';
            clearInterval(interval);
            jQuery('#errorModal').modal({ backdrop: 'static', keyboard: false });
            jQuery('#errorModal').modal('toggle');
          }
        );
    }

  }

  private getPagamento(): void {
    this.paymentService.findByKey(this.idOrder, 'ordineId.equals=').subscribe((res) => {
      this.pagamento = res;
    });
  }

  private getPoltrone(): void {
    this.poltronaService.findByKey(this.idOrder, 'ordineId.equals=').subscribe((res) => {
      this.listPoltrone = res;
    });
  }

  private getOmaggio(): void {
    this.omaggioService.findByKey(this.idOrder, 'ordineId.equals=').subscribe(res => {
      this.omaggio = res;
    })
  }

  private getPrivacy(): void {
    this.httpService.getPrivacy('assets/json/poltrone.json').subscribe((res) => {
      this.privacyText = res;
    });
    this.loadAll = true;
  }

  private createPdf(pdf): void {
    this.sendingPdf = true;
    this.openSign = true;
    const title = localStorage.getItem("id_order");
    pdf.setProperties({
      title,
    });

    const newPdf = btoa(pdf.output());
    const pages = this.printService.returnCurrentPage();
    const coordinates = this.printService.returnCoordinates();
    this.formController
      .sendSignature(
        newPdf,
        this.idOrder,
        this.form,
        pages,
        coordinates,
        title,
        false
      )
      .then(
        (res) => {
          this.generatingPdf = false;
          this.sendingPdf = false;
          this.router.navigate(["order-list"]);
        },
        (error) => {
          this.generatingPdf = false;
          this.sendingPdf = false;
          this.ordine.statopraticaCodice = "A";
          this.error = error;
          jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
          jQuery("#errorModal").modal("toggle");
        }
      );
  }

  startTimer(): any {
    const intervalId = setInterval(() => {
      if (this.timeLeft === 0) {
        clearInterval(intervalId);
        // richiamo api per invio email
        this.orderService.sendEmail(this.form.idOrder).subscribe();
        jQuery('#timeoutModal').modal({ backdrop: 'static', keyboard: false });
        jQuery('#timeoutModal').modal('toggle');

        setTimeout(() => {
          jQuery('#timeoutModal').modal('hide');
        }, 10000);

        setTimeout(() => {
          this.formController.goToDashboard();
        }, 11000);
      }
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }
    }, 1000);
    return intervalId;
  }


  returnIdOrdine(): string {
    return localStorage.getItem("id_order");
  }

  private checkAnagrafica(): boolean {
    this.validAnagrafica = this.formController.checkAnagrafica(this.form);
    return this.validAnagrafica;
  }

  public closeSignPdf(): void {
    jQuery('#signModal').modal({ backdrop: 'static', keyboard: false });
    jQuery('#signModal').modal('toggle');
    this.reload.emit(this.openSign);
    this.setStateToActive();
    this.formController.removeFrame();
  }

  private setStateToActive() {
    this.orderService.getSingleOrder(this.idOrder).subscribe((res) => {
      this.ordine = res;
      this.ordine.statopraticaCodice = "A";
      this.orderService.update(JSON.stringify(this.ordine)).subscribe(res => {
        this.getOrder(this.idOrder);
      });
    })
  }
}
