import { Component, Input, OnInit } from '@angular/core';
import { Prodotto } from 'src/app/modules/classes/prodotto';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { BrandService } from 'src/app/services/brand/brand.service';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { FotovoltaicoService } from 'src/app/services/fotovoltaico/fotovoltaico.service';
import { fotovoltaicoProd } from '../../classes/fotovltaicoprod.model';
import { Fotovoltaico } from '../../classes/fotovoltaico-dto.model';

@Component({
  selector: 'app-tipologia-prodotto',
  templateUrl: './tipologia-prodotto.component.html',
  styleUrls: ['./tipologia-prodotto.component.scss']
})
export class TipologiaProdottoComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  tipoProdottoLight: fotovoltaicoProd[];
  tipoProdottoFull: fotovoltaicoProd[];
  tipologiaFull: Trascodifica[];
  tipologiaLight: Trascodifica[];

  tagliaLight: Trascodifica[];
  batteriaLight: Trascodifica[];
  inverterLight: Trascodifica[];
  potenzaLight: Trascodifica[];
  
  tagliaFull: Trascodifica[];
  batteriaFull: Trascodifica[];
  inverterFull: Trascodifica[];
  potenzaFull: Trascodifica[];
  constructor(private brandService: BrandService,
    private formController: FormControllerService,
    private fotovoltaicoService: FotovoltaicoService) { }

  ngOnInit(): void {
    this.tipoProdottoLight = [];
    this.tipoProdottoFull = [];
    this.getTranscodifiche();
    this.getTipoProdotto();
  }

  getTipoProdotto(): void {
    this.fotovoltaicoService.findByKey(this.form.idOrder, 'ordineId.equals=' , 'fotovoltaicos').subscribe(res => {
      this.form.sections.fotovoltaico = res[0];
      if(this.form.sections.fotovoltaico !== undefined && this.form.sections.fotovoltaico.id !== undefined){
        this.fotovoltaicoService.findByKey(this.form.sections.fotovoltaico.id, 'fotovoltaicoId.equals=', 'fotovoltaicoprods').subscribe(resProd => {
          this.form.sections.fotovoltaicoProd = resProd;
        })
      }else{
        this.form.sections.fotovoltaico = new Fotovoltaico();
        this.form.sections.fotovoltaico.ordineId = this.form.idOrder;
      }

    });
  }
  getTranscodifiche(): void {
    this.brandService.getDataByProduct(Number(localStorage.getItem('id_product'))).subscribe(res => {
      this.trascodifiche = res;
      this.getModelloLight()
      this.getModelloFull();
    });
  }

  getModelloFull(): void {
    this.tipologiaFull = undefined;
    this.formController.getTrascodificaByCodicPromise('MODELLO_FOTOVOLTAICO_ARIEL_SUN_FULL_IN_KIT', this.trascodifiche).then(data => {
      this.tipologiaFull = data;

      this.tagliaFull = this.trascodifiche.filter(
        tr => (tr.codice === 'TAGLIA_IMPIANTO') && (tr.parentId === this.tipologiaFull[0].id));

      this.tagliaFull.forEach( (e : any) => {
          let prod:fotovoltaicoProd = new fotovoltaicoProd();
          prod.tagliakwId = e.id;
          prod.tagliakwDescrizione = e.descrizione;
          prod.prodottoId = data[0].id;
          prod.prodottoDescrizione = data[0].descrizione;

          this.batteriaFull  = this.trascodifiche.filter(
            tr => (tr.codice === 'BATTERIA') && (tr.parentId === e.id));
          if(this.batteriaFull && this.batteriaFull.length > 0){
            prod.battkwId = this.batteriaFull[0].id;
            prod.battkwDescrizione = this.batteriaFull[0].descrizione;
          }
          
          this.inverterFull  = this.trascodifiche.filter(
            tr => (tr.codice === 'INVERTER') && (tr.parentId === e.id));

          if(this.inverterFull && this.inverterFull.length > 0){
            prod.inverterkwId = this.inverterFull[0].id;
            prod.inverterkwDescrizione = this.inverterFull[0].descrizione;
          }

          this.potenzaFull  = this.trascodifiche.filter(
            tr => (tr.codice === 'POTENZA MINIMA') && (tr.parentId === e.id));

          if(this.potenzaFull && this.potenzaFull.length > 0){
            prod.potenzakwId = this.potenzaFull[0].id;
            prod.potenzakwDescrizione = this.potenzaFull[0].descrizione;
          }

          this.tipoProdottoFull.push(prod);
      }) 
    });
  }

  getModelloLight(): void {
    this.tipologiaLight = undefined;
    this.formController.getTrascodificaByCodicPromise('MODELLO_FOTOVOLTAICO_ARIEL_SUN_LIGHT_IN_KIT', this.trascodifiche).then(data => {
      this.tipologiaLight = data;

      this.tagliaLight = this.trascodifiche.filter(
        tr => (tr.codice === 'TAGLIA_IMPIANTO') && (tr.parentId === this.tipologiaLight[0].id));

      this.tagliaLight.forEach( (e : any) => {
          let prod:fotovoltaicoProd = new fotovoltaicoProd();
          prod.tagliakwId = e.id;
          prod.tagliakwDescrizione = e.descrizione;
          prod.prodottoId = data[0].id;
          prod.prodottoDescrizione = data[0].descrizione;

          this.batteriaLight  = this.trascodifiche.filter(
            tr => (tr.codice === 'BATTERIA') && (tr.parentId === e.id));
          if(this.batteriaLight && this.batteriaLight.length > 0){
            prod.battkwId = this.batteriaLight[0].id;
            prod.battkwDescrizione = this.batteriaLight[0].descrizione;
          }
          
          this.inverterLight  = this.trascodifiche.filter(
            tr => (tr.codice === 'INVERTER') && (tr.parentId === e.id));

          if(this.inverterLight && this.inverterLight.length > 0){
            prod.inverterkwId = this.inverterLight[0].id;
            prod.inverterkwDescrizione = this.inverterLight[0].descrizione;
          }

          this.potenzaLight  = this.trascodifiche.filter(
            tr => (tr.codice === 'POTENZA MINIMA') && (tr.parentId === e.id));

          if(this.potenzaLight && this.potenzaLight.length > 0){
            prod.potenzakwId = this.potenzaLight[0].id;
            prod.potenzakwDescrizione = this.potenzaLight[0].descrizione;
          }

          this.tipoProdottoLight.push(prod);
      })  
    });
  }

  update(event, id, prod): void {
		if (-1 === this.form.sections.fotovoltaicoProd.findIndex(r => r.tagliakwId === id)) {
			if (event.checked) {
				var newVal = new fotovoltaicoProd();
				newVal.tagliakwId = id;
        newVal.prodottoId = prod.prodottoId;
        newVal.battkwId = prod.battkwId;
        newVal.inverterkwId = prod.inverterkwId;
        newVal.potenzakwId = prod.potenzakwId;
				this.form.sections.fotovoltaicoProd.push(newVal);
			}
		} else {
      var fotoToDelete = this.form.sections.fotovoltaicoProd.filter(tr => (tr.tagliakwId === id));
			this.form.sections.fotovoltaicoToDelete.push(fotoToDelete[0]);
			this.form.sections.fotovoltaicoProd.splice(this.form.sections.fotovoltaicoProd.findIndex(r => r.tagliakwId === id), 1);
		}
	}


	checked(id): string {
		if (-1 !== this.form.sections.fotovoltaicoProd.findIndex(r => r.tagliakwId === id)) {
			return 'checked';
		} else {
			return '';
		}
	}

}
