<div class="text-center {{classBg}}">
    NUOVI TERMOSIFONI E TERMOARREDI
</div>
<div class="{{classContent}}">
    <div class="">
   		<mat-checkbox value="{{term.id}}" class="inline" class="mb-2 mr-5"
			*ngFor="let term of listTerm;let i = index;"
			color="primary" (change)="update($event,term.id)"
			[checked]="checked(term.id)"> {{term.descrizione}} </mat-checkbox>
    </div>

</div>
