import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { constants } from 'src/app/modules/classes/constants';
import { FinanziamentoService } from 'src/app/services/finanziamento/finanziamento.service';
import { Finanziamento } from 'src/app/modules/classes/finanziamento/finanziamento-dto';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
declare var jQuery: any;
@Component({
  selector: 'app-finanziamento',
  templateUrl: './finanziamento.component.html',
  styleUrls: ['./finanziamento.component.scss']
})
export class FinanziamentoComponent implements OnInit {

  @Input() form: any;
  @Input() productColor: string;
  @Input() listFinanziarie: Trascodifica[];
  @Input() pdfDetails: any;
  @Input() idOrder = '';

  pendingFinanziaria: Trascodifica;
  oldFinanziaria: Trascodifica;
  newFinanziaria: Trascodifica;
  deleteFinanziamenento: boolean = false;
  
  finanziariaSelected: Trascodifica;
  readFinanzie: readonly any[] = [
    { name: constants.DESC_FINANZIARIA_SANTANDER, url: 'assets/img/loghi/finanziarie/santander.png' },
    { name: constants.DESC_FINANZIARIA_COFIDIS, url: 'assets/img/loghi/finanziarie/cofidis.png' },
    { name: constants.DESC_FINANZIARIA_COMPASS, url: 'assets/img/loghi/finanziarie/compass.png' }
  ];

  constructor(
    private finanziamentoService: FinanziamentoService,
    private formControlService: FormControllerService
  ) { }

  ngOnInit(): void {
    this.idOrder = this.returnIdOrdine();

      // se torno indietro e ancora non ho salvo i dati, mantengo selezionata la scelta della finanziaria.
      if(this.form.sections.finanziamentoSections.finanziamento && this.form.sections.finanziamentoSections.finanziamento.finanziariaId){
        let trascodifica = this.listFinanziarie.find((el: Trascodifica) => 
          el.id == this.form.sections.finanziamentoSections.finanziamento.finanziariaId
        );
        
        if(trascodifica){
          this.isSelected(trascodifica)
          this.selectFinanziaria(trascodifica)
        }
      }

      if (Array.isArray(this.form.sections.pagamento)) {
        if (this.form.sections.pagamento[1] && this.form.sections.pagamento[1].id) {
          this.form.sections.finanziamentoSections.finanziamento.pagamentoId = this.form.sections.pagamento[1].id;
        } else if (this.form.sections.pagamento[0] && this.form.sections.pagamento[0].id) {
          this.form.sections.finanziamentoSections.finanziamento.pagamentoId = this.form.sections.pagamento[0].id;
        }
      }
    
      if (this.form.sections.finanziamentoSections.finanziamento.pagamentoId) {
        this.finanziamentoService.findByKey(this.form.sections.finanziamentoSections.finanziamento.pagamentoId, 'pagamentoId.equals=').subscribe((res: Finanziamento) => {
          if (res && res[0]?.finanziariaId) {
            this.form.sections.finanziamentoSections.finanziamento = res[0];
            this.finanziariaSelected = this.formControlService.getTrascodificaById(res[0].finanziariaId, this.listFinanziarie);
          }
        });
      } else {
        console.error('Pagamento ID is undefined. Unable to perform search.');
      }
  }

  selectFinanziaria(finanziaria: Trascodifica): void {
      this.deleteFinanziamenento = false;
      if (this.finanziariaSelected && this.finanziariaSelected.id !== finanziaria.id) {
          this.pendingFinanziaria = finanziaria;
          this.oldFinanziaria = this.finanziariaSelected;
          this.newFinanziaria = finanziaria;
          jQuery('#changeFinanziaria').modal({ backdrop: 'static', keyboard: false });
          jQuery('#changeFinanziaria').modal('show');  
      } else {
        this.finanziariaSelected = finanziaria;
        if (this.finanziariaSelected && finanziaria.id) {
          this.form.sections.finanziamentoSections.finanziamento.finanziariaId = this.finanziariaSelected.id;
        }
    }
  }

  returnImgFinanziaria(trascodificaDesc: string): string {
    let element = this.readFinanzie.find((el: any) => el.name.includes(trascodificaDesc));
    return element.url;
  }

  isSelected(finanziaria: Trascodifica): boolean {
    if(this.finanziariaSelected && this.finanziariaSelected.id && finanziaria && finanziaria.id){
      return this.finanziariaSelected.id === finanziaria.id;
    }
    return false;
  }

  confirmChange(): void {
    
    if (this.pendingFinanziaria && this.form.sections.finanziamentoSections.finanziamento.finanziariaId) {
      this.finanziariaSelected = this.pendingFinanziaria;
      this.form.sections.finanziamentoSections.finanziamento.finanziariaId = this.finanziariaSelected.id;
      this.pendingFinanziaria = null;
      this.deleteFinanziamenento = true;  
    }
    jQuery('#changeFinanziaria').modal('hide');
  }

  // close modal
  cancelChange(): void {
    this.pendingFinanziaria = null;
    jQuery('#changeFinanziaria').modal('hide');
  }

  returnIdOrdine(): string {
		return localStorage.getItem('id_order');
	}
}
