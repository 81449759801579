<div class="pt-5 text-center mb-4">
	<!-- <img class="mb-3" alt="logo" title="remail" src="/assets/img/logo-remail.png"> -->
</div>

<div class="text-center mb-4">
	<h4>PREVENTIVI DISPONIBILI</h4>
</div>

<!--<div class="with-border" *ngIf="contracts">-->
<div class="with-border">

	<div class="d-flex align-items-center">
		<div class="flex-grow-1">
		  <mat-form-field class="" appearance="standard">
			<mat-label>Cerca preventivo</mat-label>
			<input matInput (keyup)="applyFilter($event)" #input>
		  </mat-form-field>
		</div>
		<div class="padding15">
		  <button class="btnMedium greenBtn" mat-flat-button (click)="createNewOrder()">
			<span class="material-icons">note_add</span> Nuovo prodotto
		  </button>
		</div>
	  </div>

	<div class="tableCont">
		<mat-progress-bar *ngIf="loading" mode="buffer"></mat-progress-bar>
		<table class="customTable" mat-table [dataSource]="dataSource" matSort matSortActive="dataordine" matSortDirection="desc">

			<ng-container matColumnDef="idOrdine">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Id Preventivo</th>
				<td mat-cell *matCellDef="let element">
					{{element.idordine}}</td>
			</ng-container>

			<ng-container matColumnDef="brandDescrizione">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Brand</th>
				<td mat-cell *matCellDef="let element">
					{{element.brandDescrizione}}</td>
			</ng-container>

			<!-- Name Column -->
			<ng-container matColumnDef="dataordine">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Data</th>
				<td mat-cell *matCellDef="let element">{{element.dataordine |
						date: 'dd/MM/yyyy'}}</td>
			</ng-container>

			<!-- Weight Column -->
			<ng-container matColumnDef="statopraticaDescrizione">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Stato
					pratica</th>
				<td mat-cell *matCellDef="let element">
					<!--  {{element.StatoPratica}} -->
					{{element.statopraticaDescrizione}}
				</td>
			</ng-container>

			<!-- Symbol Column -->
			<ng-container matColumnDef="nomeProdotto" sticky>
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Prodotto</th>
				<td mat-cell *matCellDef="let element">
					<!--  {{element.ProdottoCode}} --> {{element.nomeProdotto}}
				</td>
			</ng-container>

			<ng-container matColumnDef="clienteCf">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					Nominativo Cliente</th>
				<td mat-cell *matCellDef="let element">{{element.cognome}}</td>
			</ng-container>

			<ng-container matColumnDef="actions">
				<th mat-header-cell *matHeaderCellDef class="lastCl"></th>
				<td mat-cell *matCellDef="let element" class="lastCl">
					<button color="primary" class="btnMedium mr-2"
							[disabled]="disabled(element.prodottoCommessa) || disabledByStatoPratica(element.statopraticaCodice)"
							mat-flat-button
							(click)="goToOrder(element.prodottoCommessa,element.clienteId,element.prodottoId,element.id)">
						<span class="material-icons">open_in_new</span> Compila
					</button>
				</td>
			</ng-container>	

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</div>

	<mat-paginator [pageSizeOptions]="[10, 25, 100]"
		aria-label="Select page"></mat-paginator>
</div>
