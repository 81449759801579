import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { MontascaleMisureDto } from 'src/app/modules/classes/zucchetti/montascale/montascaleMisure';

@Injectable({
  providedIn: 'root'
})
export class MontascaleMisureService extends HttpService {
  
  endpoint: string = 'montescalemisures';
  constructor(
    public router: Router,
    public http: HttpClient
  ) {
    super(router, http);
  }

  save(montascaleMisure: MontascaleMisureDto): Observable<any> {			
		return this.http.post<any>(this.apiUrl + this.endpoint, montascaleMisure, this.setHttpOptions()).pipe(
			retry(0),
			catchError(err => this.handleError(err))
		);
	}

  update(montascaleMisure: MontascaleMisureDto): Observable<any> {			
		return this.http.put<any>(this.apiUrl + this.endpoint, montascaleMisure, this.setHttpOptions()).pipe(
			retry(0),
			catchError(err => this.handleError(err))
		);
	}
	
	findByKey(data:any , key:string): Observable<any>{
	    return this.http
           .get<any>(this.apiUrl + this.endpoint+'?'+key+data ,this.setHttpOptions())
           .pipe(
               retry(0),
               catchError(this.handleError)
      );
	}
	
	delete(id: any): Observable<any> {
		return this.http.delete<any>(this.apiUrl+this.endpoint+'/'+id, this.setHttpOptions())
			.pipe(
				retry(0),
				catchError(this.handleError)
			);
	}
}