import { Sections, DatiTecniciDTO, RivestimentiDTO, SanitariDTO, OpereIdraulicheDTO, VascaDTO, NuoviDatiDTO, SingleBox, BoxdocciaDTO, AccessoriDTO, AccessorioBox, Omaggio, OmaggioDTO, Accessori, NuoviAccessoriDTO, Pagamento, PagamentoDTO, GroheDTO, OpereIdraulicheSostDTO, TermoarrediDTO, TermosifoniArredi, NuovaCassettaWc } from '../classes/dati-tecnici';
import { Nuovarubinetteria } from './../classes/dati-tecnici';
import { AfterContentChecked, ChangeDetectorRef, Component, DoCheck, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { AnagraficaService } from '../services/anagrafica/anagrafica.service';
import { BrandService } from '../../../../../services/brand/brand.service';
import { DatiTecniciAttService } from '../services/datiTecniciAtt/datiTecniciAtt.service';
import { RivestimentiService } from '../services/rivestimenti/rivestimenti.service';
import { SanitariService } from '../services/sanitari/sanitari.service';
import { OpereIdraulicheService } from '../services/opereIdrauliche/opereIdrauliche.service';
import { VascheService } from '../services/vasche/vasce.service';
import { NuoviDatiTecniciService } from '../services/nuovi-dati-tecnici/nuovi-dati-tecnici.service';
import { BoxdocciaService } from '../services/boxdoccia/boxdoccia.service';
import { OmaggioService } from '../services/omaggio/omaggio.service';
import { NuoviAccessoriService } from '../services/nuoviAccessori/nuoviAccessori.service';
import { PaymentService } from '../../../../../services/payment/payment.service';
import { OrdersService } from '../../../../../services/orders/orders.service';
import { TermoarrediService } from '../services/termoarredi/termoarredi.service';
import { MainComponent } from '../../../../../main/main.component';
import { Trascodifica } from '../../../../classes/trascodifica';
import { UserService } from '../../../../../services/user/user.service';
import { AnagraficaForm } from '../../../../classes/anagrafica';
import { Ordine } from '../../../../../modules/classes/ordine';
import { OptionsAvailable } from 'src/app/modules/classes/options-available';
import { OptionsAvailableService } from 'src/app/services/optionsAvailable/options-available.service';
import { constants } from 'src/app/modules/classes/constants';
import { AllegatoService } from 'src/app/services/allegato/allegato.service';
import * as $ from 'jquery';
import { forkJoin } from 'rxjs';
declare var jQuery: any;

@Component({
	selector: 'app-bagno',
	templateUrl: './bagno.component.html',
	styleUrls: ['./bagno.component.scss']
})
export class BagnoComponent implements OnInit, DoCheck, AfterContentChecked {

	generatingPdf: boolean = false;
	mobileAttivo: boolean = true;
	confirmBoxDoccia: boolean = false;
	openSign: boolean = false;
	showModalPrint: boolean;
	validAnagrafica: boolean;
	isBackDisabled: boolean = false;
	isFinanziamentoPayment: boolean = false;

	currentStep: number;
	idCliente: number;
	valueProgBar: number = 0;

	datiTecnici: DatiTecniciDTO;
	rivestimenti: RivestimentiDTO;
	sanitari: SanitariDTO;
	opIdrauliche: OpereIdraulicheDTO;
	vasche: VascaDTO;
	nuoviDatiTec: NuoviDatiDTO;
	ordine: Ordine;

	printContent: any;
	form: any;
	formDiffer: any;
	originalForm: any;
	anagrafica: any;
	obj1: any;
	obj2: any;
	error: any;

	totalSteps: number[];
	completedSteps: boolean[] = [];
	trascodifiche: Trascodifica[];
	listFinanziarie: Trascodifica[];
	attivitaLavList: Trascodifica[] = [];
	tipos: Trascodifica[];

	finanziamentoPdfHeader: { logoUrl: string, product: string, brand: string, } = {
		logoUrl: 'assets/img/loghi/logo-bagni-italiani.jpg', product: 'bagni', brand: 'Italiani'
	}

	hasValues = (obj) => Object.values(obj).some(v => v !== null && typeof v !== "undefined");

	constructor(
		public formController: FormControllerService,
		private anagraficaService: AnagraficaService,
		private route: ActivatedRoute,
		private brandService: BrandService,
		private datiTecniciService: DatiTecniciAttService,
		private rivestimentiService: RivestimentiService,
		private sanitariService: SanitariService,
		private opIdraulicheService: OpereIdraulicheService,
		private vascheService: VascheService,
		private nuoviDatiTecService: NuoviDatiTecniciService,
		private boxdocciaService: BoxdocciaService,
		private omaggioService: OmaggioService,
		private nuoviAccessoriService: NuoviAccessoriService,
		private paymentService: PaymentService,
		private orderService: OrdersService,
		private mainCompo: MainComponent,
		private termoarrediService: TermoarrediService,
		public userService: UserService,
		private changeDetector: ChangeDetectorRef,
		private optionService: OptionsAvailableService,
		private allegatoService: AllegatoService
	) {
		this.currentStep = 0;
		this.printContent = '';
		this.validAnagrafica = false;
		this.showModalPrint = false;
		this.form = {
			idOrder: Number(this.route.snapshot.paramMap.get('id')),
			idGdlOrder: "",
			anagrafica: new AnagraficaForm(),
			sections: new Sections(),
			ordine: null,
		};

		this.totalSteps = Array(10).fill(1).map((x, i) => i); // Attenzione, gestione dinamica nel metodo ( setStep() ) della parte relativa al finanziamento, che mostra lo step solo quando viene spuntato nel pagamento.
		this.completedSteps = Array(this.totalSteps.length).fill(false);
	}

	ngAfterContentChecked(): void {
		this.changeDetector.detectChanges();
	}

	private getOrder(idOrder): void {
		this.orderService.getSingleOrder(idOrder).subscribe(res => {
			this.ordine = res;
			this.form.ordine = res;
			localStorage.setItem('id_product', res.prodottoId);

			if (res.clienteId !== undefined && res.clienteId !== null) {
				localStorage.setItem('id_client', res.clienteId);
				this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
			}

			localStorage.setItem('id_order', res.idordine);
		});
		// richiamo il serivizio di trascodifica che mi restituisce i dati per popolare i form
		this.brandService.getDataByProduct(Number(localStorage.getItem('id_product'))).subscribe(res => {
			this.trascodifiche = res;
			this.listFinanziarie = this.trascodifiche.filter((res: Trascodifica) => res.codice.includes(constants.CODICE_NOME_FINANZIARIE) && res.valido == true);
		});
		this.brandService.getDataByCodice(constants.CODICE_FIN_ATTIVITA_LAV).toPromise().then(res => this.attivitaLavList = res);
	}

	ngOnInit(): void {
		this.idCliente = Number(localStorage.getItem('id_client'));
		if (localStorage.getItem('id_client') !== null) {
			this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
		}
		if (this.currentStep > 0) {
			$('.imgModulo').addClass('yesShow');
		} else {
			$('.imgModulo').removeClass('yesShow');
		}
		this.getOrder(this.form.idOrder);
	}

	private getOrdine(): void {
		this.orderService.getSingleOrder(this.form.idOrder).subscribe(res => {
			this.form.anagrafica.infoContratto.consulente = res.venditoreLogin;
			this.form.anagrafica.agente = res.venditoreLogin;
		}, error => {
			console.error('Error => ', error);
		});
	}

	public getAnagrafica(): void {
		// recupero i dati del cliente
		this.anagraficaService.findById(this.form.anagrafica.anagrafica.id).subscribe(res => {
			this.form.anagrafica.anagrafica = res;
		});
		if (this.userService.returnUserAut(constants.TYPE_ROLE_SELLER)) {
			this.form.anagrafica.infoContratto.consulente = this.mainCompo.loggedUser.firstName + ' ' + this.mainCompo.loggedUser.lastName;
			this.form.anagrafica.agente = this.mainCompo.loggedUser.firstName + ' ' + this.mainCompo.loggedUser.lastName;
		}
		if (this.userService.returnUserAut(constants.TYPE_ROLE_TECH)) {
			this.getOrdine();
		}
	}

	ngDoCheck(): void {
		this.obj1 = JSON.stringify(this.originalForm);
		this.obj2 = JSON.stringify(this.form);
		if (this.obj1 !== this.obj2) {
			this.checkAnagrafica();
			this.originalForm = JSON.parse(JSON.stringify(this.form));
		}
	}

	private setStep(num): void {
		this.currentStep = num;
		if (num === 6) {
			this.setPrint();
		}
		if (this.currentStep > 0) {
			$('.imgModulo').addClass('yesShow');
		} else {
			$('.imgModulo').removeClass('yesShow');
		}

		if (this.form.sections.pagamento[0]!.tipopagsaldoId) {
			let tipoSaldo = this.formController.getTrascodificaById(this.form.sections.pagamento[0]!.tipopagsaldoId, this.trascodifiche);
			if (tipoSaldo && tipoSaldo.id && tipoSaldo.descrizione.toUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)) {
				this.isFinanziamentoPayment = true;
				this.totalSteps = Array(10).fill(1).map((x, i) => i);
			} else {
				this.isFinanziamentoPayment = false;
				this.totalSteps = Array(9).fill(1).map((x, i) => i);
			}
		}

		this.scrollTop();
	}

	setBackStep(step: number): void {
	  this.isBackDisabled = true; 
	
	  setTimeout(() => {
		this.isBackDisabled = false;
		this.completedSteps.fill(false, this.currentStep);
	  }, 500);
	
	  this.currentStep = step - 1;
	
	  if (this.currentStep > 0) {
		$('.imgModulo').addClass('yesShow');
	  } else {
		$('.imgModulo').removeClass('yesShow');
	  }
	  
	  this.scrollTop();
	}

	private checkAnagrafica(): boolean {
		this.validAnagrafica = this.formController.checkAnagrafica(this.form);
		return this.validAnagrafica;
	}


	private setPrint(): void {
		this.printContent = document.getElementById('toPrint').innerHTML;
	}

	private scrollTop(): void {
		$('html, body').animate({ scrollTop: 0 }, 300);
	}

	public toggleJson(): void {
		$('.formJson').toggleClass('visibleJson');
	}

	async saveModule(value: boolean): Promise<void> {
		$('body').removeClass('noScr');
		if (document.fullscreenElement) {
			document.exitFullscreen();
		}

		this.completedSteps[this.currentStep] = true;

		if (this.currentStep === 0) {
			// Step salvataggio anagrafica
			this.anagrafica = this.form.anagrafica.anagrafica;
			if (this.anagrafica.id) {
				this.anagraficaService.update(JSON.stringify(this.anagrafica)).subscribe(res => {
					this.ordine.clienteId = res.id;
					this.ordine.cognome = res.cognome;
					this.ordine.nome = res.nome;
					this.ordine.flgManualOrder = false;
					this.orderService.update(JSON.stringify(this.ordine)).subscribe(() => {
						this.setStep(this.currentStep + 1);
					});
				}, error => {
					console.error('Error => ', error);
					this.formController.openSnackBar(constants.ERROR_GENERIC_API);
				});
			} else {
				this.anagraficaService.save(JSON.stringify(this.anagrafica)).subscribe(res => {
					this.ordine.clienteId = res.id;
					this.ordine.cognome = res.cognome;
					this.ordine.nome = res.nome;
					this.form.anagrafica.anagrafica.id = res.id;
					this.ordine.flgManualOrder = false;
					this.orderService.update(JSON.stringify(this.ordine)).subscribe(() => {
						this.setStep(this.currentStep + 1);
					});
				}, error => {
					console.error('Error => ', error);
					this.formController.openSnackBar(constants.ERROR_GENERIC_API);
				});
			}
		} else if (this.currentStep === 1) {

			const promoUpdate$ = this.orderService.update(JSON.stringify(this.form.ordine));

			this.datiTecnici = this.formController.mappingFormToDatiTecniciDTO(
				this.form.sections.datiTecnici,
				new DatiTecniciDTO()
			);

			let datiTecniciSaveOrUpdate$ = null;

			if (this.hasValues(this.datiTecnici)) {
				this.datiTecnici.ordineId = this.form.idOrder;

				if (this.datiTecnici.id === null || this.datiTecnici.id === undefined) {
					datiTecniciSaveOrUpdate$ = this.datiTecniciService.save(JSON.stringify(this.datiTecnici));
				} else {
					datiTecniciSaveOrUpdate$ = this.datiTecniciService.update(JSON.stringify(this.datiTecnici));
				}
			}

			const apiCalls = [promoUpdate$];

			if (datiTecniciSaveOrUpdate$) {
				apiCalls.push(datiTecniciSaveOrUpdate$);
			}

	forkJoin(apiCalls).subscribe({
  		next: (responses) => {
    		console.info('Tutte le chiamate API hanno avuto successo:', responses);
    		this.setStep(this.currentStep + 1);
  		},
  	  	error: (err) => {
    		console.error('Errore durante il salvataggio:', err);
    		this.formController.openSnackBar(constants.ERROR_GENERIC_API);
  	     },
	   });
	}else if (this.currentStep === 2){
		//Step salvataggio rivestimenti
		this.rivestimenti = this.formController.mappingFormToRivestimentiDto(this.form.sections.rivestimenti, new RivestimentiDTO());

			if (this.hasValues(this.rivestimenti)) {
				this.rivestimenti.ordineId = this.form.idOrder;

				if (this.rivestimenti.id === null || this.rivestimenti.id === undefined) {
					this.rivestimentiService.save(JSON.stringify(this.rivestimenti)).subscribe({
						next: (res) => {
							this.setStep(this.currentStep + 1);
						},
						error: (error) => {
							console.error('Error =>', error);
							this.formController.openSnackBar(constants.ERROR_GENERIC_API);
						},
					});
				} else {
					this.rivestimentiService.update(JSON.stringify(this.rivestimenti)).subscribe({
						next: (res) => {
							this.setStep(this.currentStep + 1);
						},
						error: (error) => {
							console.error('Error =>', error);
							this.formController.openSnackBar(constants.ERROR_GENERIC_API);
						},
					});
				}
			} else {
				this.setStep(this.currentStep + 1);
			}
		} else if (this.currentStep === 3) {
			try {
				if (this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.isCliente) {
					const keysToDelete = [
						'descrizione', 'modelloMobileId', 'misuraMobileId', 'baseMobileId', 'cassettiMobileId', 'tipologiaLavaboMobileId', 'specchioMobile',
						'colore', 'numeroVasche', 'vascaMob', 'colonnaPensile', 'quantitaPensili', 'note'
					];
					keysToDelete.forEach(key => {
						delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno[key];
					});
				}

				let saveBoxNuoviSan: boolean | undefined;
				let saveNuovaCassetta: boolean | undefined;
				let saveNuovaRubinetteria: boolean | undefined;

				this.form.sections.optionsAvailable.forEach(option => {
					saveBoxNuoviSan ??= this.searchBool(option, constants.TYPE_NUOVI_SANITARI);
					saveNuovaCassetta ??= this.searchBool(option, constants.TYPE_NUOVA_CASSETTA);
					saveNuovaRubinetteria ??= this.searchBool(option, constants.TYPE_NUOVA_RUBINETTERIA);
				});

				if (!saveNuovaCassetta) {
					this.form.sections.nuoviProdotti.nuovaCassettaWc = new NuovaCassettaWc();
				}
				if (!saveNuovaRubinetteria) {
					this.form.sections.nuoviProdotti.nuovaRubinetteria = new Nuovarubinetteria();
				}

				const sanitariPromises: Promise<void>[] = [];
				this.sanitari = this.formController.mappingFormToSanitariDTO(
					this.form.sections.nuoviProdotti,
					new SanitariDTO(),
					this.form.idOrder
				);

				if (this.hasValues(this.sanitari)) {
					this.sanitari.idordineId = this.form.idOrder;
					if (this.sanitari.id === null || this.sanitari.id === undefined) {
						sanitariPromises.push(
							this.sanitariService.save(JSON.stringify(this.sanitari)).toPromise()
						);
					} else {
						sanitariPromises.push(
							this.sanitariService.update(JSON.stringify(this.sanitari)).toPromise()
						);
					}
				}

				this.form.sections.nuoviSanitariDto.forEach(san => {
					san.ordineId = this.form.idOrder;
					if (!saveBoxNuoviSan) {
						sanitariPromises.push(this.sanitariService.deleteNuoviSan(san.id).toPromise().then(() => { }));
					} else {
						if (san.id === undefined || san.id === null) {
							sanitariPromises.push(this.sanitariService.saveNuoviSan(san).toPromise().then(() => { }));
						} else {
							sanitariPromises.push(this.sanitariService.updateNuoviSan(san).toPromise().then(() => { }));
						}
					}
				});

				await Promise.all(sanitariPromises);

				this.setStep(this.currentStep + 1);
			} catch (error) {
				console.error('Errore durante il salvataggio dei sanitari:', error);
				this.formController.openSnackBar(constants.ERROR_GENERIC_API);
			}
		} else if (this.currentStep === 4) {

			let saveBoxPiattoD: boolean;
			// Salvo o aggiorno l'opzione nuovo piatto doccia per l'ordine
			for (let i = 0; i < this.form.sections.optionsAvailable.length; i++) {
				let option: OptionsAvailable = this.form.sections.optionsAvailable[i];
				if (saveBoxPiattoD === undefined) {
					saveBoxPiattoD = this.searchBool(option, constants.TYPE_NUOVO_PIATTO_DOCCIA);
				}
			}

			const promises: Promise<void>[] = [];

			this.opIdrauliche = this.mappingFormToOpereIdraulicheDto(this.form.sections, new OpereIdraulicheDTO(), saveBoxPiattoD);
			if (this.hasValues(this.opIdrauliche)) {
				this.opIdrauliche.ordineId = this.form.idOrder;
				if (this.opIdrauliche.id === null || this.opIdrauliche.id === undefined) {
					promises.push(
						this.opIdraulicheService.save(JSON.stringify(this.opIdrauliche)).toPromise().then(() => { })
					);
				} else {
					promises.push(
						this.opIdraulicheService.update(JSON.stringify(this.opIdrauliche)).toPromise().then(() => { })
					);
				}
			}

			for (let i = 0; i < this.form.sections.opereIdraulicheSost.length; i++) {
				let opSost: OpereIdraulicheSostDTO = new OpereIdraulicheSostDTO();
				opSost.id = this.form.sections.opereIdraulicheSost[i].id;
				opSost.tipologiaId = this.form.sections.opereIdraulicheSost[i].tipologiaId;
				opSost.ordineId = this.form.idOrder;

				if (opSost.id === undefined || opSost.id === null) {
					promises.push(
						this.opIdraulicheService.saveSost(opSost).toPromise().then(() => { })
					);
				}
			}

			for (let i = 0; i < this.form.sections.opereIdraulicheSostToDelete.length; i++) {
				if (
					this.form.sections.opereIdraulicheSostToDelete[i].id !== undefined &&
					this.form.sections.opereIdraulicheSostToDelete[i].id !== null
				) {
					promises.push(
						this.opIdraulicheService.deleteSost(this.form.sections.opereIdraulicheSostToDelete[i].id).toPromise().then(() => { })
					);
				}
			}

			for (let i = 0; i < this.form.sections.termosifoniArredi.length; i++) {
				let term: TermoarrediDTO = this.mappingFormToTermoarrediDTO(
					this.form.sections.termosifoniArredi[i],
					new TermoarrediDTO()
				);

				if (term.id === undefined || term.id === null) {
					promises.push(this.termoarrediService.save(term).toPromise().then(() => { }));
				}
			}

			for (let i = 0; i < this.form.sections.termosifoniArrediToDelete.length; i++) {
				if (
					this.form.sections.termosifoniArrediToDelete[i].id !== undefined &&
					this.form.sections.termosifoniArrediToDelete[i].id !== null
				) {
					promises.push(
						this.termoarrediService.delete(this.form.sections.termosifoniArrediToDelete[i].id).toPromise().then(() => { })
					);
				}
			}

			try {
				await Promise.all(promises);
				this.setStep(this.currentStep + 1);
			} catch (error) {
				this.formController.openSnackBar(constants.ERROR_GENERIC_API);
			}
		} else if (this.currentStep === 5) {
			//Step salvataggio nuovi box doccia
			this.confirmBoxDoccia = value;
			if (!this.confirmBoxDoccia && !this.checkBoxDoccia()) {
				this.openConfirmBoxDoccia(false);
				return;
			} else {
				this.confirmBoxDoccia = true;
			}

			if (this.confirmBoxDoccia) {
				let saveVasca: boolean;
				let saveBoxDoccia: boolean;
				//Salvo o aggiorno l'opzione vasca per l'ordine
				for (let i = 0; i < this.form.sections.optionsAvailable.length; i++) {
					let option: OptionsAvailable = this.form.sections.optionsAvailable[i];
					if (saveVasca == undefined)
						saveVasca = this.searchBool(option, constants.TYPE_VASCA);

					if (saveBoxDoccia == undefined)
						saveBoxDoccia = this.searchBool(option, constants.TYPE_NUOVO_BOX_DOCCIA);
				}

				const apiCalls: Promise<any>[] = [];

				for (var i = 0; i < this.form.sections.nuoviBox.length; i++) {
					var box: BoxdocciaDTO = this.mappingFormToBoxdocciaDTO(this.form.sections.nuoviBox[i], new BoxdocciaDTO());
					if (!saveBoxDoccia) {
						if (box.id !== undefined && box.id !== null) {
							apiCalls.push(this.boxdocciaService.delete(box.id).toPromise());
						}
					} else {
						if (box.id === undefined || box.id === null) {
							apiCalls.push(this.boxdocciaService.save(JSON.stringify(box)).toPromise());
						} else {
							apiCalls.push(this.boxdocciaService.update(JSON.stringify(box)).toPromise());
						}
					}
				}

				this.vasche = this.mappingFormToVascheDTO(this.form.sections, new VascaDTO());
				if (this.hasValues(this.vasche)) {
					this.vasche.ordineId = this.form.idOrder;
					if (!saveVasca) {
						if (this.vasche.id !== undefined && this.vasche.id !== null)
							this.vascheService.delete(this.vasche.id).toPromise().then(() => {
								this.form.sections.vascaVenezia = {};
								this.form.sections.vascaRoma = {};
								this.form.sections.vascaEcube = {};
								this.form.sections.vascaVerona = {};
								this.form.sections.vascaFirenze = {};
							})
					} else {
						if (this.vasche.id === undefined || this.vasche.id === null) {
							apiCalls.push(this.vascheService.save(JSON.stringify(this.vasche)).toPromise());
						} else {
							apiCalls.push(this.vascheService.update(JSON.stringify(this.vasche)).toPromise());
						}
					}
				}

				this.nuoviDatiTec = this.formController.mappingFormToNuoviDatiTecDTO(this.form.sections.nuoviDati, new NuoviDatiDTO(), this.form.idOrder);

				if (this.hasValues(this.nuoviDatiTec)) {
					this.nuoviDatiTec.ordineId = this.form.idOrder;
					if (this.nuoviDatiTec.id === undefined || this.nuoviDatiTec.id === null) {
						apiCalls.push(this.nuoviDatiTecService.save(JSON.stringify(this.nuoviDatiTec)).toPromise());
					} else {
						apiCalls.push(this.nuoviDatiTecService.update(JSON.stringify(this.nuoviDatiTec)).toPromise());
					}
				}

				for (var i = 0; i < this.form.sections.omaggio.length; i++) {
					var omaggio: OmaggioDTO = this.mappingFormToOmaggioDTO(this.form.sections.omaggio[i], new OmaggioDTO());
					if (omaggio.id === undefined || omaggio.id === null) {
						apiCalls.push(this.omaggioService.save(JSON.stringify(omaggio)).toPromise());
					} else {
						apiCalls.push(this.omaggioService.update(JSON.stringify(omaggio)).toPromise());
					}
				}

				for (var i = 0; i < this.form.sections.nuoviAccessori.length; i++) {
					var nuoviAcc: NuoviAccessoriDTO = this.mappingFormToNuoviAccessoriDTO(this.form.sections.nuoviAccessori[i], new NuoviAccessoriDTO);
					if (nuoviAcc.id === undefined || nuoviAcc.id === null) {
						apiCalls.push(this.nuoviAccessoriService.save(nuoviAcc).toPromise());
					}
				}

				for (var i = 0; i < this.form.sections.nuoviAccessoriToDelete.length; i++) {
					if (this.form.sections.nuoviAccessoriToDelete[i].id !== undefined && this.form.sections.nuoviAccessoriToDelete[i].id !== null) {
						apiCalls.push(this.nuoviAccessoriService.delete(this.form.sections.nuoviAccessoriToDelete[i].id).toPromise());
					}
				}

				try {
					await Promise.all(apiCalls);
					this.setStep(this.currentStep + 1);
				} catch (error) {
					console.error('Errore durante il salvataggio:', error);
					this.formController.openSnackBar(constants.ERROR_GENERIC_API);
				}
			}
		} else if (this.currentStep === 6) {
			//Step salvataggio pagamento
			const hasValues = (obj) => Object.values(obj).some(v => v !== null && typeof v !== "undefined");

			if (hasValues(this.form.sections.pagamento[0])) {
				this.form.sections.pagamento[0].ordineId = this.form.idOrder;
				if (this.form.sections.pagamento[0].id === undefined || this.form.sections.pagamento[0].id === null) {
					this.paymentService.save(JSON.stringify(this.form.sections.pagamento[0])).subscribe((res: any) => {
						this.form.sections.pagamento[0] = res;
						this.setStep(this.currentStep + 1);
					}, error => {
						console.error('Error => ', error);
						this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					});
				} else {
					this.paymentService.update(JSON.stringify(this.form.sections.pagamento[0])).subscribe((res: any) => {
						this.form.sections.pagamento[0] = res;
						this.setStep(this.currentStep + 1);
						// se già presente un allegato finanziamento, ma cambio selezione cancella l'allegato.
						if (res.ordineId && res.tipopagsaldoDescrizione && !res.tipopagsaldoDescrizione.toUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)) {
							this.allegatoService.getAllegati(res.ordineId).subscribe((allegati: any[]) => {
								allegati.forEach((allegato: any) => {
									if (allegato.tipoDescrizione.includes(constants.TYPE_DOC_FINANZIAMENTO_STEP)) {
										this.allegatoService.delete(allegato.id).subscribe(() => {
										}, error => {
											console.error('Error => ', error);
											this.formController.openSnackBar(constants.ERROR_GENERIC_API);
										});
									}
								})
							})
						}
					}, error => {
						console.error('Error => ', error);
						this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					});
				}
			}
		} else if (this.currentStep === 7) {
			//Step salvataggio privacy
			this.orderService.update(JSON.stringify(this.form.ordine)).subscribe(() => {
				this.setStep(this.currentStep + 1);
			}, error => {
				console.error('Error => ', error);
				this.formController.openSnackBar(constants.ERROR_GENERIC_API);
			});
		} else if (this.currentStep === 8) {
			this.anagraficaService.update(JSON.stringify(this.form.anagrafica.anagrafica)).subscribe(res => {
				this.ordine.clienteId = res.id;
				this.ordine.cognome = res.cognome;
				this.ordine.nome = res.nome;
				this.setStep(this.currentStep + 1);
			}, error => {
				console.error('Error => ', error);
				this.formController.openSnackBar(constants.ERROR_GENERIC_API);
			});
		} else if (this.currentStep === 9 && this.form.sections.finanziamentoSections.isAllegatoSaved) {
			// SOLO NEXT-STEP | PARTE FINANZIAMENTO
			this.setStep(this.currentStep + 1);
		}
	}

	public validTecnic(): boolean {
		if (
			this.form.sections.datiTecnici.lavatriceDaMantenere !== undefined &&
			this.form.sections.datiTecnici.lavatriceDaMantenere !== null &&
			this.form.sections.datiTecnici.docciaVasca.tipoBagnoId &&
			this.form.sections.datiTecnici.tipoAbitazioneId &&
			this.form.sections.datiTecnici.dimensioniVano &&
			this.form.sections.datiTecnici.dimensioniVano.lung &&
			this.form.sections.datiTecnici.dimensioniVano.larg &&
			this.form.sections.datiTecnici.dimensioniVano.alt &&
			this.form.sections.datiTecnici.dimensioniPorta.lung &&
			this.form.sections.datiTecnici.dimensioniPorta.alt &&
			this.form.sections.datiTecnici.dimensioniPorta.altPiastrelle &&
			this.form.sections.datiTecnici.misuraTermosifone.lung &&
			this.form.sections.datiTecnici.misuraTermosifone.alt &&
			this.form.sections.datiTecnici.misuraTermosifone.prof &&
			this.form.sections.datiTecnici.misuraTermosifone.incassato !== undefined &&
			this.form.sections.datiTecnici.misuraTermosifone.interasse &&
			this.form.sections.datiTecnici.scaricoWcId &&
			this.form.sections.datiTecnici.muriPerimetraliId &&
			this.form.sections.datiTecnici.tipoRiscaldamentoId &&
			this.form.sections.datiTecnici.tipoimpiantoId
		) {
			return true;
		} else {
			return false;
		}
	}

	private validNuovaCassetta(): boolean {
		if (this.form.sections.nuoviProdotti.nuovaCassettaWc.modelId && this.form.sections.nuoviProdotti.nuovaCassettaWc.modelId == 57) {
			if (this.form.sections.nuoviProdotti.nuovaCassettaWc.placcaColorId && this.form.sections.nuoviProdotti.nuovaCassettaWc.placcaTypeId) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}

	private validVasche(): boolean {
		if (!this.mobileAttivo) {
			if (this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.numeroVasche) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}

	private validNuoviSanitari(): boolean {
		return true;
	}

	private validNuovaRubinetteria(): boolean {
		if (this.form.sections.nuoviProdotti.nuovaRubinetteria.id) {
			let serie = this.trascodifiche.filter(
				tr => tr => (tr.codice === constants.CODICE_SERIE_RUBINETTERIA) && (tr.id === this.form.sections.nuoviProdotti.nuovaRubinetteria.id));
			//verifico se ho selezionato la serie light
			// questa verifica non funziona perchè serie rubinetterai torna qualsiasi serie
			// serieLight.length > 0
			// controllo direttamente id di serie light
			if (this.form.sections.nuoviProdotti.nuovaRubinetteria.id === 1497) {
				//verifico se � stato selezionato il colore
				if (!this.form.sections.nuoviProdotti.nuovaRubinetteria.rubbicolId) {
					return false;
				}
			}
			//check serie grohe

			if (serie !== undefined && serie[0].descrizione === constants.TYPE_DESC_SERIE_GROHE) {

				if (this.form.sections.nuoviProdotti.nuovaRubinetteria.lavabo || this.form.sections.nuoviProdotti.nuovaRubinetteria.bidet
					|| this.form.sections.nuoviProdotti.nuovaRubinetteria.doccia || this.form.sections.nuoviProdotti.nuovaRubinetteria.vasca) {
					return true;
				} else {
					return false;
				}
			}

			if (
				(
					this.form.sections.nuoviProdotti.nuovaRubinetteria.subLavaboId ||
					this.form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId
				)
			) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}

	private validPiattoPietra(): boolean {
		if (this.form.sections.piattoEffettoPietra.coloreId) {
			if (this.form.sections.piattoEffettoPietra.dimensioneId) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}

	public validPiattoStone(): boolean {
		if (this.form.sections.piattoStone.colorepiatstoneId) {
			if (this.form.sections.piattoStone.dimpiattstoneId) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}

	private validPiattoLargo(): boolean {
		if (this.form.sections.piattoDoccia.largo.coloreId) {
			if (this.form.sections.piattoDoccia.largo.dimensioneId) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}

	private validVascaVenezia(): boolean {
		if (this.form.sections.vascaVenezia.dimensions) {
			if (this.form.sections.vascaVenezia.hydro != undefined && this.form.sections.vascaVenezia.hydro != null) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}

	private validVascaRoma(): boolean {
		if (this.form.sections.vascaRoma.dimensions) {
			if (this.form.sections.vascaRoma.dimensions === 107 ||
				this.form.sections.vascaRoma.dimensions === 101 ||
				this.form.sections.vascaRoma.dimensions === 109 ||
				this.form.sections.vascaRoma.dimensions === 110) {
				return true;
			}
			if (this.form.sections.vascaRoma.dimensions === 1337 ||
				this.form.sections.vascaRoma.dimensions === 1339 ||
				this.form.sections.vascaRoma.dimensions === 100 ||
				this.form.sections.vascaRoma.dimensions === 102) {
				if (this.form.sections.vascaRoma.seduta) {
					return true;
				} else {
					return false;
				}
			}
			if (this.form.sections.vascaRoma.dimensions === 1338 ||
				this.form.sections.vascaRoma.dimensions === 1340 ||
				this.form.sections.vascaRoma.dimensions === 103 ||
				this.form.sections.vascaRoma.dimensions === 104 ||
				this.form.sections.vascaRoma.dimensions === 105 ||
				this.form.sections.vascaRoma.dimensions === 106 ||
				this.form.sections.vascaRoma.dimensions === 108) {
				if (this.form.sections.vascaRoma.seduta) {
					return false;
				} else {
					return true;
				}
			}
		} else {
			return true;
		}
	}

	private validVascaVerona(): boolean {
		if (this.form.sections.vascaVerona.dimensions) {
			if (this.form.sections.vascaVerona.scarico && this.form.sections.vascaVerona.sportello && (this.form.sections.vascaVerona.veronaseduta != undefined || this.form.sections.vascaVerona.veronaseduta != null)) {
				if (this.form.sections.vascaVerona.veronaseduta) {
					if (this.form.sections.vascaVerona.seduta) {
						return true;
					} else {
						return false;
					}
				} else {
					return true;
				}

			} else {
				return false;
			}
		} else {
			return true;
		}
	}

	private validVascaFirenze(): boolean {
		if (this.form.sections.vascaFirenze.dimensions) {
			if (this.form.sections.vascaFirenze.sportello && this.form.sections.vascaFirenze.hydro != null && this.form.sections.vascaFirenze.hydro != undefined && this.form.sections.vascaFirenze.misurapassaggioporta) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}

	private validNuoviBox(): boolean {
		let valid = true;
		if (this.form.sections.nuoviBox.length > 0) {
			for (let i = 0; i < this.form.sections.nuoviBox.length; i++) {
				if (valid) {
					if (this.form.sections.nuoviBox[i].modello.id &&
						this.form.sections.nuoviBox[i].cristalli.id &&
						this.form.sections.nuoviBox[i].profilo.id &&
						this.form.sections.nuoviBox[i].larghezza.id
					) {
						valid = true;
					} else {
						valid = false;
					}
				}
			}
		}
		return valid;
	}

	private validNuoviSan(): boolean {
		let valid = true;
		if (this.form.sections.nuoviSanitariDto.length > 0) {
			for (let i = 0; i < this.form.sections.nuoviSanitariDto.length; i++) {
				if (valid) {
					if (this.form.sections.nuoviSanitariDto[i].modsanitariId &&
						this.form.sections.nuoviSanitariDto[i].tiposcaricoId &&
						this.form.sections.nuoviSanitariDto[i].tiposanitariId &&
						this.form.sections.nuoviSanitariDto[i].tipoprodottoId
					) {
						valid = true;
					} else {
						valid = false;
					}
				}
			}
		}
		return valid;
	}

	private validAccessori(): boolean {
		let valid = true;
		if (this.form.sections.accessoriBox.length > 0) {
			for (let i = 0; i < this.form.sections.accessoriBox.length; i++) {
				if (valid) {
					if (this.form.sections.accessoriBox[i].tipologiaId &&
						this.form.sections.accessoriBox[i].quantita
					) {
						valid = true;
					} else if (!this.form.sections.accessoriBox[i].tipologiaId && !this.form.sections.accessoriBox[i].quantita) {
						valid = true;
					} else {
						valid = false;
					}
				}
			}
		}
		return valid;
	}

	//Mapper dati cliente da dto a form
	public someVasca(): boolean {

		let options = this.formController.getTrascodificaByCodice(constants.TYPE_OPTIONS, this.trascodifiche).filter(op => op.descrizione === constants.TYPE_VASCA);

		let visible = this.form.sections.optionsAvailable.filter(op => op.typeId === options[0].id)
		if (visible !== undefined && visible.length > 0 && visible[0].selected) {
			return true;
		} else {
			return false;
		}
	}

	public mappingFormToOpereIdraulicheDto(form: Sections, dto: OpereIdraulicheDTO, savePiatto: boolean): OpereIdraulicheDTO {
		dto.id = form.opereIdrauliche.id;

		if (savePiatto) {
			dto.colorepiateffpietraId = form.piattoEffettoPietra.coloreId;
			dto.dimpiatteffpietraId = form.piattoEffettoPietra.dimensioneId;
			dto.colorepiatstoneId = form.piattoStone.colorepiatstoneId;
			dto.dimpiattstoneId = form.piattoStone.dimpiattstoneId;
			dto.colorepiattolargId = form.piattoDoccia.largo.coloreId;
			dto.misurapiattolargId = form.piattoDoccia.largo.dimensioneId;
			dto.misurapiattostrId = form.piattoDoccia.stretto.id;
		} else {
			dto.colorepiateffpietraId = null;
			dto.dimpiatteffpietraId = null;
			dto.colorepiatstoneId = null;
			dto.dimpiattstoneId = null;
			dto.colorepiattolargId = null;
			dto.misurapiattolargId = null;
			dto.misurapiattostrId = null;
		}

		dto.notelibere = form.noteLibere.value;
		dto.imbsoffitto = form.soffittoPareti.inb;
		dto.necscrostatura = form.soffittoPareti.scr;
		return dto;
	}

	public mappingFormToVascheDTO(form: Sections, dto: VascaDTO): VascaDTO {

		dto.id = form.vascaVenezia.id || form.vascaRoma.id || form.vascaVerona.id || form.vascaFirenze.id || form.vascaEcube.id;
		//venezia 
		dto.veneziamisuraId = form.vascaVenezia.dimensions;
		if (form.vascaVenezia.hydro !== undefined && form.vascaVenezia.hydro !== null) {
			dto.veneziaidro = form.vascaVenezia.hydro;
			dto.venezianoidro = !dto.veneziaidro;
		}

		//ecube
		dto.ecubemisuraId = form.vascaEcube.dimensions;
		if (form.vascaEcube.seduta !== undefined) {
			dto.ecubesedutasx = form.vascaEcube.seduta === constants.TYPE_SEDUTASX ? true : false;
			dto.ecubesedutadx = form.vascaEcube.seduta === constants.TYPE_SEDUTADX ? true : false;
		}

		//roma
		dto.romamisuraId = form.vascaRoma.dimensions;
		if (form.vascaRoma.seduta !== undefined) {
			dto.romasedutasx = form.vascaRoma.seduta === constants.TYPE_SEDUTASX ? true : false;
			dto.romasedutadx = form.vascaRoma.seduta === constants.TYPE_SEDUTADX ? true : false;
		}

		//verona
		dto.veronamisuraId = form.vascaVerona.dimensions;
		if (form.vascaVerona.scarico !== undefined) {
			dto.veronascaricosx = form.vascaVerona.scarico === constants.TYPE_SCARICOSX ? true : false;
			dto.veronascaricodx = form.vascaVerona.scarico === constants.TYPE_SCARICODX ? true : false;
		}

		dto.veronasportelloId = form.vascaVerona.sportello;
		if (this.formController.checkDimensionsVerona(form.vascaVerona.dimensions, this.trascodifiche)) {
			if (form.vascaVerona.seduta !== undefined) {
				dto.veronasedutasx = form.vascaVerona.seduta === constants.TYPE_SEDUTASX ? true : false;
				dto.veronasedutadx = form.vascaVerona.seduta === constants.TYPE_SEDUTADX ? true : false;
				if (dto.veronasedutasx || dto.veronasedutadx) {
					dto.veronaseduta = true;
				} else {
					dto.veronaseduta = false;
				}
			}

		} else {
			dto.veronasedutasx = false;
			dto.veronasedutadx = false;
			dto.veronaseduta = false;
		}

		//firenze 
		dto.firenzemisuraId = form.vascaFirenze.dimensions;
		dto.firenzesportelloId = form.vascaFirenze.sportello;
		dto.firenzepannelloId = form.vascaFirenze.pannello;
		dto.firenzepannellocentro = form.vascaFirenze.firenzepannellocentro;
		dto.firenzepannellodx = form.vascaFirenze.firenzepannellodx;
		dto.firenzepannellosx = form.vascaFirenze.firenzepannellosx;
		dto.firenzepannelloattr = form.vascaFirenze.firenzepannelloattr;
		dto.misurapassaggioporta = form.vascaFirenze.misurapassaggioporta;
		if (form.vascaFirenze.hydro !== undefined && form.vascaFirenze.hydro !== null) {
			dto.firenzeidro = form.vascaFirenze.hydro;
			dto.firenzenoidro = !dto.firenzeidro;
		}
		return dto;
	}

	public mappingFormToBoxdocciaDTO(form: SingleBox, dto: BoxdocciaDTO): BoxdocciaDTO {
		dto.id = form.id
		dto.modelloId = form.modello.id;
		dto.cristalliId = form.cristalli.id;
		dto.profiloId = form.profilo.id;
		dto.larghezzaId = form.larghezza.id;
		dto.ordineId = this.form.idOrder;
		dto.ingressoId = form.ingresso.id;

		return dto;
	}

	public mappingFormToAccessoriDTO(form: AccessorioBox, dto: AccessoriDTO): AccessoriDTO {
		dto.id = form.id;
		dto.ordineId = this.form.idOrder;
		dto.tipologiaId = form.tipologiaId;
		dto.quantita = form.quantita;

		return dto;
	}

	public mappingFormToOmaggioDTO(form: Omaggio, dto: OmaggioDTO): OmaggioDTO {
		dto.id = form.id;
		dto.ordineId = this.form.idOrder;
		dto.tipologiaId = form.tipologiaId;
		dto.quantita = form.quantita;

		return dto;
	}

	public mappingFormToNuoviAccessoriDTO(form: Accessori, dto: NuoviAccessoriDTO): NuoviAccessoriDTO {
		dto.id = form.id;
		dto.ordineId = this.form.idOrder;
		dto.tipologiaId = form.tipologiaId;

		return dto;
	}

	public mappingFormToPagamentoDTO(form: Pagamento, dto: PagamentoDTO): PagamentoDTO {
		dto.id = form.id;
		dto.caparraconf = form.caparraConfirmatoria;
		dto.cessionecredito = form.cessioneCredito;
		dto.percivaId = form.iva;
		dto.totaleordine = form.totaleOrdine;
		dto.totaledovuto = form.totaleDovuto;
		dto.metodopagId = form.tipoPagamento;
		dto.saldoinizlav = form.saldoInizioLavori;
		dto.tipopagsaldoId = form.tipoPagamentoSaldo;
		dto.ordineId = this.form.idOrder;
		return dto;
	}

	public mappingFormToTermoarrediDTO(form: TermosifoniArredi, dto: TermoarrediDTO): TermoarrediDTO {
		dto.id = form.id;
		dto.ordineId = this.form.idOrder;
		dto.tipologiaId = form.tipologiaId;
		return dto;
	}

	public stepController(): boolean {
		let disabled = false;

		if (this.completedSteps[this.currentStep]) {
			return true;
		}

		if (!this.validAnagrafica && this.currentStep === 0) {
			disabled = true;
		}

		if (this.currentStep === 1 && !this.formController.validTecnic(this.form.sections.datiTecnici) || this.formController.checkNoteLength(this.form.sections.datiTecnici.noteTecniche, 255)) {
			disabled = true ;
		}

		if(this.currentStep === 2 && this.formController.checkNoteLength(this.form.sections.rivestimenti.note , 255)){
			disabled = true ;
		}

		if ((!this.validNuovaCassetta() || !this.validNuoviSanitari() || !this.validVasche() || !this.validNuovaRubinetteria() || !this.validMobBagno() || !this.validNuoviSan()) && this.currentStep === 3) {
			disabled = true;
		}

		if ((this.currentStep === 3 && !this.formController.validOption(this.form.sections.optionsAvailable, constants.TYPE_NUOVI_SANITARI, this.trascodifiche))
			|| (this.currentStep === 3 && !this.formController.validOption(this.form.sections.optionsAvailable, constants.TYPE_NUOVA_CASSETTA, this.trascodifiche))
			|| (this.currentStep === 3 && !this.formController.validOption(this.form.sections.optionsAvailable, constants.TYPE_NUOVA_RUBINETTERIA, this.trascodifiche))
			|| this.formController.checkNoteLength(this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.note, 255)) {
			disabled = true;
		}

		if (this.currentStep === 4 && (!this.formController.validOption(this.form.sections.optionsAvailable, constants.TYPE_NUOVO_PIATTO_DOCCIA, this.trascodifiche) || !this.validPiattoPietra() || !this.validPiattoLargo() || this.formController.checkNoteLength(this.form.sections.noteLibere.value, 255))) {
			disabled = true;
		}

		if (this.currentStep === 5 &&
			(!this.formController.validOption(this.form.sections.optionsAvailable, constants.TYPE_VASCA, this.trascodifiche) ||
				!this.formController.validOption(this.form.sections.optionsAvailable, constants.TYPE_NUOVO_BOX_DOCCIA, this.trascodifiche) ||
				!this.formController.validOmaggio(this.form) ||
				!this.formController.validBagniNuoviDati(this.form) ||
				!this.validNuovoBoxDoccia(this.form) ||
				!this.validVascaVenezia() || !this.validVascaRoma() || !this.validVascaVerona()  || !this.validVascaFirenze() || !this.validNuoviBox() || !this.validAccessori()
			)) {
			disabled = true;
		}

		if (this.currentStep === 6 && !this.formController.validPagamento(this.form, false, true)){
			disabled = true;
		}
		
		if (this.currentStep === 7 && !this.formController.validPrivacy(this.form)) {
			disabled = true;
		}

		if (!this.validAnagrafica && this.currentStep === 8) {
			disabled = true;
		}

		if (!this.form.ordine?.promoId && this.currentStep === 1) {
			disabled = true;
		}

		if (this.currentStep === 9) {
			disabled = this.formController.validStepFinanziamento(this.form, this.attivitaLavList, this.listFinanziarie);
		}
		return disabled;
	}

	public toggleChange(event): void {
		this.mobileAttivo = event;
	}

	private validMobBagno(): boolean {
		if (!this.mobileAttivo) {
			if ((this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile && this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile !== 53)) {
				if (!this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.quantitaPensili || this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.quantitaPensili === 0) {
					return false;
				} else {
					return true;
				}
			} else {
				return true;
			}
		} else {
			return true;
		}
	}


	public openConfirmBoxDoccia(value): void {
		this.confirmBoxDoccia = value;
		jQuery('#confirmModal').modal({ backdrop: 'static', keyboard: false });
		jQuery('#confirmModal').modal('toggle');
		if (this.confirmBoxDoccia) {
			this.saveModule(true);
		}
	}

	private checkBoxDoccia(): boolean {
		let result = true;
		let cristalliTmp = "";
		let profiliTmp = "";
		if (this.form.sections.nuoviBox.length > 1) {
			this.form.sections.nuoviBox.forEach(r => {
				if (cristalliTmp !== "" && cristalliTmp !== r.cristalli.descr) {
					result = false;
				}

				if (profiliTmp !== "" && profiliTmp !== r.profilo.descr) {
					result = false;
				}
				cristalliTmp = r.cristalli.descr;
				profiliTmp = r.profilo.descr;
			});
		}
		return result;
	}

	private validNuovoBoxDoccia(form: any): boolean {
		let valid = true;
		if (form.sections.nuoviBox.length > 0) {
			for (let i = 0; i < form.sections.nuoviBox.length; i++) {

				if (this.form.sections.nuoviBox[i].modello.id &&
					this.form.sections.nuoviBox[i].cristalli.id &&
					this.form.sections.nuoviBox[i].profilo.id &&
					this.form.sections.nuoviBox[i].larghezza.id &&
					this.form.sections.nuoviBox[i].ingresso.id
				) {
					valid = true;
				} else {
					valid = false;
				}
			}
		}
		return valid;
	}

	private searchBool(option: any, type: any) {

		let optionTrascodifica = this.formController.getTrascodificaByCodice(constants.TYPE_OPTIONS, this.trascodifiche).filter(op => (op.descrizione === type));

		if (optionTrascodifica.find((res: any) => res.id === option.typeId)) {
			if (option.id) {
				//update option
				this.optionService.update(option).subscribe();
			} else {
				//insert option
				this.optionService.save(option).subscribe();
			}

			if (option.selected == true) {
				return true;

			} else if (option.selected == false) {
				return false;
			}
		}
	}

	public returnIdOrdine(): string {
		return localStorage.getItem('id_order');
	}

	public togglePrintPreview(show: boolean): void {
		this.showModalPrint = show;
		jQuery('#printModal').modal({ backdrop: 'static', keyboard: false });
		jQuery('#printModal').modal('toggle');
	}

	public closeSignPdf() {
		jQuery('#signModal').modal({ backdrop: 'static', keyboard: false });
		jQuery('#signModal').modal('toggle');
	}

	edit() {
		this.currentStep = 0;
		this.openSign = false;
		this.completedSteps = Array(this.totalSteps.length).fill(false);
		this.orderService.getSingleOrder(this.form.idOrder).subscribe((res) => {
			res.statopraticaCodice = "A";
			res.statopraticaId = 2;
			this.orderService.update(JSON.stringify(res)).subscribe(res => {
				this.getOrder(this.form.idOrder);
			});
		})
	}

	public reload(value: boolean) {
		this.openSign = value;
	}
}
