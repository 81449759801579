import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { DatiTecniciAttService } from '../../../bagni-italiani/main-bagni/services/datiTecniciAtt/datiTecniciAtt.service';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { constants } from 'src/app/modules/classes/constants';
@Component({
  selector: 'app-dati-tecnici-attuali',
  templateUrl: './dati-tecnici-attuali.component.html',
  styleUrls: ['./dati-tecnici-attuali.component.scss']
})
export class DatiTecniciAttualiComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche:Trascodifica[];
  tipiAbitazione:Trascodifica[];

  constructor( private datiTecniciService: DatiTecniciAttService,
               private formController: FormControllerService
             ) { }

  ngOnInit(): void {
    this.datiTecniciService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {			
			this.form.sections.datiTecnici = this.formController.mappingDatiTecniciDTOtoForm(res[0],this.form.sections.datiTecnici, this.form.idOrder);
			this.tipiAbitazione = this.formController.getTrascodificaByCodice(constants.COD_TIPO_ABITAZIONE ,  this.trascodifiche);
	});
 }
}
