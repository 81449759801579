export class DisegnocaldaiaDTO {
	altezza: number;
	combustibileId: number;
	id: number;
	larghezza: number;
	numtermo: number;
	ordineId: number;
	profondita: number;
	tipoId: number;
	tipoDescrizione:string;
}
