import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllarmiComponent } from './allarmi/allarmi.component';
import { AccessoriAllarmeComponent } from './allarmi/accessori-allarme/accessori-allarme.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PaintableModule } from 'paintablejs/angular';
import { MaterialModule } from '../../material/material.module';
import { SharedModule } from '../../shared/shared.module';
import { PrintAllarmeComponent } from './allarmi/print-allarme/print-allarme.component';
import { SafePipe } from 'src/app/safe.pipe';
import { ModelloComponent } from './allarmi/modello/modello.component';



@NgModule({
  declarations: [AllarmiComponent, AccessoriAllarmeComponent, PrintAllarmeComponent, ModelloComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MaterialModule,
    PaintableModule,
    SharedModule,
  ],
  providers: [
    SafePipe,
  ]
})
export class MondialModule { }
