import { Component, OnInit,Input } from '@angular/core';
import { Trascodifica } from '../../../../../classes/trascodifica';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';
import { RemailService } from '../../../services/remail.service';
import { constants } from 'src/app/modules/classes/constants';
@Component({
  selector: 'app-gres',
  templateUrl: './gres.component.html',
  styleUrls: ['./gres.component.scss']
})
export class GresComponent implements OnInit {

  @Input() form:any;
  @Input() trascodifiche:Trascodifica[];
  @Input() brand:string;

  listAltezzaParete:Trascodifica[];
  listPavimento:Trascodifica[];

  constructor(
    private formController: FormControllerService,
    private remailService : RemailService
  ) { }

  ngOnInit(): void {
		
		this.remailService.findBykey('gres' , 'ordineId.equals=',this.form.idOrder ,).subscribe(res => {
			if(res[0] !== undefined){
				this.form.sections.gres = res[0];
			}
		});
		
		this.listAltezzaParete = this.formController.getTrascodificaByCodice( constants.COD_GRES_ALTEZZA_PARETE,this.trascodifiche);
		this.listPavimento = this.formController.getTrascodificaByCodice( constants.COD_GRES_PAVIMENTO , this.trascodifiche);
  }
}
