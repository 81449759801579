import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnagraficaForm } from 'src/app/modules/classes/anagrafica';
import { PagamentoDTO } from '../../../bagni-italiani/main-bagni/classes/dati-tecnici';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { Ordine } from 'src/app/modules/classes/ordine';
import { BrandService } from 'src/app/services/brand/brand.service';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { FinAnagrafica, Finanziamento, FinDatiOccupazione } from 'src/app/modules/classes/finanziamento/finanziamento-dto';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { AnagraficaDTO } from '../../../bagni-italiani/main-bagni/classes/anagrafica';
import { AnagraficaService } from '../../../bagni-italiani/main-bagni/services/anagrafica/anagrafica.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { fotovoltaicoProd } from '../classes/fotovltaicoprod.model';
import { FotovoltaicoService } from 'src/app/services/fotovoltaico/fotovoltaico.service';
import { Fotovoltaico } from '../classes/fotovoltaico-dto.model';
import { constants } from 'src/app/modules/classes/constants';
import * as $ from 'jquery';
import { AllegatoService } from 'src/app/services/allegato/allegato.service';
declare var jQuery: any;

@Component({
  selector: 'app-fotovoltaico',
  templateUrl: './fotovoltaico.component.html',
  styleUrls: ['./fotovoltaico.component.scss']
})
export class FotovoltaicoComponent implements OnInit {
  
  generatingPdf:boolean = false;
  openSign: boolean = false;
  showFrame: boolean = false;
  sendingPdf: boolean = false;
  showModalPrint: boolean = false;
  validAnagrafica: boolean = false;
  confirmPannelli: boolean = false;
  isFinanziamentoPayment: boolean = false;

  idCliente: number;
  currentPrinting: number = 0;
  valueProgBar:number = 0;
  currentStep:number = 0;
  
  urlFrame:string = "";

  form: any;
  obj1: any;
  obj2: any;
  error: any;
  pdf: any;
  member: any;
  originalForm: any;
  
  ordine: Ordine;
  anagrafica: AnagraficaDTO;

  trascodifiche: Trascodifica[];
  listFinanziarie: Trascodifica[];
  attivitaLavList: Trascodifica[] = [];

  totalSteps: number[];

  finanziamentoPdfHeader: { logoUrl: string, product: string, brand: string, } = {
		logoUrl: 'assets/img/loghi/ARIEL.jpg', product: 'fotovoltaico', brand: 'Ariel'}

  hasValues = (obj) => Object.values(obj).some(v => v !== null && typeof v !== "undefined");
  integratedSignature = environment.integratedSignature;
  
  constructor(
    private route: ActivatedRoute,
    private brandService: BrandService,
    private orderService: OrdersService,
    public formController: FormControllerService,
    private anagraficaService: AnagraficaService,
    private paymentService: PaymentService,
    private snackbar: MatSnackBar,
    private fotovoltaicoService: FotovoltaicoService,
    private allegatoService: AllegatoService
  ) {
    this.validAnagrafica = false;
    this.form = {
      idOrder: Number(this.route.snapshot.paramMap.get('id')),
      anagrafica: new AnagraficaForm(),
      sections: {
        fotovoltaico:Fotovoltaico,
        fotovoltaicoProd: [],
        fotovoltaicoToDelete: [],
        pagamento: [new PagamentoDTO()],
				finanziamentoSections: {
					finanziamento: new Finanziamento(),
					finAnagrafica: new FinAnagrafica(),
					finDatiOccupazione: new FinDatiOccupazione(),
					finAnagraficaGarante: new FinAnagrafica(),
					finDatiOccupazioneGarante: new FinDatiOccupazione(),
					isAllegatoSaved: false
				},
      },
      ordine: null
    }

    this.totalSteps = Array(6).fill(1).map((x, i) => i); // [0,1,2,3,4]
  }

  ngOnInit(): void {
    this.originalForm = JSON.parse(JSON.stringify(this.form));
    if (localStorage.getItem('id_client') !== null) {
      this.idCliente = Number(localStorage.getItem('id_client'));
      this.form.anagrafica.anagrafica.id = this.idCliente;
    }

    if (this.currentStep > 0) {
      $('.imgModulo').addClass('yesShow');
    } else {
      $('.imgModulo').removeClass('yesShow');
    }

    this.getOrder(this.form.idOrder);
  }

  getOrder(idOrder): void {
    this.orderService.getSingleOrder(idOrder).subscribe(res => {
      this.ordine = res;
      this.form.ordine = res;
      localStorage.setItem('id_product', res.prodottoId);

      if (res.clienteId !== undefined && res.clienteId !== null) {
        localStorage.setItem('id_client', res.clienteId);
        this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
      }

      localStorage.setItem('id_order', res.idordine);
    });

    this.brandService.getDataByProduct(Number(localStorage.getItem('id_product'))).subscribe(res => {
      this.trascodifiche = res;
      this.listFinanziarie = this.trascodifiche.filter((res: Trascodifica) => res.codice.includes(constants.CODICE_NOME_FINANZIARIE) && res.valido == true);
    });
    this.brandService.getDataByCodice(constants.CODICE_FIN_ATTIVITA_LAV).toPromise().then(res => this.attivitaLavList = res);
  }

  ngDoCheck(): void {
    this.obj1 = JSON.stringify(this.originalForm);
    this.obj2 = JSON.stringify(this.form);
    if (this.obj1 !== this.obj2) {
      this.checkAnagrafica();
      this.originalForm = JSON.parse(JSON.stringify(this.form));
    }
  }


  ngAfterViewInit(): void {
    console.log('origina form', this.originalForm);
  }

  stepController(): boolean {
    switch (this.currentStep) {
        case 0:
        case 4:
            return this.generatingPdf || !this.validAnagrafica;
        case 2:
            return this.generatingPdf || !this.formController.validPagamento(this.form);
        case 3:
            return this.generatingPdf || !this.validPrivacy();
        case 5:
          return this.formController.validStepFinanziamento(this.form, this.attivitaLavList, this.listFinanziarie );
        default:
          return this.generatingPdf;
        }
  }

  setStep(num): void {
    this.currentStep = num;
    if (this.currentStep > 0) {
      $('.imgModulo').addClass('yesShow');
    } else {
      $('.imgModulo').removeClass('yesShow');
    }

    if(this.form.sections.pagamento[0]!.tipopagsaldoId){
			let tipoSaldo = this.formController.getTrascodificaById(this.form.sections.pagamento[0]!.tipopagsaldoId, this.trascodifiche);
			if( tipoSaldo && tipoSaldo.id && tipoSaldo.descrizione.toLocaleUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)){
				this.isFinanziamentoPayment = true;
				this.totalSteps = Array(6).fill(1).map((x, i) => i);
			}else {
				this.isFinanziamentoPayment = false;
				this.totalSteps = Array(5).fill(1).map((x, i) => i);
			}
		}

    this.scrollTop();
  }

  checkAnagrafica(): boolean {
    this.validAnagrafica = this.formController.checkAnagrafica(this.form);
    return this.validAnagrafica;
  }

  scrollTop(): void {
    $('html, body').animate({ scrollTop: 0 }, 300);
  }


  saveModule(value): void {
    $('body').removeClass('noScr');
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }

    if (this.currentStep === 0) {
      // Step salvataggio anagrafica
      this.anagrafica = this.form.anagrafica.anagrafica;
      if (this.anagrafica.id) {
        this.anagraficaService.update(JSON.stringify(this.anagrafica)).subscribe(res => {
          this.ordine.clienteId = res.id;
          this.ordine.cognome = res.cognome;
          this.ordine.nome = res.nome;
          this.orderService.update(JSON.stringify(this.ordine)).subscribe();
        }, error => {
          console.error('Error => ', error);
          this.openSnackBar('Errore in fase di salvataggio del modulo');
        });
      } else {
        this.anagraficaService.save(JSON.stringify(this.anagrafica)).subscribe(res => {
          this.ordine.clienteId = res.id;
          this.ordine.cognome = res.cognome;
          this.ordine.nome = res.nome;
          this.form.anagrafica.anagrafica.id = res.id;
          this.orderService.update(JSON.stringify(this.ordine)).subscribe();
        }, error => {
          console.error('Error => ', error);
          this.openSnackBar('Errore in fase di salvataggio del modulo');
        });
      }
    } else if (this.currentStep === 1) {
      this.confirmPannelli = value;
			if (!this.confirmPannelli && !this.notEvenFotovoltaicoSelected()){
				this.openConfirmPannelli(false);
			} else {
				this.confirmPannelli = true;
			}
      if(!this.confirmPannelli){
        this.currentStep -= 1;
      } 

      if(this.form.sections.fotovoltaico.id === undefined || this.form.sections.fotovoltaico.id === null){
        this.fotovoltaicoService.save(this.form.sections.fotovoltaico).subscribe(res => {
          this.form.sections.fotovoltaico.id = res.id;
          this.manageProdFotovoltaico();
        });
      }else{
        this.fotovoltaicoService.update(this.form.sections.fotovoltaico).subscribe();
        this.manageProdFotovoltaico();
      }
    } else if (this.currentStep === 2) {
      // Step salvataggio pagamento
      if (this.hasValues(this.form.sections.pagamento[0])) {
        this.form.sections.pagamento[0].ordineId = this.form.idOrder;
        const payment = this.form.sections.pagamento[0];
        if (payment.id === undefined || payment.id === null) {
          this.paymentService.save(JSON.stringify(payment)).subscribe((res: any) => {
						this.form.sections.pagamento[0] = res;
					});
        } else {
          this.paymentService.update(JSON.stringify(payment)).subscribe((res: any) => {
						this.form.sections.pagamento[0] = res;
            // se già presente un allegato finanziamento, ma cambio selezione cancella l'allegato.
            if(res.ordineId && res.tipopagsaldoDescrizione && !res.tipopagsaldoDescrizione.toUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)){
              this.allegatoService.getAllegati(res.ordineId).subscribe((allegati: any[]) => {
                allegati.forEach((allegato:any) => {
                  if(allegato.tipoDescrizione.includes(constants.TYPE_DOC_FINANZIAMENTO_STEP)){
                    this.allegatoService.delete(allegato.id).subscribe();
                  }
                })
              })
            }
					});
        }
      }
    } else if (this.currentStep === 3) {
      //Step salvataggio privacy
      this.orderService.update(JSON.stringify(this.form.ordine)).subscribe();
    } else if (this.currentStep === 4) {
      this.anagraficaService.update(JSON.stringify(this.form.anagrafica.anagrafica)).subscribe(res => {
        this.ordine.clienteId = res.id;
        this.ordine.cognome = res.cognome;
        this.ordine.nome = res.nome;
      }, error => {
        console.error('Error => ', error);
        this.openSnackBar('Errore in fase di salvataggio del modulo');
      });
    }

    this.setStep(this.currentStep + 1);
  }

  openSnackBar(text): void {
    this.snackbar.open(text, 'Ok', {
      duration: 4000
    });
  }

  validPrivacy(): boolean {
   if(this.form.ordine === undefined || this.form.ordine === null){
    return false;
   } 
    if (!this.form.ordine.accettprivacy || !this.form.ordine.accettprivacynd || !this.form.ordine.accettprivacyrd) {
      return false;
    } else
      return true;
  }

  returnIdOrdine(): string {
    return localStorage.getItem('id_order');
  }

  togglePrintPreview(show: boolean): void {
    this.showModalPrint = show;
    jQuery('#printModal').modal({ backdrop: 'static', keyboard: false });
    jQuery('#printModal').modal('toggle');
  }

  closeSignPdf(): void {
    jQuery('#signModal').modal({ backdrop: 'static', keyboard: false });
    jQuery('#signModal').modal('toggle');
  }

  manageProdFotovoltaico (){
      //Step salvataggio prodotti
      for (var i = 0; i < this.form.sections.fotovoltaicoProd.length; i++) {
        let fotovoltaicoProd: fotovoltaicoProd = this.form.sections.fotovoltaicoProd[i];
        fotovoltaicoProd.fotovoltaicoId = this.form.sections.fotovoltaico.id;
        if (fotovoltaicoProd.id === undefined || fotovoltaicoProd.id === null) {
          this.fotovoltaicoService.saveProd(fotovoltaicoProd).subscribe();
        }
      }

      if(this.confirmPannelli){
      //Step delete unchecked prodotti
      for (var i = 0; i < this.form.sections.fotovoltaicoToDelete.length; i++) {
        if (this.form.sections.fotovoltaicoToDelete[i].id !== undefined && this.form.sections.fotovoltaicoToDelete[i].id !== null) {
          this.fotovoltaicoService.deleteProd(this.form.sections.fotovoltaicoToDelete[i].id).subscribe(value => {
            this.form.sections.fotovoltaicoToDelete = [];
          });
        }
      }
    }
  }

  notEvenFotovoltaicoSelected(): boolean {
    return this.form.sections.fotovoltaicoProd.length;
  }

  openConfirmPannelli(value): void{
    
		this.confirmPannelli = value;
		jQuery('#confirmModal').modal({backdrop: 'static', keyboard: false});
		jQuery('#confirmModal').modal('toggle');
		if (this.confirmPannelli) {
			this.saveModule(true);
		}
	}

	edit() {
		this.currentStep = 0;
		this.openSign = false;
		this.orderService.getSingleOrder(this.form.idOrder).subscribe((res) => {
			res.statopraticaCodice = "A";
			res.statopraticaId = 2;
			this.orderService.update(JSON.stringify(res)).subscribe( res => {
				this.getOrder(this.form.idOrder);
			});
		})
	}

  reload(value: boolean) {
    this.openSign = value;
  }
}

