<div class="text-center {{classBg}}">
    OPERE IDRAULICHE
</div>
<div class="{{classContent}}">
    <div class="">
		<mat-checkbox value="{{sostOp.id}}" class="inline" class="mb-2 mr-2"
			*ngFor="let sostOp of sostOpIdr;let i = index;"
			color="primary" (change)="update($event,sostOp.id)"
			[checked]="checked(sostOp.id)"> {{sostOp.descrizione}} </mat-checkbox>
	</div>

</div>
