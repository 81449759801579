import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from '../../../../classes/trascodifica';
import { TermosifoniArredi } from '../classes/dati-tecnici';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { TermoarrediService } from '../services/termoarredi/termoarredi.service';
import { constants } from 'src/app/modules/classes/constants';
@Component({
	selector: 'app-form-nuovi-termosifoni-arredi',
	templateUrl: './form-nuovi-termosifoni-arredi.component.html',
	styleUrls: ['./form-nuovi-termosifoni-arredi.component.scss']
})
export class FormNuoviTermosifoniArrediComponent implements OnInit {

	@Input() form: any;
	@Input() trascodifiche: Trascodifica[];
	@Input() brand: string;

	listTerm: Trascodifica[];
	classBg = "blueBg";
	classContent = "blueCont";

	constructor(
		private formController: FormControllerService,
		private termoarrediService: TermoarrediService
	) { }

	ngOnInit(): void {
		if(this.brand === 'bagno-pratico'){	
			this.classBg = "bagnoPraticoBg";
			this.classContent = "bagnoPraticoCont";
		}
		this.form.sections.termosifoniArredi = [];
		this.form.sections.termosifoniArrediToDelete = [];


		this.termoarrediService.findByKey(this.form.idOrder, 'ordineId.equals=').subscribe(res => {
			for (var i = 0; i < res.length; i++) {
				const op = this.formController.mappingTermoarrediDTOtoForm(res[i], new TermosifoniArredi());
				this.form.sections.termosifoniArredi.push(op);
			}

			this.listTerm = this.formController.getTrascodificaByCodice(constants.COD_TIPO_INTERV_TERMO, this.trascodifiche);
		})
	}

	update(event, id): void {
		if (-1 === this.form.sections.termosifoniArredi.findIndex(r => r.tipologiaId === id)) {
			if (event.checked) {
				var newVal = new TermosifoniArredi();
				newVal.tipologiaId = id;
				this.form.sections.termosifoniArredi.push(newVal);
			}
		} else {
			var toDelete = this.form.sections.termosifoniArredi.filter(tr => (tr.tipologiaId === id));
			this.form.sections.termosifoniArrediToDelete.push(toDelete[0]);
			this.form.sections.termosifoniArredi.splice(this.form.sections.termosifoniArredi.findIndex(r => r.tipologiaId === id), 1);
		}
	}

	checked(id): string {
		if (-1 !== this.form.sections.termosifoniArredi.findIndex(r => r.tipologiaId === id)) {
			return 'checked';
		} else {
			return '';
		}
	}
}
