export class GresDto {	
	altezzapareteDescrizione: string;
	altezzapareteId: number;
	id: number;
	ordineId: number;
	ordineIdordine:	string;
	pavimentoDescrizione:	string;
	pavimentoId: number;
	tipo: string;
	tipopavimento: string;
	largvano:number;
    lungvano:number;
    formatopiastrelle:string;
    formatopareti:string;
}
