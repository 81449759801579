export class Finanziamento{
	id:number;
    iban:string;
    prezzotot: number;
    acconto: number;
    residuo: number;
    codicetabella: string;
    numrate: number;
    importorata: number;
    importoFinanziaria: number;
    tan: number;
    taeg: number;
    finanziariaId:number;
    finanziariaDescrizione?:string;
    pagamentoId?:number;
    bollettino?:boolean;
}

export class FinAnagrafica{
    
    id:number;
    garante: boolean | false;
    cognome: string;
    nome: string;
    codicefiscale: string;
    sesso: string;
    datanascita: Date;
    comunenascita: string;
    via: string;
    civico: string;
    comune: string;
    cap: string;
    provincia: string;
    viaprec: string;
    civicoprec: string;
    comuneprec:string;
    capprec: string;
    provinciaprec: string;
    viadomic: string;
    civicodomic: string;
    comunedomic: string;
    capdomic: string;
    provinciadomic: string;
    tel: string;
    telnd: string;
    email: string;
    altrorecapito: string;
    altracitt: string;
    tipologiaextraue: boolean;
    soggionoinizio: Date;
    soggionoscadenza: Date;
    statocivileId?:number;
    statocivileDescrizione?:string;
    tipoabitazioneId?:number;
    tipoabitazioneDescrizione?:string;
    tipologiacittadId?:number;
    tipologiacittadDescrizione?:string;
    finanziamentoId?:number;
    finanziamentoIban: string;

    constructor() {
        this.garante = false;
    }
}

export class FinDatiOccupazione{
    id:number;
    garante: boolean | false;
    attivitalavId:number;
    attivitalavDescrizione?:string;
    datainizioocc: Date;
    tipoAssunzioneId?:number;
    tipoAssunzioneDescrizione?: string;
    ragionesocialedatore: string;
    via: string;
    civico: string;
    comune: string;
    cap: string;
    provincia: string;
    telefono: string;
    tipologiaredditoId?:number;
    tipologiaredditoDescrizione?:string;
    nummensilita: number;
    totalereddito: number;
    finanziamentoId?:number;

    constructor() {
        this.garante = false;
    }
}
