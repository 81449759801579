import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Trascodifica } from '../../../../../classes/trascodifica';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';
import { RemailService } from '../../../services/remail.service';

@Component({
  selector: 'app-nuovidatitecnici',
  templateUrl: './nuovidatitecnici.component.html',
  styleUrls: ['./nuovidatitecnici.component.scss']
})
export class NuovidatitecniciComponent implements OnInit {
  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  @Input() brand:string;
  constructor
  (
      private formController: FormControllerService,
      private remailServ: RemailService,
  ) { }

  ngOnInit(): void {
    this.getVasca();
  }
  
  getVasca(): void {
    this.remailServ.getNuoviDatiTecVasca(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
      if (res[0]){
        this.form.sections.nuovidatitecnici = res[0];
      }
    });
  }

  setMuroSx(event): void {
    this.form.sections.nuovidatitecnici.murosx = event.checked;
    /*if (this.form.sections.nuovidatitecnici.murosx) {
      this.form.sections.nuovidatitecnici.murodx = false;
    }*/
  }
  setMuroDx(event): void {
    this.form.sections.nuovidatitecnici.murodx = event.checked;
    /*if (this.form.sections.nuovidatitecnici.murodx) {
      this.form.sections.nuovidatitecnici.murosx = false;
    }*/
  }

}
