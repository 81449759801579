export const constants = {

  BRAND_CRISTALBOX: 'cristalbox',
  BRAND_ZUCCHETTI: 'zucchetti',
  BRAND_ARIEL: 'ARIEL',
  BRAND_LOREN: 'LOREN',

  PRODUCT_CALDAIA: 'caldaia',
  
  COMMESSA_BAGNO_PRATICO: 'SFA-T00178',
  COMMESSA_ARIEL_FOTOVOLTAICO_PREVENTIVO: "SFA-T00177-UT",
  COMMESSA_ARIEL_FOTOVOLTAICO: 'SFA-T00177',
  COMMESSA_BAGNI_ITALIANI: "SFA-T00055",
  COMMESSA_ARIEL_STUFA_PELLET: "SFA-T00067",
  COMMESSA_ARIEL_CALDAIE: 'SFA-T00050',
  COMMESSA_ARIEL_CONDIZIONATORI: 'SFA-T00065',
  COMMESSA_REMAIL_VASCA_IN_DOCCIA: 'SFA-T00052',
  COMMESSA_REMAIL_BAGNI_ITALIANI_HELP_75: 'SFA-T00169',
  COMMESSA_ZUCCHETTI_MONTASCALE: 'SFA-T00181',
  COMMESSA_ARIEL_DEPURATORE: 'SFA-T00182',
  COMMESSA_CB_VASCA_IN_DOCCIA: 'SFA-T00186',
  COMMESSA_LOREN_CUCINE: 'SFA-T00069',
  COMMESSA_ARIEL_IBRID: 'SFA-T00179',
  COMMESSA_ZUCCHETTI_POLTRONE: 'SFA-T00058',

  STATO_PRATICA_FIRMATO: 'F',
  TYPE_TIPOPRODOTTOSISTEMA_AURUM: 'TIPOPRODOTTOSISTEMA_AURUM',
  TYPE_TIPOPRODOTTOSISTEMA_IBRID: 'TIPOPRODOTTOSISTEMA_IBRID',
  TYPE_TIPOPRODOTTOSISTEMA_SLIM_AURUM: 'TIPOPRODOTTOSISTEMA_SLIM_AURUM',
  TYPE_TIPOPRODOTTOSISTEMA_P_FULL: 'TIPOPRODOTTOSISTEMA_P_FULL',
  TYPE_TIPOPRODOTTOSISTEMA_POWER: 'TIPOPRODOTTOSISTEMA_POWER',
  TYPE_TIPOLOGIADESCRIZIONE_ARIEL_HYBRID_ARUM: 'ARIEL HYBRID AURUM',
  TYPE_TIPOLOGIADESCRIZIONE_ARIEL_HYBRID_SLIM_AURUM: 'ARIEL HYBRID SLIM AURUM',
  TYPE_TIPOLOGIADESCRIZIONE_ARIEL_POWER_FULL: 'ARIEL POWER FULL',
  TYPE_TIPOLOGIADESCRIZIONE_ARIEL_HYBRID_KIT_IBRIDIZZATORE: 'ARIEL HYBRID KIT IBRIDIZZATORE',
  TYPE_TIPOLOGIADESCRIZIONE_ARIEL_ARIEL_POWER: 'ARIEL POWER',
  TYPE_SEDILE_RIBALTABILE : 'n.1 SEDILE RIBALTABILE',
  TYPE_MANIGLIA_SICUREZZA_BIANC: 'n.1 MANIGLIA DI SICUREZZA BIANCA',
  TYPE_NUOVO_BOX_DOCCIA: 'NUOVO BOX DOCCIA',
  TYPE_NUOVO_PIATTO_DOCCIA: "NUOVO PIATTO DOCCIA",
  TYPE_NUOVA_RUBINETTERIA: "NUOVA RUBINETTERIA",
  TYPE_NUOVA_CASSETTA: 'NUOVA CASSETTA WC',
  TYPE_NUOVI_SANITARI: "NUOVI SANITARI",
  TYPE_VASCA: 'VASCA',
  TYPE_SEDUTASX: 'SEDUTASX',
  TYPE_SEDUTADX: 'SEDUTADX',
  TYPE_SCARICOSX: 'SCARICOSX',
  TYPE_SCARICODX: 'SCARICODX',
  TYPE_DESC_SERIE_GROHE: 'SERIE GROHE',
  TYPE_MODSISTPOMCALORE: 'MODSISTPOMCALORE',
  TYPE_POTSISTPOMCALORE: 'POTSISTPOMCALORE',
  TIPODETRAZIONE_CESS_50: 'Cessione credito con sconto in fattura 50%',
  TIPODETRAZIONE_CESS_65: 'Cessione credito con sconto in fattura 65%',
  TIPODETRAZIONE_CESS_75: 'Cessione credito con sconto in fattura 75%',
  TIPODETRAZIONE_50_PRIMA_CASA: '50% se prima casa',
  TIPODETRAZIONE_36_SECONDA_CASA: '36% se seconda casa',
  TIPODETRAZIONE_36: '36%',
  TIPODETRAZIONE_75: '75%',
  TYPE_TIPODETRAZIONE: 'TIPODETRAZIONE',
  TYPE_TIPODETRAZIONE0: 'TIPODETRAZIONE0',
  OPTIONAL_HOME_GLIDE: 'OPTIONAL_HOME_GLIDE',
  OPTIONAL_FLOW_X_TIPO_ARRIVO: 'FLOW_X_TIPO_ARRIVO',
  OPTIONAL_FLOW_X_TIPO_PARTENZA: 'FLOW_X_TIPO_PARTENZA',
  OPTIONAL_FLOW_X_TIPO_GUIDA: 'FLOW_X_TIPO_GUIDA',
  OPTIONAL_FLOW_X_POS_JOY: 'FLOW_X_POS_JOY',
  OPTIONAL_FLOW_X_OPT_SEDILE: 'FLOW_X_OPT_SEDILE',
  OPTIONAL_FLOW_X_VARIE: 'FLOW_X_VARIE',
  MONTASCALE_DRITTO_SUL_PIANEROTTOLO: 'DRITTO SUL PIANEROTTOLO',
  MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_ORIZZ: 'CON CURVA DI PARCHEGGIO A      ° ORRIZZONTALE',
  MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_VERTIC: 'CON CURVA DI PARCHEGGIO A      ° VERTICALE',
  MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_90_VERTICALE: 'CON CURVA DI PARCHEGGIO A 90° VERTICALE',
  MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_180_VERTICALE: 'CON CURVA DI PARCHEGGIO A 180° VERTICALE',
  MONTASCALE_RADIOCOMANDO_CHIAMATA_E_PARCHEGGIO: 'RADIOCOMANDO CHIAMATA E PARCHEGGIO',
  MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_180: 'Con curva di parcheggio a 180°',
  MONTASCALE_TIPO_ARRIVO_BASSO_GUIDA: 'TIPO_ARRIVO_BASSO_GUIDA',
  MONTASCALE_TIPO_ARRIVO_ALTO_CON_CURVA_DI_PARCHEGGIO_A_ORIZZ: 'Con curva di parcheggio a       ° orizzontale',
  MONTASCALE_TIPO_ARRIVO_ALTO_GUIDA: 'TIPO_ARRIVO_ALTO_GUIDA',
  MONTASCALE_STANDAR_SUL_PIANEROTTOLO_MINIMO_200MM: 'Standard sul pianerottolo (minimo 2000 mm) L      mm',
  DEPURATORE_ACQUA_BUONA_ARIEL: 'ACQUA BUONA ARIEL',
  COD_MODELLO_DEPURATORE: 'MODELLO_DEPURATORE',
  COD_DEPURATORE_ACQUA_BUONA_ARIEL_ABB: 'MODELLO_DEPURATORE_V_ABB',
  COD_MODELLO_CUCINA: "MODELLO_CUCINA",
  ACCESSORI_DEPURATORE_TRASC: 'ACCESSORI_DEPURATORE',
  ACCESSORI_DEPURATORE_PARENT: 'ACCESSORI_DEPURATORE_PARENT',
  ACCESSORI_DEPURATORE_GASATORE: 'GASATORE',
  TYPE_DOC_CERTIFICATO_FIRMA: 'Certificato di firma',
  TYPE_DOC_CERTIFICATO_FIRMA_ATT_SERV: 'Certificato di firma attivazione servizi',
  TYPE_DOC_SCANSIONE_CONTRATTO: 'Scansione contratto',
  TYPE_ROLE_TECH: 'ROLE_TECH',
  TYPE_ROLE_SUPPORT: 'ROLE_SUPPORT',
  TYPE_ROLE_SELLER: 'ROLE_SELLER',
  TYPE_ROLE_CUSTOMER_CARE: 'ROLE_CUSTOMER_CARE',
  DIM_PIATTI_PIETR: 'DIM_PIATTI_PIETR',
  COLORE_PIATTI_EFF_PIE: 'COLORE_PIATTI_EFF_PIE',
  DIM_PIATTO_LARGO: 'DIM_PIATTO_LARGO',
  DIM_PIATTO_STRETTO: 'DIM_PIATTO_STRETTO',
  COLORE_PIATTO_LARGO: 'COLORE_PIATTO_LARGO',
  TYPE_OPTIONS: 'TYPE_OPTIONS',
  DESC_NUOVO_PIATTO_DOCCIA: 'NUOVO PIATTO DOCCIA',
  COD_TIPO_CASSETTA_WC: 'TIPO_CASSETTA_WC',
  COD_TIPO_INTERV_TERMO: 'TIPO_INTERV_TERMO',
  COD_CASSETTA_WC_MURETTO: 'CASSETTA_WC_MURETTO',
  COD_TIPO_PLACCA_WC: 'TIPO_PLACCA_WC',
  COD_COLORE_PLACCA_WC: 'COLORE_PLACCA_WC',
  COD_TIPO_CASSETTA_WC_MONOLITE: 'TIPO_CASSETTA_WC_MONOLITE',
  COD_TIPO_CASSETTA_BIDET_MONOLITE: 'TIPO_CASSETTA_BIDET_MONOLITE',
  COD_PROMO: "PROMO",
  COD_MODCALDPELLET: 'MODCALDPELLET',
  COD_MODTERMOPELLET: 'MODTERMOPELLET',
  COD_MODSTUFEBIO: 'MODSTUFEBIO',
  COD_MODSTUFELEGNA: 'MODSTUFELEGNA',
  COD_VOLRISC: 'VOLRISC',
  COD_PERCIVA_STEP0: 'PERCIVA_STEP0',
  COD_TIPOPAGCAP_STEP0: 'TIPOPAGCAP_STEP0',
  COD_TIPOPAGSALDO_STEP0: 'TIPOPAGSALDO_STEP0',
  COD_PERCIVA: 'PERCIVA',
  COD_CESSIONE_CREDITO_PERCIVA: 'CESSIONE_CREDITO_PERCIVA',
  COD_TIPOPAGSALDO: 'TIPOPAGSALDO',
  COD_TIPOPAGCAP: 'TIPOPAGCAP',
  COD_COLORE_STUFE: 'COLORE_STUFE',
  MOD_MODSTUFEPELLET: 'MODSTUFEPELLET',
  COD_TIPO_SANITARI: 'TIPO_SANITARI',
  COD_ADESIONE_PIASTRELLE: 'ADESIONE_PIASTRELLE',
  COD_TIPO_SCARICO_WC: 'TIPO_SCARICO_WC',
  COD_TIPO_CASS_SC_WC: 'TIPO_CASS_SC_WC',
  COD_TIPO_ABITAZIONE: 'TIPO_ABITAZIONE',
  COD_CANNA_FUMARIA:'CANNA_FUMARIA',
  COD_POSIZIONE_UE: 'POSIZIONE_UE',
  COD_TIPO_ABITAZIONE_STUFA: 'TIPO_ABITAZIONE_STUFA',
  COD_TIPO_BAGNO: 'TIPO_BAGNO',
  COD_OMAGGIO_TIPOLOGIA: 'OMAGGIO_TIPOLOGIA',
  COD_MODELLO_SANITARI: "MODELLO_SANITARI",
  COD_TIPO_SOST_OP_IDR: 'TIPO_SOST_OP_IDR',
  COD_TIPO_NUOVI_SANITARI: 'TIPO_NUOVI_SANITARI',
  COD_TIPO_SCARICO: 'TIPO_SCARICO',
  COD_TIPO_PRODOTTO: 'TIPO_PRODOTTO',
  COD_NUM_VASCHE_MOB: 'NUM_VASCHE_MOB',
  COD_VASCAMOB: 'VASCAMOB',
  COD_KIT_SICUREZZA: 'KIT_SICUREZZA',
  COD_COLORE_PORTA: 'COLORE_PORTA',
  COD_APERTURA_PORTA: 'APERTURA_PORTA',
  COD_TIPO_COLONNA_MOB: 'TIPO_COLONNA_MOB',
  COD_SERIE_RUBINETTERIA: 'SERIE_RUBINETTERIA',
  COD_TIPO_ASTA_COMBY: 'TIPO_ASTA_COMBY',

  COD_MODELLO_MOBILE: 'MODELLO_MOBILE',
  COD_MISURA_MOBILE: 'MISURA_MOBILE',
  COD_BASE_MOBILE: 'BASE_MOBILE',
  COD_CASSETTI_MOBILE: 'CASSETTI_MOBILE',
  COD_TIPOLOGIA_LAVABO: 'TIPO_LAVABO_MOBILE',

  COD_COLORE_PIATTI_EFF_PIE_STONE_3D: 'COLORE_PIATTI_EFF_PIE_STONE_3D',
  COD_DIM_PIATTI_PIETR_STONE_3D: 'DIM_PIATTI_PIETR_STONE_3D',
  COD_TIPO_COLONNE_RUB: 'TIPO_COLONNE_RUB',
  COD_TIPO_MISC_EST: 'TIPO_MISC_EST',
  COD_SERIE_MISC_EST: 'SERIE_MISC_EST',
  COD_KIT_SERIE_LIME: 'KIT_SERIE_LIME',
  COD_KIT_SERIE_TANGO: 'KIT_SERIE_TANGO',
  COD_KIT_SERIE_STICK: 'KIT_SERIE_STICK',
  COD_KIT_SERIE_LIGHT: 'KIT_SERIE_LIGHT',
  COD_MISC_SERIE_LIME: 'MISC_SERIE_LIME',
  COD_MISC_SERIE_TANGO:'MISC_SERIE_TANGO',
  COD_MISC_SERIE_STICK: 'MISC_SERIE_STICK',
  COD_MISC_SERIE_LIGHT: 'MISC_SERIE_LIGHT',
  COD_COL_SERIE_LIGHT: 'COL_SERIE_LIGHT',
  COD_LIME_LAVABO: 'LIME.LAVABO',
  COD_LIME_BIDET: 'LIME.BIDET',
  COD_TANGO_LAVABO: 'TANGO.LAVABO',
  COD_TANGO_BIDET: 'TANGO.BIDET',
  COD_STICK_LAVABO: 'STICK.LAVABO',
  COD_STICK_BIDET: 'STICK.BIDET',
  COD_LIGHT_LAVABO: 'LIGHT.LAVABO',
  COD_LIGHT_BIDET: 'LIGHT.BIDET',
  COD_GROHE_LAVABO: 'GROHE.LAVABO',
  COD_GROHE_BIDET: 'GROHE.BIDET',
  COD_CRISTINA_PRIME_LAVABO: 'CRISTINA.PRIME.LAVABO',
  COD_CRISTINA_PRIME_BIDET: 'CRISTINA.PRIME.BIDET',
  COD_MISC_SERIE_CRISTINA_PRIME: 'MISC_SERIE_CRISTINA_PRIME',
  COD_CRISTINA_ELEVATION_LAVABO: 'CRISTINA.ELEVATION.LAVABO',
  COD_CRISTINA_ELEVATION_BIDET: 'CRISTINA.ELEVATION.BIDET',
  COD_KIT_SERIE_CRIST_ELEVATION: 'KIT_SERIE_CRIST_ELEVATION',
  COD_MISC_SERIE_CRIST_ELEVATION: 'MISC_SERIE_CRIST_ELEVATION',
  COD_TIPO_GROHE: 'TIPO_GROHE',
  COD_VENEZIA_MISURA: 'VENEZIA_MISURA',
  COD_ROMA_MISURA: 'ROMA_MISURA',
  COD_VERONA_MISURA: 'VERONA_MISURA',
  COD_FIRENZE_MISURA: 'FIRENZE_MISURA',
  COD_VERONA_SPORTELLO: 'VERONA_SPORTELLO',
  COD_FIRENZE_SPORTELLO: 'FIRENZE_SPORTELLO',
  COD_FIRENZE_PANNELLO: 'FIRENZE_PANNELLO',
  COD_ECUBE_MISURA: 'ECUBE_MISURA',
  COD_TIPO_RISCALDAMENTO: 'TIPO_RISCALDAMENTO',
  COD_IMPIANTO: 'IMPIANTO',
  COD_MURI_PERIMETRALI: 'MURI_PERIMETRALI',
  COD_GRES_ALTEZZA_PARETE: 'GRES_ALTEZZA_PARETE',
  COD_GRES_PAVIMENTO: 'GRES_PAVIMENTO',
  COD_NUOVI_ACCESSORI_TIPOLOGIA: 'NUOVI_ACCESSORI_TIPOLOGIA',
  COD_ACCESSORI_GASATORE: "ACCESSORI_GASATORE",
  COD_BOX_DOCCIA_INGRESSO: "BOX_DOCCIA_INGRESSO",
  COD_BOX_DOCCIA_PROFILO: "BOX_DOCCIA_PROFILO",
  COD_BOX_DOCCIA_CRISTALLI: "BOX_DOCCIA_CRISTALLI",
  COD_BOX_DOCCIA_MODELLO: "BOX_DOCCIA_MODELLO",
  COD_BOX_DOCCIA_LARGHEZZA: 'BOX_DOCCIA_LARGHEZZA',
  COD_ACCESSORI_TIPOLOGIA: "ACCESSORI_TIPOLOGIA",
  COD_MISC_DOCCIA: 'MISC_DOCCIA',
  COD_OPEN_POS_SPOR_EST: 'OPEN_POS_SPOR_EST',
  COD_VASCA_EMOTION_DIM: 'VASCA_EMOTION_DIM',
  COD_VASCA_EXP_EROG: 'VASCA_EXP_EROG',
  COD_DOCCIA_SUN_MIS: 'DOCCIA_SUN_MIS',
  COD_DOCCIA_SUN_ING: 'DOCCIA_SUN_ING',
  COD_DOCCIA_SUN_PROF: 'DOCCIA_SUN_PROF',
  COD_VASCA_EMOTION_APE: 'VASCA_EMOTION_APE',
  COD_MISC_VASCA: "MISC_VASCA",
  COD_MISC_LAVABO: "MISC_LAVABO",
  COD_MISC_BIDET: "MISC_BIDET",
  COD_COLORE_CALDAIA: "COLORECALD",
  COD_ALIMENTAZIONE_CALDAIA: "TIPOALIMCALD",
  COD_TIPO_PUFFER: 'TIPO_PUFFER',
  CODICE_TIPO_PRODOTTO_POLTRONE: 'TIPO_PRODOTTO',
  CODICE_OPZIONE_POLTRONA: 'OPZIONE_POLTRONA',
  CODICE_OPZIONE_POLTRONA_PESO: 'OPZIONE_POLTRONA_PESO',
  CODICE_COLORI_POLTRONA: 'COLORI_POLTRONA',
  COD_PROMO_CLIMA_RAGGR: 'PROMO_CLIMA_RAGGR',
  COD_PROMO_CLIMA_DESCR: 'PROMO_CLIMA_DESCR',
  COD_MODELLO_INVERTER: 'MODELLO_INVERTER',
  COD_PROMO_POLTRONA: 'PROMO_POLTRONA',
  COD_MOD_PROMO_POLTRONA: 'MOD_PROMO_POLTRONA',
  COD_MISURE_MENSOLE: "MIS_MENSOLE",
  COD_SERVIZI_DEPURATORE: 'SERVIZIO_DEPURATORE',

  COD_COLORECALDAIA: 'COLORECALD',
  COD_TIPO_ALIMETANTAZIONE_CALDAIA: 'TIPOALIMCALD',
  COD_MOD_SCALDA_ACQUA_HOT_WIND: 'MODCALDHOTWIND',
  COD_MOD_CALDAIA_CONDENSAZIONE_ECO_WIND: 'MODCALDECOW',
  COD_MOD_CALDAIA_CONDENSAZIONE_ECO_WIND_DESIGN: 'MODCALDECOWD',
  COD_MOD_CALDAIA_CONDENSAZIONE_ECO_WIND_ARUM: 'MODCALDECOWAU',
  COD_MOD_CALDAIA_CONDENSAZIONE_ECO_WIND_WALL: 'MODCALDECOWWALL',
  COD_MOD_CALDAIA_CONDENSAZIONE_EASY: 'MODCALDCONDENSEASY',
  COD_CALDAIA_CAMERA_APERTA: 'MODCALDCAMAP',

  MODELLO_ARUBA: "ARUBA",
  MODELLO_AMERIKA: "AMERIKA",
  MODELLO_AMERIKA_IMP: "AMERIKA IMP",

  CODICE_NOME_FINANZIARIE: "NOME_FINANZIARIE",
  FINANZIAMENTO_DESCRIONE_TRASC: "FINANZIAMENTO",

  CODICE_STATO_CIVILE: "STATO_CIVILE",
  CODICE_FIN_TIPO_ABITAZIONE: "FIN_TIPO_ABITAZIONE",
  CODICE_FIN_CITADINANZA: "FIN_CITADINANZA",
  CODICE_FIN_ATTIVITA_LAV: "FIN_ATTIVITA_LAV",
  CODICE_FIN_TIPO_ASSUNZIONE: "FIN_TIPO_ASSUNZIONE",
  CODICE_FIN_TIPO_REDDITO: "FIN_TIPO_REDDITO",
  CODICE_TIPO_DOC: "TIPO_DOC",
  CODICE_SERIE_RUBINETTERIA: "SERIE_RUBINETTERIA",
  TYPE_DOC_FINANZIAMENTO_STEP: "Documenti finanziamento step contratto",
  TYPE_DOC_DOC_CLIENTE: 'Documenti cliente',
  TYPE_DOC_FOTO_AMB: 'Foto ambiente',
  TYPE_MODULO_ATTIVAZIONE_SERVIZI: 'Modulo attivazione servizi',
 
  DESC_FINANZIARIA_SANTANDER: "SANTANDER",
  DESC_FINANZIARIA_COFIDIS: "COFIDIS",
  DESC_FINANZIARIA_COMPASS: "COMPASS",
  DESC_FIN_CITTADINANZA_ALTRA: "Altra, (indicarla)",
  DESC_ATTIVITA_LAV_DIPENDENTE: "Dipendente",
  DESC_ATTIVITA_LAV_PENSIONATO: "Pensionato",
  DESC_ATTIVITA_LAV_OCCUP_SALT: "Occupazione saltuaria",
  DESC_ATTIVITA_LAV_CASALINGA : 'Casalinga',
  DESC_ATTIVITA_LAB_CONDIZ_NON_PROFESS : 'Condizioni non professionali con reddito',
  DESC_ATTIVITA_LAV_AUTO: "Lavoratore autonomo",
  DESC_TIPO_REDD_NETTO: "Netto",
  DESC_TIPO_REDD_LORDO: "Lordo",
  DESC_TYPE_DOC_FINANZIAMENTO: "Documenti finanziamento",
  DESC_SERIE_GROHE: "SERIE GROHE",
  DESC_DOCUMENTEO_FINANZIAMENTO_STEP: "Documenti finanziamento step contratto",
  DESC_MODULO_ATT_SERVIZI: "Modulo attivazione servizi",

  DESC_TOGGLE_LAVABO: "LAVABO",
  DESC_LAVABO_INCASSO: "LAVABO INCASSO",
  DESC_FINANZIAMENTO_TASSO_0: "FINANZIAMENTO TASSO 0",
  DESC_NO_OMAGGIO: 'NO OMAGGIO',
  DESC_ALT_ZOC_COLORE: "colore ",
  DESC_ALT_ZOC_MISURA: "cm ",  
  DESC_PROF_MENSOLE: "Prof. cm ",
  DESC_PATTUMIERA_VALUE: " ",
  DESC_CENTAX: 'CENTAX',
  DESC_NESSUNA: 'NESSUNA',
  DESC_MOBILI_NO_COL: 'Nessuna colonna',
  DESC_MOBILE_TAVOLONE: 'TAVOLONE',
  DESC_MISURA_FILTER_TAVOLONE: '60',
  DESC_BIANCO_TONDO_40: 'BIANCO TONDO APPOGGIO 40cm',
  DESC_RUB_SERIE_TANGO: 'SERIE TANGO',
  DESC_RUB_SERIE_STICK: 'SERIE STICK',
  DESC_RUB_INCASSO: 'LAVABO INCASSO',
  DESC_RUB_SERIE_LAVABO: 'LAVABO',
  DESC_SIFONE_LAVELLO: 'SIFONE LAVELLO',

  LABEL_NO_CANNA_FUM_BIO : 'Nessuna canna fumaria ( SOLO PER STUFE BIOETANOLO )',
  LABEL_MOND_ALL_ACT_SERV: "per €",
  LABEL_MOND_ALL_ACT_SERV_IVA: "iva inclusa/anno",

  NOTA_STUFE_BIOETANOLO: 'ATTENZIONE PRODOTTO CON IVA 22% - NO DETRAZIONE FISCALE',

  MIN_SCHIENA_GINOCCHIO_MISURA_MONTASCALE: 550,
  MAX_SCHIENA_GINOCCHIO_MISURA_MONTASCALE: 1200,
  MIN_SCHIENA_TESTA_MISURA_MONTASCALE: 700,
  MAX_SCHIENA_TESTA_MISURA_MONTASCALE: 1200,
  MIN_SCHIENA_FRONTE_MISURA_MONTASCALE: 230,
  MAX_SCHIENA_FRONTE_MISURA_MONTASCALE: 500,

  ACCESSORI_ALLARME: 'ACCESSORI_ALLARME',
  COMMESSA_MONDIAL_ALLARME: 'SFA-T00092',
  ALLARME_KIT_BASE: 'ALLARME_KIT_BASE',
  OMAGGIO_ALLARME: 'OMAGGIO_ALLARME',
  DESC_ALLARME_KIT_BASE: 'CENTRALE D’ALLARME INTEGRATA, RADIOCOMANDO 4 TASTI, CONTATTO MAGNETICO POWER BIANCO, ROUTER WIRELESS CON ANTENNE ESTERNE, GRUPPO DI CONTINUIT&#193; 12V 2° PER ROUTER MODEM BATTERIA 5Wh, PACCHETTO SILVER, CENTRALE OPERRATOVA E ASSISTENZA 1 ANNO INCLUSO NEL PREZZO CON TACITO RINNOVO SALVO DISDETTA, INSTALLAZIONE STANDARD IMPIANTO MONDIAL ALLARMI',
  BRAND_MONDIAL: 'mondial',

  GARANZIA_MAX_CASALINGA_SANTANDER: 4000,
  GARANZIA_MAX_CASALINGA_COFIDIS: 2900,
  GARANZIA_MAX_CASALINGA_COMPASS: 3000,
  GARANZIA_MAX_COMPASS: 5000,
  MAX_ETA_FIN_COMPASS: 82,
  MAX_ETA_FIN_COFIDIS: 85,
  MAX_ETA_FIN_SANTANDER: 85,
  LOREN_TABLE_LINE_THICKNESS: 0.08, // spessore line print table

  NOTA_IMP_ELETTRICO_1: 'Nota: GDL S.p.A. non esegue spostamenti, modifiche o collegamenti dell’impianto elettrico esistente.',
  NOTA_IMP_ELETTRICO_2: 'Qualsiasi tipo di modifica verrà accettata entro termine massimo di 3 giorni dalla data della sottoscrizione in caso contrario si dovranno quantificare i relativi costi che saranno completamente a carico del cliente',
  ISTR_INSTALL_ALTRO_CONTRATTO: "IN CASO DI INSTALLAZIONE E’ NECESSARIO COMPILARE CONTRATTO RELATIVO PER CONTRIBUTO DI INSTALLAZIONE E RELATIVO IMPORTO",

  NOTA_DATI_TECH_CUCNA: "Le specifiche delle forniture sono riportate sul progetto esecutivo allegato al capitolato identificato come RIFERIMENTO ORDINE CUCINA",

  ERROR_GENERIC_API: 'Errore in fase di salvataggio del modulo, contatta l\' assistenza.',

  // label usate in stampa e in compilazione

  LABEL_DAT_TEC_PIANO_N: "Piano n°",
  LABEL_DAT_TEC_SCALE: "Scale",
  LABEL_DAT_TEC_ASCENSORE: "Ascensore",
  LABEL_DAT_TEC_OLD_CUCINA: "Smontaggio vecchia cucina",

  LABEL_LOREN_CUCINA_MODELLO: "Modello",
  LABEL_LOREN_CUCINA_RIF_ORDINE: "Riferimento ordine cucina",

  CERTIFICATO_FIRMA_ORDINE: 'CERTIFICATO_ORDINE',
  CERTIFICATO_MODULO_ATT_SERV: 'CERTIFICATO_ATT_SERV',
  CONTRATTO_ORDINE: 'CONTRATTO_ORDINE',
  CONTRATTO_ATT_SERV: 'CONTRATTO_ATT_SERV',
  LABEL_NO_DETRAZIONE: 'Questo prodotto non beneficia di detrazioni fiscali',

  LABEL_MODELLO_MOBILE: "MODELLO",
  LABEL_MISURA_MOBILE: "MISURA",
  LABEL_BASE_MOBILE: "BASE",
  LABEL_CASSETTI_MOBILE: "CASSETTI",
  LABEL_TIPO_LAVABO_MOBILE: "TIPOLOGIA LAVABO",
  LABEL_SPECCHIO_MOBILE: "SPECCHIO",
  LABEL_n_VASCHE: "N° VASCHE",
  LABEL_COLONNA: "COLONNA",
  LABEL_QTA_PENSILI: "QUANTITA' PENSILI",
  LABEL_NOTE_COMPOSIZIONE_MOBILI: "NOTE COMPOSIZIONE",

  LABEL_PRINT_RIVESTIMENTO_GRES: "NUOVO RIVESTIMENTO GRES SOLO INTERNO DOCCIA",
  LABEL_PRINT_MISCELATORI: "MISCELATORI",
  LABEL_PRINT_NOTE: "NOTE",
  LABEL_PRINT_NOTE_LIBERE: "NOTE LIBERE",
  LABEL_PRINT_PROMOZIONE: "PROMOZIONE",
  LABEL_PRINT_MOBILE: "MOBILE",
  LABEL_PRINT_MOBILE_BAGNO: "MOBILE BAGNO",
  LABEL_PRINT_DATI_ABITAZIONE : "DATI ABITAZIONE",
  LABEL_PRINT_OPERE_IDRAULICHE: "OPERE IDRAULICHE",
  LABEL_PRINT_PIANTINA_BAGNO_ATTUALE: "PIANTINA BAGNO ATTUALE",
  LABEL_PRINT_PIANTINA_NUOVO_BAGNO: "PIANTINA NUOVO BAGNO",
  LABEL_DATI_TECNICI_ATTUALI: "DATI TECNICI ATTUALI",

  LABEL_INFO_PAGAMENTO_TEMPI: '* IL SALDO DEVE ESSERE CORRISPOSTO ENTRO 20 GIORNI DECORRENTI DALLA DATA DI SOTTOSCRIZIONE DEL CONTRATTO',
  LABEL_INFO_PRATICHE_EDILIZIE: 'SONO ESCLUSE PRATICHE EDILIZIE PER LA VALUTAZIONE DELLE POTENZE ESISTENTI E PER LE OPPORTUNE MODIFICHE DEI kW TOTALI DELL’IMPIANTO'
}
