<div class="text-center {{classBG}}">
    DATI TECNICI ATTUALI
</div>
<div class="{{classCont}}">
    <div class="row mb-3">
        <div class="col-md-4 mb-2 col-12">
            Luogo Installazione
        </div>
        <div class="col-md-4 col-12">
            <mat-radio-group labelPosition="before" class="" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.datiTecnici.tipoAbitazioneId">
                <mat-radio-button class="d-block mb-2 mr-2" [value]="tipo.id"  *ngFor="let tipo of tipiAbitazione">{{tipo.descrizione}}</mat-radio-button>
				<span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.datiTecnici.tipoAbitazioneId" (click)="form.sections.datiTecnici.tipoAbitazioneId = null">
					backspace
				</span>
            </mat-radio-group>
            <div class="smallFont text-danger mb-3" *ngIf="!form.sections.datiTecnici.tipoAbitazioneId">Campo richiesto</div>
            <div class="smallfield">
                <span class="mr-2">Piano n°</span>
                    <app-input-number
                        (onNumChange)="form.sections.datiTecnici.piano = $event"
                        [step]="1"
                        [num]="form.sections.datiTecnici.piano"
                        [decimal]="false"
                        [payment]="false"
                        [small]="true"
                        label="">
                    </app-input-number>
            </div>

        </div>
        <div class="col-md-4 col-12">
            <mat-checkbox class="full-width d-block mb-2"
                          color="primary"
                          [(ngModel)]="form.sections.datiTecnici.diProprieta">
                Di proprietà
            </mat-checkbox>
            <mat-checkbox class="full-width d-block mb-2"
                          color="primary"
                          [(ngModel)]="form.sections.datiTecnici.ascensore">
                Ascensore
            </mat-checkbox>
            <mat-checkbox class="full-width d-block mb-2"
                          color="primary"
                          [(ngModel)]="form.sections.datiTecnici.accessibilita_cortile">
                Accessibilità cortile con mezzi
            </mat-checkbox>
        </div>
    </div>
    <hr/>
    <div class="row mb-3 smallfield">
        <div class="col-md-3 col-12 mb-2 align-self-center">
            Dimensioni vano da ristrutturare:
        </div>
        <div class="{{brand === 'bagniHelp75' ? 'col-md-4' : 'col-md-3'}}  col-4">
                <app-input-number
                    (onNumChange)="form.sections.datiTecnici.dimensioniVano.lung = $event"
                    [step]="0.1"
                    [num]="form.sections.datiTecnici.dimensioniVano.lung"
                    [decimal]="true"
                    [payment]="false"
                    [small]="false"
                    label="lung. cm">
                </app-input-number>
            <div class="smallFont text-danger" *ngIf="!form.sections.datiTecnici.dimensioniVano.lung">Campo richiesto</div>
        </div>
        <div class="{{brand === 'bagniHelp75' ? 'col-md-4' : 'col-md-3'}}  col-4">
                <app-input-number
                    (onNumChange)="form.sections.datiTecnici.dimensioniVano.larg = $event"
                    [step]="0.1"
                    [num]="form.sections.datiTecnici.dimensioniVano.larg"
                    [decimal]="true"
                    [payment]="false"
                    [small]="false"
                    label="larg. cm">
                </app-input-number>
            <div class="smallFont text-danger" *ngIf="!form.sections.datiTecnici.dimensioniVano.larg">Campo richiesto</div>
        </div>
        <div class="col-md-3 col-4" *ngIf="brand !== 'bagniHelp75'">
                <app-input-number
                    (onNumChange)="form.sections.datiTecnici.dimensioniVano.alt = $event"
                    [step]="0.1"
                    [num]="form.sections.datiTecnici.dimensioniVano.alt"
                    [decimal]="true"
                    [payment]="false"
                    [small]="false"
                    label="alt. cm">
                </app-input-number>
            <div class="smallFont text-danger" *ngIf="!form.sections.datiTecnici.dimensioniVano.alt">Campo richiesto</div>
        </div>
    </div>
    <hr *ngIf="brand !== 'bagniHelp75'"/>
    <div class="row mb-3 smallfield" *ngIf="brand !== 'bagniHelp75'">
        <div class="col-md-3 col-12 mb-2 align-self-center">
            Dimensioni porta:
        </div>
        <div class="{{brand === 'bagniHelp75' ? 'col-md-4' : 'col-md-3'}}  col-4">
                <app-input-number
                    (onNumChange)="form.sections.datiTecnici.dimensioniPorta.lung = $event"
                    [step]="0.1"
                    [num]="form.sections.datiTecnici.dimensioniPorta.lung"
                    [decimal]="true"
                    [payment]="false"
                    [small]="false"
                    label="lung. cm">
                </app-input-number>
            <div class="smalFont text-danger" *ngIf="!form.sections.datiTecnici.dimensioniPorta.lung">Campo richiesto</div>

        </div>
        <div class="{{brand === 'bagniHelp75' ? 'col-md-4' : 'col-md-3'}}  col-4">
                <app-input-number
                    (onNumChange)="form.sections.datiTecnici.dimensioniPorta.alt = $event"
                    [step]="0.1"
                    [num]="form.sections.datiTecnici.dimensioniPorta.alt"
                    [decimal]="true"
                    [payment]="false"
                    [small]="false"
                    label="alt. cm">
                </app-input-number>
            <div class="smallFont text-danger" *ngIf="!form.sections.datiTecnici.dimensioniPorta.alt">Campo richiesto</div>
        </div>
        <div class="col-md-3 col-4">
                <app-input-number
                    (onNumChange)="form.sections.datiTecnici.dimensioniPorta.altPiastrelle = $event"
                    [step]="0.1"
                    [num]="form.sections.datiTecnici.dimensioniPorta.altPiastrelle"
                    [decimal]="true"
                    [payment]="false"
                    [small]="false"
                    label="alt. piastrelle cm">
                </app-input-number>
            <div class="smallFont text-danger" *ngIf="!form.sections.datiTecnici.dimensioniPorta.altPiastrelle">Campo richiesto</div>
        </div>
    </div>
    <hr/>
 <div *ngIf="brand !== 'bagniHelp75'">   
    <div class="row mb-3 smallfield">
        <div class="col-md-3 col-12 mb-2 align-self-center">
            <mat-radio-group labelPosition="after" class="" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.datiTecnici.docciaVasca.tipoBagnoId">
            	<mat-radio-button class="d-block mb-2 mr-2" [value]="tipoBagno.id"  *ngFor="let tipoBagno of tipiBagno">{{tipoBagno.descrizione}}</mat-radio-button>            	
				<span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.datiTecnici.docciaVasca.tipoBagnoId" (click)="form.sections.datiTecnici.docciaVasca.tipoBagnoId = null">
					backspace
				</span>            	
            </mat-radio-group>
            <div class="smallFont text-danger mb-3" *ngIf="!form.sections.datiTecnici.docciaVasca.tipoBagnoId">Campo richiesto</div>
        </div>
        <div class="col-md-3 col-4">
                <app-input-number
                    (onNumChange)="form.sections.datiTecnici.docciaVasca.dimensioni.lung = $event"
                    [step]="0.1"
                    [num]="form.sections.datiTecnici.docciaVasca.dimensioni.lung"
                    [decimal]="true"
                    [payment]="false"
                    [small]="false"
                    label="lung. cm">
                </app-input-number>
        </div>
        <div class="col-md-3 col-4">
                <app-input-number
                    (onNumChange)="form.sections.datiTecnici.docciaVasca.dimensioni.larg = $event"
                    [step]="0.1"
                    [num]="form.sections.datiTecnici.docciaVasca.dimensioni.larg"
                    [decimal]="true"
                    [payment]="false"
                    [small]="false"
                    label="larg. cm">
                </app-input-number>
        </div>
        <div class="col-md-3 col-4">
                <app-input-number
                    (onNumChange)="form.sections.datiTecnici.docciaVasca.dimensioni.alt = $event"
                    [step]="0.1"
                    [num]="form.sections.datiTecnici.docciaVasca.dimensioni.alt"
                    [decimal]="true"
                    [payment]="false"
                    [small]="false"
                    label="alt. cm">
                </app-input-number>
        </div>
    </div>
    <hr/>
    <div class="row mb-3 smallfield">
        <!--Inserimento termosifone presente-->
        <div class="col-md-12 col-12">
            <div class="mb-2">Termosifone presente</div>
            <mat-radio-group labelPosition="after" class="" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.datiTecnici.termospres">
                <mat-radio-button class="mb-2 mr-2" [value]="true">Si</mat-radio-button>
                <mat-radio-button class="mr-2" [value]="false">No</mat-radio-button>
            </mat-radio-group>
            <div class="smallFont text-danger" *ngIf="form.sections.datiTecnici.termospres == undefined || form.sections.datiTecnici.termospres == null">Campo richiesto</div>
        </div>
    </div>
    <div class="row mb-3 smallfield" [hidden]="!form.sections.datiTecnici.termospres">
        <div class="col-12 mb-2">Misura termosifone:</div>
        <div class="col-md-3 col-4 align-self-center">
                <app-input-number
                    (onNumChange)="form.sections.datiTecnici.misuraTermosifone.lung = $event"
                    [step]="0.1"
                    [num]="form.sections.datiTecnici.misuraTermosifone.lung"
                    [decimal]="true"
                    [payment]="false"
                    [small]="false"
                    label="lung. cm">
                </app-input-number>
            <div class="smallFont text-danger" *ngIf="!form.sections.datiTecnici.misuraTermosifone.lung">Campo richiesto</div>
        </div>
        <div class="col-md-3 col-4 align-self-center">
                <app-input-number
                    (onNumChange)="form.sections.datiTecnici.misuraTermosifone.alt = $event"
                    [step]="0.1"
                    [num]="form.sections.datiTecnici.misuraTermosifone.alt"
                    [decimal]="true"
                    [payment]="false"
                    [small]="false"
                    label="alt. cm">
                </app-input-number>
            <div class="smallFont text-danger" *ngIf="!form.sections.datiTecnici.misuraTermosifone.alt">Campo richiesto</div>
        </div>
        <div class="col-md-3 col-4 align-self-center">
                <app-input-number
                    (onNumChange)="form.sections.datiTecnici.misuraTermosifone.prof = $event"
                    [step]="0.1"
                    [num]="form.sections.datiTecnici.misuraTermosifone.prof"
                    [decimal]="true"
                    [payment]="false"
                    [small]="false"
                    label="prof. cm">
                </app-input-number>
            <div class="smallFont text-danger" *ngIf="!form.sections.datiTecnici.misuraTermosifone.prof">Campo richiesto</div>
        </div>
        <div class="col-md-3 col-12 mb-2 align-self-center">
            <div class="mb-2">Incassato: </div>
            <mat-radio-group labelPosition="after" class="" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.datiTecnici.misuraTermosifone.incassato">
                <mat-radio-button class="mb-2 mr-2" [value]="true">Si</mat-radio-button>
                <mat-radio-button class="mb-2 mr-2" [value]="false">No</mat-radio-button>
            </mat-radio-group>
            <mat-hint class="text-danger" *ngIf="form.sections.datiTecnici.misuraTermosifone.incassato == undefined">Campo richiesto</mat-hint>
        </div>
        <div class="col-12 align-self-center">
                <app-input-number
                    (onNumChange)="form.sections.datiTecnici.misuraTermosifone.interasse = $event"
                    [step]="0.1"
                    [num]="form.sections.datiTecnici.misuraTermosifone.interasse"
                    [decimal]="true"
                    [payment]="false"
                    [small]="false"
                    label="Misura termosifone interasse cm:">
                </app-input-number>
            <div class="smallFont text-danger" *ngIf="!form.sections.datiTecnici.misuraTermosifone.interasse">Campo richiesto</div>
        </div>
    </div>
    <hr/>
    <div class="row">
        <div class="col-md-4 col-4">
            <div class="mb-2">Lavatrice presente</div>
            <mat-radio-group labelPosition="after" class="" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.datiTecnici.lavatricePresente">
                <mat-radio-button class="mb-2 mr-2" [value]="true">Si</mat-radio-button>
                <mat-radio-button class="mr-2" [value]="false">No</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="col-md-4 col-4">
            <div class="mb-2">Attacchi lavatrice da creare</div>
            <mat-radio-group labelPosition="after" class="mb-2" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.datiTecnici.attacchiLavatriceDaCreare">
                <mat-radio-button class="mb-2 mr-2" [value]="true">Si</mat-radio-button>
                <mat-radio-button class="mr-2" [value]="false">No</mat-radio-button>
            </mat-radio-group>

            <mat-form-field class="mr-2 full-width" appearance="standard">
                <mat-label>Note attacchi lavatrice</mat-label>
                <textarea matInput [(ngModel)]="form.sections.datiTecnici.attacchiLavatriceNote"></textarea>
            </mat-form-field>
        </div>
        <div class="col-md-4 col-4">
            <div class="mb-2">Lavatrice da mantenere</div>
            <mat-radio-group labelPosition="after" class="" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.datiTecnici.lavatriceDaMantenere">
                <mat-radio-button class="mb-2 mr-2" [value]="true">Si</mat-radio-button>
                <mat-radio-button class="mr-2" [value]="false">No</mat-radio-button>
            </mat-radio-group>
            <div class="smallFont text-danger" *ngIf="form.sections.datiTecnici.lavatriceDaMantenere == undefined || form.sections.datiTecnici.lavatriceDaMantenere == null">Campo richiesto</div>
        </div>
    </div>
    <hr/>
    <div class="row mb-3 smallfield">
        <div class="col-md-4 col-4">
            <div class="mb-2">Boiler presente</div>
            <mat-radio-group labelPosition="after" class="" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.datiTecnici.boilerPresente">
                <mat-radio-button class="mb-2 mr-2" [value]="true">Si</mat-radio-button>
                <mat-radio-button class="mr-2" [value]="false">No</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="col-md-4 col-4">
            <div class="mb-2">Boiler da mantenere</div>
            <mat-radio-group labelPosition="after" class="" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.datiTecnici.boilerDaMantenere">
                <mat-radio-button class="mb-2 mr-2" [value]="true">Si</mat-radio-button>
                <mat-radio-button class="mr-2" [value]="false">No</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <hr/>
    <div class="row mb-3 smallfield" >
        <div class="col-md-4 col-4 align-self-center">
            <div class="mb-2">Sanitari</div>
            <mat-radio-group labelPosition="after" class="" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.datiTecnici.sanitariTipoId">
                <mat-radio-button class="d-block mb-2 mr-2" [value]="tipoSanitari.id"  *ngFor="let tipoSanitari of tipiSanitari">{{tipoSanitari.descrizione}}</mat-radio-button>
                <span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.datiTecnici.sanitariTipoId" (click)="form.sections.datiTecnici.sanitariTipoId = null">
					backspace
				</span>
            </mat-radio-group>
        </div>
        <div class="col-md-4 col-4">
            <div class="mb-2">Bidet</div>
            <mat-radio-group labelPosition="after" class="" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.datiTecnici.bidetPresente">
                <mat-radio-button class="mb-2 mr-2" [value]="true">Si</mat-radio-button>
                <mat-radio-button class="mr-2" [value]="false">No</mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="col-md-4 col-4">
            <div class="mb-2">Wc presente</div>
            <mat-radio-group labelPosition="after" class="" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.datiTecnici.wcPresente">
                <mat-radio-button class="mb-2 mr-2" [value]="true">Si</mat-radio-button>
                <mat-radio-button class="mr-2" [value]="false">No</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <hr/>

    <div class="row mb-3 smallfield">
        <div class="col-md-4 col-4">
            <div class="mb-2">Scarico WC</div>
            <mat-radio-group required labelPosition="after" class="" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.datiTecnici.scaricoWcId">
            	<mat-radio-button class="d-block mb-2 mr-2" [value]="scaricoWc.id"  *ngFor="let scaricoWc of tipiScaricoWc">{{scaricoWc.descrizione}}</mat-radio-button>
				<span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.datiTecnici.scaricoWcId" (click)="form.sections.datiTecnici.scaricoWcId = null">
					backspace
				</span>            
            </mat-radio-group>
            <div class="smallFont text-danger" *ngIf="!form.sections.datiTecnici.scaricoWcId">Campo richiesto</div>
        </div>
        <div class="col-md-4 col-4">
            <div class="mb-2">Cassetta scarico WC</div>
            <mat-radio-group labelPosition="after" class="" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.datiTecnici.cassettaScaricoId">
            	<mat-radio-button class="d-block mb-2 mr-2" [value]="cassettaScaricoWc.id"  *ngFor="let cassettaScaricoWc of tipiCassettaScaricoWc">{{cassettaScaricoWc.descrizione}}</mat-radio-button>
				<span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.datiTecnici.cassettaScaricoId" (click)="form.sections.datiTecnici.cassettaScaricoId = null">
					backspace
				</span>           		
            </mat-radio-group>
        </div>
        <div class="col-md-4 col-4">
            <div class="mb-2">Comando sciacquone a rubinetto (flusso rapido)</div>
            <mat-radio-group labelPosition="after" class="" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.datiTecnici.comandoSciaquone">
                <mat-radio-button class="mb-2 mr-2" [value]="true">Si</mat-radio-button>
                <mat-radio-button class="mr-2" [value]="false">No</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <hr/>

    <div class="row mb-3 smallfield">
        <div class="col-md-4 col-4">
            <div class="mb-2">Adesione piastrelle esistenti</div>
            <mat-radio-group labelPosition="after" class="" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.datiTecnici.adesionePiastrelleId">
            	<mat-radio-button class="d-block mb-2 mr-2" [value]="adesionePiastrelle.id"  *ngFor="let adesionePiastrelle of tipiAdesionePiastrelle">{{adesionePiastrelle.descrizione}}</mat-radio-button>
				<span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.datiTecnici.adesionePiastrelleId" (click)="form.sections.datiTecnici.adesionePiastrelleId = null">
					backspace
				</span>              
            </mat-radio-group>
        </div>
        <div class="col-md-4 col-4">
            <div class="mb-2">Muri perimetrali</div>
            <mat-radio-group required labelPosition="after" class="" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.datiTecnici.muriPerimetraliId">
                <mat-radio-button class="d-block mb-2 mr-2" [value]="muriPeri.id"  *ngFor="let muriPeri of tipiMuriPeri">{{muriPeri.descrizione}}</mat-radio-button>
				<span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.datiTecnici.muriPerimetraliId" (click)="form.sections.datiTecnici.muriPerimetraliId = null">
					backspace
				</span>            
            </mat-radio-group>
            <div class="smallFont text-danger" *ngIf="!form.sections.datiTecnici.muriPerimetraliId">Campo richiesto</div>
        </div>
        <div class="col-md-4 col-4">
            <div class="mb-2">Punto luce in prossimità del lavabo</div>
            <mat-radio-group labelPosition="after" class="" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.datiTecnici.puntoLuce">
                <mat-radio-button class="mb-2 mr-2" [value]="true">Si</mat-radio-button>
                <mat-radio-button class="mr-2" [value]="false">No</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    
    <hr/>

    <div class="row mb-3 smallfield">
        <!--Inserimento termosifone presente-->
        <div class="col-md-12 col-12">
            <div class="mb-2">Impianto con termosifone presente</div>
            <mat-radio-group labelPosition="after" class="" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.datiTecnici.imptermpres">
                <mat-radio-button class="mb-2 mr-2" [value]="true">Si</mat-radio-button>
                <mat-radio-button class="mr-2" [value]="false">No</mat-radio-button>
            </mat-radio-group>            
            <div class="smallFont text-danger" *ngIf="form.sections.datiTecnici.imptermpres == undefined || form.sections.datiTecnici.imptermpres == null">Campo richiesto</div>
        </div>
    </div>

    <div class="row mb-3 smallfield" [hidden]="!form.sections.datiTecnici.imptermpres">
        <div class="col-md-4 col-4">
            <div class="mb-2">Tipo di riscaldamento</div>
            <mat-radio-group required labelPosition="after" class="" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.datiTecnici.tipoRiscaldamentoId">
                <mat-radio-button class="d-block mb-2 mr-2" [value]="tipoRiscaldamento.id"  *ngFor="let tipoRiscaldamento of tipiRiscaldamento">{{tipoRiscaldamento.descrizione}}</mat-radio-button>
				<span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.datiTecnici.tipoRiscaldamentoId" (click)="form.sections.datiTecnici.tipoRiscaldamentoId = null">
					backspace
				</span>            
            </mat-radio-group>
            <div class="smallFont text-danger" *ngIf="!form.sections.datiTecnici.tipoRiscaldamentoId">Campo richiesto</div>
        </div>
        <div class="col-md-4 col-4">
            <div class="mb-2">Impianto</div>
            <mat-radio-group required labelPosition="after" class="" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.datiTecnici.tipoimpiantoId">
                <mat-radio-button class="d-block mb-2 mr-2" [value]="tipoImp.id"  *ngFor="let tipoImp of tipiImpianto">{{tipoImp.descrizione}}</mat-radio-button>
				<span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.datiTecnici.tipoimpiantoId" (click)="form.sections.datiTecnici.tipoimpiantoId = null">
					backspace
				</span>            
            </mat-radio-group>
            <div class="smallFont text-danger" *ngIf="!form.sections.datiTecnici.tipoimpiantoId">Campo richiesto</div>
        </div>
    </div>

    <div class="mb-3">
        <b>Nota: G.D.L. S.p.A. non esegue spostamenti, modifiche o collegamenti dell'impianto elettrico esistente.</b>
        <br/>
        <span class="text-danger">
            Qualsiasi tipo di modifica verrà accettata entro termine massimo di 3 giorni dalla data della sottoscrizione in caso contrario si dovranno
            quantificare i relativi costi che saranno completamente a carico dell'acquirente.
        </span>
    </div>
    <div>
        <mat-form-field class="full-width" appearance="fill">
            <mat-label>Note tecniche</mat-label>
            <textarea matInput [(ngModel)]="form.sections.datiTecnici.noteTecniche"></textarea>
        </mat-form-field>
    </div>
  </div>
</div>
