import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from '../http.service';
import { environment } from 'src/environments/environment';
import { blob } from 'aws-sdk/clients/codecommit';
@Injectable({
	providedIn: 'root'
})
export class SignatureService {
	apiUrl: string;
	apiKey: string;
	isV3: boolean = environment.isV3;
	apiUrlV3: string;
	apiKeyV3: string;
	constructor(
		public router: Router,
		public http: HttpClient,
		private httpService: HttpService
	) {

		/*if (this.httpService.isTest) {
			//AUTH V2
			//this.apiKey = "7862c63ec63d209a21e2d6c82a73ce5b";
			//this.apiUrl = "https://staging-api.yousign.com"
			//AUTH V3
			this.apiKey = 'k8Fq4OC6EbRD494nKFL4tVrIh3QkJMoX';
			this.apiUrl = 'https://api-sandbox.yousign.app/v3/';

		} else {
			this.apiKey = "28fa632c2f8053eee87c4df292618a4c";
			this.apiUrl = 'https://api.yousign.com'
		}*/

		/*	if (this.isV3) {
				this.apiKey = environment.apiKeyV3;
				this.apiUrl = environment.apiUrlV3;
			} else {
				this.apiKey = environment.apiKeyV2;
				this.apiUrl = environment.apiUrlV2;
	
			}*/
		this.apiKey = environment.apiKeyV2;
		this.apiUrl = environment.apiUrlV2;
		this.apiKeyV3 = environment.apiKeyV3;
		this.apiUrlV3 = environment.apiUrlV3;
	}

	public getUrl(): string {
		return this.apiUrl;
	}


	setHttpOptions(): any {
		return {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + this.apiKey,
			})
		};
	}

	setHttpOptionsV3(): any {
		return {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + this.apiKeyV3,
			})
		};
	}

	setHttpOptionsFile(): any {
		return {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + this.apiKeyV3,
			})
		};

	}

	setHttpOptionsDownloadFile(): any {
		return {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + this.apiKeyV3,
			}),
			responseType: 'blob' as 'json'
		};

	}

	sendFile(data: any): Observable<any> {
		return this.http.post<any>(this.apiUrl + '/files', data, this.setHttpOptions());
	}

	createProcedue(data: any): Observable<any> {
		return this.http.post<any>(this.apiUrl + '/procedures', data, this.setHttpOptions());
	}

	downoloadFile(id): Observable<any> {
		return this.http.get<any>(this.apiUrl + id + '/download', this.setHttpOptions());
	}

	downoloadCertificateFile(idMember): Observable<any> {
		return this.http.get<any>(this.apiUrl + idMember + '/proof?format=pdf', this.setHttpOptions());
	}

	createMember(data: any): Observable<any> {
		return this.http.post<any>(this.apiUrl + '/members', data, this.setHttpOptions());
	}


	//INIZIO NUOVE API DI FIRMA
	signature_requests(data: any): Observable<any> {
		return this.http.post<any>(this.apiUrlV3 + '/signature_requests', data, this.setHttpOptionsV3());
	}

	uploadDocument(data: any, idSignatureRequest: any): Observable<any> {
		return this.http.post<any>(this.apiUrlV3 + '/signature_requests/' + idSignatureRequest + '/documents', data, this.setHttpOptionsFile());
	}

	addSigner(data: any, idSignatureRequest: any): Observable<any> {
		return this.http.post<any>(this.apiUrlV3 + '/signature_requests/' + idSignatureRequest + '/signers', data, this.setHttpOptionsV3());
	}

	addFollower(data: any, idSignatureRequest: any): Observable<any> {
		return this.http.post<any>(this.apiUrlV3 + '/signature_requests/' + idSignatureRequest + '/followers', data, this.setHttpOptionsV3());
	}

	activateSignature(idSignatureRequest: any): Observable<any> {
		return this.http.post<any>(this.apiUrlV3 + '/signature_requests/' + idSignatureRequest + '/activate', {}, this.setHttpOptionsV3());
	}

	downloadAuditPDF(idSignatureRequest, idMember): Observable<any> {
		return this.http.get<blob>(this.apiUrlV3 + '/signature_requests/' + idSignatureRequest + '/signers/' + idMember + '/audit_trails/download', this.setHttpOptionsDownloadFile());
	}

	downloadPdf(idSignatureRequest, idFile): Observable<any> {
		return this.http.get<blob>(this.apiUrlV3 + '/signature_requests/' + idSignatureRequest + '/documents/' + idFile + '/download', this.setHttpOptionsDownloadFile());
	}

	createWebhook(data: any): Observable<any> {
		return this.http.post<any>(this.apiUrlV3 + '/webhooks/', data, this.setHttpOptionsV3());
	}
}

