<div id="accordionSeventeen">
    <div>
      <div  id="headingOne" >
        <h5 class="mb-0">
          <div class="text-center blueBg row">
            <div class="col-6 text-right">NUOVO PIATTO DOCCIA
                <mat-hint class="text-danger">*</mat-hint>
            </div>
            <div class="col-6 text-left"><mat-radio-group labelPosition="after" class="inline" color="primary"
                [(ngModel)]="optionPiattoDoccia.selected"
                (ngModelChange)="showDiv($event)">
                <mat-radio-button class="mb-2 mr-2" [value]="true">SI</mat-radio-button>
                <mat-radio-button class="mb-2 mr-2" [value]="false">NO</mat-radio-button>
            </mat-radio-group></div>
          </div>
        </h5>
      </div>
  
      <div *ngIf="isVisible" id="collapseEight" aria-labelledby="headingOne" data-parent="#accordionSeventeen">
        <div >
  
            <div class="text-center blueBg">
                PIATTO A BORDO LARGO COLORE 
                   <div class="d-inline-block">       	
                       <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo" (ngModelChange)="changeModel($event)" [(ngModel)]="form.sections.piattoDoccia.colorepiattolargId">
                           <mat-radio-button class="mb-2 mr-2" [value]="colore.id"  *ngFor="let colore of listPiattoDocciaColor">{{colore.descrizione}}</mat-radio-button>
                           <!-- <mat-radio-button class="mb-2 mr-2" value="BIANCO">BIANCO</mat-radio-button>
                           <mat-radio-button class="mr-2" value="NERO">NERO</mat-radio-button> -->
                       </mat-radio-group>
                   </div>
                <div class="text-right">
                    <span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.piattoDoccia.colorepiattolargId" (click)="form.sections.piattoDoccia.colorepiattolargId = null; form.sections.piattoDoccia.misurapiattolargId = null">
                       backspace
                   </span>
                </div>
            
            </div>
            <div class="blueCont mb-4"> 
                    <div *ngIf="form.sections.piattoDoccia.colorepiattolargId">
                        <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.piattoDoccia.misurapiattolargId">
                            <mat-radio-button *ngFor="let piatto of listPiattoDocciaDim" class="mb-2 mr-2" [value]="piatto.id">{{piatto.descrizione}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                <div class="smallFont text-info" *ngIf="!form.sections.piattoDoccia.colorepiattolargId && piattostretto">
                    Selezionato piatto doccia a bordo stretto
                </div>
            </div>
            <div class="text-center blueBg">
                <div class="radioCustomn">
                    <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo" (ngModelChange)="changeStretto($event)" [(ngModel)]="piattostretto">
                        <mat-radio-button class="mb-2 mr-2" [value]="true" >
                            PIATTI A BORDO STRETTO in metacrilato colato con rinforzo in vetroresina COLORE BIANCO
                        </mat-radio-button>
                        <!-- <mat-radio-button class="mb-2 mr-2" value="BIANCO">BIANCO</mat-radio-button>
                        <mat-radio-button class="mr-2" value="NERO">NERO</mat-radio-button> -->
                    </mat-radio-group>
                </div>
            </div>
            <div class="blueCont">        
                <mat-radio-group *ngIf="piattostretto" labelPosition="after" class="inline" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.piattoDoccia.misurapiattostrId">
                    <mat-radio-button *ngFor="let piattoStr of listPiattoDocciaStrDim" class="mb-2 mr-2" [value]="piattoStr.id">{{piattoStr.descrizione}}</mat-radio-button>
                    <span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.piattoDoccia.misurapiattostrId" (click)="form.sections.piattoDoccia.misurapiattostrId = null; piattostretto = false;">
                        backspace
                    </span>
                </mat-radio-group>
                <div class="smallFont text-info" *ngIf="form.sections.piattoDoccia.colorepiattolargId && !form.sections.piattoDoccia.misurapiattostrId">
                    Selezionato piatto doccia a bordo largo
                </div>
            </div>
            <div class="smallFont text-center text-danger">
                * Tagli massimi dei bordi per lato (profondit&agrave;, lunghezza sinistra, lunghezza destra) 
                solo contro i muri. I nostri piatti doccia sono strutturati per essere tagliati in opera ed 
                adattarsi alla misura della vasca
            </div>

            <div id="headingOne">
                <h5 class="mb-0">
                    <div class="text-center blueBg">
                        PIATTI EFFETTO PIETRA
                    </div>
            
                </h5>
            </div>
             
            <div class="blueCont"
                [ngClass]="{'onlyRedBorder': form.sections.piattoDoccia.colorepiateffpietraId && !form.sections.piattoDoccia.dimpiatteffpietraId}">
                <div class="d-inline-block">
                    <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                        (ngModelChange)="changeModelEffPietra($event)" [(ngModel)]="form.sections.piattoDoccia.colorepiateffpietraId">
                        <mat-radio-button class="mb-2 mr-2" [value]="colore.id" *ngFor="let colore of listColore">
                            {{colore.descrizione}}</mat-radio-button>
                        <span class="undo material-icons" matTooltip="Cancella selezione"
                            *ngIf="form.sections.piattoDoccia.colorepiateffpietraId" (click)="resetPiattoDocciaEffPietra()">
                            backspace
                        </span>
                    </mat-radio-group>
                </div>
            
                <div *ngIf="form.sections.piattoDoccia.colorepiateffpietraId && listMisure && listMisure.length>0">
                    <hr />
                    <p>DIMENSIONE</p>
                    <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                        [(ngModel)]="form.sections.piattoDoccia.dimpiatteffpietraId">
                        <mat-radio-button class="mr-2" [value]="misure.id" *ngFor="let misure of listMisure">{{misure.descrizione}}
                        </mat-radio-button>
                    </mat-radio-group>
                    <div class="smallFont text-danger"
                        *ngIf="form.sections.piattoDoccia.colorepiateffpietraId && !form.sections.piattoDoccia.dimpiatteffpietraId">
                        Selezione obbligatoria
                    </div>
                </div>
            </div>
            
            
  
        </div>
      </div>
    </div>
    
    </div>



