<div class="text-center {{classBg}}">
    OMAGGIO
</div>
<div class="{{classContent}}" [ngClass]="{'onlyRedBorder': !validOmaggio()}">
    <div class="row" *ngFor="let omaggio of form.sections.omaggio; let i = index;">
        <div class="col-6">
            <mat-form-field class="full-width">
                <mat-label>Omaggio</mat-label>
                <mat-select [(ngModel)]="omaggio.tipologiaId" (ngModelChange)="checkItem($event, i)" id="omaggio">
                    <mat-option>-- Omaggio --</mat-option>
                    <mat-option [disabled]="alreadySelected(om.id)" *ngFor="let om of availableOmaggio" [value]="om.id">
                        <a>{{om.descrizione}} <span style="font-weight: bold;">{{om.note ? (' - ' + om.note) : ''}}</span></a> 
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-4" *ngIf="omaggio.tipologiaId">
            <app-input-number
                    (onNumChange)="omaggio.quantita = $event"
                    [step]="1"
                    [num]="omaggio.quantita"
                    [decimal]="false"
                    [payment]="false"
                    [small]="false"
                    label="Quantità"></app-input-number>
            <div class="smallFont text-danger" *ngIf="omaggio.tipologiaId && !omaggio.quantita">
                Campo obbligatorio
            </div>
        </div>
        <div class="col-2" *ngIf="form.sections.omaggio.length >= 1">
            <button mat-icon-button color="warn" (click)="removeItem(i)">
                <mat-icon>cancel</mat-icon>
            </button>
        </div>
    </div>
    <div>
        <button mat-flat-button class="{{classButton}}" (click)="addOmaggio()">Aggiungi Omaggio</button>
    </div>

</div>
