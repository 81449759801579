export class SignatureRequestDto {
    // Add your class properties here
    name: string;
    delivery_mode: string;
    custom_experience_id?: string;
    signers_allowed_to_decline: boolean = true;
    external_id?: string;

    // Add your constructor logic here
    constructor(name: string, delivery_mode: string) {
        this.name = name;
        this.delivery_mode = delivery_mode;
    }

    // Add your class methods here
}