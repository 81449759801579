export class InstallazioneAccessoriDto {
	aggiuntagas	: boolean;
	asservazione: boolean;
	cestaidraulica:	boolean;
	contotermico: boolean;
    forimurocm:	number;
	forimuroqta: number;
	id:	number;
	installazioneconpred:boolean;
	installazionesenzapred:boolean;
    kitlineaidr:number;
	ordineId: number;
	ordineIdordine:	string;
	pompacaricocondensa:boolean;
 	ponteggio: boolean;
	posizioneueDescrizione:string;
	posizioneueId:number;
	qtaue:number;
	smontaggiovecchioimp:boolean;
	staffeue:boolean;
	note:string;
	qtainstconpred:number;
	qtainstsenzapred:number;
	numpompecondensa: number;
	suoloPubblico:boolean;
}
