import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PaintableModule } from 'paintablejs/angular';
import { MaterialModule } from '../../material/material.module';
import { SharedModule } from '../../shared/shared.module';
import { SafePipe } from 'src/app/safe.pipe';
import { CucinaComponent } from './cucina/cucina.component';
import { CucinaPrintComponent } from './cucina/cucina-print/cucina-print.component';
import { CucinaProdottoComponent } from './cucina/cucina-prodotto/cucina-prodotto.component';
import { DatiTecniciComponent } from './cucina/dati-tecnici/dati-tecnici.component';

@NgModule({
  declarations: [CucinaComponent, CucinaPrintComponent, CucinaProdottoComponent, DatiTecniciComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MaterialModule,
    PaintableModule,
    SharedModule,
  ],
  providers: [
    SafePipe,
  ]
})
export class LorenModule { }
