import { Component, Input, OnInit } from '@angular/core';
import { constants } from 'src/app/modules/classes/constants';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { MontascaleService } from 'src/app/services/montascale/montascale.service';
@Component({
  selector: 'app-flow-x-curvilineo',
  templateUrl: './flow-x-curvilineo.component.html',
  styleUrls: ['./flow-x-curvilineo.component.scss']
})
export class FLOWXCurvilineoComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: any;
  optionalFlowXTipoPartenzaTrascodifiche: Trascodifica[] = []; 
  optionalFlowXTipoArrivoTrascodifiche: Trascodifica[] = []; 
  optionalFlowXTipoGuidaTrascodifiche: Trascodifica[] = []; 
  optionalFlowXposizioneJoyTrascodifiche: Trascodifica[] = [];

  public constants = constants;
  constructor(private montascaleService: MontascaleService) { }

  ngOnInit(): void {

    this.trascodifiche.forEach(el => {
      switch(el.codice){
        case constants.OPTIONAL_FLOW_X_TIPO_GUIDA: {
          this.optionalFlowXTipoGuidaTrascodifiche.push(el);
        }
        break;
        case constants.OPTIONAL_FLOW_X_POS_JOY: {
          this.optionalFlowXposizioneJoyTrascodifiche.push(el);
        }
        break;
      }
    });
    
    this.montascaleService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
			if(res[0] !== undefined){
				this.form.sections.montascale = res[0];
			}
    })
  }

  onFlowSedileOptionsChange(index: number): void {
    this.form.sections.flowXSedileOptionsAvailable[index].selected = !this.form.sections.flowXSedileOptionsAvailable[index].selected;
  }

  onFlowSedileVarieOptionsChange(index: number): void {
    this.form.sections.flowXVarieOptionsAvailable[index].selected = !this.form.sections.flowXVarieOptionsAvailable[index].selected;
    this.form.sections.flowXVarieOptionsAvailable[index].quantity = null; // al change sbianchiamo la quantità
  }
  
  convertTrascodifica(id: number) {
    const matchingTrascodifica = this.trascodifiche.find(el => el.id === id);
    if (matchingTrascodifica) {
      return matchingTrascodifica.descrizione;
    }
  
    return ''; 
  }
}
