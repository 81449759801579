<div id="accordionFive">
    <div>
      <div  id="headingOne"  class="text-center bagnoPraticoBg">
        <h5 class="mb-0 row">
          <div class="col-6 text-right">
             NUOVI SANITARI
              <mat-hint class="text-danger">*</mat-hint>
          </div>
          <div class="col-6 text-left"><mat-radio-group labelPosition="after" class="inline" color="accent"
              [(ngModel)]="optionNuoviSanitariOmaggio.selected"
              (ngModelChange)="showDiv($event)">
              <mat-radio-button class="mb-2 mr-2" [value]="true">SI</mat-radio-button>
              <mat-radio-button class="mb-2 mr-2" [value]="false">NO</mat-radio-button>
          </mat-radio-group></div>
        </h5>
      </div>
  
      <div *ngIf="isVisible" id="collapseOne" aria-labelledby="headingOne" data-parent="#accordionFive">
        <div>
          <div class="bagnoPraticoCont" [ngClass]="{'onlyRedBorder': !this.formController.validNuoviSan(this.form)}">
              <div class="">
                  <div class="row mb-2" *ngFor="let san of form.sections.nuoviSanitariDto; let i = index;">
                      <div class="col-3">
                          <mat-form-field class="full-width" appearance="standard" *ngIf="this.avaModel[i] && this.avaModel[i].length > 0">
                              <mat-label>Modello sanitari</mat-label>
                              <mat-select (ngModelChange)="changeModel($event, i)" [(ngModel)]="san.modsanitariId">
                                  <mat-option [value]="null">-- Modello sanitari --</mat-option>
                                  <mat-option *ngFor="let option of avaModel[i]" [value]="option.id">
                                      {{option.descrizione}}
                                  </mat-option>
                              </mat-select>
                          </mat-form-field>
                      </div>
          
                      <div class="col-3">
                          <mat-form-field class="full-width" appearance="standard" *ngIf="this.avaScarico[i] && this.avaScarico[i].length>0 && san.modsanitariId">
                              <mat-label>Tipo scarico</mat-label>
                              <mat-select (ngModelChange)="changeScarico($event, i)" [(ngModel)]="san.tiposcaricoId">
                                  <mat-option [value]="null">-- Tipo scarico --</mat-option>
                                  <mat-option *ngFor="let option of avaScarico[i]" [value]="option.id">
                                      {{option.descrizione}}
                                  </mat-option>
                              </mat-select>
                              <mat-hint class="text-danger" *ngIf="san.modsanitariId && !san.tiposcaricoId">
                                  Campo obbligatorio
                              </mat-hint>
                          </mat-form-field>
                      </div>
          
                      <div class="col-3">
                          <mat-form-field class="full-width" appearance="standard" *ngIf="this.avaSanitari[i] && this.avaSanitari[i].length>0 && san.tiposcaricoId">
                              <mat-label>Tipo sanitari</mat-label>
                              <mat-select (ngModelChange)="changeSanitari($event, i)" [(ngModel)]="san.tiposanitariId">
                                  <mat-option [value]="null">-- Tipo sanitari --</mat-option>
                                  <mat-option *ngFor="let option of avaSanitari[i]" [value]="option.id">
                                      {{option.descrizione}}
                                  </mat-option>
                              </mat-select>
                              <mat-hint class="text-danger" *ngIf="san.tiposcaricoId && !san.tiposanitariId">
                                  Campo obbligatorio
                              </mat-hint>
                          </mat-form-field>
                      </div>
          
                      <div class="col-2">
                          <mat-form-field class="full-width" appearance="standard" *ngIf="this.avaTipo[i] && this.avaTipo[i].length>0 && san.tiposanitariId">
                              <mat-label>Tipo prodotto</mat-label>
                              <mat-select [(ngModel)]="san.tipoprodottoId">
                                  <mat-option [value]="null">-- Tipo prodotto --</mat-option>
                                  <mat-option *ngFor="let option of avaTipo[i]" [value]="option.id">
                                      {{option.descrizione}}
                                  </mat-option>
                              </mat-select>
                              <mat-hint class="text-danger" *ngIf="san.tiposanitariId && !san.tipoprodottoId">
                                  Campo obbligatorio
                              </mat-hint>
                          </mat-form-field>
                      </div>
                      <div class="col-1 align-self-center" *ngIf="form.sections.nuoviSanitariDto.length>=1">
                          <button mat-icon-button color="warn" (click)="removeSan(i)">
                              <mat-icon>cancel</mat-icon>
                          </button>
                      </div>
                  </div>
                  <div>
                      <button mat-flat-button class="praticoButton" (click)="addSan()">Aggiungi sanitario</button>
                  </div>
              </div>
          </div>
        </div>
      </div>
     </div>
    </div>
