<div class="zucchettiMontascaleBg"> MODELLO DEPURATORE</div>
<div class="zucchettiMontascaleCont">
    <div class="row align-items-center">
        <div class="col-sm">
            <a>VENDITA ABBINATA AD UN ALTRO PRODOTTO</a>
        </div>
        <div class="col-sm">
            <mat-radio-group class="inline" color="primary" [(ngModel)]="depuratoreOptionChoice" (ngModelChange)="setDepuratore()">
                <mat-radio-button class="mb-2 mr-2" [value]="false">NO</mat-radio-button>
                <mat-radio-button class="mb-2 mr-2" [value]="true">SI</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
</div>

<ng-container *ngIf="depuratoreOptionChoice; else noDepuratoreOption">
    <div class="zucchettiMontascaleBg"> MODELLO DEPURATORE - VENDITA ABBINATA </div>
    <div class="zucchettiMontascaleCont">
        <div class="row align-items-center">
            <div class="col-sm">
                <a>CONTRIBUTO DI INSTALLAZIONE DEL DEPURATORE mod. {{depuratoreTrasc?.descrizione}} OMAGGIO</a>
            </div>
            <div class="col-sm">
                <span class="mr-2">Quantità </span>
                <app-input-number
                    (onNumChange)="form.sections.depuratore.quantita = $event"
                    [step]="1"
                    [num]="form.sections.depuratore.quantita"
                    [decimal]="false"
                    [payment]="false"
                    [small]="true"
                    label="">
                </app-input-number>
            </div>
        </div> 
        <div class="row mt-2">
            <div class="col-sm">
                <a>  Inclusa CENTRALINA WI-FI, CONTROLLO CON APP, ASSISTENZA CENTALE OPERATIVA</a>
                <br>
                <a class="text-danger" style="font-size: 9px;">  Inclusa Installazione e collaudo. Il collegamento con la centrale operativa si rinnova tacitamente al termine del 1° anno.</a>
            </div>
        </div>   
    </div>
</ng-container>

<ng-template #noDepuratoreOption>
    <div class="zucchettiMontascaleBg"> MODELLO DEPURATORE</div>
    <div class="zucchettiMontascaleCont">
        <div class="row align-items-center">
            <div class="col-sm">
                <a>DEPURATORE ACQUA mod. {{depuratoreTrasc?.descrizione}}</a>
            </div>
            <div class="col-sm">
                <span class="mr-2">Quantità </span>
                <app-input-number
                    (onNumChange)="form.sections.depuratore.quantita = $event"
                    [step]="1"
                    [num]="form.sections.depuratore.quantita"
                    [decimal]="false"
                    [payment]="false"
                    [small]="true"
                    label="">
                </app-input-number>
            </div>
        </div> 
        <div class="row mt-2">
            <div class="col-sm">
                <a> Inclusa CENTRALINA WI-FI, CONTROLLO CON APP, ASSISTENZA CENTALE OPERATIVA </a>
                <br>
                <a class="text-danger" style="font-size: 9px;"> Inclusa Installazione e collaudo. Il collegamento con la centrale operativa si rinnova tacitamente al termine del 1° anno.</a>
            </div>
        </div>   
    </div>
</ng-template>

