<div class="pt-5 text-center mb-4">
</div>

<div class="formContainer">
    <div class="text-center mb-4">
        <h4>
            <mat-icon class="icon">insert_drive_file</mat-icon>
            Modulo d'ordine compilazione manuale
        </h4>
    </div>
    
    <hr/>

    <div *ngIf="ordine?.statopraticaCodice == 'F'" >
        <div class="text-center mb-4">
            <h4>
                <h3 class="text-danger">L'ordine è già stato firmato, non è possibile effettuare ulteriori caricamenti.</h3>
            </h4>
        </div>
    </div>

     <div *ngIf="ordine?.statopraticaCodice !== 'F'"> 
        <div class="moduleContainer mb-3">
            <div class="" *ngIf="currentStep == 0">
                <div class="container mb-5" appDnd (fileDropped)="handleFileDropped($event)" [ngClass]="{'hideUp':arrayFiles.length > 0}">
                    <input type="file" #fileDropRef id="fileDropRef" multiple (change)="handleFileDropped($any($event).target.files)" />
                    <img src="assets/images/ic-upload-file.svg" alt="" style="margin-bottom: 10px;">
                    <h3>Trascina qui il contratto</h3>
                    <h3>oppure</h3>
                    <label for="fileDropRef">Cerca nel dispositivo</label>
                </div>
            </div>            
        </div>

        <div class="uploadBlock mb-5">
        
            <div class="files-list" *ngIf="arrayFiles.length>0">
                <div class="single-file" *ngFor="let file of arrayFiles; let i = index">
                    <img src="assets/images/ic-file.svg" width="45px" alt="file">
                    <div class="info">
                        <h4 class="name">
                            {{ file?.name }}
                        </h4>
                        <p class="size">
                            {{ formControllerService.formatBytes(file?.size) }} - {{file?.progress}}%
                        </p>
                        <app-progress [progress]="file?.progress"></app-progress>
                    </div>
                    <img src="assets/images/ic-delete-file.svg" class="delete" width="20px" alt="file" (click)="deleteFile(i)">
                </div>
            </div>
            <div *ngIf="loadingFiles" class="mb-3">
                <div class="mb-2">Caricamento in corso...</div>
                <mat-progress-bar mode="buffer"></mat-progress-bar>
            </div>
        
            <div class="text-end">
                <button [disabled]="isUploadDisabled() || upload || loadingFiles" mat-flat-button color="primary" (click)="uploadToS3(arrayFiles)">Salva ordine</button>
            </div>
        </div>
      </div> 
    </div>
    
    <div class="modal fade" id="confirmModal" tabindex="-1">
        <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title">Impossibile caricare il file. Rinominare il file senza i caratteri accentati ed i seguenti caratteri : /, |, %, $, ?, €</h3>
                </div>
                
                <div class="modal-footer">
                    <button mat-button (click)="openModal()">Annulla</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="sizeExceededModal" tabindex="-1">
        <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title">Impossibile caricare il/i file. Limite di dimensione del file superato.</h3>
                </div>
                <div class="modal-body">
                    <ul>
                        <li *ngFor="let element of arrayFileExceeded">{{element.name}}</li>
                    </ul>
                    <hr>
                    <mat-hint>Limite di dimensione: 15Mb</mat-hint>
                </div>
                <div class="modal-footer">
                    <button mat-button (click)="openSizeModal()">Chiudi</button>
                </div>
            </div>
        </div>
    </div>