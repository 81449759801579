import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrderlogService extends HttpService{


  constructor(
      public router: Router,
      public http: HttpClient
  ) {
    super(router, http);
  }
  
  
  	save(ordinelog: any): Observable<any> {			
		return this.http.post<any>(this.apiUrl + 'ordinelog', ordinelog, this.setHttpOptions()).pipe(
			retry(0),
			catchError(err => this.handleError(err))
		);
	}
	
	update(ordinelog: any): Observable<any> {			
		return this.http.put<any>(this.apiUrl + 'ordinelog', ordinelog, this.setHttpOptions()).pipe(
			retry(0),
			catchError(err => this.handleError(err))
		);
	}
}
