import { Component, Input, OnInit } from '@angular/core';
import { constants } from 'src/app/modules/classes/constants';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';

@Component({
  selector: 'poltrone-promo',
  templateUrl: './poltrone-promo.component.html',
  styleUrls: ['./poltrone-promo.component.scss']
})
export class PoltronePromoComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];

  searchModello:string;
  promo: Trascodifica[] = [];
  modelli: Trascodifica[] = [];

  constructor(
    private formControllerService: FormControllerService
  ) { }

  ngOnInit(): void {
    this.promo = this.trascodifiche.filter((res: Trascodifica) => res.codice === constants.COD_PROMO_POLTRONA);

    if (this.form.ordine.promoId) {
      const selectedModel = this.trascodifiche.find((res: Trascodifica) => res.id === this.form.ordine.promoId);

      if (selectedModel) {
        this.form.sections.promo = selectedModel.parentId;

        this.modelli = this.trascodifiche.filter(
          (res: Trascodifica) => res.codice === constants.COD_MOD_PROMO_POLTRONA && res.parentId === this.form.sections.promo
        );
      }
    }
  }

  onPromoChange(promoId: number): void {
    this.modelli = this.trascodifiche.filter(
      (res: Trascodifica) => res.codice === constants.COD_MOD_PROMO_POLTRONA && res.parentId === promoId
    );

    this.form.ordine.promoId = null;
  }

  returnPromoDesc(id:number): string {
    if(id !== null && id !== undefined){
      return this.formControllerService.getTrascodificaById(id, this.trascodifiche)?.descrizione || '';
    }else{
      return '';
    }
  }

  includeModello(i: any): boolean {
    if (i !== null && i !== undefined) {
      if (!this.searchModello || this.searchModello.trim() === '') {
        return false; 
      } else {
        const searchString = String(this.searchModello).trim()?.toLowerCase();
        const inputString = String(i).toLowerCase();
        return !inputString.startsWith(searchString); 
      }
    }
    return true; 
  }
}
