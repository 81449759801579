import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../http.service';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EstimateFotovoltaicoService extends HttpService {

	constructor(
		public router: Router,
    public http: HttpClient,
		private httpService:HttpService
	) {
    super(router, http);
	}

  findById(id: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'fotovoltaicoUt/' + id, this.setHttpOptions())
    .pipe(
        retry(0),
        catchError(this.handleError)
    );
    }
}