<div class="text-center arielBg mb-2">
    SISTEMI IBRIDI E POMPE DI CALORE
</div>



<div class="row originalRow">
    <div class="col-lg-6 mb-2" *ngIf="prodottiSistema && prodottiSistema.get(constants.TYPE_TIPOPRODOTTOSISTEMA_AURUM)">
        <div class="text-center arielBg">{{prodottiSistema.get(constants.TYPE_TIPOPRODOTTOSISTEMA_AURUM).descrizione}}</div>
        <div class="greenCont" *ngIf="trascodifiche && caldaie && caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_AURUM)">
            <div class="row originalRow mb-1"
                *ngFor="let aurum of caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_AURUM); let i = index">
                <div class="col-7">
                    <mat-checkbox class="smalFt" color="primary"
                        (change)="update($event,aurum,prodottiSistema.get(constants.TYPE_TIPOPRODOTTOSISTEMA_AURUM).id, constants.TYPE_TIPOPRODOTTOSISTEMA_AURUM)"                        
                        [(ngModel)]="caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_AURUM)[i].checked">
                        {{aurum.modibrepomcalDescrizione}} </mat-checkbox>
                        <span class="smallFont">{{aurum.note}}</span>    
                </div>
                <div class="col-5 smalFt">
                    <mat-radio-group class="smalFt"
                        [disabled]="!caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_AURUM)[i].checked"
                        [(ngModel)]="caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_AURUM)[i].potenzaibrepomcalId"
                        (change)="checkPotenza($event,prodottiSistema.get(constants.TYPE_TIPOPRODOTTOSISTEMA_AURUM).id ,aurum.modibrepomcalId, constants.TYPE_TIPOPRODOTTOSISTEMA_AURUM)">
                        <mat-radio-button color="primary" class="mr-2"
                            *ngFor="let potenza of aurum.listPotenza; let f = index;" [value]="potenza.id">
                            {{potenza.descrizione}}
                        </mat-radio-button>
                    </mat-radio-group>
                    <div class="smallFont text-danger"
                        *ngIf="aurum.listPotenza.length > 0 && (caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_AURUM)[i].checked && !caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_AURUM)[i].potenzaibrepomcalId)">
                        selezione obbligatoria
                        <mat-checkbox [hidden]="true" [checked]="setAurum(false)" [(ngModel)]="checkAURUM"></mat-checkbox>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="col-lg-6 mb-2" *ngIf="prodottiSistema && prodottiSistema.get(constants.TYPE_TIPOPRODOTTOSISTEMA_SLIM_AURUM)">
        <div class="row originalRow">
            <div class="col-lg-12">
                <div class="text-center arielBg">{{prodottiSistema.get(constants.TYPE_TIPOPRODOTTOSISTEMA_SLIM_AURUM).descrizione}}
                </div>
                <div class="greenCont">
                    <div *ngIf="trascodifiche && caldaie && caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_SLIM_AURUM)">
                        <div class="row originalRow mb-1"
                            *ngFor="let aurum of caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_SLIM_AURUM); let i = index">
                            <div class="col-7">
                                <mat-checkbox class="smalFt" color="primary"
                                    (change)="update($event,aurum,prodottiSistema.get(constants.TYPE_TIPOPRODOTTOSISTEMA_SLIM_AURUM).id , constants.TYPE_TIPOPRODOTTOSISTEMA_SLIM_AURUM)"                                    
                                    [(ngModel)]="caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_SLIM_AURUM)[i].checked">
                                    {{aurum.modibrepomcalDescrizione}} </mat-checkbox><span class="smallFont">{{aurum.note}}</span>    
                            </div>
                            <div class="col-5 smalFt">
                                <mat-radio-group class="smalFt"
                                    [disabled]="!caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_SLIM_AURUM)[i].checked"                                    
                                    [(ngModel)]="caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_SLIM_AURUM)[i].potenzaibrepomcalId"
                                    (change)="checkPotenza($event,prodottiSistema.get(constants.TYPE_TIPOPRODOTTOSISTEMA_SLIM_AURUM).id ,aurum.modibrepomcalId , constants.TYPE_TIPOPRODOTTOSISTEMA_SLIM_AURUM)">
                                    <mat-radio-button color="primary" class="mr-2"
                                        *ngFor="let potenza of aurum.listPotenza; let f = index;" [value]="potenza.id">
                                        {{potenza.descrizione}}
                                    </mat-radio-button>
                                </mat-radio-group>
                                <div class="smallFont text-danger"
                                    *ngIf="aurum.listPotenza.length > 0 && (caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_SLIM_AURUM)[i].checked && !caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_SLIM_AURUM)[i].potenzaibrepomcalId)">
                                    selezione obbligatoria
                                    <mat-checkbox [hidden]="true" [checked]="setSlim(false)" [(ngModel)]="checkSlim"></mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row originalRow">
            <div class="col-lg-12 mb-2" *ngIf="prodottiSistema && prodottiSistema.get(constants.TYPE_TIPOPRODOTTOSISTEMA_P_FULL)">
                <div class="text-center arielBg">{{prodottiSistema.get(constants.TYPE_TIPOPRODOTTOSISTEMA_P_FULL).descrizione}}</div>
                <div class="greenCont">
                    <div *ngIf="trascodifiche && caldaie && caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_P_FULL)">
                        <div class="row originalRow mb-1"
                            *ngFor="let aurum of caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_P_FULL); let i = index">
                            <div class="col-6">
                                <mat-checkbox class="smalFt" color="primary"
                                    (change)="update($event,aurum,prodottiSistema.get(constants.TYPE_TIPOPRODOTTOSISTEMA_P_FULL).id , constants.TYPE_TIPOPRODOTTOSISTEMA_P_FULL)"                                  
                                    [(ngModel)]="caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_P_FULL)[i].checked">
                                    {{aurum.modibrepomcalDescrizione}} </mat-checkbox><span class="smallFont">{{aurum.note}}</span>    
                            </div>
                            <div class="col-6 smalFt">
                                <mat-radio-group class="smalFt"
                                    [disabled]="!caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_P_FULL)[i].checked"                                    
                                    [(ngModel)]="caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_P_FULL)[i].potenzaibrepomcalId"
                                    (change)="checkPotenza($event,prodottiSistema.get(constants.TYPE_TIPOPRODOTTOSISTEMA_P_FULL).id ,aurum.modibrepomcalId , constants.TYPE_TIPOPRODOTTOSISTEMA_P_FULL)">
                                    <mat-radio-button color="primary" class="mr-2"
                                        *ngFor="let potenza of aurum.listPotenza; let f = index;" [value]="potenza.id"
                                        >
                                        {{potenza.descrizione}}
                                    </mat-radio-button>
                                </mat-radio-group>
                                <div class="smallFont text-danger"
                                    *ngIf="aurum.listPotenza.length > 0 && (caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_P_FULL)[i].checked && !caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_P_FULL)[i].potenzaibrepomcalId)">
                                    selezione obbligatoria
                                    <mat-checkbox [hidden]="true" [checked]="setFull(false)" [(ngModel)]="checkFull"></mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row originalRow">
    <div class="col-lg-6"  *ngIf="prodottiSistema && prodottiSistema.get(constants.TYPE_TIPOPRODOTTOSISTEMA_IBRID)">
        <div class="text-center arielBg">{{prodottiSistema.get(constants.TYPE_TIPOPRODOTTOSISTEMA_IBRID).descrizione}}</div>
        <div class="greenCont">
            <div *ngIf="trascodifiche && caldaie && caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_IBRID)">
                <div class="row originalRow mb-1"
                    *ngFor="let aurum of caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_IBRID); let i = index">
                    <div class="col-7">
                        <mat-checkbox  class="smalFt" color="primary"
                            (change)="update($event,aurum,prodottiSistema.get(constants.TYPE_TIPOPRODOTTOSISTEMA_IBRID).id , constants.TYPE_TIPOPRODOTTOSISTEMA_IBRID)"                            
                            [(ngModel)]="caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_IBRID)[i].checked">
                            {{aurum.modibrepomcalDescrizione}} </mat-checkbox><span class="smallFont">{{aurum.note}}</span>    
                    </div>
                    <div class="col-5 smalFt">
                        <mat-radio-group class="smalFt"
                            [disabled]="!caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_IBRID)[i].checked"
                            [(ngModel)]="caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_IBRID)[i].potenzaibrepomcalId"
                            (change)="checkPotenza($event,prodottiSistema.get(constants.TYPE_TIPOPRODOTTOSISTEMA_IBRID).id ,aurum.modibrepomcalId , constants.TYPE_TIPOPRODOTTOSISTEMA_IBRID)">
                            <mat-radio-button color="primary" class="mr-2"
                                *ngFor="let potenza of aurum.listPotenza; let f = index;" [value]="potenza.id"
                                >
                                {{potenza.descrizione}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div class="smallFont text-danger"
                            *ngIf="aurum.listPotenza.length > 0 && (caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_IBRID)[i].checked && !caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_IBRID)[i].potenzaibrepomcalId)">
                            selezione obbligatoria
                            <mat-checkbox [hidden]="true" [checked]="setIbrid(false)" [(ngModel)]="checkIbrid"></mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-6 mb-2" *ngIf="prodottiSistema && prodottiSistema.get(constants.TYPE_TIPOPRODOTTOSISTEMA_POWER)">
        <div class="text-center arielBg"> {{prodottiSistema.get(constants.TYPE_TIPOPRODOTTOSISTEMA_POWER).descrizione}}</div>
        <div class="greenCont">
            <div *ngIf="trascodifiche && caldaie && caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_POWER)">
                <div class="row originalRow"
                    *ngFor="let aurum of caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_POWER); let i = index">
                    <div class="col-6">
                        <mat-checkbox  class="smalFt" color="primary"
                            (change)="update($event,aurum,prodottiSistema.get(constants.TYPE_TIPOPRODOTTOSISTEMA_POWER).id , constants.TYPE_TIPOPRODOTTOSISTEMA_POWER)"                            
                            [(ngModel)]="caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_POWER)[i].checked">
                            {{aurum.modibrepomcalDescrizione}} </mat-checkbox>
                        <span class="smallFont">{{aurum.note}}</span>    
                    </div>
                    <div class="col-6 smalFt">
                        <mat-radio-group class="smalFt"
                            [disabled]="!caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_POWER)[i].checked"
                            [(ngModel)]="caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_POWER)[i].potenzaibrepomcalId">
                            <mat-radio-button color="primary" class="mr-2"
                                *ngFor="let potenza of aurum.listPotenza; let f = index;" [value]="potenza.id"
                                (change)="checkPotenza($event,prodottiSistema.get(constants.TYPE_TIPOPRODOTTOSISTEMA_POWER).id ,aurum.modibrepomcalId, constants.TYPE_TIPOPRODOTTOSISTEMA_POWER)">
                                {{potenza.descrizione}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div class="smallFont text-danger"
                            *ngIf="aurum.listPotenza.length > 0 && (caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_POWER)[i].checked && !caldaie.get(constants.TYPE_TIPOPRODOTTOSISTEMA_POWER)[i].potenzaibrepomcalId)">
                            selezione obbligatoria
                            <mat-checkbox [hidden]="true" [checked]="setPower(false)" [(ngModel)]="checkPower"></mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row originalRow">
    <div class="col-lg-6">
        <div class="anagraficaContainer">
            <mat-form-field class="full-width" appearance="fill">
                <mat-label>Note</mat-label>
                <textarea matInput [(ngModel)]="form.ordine.note"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="col-6 text-danger smallFont mb-2">Note: tutti i sistemi che prevedono la caldaia
        a condensazione sono comprensivi di valvole
        termostatiche per i termisifoni, fino a un
        massimo di 15. Tutti i prezzi includono tutte
        le lavorazioni necessarie (es. intubamenti,
        collegamenti, filtri ecc…)</div>
</div>