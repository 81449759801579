export class DatiTecniciLorenDTO {
	id: number;
	pianointervento: number;
	ascensore: boolean;
	scale: boolean;
    smontaggioCucina: boolean;
	ordineId: number
	ordineIdordine: string;
}

export class DatiTecniciLoren {
	id: number;
	pianointervento: number;
	ascensore: boolean;
	scale: boolean;
    smontaggioCucina: boolean;
	ordineId: number
}