import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewEstimateComponent } from './new-estimate/new-estimate.component';
import { MatSortModule } from '@angular/material/sort';
import { MaterialModule } from '../material/material.module';
import { ArielModule } from '../seller/ariel/ariel.module';
import { EstimateListComponent } from './estimate-list/estimate-list.component';

@NgModule({
  declarations: [
    NewEstimateComponent,
    EstimateListComponent
  ],
  imports: [
    CommonModule,
		MaterialModule,
		ArielModule,
		MatSortModule
  ],
  exports: [
    NewEstimateComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "it-IT" },
    //otherProviders...
  ]
 })
export class EstimateModule { }
