import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { constants } from '../../../../classes/constants';
import { OptionsAvailable } from 'src/app/modules/classes/options-available';
import { CucinaService } from 'src/app/services/cucina/cucina.service';
import { OptionsAvailableService } from 'src/app/services/optionsAvailable/options-available.service';

@Component({
  selector: 'cucina-prodotto',
  templateUrl: './cucina-prodotto.component.html',
  styleUrls: ['./cucina-prodotto.component.scss']
})
export class CucinaProdottoComponent implements OnInit {

  @Input()
  form: any;

  @Input() trascodifiche:Trascodifica[];

  isLoaded: boolean = false;
  searchCucina: string;
  modelliCucina: Trascodifica[] = [];

  public constants = constants;

  constructor(
    private cucinaService: CucinaService,
    private formControllService: FormControllerService,
  ) { }

  async ngOnInit(): Promise<void> {
    try {
      await Promise.all([
          this.returnTrascodifiche()
      ]);
      
      this.getDatiCucina();

    } catch (error) {
      console.error("Errore durante l'inizializzazione del componente:", error);
    }
  }

  private getDatiCucina(): void {
    this.cucinaService.findByKey(this.form.idOrder, 'ordineId.equals=').subscribe(res => {
      if (res[0] !== undefined) {
        this.form.sections.cucina = res[0];
      }
    });
    this.isLoaded = true;
  }


  private returnTrascodifiche(): void {
      this.modelliCucina = this.formControllService.getTrascodificaByCodice(constants.COD_MODELLO_CUCINA, this.trascodifiche);
  }
  
  includeModello(el: any): boolean {
    if (el !== null && el !== undefined) {
      if (!this.searchCucina || this.searchCucina.trim() === '') {
        return false; 
      } else {
        const searchString = String(this.searchCucina).trim()?.toLowerCase();
        const inputString = String(el.descrizione)?.toLowerCase();
        return !inputString.startsWith(searchString); 
      }
    }
    return true; 
  }
}
