import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { RivestimentiService } from '../../main-bagni/services/rivestimenti/rivestimenti.service';
import { SanitariService } from '../../main-bagni/services/sanitari/sanitari.service';
import { constants } from 'src/app/modules/classes/constants';

@Component({
  selector: 'app-pratico-rivestimenti',
  templateUrl: './pratico-rivestimenti.component.html',
  styleUrls: ['./pratico-rivestimenti.component.scss']
})
export class PraticoRivestimentiComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  @Input() mobileAttivo: boolean;
  @Output('update') change: EventEmitter<any> = new EventEmitter<any>();
  
  tipiVasche: Trascodifica[];
  tipiCol: Trascodifica[];
  vascaMob: Trascodifica[];
  noCol: Trascodifica[];
  
  constructor(
    private rivestimentiService: RivestimentiService,
    public sanitariService: SanitariService,
    public formController: FormControllerService ) { }

  ngOnInit(): void {
    this.rivestimentiService.findByKey(this.form.idOrder, 'ordineId.equals=').subscribe(res => {
      if (res[0] !== undefined) {
        this.form.sections.rivestimenti = res[0];
      }
    });

    this.sanitariService.findByKey(this.form.idOrder, 'idordineId.equals=').subscribe(res => {
      this.formController.mappingSanitariDTOtoForm(res[0], this.form.sections.nuoviProdotti);
      this.tipiVasche = this.formController.getTrascodificaByCodice(constants.COD_NUM_VASCHE_MOB, this.trascodifiche);
      this.vascaMob = this.formController.getTrascodificaByCodice(constants.COD_VASCAMOB, this.trascodifiche);
      this.tipiCol = this.formController.getTrascodificaByCodice(constants.COD_TIPO_COLONNA_MOB, this.trascodifiche);
      this.noCol = this.tipiCol.filter((c: any) => c.descrizione === 'Nessuna colonna');
      if (this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.descrizione ||
        this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colore ||
        this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.numeroVasche ||
        this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile) {
        this.toggleAttivo(false);
        this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.isCliente = false;
      } else {
        this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.isCliente = true;
      }
    });
  }


  toggleAttivo(event): void {
    this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.isCliente = event;
    this.change.emit(event);
  }
}
