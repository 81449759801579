import { environment } from "../../../../../../../environments/environment";
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { AnagraficaService } from 'src/app/modules/seller/bagni-italiani/main-bagni/services/anagrafica/anagrafica.service';
import { OmaggioService } from 'src/app/modules/seller/bagni-italiani/main-bagni/services/omaggio/omaggio.service';
import { PrintComponent } from 'src/app/modules/shared/pdf/print/print.component';
import { BrandService } from 'src/app/services/brand/brand.service';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { HttpService } from 'src/app/services/http.service';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { PrintserviceService } from 'src/app/services/print/printservice.service';
import { AbitazioneService } from '../../services/abitazione.service';
import { AnagraficaDTO } from "src/app/modules/classes/anagrafica";
import { PreviewComponent } from "src/app/modules/shared/pdf/preview/preview.component";
import { OmaggioDto } from "src/app/modules/classes/omaggio-dto";
import { CaldaiaService } from "../services/caldaia.service";
declare var jQuery: any;

@Component({
  selector: 'app-print-caldaia',
  templateUrl: './print-caldaia.component.html',
  styleUrls: ['./print-caldaia.component.scss']
})
export class PrintCaldaiaComponent extends PrintComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  @Input() modale: boolean;
  @Output("reload") reload: EventEmitter<any> = new EventEmitter<any>();

  idOrdine: number;
  timeLeft: number = 60;

  urlFrame: string = '';
  privacyText: string = '';
  noteCaldaia: string = '';
  noteInstallazione: string = '';
  colorString: string = "#008000";

  showFrame: boolean = false;
  generatingPdf: boolean = false;
  validAnagrafica: boolean = false;
  openSign: boolean = false;
  sendingPdf: boolean = false;
  showModalPrint: boolean = false;
  loadAll: boolean = false;

  anagrafica: AnagraficaDTO;

  member: any;
  abitazione: any;
  error: any;
  caldaie: any;
  disegnoCaldaiaEsistente: any;
  dettagliInstallEAcces: any;

  rgbColorLine: number[] = [0, 128, 0];
  omaggio: OmaggioDto[];
  defineColumnGeneric: any[] = [];
  defineColumnCaldaiaDesign: any[] = [];
  integratedSignature = environment.integratedSignature;
  isApiV3 = environment.isV3;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private abitazioneService: AbitazioneService,
    public printService: PrintserviceService,
    public formController: FormControllerService,
    private anagraficaService: AnagraficaService,
    private omaggioService: OmaggioService,
    private orderService: OrdersService,
    private paymentService: PaymentService,
    private caldaiaService: CaldaiaService,
    private brandService: BrandService,
    private httpService: HttpService
  ) {
    super(printService);
  }

  ngOnInit(): void {
    this.idOrdine = this.form.idOrder;
    this.color = this.rgbColorLine;

    this.defineColumnGeneric = [
      "MODELLO",
      "TIPO"
    ];

    this.defineColumnCaldaiaDesign = [
      "MODELLO",
      "TIPO",
      "COLORE"
    ];

    this.getOrder();
  }

  getOrder(): void {
    this.orderService.getSingleOrder(this.idOrdine).subscribe((res) => {
      this.ordine = res;
      this.brandService
        .getDataByProduct(Number(localStorage.getItem("id_product")))
        .subscribe((resp: any) => {
          this.trascodifiche = resp;
          this.getAnagrafica();
          this.getCaldaieOrder();
          this.getPagamento();
        });
    });
    this.loadAll = true;
  }

  generatePdf(firma: boolean): void {
    let y = this.initPdf(this.ordine, this.anagrafica, "/assets/img/loghi/ARIEL.jpg", "Modulo d'ordine caldaia Ariel", this.colorString);

    this.printService.writeFooterPage(this.doc, this.ordine, this.currentPdfPage, 6);

    if (this.caldaie) {
      const caldaiaAcondensazioneEcoWind: any[] = [];
      if (this.caldaie.length) {
        this.caldaie.forEach((caldaia) => {
          if (caldaia.modcaldcondecowDescrizione && caldaia.tipologiaDescrizione) {
            caldaiaAcondensazioneEcoWind.push(caldaia);
          }
        })
      }

      let tableTitle = 'Caldaia a condensazione ECO WIND';
      this.genericPrintCaldaiaProduct(this.doc, caldaiaAcondensazioneEcoWind, tableTitle, this.defineColumnGeneric, y, this.colorString);

      const caldaiaAcondensazioneEcoWindDesign: any[] = [];
      if (this.caldaie.length) {
        this.caldaie.forEach((caldaia) => {
          if (caldaia.modcaldcondecowdDescrizione && caldaia.tipologiaDescrizione && caldaia.coloreDescrizione) {
            caldaiaAcondensazioneEcoWindDesign.push(caldaia);
          }
        })

        tableTitle = 'Caldaia a condensazione ECO WIND DESIGN';
        this.genericPrintCaldaiaProduct(this.doc, caldaiaAcondensazioneEcoWindDesign, tableTitle, this.defineColumnCaldaiaDesign, y, this.colorString);
      }

      const caldaiaAcondensazioneEcoWindAurum: any[] = [];
      if (this.caldaie.length) {
        this.caldaie.forEach((caldaia) => {
          if (caldaia.modcaldcondecowauDescrizione && caldaia.tipologiaDescrizione) {
            caldaiaAcondensazioneEcoWindAurum.push(caldaia);
          }
        })

        tableTitle = 'Caldaia a condensazione ECO WIND AURUM';
        this.genericPrintCaldaiaProduct(this.doc, caldaiaAcondensazioneEcoWindAurum, tableTitle, this.defineColumnGeneric, y, this.colorString);
      }

      const caldaiaAcondensazioneEcoWindWall: any[] = [];
      if (this.caldaie.length) {
        this.caldaie.forEach((caldaia) => {
          if (caldaia.modcaldcondecowallDescrizione && caldaia.tipologiaDescrizione) {
            caldaiaAcondensazioneEcoWindWall.push(caldaia);
          }
        })

        tableTitle = 'Caldaia a condensazione ECO WIND WALL';
        this.genericPrintCaldaiaProduct(this.doc, caldaiaAcondensazioneEcoWindWall, tableTitle, this.defineColumnGeneric, y, this.colorString);
      }

      const scaldaAcquaHotWind: any[] = [];
      if (this.caldaie.length) {
        this.caldaie.forEach((caldaia) => {
          if (caldaia.modcaldacqhwDescrizione && caldaia.tipologiaDescrizione) {
            scaldaAcquaHotWind.push(caldaia);
          }
        })
        tableTitle = 'Scalda acqua HOT WIND';
        this.genericPrintCaldaiaProduct(this.doc, scaldaAcquaHotWind, tableTitle, this.defineColumnGeneric, y, this.colorString);
      }


      const caldaiaACameraAperta: any[] = [];
      if (this.caldaie.length) {
        this.caldaie.forEach((caldaia) => {
          if (caldaia.modcaldcondcamDescrizione && caldaia.tipologiaDescrizione) {
            caldaiaACameraAperta.push(caldaia);
          }
        })
        tableTitle = 'Caldaia a camera aperta';
        this.genericPrintCaldaiaProduct(this.doc, caldaiaACameraAperta, tableTitle, this.defineColumnGeneric, y, this.colorString);
      }

      if (this.noteCaldaia) {
        const title = ['NOTE']
        if (this.noteCaldaia && this.noteCaldaia !== '') {
          this.createTableTextNoheader(title, this.noteCaldaia, this.printService.returnCurrentY(), this.doc, this.rgbColorLine);
        }
      }
    }

    if (this.disegnoCaldaiaEsistente) {
      this.generiCaldaiaDisegnoEsistente(this.doc, this.disegnoCaldaiaEsistente, this.printService.returnCurrentY(), this.colorString)
    }
    if (this.abitazione) {
      const tableTitle = 'DATI ABITAZIONE';
      this.genericCaldaiaDatiAbitazioneTable(this.doc, this.abitazione, this.printService.returnCurrentY(), tableTitle, this.colorString)
    }

    if (this.dettagliInstallEAcces || this.noteInstallazione) {
      const tableTitle = 'DETTAGLI INSTALLAZIONE';
      this.genericCaldaiaDettagliInstallazioneTable(this.doc, this.dettagliInstallEAcces, this.printService.returnCurrentY(), tableTitle, this.colorString);

      if (this.noteInstallazione && this.noteInstallazione !== '') {
        const title = ['NOTE']
        this.createTableTextNoheader(title, this.noteInstallazione, this.printService.returnCurrentY(), this.doc, this.rgbColorLine);
      }
    }

    if (this.dettagliInstallEAcces) {
      const tableTitle = 'ACCESSORI';
      this.genericCaldaiaAccessoriTable(this.doc, this.dettagliInstallEAcces, this.printService.returnCurrentY(), tableTitle, this.colorString);
    }

    if (this.omaggio.length > 0) {
      this.genericOmaggio(this.doc, this.omaggio, this.printService.returnCurrentY(), this.colorString);
    }

    if (this.pagamento) {
      this.createTablePagamento(this.doc, this.pagamento, this.printService.returnCurrentY(), 0, this.colorString);
    }

    this.printPrivacyInformation(y);

    this.creatingPdf = false;
    if (firma) {
      if (!this.integratedSignature) {
        this.createPdf(this.doc);
      } else {
        this.toggleSignPdf(this.doc);
      }
    } else {
      const pdfBlob = this.doc.output("blob");

      // Crea un URL dall'oggetto Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);
      this.openDialog(pdfUrl);
    }
  }

  // Print method	

  private printPrivacyInformation(y: number): number {

    this.printService.addNewPage(this.doc, true, this.img, this.ordine, this.currentPdfPage, this.title)
    y = this.printService.returnCurrentY();
    this.printService.writePrivacyOne(this.doc, y, this.ordine, this.img, this.title, this.currentPdfPage, this.privacyText);
    return y;
  }

  // Http method
  private getAnagrafica(): void {
    this.anagraficaService.findById(this.ordine.clienteId).subscribe((res) => {
      this.anagrafica = res;
      this.checkAnagrafica();
    });
    this.getAbitazione();
  }

  private getAbitazione() {
    this.abitazioneService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe((res) => {
      this.abitazione = res[0];
    });
  }

  private getOmaggio(): void {
    this.omaggioService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe(res => {
      this.omaggio = res;
    })
  }

  private getPagamento(): void {
    this.paymentService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe((res) => {
      this.pagamento = res;
    });
    this.getPrivacy();
  }

  private getCaldaieOrder(): void {
    this.caldaiaService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe((res) => {
      this.caldaie = res;
      this.getDisegnoCaldaiaEsistente()
      this.getNoteCaldaia();
    });

    this.getDettagliInstallazioneEAccessori();
    this.getOmaggio();
  }

  private getNoteCaldaia(): void {
    this.caldaiaService.getDettaglioCaldaia(this.idOrdine, 'ordineId.equals=').subscribe((res) => {
      this.noteCaldaia = res[0]?.note;
    })
  }

  private getDisegnoCaldaiaEsistente(): void {
    this.caldaiaService.getDisegnoCaldaia(this.idOrdine, 'ordineId.equals=').subscribe((res) => {
      this.disegnoCaldaiaEsistente = res[0];
    })
  }

  private getDettagliInstallazioneEAccessori(): void {
    this.caldaiaService.getAccessoriCaldaia(this.idOrdine, 'ordineId.equals=').subscribe((res) => {
      this.dettagliInstallEAcces = res[0];
      this.noteInstallazione = res[0]?.note;
    })
  }

  private getPrivacy(): void {
    this.httpService.getPrivacy('assets/json/caldaia.json').subscribe((res) => {
      this.privacyText = res;
    });
  }

  // Others method
  private openDialog(pdf: any): void {
    this.dialog.open(PreviewComponent, {
      width: "800px",
      maxWidth: "100%",
      data: {
        pdf,
      },
    });
  }

  private checkAnagrafica(): boolean {
    this.validAnagrafica = this.formController.checkAnagrafica(this.form);
    return this.validAnagrafica;
  }

  private checkIfServeNewPage(y): number {

    if (y > 270) {
      this.printService.addNewPage(this.doc, true, this.img, this.ordine, this.currentPdfPage, '');
      return y = 28;
    }
    return y;
  }

  private createPdf(pdf): void {
    this.sendingPdf = true;
    this.openSign = true;
    const title = localStorage.getItem("id_order");
    pdf.setProperties({
      title,
    });

    const newPdf = btoa(pdf.output());
    const pages = this.printService.returnCurrentPage();
    const coordinates = this.printService.returnCoordinates();
    this.formController
      .sendSignature(
        newPdf,
        this.idOrdine,
        this.form,
        pages,
        coordinates,
        title,
        false
      )
      .then(
        (res) => {
          this.generatingPdf = false;
          this.sendingPdf = false;
          this.router.navigate(["order-list"]);
        },
        (error) => {
          this.generatingPdf = false;
          this.sendingPdf = false;
          this.ordine.statopraticaCodice = "A";
          this.error = error;
          jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
          jQuery("#errorModal").modal("toggle");
        }
      );
  }

  returnIdOrdine(): string {
    return localStorage.getItem("id_order");
  }

  togglePrintPreview(): void {
    this.generatePdf(false);
  }

  toggleSignPdf(pdf): void {
    const interval = this.startTimer();
    const title = localStorage.getItem("id_order");
    const pages = this.printService.returnCurrentPage();
    this.showModalPrint = !this.showModalPrint;
    this.openSign = true;
    this.showFrame = false;
    this.generatingPdf = true;
    this.urlFrame = '';
    jQuery("#signModal").modal({ backdrop: "static", keyboard: false });
    jQuery("#signModal").modal("toggle");
    if (this.isApiV3) {
      const newPdf = new Blob([pdf.output('blob')], { type: "application/pdf" });
      var split = this.printService.returnCoordinates().split(",");
      const coordinates = {
        width: 120,
        height: 37,
        x: split[0],
        y: split[3],
      }
      this.formController.manageSignature(newPdf, this.form.idOrder, this.form, pages, coordinates, title, environment.signGenericId).then((res: any) => {
        this.urlFrame = res.signers[0].signature_link + '&disable_domain_validation=true';
        setTimeout(() => {
          this.showFrame = true;
          this.sendingPdf = false;
          this.generatingPdf = false;

        }, 2000);
        clearInterval(interval);
      }, error => {
        this.generatingPdf = false;
        this.sendingPdf = false;
        this.ordine.statopraticaCodice = "A";
        clearInterval(interval);
        this.closeSignPdf();
        jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
        jQuery("#errorModal").modal("toggle");
      });
    } else {
      const newPdf = btoa(pdf.output());
      const coordinates = this.printService.returnCoordinates();
      this.formController
        .sendSignature(
          newPdf,
          this.form.idOrder,
          this.form,
          pages,
          coordinates,
          title,
          true
        )
        .then(
          (res) => {
            this.member = res.members[0].id;
            this.urlFrame =
              environment.apiUrl +
              "/procedure/sign?members=" +
              this.member +
              "&signatureUi=" +
              environment.signAriel; // "https://staging-app.yousign.com/procedure/sign?members="+this.member;
            this.showFrame = true;
            this.sendingPdf = false;
            this.generatingPdf = false;

            clearInterval(interval);
            // this.router.navigate(['order-list']);
          },
          (error) => {
            this.generatingPdf = false;
            this.sendingPdf = false;
            this.ordine.statopraticaCodice = "A";
            clearInterval(interval);
            jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
            jQuery("#errorModal").modal("toggle");
          }
        );
    }
  }

  public closeSignPdf(): void {
    jQuery('#signModal').modal({ backdrop: 'static', keyboard: false });
    jQuery('#signModal').modal('toggle');
    this.reload.emit(this.openSign);
    this.setStateToActive();
    this.formController.removeFrame();
  }

  private setStateToActive() {

    this.orderService.getSingleOrder(this.idOrdine).subscribe((res) => {
      this.ordine = res;
      this.ordine.statopraticaCodice = "A";
      this.orderService.update(JSON.stringify(this.ordine)).subscribe(res => {
        this.getOrder();
      });
    })
  }

  startTimer(): any {
    const intervalId = setInterval(() => {
      if (this.timeLeft === 0) {
        clearInterval(intervalId);
        // richiamo api per invio email
        this.orderService.sendEmail(this.form.idOrder).subscribe();
        jQuery("#timeoutModal").modal({ backdrop: "static", keyboard: false });
        jQuery("#timeoutModal").modal("toggle");

        setTimeout(() => {
          jQuery("#timeoutModal").modal("hide");
        }, 10000);

        setTimeout(() => {
          this.formController.goToDashboard();
        }, 11000);
      }
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }
    }, 1000);
    return intervalId;
  }
}