<div class="row originalRow mb-2">
    <div class="col-lg-4">

        <div>
            <div class="text-center greenBg">
                DATI ATTUALI<br/>
                VASCA/PIATTO
            </div>
            <div class="greenCont">
                <div>
                    <app-input-number
                            (onNumChange)="form.sections.datitecnicivasca.lungvasca = $event"
                            [step]="0.1"
                            [num]="form.sections.datitecnicivasca.lungvasca"
                            [decimal]="true"
                            [payment]="false"
                            [small]="false"
                            label="Lunghezza (L) cm:"></app-input-number>
                </div>

                <div>
                    <app-input-number
                            (onNumChange)="form.sections.datitecnicivasca.largvasca = $event"
                            [step]="0.1"
                            [num]="form.sections.datitecnicivasca.largvasca"
                            [decimal]="true"
                            [payment]="false"
                            [small]="false"
                            label="Larghezza (P) cm:"></app-input-number>
                </div>
                <div>
                    <app-input-number
                            (onNumChange)="form.sections.datitecnicivasca.altvasca = $event"
                            [step]="0.1"
                            [num]="form.sections.datitecnicivasca.altvasca"
                            [decimal]="true"
                            [payment]="false"
                            [small]="false"
                            label="Altezza (H) cm:"></app-input-number>
                </div>

                <div class="text-center mb-2">
                	<mat-checkbox class="mr-2" (change)="setMuroDietro($event)" [checked]="this.form.sections.datitecnicivasca.murodietro" color="primary">Muro dietro</mat-checkbox>
                    <mat-checkbox class="mr-2" (change)="setMuroSx($event)" [checked]="form.sections.datitecnicivasca.murosx" color="primary"> Muro sx. </mat-checkbox>
                    <mat-checkbox (change)="setMuroDx($event)" [checked]="form.sections.datitecnicivasca.murodx" color="primary"> Muro dx. </mat-checkbox>
                </div>

                <div class="text-center mb-2">
                    <mat-checkbox class="mr-2" (change)="setScaricoSx($event)" [checked]="form.sections.datitecnicivasca.scaricosx" color="primary"> Scarico sx. </mat-checkbox>
                    <mat-checkbox (change)="setScaricoDx($event)" [checked]="form.sections.datitecnicivasca.scaricodx" color="primary"> Scarico dx. </mat-checkbox>
                </div>


                <div class="text-center">
                    <img src="assets/img/remail/vasca1.png" alt="vasca"/>
                </div>

            </div>
        </div>

    </div>
    <div class="col-lg-8">
        <div class="text-center greenBg">
            DATI TECNICI MURETTI ATTUALI
        </div>
        <div class="greenCont">
            <div class="mb-3">
                <mat-slide-toggle
                        class="example-margin"
                        (ngModelChange)="toggleMur($event)"
                        [checked]="murettiToggle"
                        [(ngModel)]="murettiToggle">
                    Presenti muretti
                </mat-slide-toggle>
            </div>
            <hr/>
            <div class="row originalRow" *ngIf="murettiToggle">
                <div class="col-6">

                    <div class="row originalRow">
                        <div class="col-5">
                            <div>MURETTO SX.</div>
                            <div>
                                <app-input-number
                                        (onNumChange)="form.sections.datitecnicivasca.lungmurosx = $event"
                                        [step]="0.1"
                                        [num]="form.sections.datitecnicivasca.lungmurosx"
                                        [decimal]="true"
                                        [payment]="false"
                                        [small]="false"
                                        label="Lunghezza cm:"></app-input-number>
                                <!--
                                <mat-form-field class="full-width">
                                    <mat-label>Lunghezza cm:</mat-label>
                                    <input matInput type="number" min="0" step=".1" [(ngModel)]="form.sections.datitecnicivasca.lungmurosx">
                                </mat-form-field>
                                -->
                            </div>

                            <div>
                                <app-input-number
                                        (onNumChange)="form.sections.datitecnicivasca.largmurosx = $event"
                                        [step]="0.1"
                                        [num]="form.sections.datitecnicivasca.largmurosx"
                                        [decimal]="true"
                                        [payment]="false"
                                        [small]="false"
                                        label="Larghezza cm:"></app-input-number>
                                <!--
                                <mat-form-field class="full-width">
                                    <mat-label>Larghezza cm:</mat-label>
                                    <input matInput type="number" min="0" step=".1" [(ngModel)]="form.sections.datitecnicivasca.largmurosx">
                                </mat-form-field>
                                -->
                            </div>
                            <div>
                                <app-input-number
                                        (onNumChange)="form.sections.datitecnicivasca.altmurosx = $event"
                                        [step]="0.1"
                                        [num]="form.sections.datitecnicivasca.altmurosx"
                                        [decimal]="true"
                                        [payment]="false"
                                        [small]="false"
                                        label="Altezza cm:"></app-input-number>
                                <!--
                                <mat-form-field class="full-width">
                                    <mat-label>Altezza cm:</mat-label>
                                    <input matInput type="number" min="0" step=".1" [(ngModel)]="form.sections.datitecnicivasca.altmurosx">
                                </mat-form-field>
                                -->
                            </div>
                        </div>
                        <div class="col-7">
                            <img src="assets/img/remail/murosx.png" alt="murettosx"/>
                            <div>
                                <div class="mb-1">Eliminare muretto</div>
                                <mat-radio-group color="primary"  aria-label="Select an option" [(ngModel)]="form.sections.datitecnicivasca.eliminamurosx">
                                    <mat-radio-button class="mr-2" [value]="true">Si</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                                <div class="text-danger mb-2 smallFont" *ngIf="(form.sections.datitecnicivasca.eliminamurosx == null ||
                                form.sections.datitecnicivasca.eliminamurosx == undefined) &&
                                (form.sections.datitecnicivasca.lungmurosx || form.sections.datitecnicivasca.largmurosx || form.sections.datitecnicivasca.altmurosx)
                                ">
                                    Campo obbligatorio
                                </div>
                            </div>
                            <div>
                                <div class="mb-1">Zoppo</div>
                                <mat-radio-group color="primary"  aria-label="Select an option" [(ngModel)]="form.sections.datitecnicivasca.zoppo">
                                    <mat-radio-button class="mr-2" [value]="true">Si</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                                <div class="text-danger mb-2 smallFont" *ngIf="(form.sections.datitecnicivasca.zoppo == null ||
                                form.sections.datitecnicivasca.zoppo == undefined) &&
                                (form.sections.datitecnicivasca.lungmurosx || form.sections.datitecnicivasca.largmurosx || form.sections.datitecnicivasca.altmurosx)
                                ">
                                    Campo obbligatorio
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row originalRow">
                        <div class="col-6">
                            <div class="text-center mb-2">
                                <mat-checkbox class="mr-2" (change)="murettoDentro($event)" [checked]="form.sections.datitecnicivasca.teneremurosxdentro" color="primary">
                                    Tenere muretto dentro la doccia
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                   	<div class="row originalRow">
                        <div class="col-6">
                            <div class="text-center mb-2">
                                <mat-checkbox class="mr-2" (change)="murettoFuori($event)" [checked]="form.sections.datitecnicivasca.teneremurosxfuori" color="primary">
                                    Tenere muretto fuori dalla doccia
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="verticalHr"></div>
                </div>
                <div class="col-6">

                    <div class="row originalRow">
                        <div class="col-5">
                            <div>MURETTO DX.</div>
                            <div>
                                <app-input-number
                                        (onNumChange)="form.sections.datitecnicivasca.lungmurodx = $event"
                                        [step]="0.1"
                                        [num]="form.sections.datitecnicivasca.lungmurodx"
                                        [decimal]="true"
                                        [payment]="false"
                                        [small]="false"
                                        label="Lunghezza cm:">
                                </app-input-number>
                            </div>

                            <div>
                                <app-input-number
                                        (onNumChange)="form.sections.datitecnicivasca.largmurodx = $event"
                                        [step]="0.1"
                                        [num]="form.sections.datitecnicivasca.largmurodx"
                                        [decimal]="true"
                                        [payment]="false"
                                        [small]="false"
                                        label="Larghezza cm:">
                                </app-input-number>
                            </div>
                            <div>
                                <app-input-number
                                        (onNumChange)="form.sections.datitecnicivasca.altmurodx = $event"
                                        [step]="0.1"
                                        [num]="form.sections.datitecnicivasca.altmurodx"
                                        [decimal]="true"
                                        [payment]="false"
                                        [small]="false"
                                        label="Altezza cm:">
                                    </app-input-number>
                            </div>
                        </div>
                        <div class="col-7">
                            <img src="assets/img/remail/murodx.png" alt="murettodx"/>
                            <div>
                                <div class="mb-1">Eliminare muretto</div>
                                <mat-radio-group color="primary"  aria-label="Select an option" [(ngModel)]="form.sections.datitecnicivasca.eliminamurodx">
                                    <mat-radio-button class="mr-2" [value]="true">Si</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                                <div class="text-danger mb-2 smallFont" *ngIf="(form.sections.datitecnicivasca.eliminamurodx == null ||
                                form.sections.datitecnicivasca.eliminamurodx == undefined) &&
                                (form.sections.datitecnicivasca.lungmurodx || form.sections.datitecnicivasca.largmurodx || form.sections.datitecnicivasca.altmurodx)
                                ">
                                    Campo obbligatorio
                                </div>
                            </div>
                            <div>
                                <div class="mb-1">Zoppo</div>
                                <mat-radio-group color="primary" aria-label="Select an option" [(ngModel)]="form.sections.datitecnicivasca.zoppomurodx">
                                    <mat-radio-button class="mr-2" [value]="true">Si</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                                <div class="text-danger mb-2 smallFont" *ngIf="(form.sections.datitecnicivasca.zoppomurodx == null ||
                                form.sections.datitecnicivasca.zoppomurodx == undefined) &&
                                (form.sections.datitecnicivasca.lungmurodx || form.sections.datitecnicivasca.largmurodx || form.sections.datitecnicivasca.altmurodx)
                                ">
                                    Campo obbligatorio
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row originalRow">
                        <div class="col-6">
                            <div class="text-center mb-2">
                                <mat-checkbox class="mr-2" (change)="murettoDxDentro($event)" [checked]="form.sections.datitecnicivasca.teneremurodxdentro" color="primary">
                                    Tenere muretto dentro la doccia
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>    
                    <div class="row originalRow">    
                        <div class="col-6">
                            <div class="text-center mb-2">
                                <mat-checkbox class="mr-2" (change)="murettoDxFuori($event)" [checked]="form.sections.datitecnicivasca.teneremurodxfuori" color="primary">
                                    Tenere muretto fuori dalla doccia
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <hr *ngIf="murettiToggle"/>
            <div class="row originalRow" *ngIf="murettiToggle">
                <div class="col-6">
                    <div>Muro dietro: tenere sempre</div>
                    <div class="row originalRow">
                        <div class="col-6 align-self-center">
                            <img src="assets/img/remail/murettodietro.png" alt="murodietro"/>
                        </div>
                        <div class="col-6">
                            <div>
                                <app-input-number
                                        (onNumChange)="form.sections.datitecnicivasca.lungmurodietro = $event"
                                        [step]="0.1"
                                        [num]="form.sections.datitecnicivasca.lungmurodietro"
                                        [decimal]="true"
                                        [payment]="false"
                                        [small]="false"
                                        label="Lunghezza cm:">
                                </app-input-number>
                            </div>

                            <div>
                                <app-input-number
                                        (onNumChange)="form.sections.datitecnicivasca.largmurodietro = $event"
                                        [step]="0.1"
                                        [num]="form.sections.datitecnicivasca.largmurodietro"
                                        [decimal]="true"
                                        [payment]="false"
                                        [small]="false"
                                        label="Larghezza cm:">
                                </app-input-number>
                            </div>
                            <div>
                                <app-input-number
                                        (onNumChange)="form.sections.datitecnicivasca.altmurodietro = $event"
                                        [step]="0.1"
                                        [num]="form.sections.datitecnicivasca.altmurodietro"
                                        [decimal]="true"
                                        [payment]="false"
                                        [small]="false"
                                        label="Altezza cm:">
                                </app-input-number>
                            </div>
                            <div>
                                <div class="mb-1">Zoppo</div>
                                <mat-radio-group color="primary" aria-label="Select an option" [(ngModel)]="form.sections.datitecnicivasca.zoppomurodietro">
                                    <mat-radio-button class="mr-2" [value]="true">Si</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                                <div class="text-danger mb-2 smallFont" *ngIf="(form.sections.datitecnicivasca.zoppomurodietro == null ||
                                form.sections.datitecnicivasca.zoppomurodietro == undefined) &&
                                (form.sections.datitecnicivasca.lungmurodietro || form.sections.datitecnicivasca.largmurodietro || form.sections.datitecnicivasca.altmurodietro)
                                ">
                                    Campo obbligatorio
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <hr/>
                            <div class="text-center mb-2">
                                <mat-checkbox class="mr-2" (change)="murettoDietroDentro($event)" [checked]="form.sections.datitecnicivasca.teneremurodietrodentro" color="primary">
                                    Tenere muretto dentro la doccia
                                </mat-checkbox>
                            </div>
                            <div class="text-center mb-2">
                                <mat-checkbox class="mr-2" (change)="murettoDietroFuori($event)" [checked]="form.sections.datitecnicivasca.teneremurodietrofuori" color="primary">
                                    Tenere muretto fuori dalla doccia
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="verticalHr"></div>
                </div>
                <div class="col-6">
                    <div>Muro avanti: eliminare sempre</div>
                    <div class="row originalRow">
                        <div class="col-6 align-self-center">
                            <img src="assets/img/remail/murettoavanti.png" alt="murodietro"/>
                        </div>
                        <div class="col-6">
                            <div>
                                <app-input-number
                                        (onNumChange)="form.sections.datitecnicivasca.lungmuroavanti = $event"
                                        [step]="0.1"
                                        [num]="form.sections.datitecnicivasca.lungmuroavanti"
                                        [decimal]="true"
                                        [payment]="false"
                                        [small]="false"
                                        label="Lunghezza cm:">
                                </app-input-number>
                            </div>

                            <div>
                                <app-input-number
                                        (onNumChange)="form.sections.datitecnicivasca.largmuroavanti = $event"
                                        [step]="0.1"
                                        [num]="form.sections.datitecnicivasca.largmuroavanti"
                                        [decimal]="true"
                                        [payment]="false"
                                        [small]="false"
                                        label="Larghezza cm:">
                                </app-input-number>
                            </div>
                            <div>

                                <app-input-number
                                        (onNumChange)="form.sections.datitecnicivasca.altmuroavanti = $event"
                                        [step]="0.1"
                                        [num]="form.sections.datitecnicivasca.altmuroavanti"
                                        [decimal]="true"
                                        [payment]="false"
                                        [small]="false"
                                        label="Altezza cm:">
                                </app-input-number>
                            </div>
                            <div>
                                <div class="mb-1">Zoppo</div>
                                <mat-radio-group color="primary" aria-label="Select an option" [(ngModel)]="form.sections.datitecnicivasca.zoppomuroavanti">
                                    <mat-radio-button class="mr-2" [value]="true">Si</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                                <div class="text-danger mb-2 smallFont" *ngIf="(form.sections.datitecnicivasca.zoppomuroavanti == null ||
                                form.sections.datitecnicivasca.zoppomuroavanti == undefined) &&
                                (form.sections.datitecnicivasca.lungmuroavanti || form.sections.datitecnicivasca.largmuroavanti || form.sections.datitecnicivasca.altmuroavanti)
                                ">
                                    Campo obbligatorio
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <hr/>
                            <div class="text-center mb-2">
                                <mat-checkbox class="mr-2" (change)="murettoAvantiDentro($event)" [checked]="form.sections.datitecnicivasca.teneremuroavantidentro" color="primary">
                                    Tenere muretto dentro la doccia
                                </mat-checkbox>
                            </div>
                            <div class="text-center mb-2">
                                <mat-checkbox class="mr-2" (change)="murettoAvantiFuori($event)" [checked]="form.sections.datitecnicivasca.teneremuroavantifuori" color="primary">
                                    Tenere muretto fuori dalla doccia
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="smallFont text-center text-danger">
    PER "ZOPPO" SI INTENDE UN'ANTA DI BOX IN ACRILICO CHE VA POSIZIONATA SUL MURETTO
</div>
