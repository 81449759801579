import { Component, OnInit } from '@angular/core';
import { BrandService } from '../../services/brand/brand.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  currentUser: any;
  constructor(
      private brandService: BrandService,
      public userService: UserService,
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('or_profile'));
  }

  ngOnInit(): void {
  }

}
