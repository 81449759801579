import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';

@Component({
  selector: 'app-arrivo-item',
  templateUrl: './arrivo-item.component.html',
  styleUrls: ['./arrivo-item.component.scss']
})
export class ArrivoItemComponent implements OnInit {

  @Input()
  arrivoGuidaType: string;

  @Input()
  element: Trascodifica;

  @Input()
  form:any

  @Input()
  showInputLine:boolean = false;

  @Input()
  unitOfMeasure:string = '';

  @Input()
  underDescription: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  changeBassoModel(event: any):void {
    if(event.id){
      this.form.sections.montascale.arrivobassoguidaId = event.id;
    }
    
    if(this.form.sections.montascale.arrivobassevalue){
      //Se già valorizzato e mi sposto di checkbox senza cancellare la scelta, pulisci il valore
      this.form.sections.montascale.arrivobassevalue = null;
    }
  }

  changeAltoModel(event: any):void {
    if(event.id){
      this.form.sections.montascale.arrivoaltoguidaId = event.id;
    }
    
    if(this.form.sections.montascale.arrivoaltoguidavalue){
      this.form.sections.montascale.arrivoaltoguidavalue = null;
    }
  }

  cleanBassoItem():void{
    this.form.sections.montascale.arrivobassoguidaId = null;
    this.form.sections.montascale.arrivobassevalue = null;
  }

  cleanAltoItem():void{
    this.form.sections.montascale.arrivoaltoguidaId = null;
    this.form.sections.montascale.arrivoaltoguidavalue = null;
  }
}
