export class BoxdocciaDTO {
	ordineId: number;
	modelloId: number;
	modelloDescrizione: string;
	cristalliId: number;
	cristalliDescrizione: string;
	profiloId: number;
	profiloDescrizione: string;
	larghezzaId: number;
	larghezzaDescrizione: string;
	ingressoId: number;
	ingressoDescrizione: string;
	listCristalli:[];
	listProfili:[];
	listIngressi:[];
}
