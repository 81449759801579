import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from '../../../../../classes/trascodifica';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';
import { PromoService } from '../../services/promo.service';
import { DettaglioService } from '../../services/dettaglio.service';
import { constants } from 'src/app/modules/classes/constants';
@Component({
  selector: 'app-promo',
  templateUrl: './promo.component.html',
  styleUrls: ['./promo.component.scss']
})
export class PromoComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  tipologie: Trascodifica[];
  promozioni: Trascodifica[];
  availablePromo: Trascodifica[];
  modelliInverter: Trascodifica[];
  selectedTipo: any;
  selectedPromo: any;
  
  constructor(
      private formController: FormControllerService,
      private promoService: PromoService,
      private dettaglioService:DettaglioService,
  ) { }

  ngOnInit(): void {
    this.getCondizionatori();
    this.getTipologie();
    this.getPromozioni();
    this.getModelliInverter();
    this.availablePromo = [];
    this.getDettaglio();
  }

  getCondizionatori(): void {
    this.promoService.getCondizionatori(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
      this.form.sections.condizionatori = res;
      this.form.sections.condizionatori.forEach(c => {
        c.toDelete = false;
      });
    });
  }

  getTipologie(): void {
    this.tipologie = undefined;
    this.formController.getTrascodificaByCodicPromise( constants.COD_PROMO_CLIMA_RAGGR, this.trascodifiche).then(data => {
      this.tipologie = data;
    });
  }

  getPromozioni(): void {
    this.promozioni = undefined;
    this.formController.getTrascodificaByCodicPromise( constants.COD_PROMO_CLIMA_DESCR, this.trascodifiche).then(data => {
      this.promozioni = data;
    });
  }

  getModelliInverter(): void {
    this.modelliInverter = undefined;
    this.formController.getTrascodificaByCodicPromise(constants.COD_MODELLO_INVERTER, this.trascodifiche).then(data => {
      this.modelliInverter = data;
    });
  }

  tipoSelected(event): void {
    this.availablePromo = [];
    if (event) {
      this.promozioni.forEach(p => {
        if (p.parentId === event) {
          this.availablePromo.push(p);
        }
      });
    }
  }

  selectPromo(): void {
    this.modelliInverter.forEach(p => {
      if (p.parentId === this.selectedPromo) {
        const condizionatore = {
          modelloId: p.id,
          ordineId: this.form.idOrder,
        };
        this.form.sections.condizionatori.push(condizionatore);
      }
    });
    delete this.selectedPromo;
    delete this.selectedTipo;
    this.availablePromo = [];
  }

  returnInverterMod(id): string {
    const index = this.modelliInverter.findIndex(r => r.id === id );
    return this.modelliInverter[index].descrizione;
  }

  returnInverterQt(id): number {
    const index = this.modelliInverter.findIndex(r => r.id === id );
    return this.modelliInverter[index].qta;
  }

  returnInverterPromo(id): string {
    const parent = this.modelliInverter.findIndex(r => r.id === id );
    const parentId = this.modelliInverter[parent].parentId;
    const index = this.promozioni.findIndex(r => r.id === parentId );
    return this.promozioni[index].descrizione;
  }

  returnPromoTipo(id): string {
    const parent = this.modelliInverter.findIndex(r => r.id === id );
    const parentId = this.modelliInverter[parent].parentId;
    const index = this.promozioni.findIndex(r => r.id === parentId );
    const promoParent = this.promozioni[index].parentId;
    const tipo = this.tipologie.findIndex(r => r.id === promoParent );
    return this.tipologie[tipo].descrizione;
  }

  removeInverter(id): void {
    const parent = this.modelliInverter.findIndex(r => r.id === id );
    const parentId = this.modelliInverter[parent].parentId;
    for (let i = 0; i < this.form.sections.condizionatori.length; i++) {
      const c = this.form.sections.condizionatori[i];
      const inverterModel = this.modelliInverter.findIndex(r => r.id === c.modelloId );
      const parentIdInverter = this.modelliInverter[inverterModel].parentId;
      if (parentIdInverter === parentId) {
        this.form.sections.condizionatori[i].toDelete = true;
      }
    }
  }

  getDettaglio(){
	 this.dettaglioService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
		if(res[0] !== undefined){
			this.form.sections.dettaglioDto = res[0];	
		}
	 });
  }
}
