import { Component, OnInit } from '@angular/core';
import { Signature } from '../../../../classes/signature';
import { AnagraficaDTO } from '../../../bagni-italiani/main-bagni/classes/anagrafica';
import { Trascodifica } from '../../../../classes/trascodifica';
import { Ordine } from '../../../../classes/ordine';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { UserService } from '../../../../../services/user/user.service';
import { AnagraficaService } from '../../../bagni-italiani/main-bagni/services/anagrafica/anagrafica.service';
import { BrandService } from '../../../../../services/brand/brand.service';
import { ActivatedRoute } from '@angular/router';
import { AnagraficaForm } from '../../../../classes/anagrafica';
import { AbitazioneDTO } from '../../ariel-main/classes/abitazione-dto';
import { PagamentoDTO } from '../../../bagni-italiani/main-bagni/classes/dati-tecnici';
import { InstallazioneAccessoriDto } from '../../ariel-main/classes/installazione-accessori-dto';
import { AbitazioneService } from '../../ariel-main/services/abitazione.service';
import { PaymentService } from '../../../../../services/payment/payment.service';
import { InstallazioneAccessoriService } from '../../ariel-main/services/installazione-accessori.service';
import { PromoService } from '../services/promo.service';
import { FinAnagrafica, Finanziamento, FinDatiOccupazione } from 'src/app/modules/classes/finanziamento/finanziamento-dto';
import { OrdersService } from '../../../../../services/orders/orders.service';
import { DettaglioCondizionatore } from '../../../../../modules/classes/dettaglio-condizionatore';
import { DettaglioService } from '../../ariel-main/services/dettaglio.service';
import { OmaggioService } from '../../../bagni-italiani/main-bagni/services/omaggio/omaggio.service';
import { OmaggioDto } from '../../../../classes/omaggio-dto';
import { environment } from 'src/environments/environment';
import { constants } from 'src/app/modules/classes/constants';
import { AllegatoService } from 'src/app/services/allegato/allegato.service';
import * as $ from 'jquery';

declare var jQuery: any;
@Component({
	selector: 'app-condizionatore',
	templateUrl: './condizionatore.component.html',
	styleUrls: ['./condizionatore.component.scss']
})
export class CondizionatoreComponent implements OnInit {

	showModalPrint: boolean;
	openSign: boolean = false;
	generatingPdf: boolean = false;
	validAnagrafica: boolean = false;
	sendingPdf: boolean = false;
	isBackDisabled: boolean = false;
	isFinanziamentoPayment: boolean = false;

	currentStep: number = 0;
	idCliente: number;
	valueProgBar: number = 0;
	currentPrinting: number = 0;

	urlFrame: string = "";

	formDiffer: any;
	originalForm: any;
	obj1: any;
	obj2: any;
	pdf: any;
	member: any;
	error: any;
	form: any;

	ordine: Ordine;
	signature: Signature;

	anagrafica: AnagraficaDTO;
	trascodifiche: Trascodifica[];
	listFinanziarie: Trascodifica[];
	attivitaLavList: Trascodifica[] = [];
	totalSteps: number[];
	completedSteps: boolean[] = [];

	integratedSignature = environment.integratedSignature;

	finanziamentoPdfHeader: { logoUrl: string, product: string, brand: string, } = {
		logoUrl: 'assets/img/loghi/ARIEL.jpg', product: 'condizionatore', brand: 'Ariel'
	}

  hasValues = (obj) => Object.values(obj).some(v => v !== null && typeof v !== "undefined");
	
  constructor(
      public formController: FormControllerService,
      public userService: UserService,
      private anagraficaService: AnagraficaService,
      private brandService: BrandService,
      private route: ActivatedRoute,
	  private abitazioneService:AbitazioneService,
	  private paymentService:PaymentService,
	  private installazioneAccService:InstallazioneAccessoriService,
      private promoService: PromoService,
      private orderService:OrdersService,
      private dettaglioService: DettaglioService,
	  private omaggioService: OmaggioService,
	  private allegatoService: AllegatoService
  ) {
	this.showModalPrint = false;
    this.validAnagrafica = false;
    this.form = {
      idOrder: Number(this.route.snapshot.paramMap.get('id')),
      anagrafica: new AnagraficaForm(),
      sections: {
        condizionatori: [],
        abitazione: new AbitazioneDTO(),
		installazioneAcc:new InstallazioneAccessoriDto(),
        pagamento: [new PagamentoDTO()],
		finanziamentoSections: {
			finanziamento: new Finanziamento(),
			finAnagrafica: new FinAnagrafica(),
			finDatiOccupazione: new FinDatiOccupazione(),
			finAnagraficaGarante: new FinAnagrafica(),
			finDatiOccupazioneGarante: new FinDatiOccupazione(),
			isAllegatoSaved: false,
			isSaveFinanziamento: false,
		},
        dettaglioDto: new DettaglioCondizionatore(),
		ordine : null,
		omaggio : [new OmaggioDto()],
      }
    };

		this.totalSteps = Array(9).fill(1).map((x, i) => i); // Attenzione, gestione dinamica nel metodo ( setStep() ) della parte relativa al finanziamento, che mostra lo step solo quando viene spuntato nel pagamento.
		// richiamo il serivizio di trascodifica che mi restituisce i dati per popolare i form

		this.completedSteps = Array(this.totalSteps.length).fill(false);
	}

	ngOnInit(): void {
		this.originalForm = JSON.parse(JSON.stringify(this.form));
		if (localStorage.getItem('id_client') !== null) {
			this.idCliente = Number(localStorage.getItem('id_client'));
			this.form.anagrafica.anagrafica.id = this.idCliente;
		}

		if (this.currentStep > 0) {
			$('.imgModulo').addClass('yesShow');
		} else {
			$('.imgModulo').removeClass('yesShow');
		}

		this.getOrder(this.form.idOrder);
	}

	getOrder(idOrder): void {
		this.orderService.getSingleOrder(idOrder).subscribe(res => {
			this.ordine = res;
			this.form.ordine = res;
			localStorage.setItem('id_product', res.prodottoId);

			if (res.clienteId !== undefined && res.clienteId !== null) {
				localStorage.setItem('id_client', res.clienteId);
				this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
			}

			localStorage.setItem('id_order', res.idordine);
		});

		this.brandService.getDataByProduct(Number(localStorage.getItem('id_product'))).subscribe(res => {
			this.trascodifiche = res;
			this.listFinanziarie = this.trascodifiche.filter((res: Trascodifica) => res.codice.includes(constants.CODICE_NOME_FINANZIARIE) && res.valido == true);
		});
		this.brandService.getDataByCodice(constants.CODICE_FIN_ATTIVITA_LAV).toPromise().then(res => this.attivitaLavList = res);
	}

	ngDoCheck(): void {
		this.obj1 = JSON.stringify(this.originalForm);
		this.obj2 = JSON.stringify(this.form);
		if (this.obj1 !== this.obj2) {
			this.checkAnagrafica();
			this.originalForm = JSON.parse(JSON.stringify(this.form));
		}
	}
  
  stepController(): boolean {

	if (this.completedSteps[this.currentStep]) {
		return true;
	}

    switch (this.currentStep) {
        case 0:
			return this.generatingPdf || !this.validAnagrafica;
        case 7:
            return this.generatingPdf || !this.validAnagrafica;
        case 1:
			return this.generatingPdf || !this.validPromo() || this.formController.checkNoteLength(this.form.sections.dettaglioDto.note, 255);
        case 2:
            return this.generatingPdf || !this.validPiani() || !this.form.sections.abitazione.disegnotecnico;
        case 3:
			return this.generatingPdf || !this.validInstAccessori() || this.formController.checkNoteLength(this.form.sections.installazioneAcc.note, 255);
        case 4:
            return this.generatingPdf || !this.formController.validOmaggio(this.form) || this.form.sections.omaggio.length == 0;
        case 5:
            return this.generatingPdf || !this.formController.validPagamento(this.form, false, true);
        case 6:
            return this.generatingPdf || !this.formController.validPrivacy(this.form);
		case 8:
			return this.formController.validStepFinanziamento(this.form, this.attivitaLavList, this.listFinanziarie );		
		default:
			return this.generatingPdf;
		}
	}

	setStep(num): void {
		this.currentStep = num;
		if (this.currentStep > 0) {
			$('.imgModulo').addClass('yesShow');
		} else {
			$('.imgModulo').removeClass('yesShow');
		}

		if (this.form.sections.pagamento[0]!.tipopagsaldoId) {
			let tipoSaldo = this.formController.getTrascodificaById(this.form.sections.pagamento[0]!.tipopagsaldoId, this.trascodifiche);
			if (tipoSaldo && tipoSaldo.id && tipoSaldo.descrizione.toLocaleUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)) {
				this.isFinanziamentoPayment = true;
				this.totalSteps = Array(9).fill(1).map((x, i) => i);
			} else {
				this.isFinanziamentoPayment = false;
				this.totalSteps = Array(8).fill(1).map((x, i) => i);
			}
		}

		this.scrollTop();
	}

	setBackStep(step: number): void {
		this.isBackDisabled = true; 
	
		setTimeout(() => {
			this.isBackDisabled = false;
			this.completedSteps.fill(false, this.currentStep);
		}, 500);
	
		this.currentStep = step - 1;
	
		if (this.currentStep > 0) {
			$('.imgModulo').addClass('yesShow');
		} else {
			$('.imgModulo').removeClass('yesShow');
		}
		
		this.scrollTop();
	}
	
	checkAnagrafica(): boolean {
		this.validAnagrafica = this.formController.checkAnagrafica(this.form);
		return this.validAnagrafica;
	}

	scrollTop(): void {
		$('html, body').animate({ scrollTop: 0 }, 300);
	}

	async saveModule(): Promise<void> {
		$('body').removeClass('noScr');
		if (document.fullscreenElement) {
			document.exitFullscreen();
		}

		this.completedSteps[this.currentStep] = true;

		if (this.currentStep === 0) {
			// Step salvataggio anagrafica
			this.anagrafica = this.form.anagrafica.anagrafica;
			if (this.anagrafica.id) {
				this.anagraficaService.update(JSON.stringify(this.anagrafica)).subscribe(res => {
					this.ordine.clienteId = res.id;
					this.ordine.cognome = res.cognome;
					this.ordine.nome = res.nome;
					this.ordine.flgManualOrder = false;
					this.orderService.update(JSON.stringify(this.ordine)).subscribe((res:any) => {
						this.ordine = res;
						this.setStep(this.currentStep + 1);
					});
				}, error => {
					console.error('Error => ', error);
					this.formController.openSnackBar(constants.ERROR_GENERIC_API);
				});
			} else {
				this.anagraficaService.save(JSON.stringify(this.anagrafica)).subscribe(res => {
					this.ordine.clienteId = res.id;
					this.ordine.cognome = res.cognome;
					this.ordine.nome = res.nome;
					this.form.anagrafica.anagrafica.id = res.id;
					this.ordine.flgManualOrder = false;
					this.orderService.update(JSON.stringify(this.ordine)).subscribe((res:any) => {
						this.ordine = res;
						this.setStep(this.currentStep + 1);
					});
				}, error => {
					console.error('Error => ', error);
					this.formController.openSnackBar(constants.ERROR_GENERIC_API);
				});
			}

		} else if (this.currentStep === 1) {
			const savingCondizionatori = [];
			const deletePromises = [];
			const saveOrUpdatePromises = [];

			this.form.sections.condizionatori.forEach(r => {
				if (!r.toDelete) {
					savingCondizionatori.push(r);
				} else {
					if(r.id){
						deletePromises.push(
							this.promoService.delete(JSON.stringify(r.id)).toPromise().catch(error => {
								console.error('Error during deletion:', error);
								return Promise.reject('Error during deletion');
							})
						);
					}
				}
			});

			savingCondizionatori.forEach(c => {
				delete c.toDelete;
				if (c.id !== undefined) {
					saveOrUpdatePromises.push(
						this.promoService.updateCondizionatori(JSON.stringify(c)).toPromise().catch(error => {
							console.error('Error during update:', error);
							return Promise.reject('Error during update');
						})
					);
				} else {
					saveOrUpdatePromises.push(
						this.promoService.saveCondizionatori(JSON.stringify(c)).toPromise().catch(error => {
							console.error('Error during save:', error);
							return Promise.reject('Error during save');
						})
					);
				}
			});

			if (this.hasValues(this.form.sections.dettaglioDto)) {
				this.form.sections.dettaglioDto.ordineId = this.form.idOrder;
				if (this.form.sections.dettaglioDto.id === undefined || this.form.sections.dettaglioDto.id === null) {
					saveOrUpdatePromises.push(
						this.dettaglioService.save(JSON.stringify(this.form.sections.dettaglioDto)).toPromise().catch(error => {
							console.error('Error during save dettaglio:', error);
							return Promise.reject('Error during save dettaglio');
						})
					);
				} else {
					saveOrUpdatePromises.push(
						this.dettaglioService.update(JSON.stringify(this.form.sections.dettaglioDto)).toPromise().catch(error => {
							console.error('Error during update dettaglio:', error);
							return Promise.reject('Error during update dettaglio');
						})
					);
				}
			}

			Promise.all([...deletePromises, ...saveOrUpdatePromises])
				.then(() => {
					this.setStep(this.currentStep + 1); 
				})
				.catch(error => {
					this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					console.error('An error occurred during saving:', error); 
				});
		} else if (this.currentStep === 2) {
			//dati abitazione
			if (this.hasValues(this.form.sections.abitazione)) {
				this.form.sections.abitazione.ordineId = this.form.idOrder;
				if (this.form.sections.abitazione.id === undefined || this.form.sections.abitazione.id === null) {
					this.abitazioneService.save(JSON.stringify(this.form.sections.abitazione)).subscribe(() => {
						this.setStep(this.currentStep + 1);
					}, error => {
						console.error('Error => ', error);
						this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					});
				} else {
					this.abitazioneService.update(JSON.stringify(this.form.sections.abitazione)).subscribe(() => {
						this.setStep(this.currentStep + 1);
					}, error => {
						console.error('Error => ', error);
						this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					});
				}
			} else {
				this.setStep(this.currentStep + 1);
			}
		} else if (this.currentStep === 3) {
			//dettagli installazione e accessori
			if (this.hasValues(this.form.sections.installazioneAcc)) {
				this.form.sections.installazioneAcc.ordineId = this.form.idOrder;
				if (this.form.sections.installazioneAcc.id === undefined || this.form.sections.installazioneAcc.id === null) {
					this.installazioneAccService.save(JSON.stringify(this.form.sections.installazioneAcc)).subscribe(() => {
						this.setStep(this.currentStep + 1);
					}, error => {
						console.error('Error => ', error);
						this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					});
				} else {
					this.installazioneAccService.update(JSON.stringify(this.form.sections.installazioneAcc)).subscribe(() => {
						this.setStep(this.currentStep + 1);
					}, error => {
						console.error('Error => ', error);
						this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					});
				}
			} else {
				this.setStep(this.currentStep + 1);
			}
		} else if (this.currentStep === 4) {
			//Step salvataggio omaggioDTO

			let allSuccess = true;

			for (let i = 0; i < this.form.sections.omaggio.length; i++) {
				const omaggio: OmaggioDto = this.form.sections.omaggio[i];
				omaggio.ordineId = this.form.idOrder;

				try {
					if (omaggio.id === undefined || omaggio.id === null) {
						// Save new omaggio
						await this.omaggioService.save(JSON.stringify(omaggio)).toPromise();
					} else {
						// Update existing omaggio
						await this.omaggioService.update(JSON.stringify(omaggio)).toPromise();
					}
				} catch (error) {
					console.error('Error => ', error);
					this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					allSuccess = false;
					break;
				}
			}

			if (allSuccess) {
				this.setStep(this.currentStep + 1);
			}
		} else if (this.currentStep === 5) {
			// Step salvataggio pagamento
			if (this.hasValues(this.form.sections.pagamento[0])) {
				this.form.sections.pagamento[0].ordineId = this.form.idOrder;
				const payment = this.form.sections.pagamento[0];
				if (payment.id === undefined || payment.id === null) {
					this.paymentService.save(JSON.stringify(payment)).subscribe((res: any) => {
						this.form.sections.pagamento[0] = res;
						this.setStep(this.currentStep + 1);
					}, error => {
						console.error('Error => ', error);
						this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					});
				} else {
					this.paymentService.update(JSON.stringify(payment)).subscribe((res: any) => {
						this.form.sections.pagamento[0] = res;
						this.setStep(this.currentStep + 1);
						// se già presente un allegato finanziamento, ma cambio selezione cancella l'allegato.
						if (res.ordineId && res.tipopagsaldoDescrizione && !res.tipopagsaldoDescrizione.toUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)) {
							this.allegatoService.getAllegati(res.ordineId).subscribe((allegati: any[]) => {
								allegati.forEach((allegato: any) => {
									if (allegato.tipoDescrizione.includes(constants.TYPE_DOC_FINANZIAMENTO_STEP)) {
										this.allegatoService.delete(allegato.id).subscribe(() => {
										}, error => {
											console.error('Error => ', error);
											this.formController.openSnackBar(constants.ERROR_GENERIC_API);
										});
									}
								})
							})
						}
					}, error => {
						console.error('Error => ', error);
						this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					});
				}
			}
		} else if (this.currentStep === 6) {
			//Step salvataggio privacy
			this.orderService.update(JSON.stringify(this.form.ordine)).subscribe((res: any) => {
				this.ordine = res;
				this.setStep(this.currentStep + 1);
			}, error => {
				console.error('Error => ', error);
				this.formController.openSnackBar(constants.ERROR_GENERIC_API);
			});
		} else if (this.currentStep === 7) {
			this.anagraficaService.update(JSON.stringify(this.form.anagrafica.anagrafica)).subscribe(res => {
				this.ordine.clienteId = res.id;
				this.ordine.cognome = res.cognome;
				this.ordine.nome = res.nome;
				this.setStep(this.currentStep + 1);
			}, error => {
				console.error('Error => ', error);
				this.formController.openSnackBar(constants.ERROR_GENERIC_API);
			});
		} else if (this.currentStep === 8 && this.form.sections.finanziamentoSections.isAllegatoSaved) {
			// SOLO NEXT-STEP | PARTE FINANZIAMENTO
			this.setStep(this.currentStep + 1);
		}
	}

	togglePrintPreview(show: boolean): void {
		this.showModalPrint = show;
		jQuery('#printModal').modal({ backdrop: 'static', keyboard: false });
		jQuery('#printModal').modal('toggle');
	}

	closeSignPdf() {
		jQuery('#signModal').modal({ backdrop: 'static', keyboard: false });
		jQuery('#signModal').modal('toggle');
	}

	returnIdOrdine(): string {
		return localStorage.getItem('id_order');
	}

	validPiani(): boolean {
		if ((!this.form.sections.abitazione.pianistabile && this.form.sections.abitazione.pianistabile != 0) ||
			(!this.form.sections.abitazione.pianointerv && this.form.sections.abitazione.pianointerv != 0)
		) {
			return false;
		} else {
			return true;
		}
	}

	validInstAccessori(): boolean {
		return this.formController.validInstAccessori(this.form);
	}
		
	validPromo():boolean {
		let select = false;
		if (this.form.sections.condizionatori.length > 0) {
			this.form.sections.condizionatori.forEach(r => {
				if (!r.toDelete) {
					select = true;
				}
			});
		}

		return select;
	}


	edit() {
		this.currentStep = 0;
		this.openSign = false;
		this.completedSteps = Array(this.totalSteps.length).fill(false);
		this.orderService.getSingleOrder(this.form.idOrder).subscribe((res) => {
			res.statopraticaCodice = "A";
			res.statopraticaId = 2;
			this.orderService.update(JSON.stringify(res)).subscribe(res => {
				this.getOrder(this.form.idOrder);
			});
		})
	}

	reload(value: boolean) {
		this.openSign = value;
	}
}
