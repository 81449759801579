<div class="zucchettiMontascaleBg"> ACCESSORI </div>
<div class="zucchettiMontascaleCont">

    <div *ngFor="let acc of form.sections.accessoriAllarme; let i = index;">
        <div class="row">
            <div class="col-8">
                <mat-form-field class="full-width">
                    <mat-label>Accessorio</mat-label>
                    <mat-select [(ngModel)]="acc.tipologiaId" (ngModelChange)="checkItem($event, i)">
                        <mat-option>-- Accessorio --</mat-option>
                        <mat-option [disabled]="alreadySelected(ac.id)" *ngFor="let ac of trascodificheAcc"
                            [value]="ac.id">
                            {{ac.descrizione}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4" *ngIf="acc.tipologiaId">
                <app-input-number (onNumChange)="acc.qta = $event" [step]="1" [num]="acc.qta" [decimal]="false"
                    [payment]="false" [small]="false" label="Quantità"></app-input-number>
                <div class="smallFont text-danger" *ngIf="acc.tipologiaId && !acc.qta">
                    Campo obbligatorio
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-8">
                <mat-form-field class="full-width">
                    <mat-label>Posizione</mat-label>
                    <input matInput placeholder="Posizione" [(ngModel)]="acc.posizione">
                </mat-form-field>
            </div>
            <div class="col-2" *ngIf="form.sections.accessoriAllarme.length > 0">
                <button mat-icon-button color="warn" (click)="removeItem(i)">
                    <mat-icon>cancel</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div>
        <button mat-flat-button class="mondialBtn" (click)="addAccessori()">Aggiungi Accessori</button>
    </div>
</div>
<div class="row">
    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Note</mat-label>
        <textarea matInput [(ngModel)]="form.ordine.note"></textarea>
    </mat-form-field>
</div>