import { Component, OnInit,Input } from '@angular/core';
import { Trascodifica } from '../../../../../classes/trascodifica';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';
import { AccessoriService } from '../../../../bagni-italiani/main-bagni/services/accessori/accessori.service';
import { AccessoriDTO } from '../../../../bagni-italiani/main-bagni/classes/dati-tecnici';
import { constants } from 'src/app/modules/classes/constants';
@Component({
  selector: 'cristalbox-accessori',
  templateUrl: './accessori.component.html',
  styleUrls: ['./accessori.component.scss']
})
export class CristalBoxAccessoriComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  @Input() brand:string;
  availableAccessories: Trascodifica[];

  constructor(private formController: FormControllerService,private accessoriService:AccessoriService) { }

  ngOnInit(): void {
		this.form.sections.accessorio = [];
		this.form.sections.accessorioToDelete = [];
		this.accessoriService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
			for (var i = 0; i < res.length; i++){
				const acc = res[i];
				this.form.sections.accessorio.push(acc);
			}
			this.availableAccessories = this.formController.getTrascodificaByCodice(constants.COD_ACCESSORI_TIPOLOGIA, this.trascodifiche);
			
			if(this.brand === 'crisalBox' && this.form.sections.accessorio.length === 0 ){
				//recupero in base alla descrizione i due accessori da preselezionare.
				let accAvail = this.availableAccessories.filter(tr => tr.descrizione === 'n.1 SEDILE RIBALTABILE' || tr.descrizione === 'n.1 MANIGLIA DI SICUREZZA BIANCA');
				accAvail.forEach(el => {
					//se non ci sono ancora accessori allora devo aggiungere quelli di default
					let accDef = new AccessoriDTO();
					accDef.ordineId = this.form.idOrder;
					accDef.quantita = 1;	
					accDef.tipologiaId = el.id;
					accDef.tipologiaDescrizione = el.descrizione;
					this.form.sections.accessorio.push(accDef);			
				})
			}	
		});
  }

	updateAccessori(event,id): void {
		if (-1 === this.form.sections.accessorio.findIndex(r => r.tipologiaId === id)) {
			if (event.checked) {
				var newVal = new AccessoriDTO();
				newVal.tipologiaId = id;
				this.form.sections.accessorio.push(newVal);
			}
		}else {
				var accToDelete = this.form.sections.accessorio.filter(tr => (tr.tipologiaId === id));
				this.form.sections.accessorioToDelete.push(accToDelete[0]);
				this.form.sections.accessorio.splice(this.form.sections.accessorio.findIndex(r => r.tipologiaId === id), 1);
			}
	}
	
	
	checked(id):string{
		if (-1 !== this.form.sections.accessorio.findIndex(r => r.tipologiaId === id)) {
			return 'checked';
		}else{
			return '';
		}
	}

	alreadySelected(value): boolean {
	    const found = this.form.sections.accessorio.findIndex(r => r.id === value);
	    return found !== - 1;
  	}	
	
  addAccessorio(): void {
    this.form.sections.accessorio.push(new AccessoriDTO());
  }

  removeItem(i): void {
	if(this.form.sections.accessorio[i].id !== undefined){
		this.accessoriService.delete(this.form.sections.accessorio[i].id).subscribe();
	}
    this.form.sections.accessorio.splice(i, 1);
  }

  checkItem(event, i): void {
    if (event == null) {
      this.form.sections.accessorio[i] = new AccessoriDTO();
    }
  }	
}
