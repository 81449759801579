<div class="pt-5 text-center mb-4">
</div>

<div class="text-center mb-4">
    <h4>SELEZIONA IL PRODOTTO</h4>
</div>
<div class="row text-center">
    <div class="col-2">
        <div class="singleBrand" (click)="goToProduct('bagno')">
            BAGNO
        </div>

    </div>
</div>
