<div>
    <div class="text-center {{brand === 'bagniHelp75' ? 'help75Bg' : 'blueBg'}}">
        NUOVI DATI TECNICI
    </div>
    <div class="{{brand === 'bagniHelp75' ? 'hepl75Cont' : 'blueCont'}}">
        <div class="row originalRow">
            <div class="{{brand === 'bagniHelp75' ? 'col-12' : 'col-6'}}">
                <div class="text-center">PIATTO DOCCIA</div>
                <div class="text-center">
                    <img src="assets/img/piatto-doccia.png" alt="piatto doccia"/>
                </div>
                <div>
                    <div>
                        <app-input-number
                                (onNumChange)="form.sections.nuovidatitecnici.piattop = $event"
                                [step]="0.1"
                                [num]="form.sections.nuovidatitecnici.piattop"
                                [decimal]="true"
                                [payment]="false"
                                [small]="false"
                                label="P cm:"></app-input-number>
                        <!--
                        <mat-form-field class="full-width">
                            <mat-label>Larghezza (P) cm:</mat-label>
                            <input matInput type="number" min="0" step=".1" [(ngModel)]="form.sections.nuovidatitecnici.piattop">
                        </mat-form-field>
                        -->
                    </div>

                    <div>
                        <app-input-number
                                (onNumChange)="form.sections.nuovidatitecnici.piattol = $event"
                                [step]="0.1"
                                [num]="form.sections.nuovidatitecnici.piattol"
                                [decimal]="true"
                                [payment]="false"
                                [small]="false"
                                label="L cm:"></app-input-number>
                    </div>

                    <div class="text-center mb-2">
                        <mat-checkbox class="mr-2" (change)="setMuroSx($event)" [checked]="form.sections.nuovidatitecnici.murosx" color="primary"> Muro sx. </mat-checkbox>
                        <mat-checkbox  (change)="setMuroDx($event)" [checked]="form.sections.nuovidatitecnici.murodx" color="primary"> Muro dx. </mat-checkbox>
                    </div>
                </div>
                <div class="verticalHr" *ngIf="brand !== 'bagniHelp75'"></div>
            </div>
            <div class="col-6" *ngIf="brand !== 'bagniHelp75'">
                <div>
                	 <p class="text-center">BOX</p>
                    <app-input-number
                            (onNumChange)="form.sections.nuovidatitecnici.boxl = $event"
                            [step]="0.1"
                            [num]="form.sections.nuovidatitecnici.boxl"
                            [decimal]="true"
                            [payment]="false"
                            [small]="false"
                            label="Lunghezza (L) cm:"></app-input-number>
                    <!--
                    <mat-form-field class="full-width">
                        <mat-label>Lunghezza (L) cm:</mat-label>
                        <input matInput type="number" min="0" step=".1" [(ngModel)]="form.sections.nuovidatitecnici.boxl">
                    </mat-form-field>
                    -->
                </div>

                <div class="mb-2">
                    <app-input-number
                            (onNumChange)="form.sections.nuovidatitecnici.boxp = $event"
                            [step]="0.1"
                            [num]="form.sections.nuovidatitecnici.boxp"
                            [decimal]="true"
                            [payment]="false"
                            [small]="false"
                            label="Larghezza (P) cm:"></app-input-number>
                    <!--
                    <mat-form-field class="full-width">
                        <mat-label>Larghezza (P) cm:</mat-label>
                        <input matInput type="number" min="0" step=".1" [(ngModel)]="form.sections.nuovidatitecnici.boxp">
                    </mat-form-field>
                    -->
                </div>

                <div class="mb-2">
                    Altezza (H) 200 cm
                </div>
                <div class="smallFont text-danger">
                    {{brand === 'bagniHelp75' ? 'Piatto a filo pavimento' : 'Escluso piatto doccia H 6,5'}}
                </div>
            </div>
        </div>
    </div>
</div>
