import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../../../../services/http.service'
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {
  catchError,
  retry,
} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class AnagraficaService extends HttpService {

	constructor(
		public router: Router,
		public http: HttpClient
	) {
		super(router, http);
	}

	update(anagrafica: any): Observable<any> {			
		return this.http.put<any>(this.apiUrl + 'clientes', anagrafica, this.setHttpOptions()).pipe(
			retry(0),
			catchError(err => this.handleError(err))
		);
	}
	
	findById(id:number): Observable<any>{
	    return this.http
           .get<any>(this.apiUrl + 'clientes/'+id ,this.setHttpOptions())
           .pipe(
               retry(0),
               catchError(this.handleError)
           );
	}
	
	save(anagrafica: any): Observable<any> {			
		return this.http.post<any>(this.apiUrl + 'clientes', anagrafica, this.setHttpOptions()).pipe(
			retry(0),
			catchError(err => this.handleError(err))
		);
	}

}