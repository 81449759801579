<div class="row originalRow">
    <div class="col-12">
        <div class="anagraficaContainer">
            <mat-form-field class="full-width" appearance="fill">
                <mat-label>Note</mat-label>
                <textarea matInput [(ngModel)]="form.sections.dettaglioCaldaia.note"></textarea>
            </mat-form-field>
        </div>
    </div>
</div>
