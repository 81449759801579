import { AllegatoService } from 'src/app/services/allegato/allegato.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Ordine } from '../../classes/ordine';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BrandService } from 'src/app/services/brand/brand.service';
import { constants } from '../../classes/constants';
import { UploadAreaComponent } from '../upload-document/upload-area/upload-area.component';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { Allegato } from '../../classes/allegato';
import { UploadService } from 'src/app/services/uploadFiles/upload.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
declare var jQuery: any;

@Component({
  selector: 'app-manually-contract',
  templateUrl: './manually-contract.component.html',
  styleUrls: ['./manually-contract.component.scss']
})
export class ManuallyContractComponent implements OnInit {
  
  isLoaded = false;
  loadingFiles = false;
  isBackDisabled = false;

  idCliente!: number;
  currentStep = 0;
  form: any;
  modello: any = {}; 
  typeOrdineDoc!: any;
  listTipoOrdine:any[];
  ordine!: Ordine;
  upload = false;
  statoPraticaFirmato:any;

  arrayFileExceeded: any[] = [];
  arrayBool: boolean[] = [];
  arrayFiles: any[] = [];
  files: any[] = [];
  idordine?: any;

  FOLDER = environment.awsFolder;
  BUCKET = environment.awsBucket;

  hasValues = (obj: any) => Object.values(obj).some(v => v !== null && typeof v !== 'undefined');

  @ViewChild('fileDropRef', { static: false }) fileDropEl!: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private orderService: OrdersService,
    private snackBar: MatSnackBar,
    private brandService: BrandService,
    private router: Router,
    public formControllerService: FormControllerService,
    private allegatoService: AllegatoService,
    private authenticationService: AuthenticationService,
    private uploadFilesService: UploadService
  ) {
    this.form = {
      idOrder: Number(this.route.snapshot.paramMap.get('id')),
      ordine: null
    };
  }

  ngOnInit(): void {
    this.getOrder(this.form.idOrder);
  }

  getOrder(idOrder: number): void {
    this.orderService.getSingleOrder(idOrder).subscribe(res => {
      this.ordine = res;
      this.form.ordine = res;
      this.idordine = res.idordine;

      this.orderService.getSendId(constants.STATO_PRATICA_FIRMATO).subscribe((res: any) => {

        this.statoPraticaFirmato = res[0];

        this.brandService.getDataByCodice(constants.CODICE_TIPO_DOC).subscribe((tipoOrdine: any[]) => {
          this.listTipoOrdine = tipoOrdine;
          this.typeOrdineDoc = tipoOrdine.find((el: any) => el.descrizione === constants.TYPE_DOC_SCANSIONE_CONTRATTO)!;
          this.modello.tipoId = this.typeOrdineDoc.id;
          localStorage.setItem('id_product', res.prodottoId);
          localStorage.setItem('id_order', res.idordine);
  
          this.isLoaded = true;
        });
      })
    });
  }

    /**
     * handle file from browsing
     */
    handleFileDropped(files: any) {
      if (!files) {
          return;
      } else {
          this.upload = false;
          if (this.isValidNameFile(files) && this.isValidSize(files)) {
              this.arrayFiles = [];
              for (const item of files) {
                  if (this.hasExtension(item)) {
                      if (item.size <= UploadAreaComponent.MAX_FILE_SIZE_BYTES) {
                          this.arrayFiles.push(item);
                      }
                  }
              }

              this.prepareFilesList(this.arrayFiles);
          }
      }
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
  */
  prepareFilesList(files: Array<any>): void {
      for (const item of files) {
          item.progress = 0;
          if (this.files) {
              this.files.push(item);
          }
          item.fileAttr = item.name;
          this.readFileContent(item).then(res => {
              item.content = res.split(',')[1];
          });
      }
      this.fileDropEl.nativeElement.value = '';
      this.uploadFilesSimulator(0);

  }

    /**
     * Simulate the upload process
    */
    uploadFilesSimulator(index: number) {
      setTimeout(() => {
          if (this.files) {
              if (index === this.files.length) {
                  return;
              } else {
                  const progressInterval = setInterval(() => {
                      if (this.files && this.files[index].progress === 100) {
                          clearInterval(progressInterval);
                          this.uploadFilesSimulator(index + 1);
                      } else {
                          if (this.files) {
                              this.files[index].progress += 5;
                          } else {
                              return;
                          }

                      }
                  }, 100);
              }
          } else {
              return;
          }
      }, 200);
  }

  readFileContent(file: File): Promise<any> {
    return new Promise<any>((resolve, reject) => {
        if (!file) {
            resolve('');
        }
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = reader.result;
            resolve(text);
        };
        reader.readAsDataURL(file);
    });
  }

    checkFileExist(name: any, files: any[]): string {
        if (files?.find(a => a.name === name) !== undefined) {
            // rinomino il file prima di salvarlo
            const nameSplit = name.split('.');
            const number = this.generateNumberRandom();
            name = nameSplit[0] + '_' + number + '.' + nameSplit[1];
            // faccio un controllo ricorsivo per vedere che il nuovo nome non sia giÃ¯Â¿Â½ presente nella lista file
            name = this.checkFileExist(name, files);
        }
        return name;
    }

    generateNumberRandom(): string {
        return Math.floor(Math.random() * 10).toString();
    }

    hasExtension(file: File): boolean {
      let valid = true;

      const fileNameParts = file.name.split('.');
      if (fileNameParts.length < 2) {
          valid = false;
          this.snackBar.open('Impossibile caricare il file ' + file.name + ', estensione mancante', '', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
          });
      }

      return valid;
    }

    isValidNameFile(files: any): boolean {
        const fileList = [];
        for (const item of files) {
            fileList.push(item.name);
        }

        const containsInvalidChars = (value: any) => this.formControllerService.invalidCharacters.test(value);

        const invalidName = fileList.some(containsInvalidChars);
        if (invalidName) {
            this.openModal();
        }
        return fileList.length > 0 && !invalidName;
    }

    openModal(): void {
        this.upload = true;
        jQuery('#confirmModal').modal({ backdrop: 'static', keyboard: false });
        jQuery('#confirmModal').modal('toggle');
    }

    openSizeModal(): void {
        if (this.arrayFiles.length == 0) {
            this.upload = true;
        } else {
            this.upload = false;
        }
        jQuery('#sizeExceededModal').modal({ backdrop: 'static', keyboard: false });
        jQuery('#sizeExceededModal').modal('toggle');
    }


    private isValidSize(files: any): boolean {
        const fileList = [];
        this.arrayFileExceeded = [];
        for (const item of files) {
            fileList.push(item.size);
            if (item.size > UploadAreaComponent.MAX_FILE_SIZE_BYTES) {
                this.arrayFileExceeded.push(item);
            }
        }
        const sizeExceeded = fileList.filter(fsize => fsize <= UploadAreaComponent.MAX_FILE_SIZE_BYTES).length > 0;
        if (this.arrayFileExceeded.length > 0) {
            this.openSizeModal();
        }
        return sizeExceeded;
    }

    /**
      * Delete file from files list
      * @param index (File index)
    */
    deleteFile(index: number) {
        if (this.arrayFiles) {
            if (this.arrayFiles[index]?.progress < 100) {
              return;
            }
          this.arrayFiles.splice(index, 1);
        } else {
          return;
        }
    }

    isUploadDisabled() {
      return this.arrayFiles.length === 0 || this.arrayFiles.find(f => f.progress !== 100);
    }

    uploadToS3(files: File[]) {
      if(this.ordine.gdlid && this.ordine.id){
        this.modello.ordineId = this.ordine.id
        this.modello.gdlId = this.ordine.gdlid
        this.checkAndSaveOrder();
      }
    }

    uploadFile(file, idOrdineGdl, name, originalName): Promise<Allegato> {
            return new Promise((resolve, reject) => {
                const formData = new FormData();
    
                formData.append('file', file);
                formData.append('gdlId', file.gdlId);
                formData.append('idOrdineGdl', idOrdineGdl);
    
                this.uploadFilesService.uploadFile(formData).subscribe(
                    (response: any) => {
                        const allegato = new Allegato();
                        allegato.ordineId = file.ordineId;
                        allegato.tipoId = file.tipoId;
                        allegato.path = response;
                        allegato.size = file.size;
                        allegato.originalName = originalName;
                        resolve(allegato);
                },
                (error) => {
                reject(error);
            }
        );
      });
    }

    checkAndSaveOrder(): void {
      this.loadingFiles = true;
      this.arrayBool = [];
      this.arrayFiles.forEach((res) => {
          this.arrayBool.push(false);
      });  
        this.arrayFiles.forEach((f, i) => {
            f.tipoId = this.modello.tipoId;
            f.ordineId = this.modello.ordineId;
            f.gdlId = this.modello.gdlId;

            const find = this.listTipoOrdine.findIndex(tr => tr.id === this.modello.tipoId);
            this.modello.originalName = this.listTipoOrdine[find].note + '_' + this.idordine + '_' + f.fileAttr;
            let indAllegato = Number(i) + Number(this.arrayFiles.length);
            let listEstensione = this.modello.originalName.split(".");
            let estensione = listEstensione[listEstensione.length - 1];
            this.modello.name = this.listTipoOrdine[find].note + '_' + this.idordine + '_allegato_' + indAllegato + '.' + estensione;
            f.loading = true;

            // controllo che il nome file non esista giÃ¯Â¿Â½
            if (this.arrayFiles.length > 0) {
                // se ci sono vecchi allegati salvati controllo prima su quelli
                this.modello.name = this.checkFileExist(this.modello.name, this.arrayFiles);
            }

            this.uploadFile(f, this.idordine, this.modello.name, this.modello.originalName).then((allSave: Allegato) => {
                allSave.dataCreazione = this.formControllerService.createDateAsUTC(new Date());
                // Salvo l'allegato
                this.allegatoService.save(allSave).subscribe(res => {
                    this.arrayBool[i] = true;
                    if (this.arrayBool.every((res: any) => res == true)) {
                        this.arrayFiles = [];
                        this.modello = new Allegato();
                        this.loadingFiles = false;

                        if(this.statoPraticaFirmato && this.statoPraticaFirmato.id){

                          this.ordine.statopraticaId = this.statoPraticaFirmato.id
                          this.ordine.flgManualOrder = true;
                          
                          this.orderService.update(JSON.stringify(this.ordine)).subscribe({
                            next: (res) => {
                              if(res){
                                if(this.authenticationService.returnUserRole() == constants.TYPE_ROLE_SELLER){
                                  this.router.navigate(["order-list"]);
                                }else if(this.authenticationService.returnUserRole() == constants.TYPE_ROLE_CUSTOMER_CARE){
                                  this.router.navigate(["search-order"]); 
                                }
                              }                                  
                            },
                            error: (err) => {
                                this.snackBar.open("Si è presentato un problema durante il salvataggio dell'ordine", '', {
                                  duration: 3500,
                                  horizontalPosition: 'center',
                                  verticalPosition: 'top',
                                });
                                console.error("Errore durante l'aggiornamento:", err);
                            }
                        });
                       }
                    }
                });
            });
        });
    }
}
