import { Injectable } from '@angular/core';
import { HttpService } from '../../../../../../services/http.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  catchError,
  retry,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CaldaiaService extends HttpService{

  constructor(
      public router: Router,
      public http: HttpClient
  ) {
    super(router, http);
  }
  save(caldaie: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'caldaias', caldaie, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }

  update(caldaie: any): Observable<any> {
    return this.http.put<any>(this.apiUrl + 'caldaias', caldaie, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }

  delete(id: any): Observable<any> {
    return this.http.delete<any>(this.apiUrl + 'caldaias/' + id, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }

  findByKey(id: any, key: any): Observable<any>{
    return this.http
               .get<any>(this.apiUrl + 'caldaias?' + key + id , this.setHttpOptions())
               .pipe(
                   retry(0),
                   catchError(this.handleError)
               );
  }

  getDettaglioCaldaia(id: any, key: any): Observable<any> {
    return this.http
        .get<any>(this.apiUrl + 'dettagliocaldaias?' + key + id, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }

  saveDettaglio(dettaglio: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'dettagliocaldaias', dettaglio, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }

  updateDettaglio(dettaglio: any): Observable<any> {
    return this.http.put<any>(this.apiUrl + 'dettagliocaldaias', dettaglio, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }

  getDisegnoCaldaia(id: any, key: any): Observable<any> {
    return this.http
               .get<any>(this.apiUrl + 'disegnocaldaias?' + key + id, this.setHttpOptions()).pipe(
            retry(0),
            catchError(err => this.handleError(err))
        );
  }

  saveDisegno(disegno: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'disegnocaldaias', disegno, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }

  updateDisegno(disegno: any): Observable<any> {
    return this.http.put<any>(this.apiUrl + 'disegnocaldaias', disegno, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }

  getAccessoriCaldaia(id: any, key: any): Observable<any> {
    return this.http
               .get<any>(this.apiUrl + 'accessoricaldaias?' + key + id, this.setHttpOptions()).pipe(
            retry(0),
            catchError(err => this.handleError(err))
        );
  }

  saveAccessori(accessori: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'accessoricaldaias', accessori, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }

  updateAccessori(accessori: any): Observable<any> {
    return this.http.put<any>(this.apiUrl + 'accessoricaldaias', accessori, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }

}
