export class DatitecnicivascaDTO {
	lungvasca: number;
	largvasca: number;
	altvasca: number;
	murosx: boolean;
	murodx: boolean;
	scaricosx: boolean;
	scaricodx: boolean;
	lungmurosx: number;
	largmurosx: number;
	altmurosx: number;
	eliminamurosx: boolean;
	zoppo: boolean;
	teneremurosxdentro: boolean;
	teneremurosxfuori: boolean;
	lungmurodx: number;
	largmurodx: number;
	altmurodx: number;
	eliminamurodx: boolean;
	zoppomurodx: boolean;
	teneremurodxdentro: boolean;
	teneremurodxfuori: boolean;
	lungmurodietro: number;
	largmurodietro: number;
	altmurodietro: number;
	zoppomurodietro: boolean;
	lungmuroavanti: number;
	largmuroavanti: number;
	altmuroavanti: number;
	zoppomuroavanti: boolean;
	ordineId: number;
	murodietro: boolean;
	teneremuroavantidentro: boolean;
	teneremuroavantifuori: boolean;
	teneremurodietrodentro: boolean;
	teneremurodietrofuori: boolean;
}
