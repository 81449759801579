import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {
  screenHeight: any;
  showpdf = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.screenHeight = window.innerHeight - 100;
    setTimeout((): void => {
      this.showpdf = true;
    }, 3000);
  }

  onProgress(event:any): void{
    console.log(event);
  }
}
