import { Component, Input, OnInit } from '@angular/core';
import { AccessorioBox } from '../classes/dati-tecnici';
import { Trascodifica } from '../../../../classes/trascodifica';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { AccessoriService } from '../services/accessori/accessori.service';
import { constants } from 'src/app/modules/classes/constants';
@Component({
  selector: 'app-form-accessori',
  templateUrl: './form-accessori.component.html',
  styleUrls: ['./form-accessori.component.scss']
})
export class FormAccessoriComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche:Trascodifica[];
  availableAccessories : Trascodifica[];
  constructor(private formController: FormControllerService , private accessoriService:AccessoriService) { }

  ngOnInit(): void {
	    this.form.sections.accessoriBox = [];
		this.accessoriService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
			for (var i = 0; i < res.length; i++){
				const acc = this.formController.mappingAccessoriDtoToForm(res[i] , new AccessorioBox());
				this.form.sections.accessoriBox.push(acc);
			}
			this.availableAccessories = this.formController.getTrascodificaByCodice(constants.COD_ACCESSORI_TIPOLOGIA , this.trascodifiche);
		})
  }

  alreadySelected(value): boolean {
    const found = this.form.sections.accessoriBox.findIndex(r => r.tipologiaId === value);
    return found !== - 1;
  }
  addAccessorio(): void {
    this.form.sections.accessoriBox.push(new AccessorioBox());
  }

  removeItem(i): void {
	if(this.form.sections.accessoriBox[i].id !== undefined){
		this.accessoriService.delete(this.form.sections.accessoriBox[i].id).subscribe();
	}
	
    this.form.sections.accessoriBox.splice(i, 1);
  }

  checkItem(event, i): void {
    if (event == null) {
      this.form.sections.accessoriBox[i] = new AccessorioBox();
    }
  }

	validAccessori(): boolean {
		let valid = true;
		if (this.form.sections.accessoriBox.length > 0) {
			for (let i = 0; i < this.form.sections.accessoriBox.length; i++) {
				if (valid) {
					if (this.form.sections.accessoriBox[i].tipologiaId &&
						this.form.sections.accessoriBox[i].quantita
					) {
						valid = true;
					} else if (!this.form.sections.accessoriBox[i].tipologiaId && !this.form.sections.accessoriBox[i].quantita){
						valid = true;
					}  else {
						valid = false;
					}
				}
				
			}
		}
		return valid;
	}
}
