import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { constants } from "src/app/modules/classes/constants";
import { OmaggioDto } from "src/app/modules/classes/omaggio-dto";
import { BrandService } from 'src/app/services/brand/brand.service';
import { AnagraficaDTO } from "src/app/modules/classes/anagrafica";
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { CristalBoxService } from "../../../services/cristalbox.service";
import { DisegnoTecnico } from "src/app/modules/classes/disegno-tecnico";
import { PaymentService } from 'src/app/services/payment/payment.service';
import { environment } from "../../../../../../../environments/environment";
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PrintComponent } from 'src/app/modules/shared/pdf/print/print.component';
import { PrintserviceService } from 'src/app/services/print/printservice.service';
import { PreviewComponent } from "src/app/modules/shared/pdf/preview/preview.component";
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { DisegnoTecnicoService } from "src/app/services/disegnoTecnico/disegno-tecnico.service";
import { OptionsAvailableService } from "src/app/services/optionsAvailable/options-available.service";
import { VascheService } from "src/app/modules/seller/bagni-italiani/main-bagni/services/vasche/vasce.service";
import { OmaggioService } from "src/app/modules/seller/bagni-italiani/main-bagni/services/omaggio/omaggio.service";
import { BoxdocciaService } from "src/app/modules/seller/bagni-italiani/main-bagni/services/boxdoccia/boxdoccia.service";
import { AccessoriService } from "src/app/modules/seller/bagni-italiani/main-bagni/services/accessori/accessori.service";
import { AnagraficaService } from 'src/app/modules/seller/bagni-italiani/main-bagni/services/anagrafica/anagrafica.service';
import { OpereIdraulicheService } from "src/app/modules/seller/bagni-italiani/main-bagni/services/opereIdrauliche/opereIdrauliche.service";
declare var jQuery: any;
@Component({
	selector: 'cristalbox-vasca-in-doccia-print',
	templateUrl: './vasca-in-doccia-print.component.html',
	styleUrls: ['./vasca-in-doccia-print.component.scss']
})
export class CristalBoxVascaInDocciaPrintComponent extends PrintComponent implements OnInit {

	@Input() form: any;
	@Input() trascodifiche: Trascodifica[];
	@Input() modale: boolean;
	@Output("reload") reload: EventEmitter<any> = new EventEmitter<any>();

	idOrdine: number;
	desiredWidth: number;
	proportionalHeight: number;
	timeLeft: number = 60;

	showFrame: boolean = false;
	generatingPdf: boolean = false;
	validAnagrafica: boolean = false;
	openSign: boolean = false;
	sendingPdf: boolean = false;
	showModalPrint: boolean = false;
	loadAll: boolean = false;

	urlFrame: string = '';
	privacyText: string = '';
	colorString: string = "#59ab38";

	member: any;
	abitazione: any;
	error: any;
	datiAttuali: any;
	nuoviDatiTecnici: any;
	nuovoBoxDoccia: any;
	nuovoRivestimentoGres: any;
	nuovoPiattoDoccia: any;
	miscelatori: any;
	vasche: any;
	disegno: any;
	accessori: any;

	omaggio: OmaggioDto[];
	anagrafica: AnagraficaDTO;
	disegnoTecnico: DisegnoTecnico;

	rgbColorLine: number[] = [89, 171, 56];
	nuovoBoxDocciaColumn: any[] = [];
	accessoriColumn: any[] = [];
	mapOptions = new Map();
	integratedSignature = environment.integratedSignature;
	isApiV3 = environment.isV3;

	constructor(
		private router: Router,
		public dialog: MatDialog,
		private cristalBoxService: CristalBoxService,
		public printService: PrintserviceService,
		public formController: FormControllerService,
		private anagraficaService: AnagraficaService,
		private orderService: OrdersService,
		private omaggioService: OmaggioService,
		private paymentService: PaymentService,
		private brandService: BrandService,
		private boxDocciaService: BoxdocciaService,
		private vascheService: VascheService,
		private disegnoTecnicoService: DisegnoTecnicoService,
		private opereIdraulicheService: OpereIdraulicheService,
		private optionsAvailableService: OptionsAvailableService,
		private accessoriService: AccessoriService,
		private httpService: HttpService
	) {
		super(printService);
	}

	ngOnInit(): void {
		this.idOrdine = this.form.idOrder;
		this.color = this.rgbColorLine;
		this.getOrder();

		this.nuovoBoxDocciaColumn = [
			"MODELLO",
			"CRISTALLI",
			"PROFILO",
			"INGRESSO",
		];

		this.accessoriColumn = [
			"ACCESSORIO",
			"QUANTITA'",
		]
	}

	private getOrder(): void {
		this.orderService.getSingleOrder(this.idOrdine).subscribe((res) => {
			this.ordine = res;
			this.brandService
				.getDataByProduct(Number(localStorage.getItem("id_product")))
				.subscribe((resp: any) => {
					this.trascodifiche = resp;
					this.getAnagrafica();
					this.getPagamento();
				});
		});
		this.getOmaggio();
		this.getPrivacy();
		this.loadAll = true;
	}

	private generatePdf(firma: boolean): void {
		let y = this.initPdf(
			this.ordine,
			this.anagrafica,
			"/assets/img/loghi/cristalbox.jpg",
			" Modulo d'ordine vasca in doccia Cristalbox",
			this.colorString,
			constants.BRAND_CRISTALBOX  // brand per dimensione immagine header / footer
		);

		// PROMO
		if (this.ordine.promoId) {

			let tableTitle = constants.LABEL_PRINT_PROMOZIONE;

			let column = [
				"DESCRIZIONE"
			]

			this.genericPromoPrint(this.doc, this.ordine.promoId, tableTitle, column, y, this.trascodifiche, this.colorString, false)

		}

		y = this.printService.returnCurrentY();

		this.genericNoteSpostamentoImpiantoEletPrint(this.doc, y, '#000', '#F00')

		y = this.printService.returnCurrentY();

		// DATI ATTUALI
		if (this.datiAttuali) {
			y = this.printNuoviDatiAttuali(y);
		}

		if (this.nuoviDatiTecnici) {
			y = this.printNuoviDatiTecnici(y);
		}

		this.printNuovoBoxDoccia(y);

		this.printNuovoPiattoDoccia(y);

		if (this.nuovoRivestimentoGres) {
			const tableTitle = constants.LABEL_PRINT_RIVESTIMENTO_GRES;
			this.genericNuovoRivestimentoGres(this.doc, this.nuovoRivestimentoGres, tableTitle, y, this.colorString);
		}

		if (this.miscelatori) {
			const tableTitle = constants.LABEL_PRINT_MISCELATORI;
			this.genericMiscelatoriVascaInDoccia(this.doc, this.miscelatori, tableTitle, y, this.colorString);
		}

		if (this.ordine && this.ordine.note && this.ordine.note?.trim() !== '') {
			const title = constants.LABEL_PRINT_NOTE;
			this.createTableTextNote(title, this.ordine.note, this.printService.returnCurrentY(), this.doc, this.rgbColorLine);
		}

		if (this.vasche) {
			y = this.printVasche(y);
		}

		if (this.disegno && this.checkDisegnoTecnico()) {
			const tableTitle = 'DISEGNO TECNICO'
			this.genericPrintImages(this.doc, this.printService.returnCurrentY(), this.disegno, null, tableTitle, null, this.desiredWidth, this.proportionalHeight, this.colorString, this.color);
		}

		if (this.omaggio?.length > 0) {
			this.genericOmaggio(this.doc, this.omaggio, this.printService.returnCurrentY(), this.colorString);
		}

		if (this.nuovoPiattoDoccia && this.nuovoPiattoDoccia.notelibere && this.nuovoPiattoDoccia.notelibere?.trim() !== '') {
			const title = constants.LABEL_PRINT_NOTE_LIBERE;
			this.createTableTextNote(title, this.nuovoPiattoDoccia.notelibere, this.printService.returnCurrentY(), this.doc, this.rgbColorLine);
		}

		if (this.accessori?.length > 0) {
			const tableTitle = 'ACCESSORI'
			this.genericAccessoriVasca(this.doc, this.accessori, this.accessoriColumn, y, tableTitle, this.colorString);
		}

		if (this.pagamento) {
			this.createTablePagamento(this.doc, this.pagamento, this.ordine, this.printService.returnCurrentY(), 0, this.colorString);
		}

		if (this.privacyText !== '')
			y = this.printPrivacy(y);

		this.creatingPdf = false;
		if (firma) {
			if (!this.integratedSignature) {
				this.createPdf(this.doc);
			} else {
				this.toggleSignPdf(this.doc);
			}
		} else {
			const pdfBlob = this.doc.output("blob");

			// Crea un URL dall'oggetto Blob
			const pdfUrl = URL.createObjectURL(pdfBlob);
			this.openDialog(pdfUrl);
		}
	}

	//Print method
	private printNuoviDatiAttuali(y: number): number {

		if (this.someDatiAttuali()) {
			y = this.printDatiAttuali(y);
		}

		if (this.checkDatiTecniciMuretti()) {
			y = this.printDatiTecniciMurettiAttuali(y);
		}

		(this.someDatiAttuali() || this.checkDatiTecniciMuretti()) ? this.printService.setCurrentY(y += 5) : y;
		return y;
	}

	private printDatiAttuali(y: number): number {
		let sectionTitle = 'DATI ATTUALI VASCA/PIATTO:';
		this.genericOnlyTitleCenter(this.doc, (y - 5), sectionTitle, this.colorString);
		y = this.printService.addLine(y);

		this.doc.addImage("/assets/img/remail/vasca1.png", "PNG", 10, y, 40, 25, "", "SLOW");

		y = this.printService.addLine(y);
		const attualiY = y;
		this.printService.writeLine(this.doc, 9, y, "Lunghezza (L) cm: " + (this.datiAttuali.lungvasca ? this.datiAttuali.lungvasca : "non definita"), 65);
		y = this.printService.addLine(y);
		this.printService.writeLine(this.doc, 9, y, "Larghezza (P) cm: " + (this.datiAttuali.largvasca ? this.datiAttuali.largvasca : "non definita"), 65);
		y = this.printService.addLine(y);
		this.printService.writeLine(this.doc, 9, y, "Altezza (H) cm: " + (this.datiAttuali.altvasca ? this.datiAttuali.altvasca : "non definita"), 65);
		y = attualiY;
		this.printService.writeLine(this.doc, 9, y, "Muro dietro: " + (this.datiAttuali.murodietro ? "si" : "no"), 115);
		y = this.printService.addLine(y);
		this.printService.writeLine(this.doc, 9, y, "Muro sx: " + (this.datiAttuali.murosx ? "si" : "no"), 115);
		y = this.printService.addLine(y);
		this.printService.writeLine(this.doc, 9, y, "Muro dx: " + (this.datiAttuali.murodx ? "si" : "no"), 115);
		y = this.printService.addLine(y);
		y = attualiY;
		this.printService.writeLine(this.doc, 9, y, "Scarico sx: " + (this.datiAttuali.scaricosx ? "si" : "no"), 155);
		y = this.printService.addLine(y);
		this.printService.writeLine(this.doc, 9, y, "Scarico dx: " + (this.datiAttuali.scaricodx ? "si" : "no"), 155);
		y = this.addLine(y + 10);
		return y;
	}

	private printDatiTecniciMurettiAttuali(y: number): number {

		this.printService.setCurrentY(y);
		let sectionTitle = 'DATI TECNICI MURETTI ATTUALI:';
		this.genericOnlyTitleCenter(this.doc, y, sectionTitle, this.colorString);
		y = this.printService.returnCurrentY();
		let murettiY = y;
		let hImage = 20;
		let newPage: boolean = false;
		let xLinePosition = 100;
		
		if(y > this.printService.maxYHeight - hImage){
			this.printService.addNewPage(this.doc, true, this.img, this.ordine, this.currentPdfPage, '');
			y = 28;
			this.printService.setCurrentY(y)
		}

		this.doc.addImage("/assets/img/remail/murosx.png", "PNG", 55, y + 5, 35, hImage, "", "SLOW");
		this.doc.addImage("/assets/img/remail/murodx.png", "PNG", 155, y + 5, 35, hImage, "", "SLOW");
		y = this.printService.addLine(y);
		
		this.printService.writeLine(this.doc, 9, y, "MURETTO SX", 10);
		this.printService.writeLine(this.doc, 9, y, "MURETTO DX", 110);
		this.doc.line(xLinePosition, y - 5, xLinePosition, y + 10);
		y = this.printService.addLine(y);
		
		this.printService.writeLine(this.doc, 9, y, "Lunghezza cm: " + (this.datiAttuali.lungmurosx ? this.datiAttuali.lungmurosx : "non definita"), 10);
		this.printService.writeLine(this.doc, 9, y, "Lunghezza cm: " + (this.datiAttuali.lungmurodx ? this.datiAttuali.lungmurodx : "non definita"), 110);
		this.doc.line(xLinePosition, y - 5, xLinePosition, y + 10);
		y = this.printService.addLine(y);
		
		this.printService.writeLine(this.doc, 9, y, "Larghezza cm: " + (this.datiAttuali.largmurosx ? this.datiAttuali.largmurosx : "non definita"), 10);
		this.printService.writeLine(this.doc, 9, y, "Larghezza cm: " + (this.datiAttuali.largmurodx ? this.datiAttuali.largmurodx : "non definita"), 110);
		this.doc.line(xLinePosition, y - 5, xLinePosition, y + 10);
		y = this.printService.addLine(y);
		
		this.printService.writeLine(this.doc, 9, y, "Altezza cm: " + (this.datiAttuali.altmurosx ? this.datiAttuali.altmurosx : "non definita"), 10);
		this.printService.writeLine(this.doc, 9, y, "Altezza cm: " + (this.datiAttuali.altmurodx ? this.datiAttuali.altmurodx : "non definita"), 110);
		this.doc.line(xLinePosition, y - 5, xLinePosition, y + 10);
		y = this.checkIfServeNewPage(y);
		if(!newPage){
			newPage = y == this.printService.resetY ? true : false;
		}
		y = this.printService.addLine(y);
		
		this.printService.writeLine(this.doc, 9, y, "Eliminare muretto: " + (this.datiAttuali.eliminamurosx ? "si" : "no"), 10);
		this.printService.writeLine(this.doc, 9, y, "Eliminare muretto: " + (this.datiAttuali.eliminamurodx ? "si" : "no"), 110);
		this.doc.line(xLinePosition, y - 5, xLinePosition, y + 10);
		y = this.checkIfServeNewPage(y);
		if(!newPage){
			newPage = y == this.printService.resetY ? true : false;
		}
		y = this.printService.addLine(y);

		this.printService.writeLine(this.doc, 9, y, "Zoppo: " + (this.datiAttuali.zoppo ? "si" : "no"), 10);
		this.printService.writeLine(this.doc, 9, y, "Zoppo: " + (this.datiAttuali.zoppomurodx ? "si" : "no"), 110);
		this.doc.line(xLinePosition, y - 5, xLinePosition, y + 10);
		y = this.checkIfServeNewPage(y);
		if(!newPage){
			newPage = y == this.printService.resetY ? true : false;
		}
		y = this.printService.addLine(y);
		
		this.printService.writeLine(this.doc, 9, y, "Tenere muretto dentro la doccia: " + (this.datiAttuali.teneremurosxdentro ? "si" : "no"), 10);
		this.printService.writeLine(this.doc, 9, y, "Tenere muretto dentro la doccia: " + (this.datiAttuali.teneremurodxdentro ? "si" : "no"), 110);
		this.doc.line(xLinePosition, y - 5, xLinePosition, y + 10);
		y = this.checkIfServeNewPage(y);
		if(!newPage){
			newPage = y == this.printService.resetY ? true : false;
		}
		y = this.printService.addLine(y);
		
		this.printService.writeLine(this.doc, 9, y, "Tenere muretto fuori la doccia: " + (this.datiAttuali.teneremurosxfuori ? "si" : "no"), 10);
		this.printService.writeLine(this.doc, 9, y, "Tenere muretto fuori la doccia: " + (this.datiAttuali.teneremurodxfuori ? "si" : "no"), 110);
		this.doc.line(xLinePosition, y - 5, xLinePosition, y );
		y = this.checkIfServeNewPage(y);
		if(!newPage){
			newPage = y == this.printService.resetY ? true : false;
		}
		y = this.printService.addLine(y);
		y = this.checkIfServeNewPage(y);
		if(!newPage){
			newPage = y == this.printService.resetY ? true : false;
		}
		
		y = this.addLine(y + 5);
		y = this.checkIfServeNewPage(y);
		if(!newPage){
			newPage = y == this.printService.resetY ? true : false;
		}
		
		this.printService.writeLine(this.doc, 9, (y + 2), "MURO DIETRO tenere sempre", 10);
		this.printService.writeLine(this.doc, 9, (y + 2), "MURO AVANTI eliminare sempre", 110);
		this.doc.line(xLinePosition, y, xLinePosition, y + 10);
		y = this.checkIfServeNewPage(y);
		if(!newPage){
			newPage = y == this.printService.resetY ? true : false;
		}
		y = this.printService.addLine(y);

		this.doc.addImage("/assets/img/remail/murettodietro.png", "PNG", 55, y, 35, 20, "", "SLOW");
		this.doc.addImage("/assets/img/remail/murettoavanti.png", "PNG", 155, y, 35, 20, "", "SLOW");
		this.doc.line(xLinePosition, y - 5, xLinePosition, y + 10);
		y = this.checkIfServeNewPage(y);
		if(!newPage){
			newPage = y == this.printService.resetY ? true : false;
		}
		y = this.printService.addLine(y);
		
		this.printService.writeLine(this.doc, 9, y, "Lunghezza cm: " + (this.datiAttuali.lungmurodietro ? this.datiAttuali.lungmurodietro : "non definita"), 10);
		this.printService.writeLine(this.doc, 9, y, "Lunghezza cm: " + (this.datiAttuali.lungmuroavanti ? this.datiAttuali.lungmuroavanti : "non definita"), 110);
		this.doc.line(xLinePosition, y - 5, xLinePosition, y + 10);
		y = this.checkIfServeNewPage(y);
		if(!newPage){
			newPage = y == this.printService.resetY ? true : false;
		}
		y = this.printService.addLine(y);
		
		this.printService.writeLine(this.doc, 9, y, "Larghezza cm: " + (this.datiAttuali.largmurodietro ? this.datiAttuali.largmurodietro : "non definita"), 10);
		this.printService.writeLine(this.doc, 9, y, "Larghezza cm: " + (this.datiAttuali.largmuroavanti ? this.datiAttuali.largmuroavanti : "non definita"), 110);
		this.doc.line(xLinePosition, y - 5, xLinePosition, y + 10);
		y = this.checkIfServeNewPage(y);
		if(!newPage){
			newPage = y == this.printService.resetY ? true : false;
		}
		y = this.printService.addLine(y);
		
		this.printService.writeLine(this.doc, 9, y, "Altezza cm: " + (this.datiAttuali.altmurodietro ? this.datiAttuali.altmurodietro : "non definita"), 10);
		this.printService.writeLine(this.doc, 9, y, "Altezza cm: " + (this.datiAttuali.altmuroavanti ? this.datiAttuali.altmuroavanti : "non definita"), 110);
		this.doc.line(xLinePosition, y - 5, xLinePosition, y + 10);
		y = this.checkIfServeNewPage(y);
		if(!newPage){
			newPage = y == this.printService.resetY ? true : false;
		}
		y = this.printService.addLine(y);
		
		this.printService.writeLine(this.doc, 9, y, "Zoppo: " + (this.datiAttuali.zoppomurodietro ? "si" : "no"), 10);
		this.printService.writeLine(this.doc, 9, y, "Zoppo: " + (this.datiAttuali.zoppomuroavanti ? "si" : "no"), 110);
		this.doc.line(xLinePosition, y - 5, xLinePosition, y + 10);
		y = this.checkIfServeNewPage(y);
		if(!newPage){
			newPage = y == this.printService.resetY ? true : false;
		}
		y = this.printService.addLine(y);
		
		this.printService.writeLine(this.doc, 9, y, "Tenere muretto dentro la doccia: " + (this.datiAttuali.teneremurodietrodentro ? "si" : "no"), 10);
		this.printService.writeLine(this.doc, 9, y, "Tenere muretto dentro la doccia: " + (this.datiAttuali.teneremuroavantidentro ? "si" : "no"), 110);
		this.doc.line(xLinePosition, y - 5, xLinePosition, y + 10);
		y = this.checkIfServeNewPage(y);
		if(!newPage){
			newPage = y == this.printService.resetY ? true : false;
		}
		y = this.printService.addLine(y);
		
		this.printService.writeLine(this.doc, 9, y, "Tenere muretto fuori la doccia: " + (this.datiAttuali.teneremurodietrofuori ? "si" : "no"), 10);
		this.printService.writeLine(this.doc, 9, y, "Tenere muretto fuori la doccia: " + (this.datiAttuali.teneremuroavantifuori ? "si" : "no"), 110);
		this.doc.line(xLinePosition, y - 5, xLinePosition, y);
		y = this.checkIfServeNewPage(y);
		if(!newPage){
			newPage = y == this.printService.resetY ? true : false;
		}

		y = this.printService.addLine(y + 2);
		y = this.checkIfServeNewPage(y);

		this.printService.writeLine(this.doc, 7, y, "Nota: ", 98);
		y = this.printService.addLine(y);
		this.printService.writeLine(this.doc, 7, y, "PER \"ZOPPO\" SI INTENDE UN'ANTA DI BOX IN ACRILICO CHE VA POSIZIONATA SUL MURETTO", 45);

		y = this.printService.addLine(y + 5);

		this.printService.setCurrentY(y + 5);
		return y;
	}

	private printNuoviDatiTecnici(y: number): number {

		const titleTable = 'NUOVI DATI TECNICI';
		this.genericNuoviDatiTecnici(this.doc, this.nuoviDatiTecnici, titleTable, y, this.colorString);

		return y;
	}

	private printNuovoBoxDoccia(y: number): void {
		const option = this.filterOptionMapByKey(constants.TYPE_NUOVO_BOX_DOCCIA);
		let tableTitle = `NUOVO BOX DOCCIA: ${option ? 'SI' : 'NO'}`;

		this.genericPrintBoxDoccia(this.doc, this.nuovoBoxDoccia, tableTitle, option, this.nuovoBoxDocciaColumn, y, this.colorString);
	}

	private printNuovoPiattoDoccia(y: number): void {
		const option = this.filterOptionMapByKey(constants.TYPE_NUOVO_PIATTO_DOCCIA);
		let tableTitle = `NUOVO PIATTO DOCCIA: ${option ? 'SI' : 'NO'}`;
		this.genericNuovoPiattoDoccia(this.doc, this.nuovoPiattoDoccia, y, tableTitle, this.trascodifiche, this.colorString);
		tableTitle = `PIATTI EFFETTO PIETRA`;
		if (this.nuovoPiattoDoccia?.colorepiateffpietraId && this.nuovoPiattoDoccia?.dimpiatteffpietraDescrizione) {
			this.genericPiattoDocciaEffPietra(this.doc, this.nuovoPiattoDoccia, y, tableTitle, this.trascodifiche, this.colorString);
		}
	}

	private printVasche(y: number): number {

		const option = this.filterOptionMapByKey(constants.TYPE_VASCA);
		const vasca = this.vasche[0];
		let baseEmotionY = 0;
		const vascaOpenDetails = [
			{ label: "N. di porte ", value: (vasca?.openposportestrDescrizione) ? (vasca?.openposportestrDescrizione) : 'No' },
			{ label: "Sedile Integrato ", value: (vasca?.opensedileint ? 'Si' : 'No') }
		];

		const apertutaEmotion = this.returnAperturaEmotion(vasca?.emotionaperturaId);
		const isDisegno = (vasca?.emotionaperturaId === undefined || vasca?.emotionaperturaId === null) ? false : true;
		const vascaEmotionDetails = [
			{ label: "Misure ", value: (vasca?.emotiondimDescrizione) ? (vasca?.emotiondimDescrizione) : 'No' },
			{ label: "Disegno", value: apertutaEmotion ? apertutaEmotion : 'No' }
		];

		const vascaExpressDetails = [
			{ label: "Erogazione Acqua", value: (vasca?.expresserogazioneDescrizione) ? (vasca?.expresserogazioneDescrizione) : 'No' },
			{ label: "Lunghezza (L)", value: (vasca?.expresslung) ? (vasca?.expresslung) + ' cm' : 'No' },
			{ label: "Larghezza (P)", value: (vasca?.expresslargh) ? (vasca?.expresslargh) + ' cm' : 'No' },
			{ label: "Altezza (H)", value: (vasca?.expressalt) ? (vasca?.expressalt) + ' cm' : 'No' },
		];

		y = this.printService.returnCurrentY();

		const tableTitle = `VASCA: ${option ? 'SI' : 'NO'}`;
		this.genericOnlyTitleCenter(this.doc, y, tableTitle, this.colorString);

		y = this.printService.returnCurrentY();

		const hasVascaOpenSi = vascaOpenDetails.some(field => field.value !== 'No');
		const hasVascaEmotionSi = vascaEmotionDetails.some(field => field.value !== 'No');
		const hasVascaExpressSi = vascaExpressDetails.some(field => field.value !== 'No');

		if (hasVascaOpenSi) {
			y = this.printVascaOpen(y, hasVascaOpenSi, vascaOpenDetails);
		}

		if (hasVascaEmotionSi) {
			y = this.printVascaEmotion(y, baseEmotionY, vascaEmotionDetails, hasVascaEmotionSi, isDisegno, vasca);
		}

		if (hasVascaExpressSi) {
			y = this.printVascaExpress(y, hasVascaExpressSi, vascaExpressDetails);
		}

		if (!hasVascaOpenSi && !hasVascaEmotionSi && !hasVascaExpressSi) {
			this.printService.setCurrentY(y + 5);
		}
		return y;
	}

	private printVascaOpen(y: number, hasVascaOpenSi, vascaOpenDetails): number {
		let calculateY = vascaOpenDetails?.length * 9 + 25 // 25 h image, 9 h riga 
		y = this.checkIfServeNewPage(y + calculateY);
		y = this.printService.returnCurrentY();

		if (hasVascaOpenSi) {
			this.genericOnlySubtitleCenter(this.doc, y, 'VASCA OPEN', this.colorString);

			y = this.createLine(y);
			this.doc.addImage("/assets/img/remail/vascaopen.jpg", "PNG", 10, y + 2, 40, 25, "", "SLOW");
			y = this.createLine(y);
			vascaOpenDetails.forEach((field, index) => {
				if (field.value !== 'No') {
					y = this.printService.writeLongText(this.doc, 9, y, `${field.label}: ${field.value}`, 65, this.ordine, this.img, this.title, this.currentPdfPage, false);
				} if ((vascaOpenDetails?.length - 1) === index) {
					this.printService.writeLongText(this.doc, 9, y, `Posizione sportello: estraibile`, 65, this.ordine, this.img, this.title, this.currentPdfPage, false);
					this.printService.writeLongText(this.doc, 7, y, `* sportello colore "trasparente"`, 65, this.ordine, this.img, this.title, this.currentPdfPage, false);
					this.printService.writeLongText(this.doc, 9, y + 25, ``, 10, this.ordine, this.img, this.title, this.currentPdfPage, false);
					this.printService.writeLongText(this.doc, 9, y + 25, `Nota:`, 10, this.ordine, this.img, this.title, this.currentPdfPage, false);
					this.printService.writeLongText(this.doc, 8, y, `In caso di seggiolino removibile, lo sportellino dovrà sempre essere posizionato in posizione laterale ( 1 o 3 ) e mai in posizione centrale ( 2 )`, 10, this.ordine, this.img, this.title, this.currentPdfPage, false);
				}
			});

			y = this.createLine(y);
			this.printService.setCurrentY(y + 25);
		}
		return y;
	}

	private printVascaEmotion(y: number, baseEmotionY: number, vascaEmotionDetails, hasVascaEmotionSi, isDisegno, vasca): number {
		const hImage = 25;
		let calculateY = hImage + 10;
		y = this.checkIfServeNewPage(y + calculateY);
		y = this.printService.returnCurrentY();
		if (hasVascaEmotionSi) {

			this.genericOnlySubtitleCenter(this.doc, y, 'VASCA E-MOTION', this.colorString);
			y = this.createLine(y);
			baseEmotionY = y + 5;
			this.doc.addImage("/assets/img/remail/vascaemotion.jpg", "PNG", 10, baseEmotionY, 40, hImage, "", "SLOW");
			y = this.createLine(y);
			vascaEmotionDetails.forEach((field) => {
				if (field.value !== 'No') {
					if (field.label !== 'Disegno') {
						if (isDisegno) {
							this.printService.writeLine(this.doc, 9, y, `${field.label}: ${field.value} cm`, 120);
							y = this.addLine(y);
						} else {
							this.printService.writeLine(this.doc, 9, y, `${field.label}: ${field.value} cm`, 65);
							y = this.addLine(y);
						}
					} else {
						this.doc.addImage(field.value, "PNG", 72, baseEmotionY, 35, 25, "", "SLOW");
						this.printService.writeLine(this.doc, 8, baseEmotionY, `Apertura porta`, 80);
					}
				}
			});

			if (vasca?.emotionlarghporta !== undefined && vasca?.emotionlarghporta !== null && vasca?.emotionlarghporta !== '') {
				if (isDisegno) {
					const larghezzaPorta = vasca?.emotionlarghporta + 'cm';
					this.printService.writeLine(this.doc, 9, y, `Larghezza porta bagno: ${larghezzaPorta ? larghezzaPorta : 'non definita'}`, 120);
					y = this.addLine(y);
					this.printService.writeLine(this.doc, 8, y, `* In caso di larghezza porta inferiore a 70 cm è necessario`, 120);
					y = this.addLine(y);
					this.printService.writeLine(this.doc, 8, y, `lo smontaggio della porta o il taglio del muro.`, 121);
					y = this.addLine(y);
				} else {
					const larghezzaPorta = vasca?.emotionlarghporta + 'cm';
					this.printService.writeLine(this.doc, 9, y, `Larghezza porta bagno: ${larghezzaPorta ? larghezzaPorta : 'non definita'}`, 65);
					y = this.addLine(y);
					this.printService.writeLine(this.doc, 7, y, `* In caso di larghezza porta inferiore a 70 cm è necessario "`, 65);
					y = this.addLine(y);
					this.printService.writeLine(this.doc, 7, y, `lo smontaggio della porta o il taglio del muro.`, 66);
					y = this.addLine(y + 10);
				}
			}
			y = this.createLine(y);

			if (vasca?.emotionlarghporta) {
				const title = "Larghezza porta bagno";
				const colorBorder = [255, 255, 255];
				this.createTableTextNoheader(title, this.addUnitOfMeasure(vasca?.emotionlarghporta, 'cm'), y, this.doc, colorBorder);
			}

			this.printService.setCurrentY(y + 15);
		}
		return y + 15;
	}

	private printVascaExpress(y: number, hasVascaExpressSi, vascaExpressDetails) {
		let calculateSpaceY = 25 + 10; // h image + space 
		y = this.checkIfServeNewPage(y + calculateSpaceY);
		y = this.printService.returnCurrentY();
		let calculateY = y;
		if (hasVascaExpressSi) {
			this.genericOnlySubtitleCenter(this.doc, y, 'VASCA EXPRESS', this.colorString);
			y = this.printService.addLine(y);
			this.doc.addImage("/assets/img/remail/vascaexpress.jpg", "PNG", 10, y + 5, 40, 25, "", "SLOW");
			y = this.createLine(y);
			vascaExpressDetails.forEach((field) => {
				if (field.value !== 'No') {
					y = this.printService.writeLongText(this.doc, 9, y, `${field.label}: ${field.value}`, 65, this.ordine, this.img, this.title, this.currentPdfPage, false);
				}
				calculateY += 9;
			});
			y = this.createLine(y);
			this.printService.setCurrentY(y + 10);
		}
		return y;
	}

	private printPrivacy(y: number): number {
		this.printService.addNewPage(this.doc, true, this.img, this.ordine, this.currentPdfPage, this.title)
		y = this.printService.returnCurrentY();
		this.printService.writePrivacyOne(this.doc, y, this.ordine, this.img, this.title, this.currentPdfPage, this.privacyText);
		return y;
	}

	// Http method
	private getAnagrafica(): void {
		this.anagraficaService.findById(this.ordine.clienteId).subscribe((res) => {
			this.anagrafica = res;
			this.checkAnagrafica();
		});
		this.getOptions();
		this.getDatiAttuali();
		this.getVasche();
	}

	private getOptions(): void {
		this.optionsAvailableService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe((res) => {
			res.forEach(element => {
				const optione = this.trascodifiche.find(el => el.id === element.typeId);
				if (optione) {
					this.mapOptions.set(optione.descrizione, { 'selected': element.selected })
				}
			});
		});
		this.getDisegnoTecnico();
	}

	private getDatiAttuali(): void {
		this.cristalBoxService.getDatiTecVasca(this.idOrdine, 'ordineId.equals=').subscribe((res) => {
			this.datiAttuali = res[0];
			this.getNuoviDatiTecnici();
			this.getNuovoBoxDoccia();
			this.getNuovoPiattoDoccia();
		});
	}

	private getNuovoBoxDoccia(): void {
		this.boxDocciaService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe((res) => {
			this.nuovoBoxDoccia = res;
			this.getNuovoRivestimentoGres();
		});
	}

	private getNuovoPiattoDoccia(): void {
		this.opereIdraulicheService.findByKey(this.idOrdine, 'ordineId.equals=', 'opereidrauliches').subscribe((res) => {
			this.nuovoPiattoDoccia = res[0];
		})
	}

	private getNuoviDatiTecnici(): void {
		this.cristalBoxService.getNuoviDatiTecVasca(this.idOrdine, 'ordineId.equals=').subscribe((res) => {
			this.nuoviDatiTecnici = res[0];
		});
	}

	private getNuovoRivestimentoGres(): void {
		this.cristalBoxService.getNuovoRivGres(this.idOrdine).subscribe((res) => {
			this.nuovoRivestimentoGres = res[0];
		});
		this.getMiscelatori();
	}

	private getMiscelatori(): void {
		this.cristalBoxService.findBykey('miscelatores', 'ordineId.equals=', this.idOrdine).subscribe(res => {
			this.miscelatori = res[0];
		})
	}

	private getVasche(): void {
		this.vascheService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe((res) => {
			this.vasche = res;
		});
	}

	private getDisegnoTecnico(): void {
		this.disegnoTecnicoService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe((res) => {
			this.disegnoTecnico = res[0];
			if (this.disegnoTecnico)
				this.disegno = "data:" + this.disegnoTecnico.disegnoContentType + ";base64," + this.disegnoTecnico.disegno;

			const img = new Image();
			img.src = this.disegno;
			img.onload = () => {
				const originalWidth = img.width;
				const originalHeight = img.height;

				this.desiredWidth = 70; // Larghezza desiderata in unità del PDF
				this.proportionalHeight =
					(this.desiredWidth * originalHeight) / originalWidth;
			};
		});
		this.getAccessori();
	}

	private getOmaggio(): void {
		this.omaggioService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe((res) => {
			this.omaggio = res;
			this.getPagamento();
		});
	}

	private getAccessori(): void {
		this.accessoriService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe((res) => {
			this.accessori = res;
		})
	}

	private getPagamento(): void {
		this.paymentService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe((res) => {
			this.pagamento = res;
		});
	}

	private getPrivacy(): void {
		this.httpService.getPrivacy('assets/json/CbVascaindoccia.json').subscribe((res) => {
			this.privacyText = res;
		});
	}

	// Others method
	private checkAnagrafica(): boolean {
		this.validAnagrafica = this.formController.checkAnagrafica(this.form);
		return this.validAnagrafica;
	}

	private checkDisegnoTecnico(): boolean {
		if (this.disegnoTecnico.disegno && this.disegnoTecnico.disegnoContentType) {
			return true;
		}
		return false;
	}

	private checkDatiTecniciMuretti(): boolean {
		let isMurettoSx = false;
		let isMurettoDx = false;
		let isMuroDietro = false;
		let isMuroDavanti = false;
		if (this.datiAttuali.lungmurosx || this.datiAttuali.lungmurosx || this.datiAttuali.largmurosx || this.datiAttuali.altmurosx || this.datiAttuali.eliminamurosx || this.datiAttuali.zoppo || this.datiAttuali.teneremurosxdentro || this.datiAttuali.teneremurosxfuori) {
			isMurettoSx = true;
		}

		if (this.datiAttuali.lungmurodx || this.datiAttuali.largmurodx || this.datiAttuali.altmurodx || this.datiAttuali.eliminamurodx || this.datiAttuali.zoppomurodx || this.datiAttuali.teneremurodxdentro || this.datiAttuali.teneremurodxfuori) {
			isMurettoDx = true;
		}

		if (this.datiAttuali.lungmurodietro || this.datiAttuali.largmurodietro || this.datiAttuali.altmurodietro || this.datiAttuali.zoppomurodietro || this.datiAttuali.teneremurodietrodentro || this.datiAttuali.teneremurodietrofuori) {
			isMuroDietro = true;
		}

		if (this.datiAttuali.lungmuroavanti || this.datiAttuali.largmuroavanti || this.datiAttuali.altmuroavanti || this.datiAttuali.zoppomuroavanti || this.datiAttuali.teneremuroavantidentro || this.datiAttuali.teneremuroavantifuori) {
			isMuroDavanti = true;
		}

		return (isMurettoSx || isMurettoDx || isMuroDietro || isMuroDavanti);
	}

	private someDatiAttuali(): boolean {
		let isDatiAttuali = false;
		if (this.datiAttuali.lungvasca || this.datiAttuali.largvasca || this.datiAttuali.altvasca || this.datiAttuali.murodietro || this.datiAttuali.murosx || this.datiAttuali.murodx || this.datiAttuali.scaricosx || this.datiAttuali.scaricodx) {
			isDatiAttuali = true;
		}
		return isDatiAttuali;
	}

	private returnAperturaEmotion(idTrascodifica: number): string {
		return this.trascodifiche.find(el => el.id === idTrascodifica)?.note;
	}

	private filterOptionMapByKey(key: string): boolean | undefined {
		let selectedValue: boolean | undefined;
		this.mapOptions.forEach((option, optionKey) => {
			if (optionKey === key) {
				selectedValue = option.selected;
			}
		});

		return selectedValue;
	}

	private addLine(value: number): number {
		return value + 6;
	}

	public togglePrintPreview(): void {
		this.generatePdf(false);
	}

	private createPdf(pdf): void {
		this.sendingPdf = true;
		this.openSign = true;
		const title = localStorage.getItem("id_order");
		pdf.setProperties({
			title,
		});

		const newPdf = btoa(pdf.output());
		const pages = this.printService.returnCurrentPage();
		const coordinates = this.printService.returnCoordinates();
		this.formController
			.sendSignature(
				newPdf,
				this.idOrdine,
				this.form,
				pages,
				coordinates,
				title,
				false
			)
			.then(
				(res) => {
					this.generatingPdf = false;
					this.sendingPdf = false;
					this.router.navigate(["order-list"]);
				},
				(error) => {
					this.generatingPdf = false;
					this.sendingPdf = false;
					this.ordine.statopraticaCodice = "A";
					this.error = error;
					jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
					jQuery("#errorModal").modal("toggle");
				}
			);
	}

	private checkIfServeNewPage(y): number {
		if (y > this.printService.maxYHeight) {
			this.printService.addNewPage(this.doc, true, this.img, this.ordine, this.currentPdfPage, '');
			y = this.printService.resetY;
			this.printService.setCurrentY(y)
		}
		return y;
	}

	private toggleSignPdf(pdf): void {
		const interval = this.startTimer();
		const title = localStorage.getItem("id_order");
		const pages = this.printService.returnCurrentPage();
		this.showModalPrint = !this.showModalPrint;
		this.openSign = true;
		this.showFrame = false;
		this.generatingPdf = true;
		this.urlFrame = '';
		jQuery("#signModal").modal({ backdrop: "static", keyboard: false });
		jQuery("#signModal").modal("toggle");

		if (this.isApiV3) {
			const newPdf = new Blob([pdf.output('blob')], { type: "application/pdf" });
			var split = this.printService.returnCoordinates().split(",");
			const coordinates = {
				width: 120,
				height: 37,
				x: split[0],
				y: split[3],
			}
			this.formController.manageSignature(newPdf, this.form.idOrder, this.form, pages, coordinates, title, environment.signGenericId).then((res: any) => {
				this.urlFrame = res.signers[0].signature_link + '&disable_domain_validation=true';
				setTimeout(() => {
					this.showFrame = true;
					this.sendingPdf = false;
					this.generatingPdf = false;

				}, 2000);
				clearInterval(interval);
			}, error => {
				this.generatingPdf = false;
				this.sendingPdf = false;
				this.ordine.statopraticaCodice = "A";
				clearInterval(interval);
				this.closeSignPdf();
				jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
				jQuery("#errorModal").modal("toggle");
			});
		} else {
			const newPdf = btoa(pdf.output());
			const coordinates = this.printService.returnCoordinates();
			this.formController
				.sendSignature(
					newPdf,
					this.form.idOrder,
					this.form,
					pages,
					coordinates,
					title,
					true
				)
				.then(
					(res) => {
						this.member = res.members[0].id;
						this.urlFrame =
							environment.apiUrl +
							"/procedure/sign?members=" +
							this.member +
							"&signatureUi=" +
							environment.signCBVascaInDoccia; // serve per recuperare il modello del prodotto corretto. In modo che il frame vega visualizzato con i colori corretti.
						this.showFrame = true;
						this.sendingPdf = false;
						this.generatingPdf = false;

						clearInterval(interval);
						// this.router.navigate(['order-list']);
					},
					(error) => {
						this.generatingPdf = false;
						this.sendingPdf = false;
						this.ordine.statopraticaCodice = "A";
						clearInterval(interval);
						jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
						jQuery("#errorModal").modal("toggle");
					}
				);
		}


	}

	private openDialog(pdf: any): void {
		this.dialog.open(PreviewComponent, {
			width: "800px",
			maxWidth: "100%",
			data: {
				pdf,
			},
		});
	}

	private startTimer(): any {
		const intervalId = setInterval(() => {
			if (this.timeLeft === 0) {
				clearInterval(intervalId);
				// richiamo api per invio email
				this.orderService.sendEmail(this.form.idOrder).subscribe();
				jQuery("#timeoutModal").modal({ backdrop: "static", keyboard: false });
				jQuery("#timeoutModal").modal("toggle");

				setTimeout(() => {
					jQuery("#timeoutModal").modal("hide");
				}, 10000);

				setTimeout(() => {
					this.formController.goToDashboard();
				}, 11000);
			}
			if (this.timeLeft > 0) {
				this.timeLeft--;
			}
		}, 1000);
		return intervalId;
	}

	public closeSignPdf(): void {
		jQuery('#signModal').modal({ backdrop: 'static', keyboard: false });
		jQuery('#signModal').modal('toggle');
		this.reload.emit(this.openSign);
		this.setStateToActive();
		this.formController.removeFrame();
	}

	private setStateToActive() {
		this.orderService.getSingleOrder(this.idOrdine).subscribe((res) => {
			this.ordine = res;
			this.ordine.statopraticaCodice = "A";
			this.orderService.update(JSON.stringify(this.ordine)).subscribe(res => {
				this.getOrder();
			});
		})
	}

	public returnIdOrdine(): string {
		return localStorage.getItem("id_order");
	}
}
