
<div class="loginPage">

    <div class="loginContainer">
        <div class="text-center mb-4">
            <img class="mb-3" alt="logo" title="remail" src="/assets/img/gdl.png">
            <div class="intro-login">
                <h4>Recupera la tua password:</h4>
                <p>Inserisci l'indirizzo email associato al tuo profilo</p>
                <p>Riceverai un email con le istruzioni per recuperare la tua password</p>
            </div>
        </div>

        <mat-form-field class="full-width" appearance="outline">
            <mat-label>Email</mat-label>
            <input type="email" matInput [(ngModel)]="email">
            <button mat-button *ngIf="email" matSuffix mat-icon-button aria-label="Clear" (click)="email=''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <p class="mt-0 text-danger small" *ngIf="!validEmail(email)">
            Inserisci un indirizzo email valido
        </p>

        <hr/>
        <div class="text-center">
            <a *ngIf="!loading" mat-flat-button color="warn" class="mr-2" routerLink="/login">
                Torna al login
            </a>
            <button *ngIf="!loading" mat-flat-button color="primary" (click)="forgotAction()" [disabled]="!validEmail(email)">
                Continua <span class="material-icons">login</span>
            </button>
            <mat-progress-bar *ngIf="loading" mode="indeterminate" color="warn"></mat-progress-bar>
        </div>

    </div>

</div>

