import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from '../../../../../classes/trascodifica';
import { StufeService } from '../services/stufe.service';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';
import { constants } from 'src/app/modules/classes/constants';
@Component({
  selector: 'app-accessori-stufa',
  templateUrl: './accessori-stufa.component.html',
  styleUrls: ['./accessori-stufa.component.scss']
})
export class AccessoriStufaComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];

  tipiPuffer: Trascodifica[];
  accStufa: any;

  public constants = constants;

  constructor(
      private stufeService: StufeService,
      private formController: FormControllerService
  ) { }

  async ngOnInit(): Promise<void> {
    
    this.getTranscodifiche();    

    this.form.sections.accStufa = {
      installazionestand: false,
      accessotettobotola: false,
      ponteggio: false,
      cestaidr: false,
      scambiatore: false,
      aspiracenere: false,
      modulowifi: false,
      pelletomaggio: false,
    };

    this.getAccStufa();
  }

  getAccStufa(): void {
    this.stufeService.getAccStufa(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
      this.accStufa = res[0];
      if (this.accStufa) {
        this.form.sections.accStufa = this.accStufa;
      }
    });
  }

  getTranscodifiche(): void {
		this.tipiPuffer = this.formController.getTrascodificaByCodice( constants.COD_TIPO_PUFFER ,this.trascodifiche);
  }
}
