import { Injectable } from '@angular/core';
import { HttpService } from '../../../../services/http.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class CristalBoxService extends HttpService{

  constructor(
      public router: Router,
      public http: HttpClient
  ) {
    super(router, http);
  }

  getDatiTecVasca(id: any, key: any): Observable<any> {
    return this.http
               .get<any>(this.apiUrl + 'datitecnicivascas?' + key + id, this.setHttpOptions()).pipe(
            retry(0),
            catchError(err => this.handleError(err))
        );
  }

  saveDatiTecVasca(tecVasca: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'datitecnicivascas', tecVasca, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }

  updateDatiTecVasca(tecVasca: any): Observable<any> {
    return this.http.put<any>(this.apiUrl + 'datitecnicivascas', tecVasca, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }
  
  getNuoviDatiTecVasca(id: any, key: any): Observable<any> {
    return this.http
               .get<any>(this.apiUrl + 'nuovidatitecnicis?' + key + id, this.setHttpOptions()).pipe(
            retry(0),
            catchError(err => this.handleError(err))
        );
  }
  
  saveNuoviDatiTecVasca(tecVasca: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'nuovidatitecnicis', tecVasca, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }
  
  updateNuoviDatiTecVasca(tecVasca: any): Observable<any> {
    return this.http.put<any>(this.apiUrl + 'nuovidatitecnicis', tecVasca, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }
  
  getBoxDoccia(id: any, key: any): Observable<any> {
    return this.http
               .get<any>(this.apiUrl + 'boxdoccias?' + key + id, this.setHttpOptions()).pipe(
            retry(0),
            catchError(err => this.handleError(err))
        );
  }

  getNuovoRivGres(data): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'gres?ordineId.equals=' + data, this.setHttpOptions())
        .pipe(
            retry(0),
            catchError(this.handleError)
        );
  }
  
  saveBoxDoccia(tecVasca: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'boxdoccias', tecVasca, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }
  
  updateBoxDoccia(tecVasca: any): Observable<any> {
    return this.http.put<any>(this.apiUrl + 'boxdoccias', tecVasca, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }
  
  findBykey(nameApi:string, key:string,value:any){
	    return this.http
               .get<any>(this.apiUrl + nameApi+'?' + key + value, this.setHttpOptions()).pipe(
            retry(0),
            catchError(err => this.handleError(err))
        );
   } 


  save(apiName:string , value: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + apiName , value, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }
  
  update(apiName:string , value: any): Observable<any> {
    return this.http.put<any>(this.apiUrl + apiName, value, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }

	delete(id: any, endpoint:string): Observable<any> {
		return this.http.delete<any>(this.apiUrl+endpoint+'/'+id, this.setHttpOptions())
			.pipe(
				retry(0),
				catchError(this.handleError)
			);
	}  

}
