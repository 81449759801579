import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  catchError,
  retry,
} from 'rxjs/operators';
import { HttpService } from '../../../../../../services/http.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StufeService extends HttpService{

  constructor(
      public router: Router,
      public http: HttpClient
  ) {
    super(router, http);
  }

  save(stufe: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'stufes', stufe, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }

  update(stufe: any): Observable<any> {
    return this.http.put<any>(this.apiUrl + 'stufes', stufe, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }

  delete(id: any): Observable<any> {
    return this.http.delete<any>(this.apiUrl + 'stufes/' + id, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }

  findByKey(id: any, key: any): Observable<any>{
    return this.http
               .get<any>(this.apiUrl + 'stufes?' + key + id , this.setHttpOptions())
               .pipe(
                   retry(0),
                   catchError(this.handleError)
               );
  }

  getDettaglio(id: any, key: any): Observable<any>{
    return this.http
               .get<any>(this.apiUrl + 'dettagliostufes?' + key + id , this.setHttpOptions())
               .pipe(
                   retry(0),
                   catchError(this.handleError)
               );
  }

  saveDt(dettaglio: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'dettagliostufes', dettaglio, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }

  updateDt(dettaglio: any): Observable<any> {
    return this.http.put<any>(this.apiUrl + 'dettagliostufes', dettaglio, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }

  getAccStufa(id: any, key: any): Observable<any>{
    return this.http
               .get<any>(this.apiUrl + 'accessoristufas?' + key + id , this.setHttpOptions())
               .pipe(
                   retry(0),
                   catchError(this.handleError)
               );
  }

  getCasaStufa(id: any, key: any): Observable<any>{
    return this.http
               .get<any>(this.apiUrl + 'casastufas?' + key + id , this.setHttpOptions())
               .pipe(
                   retry(0),
                   catchError(this.handleError)
               );
  }

  saveAccStufa(data): Observable<any>{
    return this.http
               .post<any>(this.apiUrl + 'accessoristufas', data, this.setHttpOptions())
               .pipe(
                   retry(0),
                   catchError(this.handleError)
               );
  }

  updateAccStufa(data): Observable<any>{
    return this.http
               .put<any>(this.apiUrl + 'accessoristufas', data, this.setHttpOptions())
               .pipe(
                   retry(0),
                   catchError(this.handleError)
               );
  }

  /*saveCasaStufa(data): Observable<any>{
    return this.http
               .post<any>(this.apiUrl + 'casastufas', data, this.setHttpOptions())
               .pipe(
                   retry(0),
                   catchError(this.handleError)
               );
  }

  updateCasaStufa(data): Observable<any>{
    return this.http
               .put<any>(this.apiUrl + 'casastufas', data, this.setHttpOptions())
               .pipe(
                   retry(0),
                   catchError(this.handleError)
               );
  }*/
}
