import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MaterialModule } from '../../material/material.module';
import { MainBagniComponent } from './main-bagni/main-bagni.component';
import { FormBagniAnagraficaComponent } from './main-bagni/form-anagrafica-bagni/form-anagrafica.component';
import { BagnoComponent } from './main-bagni/bagno/bagno.component';
import { FormDatiTecniciAttComponent } from './main-bagni/form-dati-tecnici-att/form-dati-tecnici-att.component';
import { RivestimentiComponent } from './main-bagni/form-rivestimenti/rivestimenti.component';
import { FormNuovaCassettaComponent } from './main-bagni/form-nuova-cassetta/form-nuova-cassetta.component';
import { FormNuovaRubinetteriaComponent } from './main-bagni/form-nuova-rubinetteria/form-nuova-rubinetteria.component';
import { FormGroheComponent } from './main-bagni/form-grohe/form-grohe.component';
import { FormOpereIdraulicheComponent } from './main-bagni/form-opere-idrauliche/form-opere-idrauliche.component';
import { FormNuoviSanitariComponent } from './main-bagni/form-nuovi-sanitari/form-nuovi-sanitari.component';
import { FormNuoviTermosifoniArrediComponent } from './main-bagni/form-nuovi-termosifoni-arredi/form-nuovi-termosifoni-arredi.component';
import { FormPiattoEffettoPietraComponent } from './main-bagni/form-piatto-effetto-pietra/form-piatto-effetto-pietra.component';
import { FormPiattoDocciaLargoComponent } from './main-bagni/form-piatto-doccia-largo/form-piatto-doccia-largo.component';
import { FormPiattoDocciaStrettoComponent } from './main-bagni/form-piatto-doccia-stretto/form-piatto-doccia-stretto.component';
import { FormVascheComponent } from './main-bagni/form-vasche/form-vasche.component';
import { RiepilogoVascheComponent } from './main-bagni/riepilogo-vasche/riepilogo-vasche.component';
import { FormNuoviDatiComponent } from './main-bagni/form-nuovi-dati/form-nuovi-dati.component';
import { FormNuoviBoxComponent } from './main-bagni/form-nuovi-box/form-nuovi-box.component';
import { FormOmaggioComponent } from './main-bagni/form-omaggio/form-omaggio.component';
import { FormNuoviAccessoriComponent } from './main-bagni/form-nuovi-accessori/form-nuovi-accessori.component';
import { FormPiantinaAttualeComponent } from './main-bagni/form-piantina-attuale/form-piantina-attuale.component';
import { FormPiantinaNuovoComponent } from './main-bagni/form-piantina-nuovo/form-piantina-nuovo.component';
import { SharedModule } from '../../shared/shared.module';
import { FormAccessoriComponent } from './main-bagni/form-accessori/form-accessori.component';
import { PaintableModule } from 'paintablejs/angular';
import { FormPiattoStoneComponent } from './main-bagni/form-piatto-stone/form-piatto-stone.component';
import { Help75Component } from './help75/help75.component';
import { RemailModule} from '../remail/remail.module';
import { OpereidraulicheComponent } from './help75/opereidrauliche/opereidrauliche.component';
import { FormNuovoPiattoDocciaComponent } from './main-bagni/form-nuovo-piatto-doccia/form-nuovo-piatto-doccia.component';
import { BagnoPraticoComponent } from './bagno-pratico/bagno-pratico.component';
import { PraticoRivestimentiComponent } from './bagno-pratico/pratico-rivestimenti/pratico-rivestimenti.component';
import { NuovoPiattoDocciaPraticoComponent } from './bagno-pratico/nuovo-piatto-doccia-pratico/nuovo-piatto-doccia-pratico.component';
import { PiattoEffettoPietraPraticoComponent } from './bagno-pratico/piatto-effetto-pietra-pratico/piatto-effetto-pietra-pratico.component';
import { FormPortaComponent } from './bagno-pratico/form-porta/form-porta.component';
import { FormSicurezzaComponent } from './bagno-pratico/form-sicurezza/form-sicurezza.component';
import { PrintPraticoComponent } from './bagno-pratico/print-pratico/print-pratico.component';
import { NuoviSanitariPraticoComponent } from './bagno-pratico/nuovi-sanitari-pratico/nuovi-sanitari-pratico.component';
import { BoxDocciaPraticoComponent } from './bagno-pratico/box-doccia-pratico/box-doccia-pratico.component';
import { PrintHelp75Component } from './help75/print-help75/print-help75.component';
import { PrintBagniItalianiComponent } from './main-bagni/print-bagni-italiani/print-bagni-italiani.component';

@NgModule({
	declarations: [
		MainBagniComponent,
		FormBagniAnagraficaComponent,
		BagnoComponent,
		FormDatiTecniciAttComponent,
		RivestimentiComponent,
		FormNuovaCassettaComponent,
		FormNuovaRubinetteriaComponent,
		FormGroheComponent,
		FormOpereIdraulicheComponent,
		FormNuoviSanitariComponent,
		FormNuoviTermosifoniArrediComponent,
		FormPiattoEffettoPietraComponent,
		FormPiattoDocciaLargoComponent,
		FormPiattoDocciaStrettoComponent,
		FormVascheComponent,
		RiepilogoVascheComponent,
		FormNuoviDatiComponent,
		FormNuoviBoxComponent,
		FormOmaggioComponent,
		FormNuoviAccessoriComponent,
		FormPiantinaAttualeComponent,
		FormPiantinaNuovoComponent,
		FormAccessoriComponent,
		FormPiattoStoneComponent,
		Help75Component,
		OpereidraulicheComponent,
		FormNuovoPiattoDocciaComponent,
		BagnoPraticoComponent,
		PraticoRivestimentiComponent,
		NuovoPiattoDocciaPraticoComponent,
		PiattoEffettoPietraPraticoComponent,
		FormPortaComponent, FormSicurezzaComponent,
		PrintPraticoComponent, 
		NuoviSanitariPraticoComponent, 
		BoxDocciaPraticoComponent, 
		PrintHelp75Component, 
		PrintBagniItalianiComponent
	],
	imports: [
		CommonModule,
		MatFormFieldModule,
		MaterialModule,
		SharedModule,
		PaintableModule,
		RemailModule
	]
})
export class BagniItalianiModule { }
