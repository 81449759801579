<div class="text-center blueBg">
    RIVESTIMENTI
</div>
<div class="blueCont">
    <div class="">
        <mat-form-field class="full-width" appearance="standard">
            <mat-label>Pavimento</mat-label>
            <input matInput type="text" [(ngModel)]="form.sections.rivestimenti.pavimento">
        </mat-form-field>
    </div>
    <div class="">
        <mat-form-field class="full-width" appearance="standard">
            <mat-label>Pareti</mat-label>
            <input matInput type="text" [(ngModel)]="form.sections.rivestimenti.pareti">
        </mat-form-field>
    </div>
    <div class="row">
        <div class="col-3">
        	<mat-form-field class="full-width" appearance="standard">
            	<mat-label>A</mat-label>
        	 	<input matInput type="text" [(ngModel)]="form.sections.rivestimenti.A">
        	 </mat-form-field>	
            <!-- <app-input-number
                    (onNumChange)="form.sections.rivestimenti.A = $event"
                    [step]="0.1"
                    [num]="form.sections.rivestimenti.A"
                    [decimal]="true"
                    [payment]="false"
                    [small]="false"
                    label="A"></app-input-number> -->
            <!--
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>A</mat-label>
                <input matInput type="number" min="0" step="0.1" min="0" [(ngModel)]="form.sections.rivestimenti.A">
            </mat-form-field>
            -->
        </div>
        <div class="col-3">
            <app-input-number
                    (onNumChange)="form.sections.rivestimenti.Ah = $event"
                    [step]="0.1"
                    [num]="form.sections.rivestimenti.Ah"
                    [decimal]="true"
                    [payment]="false"
                    [small]="false"
                    label="h"></app-input-number>
            <!--
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>h</mat-label>
                <input matInput type="number" min="0" step="0.1" min="0" [(ngModel)]="form.sections.rivestimenti.Ah">
            </mat-form-field>
            -->
        </div>
        <div class="col-3">
        	<mat-form-field class="full-width" appearance="standard">
            	<mat-label>B</mat-label>
        		<input matInput type="text" [(ngModel)]="form.sections.rivestimenti.B">
        	</mat-form-field>	
            <!-- <app-input-number
                    (onNumChange)="form.sections.rivestimenti.B = $event"
                    [step]="0.1"
                    [num]="form.sections.rivestimenti.B"
                    [decimal]="true"
                    [payment]="false"
                    [small]="false"
                    label="B"></app-input-number> -->
            <!--
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>B</mat-label>
                <input matInput type="number" min="0" step="0.1" min="0" [(ngModel)]="form.sections.rivestimenti.B">
            </mat-form-field>
            -->
        </div>
        <div class="col-3">
            <app-input-number
                    (onNumChange)="form.sections.rivestimenti.Bh = $event"
                    [step]="0.1"
                    [num]="form.sections.rivestimenti.Bh"
                    [decimal]="true"
                    [payment]="false"
                    [small]="false"
                    label="h"></app-input-number>
            <!--
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>h</mat-label>
                <input matInput type="number" min="0" step="0.1" min="0" [(ngModel)]="form.sections.rivestimenti.Bh">
            </mat-form-field>
            -->
        </div>
    </div>
    
    <div class="row">
        <div class="col-3">
        	<mat-form-field class="full-width" appearance="standard">
            	<mat-label>C</mat-label>
        		<input matInput type="text" [(ngModel)]="form.sections.rivestimenti.pareteC">
        	</mat-form-field>
            <!-- <app-input-number
                    (onNumChange)="form.sections.rivestimenti.pareteC = $event"
                    [step]="0.1"
                    [num]="form.sections.rivestimenti.pareteC"
                    [decimal]="true"
                    [payment]="false"
                    [small]="false"
                    label="C"></app-input-number> -->
        </div>
        <div class="col-3">
            <app-input-number
                    (onNumChange)="form.sections.rivestimenti.pareteCh = $event"
                    [step]="0.1"
                    [num]="form.sections.rivestimenti.pareteCh"
                    [decimal]="true"
                    [payment]="false"
                    [small]="false"
                    label="h"></app-input-number>
        </div>
        <div class="col-3">
        	<mat-form-field class="full-width" appearance="standard">
            	<mat-label>D</mat-label>
        		<input matInput type="text" [(ngModel)]="form.sections.rivestimenti.pareteD">
        	</mat-form-field>	
            <!-- <app-input-number
                    (onNumChange)="form.sections.rivestimenti.pareteD = $event"
                    [step]="0.1"
                    [num]="form.sections.rivestimenti.pareteD"
                    [decimal]="true"
                    [payment]="false"
                    [small]="false"
                    label="D"></app-input-number> -->
        </div>
        <div class="col-3">
            <app-input-number
                    (onNumChange)="form.sections.rivestimenti.pareteDh = $event"
                    [step]="0.1"
                    [num]="form.sections.rivestimenti.pareteDh"
                    [decimal]="true"
                    [payment]="false"
                    [small]="false"
                    label="h"></app-input-number>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field class="full-width" appearance="fill">
                <mat-label>Note</mat-label>
                <textarea matInput [(ngModel)]="form.sections.rivestimenti.note"></textarea>
            </mat-form-field>
        </div>
    </div>

</div>
