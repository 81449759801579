import { Component, Input, OnInit } from '@angular/core';
import { constants } from 'src/app/modules/classes/constants';
import { MontascaleMisureService } from 'src/app/services/montascale-misure/montascale-misure.service';
@Component({
  selector: 'app-rilievo-misure-schiena-e-sedile',
  templateUrl: './rilievo-misure-schiena-e-sedile.component.html',
  styleUrls: ['./rilievo-misure-schiena-e-sedile.component.scss']
})
export class RilievoMisureSchienaESedileComponent implements OnInit {

  @Input() form: any;

  public constants = constants;

  constructor(private montascaleMisureService: MontascaleMisureService) { }

  ngOnInit(): void {
    this.montascaleMisureService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {			
			if(res[0]){
        this.form.sections.montascaleMisure =  res[0];
      }
	  });
  }
}
