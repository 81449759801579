export class Porta{
    altezza:number;
    aperturaDescrizione;	string;
    aperturaId:number;
    coloreDescrizione:	string;
    coloreId:number;
    id:number;
    lunghezza:number;
    note:string;
    ordineId:number;
    ordineIdordine:string;
    spessore:number;
}