<div class="digitalSignatureButtons previewPrint" style="height: 124px"
    *ngIf="ordine && ordine.statopraticaCodice === 'A'">
    <button class="signButton" (click)="togglePrintPreview()" [disabled]="generatingPdf || !loadAll">
        <span class="material-icons d-block">
            picture_as_pdf
        </span>
        <span class="d-block mb-2">Anteprima Contratto</span>
    </button>
</div>

<div class="digitalSignatureButtons" *ngIf="ordine && ordine.statopraticaCodice === 'A'">
    <button *ngIf="integratedSignature" class="signButton" [ngClass]="{'totopBtn': generatingPdf}"
        (click)="generatePdf(true)" [disabled]="!validAnagrafica || generatingPdf || !loadAll">
        <span class="material-icons d-block">
            edit
        </span>
        <span class="d-block mb-2">Firma il contratto</span>
        <span class="d-block smallFont">
            Verrà inviato a {{form.anagrafica.anagrafica.email}}
        </span>
    </button>
    <!-- BOTTONE PER FIRMA CLASSICA -->
    <button *ngIf="!integratedSignature" class="signButton" [ngClass]="{'totopBtn': generatingPdf}"
        (click)="generatePdf(true)" [disabled]="!validAnagrafica || generatingPdf">
        <span class="material-icons d-block">
            edit
        </span>
        <span class="d-block mb-2">Invia documento per firma</span>
        <span class="d-block smallFont">
            Verrà inviato a {{form.anagrafica.anagrafica.email}}
        </span>
    </button>
    <div class="loadingPart" [ngClass]="{'totopLoad': generatingPdf}">
        <div class="mb-4">Caricamento</div>
        <mat-progress-bar class="mb-3" mode="buffer"></mat-progress-bar>
        <div style="text-align:left;font-size:12px;">
            <div *ngIf="sendingPdf">
                Invio PDF
            </div>
        </div>
    </div>
</div>
<div [ngClass]="{'totopLoad': creatingPdf}" [hidden]="!creatingPdf">
    <div style=" font-size:20px;">Generazione del contratto in corso.<br />L'operazione potrebbe
        richiedere alcuni istanti. Si prega di non abbandonare la pagina. </div>
</div>
<div [ngClass]="{'totopLoad': generatingPdf}" [hidden]="!generatingPdf">
    <div style=" font-size:20px;">Generazione del contratto in corso.<br />L'operazione potrebbe
        richiedere alcuni istanti. Si prega di non abbandonare la pagina. </div>
</div>

<div class="text-danger" *ngIf="!validAnagrafica">
    Compila correttamente il punto 1 del modulo d'ordine
</div>

<div class="modal fade" id="signModal" tabindex="-1">
    <div class="modal-dialog modal-xxl modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">Procedura di firma contratto {{returnIdOrdine()}}</h3>
                <button type="button" class="close" (click)="closeSignPdf()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <mat-progress-bar mode="buffer" *ngIf="!showFrame"></mat-progress-bar>
                <div *ngIf="showFrame">
                    <iframe id="signFrame" class="col-12" style="height: 500px;" [src]="urlFrame | safe"></iframe>
                </div>
            </div>
            <div class="modal-footer">
                <button mat-button (click)="closeSignPdf()">Chiudi</button>
            </div>
        </div>
    </div>
</div>