import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { PrintserviceService } from 'src/app/services/print/printservice.service';
import { PrintComponent } from 'src/app/modules/shared/pdf/print/print.component';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { PreviewComponent } from 'src/app/modules/shared/pdf/preview/preview.component';
import { MatDialog } from '@angular/material/dialog';
import { AccessoriDepuratoreDto } from 'src/app/modules/classes/accessori-depuratore-dto';
import { DepuratoreDto } from 'src/app/modules/classes/depuratore-dto';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { AccessoriDepuratoreService } from 'src/app/services/accessori-depuratore/accessori-depuratore.service';
import { DepuratoreService } from 'src/app/services/depuratore/depuratore.service';
import { HttpService } from 'src/app/services/http.service';
import { constants } from '../../../../../classes/constants';
declare var jQuery: any;

@Component({
  selector: 'app-print-depuratore',
  templateUrl: './print-depuratore.component.html',
  styleUrls: ['./print-depuratore.component.scss']
})
export class PrintDepuratoreComponent extends PrintComponent implements OnInit {

  @Input() idOrder: number;
  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  @Input() anagrafica: any;
  @Output('reload') reload: EventEmitter<any> = new EventEmitter<any>();


  generatingPdf: boolean = false;
  creatingPdf: boolean = false;
  validAnagrafica: boolean = false;
  loadAll: boolean = false;
  sendingPdf: boolean = false;
  openSign: boolean = false;
  showFrame: boolean = false;
  showModalPrint: boolean = false;

  privacyText: string = '';
  urlFrame: string = '';
  noteAccessori: string = '';
  colorString: string = '#008000';
  columnPdf: string[];

  timeLeft: number = 60;
  rgbColorLine: number[] = [0, 128, 0];

  depuratore: DepuratoreDto;
  accessoriDepuratore: AccessoriDepuratoreDto[] = [];

  ordine: any;
  error: any;
  member: any;

  integratedSignature = environment.integratedSignature;
  isApiV3 = environment.isV3;
  constructor(
    private router: Router,
    public formController: FormControllerService,
    private orderService: OrdersService,
    public dialog: MatDialog,
    private depuratoreService: DepuratoreService,
    private accessoriDepuratoreService: AccessoriDepuratoreService,
    private paymentService: PaymentService,
    public printService: PrintserviceService,
    private httpService: HttpService,
  ) {
    super(printService);
  }

  ngOnInit(): void {
    this.getOrder(this.idOrder);
    this.checkAnagrafica();
    this.getDepuratore();
    this.getPagamento();
    this.getPrivacy();

    this.columnPdf = [
      "NOME",
      "QUANTITA'"
    ]
  }

  getOrder(idOrder): void {
    this.orderService.getSingleOrder(idOrder).subscribe(res => {
      this.ordine = res;
      this.form.ordine = res;
      this.noteAccessori = res.note;
    });
  }

  async generatePdf(firma: boolean): Promise<void> {
    let y = this.initPdf(this.ordine, this.anagrafica, '/assets/img/loghi/ARIEL.jpg', 'Modulo d\'ordine depuratore Ariel', this.colorString);

    this.printService.writeFooterPage(
      this.doc,
      this.ordine,
      this.currentPdfPage,
      6
    );

    let sectionTitle: string = 'MODELLO DEPURATORE';
    if (this.depuratore && this.depuratore.tipologiaId && this.depuratore.quantita) {

      const depuratoreAbbinato = await this.trascodifiche.find(el => el.codice === constants.COD_DEPURATORE_ACQUA_BUONA_ARIEL_ABB && el.descrizione === constants.DEPURATORE_ACQUA_BUONA_ARIEL);
      if (depuratoreAbbinato?.id === this.depuratore?.tipologiaId) {
        if (!this.depuratore?.quantita) {
          sectionTitle += ' - VENDITA ABBINATA: SI';
        } else {
          sectionTitle += ' - VENDITA ABBINATA';
        }
      }
      this.modelloDepuratoreAriel(this.doc, this.depuratore, sectionTitle, this.columnPdf, y, this.trascodifiche, this.colorString);
    } else if (this.depuratore && this.depuratore.tipologiaId && !this.depuratore.quantita) {
      const depuratoreAbbinato = await this.trascodifiche.find(el => el.codice === constants.COD_DEPURATORE_ACQUA_BUONA_ARIEL_ABB && el.descrizione === constants.DEPURATORE_ACQUA_BUONA_ARIEL);
      if (depuratoreAbbinato?.id === this.depuratore.tipologiaId) {
        if (!this.depuratore?.quantita) {
          sectionTitle += ' - VENDITA ABBINATA: SI';
        } else {
          sectionTitle += ' - VENDITA ABBINATA';
        }
        this.genericOnlyTitleCenter(this.doc, (y - 5), sectionTitle, this.colorString);
      }
    }

    if (this.accessoriDepuratore.length) {
      const sectionTitle: string = 'ACCESSORI';

      let areElements = this.accessoriDepuratore.filter(element => element.quantita);

      if (areElements.length) {
        this.accessoriDepuratoreAriel(this.doc, this.accessoriDepuratore, sectionTitle, this.columnPdf, y, this.trascodifiche, this.colorString);
      }
    }

    y = this.printService.returnCurrentY();

    if (this.noteAccessori && this.noteAccessori.trim() !== '') {
      const title = ['NOTE LIBERE']
      this.createTableTextNoheader(title, this.noteAccessori, y, this.doc, this.rgbColorLine);
    }

    y = this.printService.returnCurrentY();

    if (this.pagamento) {
      this.createTablePagamentoNoDet(this.doc, this.pagamento, y, 0, this.colorString);
    }

    y = this.printService.addNewPage(
      this.doc,
      true,
      this.img,
      this.ordine,
      this.currentPdfPage,
      this.title
    );

    this.printService.writePrivacyOne(
      this.doc,
      y,
      this.ordine,
      this.img,
      this.title,
      this.currentPdfPage,
      this.privacyText
    );
    y = this.printService.returnCurrentY();

    this.creatingPdf = false;
    if (firma) {
      if (!this.integratedSignature) {
        this.createPdf(this.doc);
      } else {
        this.toggleSignPdf(this.doc);
      }
    } else {
      const pdfBlob = this.doc.output('blob');

      // Crea un URL dall'oggetto Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);
      this.openDialog(pdfUrl);
    }
  }

  //http Methods

  private getDepuratore(): void {
    this.depuratoreService.findByKey(this.idOrder, 'ordineId.equals=').subscribe(res => {
      if (res[0] !== undefined) {
        this.depuratore = res[0];
      }
    });
    this.getAccessoriDepuratore();
  }

  private getAccessoriDepuratore(): void {
    this.accessoriDepuratoreService.findByKey(this.idOrder, 'ordineId.equals=').subscribe(res => {
      if (res) {
        this.accessoriDepuratore = res;
      }
    })
  }

  private getPagamento(): void {
    this.paymentService.findByKey(this.idOrder, 'ordineId.equals=').subscribe((res) => {
      this.pagamento = res;
    });
    this.loadAll = true;
  }

  private getPrivacy(): void {
    this.httpService.getPrivacy('assets/json/depuratore.json').subscribe((res) => {
      this.privacyText = res;
    });
  }

  private checkAnagrafica(): boolean {
    this.validAnagrafica = this.formController.checkAnagrafica(this.form);
    return this.validAnagrafica;
  }

  private createPdf(pdf): void {
    this.sendingPdf = true;
    this.openSign = true;
    const title = localStorage.getItem("id_order");
    pdf.setProperties({
      title,
    });

    const newPdf = btoa(pdf.output());
    const pages = this.printService.returnCurrentPage();
    const coordinates = this.printService.returnCoordinates();
    this.formController
      .sendSignature(
        newPdf,
        this.idOrder,
        this.form,
        pages,
        coordinates,
        title,
        false
      )
      .then(
        (res) => {
          this.generatingPdf = false;
          this.sendingPdf = false;
          this.router.navigate(["order-list"]);
        },
        (error) => {
          this.generatingPdf = false;
          this.sendingPdf = false;
          this.ordine.statopraticaCodice = "A";
          this.error = error;
          jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
          jQuery("#errorModal").modal("toggle");
        }
      );
  }

  togglePrintPreview(): void {
    this.generatePdf(false);
  }

  openDialog(pdf: any): void {
    this.dialog.open(PreviewComponent, {
      width: '800px',
      maxWidth: '100%',
      data: {
        pdf,
      },
    });
  }

  private toggleSignPdf(pdf): void {
    const interval = this.startTimer();
    const title = localStorage.getItem('id_order');


    const pages = this.printService.returnCurrentPage();

    this.showModalPrint = !this.showModalPrint;
    this.openSign = true;
    this.showFrame = false;
    this.generatingPdf = true;
    this.urlFrame = '';
    jQuery('#signModal').modal({ backdrop: 'static', keyboard: false });
    jQuery('#signModal').modal('toggle');
    if (this.isApiV3) {
      const newPdf = new Blob([pdf.output('blob')], { type: "application/pdf" });
      var split = this.printService.returnCoordinates().split(",");
      const coordinates = {
        width: 120,
        height: 37,
        x: split[0],
        y: split[3],
      }
      this.formController.manageSignature(newPdf, this.form.idOrder, this.form, pages, coordinates, title, environment.signGenericId).then((res: any) => {
        this.urlFrame = res.signers[0].signature_link + '&disable_domain_validation=true';
        setTimeout(() => {
          this.showFrame = true;
          this.sendingPdf = false;
          this.generatingPdf = false;

        }, 2000);
        clearInterval(interval);
      }, error => {
        this.generatingPdf = false;
        this.sendingPdf = false;
        this.ordine.statopraticaCodice = "A";
        clearInterval(interval);
        this.closeSignPdf();
        jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
        jQuery("#errorModal").modal("toggle");
      });
    } else {
      const newPdf = btoa(pdf.output());
      const coordinates = this.printService.returnCoordinates();
      this.formController
        .sendSignature(
          newPdf,
          this.idOrder,
          this.form,
          pages,
          coordinates,
          title,
          true
        )
        .then(
          (res) => {
            this.member = res.members[0].id;
            this.urlFrame =
              environment.apiUrl +
              '/procedure/sign?members=' +
              this.member +
              '&signatureUi=' +
              environment.signDepuratore;
            this.showFrame = true;
            this.sendingPdf = false;
            this.generatingPdf = false;

            clearInterval(interval);
            // this.router.navigate(['order-list']);
          },
          (error) => {
            this.generatingPdf = false;
            this.sendingPdf = false;
            this.ordine.statopraticaCodice = 'A';
            clearInterval(interval);
            jQuery('#errorModal').modal({ backdrop: 'static', keyboard: false });
            jQuery('#errorModal').modal('toggle');
          }
        );
    }


  }

  startTimer(): any {
    const intervalId = setInterval(() => {
      if (this.timeLeft === 0) {
        clearInterval(intervalId);
        // richiamo api per invio email
        this.orderService.sendEmail(this.form.idOrder).subscribe();
        jQuery('#timeoutModal').modal({ backdrop: 'static', keyboard: false });
        jQuery('#timeoutModal').modal('toggle');

        setTimeout(() => {
          jQuery('#timeoutModal').modal('hide');
        }, 10000);

        setTimeout(() => {
          this.formController.goToDashboard();
        }, 11000);
      }
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }
    }, 1000);
    return intervalId;
  }

  returnIdOrdine(): string {
    return localStorage.getItem("id_order");
  }

  public closeSignPdf(): void {
    jQuery('#signModal').modal({ backdrop: 'static', keyboard: false });
    jQuery('#signModal').modal('toggle');
    this.reload.emit(this.openSign);
    this.setStateToActive();
    this.formController.removeFrame();
  }

  private setStateToActive() {
    this.orderService.getSingleOrder(this.idOrder).subscribe((res) => {
      this.ordine = res;
      this.ordine.statopraticaCodice = "A";
      this.orderService.update(JSON.stringify(this.ordine)).subscribe(res => {
        this.getOrder(this.idOrder);
      });
    })
  }
}
