import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PaintableComponent } from 'paintablejs/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { NuoviDatiTecniciService} from '../services/nuovi-dati-tecnici/nuovi-dati-tecnici.service';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { NuoviDatiDTO } from '../classes/dati-tecnici';
import * as _ from 'lodash';
import * as $ from 'jquery';
@Component({
  selector: 'app-form-piantina-attuale',
  templateUrl: './form-piantina-attuale.component.html',
  styleUrls: ['./form-piantina-attuale.component.scss']
})
export class FormPiantinaAttualeComponent implements OnInit {

  @Input() form: any;
  @ViewChild(PaintableComponent)
  paintable: PaintableComponent;
  @Input() prodotto: any;
  active = false;
  useEraser = false;
  thickness = 2;
  color = '#000000';
  savedImage: any;
  drawing = true;
  manualUpload: any;
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  isLoadingImage: boolean = true;
  width: number;
  height: number;
  classBg: string = 'blueBg';
  classContent: string = 'blueCont';

  constructor(
      private sanitizer: DomSanitizer,
      private nuoviDatiTecService:NuoviDatiTecniciService,
      private formController: FormControllerService
  ) { }

  ngOnInit(): void {
    if(this.prodotto === 'bagno-pratico'){
      this.classBg = 'bagnoPraticoBg';
      this.classContent = 'bagnoPraticoCont';
    }
		this.callImage();
    	this.height = 300;
    	this.width = document.getElementById('partOne').offsetWidth - 40;
  }
  clear(): void {
    this.paintable?.clear();
    this.isImageSaved = false;
    delete this.cardImageBase64;
  }
  undo(): void {
    this.paintable?.undo();
  }

  redo(): void {
    this.paintable?.redo();
  }

  toggleEdit(): void {
    this.useEraser = false;
    this.active = !this.active;
    if (this.active) {
	    $('body').addClass('noScr');
	    document.body.requestFullscreen();
	    $('html,body').animate({
		    scrollTop: $('#partOne').offset().top - 80
	    },'slow');
    } else {
	    $('body').removeClass('noScr');
	    document.exitFullscreen();
	    $('html,body').animate({
		    scrollTop: $('#partOne').offset().top - 80
	    },'slow');
    }
  }

  toggleUseEraser(): void {
    this.useEraser = !this.useEraser;
  }

  get image(): string {
		return this.cardImageBase64 || undefined;
  }

  onSave(image: string): void {
  
	delete this.cardImageBase64;	
	this.form.sections.nuoviDati.piantinaAttuale = image;
    var arr = this.form.sections.nuoviDati.piantinaAttuale.split(";");
    this.form.sections.nuoviDati.piantinaAttualeType = 'image/png';
    var byte = arr[1].split(",");
    this.form.sections.nuoviDati.piantinaAttuale = byte[1];
	  var dto:NuoviDatiDTO = this.formController.mappingFormToNuoviDatiTecDTO(this.form.sections.nuoviDati , new NuoviDatiDTO(),this.form.idOrder );
	  dto.piantina = this.form.sections.nuoviDati.piantinaAttuale;
	  dto.piantinaContentType = this.form.sections.nuoviDati.piantinaAttualeType;
    if(dto.id === undefined){
		  this.nuoviDatiTecService.save(JSON.stringify(dto)).subscribe( res => {
      this.form.sections.nuoviDati.id=res.id;
      this.form.sections.nuoviDati.piantinaAttualeType =  res.piantinaContentType;
      this.form.sections.nuoviDati.piantinaAttuale = res.piantina;
      this.cardImageBase64 ="";
      this.cardImageBase64 = "data:"+res.piantinaContentType+";base64,"+res.piantina;
      this.isLoadingImage = false;
      this.active = false;
      this.isImageSaved = true;
		  });	
	  }else{
		  this.nuoviDatiTecService.update(JSON.stringify(dto)).subscribe(res => {
			  this.callImage();
		});
	}

	  this.isImageSaved = true;
  }

  onLongPress(): void {
    console.log('longpress');
  }

  fileChangeEvent(fileInput: any): any {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 5242880;
      const allowed_types = [
        'image/jpg',
        'image/jpeg',
        'image/png',
      ];
      const max_height = 15200;
      const max_width = 25600;
      
      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
            'Maximum size allowed is ' + max_size / 1000000 + 'Mb';
        return false;
      }
      
      if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
        this.imageError = 'Sono consentite solo immagini ( JPG | PNG )';
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          
          console.log('img size =>', img_height, img_width);
          
          
          if (img_height > max_height && img_width > max_width) {
            this.imageError =
                'Maximum dimentions allowed ' +
                max_height +
                '*' +
                max_width +
                'px';
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            this.formController.compressImage(this.cardImageBase64, this.width, this.height).then(compressed => {
              this.cardImageBase64 = compressed;
	            this.form.sections.nuoviDati.piantinaAttuale = this.cardImageBase64;
	            var arr = this.form.sections.nuoviDati.piantinaAttuale.split(";");
	            this.form.sections.nuoviDati.piantinaAttualeType = 'image/png';
	            var byte = arr[1].split(",");
	            this.form.sections.nuoviDati.piantinaAttuale = byte[1];
            }, err => console.log('this.err => ', err));
            
            // this.previewImagePath = imgBase64Path;
          }
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
      this.active = false;
    }
  }
  
  removeImage(): void {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
  }

  callImage(){
	this.nuoviDatiTecService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {	
		if(res[0] !== undefined){
			if(res[0].piantina !== null && res[0].piantina != undefined){
				this.form.sections.nuoviDati.piantinaAttualeType =  res[0].piantinaContentType;
				this.form.sections.nuoviDati.piantinaAttuale = res[0].piantina;
				this.cardImageBase64 = "data:"+res[0].piantinaContentType+";base64,"+res[0].piantina;
				this.isLoadingImage = false;
				this.active = false;
				this.isImageSaved = true;
			}else{
				this.isLoadingImage = false;
			}
		}else{
				this.isLoadingImage = false;
			}
		})
	}
}
