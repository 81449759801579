import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from '../../../../classes/trascodifica';
import { OpereIdraulicheService } from '../services/opereIdrauliche/opereIdrauliche.service';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { constants } from 'src/app/modules/classes/constants';
@Component({
  selector: 'app-form-piatto-stone',
  templateUrl: './form-piatto-stone.component.html',
  styleUrls: ['./form-piatto-stone.component.scss']
})
export class FormPiattoStoneComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];

  listColore: Trascodifica[];
  listMisure: Trascodifica [];
  constructor(
      private opIdraulicheService: OpereIdraulicheService,
      private formController: FormControllerService
  ) { }

  ngOnInit(): void {

    this.opIdraulicheService.findByKey(this.form.idOrder , 'ordineId.equals=','opereidrauliches').subscribe(res => {
      this.listColore = this.formController.getTrascodificaByCodice( constants.COD_COLORE_PIATTI_EFF_PIE_STONE_3D ,this.trascodifiche);
      this.form.sections.piattoStone.colorepiatstoneId = res[0] !== undefined ? res[0].colorepiatstoneId : null;
      if(this.form.sections.piattoStone.colorepiatstoneId !== undefined){
        this.changeModel(this.form.sections.piattoStone.colorepiatstoneId);
        this.form.sections.piattoStone.dimpiattstoneId = res[0] !== undefined ? res[0].dimpiattstoneId : null;
      }
    });
  }

  changeModel(event): void {
    this.listMisure =  this.trascodifiche.filter(tr => (tr.codice === constants.COD_DIM_PIATTI_PIETR_STONE_3D) && (tr.parentId === event));
  }
}
