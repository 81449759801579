import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class DisegnoTecnicoService extends HttpService{

  constructor(
      public router: Router,
      public http: HttpClient
  ) {
    super(router, http);
  }

  findByKey(data , key): Observable<any> {
	if(key === null || key === undefined){
	    return this.http
           .get<any>(this.apiUrl + 'disegnotecnicos', this.setHttpOptions())
           .pipe(
               retry(0),
               catchError(this.handleError)
           );
	}else{
	    return this.http
           .get<any>(this.apiUrl + 'disegnotecnicos?'+ key + data, this.setHttpOptions())
           .pipe(
               retry(0),
               catchError(this.handleError)
           );
	}

  }

	save(disegno: any): Observable<any> {			
		return this.http.post<any>(this.apiUrl + 'disegnotecnicos', disegno, this.setHttpOptions()).pipe(
			retry(0),
			catchError(err => this.handleError(err))
		);
	}
	
	update(disegno: any): Observable<any> {			
		return this.http.put<any>(this.apiUrl + 'disegnotecnicos', disegno, this.setHttpOptions()).pipe(
			retry(0),
			catchError(err => this.handleError(err))
		);
	}
}
