import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-cristal-box-main',
  templateUrl: './cristalbox-main.component.html',
  styleUrls: ['./cristalbox-main.component.scss']
})
export class CristalBoxMainComponent  implements OnInit {

  constructor(private router: Router,) { }

  ngOnInit(): void {
  }

  goToProduct(value): void {
    this.router.navigate(['new-order/CRISTALBOX/' + value]);
  }

}
