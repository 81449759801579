import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from '../../../../classes/trascodifica';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { constants } from 'src/app/modules/classes/constants';
@Component({
  selector: 'app-form-piatto-doccia-stretto',
  templateUrl: './form-piatto-doccia-stretto.component.html',
  styleUrls: ['./form-piatto-doccia-stretto.component.scss']
})
export class FormPiattoDocciaStrettoComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche:Trascodifica[];

  listMisure : Trascodifica [];
  
  constructor(
    private formController: FormControllerService
  ) { }

  ngOnInit(): void {
	  this.listMisure = this.formController.getTrascodificaByCodice(constants.DIM_PIATTO_STRETTO , this.trascodifiche);
  }
}
