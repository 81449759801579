<div class="pt-5 text-center mb-4">
</div>

<div class="with-border">
    <div class="padding15">
		<mat-form-field class="" appearance="standard"> <mat-label>Cerca
		ordine</mat-label> <input matInput (keyup)="applyFilter($event)" #input> </mat-form-field>
	</div>
    <div class="tableCont">
        <table class="customTable" mat-table [dataSource]="dataSource" matSort matSortActive="dataordine" matSortDirection="desc">

            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
            <ng-container matColumnDef="idOrdine">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Id Ordine</th>
                <td mat-cell *matCellDef="let element">
                    {{element.idordine}}</td>
            </ng-container>

            <ng-container matColumnDef="brandDescrizione">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Brand </th>
                <td mat-cell *matCellDef="let element"> {{element.brandDescrizione}} </td>
            </ng-container>
            <!-- <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element"> {{nameUser}} </td>
            </ng-container> -->

            <!-- Name Column -->
            <ng-container matColumnDef="dataordine">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Data </th>
                <td mat-cell *matCellDef="let element"> {{element.dataordine  | date: 'dd/MM/yyyy'}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="statopraticaDescrizione">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Stato pratica </th>
                <td mat-cell *matCellDef="let element"><!--  {{element.StatoPratica}} --> {{element.statopraticaDescrizione}}</td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="nomeProdotto" sticky>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Prodotto </th>
                <td mat-cell *matCellDef="let element"><!--  {{element.ProdottoCode}} -->
                    {{element.nomeProdotto}}
                </td>
            </ng-container>

            <ng-container matColumnDef="clienteCf">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nominativo Cliente </th>
                <td mat-cell *matCellDef="let element">
                    {{element.cognome}}
                </td>
            </ng-container>

            <ng-container matColumnDef="stato">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Stato </th>
                <td mat-cell *matCellDef="let element">
                <span style="font-size:30px;vertical-align: middle"
                      [ngClass]="{'text-info':element.statopraticaDescrizione === 'ATTIVA', 'text-success':element.statopraticaDescrizione === 'FIRMATA', 'text-warning':element.statopraticaDescrizione === 'in_approvazione', 'text-danger':element.statopraticaDescrizione === 'respinto'}"
                >&bull;{{element.statopraticaDescrizione}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions" >
                <th mat-header-cell *matHeaderCellDef class="lastCl lastClBis actionCss"> </th>
                <td mat-cell *matCellDef="let element" class="lastCl lastClBis actionCss">
                    <!--<button color="primary" class="btnMedium mr-2"  mat-flat-button (click)="uploadDocument(element.id,element.gdlid)" [disabled]="element.statopraticaCodice !== 'F'">
                        <span class="material-icons">open_in_new</span> Upload file
                    </button>-->
                    <button color="primary" class="btnMedium mr-1"  mat-flat-button (click)="formController.openPdf(element.idordine,element.idfile)" [disabled]="disabledDownload(element.statopraticaCodice)">
                        <span class="material-icons">preview</span> Anteprima
                    </button>
                    <button color="primary" class="btnMedium mr-2"  mat-flat-button (click)="downloadPdf(element.idordine,element.idfile)" [disabled]="disabledDownload(element.statopraticaCodice)">
                        <span class="material-icons">cloud_download</span> Download
                    </button>
                    
					<button class="btnMedium greenBtn" mat-flat-button
							(click)="createNewOrder()">
						<span class="material-icons">note_add</span> Nuovo prodotto
					</button>                    
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>



    <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
</div>
