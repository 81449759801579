import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  username: string;
  password: string;
  email:string;
  passwordConfirm: string;
  fiscalcode: string;
  hide: boolean;
  loading: boolean;
  color: string;
  barValue: number;
  constructor(
      private router: Router,
      private snackBar: MatSnackBar,
      private authService: AuthenticationService,
  ) {
    this.hide = true;
    this.loading = false;
  }

  ngOnInit(): void {
  }
  registrationAction(): void {
    this.loading = true;
    const body = {
      login: this.username,
      email: this.email,
      password: this.password
    };
    this.authService.registrationForm(body).subscribe(response => {
      this.loading = false;
      console.log('login response => ', response);
      // tslint:disable-next-line:max-line-length
      this.snackBar.open('Registrazione avvenuta con successo, controlla la tua casella di posta per proseguire.', 'X', {
        duration: 20000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      // this.router.navigate(['/login']);
    }, error => {	
      this.loading = false;
				this.snackBar.open(error, 'ok', {
		    		duration: 5000,
		    		horizontalPosition: 'center',
		    		verticalPosition: 'top',
		    		panelClass: 'snackerror'
      			});	

    });
  }
  validEmail(email): boolean{
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  measureStrength(p: string): number {
    if (!p) {
      p = 'a';
    }
    let force = 0;
    const regex = /[$-/:-?{-~!"^_`[\]]/g; // "
    const lowerLetters = /[a-z]+/.test(p);
    const upperLetters = /[A-Z]+/.test(p);
    const numbers = /[0-9]+/.test(p);
    const symbols = regex.test(p);
    const flags = [lowerLetters, upperLetters, numbers, symbols];
    const passedMatches = flags.filter((isMatchedFlag: boolean) => {
      return isMatchedFlag === true;
    }).length;

    force += 2 * p.length + (p.length >= 10 ? 1 : 0);
    force += passedMatches * 10;

    // penalty (short password)
    force = p.length <= 6 ? Math.min(force, 10) : force;

    // penalty (poor variety of characters)
    force = passedMatches === 1 ? Math.min(force, 10) : force;
    force = passedMatches === 2 ? Math.min(force, 20) : force;
    force = passedMatches === 3 ? Math.min(force, 40) : force;

    if (force < 20) {
      this.barValue = 33;
      this.color = 'accent';
    } else if (force >= 20 && force < 40) {
      this.barValue = 60;
      this.color = 'warn';
    } else {
      this.barValue = 100;
      this.color = 'primary';
    }

    return force;
  }

  upperLetters(p: string) {
    if(!p) {
      p='a';
    }
    return /^(?=.*[A-Z].*[A-Z]).{2,}$/.test(p);
  }

  lowerLetters(p: string) {
    if(!p) {
      p='a';
    }
    return /^(?=.*[a-z].*[a-z]).{2,}$/.test(p);
  }

  numbers(p: string) {
    if(!p) {
      p='a';
    }
    return /^(?=.*[0-9].*[0-9]).{2,}/.test(p);
  }

  symbols(p: string) {
    if(!p) {
      p='a';
    }
    return /[$-/:-?{-~!"^_`[\]]/g.test(p);
  }

  length(p:string) {
    if(!p) {
      p='a';
    }
    return p.length>=8;
  }
}
