import { OptionePoltronaDto } from "./options_poltrone";

export class PoltroneDto {
    id: number;
    ordineId?: number;
    coloreId: number = null;
    linea?: string = null;
    opzionePoltronas?: OptionePoltronaDto[];
    trascodificaId: number;
    
    constructor(init?: Partial<PoltroneDto>) {
        Object.assign(this, init);
    }
}
