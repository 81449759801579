<div class="container" *ngIf="isloaded">
    <div class="accordion">
        <div class="accordion-item">
            <div class="accordion-header" (click)="toggle('collapseAnagrafica')" [ngStyle]="{'background': productColor}">
                <div class="header-content">
                    <strong>DATI ANAGRAFICI</strong>
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="accordion-body" [@detailExpand]="expandedElement === 'collapseAnagrafica' ? 'expanded' : 'collapsed'" >
                <div class="body-content" [ngStyle]="{'border-color': checkAnagrafica().color}">
                    <div class="row">
                          <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Nome</mat-label>
                                <input matInput placeholder="Nome" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.nome" [readonly]="anagraficaReadOnlyField" required>
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finAnagrafica?.nome">Nome richiesto</mat-hint>
                            </mat-form-field>
                          </div>

                          <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Cognome</mat-label>
                                <input matInput placeholder="Cognome" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.cognome" [readonly]="anagraficaReadOnlyField"  required>
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finAnagrafica?.cognome">Cognome richiesto</mat-hint>
                            </mat-form-field> 
                          </div>

                          <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>{{ form.sections.finanziamentoSections.finAnagrafica.codicefiscale?.length == 16 ? 'Codice Fiscale  ' : 'Partita IVA  ' }}</mat-label>
                                <input matInput placeholder="{{ selectedCFoIVA === 'cf' ? 'Codice Fiscale' : 'Partita IVA' }}" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.codicefiscale" [readonly]="anagraficaReadOnlyField" required>
                                <mat-hint class="text-danger" *ngIf="!isValid()">{{ getValidationMessage() }}</mat-hint>
                                <mat-hint align="end" *ngIf="form.sections.finanziamentoSections.finAnagrafica.codicefiscale"> {{selectedCFoIVA === 'cf' ? form.sections.finanziamentoSections.finAnagrafica.codicefiscale?.length + ' / 16' : form.sections.finanziamentoSections.finAnagrafica.codicefiscale?.length + ' / 11' }}</mat-hint>
                            </mat-form-field>
                          </div>

                          <div class="col-md-6 col-12">
                            <div class="form-group">
                                <label for="sesso">Sesso: *</label>
                                <mat-radio-group id="sesso" class="radio-group" appearance="standard" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.sesso" >
                                    <mat-radio-button value="M">M</mat-radio-button>
                                    <mat-radio-button value="F">F</mat-radio-button>
                                </mat-radio-group>
                            </div>
                          </div>

                          <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Data di Nascita</mat-label>
                                <input (click)="pickerNascita.open()" matInput
                                    [matDatepicker]="pickerNascita" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.datanascita" required>
                                <mat-datepicker-toggle matSuffix [for]="pickerNascita"></mat-datepicker-toggle> 
                                <mat-datepicker #pickerNascita></mat-datepicker>
                            </mat-form-field>
                          </div>

                          <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Comune di Nascita</mat-label>
                                <input matInput placeholder="Comune di Nascita" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.comunenascita" required>
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finAnagrafica?.comunenascita">Comune di Nascita richiesto</mat-hint>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group" *ngIf="listStatoCivile && listStatoCivile.length > 0"> 
                                <label for="statocivile" class="stato-civile-label">Stato civile: *</label>
                                <mat-radio-group id="statocivile" class="radio-group flex-wrap-group" appearance="standard" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.statocivileId">
                                    <mat-radio-button *ngFor="let el of listStatoCivile" [value]="el.id">{{el.descrizione}}</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <br>
                        <div class="col-12">
                            <div class="warning-message" *ngIf="!checkAnagrafica().value">
                                Attenzione, compila tutti i campi obbligatori
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        
         <div class="accordion-item">
            <div class="accordion-header" (click)="stepController('collapseDatiResidenza', false) && toggle('collapseDatiResidenza')" [ngStyle]="{'background': productColor}">
                <div class="header-content">
                    <strong>DATI RESIDENZA</strong>
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="accordion-body" [@detailExpand]="expandedElement === 'collapseDatiResidenza'? 'expanded' : 'collapsed'">
                <div class="body-content" [ngStyle]="{'border-color': checkDatiResidenza().color}">
                    <div class="row">
                        <div class="col-12">
                            <div class="alert alert-danger d-flex align-items-center" role="alert">
                                <svg xmlns="http://www.w3.org/2000/svg" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:" 
                                  width="24px" height="24px">
                                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                </svg>
                                <div>
                                    &nbsp;&nbsp;In caso di mancata corrispondenza dei dati di residenza con quelli riportati sul documento d’identità allegato, richiedere certificato di residenza (utenza recente, ecc.)
                                </div>
                              </div>
                            <label class="label-font">Indirizzo Residenza:</label>
                        </div>
 
                        <div class="col-md-4 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Via</mat-label>
                                <input matInput placeholder="Via" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.via" required>
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finAnagrafica?.via">Via richiesta</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Civico</mat-label>
                                <input matInput placeholder="Civico" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.civico" required>
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finAnagrafica?.civico">Civico richiesto</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Comune</mat-label>
                                <input matInput placeholder="Comune" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.comune" required>
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finAnagrafica?.comune">Comune richiesto</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
          
                    <div class="row">
                        <div class="col-md-6 col-12 no-padding">
                            <div class="row">
                                <div class="col-md-6 col-12 no-padding">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>Cap</mat-label>
                                        <input matInput placeholder="Cap" type="number" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.cap" required>
                                        <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finAnagrafica?.cap">Cap richiesto</mat-hint>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-12 no-padding">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>Provincia</mat-label>
                                        <input type="text" required placeholder="Provincia" aria-label="Provincia" matInput
                                            [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.provincia" [matAutocomplete]="auto"
                                            (ngModelChange)="filter(form.sections.finanziamentoSections.finAnagrafica.provincia)"
                                            (click)="filtered = []">
                                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                            <mat-option *ngFor="let provincia of filtered" [value]="provincia.sigla">
                                                {{provincia.nome}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finAnagrafica.provincia">Provincia
                                            richiesta</mat-hint>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-12">
                        <div class="form-group" *ngIf="tipoAbitazione && tipoAbitazione.length > 0"> 
                            <label for="statocivile" class="stato-civile-label">Tipo abitazione: *</label>
                            <mat-radio-group id="statocivile" class="radio-group flex-wrap-group" appearance="standard" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.tipoabitazioneId">
                                <mat-radio-button *ngFor="let el of tipoAbitazione" [value]="el.id">{{el.descrizione}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>

                    <br>
                    <div class="row">
                        <div class="col-12">
                            <label class="label-font">Indirizzo Precedente (se Residenza variata negli ultimi 3 anni):</label>
                        </div>
                        <div class="col-md-4 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Via</mat-label>
                                    <input matInput placeholder="Via" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.viaprec">
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 col-12">
                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label>Civico</mat-label>
                                    <input matInput placeholder="Civico" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.civicoprec">
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 col-12">
                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label>Comune</mat-label>
                                    <input matInput placeholder="Comune" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.comuneprec">
                                </mat-form-field>
                            </div>
                        </div>
              
                        <div class="row">
                            <div class="col-md-6 col-12 no-padding">
                                <div class="row">
                                    <div class="col-md-6 col-12 no-padding">
                                        <mat-form-field class="full-width" appearance="standard">
                                            <mat-label>Cap</mat-label>
                                            <input matInput placeholder="Cap" type="number" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.capprec">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6 col-12 no-padding">
                                        <mat-form-field class="full-width" appearance="standard">
                                            <mat-label>Provincia</mat-label>
                                            <input type="text" required placeholder="Provincia" aria-label="Provincia" matInput
                                                [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.provinciaprec" [matAutocomplete]="auto"
                                                (ngModelChange)="filter(form.sections.finanziamentoSections.finAnagrafica.provinciaprec)"
                                                (click)="filtered = []">
                                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                <mat-option *ngFor="let provincia of filtered" [value]="provincia.sigla">
                                                    {{provincia.nome}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <br>
                        <div class="row">
                            <div class="col-12">
                                <label class="label-font">Indirizzo Domicilio (se diverso da Residenza):</label>
                            </div>
                            <div class="col-md-4 col-12">
                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label>Via</mat-label>
                                        <input matInput placeholder="Via" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.viadomic">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4 col-12">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>Civico</mat-label>
                                        <input matInput placeholder="Civico" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.civicodomic">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4 col-12">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>Comune</mat-label>
                                        <input matInput placeholder="Comune" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.comunedomic">
                                    </mat-form-field>
                                </div>
                            </div>
                  
                            <div class="row">
                                <div class="col-md-6 col-12 no-padding">
                                    <div class="row">
                                        <div class="col-md-6 col-12 no-padding">
                                            <mat-form-field class="full-width" appearance="standard">
                                                <mat-label>Cap</mat-label>
                                                <input matInput placeholder="Cap" type="number" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.capdomic">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6 col-12 no-padding">
                                            <mat-form-field class="full-width" appearance="standard">
                                                <mat-label>Provincia</mat-label>
                                                <input type="text" required placeholder="Provincia" aria-label="Provincia" matInput
                                                    [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.provinciadomic" [matAutocomplete]="auto"
                                                    (ngModelChange)="filter(form.sections.finanziamentoSections.finAnagrafica.provinciadomic)"
                                                    (click)="filtered = []">
                                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                    <mat-option *ngFor="let provincia of filtered" [value]="provincia.sigla">
                                                        {{provincia.nome}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="warning-message" *ngIf="!checkDatiResidenza().value">
                                    Attenzione, compila tutti i campi obbligatori
                                </div>
                            </div>
                    </div>
             </div>
         </div>
        
        <div class="accordion-item">
            <div class="accordion-header" (click)="stepController('collapseRecapiti', false) && toggle('collapseRecapiti')" [ngStyle]="{'background': productColor}">
                <div class="header-content">
                    <strong>RECAPITI</strong>
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="accordion-body" [@detailExpand]="expandedElement === 'collapseRecapiti' ? 'expanded' : 'collapsed'">
                <div class="body-content" [ngStyle]="{'border-color': checkRecapiti().color}">
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Telefono 1</mat-label>
                                <input matInput placeholder="Telefono 1" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.tel" required>
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finAnagrafica?.tel">Telefono 1 richiesto</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Telefono 2</mat-label>
                                <input matInput placeholder="Telefono 2" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.telnd">
                            </mat-form-field> 
                        </div>
                        <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Indirizzo e-mail</mat-label>
                                <input matInput placeholder="Indirizzo e-mail" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.email" required>
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finAnagrafica?.email">E-mail richiesta</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Altro recapito</mat-label>
                                <input matInput placeholder="Altro recapito" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.altrorecapito">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="warning-message" *ngIf="!checkRecapiti().value">
                        Attenzione, compila tutti i campi obbligatori
                    </div>
                </div>
            </div>
        </div>

        <div class="accordion-item">  
            <div class="accordion-header" (click)="stepController('collapseDatiCittadinanza', false) && toggle('collapseDatiCittadinanza')" [ngStyle]="{'background': productColor}">
                <div class="header-content">
                    <strong>DATI CITTADINANZA</strong>
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="accordion-body" [@detailExpand]="expandedElement === 'collapseDatiCittadinanza' ? 'expanded' : 'collapsed'">
                <div class="body-content" [ngStyle]="{'border-color': checkDatiCittadinanza().color}">
                    <div class="col-12">
                        <div class="alert alert-danger d-flex align-items-center" role="alert">
                            <svg xmlns="http://www.w3.org/2000/svg" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:" 
                              width="24px" height="24px">
                              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                            </svg>
                            <div>
                                &nbsp;&nbsp;In caso di cittadinanza non italiana sarà obbligatorio caricare il permesso di soggiorno tra gli allegati del contratto
                            </div>
                          </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group" *ngIf="cittadinanzaList && cittadinanzaList.length > 0"> 
                                <label for="statocivile" class="stato-civile-label">Cittadinanza: </label>
                                <mat-radio-group id="statocivile" class="radio-group flex-wrap-group" appearance="standard" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.tipologiacittadId" (ngModelChange)="resetCittadinanza()">
                                    <mat-radio-button *ngFor="let el of cittadinanzaList" [value]="el.id">{{el.descrizione}}</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="checkCittadinanzaAltra()">
                        <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Cittadinanza</mat-label>
                                <input matInput placeholder="Cittadinanza" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.altracitt">
                                <mat-hint class="text-danger" *ngIf="returnCittadinanzaSelected() == constants.DESC_FIN_CITTADINANZA_ALTRA && !form.sections.finanziamentoSections.finAnagrafica.altracitt">Cittadinanza richiesta</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-10 col-12" *ngIf="checkCittadinanzaAltra()">
                        <div class="extra-ue-box">
                            <label>Cittadinanza EXTRA UE:</label>
                            <mat-radio-group class="radio-group" appearance="standard" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.tipologiaextraue">
                                <mat-radio-button [value]="false">NO</mat-radio-button>
                                <mat-radio-button [value]="true">SI</mat-radio-button>
                            </mat-radio-group>
                            
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <mat-form-field class="full-width" appearance="standard">
                                            <mat-label>Soggiorno in Italia dal: (mese/anno)</mat-label>
                                            <input (click)="pickerInizSogg.open()" matInput
                                                [matDatepicker]="pickerInizSogg" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.soggionoinizio" [required]="areSoggiornoFieldsRequired()">
                                            <mat-datepicker-toggle matSuffix [for]="pickerInizSogg"></mat-datepicker-toggle> 
                                            <mat-datepicker #pickerInizSogg></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                            
                                    <div class="col-md-6 col-12">
                                        <mat-form-field class="full-width" appearance="standard">
                                            <mat-label>Data scadenza permesso di soggiorno</mat-label>
                                                <input (click)="pickerScadSogg.open()" matInput
                                                    [matDatepicker]="pickerScadSogg" [min]="form.sections.finanziamentoSections.finAnagrafica.soggionoinizio" [(ngModel)]="form.sections.finanziamentoSections.finAnagrafica.soggionoscadenza" [required]="areSoggiornoFieldsRequired()">
                                            <mat-datepicker-toggle matSuffix [for]="pickerScadSogg"></mat-datepicker-toggle> 
                                            <mat-datepicker #pickerScadSogg></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br><br>
                        <div class="row">
                            <div class="col-12">
                                <div class="warning-message" *ngIf="!checkDatiCittadinanza().value">
                                    Attenzione, compila tutti i campi obbligatori
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <div class="accordion-item">
            <div class="accordion-header" (click)="stepController('collapseDatiOccupazione', false) && toggle('collapseDatiOccupazione')" [ngStyle]="{'background': productColor}">
                <div class="header-content">
                    <strong>DATI OCCUPAZIONE</strong>
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="accordion-body" [@detailExpand]="expandedElement === 'collapseDatiOccupazione' ? 'expanded' : 'collapsed'">
                <div class="body-content" [ngStyle]="{'border-color': checkDatiOccupazione().color}">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group" *ngIf="attivitaLavList && attivitaLavList.length > 0"> 
                                <label for="statocivile" class="stato-civile-label">Attività lavorativa*: </label>
                                <mat-radio-group id="statocivile" class="radio-group flex-wrap-group" appearance="standard" [(ngModel)]="form.sections.finanziamentoSections.finDatiOccupazione.attivitalavId" (ngModelChange)="attivitaLavChange()" required>
                                    <mat-radio-button *ngFor="let el of attivitaLavList" [value]="el.id">{{el.descrizione}}</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-10 col-12" *ngIf="checkAttivitaLavorativa()">
                        <div class="extra-ue-box">                            
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <mat-form-field class="full-width" appearance="standard">
                                            <mat-label>Data inizio occupazione: </mat-label>
                                            <input (click)="pickerDataOccupazione.open()" matInput
                                                [matDatepicker]="pickerDataOccupazione" [(ngModel)]="form.sections.finanziamentoSections.finDatiOccupazione.datainizioocc" required>
                                            <mat-datepicker-toggle matSuffix [for]="pickerDataOccupazione"></mat-datepicker-toggle> 
                                            <mat-datepicker #pickerDataOccupazione></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group" *ngIf="tipoAssunzioneList && tipoAssunzioneList.length > 0"> 
                                            <label for="tipoAssunzione" class="stato-civile-label">Tipo assunzione*: </label>
                                            <mat-radio-group id="tipoAssunzione" class="radio-group flex-wrap-group" appearance="standard" [(ngModel)]="form.sections.finanziamentoSections.finDatiOccupazione.tipoAssunzioneId" required>
                                                <mat-radio-button *ngFor="let el of tipoAssunzioneList" [value]="el.id">{{el.descrizione}}</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br><br>
                        <div class="row">
                            <div class="col-12">
                                <div class="warning-message" *ngIf="!checkDatiOccupazione().value">
                                    Attenzione, compila tutti i campi obbligatori
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>

        <div class="accordion-item" *ngIf="checkVisibilitySectionDatoreDiLavoro()">
            <div class="accordion-header" (click)="stepController('collapseDatiDatore', false) && toggle('collapseDatiDatore')" [ngStyle]="{'background': productColor}">
                <div class="header-content">
                    <strong>DATI DATORE DI LAVORO</strong>
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="accordion-body" [@detailExpand]="expandedElement === 'collapseDatiDatore' ? 'expanded' : 'collapsed'">
                <div class="body-content" [ngStyle]="{'border-color': checkDatiDatore().color}"> 
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Ragione Sociale*:</mat-label>
                                <input matInput placeholder="Ragione Sociale" type="text" [(ngModel)]="form.sections.finanziamentoSections.finDatiOccupazione.ragionesocialedatore">
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finDatiOccupazione?.ragionesocialedatore">Ragione sociale richiesta</mat-hint>
                            </mat-form-field>
                        </div>
                        <br>
                        <div class="col-12">
                            <br>
                            <label class="label-font">Indirizzo:</label>
                        </div>
                        <div class="col-md-4 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Via</mat-label>
                                <input matInput placeholder="Via"  type="text" [(ngModel)]="form.sections.finanziamentoSections.finDatiOccupazione.via" required>
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finDatiOccupazione?.via">Via richiesta</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Civico</mat-label>
                                <input matInput placeholder="Civico" [(ngModel)]="form.sections.finanziamentoSections.finDatiOccupazione.civico" required>
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finDatiOccupazione?.civico">Civico richiesto</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Comune</mat-label>
                                <input matInput placeholder="Comune" type="text" [(ngModel)]="form.sections.finanziamentoSections.finDatiOccupazione.comune" required>
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finDatiOccupazione?.comune">Comune richiesto</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 col-12 no-padding">
                            <div class="row">
                                <div class="col-md-6 col-12 no-padding">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>Cap</mat-label>
                                        <input matInput placeholder="Cap" type="number" [(ngModel)]="form.sections.finanziamentoSections.finDatiOccupazione.cap" required>
                                        <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finDatiOccupazione?.cap">Cap richiesto</mat-hint>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-12 no-padding">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>Provincia</mat-label>
                                        <input type="text" required placeholder="Provincia" aria-label="Provincia" matInput
                                            [(ngModel)]="form.sections.finanziamentoSections.finDatiOccupazione.provincia" [matAutocomplete]="auto"
                                            (ngModelChange)="filter(form.sections.finanziamentoSections.finDatiOccupazione?.provincia)"
                                            (click)="filtered = []">
                                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                            <mat-option *ngFor="let provincia of filtered" [value]="provincia.sigla">
                                                {{provincia.nome}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finDatiOccupazione?.provincia">Provincia
                                            richiesta</mat-hint>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 col-12 no-padding">
                            <div class="row">
                                <div class="col-md-6 col-12 no-padding">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>Telefono</mat-label>
                                        <input matInput placeholder="Telefono" type="number" [(ngModel)]="form.sections.finanziamentoSections.finDatiOccupazione.telefono">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br><br>
                    <div class="row">
                        <div class="col-12">
                            <div class="warning-message" *ngIf="!checkDatiDatore().value">
                                Attenzione, compila tutti i campi obbligatori
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="accordion-item">
            <div class="accordion-header" (click)="stepController('collapseDatiReddito', false) && toggle('collapseDatiReddito')" [ngStyle]="{'background': productColor}">
                <div class="header-content">
                    <strong>DATI REDDITO</strong>
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="accordion-body" [@detailExpand]="expandedElement === 'collapseDatiReddito' ? 'expanded' : 'collapsed'">
                <div class="body-content" [ngStyle]="{'border-color': checkDatiReddito().color}"> 
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <label class="full-width">Tipo Reddito: {{redditoString}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Numero mensilità*:</mat-label>
                                <input matInput placeholder="Numero mensilità" type="number" [(ngModel)]="form.sections.finanziamentoSections.finDatiOccupazione.nummensilita">
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finDatiOccupazione?.nummensilita">Numero mensilità richiesto</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Totale importo Reddito*:</mat-label>
                                <input matInput placeholder="Totale importo Reddito"  type="number" [(ngModel)]="form.sections.finanziamentoSections.finDatiOccupazione.totalereddito">
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finDatiOccupazione?.totalereddito">Totale importo reddito richiesto</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <br><br>
                    <div class="row">
                        <div class="col-12">
                            <div class="warning-message" *ngIf="!checkDatiReddito().value">
                                Attenzione, compila tutti i campi obbligatori
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="accordion-item">
            <div class="accordion-header" (click)="stepController('collapseDatiPagamento', false) && toggle('collapseDatiPagamento')" [ngStyle]="{'background': productColor}">
                <div class="header-content">
                    <strong>DATI PAGAMENTO</strong>
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="accordion-body" [@detailExpand]="expandedElement === 'collapseDatiPagamento' ? 'expanded' : 'collapsed'">
                    <div class="body-content" [ngStyle]="{'border-color': checkDatiPagamento().color}">
                        <div class="row">
                            <div class="col-12">
                                <div class="alert alert-danger d-flex align-items-center" role="alert">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:" 
                                        width="24px" height="24px">
                                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                    </svg>
                                    <div>
                                        &nbsp;&nbsp;Non sono ammessi pagamenti con carte Poste Pay o carte ricaribabilli
                                    </div>
                                </div>
                              <label class="label-font">Addebito su Conto Corrente:</label>
                            </div>
                        </div>
                    <div class="row">
                        <div class="col-md-6 col-12 no-padding">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>IBAN*:</mat-label>
                                <input matInput placeholder="Iban" type="text" [(ngModel)]="form.sections.finanziamentoSections.finanziamento.iban" (ngModelChange)="verificaIban()">
                                <!--<mat-hint class="text-danger" *ngIf="(!checkBollettino() && !form.sections.finanziamentoSections.finanziamento.iban)">Iban richiesto</mat-hint>-->
                                <br>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 col-12 no-padding" *ngIf="checkBollettino()">
                            <div class="form-group">
                                <label for="garante" class="d-block label-font">&nbsp; Bollettino:</label><br>
                                <mat-radio-group id="garante" class="radio-group" [(ngModel)]="form.sections.finanziamentoSections.finanziamento.bollettino">
                                    <mat-radio-button class="mr-3" [value]="false">No</mat-radio-button>
                                    <mat-radio-button [value]="true">Sì</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="!checkBollettino()">
                        <div class="col-12">
                            <mat-icon *ngIf="ibanVerificato">check_circle</mat-icon>
                            <mat-icon *ngIf="!ibanVerificato">error</mat-icon>
                            <span *ngIf="ibanVerificato" style="color: green;">IBAN verificato con successo!</span>
                            <span *ngIf="!ibanVerificato" style="color: red;">IBAN non valido.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="accordion-item">
            <div class="accordion-header" (click)="stepController('collapseProspetto', false) && toggle('collapseProspetto'); getCodiceTabella()" [ngStyle]="{'background': productColor}">
                <div class="header-content">
                    <strong>PROSPETTO CONTABILE</strong>
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="accordion-body" [@detailExpand]="expandedElement === 'collapseProspetto' ? 'expanded' : 'collapsed'">
                <div class="body-content" [ngStyle]="{'border-color': checkProspetto().color}">

                    <div class="row">
                        <div class="col-md-3 col-12">
                            <label class="full-width">
                                <span class="label-font">PREZZO TOTALE:</span> {{form.sections.finanziamentoSections.finanziamento?.prezzotot}} €
                            </label>
                        </div>
                        <div class="col-md-3 col-12">
                            <label class="full-width">
                                <span class="label-font">ACCONTO:</span> {{form.sections.finanziamentoSections.finanziamento?.acconto}} €
                            </label>
                        </div>
                        <div class="col-md-3 col-12">
                            <label class="full-width">
                                <span class="label-font">RESIDUO DA FINANZIARE:</span> {{form.sections.finanziamentoSections.finanziamento?.residuo}} €
                            </label>
                        </div>
                    </div>
                    <br>

                    <!-- richiesta prospetto guidato momentaneo manuale da cancellare parte sottostante-->
                    <div class="row">
                        <div class="col-md-4 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Codice Tabella</mat-label>
                                <input matInput placeholder="Codice Tabella" type="text" [(ngModel)]="form.sections.finanziamentoSections.finanziamento.codicetabella">
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finanziamento.codicetabella">Codice tabella richiesto</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Importo</mat-label>
                                <input matInput placeholder="Importo" type="number" min="0" step=".01" [(ngModel)]="form.sections.finanziamentoSections.finanziamento.importoFinanziaria">
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finanziamento.importoFinanziaria">Importo richiesto</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Numero rate</mat-label>
                                <input matInput placeholder="Numero rate" type="number" min="1" step="1" [(ngModel)]="form.sections.finanziamentoSections.finanziamento.numrate">
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finanziamento.numrate">Numero rate richiesto</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Importo rata</mat-label>
                                <input matInput placeholder="Importo rata" type="number" min="0" step=".01" [(ngModel)]="form.sections.finanziamentoSections.finanziamento.importorata">
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finanziamento.importorata">Importo rata richiesto</mat-hint>
                            </mat-form-field>
                        </div>
                        <!-- GDLO-326 - commento -->
                         
                        <!--<div class="col-md-4 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Tan</mat-label>
                                <input matInput placeholder="Tan" type="number" min="0" step=".01" [(ngModel)]="form.sections.finanziamentoSections.finanziamento.tan">
                                <mat-hint class="text-danger" *ngIf="form.sections.finanziamentoSections.finanziamento.tan == null">Tan richiesto</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Taeg</mat-label>
                                <input matInput placeholder="Taeg" type="number" min="0" step=".01" [(ngModel)]="form.sections.finanziamentoSections.finanziamento.taeg">
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finanziamento.taeg">Taeg richiesto</mat-hint>
                            </mat-form-field>
                        </div>-->
                    </div>

                    <!-- richiesta prospetto guidato momentaneo manuale da scommentare parte sottostante-->
                    <!--<div class="row">
                        <div class="col-md-4 col-12">
                            <mat-form-field class="w-100 formField selectCustom" appearance="outline">
                                <mat-label>Codice Tabella</mat-label>
                                <mat-select [(ngModel)]="form.sections.finanziamentoSections.finanziamento.codicetabella" (ngModelChange)="getImporti($event, false)" placeholder="Codice Tabella">
                                  <mat-option *ngFor="let code of codiciTabellaList" [value]="code">
                                    {{code}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                        </div>

                        <div class="col-md-4 col-12">
                            <mat-form-field class="w-100 formField selectCustom" appearance="outline" *ngIf="form.sections.finanziamentoSections.finanziamento.codicetabella" 
                                (click)="searchImportoProspetto = ''">
                                <mat-label>Importo</mat-label> 
                                <mat-select [(ngModel)]="form.sections.finanziamentoSections.finanziamento.importoFinanziaria" (ngModelChange)="getNumeroRate($event, false)" placeholder="Importo">
                                  <div class="searchInSelect">
                                    <input type="text" [(ngModel)]="searchImportoProspetto" placeholder="cerca"/>
                                  </div>
                                  <mat-option *ngFor="let importo of valoriImportoList" [value]="importo" [hidden]="includeImporto(importo)">
                                    {{importo + " €"}}
                                  </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-md-4 col-12"> 
                            <mat-form-field class="w-100 formField selectCustom" appearance="outline"  
                                  *ngIf="form.sections.finanziamentoSections.finanziamento.codicetabella && form.sections.finanziamentoSections.finanziamento.importoFinanziaria">
                                <mat-label>Numero rate</mat-label>
                                <mat-select [(ngModel)]="form.sections.finanziamentoSections.finanziamento.numrate" (ngModelChange)="getLatestDataProspetto($event)" placeholder="Numero rate">
                                  <mat-option *ngFor="let rata of numeroRateList" [value]="rata">
                                    {{rata}}
                                  </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" *ngIf="form.sections.finanziamentoSections.finanziamento.codicetabella && form.sections.finanziamentoSections.finanziamento.importoFinanziaria && form.sections.finanziamentoSections.finanziamento.numrate">
                        <div class="col-md-4 col-12">
                            <label>Importo rata</label>
                            <span>{{ form.sections.finanziamentoSections.finanziamento.importorata | currency:'EUR':'symbol' }}</span>
                        </div>
                            
                        <div class="col-md-4 col-12">
                            <label>TAN</label>
                            <span>{{ form.sections.finanziamentoSections.finanziamento.tan }} %</span>
                        </div>
                                
                        <div class="col-md-4 col-12">
                            <label>TAEG</label>
                            <span>{{ form.sections.finanziamentoSections.finanziamento.taeg }} %</span>
                        </div>
                    </div>-->
                    
                    <br><br>
                    <div class="row">
                        <div class="col-12">
                            <div class="warning-message" *ngIf="!checkProspetto().value">
                                Attenzione, compila tutti i campi obbligatori
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>


<div class="row justify-content-center mb-3" *ngIf="isloaded">
    <div class="form-group text-center">
        <label for="garante" class="d-block label-font">Vuoi inserire un garante:</label>
        <mat-radio-group id="garante" class="radio-group" [(ngModel)]="isGarante" (ngModelChange)="changeValueGarante($event)">
            <mat-radio-button class="mr-3" [value]="false">No</mat-radio-button>
            <mat-radio-button [value]="true">Sì</mat-radio-button>
        </mat-radio-group>
    </div>
</div>


<div class="container" *ngIf="isloaded && form.sections.finanziamentoSections.finAnagraficaGarante.garante">
    <div class="accordion">
        <div class="accordion-item">
            <div class="accordion-header" (click)="toggle('collapseAnagraficaGarante')" [ngStyle]="{'background': productColor}">
                <div class="header-content">
                    <strong>DATI ANAGRAFICI GARANTE</strong>
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="accordion-body" [@detailExpand]="expandedElement === 'collapseAnagraficaGarante' ? 'expanded' : 'collapsed'" >
                <div class="body-content" [ngStyle]="{'border-color': checkAnagraficaGarante().color}">
                    <div class="row">
                          <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Nome</mat-label>
                                <input matInput placeholder="Nome" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.nome" required>
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finAnagraficaGarante?.nome">Nome richiesto</mat-hint>
                            </mat-form-field>
                          </div>

                          <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Cognome</mat-label>
                                <input matInput placeholder="Cognome" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.cognome" required>
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finAnagraficaGarante?.cognome">Cognome richiesto</mat-hint>
                            </mat-form-field> 
                          </div>

                          <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Codice Fiscale</mat-label>
                                <input matInput placeholder="Codice Fiscale" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.codicefiscale" required>
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finAnagraficaGarante.codicefiscale || form.sections.finanziamentoSections.finAnagraficaGarante.codicefiscale?.length < 16">Codice Fiscale non valido</mat-hint>
                                <mat-hint align="end" *ngIf="form.sections.finanziamentoSections.finAnagraficaGarante?.codicefiscale"> {{form.sections.finanziamentoSections.finAnagraficaGarante.codicefiscale.length + ' / 16' }}</mat-hint>
                            </mat-form-field>
                          </div>

                          <div class="col-md-6 col-12">
                            <div class="form-group">
                                <label for="sesso">Sesso: *</label>
                                <mat-radio-group id="sesso" class="radio-group" appearance="standard" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.sesso" >
                                    <mat-radio-button value="M">M</mat-radio-button>
                                    <mat-radio-button value="F">F</mat-radio-button>
                                </mat-radio-group>
                            </div>
                          </div>

                          <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Data di Nascita</mat-label>
                                <input (click)="pickerNascitaGarante.open()" matInput
                                    [matDatepicker]="pickerNascitaGarante" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.datanascita" required>
                                <mat-datepicker-toggle matSuffix [for]="pickerNascitaGarante"></mat-datepicker-toggle> 
                                <mat-datepicker #pickerNascitaGarante></mat-datepicker>
                            </mat-form-field>
                          </div>

                          <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Comune di Nascita</mat-label>
                                <input matInput placeholder="Comune di Nascita" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.comunenascita" required>
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finAnagraficaGarante?.comunenascita">Comune di Nascita richiesto</mat-hint>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group" *ngIf="listStatoCivile && listStatoCivile.length > 0"> 
                                <label for="statocivile" class="stato-civile-label">Stato civile: *</label>
                                <mat-radio-group id="statocivile" class="radio-group flex-wrap-group" appearance="standard" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.statocivileId">
                                    <mat-radio-button *ngFor="let el of listStatoCivile" [value]="el.id">{{el.descrizione}}</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <br>
                        <div class="col-12">
                            <div class="warning-message" *ngIf="!checkAnagraficaGarante().value">
                                Attenzione, compila tutti i campi obbligatori
                            </div>
                        </div>
                    </div>
                </div>
        </div>

        <div class="accordion-item">
            <div class="accordion-header" (click)="stepController('collapseDatiResidenzaGarante', true) && toggle('collapseDatiResidenzaGarante')" [ngStyle]="{'background': productColor}">
                <div class="header-content">
                    <strong>DATI RESIDENZA GARANTE</strong>
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="accordion-body" [@detailExpand]="expandedElement === 'collapseDatiResidenzaGarante'? 'expanded' : 'collapsed'">
                <div class="body-content" [ngStyle]="{'border-color': checkDatiResidenzaGarante().color}">
                    <div class="row">
                        <div class="col-12">
                            <div class="alert alert-danger d-flex align-items-center" role="alert">
                                <svg xmlns="http://www.w3.org/2000/svg" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:" 
                                  width="24px" height="24px">
                                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                </svg>
                                <div>
                                    &nbsp;&nbsp;In caso di mancata corrispondenza dei dati di residenza con quelli riportati sul documento d’identità allegato, richiedere certificato di residenza (utenza recente, ecc.)
                                </div>
                              </div>
                            <label class="label-font">Indirizzo Residenza:</label>
                        </div>
 
                        <div class="col-md-4 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Via</mat-label>
                                <input matInput placeholder="Via" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.via" required>
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finAnagraficaGarante?.via">Via richiesta</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Civico</mat-label>
                                <input matInput placeholder="Civico" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.civico" required>
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finAnagraficaGarante?.civico">Civico richiesto</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Comune</mat-label>
                                <input matInput placeholder="Comune" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.comune" required>
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finAnagraficaGarante?.comune">Comune richiesto</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
          
                    <div class="row">
                        <div class="col-md-6 col-12 no-padding">
                            <div class="row">
                                <div class="col-md-6 col-12 no-padding">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>Cap</mat-label>
                                        <input matInput placeholder="Cap" type="number" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.cap" required>
                                        <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finAnagraficaGarante?.cap">Cap richiesto</mat-hint>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-12 no-padding">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>Provincia</mat-label>
                                        <input type="text" required placeholder="Provincia" aria-label="Provincia" matInput
                                            [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.provincia" [matAutocomplete]="auto"
                                            (ngModelChange)="filter(form.sections.finanziamentoSections.finAnagraficaGarante?.provincia)"
                                            (click)="filtered = []">
                                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                            <mat-option *ngFor="let provincia of filtered" [value]="provincia.sigla">
                                                {{provincia.nome}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finAnagraficaGarante?.provincia">Provincia
                                            richiesta</mat-hint>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-12">
                        <div class="form-group" *ngIf="tipoAbitazione && tipoAbitazione.length > 0"> 
                            <label for="statocivile" class="stato-civile-label">Tipo abitazione: *</label>
                            <mat-radio-group id="statocivile" class="radio-group flex-wrap-group" appearance="standard" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.tipoabitazioneId">
                                <mat-radio-button *ngFor="let el of tipoAbitazione" [value]="el.id">{{el.descrizione}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>

                    <br>
                    <div class="row">
                        <div class="col-12">
                            <label class="label-font">Indirizzo Precedente (se Residenza variata negli ultimi 3 anni):</label>
                        </div>
                        <div class="col-md-4 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Via</mat-label>
                                    <input matInput placeholder="Via" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.viaprec">
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 col-12">
                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label>Civico</mat-label>
                                    <input matInput placeholder="Civico" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.civicoprec">
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 col-12">
                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label>Comune</mat-label>
                                    <input matInput placeholder="Comune" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.comuneprec">
                                </mat-form-field>
                            </div>
                        </div>
              
                        <div class="row">
                            <div class="col-md-6 col-12 no-padding">
                                <div class="row">
                                    <div class="col-md-6 col-12 no-padding">
                                        <mat-form-field class="full-width" appearance="standard">
                                            <mat-label>Cap</mat-label>
                                            <input matInput placeholder="Cap" type="number" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.capprec">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6 col-12 no-padding">
                                        <mat-form-field class="full-width" appearance="standard">
                                            <mat-label>Provincia</mat-label>
                                            <input type="text" required placeholder="Provincia" aria-label="Provincia" matInput
                                                [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.provinciaprec" [matAutocomplete]="auto"
                                                (ngModelChange)="filter(form.sections.finanziamentoSections.finAnagraficaGarante?.provinciaprec)"
                                                (click)="filtered = []">
                                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                <mat-option *ngFor="let provincia of filtered" [value]="provincia.sigla">
                                                    {{provincia.nome}}
                                                </mat-option>
                                            </mat-autocomplete>
                                            <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finAnagraficaGarante?.provincia">Provincia
                                                richiesta</mat-hint>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <br>
                        <div class="row">
                            <div class="col-12">
                                <label class="label-font">Indirizzo Domicilio (se diverso da Residenza):</label>
                            </div>
                            <div class="col-md-4 col-12">
                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label>Via</mat-label>
                                        <input matInput placeholder="Via" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.viadomic">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4 col-12">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>Civico</mat-label>
                                        <input matInput placeholder="Civico" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.civicodomic">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4 col-12">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>Comune</mat-label>
                                        <input matInput placeholder="Comune" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.comunedomic">
                                    </mat-form-field>
                                </div>
                            </div>
                  
                            <div class="row">
                                <div class="col-md-6 col-12 no-padding">
                                    <div class="row">
                                        <div class="col-md-6 col-12 no-padding">
                                            <mat-form-field class="full-width" appearance="standard">
                                                <mat-label>Cap</mat-label>
                                                <input matInput placeholder="Cap" type="number" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.capdomic">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6 col-12 no-padding">
                                            <mat-form-field class="full-width" appearance="standard">
                                                <mat-label>Provincia</mat-label>
                                                <input type="text" required placeholder="Provincia" aria-label="Provincia" matInput
                                                    [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.provinciadomic" [matAutocomplete]="auto"
                                                    (ngModelChange)="filter(form.sections.finanziamentoSections.finAnagraficaGarante?.provinciadomic)"
                                                    (click)="filtered = []">
                                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                    <mat-option *ngFor="let provincia of filtered" [value]="provincia.sigla">
                                                        {{provincia.nome}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="warning-message" *ngIf="!checkDatiResidenzaGarante().value">
                                    Attenzione, compila tutti i campi obbligatori
                                </div>
                            </div>
                    </div>
             </div>
         </div>
        
        <div class="accordion-item">
            <div class="accordion-header" (click)="stepController('collapseRecapitiGarante', true) && toggle('collapseRecapitiGarante')" [ngStyle]="{'background': productColor}">
                <div class="header-content">
                    <strong>RECAPITI GARANTE</strong>
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="accordion-body" [@detailExpand]="expandedElement === 'collapseRecapitiGarante' ? 'expanded' : 'collapsed'">
                <div class="body-content" [ngStyle]="{'border-color': checkRecapitiGarante().color}">
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Telefono 1</mat-label>
                                <input matInput placeholder="Telefono 1" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.tel" required>
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finAnagraficaGarante?.tel">Telefono 1 richiesto</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Telefono 2</mat-label>
                                <input matInput placeholder="Telefono 2" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.telnd">
                            </mat-form-field> 
                        </div>
                        <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Indirizzo e-mail</mat-label>
                                <input matInput placeholder="Indirizzo e-mail" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.email" required>
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finAnagraficaGarante?.email">E-mail richiesta</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Altro recapito</mat-label>
                                <input matInput placeholder="Altro recapito" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.altrorecapito">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="warning-message" *ngIf="!checkRecapitiGarante().value">
                        Attenzione, compila tutti i campi obbligatori
                    </div>
                </div>
            </div>
        </div>

        <div class="accordion-item">  
            <div class="accordion-header" (click)="stepController('collapseDatiCittadinanzaGarante', true) && toggle('collapseDatiCittadinanzaGarante')" [ngStyle]="{'background': productColor}">
                <div class="header-content">
                    <strong>DATI CITTADINANZA GARANTE</strong>
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="accordion-body" [@detailExpand]="expandedElement === 'collapseDatiCittadinanzaGarante' ? 'expanded' : 'collapsed'">
                <div class="body-content" [ngStyle]="{'border-color': checkDatiCittadinanzaGarante().color}">
                    <div class="col-12">
                        <div class="alert alert-danger d-flex align-items-center" role="alert">
                            <svg xmlns="http://www.w3.org/2000/svg" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:" 
                              width="24px" height="24px">
                              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                            </svg>
                            <div>
                                &nbsp;&nbsp;In caso di cittadinanza italiana sarà obbligatorio caricare il permesso di soggiorno tra gli allegati del contratto
                            </div>
                          </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group" *ngIf="cittadinanzaList && cittadinanzaList.length > 0"> 
                                <label for="statocivile" class="stato-civile-label">Cittadinanza: </label>
                                <mat-radio-group id="statocivile" class="radio-group flex-wrap-group" appearance="standard" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.tipologiacittadId" (ngModelChange)="resetCittadinanzaGarante()">
                                    <mat-radio-button *ngFor="let el of cittadinanzaList" [value]="el.id">{{el.descrizione}}</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="checkCittadinanzaAltraGarante()">
                        <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Cittadinanza</mat-label>
                                <input matInput placeholder="Cittadinanza" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.altracitt">
                                <mat-hint class="text-danger" *ngIf="returnCittadinanzaSelectedGarante() == constants.DESC_FIN_CITTADINANZA_ALTRA && !form.sections.finanziamentoSections.finAnagraficaGarante.altracitt">Cittadinanza richiesta</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-10 col-12" *ngIf="checkCittadinanzaAltraGarante()">
                        <div class="extra-ue-box">
                            <label>Cittadinanza EXTRA UE:</label>
                            <mat-radio-group class="radio-group" appearance="standard" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.tipologiaextraue">
                                <mat-radio-button [value]="false">NO</mat-radio-button>
                                <mat-radio-button [value]="true">SI</mat-radio-button>
                            </mat-radio-group>
                            
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <mat-form-field class="full-width" appearance="standard">
                                            <mat-label>Soggiorno in Italia dal: (mese/anno)</mat-label>
                                            <input (click)="pickerInizSoggGarante.open()" matInput
                                                [matDatepicker]="pickerInizSoggGarante" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.soggionoinizio" [required]="areSoggiornoFieldsGaranteRequired()">
                                            <mat-datepicker-toggle matSuffix [for]="pickerInizSoggGarante"></mat-datepicker-toggle> 
                                            <mat-datepicker #pickerInizSoggGarante></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                            
                                    <div class="col-md-6 col-12">
                                        <mat-form-field class="full-width" appearance="standard">
                                            <mat-label>Data scadenza permesso di soggiorno</mat-label>
                                                <input (click)="pickerScadSoggGarante.open()" matInput
                                                    [matDatepicker]="pickerScadSoggGarante" [min]="form.sections.finanziamentoSections.finAnagraficaGarante.soggionoinizio" [(ngModel)]="form.sections.finanziamentoSections.finAnagraficaGarante.soggionoscadenza" [required]="areSoggiornoFieldsGaranteRequired()">
                                            <mat-datepicker-toggle matSuffix [for]="pickerScadSoggGarante"></mat-datepicker-toggle> 
                                            <mat-datepicker #pickerScadSoggGarante></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br><br>
                        <div class="row">
                            <div class="col-12">
                                <div class="warning-message" *ngIf="!checkDatiCittadinanzaGarante().value">
                                    Attenzione, compila tutti i campi obbligatori
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <div class="accordion-item">
            <div class="accordion-header" (click)="stepController('collapseDatiOccupazioneGarante', true) && toggle('collapseDatiOccupazioneGarante')" [ngStyle]="{'background': productColor}">
                <div class="header-content">
                    <strong>DATI OCCUPAZIONE GARANTE</strong>
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="accordion-body" [@detailExpand]="expandedElement === 'collapseDatiOccupazioneGarante' ? 'expanded' : 'collapsed'">
                <div class="body-content" [ngStyle]="{'border-color': checkDatiOccupazioneGarante().color}">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group" *ngIf="attivitaLavList && attivitaLavList.length > 0"> 
                                <label for="statocivile" class="stato-civile-label">Attività lavorativa*: </label>
                                <mat-radio-group id="statocivile" class="radio-group flex-wrap-group" appearance="standard" [(ngModel)]="form.sections.finanziamentoSections.finDatiOccupazioneGarante.attivitalavId" (ngModelChange)="attivitaLavGaranteChange()" required>
                                    <mat-radio-button *ngFor="let el of attivitaLavList" [value]="el.id">{{el.descrizione}}</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-10 col-12" *ngIf="checkAttivitaLavorativaGarante()">
                        <div class="extra-ue-box">                            
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <mat-form-field class="full-width" appearance="standard">
                                            <mat-label>Data inizio occupazione: </mat-label>
                                            <input (click)="pickerDataOccupazioneGarante.open()" matInput
                                                [matDatepicker]="pickerDataOccupazioneGarante" [(ngModel)]="form.sections.finanziamentoSections.finDatiOccupazioneGarante.datainizioocc" required>
                                            <mat-datepicker-toggle matSuffix [for]="pickerDataOccupazioneGarante"></mat-datepicker-toggle> 
                                            <mat-datepicker #pickerDataOccupazioneGarante></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group" *ngIf="tipoAssunzioneList && tipoAssunzioneList.length > 0"> 
                                            <label for="tipoAssunzioneGarante" class="stato-civile-label">Tipo assunzione*: </label>
                                            <mat-radio-group id="tipoAssunzioneGarante" class="radio-group flex-wrap-group" appearance="standard" [(ngModel)]="form.sections.finanziamentoSections.finDatiOccupazioneGarante.tipoAssunzioneId" required>
                                                <mat-radio-button *ngFor="let el of tipoAssunzioneList" [value]="el.id">{{el.descrizione}}</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br><br>
                        <div class="row">
                            <div class="col-12">
                                <div class="warning-message" *ngIf="!checkDatiOccupazioneGarante().value">
                                    Attenzione, compila tutti i campi obbligatori
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>

        <div class="accordion-item" *ngIf="checkVisibilitySectionDatoreDiLavoroGarante()">
            <div class="accordion-header" (click)="stepController('collapseDatiDatoreGarante', true) && toggle('collapseDatiDatoreGarante')" [ngStyle]="{'background': productColor}">
                <div class="header-content">
                    <strong>DATI DATORE DI LAVORO GARANTE</strong>
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="accordion-body" [@detailExpand]="expandedElement === 'collapseDatiDatoreGarante' ? 'expanded' : 'collapsed'">
                <div class="body-content" [ngStyle]="{'border-color': checkDatiDatoreGarante().color}"> 
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Ragione Sociale*:</mat-label>
                                <input matInput placeholder="Ragione Sociale"  type="text" [(ngModel)]="form.sections.finanziamentoSections.finDatiOccupazioneGarante.ragionesocialedatore">
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finDatiOccupazioneGarante?.ragionesocialedatore">Ragione sociale richiesta</mat-hint>
                            </mat-form-field>
                        </div>
                        <br>
                        <div class="col-12">
                            <br>
                            <label class="label-font">Indirizzo:</label>
                        </div>
                        <div class="col-md-4 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Via</mat-label>
                                <input matInput placeholder="Via"  type="text" [(ngModel)]="form.sections.finanziamentoSections.finDatiOccupazioneGarante.via" required>
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finDatiOccupazioneGarante?.via">Via richiesta</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Civico</mat-label>
                                <input matInput placeholder="Civico" [(ngModel)]="form.sections.finanziamentoSections.finDatiOccupazioneGarante.civico" required>
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finDatiOccupazioneGarante?.civico">Civico richiesto</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Comune</mat-label>
                                <input matInput placeholder="Comune" type="text" [(ngModel)]="form.sections.finanziamentoSections.finDatiOccupazioneGarante.comune" required>
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finDatiOccupazioneGarante?.comune">Comune richiesto</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 col-12 no-padding">
                            <div class="row">
                                <div class="col-md-6 col-12 no-padding">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>Cap</mat-label>
                                        <input matInput placeholder="Cap" type="number" [(ngModel)]="form.sections.finanziamentoSections.finDatiOccupazioneGarante.cap" required>
                                        <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finDatiOccupazioneGarante?.cap">Cap richiesto</mat-hint>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-12 no-padding">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>Provincia</mat-label>
                                        <input type="text" required placeholder="Provincia" aria-label="Provincia" matInput
                                            [(ngModel)]="form.sections.finanziamentoSections.finDatiOccupazioneGarante.provincia" [matAutocomplete]="auto"
                                            (ngModelChange)="filter(form.sections.finanziamentoSections.finDatiOccupazioneGarante?.provincia)"
                                            (click)="filtered = []">
                                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                            <mat-option *ngFor="let provincia of filtered" [value]="provincia.sigla">
                                                {{provincia.nome}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finDatiOccupazioneGarante?.provincia">Provincia
                                            richiesta</mat-hint>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 col-12 no-padding">
                            <div class="row">
                                <div class="col-md-6 col-12 no-padding">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>Telefono</mat-label>
                                        <input matInput placeholder="Telefono" type="number" [(ngModel)]="form.sections.finanziamentoSections.finDatiOccupazioneGarante.telefono">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br><br>
                    <div class="row">
                        <div class="col-12">
                            <div class="warning-message" *ngIf="!checkDatiDatoreGarante().value">
                                Attenzione, compila tutti i campi obbligatori
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="accordion-item">
            <div class="accordion-header" (click)="stepController('collapseDatiRedditoGarante', true) && toggle('collapseDatiRedditoGarante')" [ngStyle]="{'background': productColor}">
                <div class="header-content">
                    <strong>DATI REDDITO GARANTE</strong>
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="accordion-body" [@detailExpand]="expandedElement === 'collapseDatiRedditoGarante' ? 'expanded' : 'collapsed'">
                <div class="body-content" [ngStyle]="{'border-color': checkDatiRedditoGarante().color}"> 
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <label class="full-width">Tipo Reddito: {{redditoGaranteString}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Numero mensilità*:</mat-label>
                                <input matInput placeholder="Numero mensilità" type="number" [(ngModel)]="form.sections.finanziamentoSections.finDatiOccupazioneGarante.nummensilita">
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finDatiOccupazioneGarante?.nummensilita">Numero mensilità richiesto</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <mat-form-field class="full-width" appearance="standard">
                                <mat-label>Totale importo Reddito*:</mat-label>
                                <input matInput placeholder="Totale importo Reddito"  type="number" [(ngModel)]="form.sections.finanziamentoSections.finDatiOccupazioneGarante.totalereddito">
                                <mat-hint class="text-danger" *ngIf="!form.sections.finanziamentoSections.finDatiOccupazioneGarante?.totalereddito">Totale importo reddito richiesto</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <br><br>
                    <div class="row">
                        <div class="col-12">
                            <div class="warning-message" *ngIf="!checkDatiRedditoGarante().value">
                                Attenzione, compila tutti i campi obbligatori
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row justify-content-center mb-3" *ngIf="isloaded">
    <div class="form-group text-center">
        <button class="btn" (click)="save()" [disabled]="!checkProspetto().value || (isGarante && !checkDatiRedditoGarante().value) || !formController.checkDatiPagamento(form, finanziamento.id, listFinanziarie, attivitaLavList)" [ngStyle]="{'background': productColor}" style="color: white;"> Salva </button>
    </div>
</div>


<!-- popup delete garante -->
<div class="modal fade" id="deleteGarante" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">Avviso rimozione garante </h3>
        </div>
        <div class="modal-body">
          <div>
            <span>Attenzione se cambi questa scelta perderai tutti i dati inseriti nelle sezioni rigurdante il garante</span><br>
            <span>Vuoi procedere ugualmente ?</span> 
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" (click)="cancelChange()">Annulla</button>
          <button class="btn" (click)="confirmCleanGarante()" [ngStyle]="{'background': productColor}" style="color: white;">Avanti</button>
        </div>
      </div>
    </div>
  </div>

  <!-- popup garanzie -->
<div class="modal fade" id="popupGaranzie" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title"> Attenzione </h3>
        </div>
        <div class="modal-body">
          <div>
              <span>Non puoi procedere avendo selezionato come occupazione <strong>{{ this.formController.getTrascodificaById(this.form.sections.finanziamentoSections.finDatiOccupazione.attivitalavId, this.attivitaLavList)?.descrizione }}</strong>,  
              </span> l' importo finanziato è superiore rispetto al consentito.<br>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn" (click)="cancelGaranzieChange()" [ngStyle]="{'background': productColor}" style="color: white;">Chiudi</button>
        </div>
      </div>
    </div>
  </div>

    <!-- popup garanzie eta-->
  <div class="modal fade" id="popupGaranzieEta" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title"> Attenzione </h3>
        </div>
        <div class="modal-body">
          <div>
              <span>Superta Età massima finanziabile</span>
              <!--</span> per procedere almeno una data non deve superare <strong>la massima età finanziabile</strong> imposta dalla finanziaria selezionata<br>-->
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn" (click)="closeModalEta()" [ngStyle]="{'background': productColor}" style="color: white;">Chiudi</button>
        </div>
      </div>
    </div>
  </div>

    <!-- popup finanziamento tan 0 -->
    <div class="modal fade" id="popupTan0" tabindex="-1">
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title"> Attenzione </h3>
            </div>
            <div class="modal-body">
              <div>
                  <span>Omaggio presente con scelta finanziamento a tasso 0.</span>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn" (click)="closeModalTan0()" [ngStyle]="{'background': productColor}" style="color: white;">Chiudi</button>
            </div>
          </div>
        </div>
    </div>