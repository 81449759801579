export class AbitazioneDTO {
	altezza: number;
	cannafumariaDescrizione:string;
	cannafumariaId: number;
	disegnotecnico: string;
	disegnotecnicoContentType: string;
	id: number;
	locale: string;
	note:string;
	mq: number;
	murospess: number;
	ordineId: number;
	pianistabile: number;
	pianointerv: number;
	tipoabitazioneDescrizione:string;
	tipoabitazioneId: number;
	volrisc: number;
	volumeclima: number;
	volumetot: number;
}
