import { Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';

@Component({
  selector: 'app-payment-input-number',
  templateUrl: './payment-input-number.component.html',
  styleUrls: ['../input-number/input-number.component.scss']
})
export class PaymentInputNumberComponent implements OnInit {

  @Input() num: any;
  @Input() step: number;
  @Input() decimal: boolean;
  @Input() label: string;
  @Input() payment: boolean;
  @Input() small: boolean;
  @Input() isValid: boolean;
  @Output() onNumChange = new EventEmitter<any>();

  isMobile: boolean = false;

  constructor(
    private formControllerService: FormControllerService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.formControllerService.checkWindowSize();
  }

  updateNumber(decimal, add): void {
    if (!this.num) {
      this.num = 0;
    }
    if (add) {
      this.num = parseFloat(this.num);
      this.num += 1;
    } else if (this.num !== 0 || this.num !== undefined) {
      this.num = parseFloat(this.num);
      if (!this.num){
        this.num = 0;
      } else {
        this.num -= 1;
      }
    } else {
      this.num = 0;
    }
    if (this.payment) {
      this.num = this.num.toFixed(2);
    }
    this.emit();    
  }


  emit(): void {
    if (this.num || this.num === 0) {
      /*let newNum = this.num.toString();
      if (newNum.includes('.')){
        newNum = newNum.replace('.', ',');
      }*/
      this.num = parseFloat(this.num);
    }
    // this.num = parseFloat(newNum);
    this.onNumChange.emit(this.num);
  }

}
