<div class="text-center {{brand === 'bagniHelp75' ? 'help75Bg' : 'blueBg'}}">
   ACCESSORI
</div>
<div class="{{brand === 'bagniHelp75' ? 'hepl75Cont' : 'blueCont'}}">

	<div class="row" *ngFor="let accessorio of form.sections.accessorio; let i = index;">
        <div class="col-6">
            <mat-form-field class="full-width">
                <mat-label>Accessorio</mat-label>
                <mat-select [(ngModel)]="accessorio.tipologiaId" (ngModelChange)="checkItem($event, i)">
                    <mat-option>-- Accessorio --</mat-option>
                    <mat-option [disabled]="alreadySelected(acc.id)" *ngFor="let acc of availableAccessories" [value]="acc.id">
                        {{acc.descrizione}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-4" *ngIf="accessorio.tipologiaId">
            <app-input-number
                    (onNumChange)="accessorio.quantita = $event"
                    [step]="1"
                    [num]="accessorio.quantita"
                    [decimal]="false"
                    [payment]="false"
                    [small]="false"
                    label="Quantità"></app-input-number>
            <div class="smallFont text-danger" *ngIf="accessorio.tipologiaId && !accessorio.quantita">
                Campo obbligatorio
            </div>

        </div>
        <div class="col-2" *ngIf="form.sections.accessorio.length > 0">
            <button mat-icon-button color="warn" (click)="removeItem(i)">
                <mat-icon>cancel</mat-icon>
            </button>
        </div>
    </div>
    <div>
        <button mat-flat-button class="{{brand === 'bagniHelp75' ? 'ochre' : 'azur'}}" (click)="addAccessorio()">Aggiungi Accessorio</button>
    </div>

</div>
