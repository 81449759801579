import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-finishforgot',
  templateUrl: './finishforgot.component.html',
  styleUrls: ['./finishforgot.component.scss']
})
export class FinishforgotComponent implements OnInit {
  key: string;
  loading: boolean;
  errorKey: boolean;
  errorSend: boolean;
  password: string;
  passwordConfirm: string;
  hide: boolean;
  hideConfirm: boolean;
  sending: boolean;
  color: string;
  barValue: number;
  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private snackBar: MatSnackBar,
      private authService: AuthenticationService,
  ) {
    this.loading = true;
    this.hide = true;
    this.hideConfirm = true;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.key = params.key;
    });
    setTimeout(() => {
      if (this.key) {
        this.loading = false;
        this.errorKey = false;
      } else {
        this.loading = false;
        this.errorKey = true;
      }
    }, 1000);
  }

  goToForgot(): void {
    this.router.navigate(['forgot']);
  }

  goToLogin(): void {
    this.router.navigate(['login']);
  }

  sendNewPassword(): void {
    this.sending = true;
    const body = {
      newPassword: this.password,
      key: this.key
    };
    this.authService.sendNewPassword(body).subscribe(response => {
      this.sending = false;
      this.snackBar.open('Password modificata', 'ok', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      this.goToLogin();
    }, error => {
      this.sending = false;
      this.errorSend = true;
      this.snackBar.open('Errore, qualcosa non ha funzionato', 'ok', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: 'snackerror'
      });
      console.error('Error => ', error);
    });
  }

  measureStrength(p: string): number {
    if (!p) {
      p = 'a';
    }
    let force = 0;
    const regex = /[$-/:-?{-~!"^_`[\]]/g; // "
    const lowerLetters = /[a-z]+/.test(p);
    const upperLetters = /[A-Z]+/.test(p);
    const numbers = /[0-9]+/.test(p);
    const symbols = regex.test(p);
    const flags = [lowerLetters, upperLetters, numbers, symbols];
    const passedMatches = flags.filter((isMatchedFlag: boolean) => {
      return isMatchedFlag === true;
    }).length;

    force += 2 * p.length + (p.length >= 10 ? 1 : 0);
    force += passedMatches * 10;

    // penalty (short password)
    force = p.length <= 6 ? Math.min(force, 10) : force;

    // penalty (poor variety of characters)
    force = passedMatches === 1 ? Math.min(force, 10) : force;
    force = passedMatches === 2 ? Math.min(force, 20) : force;
    force = passedMatches === 3 ? Math.min(force, 40) : force;

    if (force < 20) {
      this.barValue = 33;
      this.color = 'accent';
    } else if (force >= 20 && force < 40) {
      this.barValue = 60;
      this.color = 'warn';
    } else {
      this.barValue = 100;
      this.color = 'primary';
    }

    return force;
  }

}
