<div class="pt-5 text-center mb-4">
</div>

<app-upload-area *ngIf="ordine && ordine.statopraticaCodice !== 'I' && ordine.statopraticaCodice !== 'D'" (callbackUpload)="uploadFromMulti()" [idordine]="ordine.idordine" [modello]="allegato" [id]="id" [gdl_id]="gdl_id" [listTypeDoc]="listTypeDoc" [listAllegati]="allegati"></app-upload-area>

<!-- tabella con riepilogo documenti -->
<div class="row">
	<div class="with-border col-12">
		<div class="text-center mb-2 mt-2">
			<mat-label>Lista documenti caricati</mat-label>
		</div>

        <table class="customTable" mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="desc">

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
                <td mat-cell *matCellDef="let element">
                    {{element.originalName !== null && element.originalName !== undefined ? element.originalName : element.name}}</td>
            </ng-container>

            <ng-container matColumnDef="tipoDescrizione">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipologia </th>
                <td mat-cell *matCellDef="let element"> {{element.tipoDescrizione}} </td>
            </ng-container>

            <ng-container matColumnDef="actions" >
                <th mat-header-cell *matHeaderCellDef class="lastCl lastClBis"> </th>
                <td mat-cell *matCellDef="let element" class="lastCl lastClBis">
                    <button color="primary" class="btnMedium mr-2"  mat-flat-button (click)="downloadDocument(element.name , element.originalName, element.id)">
                        <span class="material-icons">cloud_download</span> Download
                    </button>
                    <button color="warn" class="btnMedium mr-2"  mat-flat-button (click)="deleteDocument(element.id)" *ngIf="element.tipoDescrizione !== constants.TYPE_DOC_CERTIFICATO_FIRMA && element.tipoDescrizione !== constants.TYPE_DOC_SCANSIONE_CONTRATTO 
                                && element.tipoDescrizione !== constants.TYPE_DOC_FINANZIAMENTO_STEP && element.tipoDescrizione !== constants.TYPE_DOC_CERTIFICATO_FIRMA_ATT_SERV && element.tipoDescrizione !== constants.TYPE_MODULO_ATTIVAZIONE_SERVIZI">
                        <span class="material-icons">delete</span> Elimina
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

    	<mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
	</div>
</div>

<div class="row mt-4" *ngIf="ordine && ordine.statopraticaCodice !== 'I' && ordine.statopraticaCodice !== 'D'">
	<div class="col-12 text-center">
		<button class="col-6" mat-flat-button color="primary" *ngIf="checkDocument()" (click)="sendDocumentToGdl();"> <mat-icon class="mr-2">cloud_upload</mat-icon>Invia documenti a Gdl</button>
		<h2 class="text-danger" *ngIf="!checkDocument()">Allegati mancanti:<br>
			<ul class="no-bullets">
				<li *ngIf="!this.isDocCli">Documenti cliente</li>
				<li *ngIf="!this.isFotoAmb">Foto ambiente</li>
				<div *ngIf="this.checkFin">
					<li *ngIf="!this.isDocFin">Documenti finanziamento</li>
				</div>
		</ul></h2> 
	</div>
</div>

<div class="modal fade" id="confirmModalCanc" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable">
    	<input type="hidden" id="idAllegato">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">Attenzione</h3>
                <button type="button" class="close" (click)="confirmDelete(false)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    Si sta cancellando un allegato, si &egrave; certi di continuare?
                </div>
            </div>
            <div class="modal-footer">
                <button mat-button (click)="confirmDelete(false)">Annulla</button>
                <button mat-button (click)="confirmDelete(true)">Conferma</button>
            </div>
        </div>
    </div>
</div>
