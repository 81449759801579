<div class="text-center greenBg">
    NUOVO RIVESTIMENTO GRES
</div>
<div class="greenCont mb-4"> 
	<div class="row mb-4">
		<div class="col-6">
			<div class="mb-2" *ngIf="brand === 'bagniHelp75'"><mat-label>INTERNO DOCCIA</mat-label></div> 
			<div class="mb-2" *ngIf="brand != 'bagniHelp75'"><mat-label>ALTEZZA PARETE</mat-label></div> 
	        <div class="d-inline-block">       	
	           <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.gres.altezzapareteId">
	           	<mat-radio-button class="mb-2 mr-2" [value]="altezza.id"  *ngFor="let altezza of listAltezzaParete">{{altezza.descrizione}}</mat-radio-button>
	               <span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.gres.altezzapareteId" (click)="form.sections.gres.altezzapareteId = null">
	                   backspace
	               </span>
	           </mat-radio-group>
	       </div>
	       <div class="verticalHr"></div>
		</div>
		<div class="col-6">
			<div class="mb-2"><mat-label>PAVIMENTO</mat-label></div>
	        <div class="d-inline-block mb-4">       	
	           <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.gres.pavimentoId">
	           	<mat-radio-button class="mb-2 mr-2" [value]="pavimento.id"  *ngFor="let pavimento of listPavimento">{{pavimento.descrizione}}</mat-radio-button>
	               <span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.gres.pavimentoId" (click)="form.sections.gres.pavimentoId = null">
	                   backspace
	               </span>
	           </mat-radio-group>
	       </div>	       
		</div>
	</div>
	<div class="row">
		<div class="col-6">
	        <mat-form-field class="full-width">
	            <mat-label>TIPO GRES:</mat-label>
	            <input matInput type="text" [(ngModel)]="form.sections.gres.tipo">
	        </mat-form-field>	    
			<div class="verticalHr"></div>  
		</div>

		<div class="col-6">
	        <mat-form-field class="full-width">
	            <mat-label>TIPO GRES:</mat-label>
	            <input matInput type="text" [(ngModel)]="form.sections.gres.tipopavimento">
	        </mat-form-field>		
		</div>
	</div>
	
	<div class="row" *ngIf="brand === 'bagniHelp75'">
		<div class="col-6">
	        <mat-form-field class="full-width">
	            <mat-label>FORMATO PIASTRELLE:</mat-label>
	            <input matInput type="text" [(ngModel)]="form.sections.gres.formatopiastrelle">
	        </mat-form-field>	     
			<div class="verticalHr"></div>  
		</div>

		<div class="col-6">
	        <mat-form-field class="full-width">
	            <mat-label>FORMATO PIASTRELLE:</mat-label>
	            <input matInput type="text" [(ngModel)]="form.sections.gres.formatopareti">
	        </mat-form-field>		
		</div>
	</div>
	
	<div class="row">
		<div class="col-6">	    
      		<div class="smallFont text-center text-danger">
				Il Gres viene posato in 
				sovrapposizione*
			</div>  
			<div class="verticalHr"></div>  
		</div>	
		<div class="col-6">
     		<div class="smallFont text-center text-danger" *ngIf="brand !== 'bagniHelp75'">
				Solo in caso di riduzione 
				piatto doccia*
			</div>
     		<div class="smallFont text-center text-danger" *ngIf="brand === 'bagniHelp75'">
				Il Gres viene posato in sovrapposizione. 
				Non verranno eseguite le sostituzioni di tubazioni sottopavimento
			</div>			
		</div>		
	</div>
</div>