import { Component, OnInit,Input } from '@angular/core';
import { OmaggioService } from '../../../../bagni-italiani/main-bagni/services/omaggio/omaggio.service';
import { Trascodifica } from '../../../../../classes/trascodifica';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';
import { OmaggioDto } from '../../../../../classes/omaggio-dto';
@Component({
  selector: 'cristalbox-omaggio',
  templateUrl: './omaggio.component.html',
  styleUrls: ['./omaggio.component.scss']
})
export class CristalBoxOmaggioComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  @Input() brand:string;
  availableOmaggio: Trascodifica[];
  constructor( 
    private formController: FormControllerService,
    private omaggioService : OmaggioService
  ) { }

  ngOnInit(): void {
  		this.form.sections.omaggio = [];
		this.omaggioService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
			for (var i = 0; i < res.length; i++){
				const omaggio = res[i];
				this.form.sections.omaggio.push(omaggio);
			}
			
			this.availableOmaggio = this.formController.getTrascodificaByCodice('OMAGGIO_TIPOLOGIA' , this.trascodifiche);
		})
		
   }

  alreadySelected(value): boolean {
    const found = this.form.sections.omaggio.findIndex(r => r.id === value);
    return found !== - 1;
  }

  addOmaggio(): void {
    this.form.sections.omaggio.push(new OmaggioDto());
  }

  removeItem(i): void {
	if(this.form.sections.omaggio[i].id !== undefined){
		this.omaggioService.delete(this.form.sections.omaggio[i].id).subscribe();
	}
    this.form.sections.omaggio.splice(i, 1);
  }

  checkItem(event, i): void {
    if (event == null) {
      this.form.sections.omaggio[i] = new OmaggioDto();
    }
  }
}
