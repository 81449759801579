import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from '../../../../classes/trascodifica';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { OpereIdraulicheService } from '../services/opereIdrauliche/opereIdrauliche.service';
import { constants } from 'src/app/modules/classes/constants';
@Component({
  selector: 'app-form-piatto-doccia-largo',
  templateUrl: './form-piatto-doccia-largo.component.html',
  styleUrls: ['./form-piatto-doccia-largo.component.scss']
})
export class FormPiattoDocciaLargoComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: any;
  @Input() listPiattoDocciaDim : Trascodifica[];

  misurePdocciaLargo: any[];
  filtered: any[];
  listPiattoDocciaColor : Trascodifica [];
  
  constructor(
    private opIdraulicheService: OpereIdraulicheService,
    private formController : FormControllerService
  ) {
    
    this.misurePdocciaLargo = [
      {
        Colore: 'BIANCO',
        Misura: '105x70 (ris. 8/15/15 cm)*',
        Codice: 'DIS.105X70.BIA'
      },
      {
        Colore: 'BIANCO',
        Misura: '105x80 (ris. 8/12/15 cm)*',
        Codice: 'DIS.105X80.BIA'
      },
      {
        Colore: 'BIANCO',
        Misura: '120x70 (ris. 8/12/12 cm)*',
        Codice: 'DIS.120X70.BIA'
      },
      {
        Colore: 'BIANCO',
        Misura: '120x80 (ris. 8/15/15 cm)*',
        Codice: 'DIS.120X80.BIA'
      },
      {
        Colore: 'BIANCO',
        Misura: '120x90 (ris. 8/15/15 cm)*',
        Codice: 'DIS.120X90.BIA'
      },
      {
        Colore: 'BIANCO',
        Misura: '140x70 (ris. 8/15/15 cm)*',
        Codice: 'DIS.140X70.BIA'
      },
      {
        Colore: 'BIANCO',
        Misura: '140x80 (ris. 8/15/15 cm)*',
        Codice: 'DIS.140X80.BIA'
      },
      {
        Colore: 'BIANCO',
        Misura: '140x90 (ris. 8/15/25 cm)*',
        Codice: 'DIS.140X90.BIA'
      },
      {
        Colore: 'BIANCO',
        Misura: '150x70 (ris. 8/15/15 cm)*',
        Codice: 'DIS.150X70.BIA'
      },
      {
        Colore: 'BIANCO',
        Misura: '160x70 (ris. 8/12/12 cm)*',
        Codice: 'DIS.160X70.BIA'
      },
      {
        Colore: 'BIANCO',
        Misura: '160x80 (ris. 8/15/15 cm)*',
        Codice: 'DIS.160X80.BIA'
      },
      {
        Colore: 'BIANCO',
        Misura: '170x70 (ris. 8/15/15 cm)*',
        Codice: 'DIS.170X70.BIA'
      },
      {
        Colore: 'BIANCO',
        Misura: '170x80 (ris. 8/18/18 cm)*',
        Codice: 'DIS.170X80.BIA'
      },
      {
        Colore: 'BIANCO',
        Misura: '180x70 (ris. 8/15/15 cm)*',
        Codice: 'DIS.180X70.BIA'
      },
      {
        Colore: 'BIANCO',
        Misura: '180x80 (ris. 8/22/22 cm)*',
        Codice: 'DIS.180X80.BIA'
      },
      {
        Colore: 'BIANCO',
        Misura: '190x70 (ris. 8/15/15 cm)*',
        Codice: 'DIS.190X70.BIA'
      },
      {
        Colore: 'BIANCO',
        Misura: '190x90 (ris. 8/25/25 cm)*',
        Codice: 'DIS.190X90.BIA'
      },
      {
        Colore: 'BIANCO',
        Misura: '200x100 (ris. 8/30/30 cm)*',
        Codice: 'DIS.200X100.BIA'
      },
      {
        Colore: 'NERO',
        Misura: '105x70 (ris. 8/15/15 cm)*',
        Codice: 'DIS.105X70.NER'
      },
      {
        Colore: 'NERO',
        Misura: '105x80 (ris. 8/12/15 cm)*',
        Codice: 'DIS.105X80.NER'
      },
      {
        Colore: 'NERO',
        Misura: '120x70 (ris. 8/12/12 cm)*',
        Codice: 'DIS.120X70.NER'
      },
      {
        Colore: 'NERO',
        Misura: '120x80 (ris. 8/15/15 cm)*',
        Codice: 'DIS.120X80.NER'
      },
      {
        Colore: 'NERO',
        Misura: '120x90 (ris. 8/15/15 cm)*',
        Codice: 'DIS.120X90.NER'
      },
      {
        Colore: 'NERO',
        Misura: '140x70 (ris. 8/15/15 cm)*',
        Codice: 'DIS.140X70.NER'
      },
      {
        Colore: 'NERO',
        Misura: '140x80 (ris. 8/15/15 cm)*',
        Codice: 'DIS.140X80.NER'
      },
      {
        Colore: 'NERO',
        Misura: '140x90 (ris. 8/15/25 cm)*',
        Codice: 'DIS.140X90.NER'
      },
      {
        Colore: 'NERO',
        Misura: '150x70 (ris. 8/15/15 cm)*',
        Codice: 'DIS.150X70.NER'
      },
      {
        Colore: 'NERO',
        Misura: '160x70 (ris. 8/12/12 cm)*',
        Codice: 'DIS.160X70.NER'
      },
      {
        Colore: 'NERO',
        Misura: '160x80 (ris. 8/15/15 cm)*',
        Codice: 'DIS.160X80.NER'
      },
      {
        Colore: 'NERO',
        Misura: '170x70 (ris. 8/15/15 cm)*',
        Codice: 'DIS.170X70.NER'
      },
      {
        Colore: 'NERO',
        Misura: '170x80 (ris. 8/18/18 cm)*',
        Codice: 'DIS.170X80.NER'
      },
      {
        Colore: 'NERO',
        Misura: '180x70 (ris. 8/15/15 cm)*',
        Codice: 'DIS.180X70.NER'
      },
      {
        Colore: 'NERO',
        Misura: '180x80 (ris. 8/22/22 cm)*',
        Codice: 'DIS.180X80.NER'
      },
      {
        Colore: 'NERO',
        Misura: '190x70 (ris. 8/15/15 cm)*',
        Codice: 'DIS.190X70.NER'
      },
      {
        Colore: 'NERO',
        Misura: '190x90 (ris. 8/25/25 cm)*',
        Codice: 'DIS.190X90.NER'
      }
    ];
    this.filtered = [...this.misurePdocciaLargo];
  }

  ngOnInit(): void {
		this.opIdraulicheService.findByKey(this.form.idOrder , 'ordineId.equals=', 'opereidrauliches').subscribe(res => {		
				this.form.sections.piattoDoccia = this.formController.mappingPiattoLargoDTOtoForm(res[0] , this.form.sections.piattoDoccia);
				this.listPiattoDocciaColor = this.formController.getTrascodificaByCodice(constants.COLORE_PIATTO_LARGO,this.trascodifiche);
				if(this.form.sections.piattoDoccia.largo.coloreId !== undefined){
					this.changeModel(this.form.sections.piattoDoccia.largo.coloreId);
				}							
		})
  }

  changeModel(event): void {
	  this.listPiattoDocciaDim = this.trascodifiche.filter(tr =>(tr.codice === constants.DIM_PIATTO_LARGO) && (tr.parentId === event));
  }
}
