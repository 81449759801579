import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpService } from '../http.service';
import { Router } from '@angular/router';
import { HttpClient,HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AllegatoService extends HttpService{
	
    constructor(
      public router: Router,
      public http: HttpClient
  ) {
    super(router, http);
  }

   save(allegato: any): Observable<any> {			
		return this.http.post<any>(this.apiUrl + 'allegatoes', allegato, this.setHttpOptions()).pipe(
			retry(0),
			catchError(err => this.handleError(err))
		);
	}

	update(allegato: any): Observable<any> {			
		return this.http.put<any>(this.apiUrl + 'allegatoes', allegato, this.setHttpOptions()).pipe(
			retry(0),
			catchError(err => this.handleError(err))
		);
	}
	
	getAllegati(data): Observable<any> {
		return this.http.get<any>(this.apiUrl + 'allegatoes?path.notEquals=null&size=1000&ordineId.equals=' + data, this.setHttpOptions())
		.pipe(
		   retry(0),
		   catchError(this.handleError)
		);
	}
	
	delete(id: any): Observable<any> {		
		return this.http.delete<any>(this.apiUrl+'allegatoes/'+id, this.setHttpOptions())
			.pipe(
				retry(0),
				catchError(this.handleError)
			);
	}
	
  	downloadFile(name ?: any , id? :number): Observable<Blob> {   
    	return this.http.get(this.apiUrl + 'download/' + name+'/'+id, {  headers: new HttpHeaders({'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('or_access_token'),}), responseType: 'blob' });
  	}
}
