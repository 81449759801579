import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { read } from 'fs';
import { CaldaiaDto } from 'src/app/modules/classes/caldaie/caldaia-dto';
import { constants } from 'src/app/modules/classes/constants';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { BrandService } from 'src/app/services/brand/brand.service';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { CaldaiaService } from '../../caldaia/services/caldaia.service';

@Component({
  selector: 'app-tipologia',
  templateUrl: './tipologia.component.html',
  styleUrls: ['./tipologia.component.scss']
})
export class TipologiaComponent implements OnInit {
  caldaiaResponse: any;
  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  @Output('validform') validform: EventEmitter<boolean> = new EventEmitter<boolean>();
  caldaie: Map<string, CaldaiaDto[]>;
  prodottiSistema: Map<string, Trascodifica>;
  prodottiSistemaIbird: Trascodifica;
  modAurum: Trascodifica[];
  potenzaAurum: Trascodifica[];
  type = [constants.TYPE_TIPOPRODOTTOSISTEMA_AURUM, constants.TYPE_TIPOPRODOTTOSISTEMA_IBRID, constants.TYPE_TIPOPRODOTTOSISTEMA_SLIM_AURUM, constants.TYPE_TIPOPRODOTTOSISTEMA_P_FULL, constants.TYPE_TIPOPRODOTTOSISTEMA_POWER]
  checkAURUM = true;
  checkSlim = true;
  checkIbrid = true;
  checkFull = true;
  checkPower = true;
  public constants = constants;
  constructor(
    private caldaiaService: CaldaiaService,
    private brandService: BrandService,
    private formController: FormControllerService,
  ) { }

  ngOnInit(): void {
    this.caldaie = new Map<string, CaldaiaDto[]>();
    this.prodottiSistema = new Map<string, Trascodifica>();
    this.getCaldaie();
  }


  getCaldaie(): void {
    this.caldaiaService.findByKey(this.form.idOrder, 'ordineId.equals=').subscribe(res => {
      this.form.sections.caldaie = res;
      this.type.forEach((el: any) => { this.getProdAurum(el).then(res => { this.caldaie.set(el, res) }) })
    });
  }

  getProdAurum(type: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.formController.getTrascodificaByCodicPromise(type, this.trascodifiche).then(data => {
        this.prodottiSistema.set(type, data[0]);
        this.modAurum = this.trascodifiche.filter(
          tr => (tr.codice === constants.TYPE_MODSISTPOMCALORE) && (tr.parentId === this.prodottiSistema.get(type).id));

        let prodAurum = [];
        this.modAurum.forEach((e: any) => {
          let index = this.form.sections.caldaie.findIndex(r => r.modibrepomcalId === e.id && r.tipologiaId === this.prodottiSistema.get(type).id);
          let caldaia: any = {};
          if (index !== -1) {
            caldaia = this.form.sections.caldaie[index];
            this.form.sections.caldaie[index].checked = true;
            caldaia.checked = true;
          } else {
            caldaia.tipologiaId = this.prodottiSistema.get(type).id;
            caldaia.modibrepomcalId = e.id;
            caldaia.modibrepomcalDescrizione = e.descrizione;
          }
          caldaia.note = e.note;
          this.potenzaAurum = this.trascodifiche.filter(
            tr => (tr.codice === constants.TYPE_POTSISTPOMCALORE) && (tr.parentId === e.id));

          caldaia.listPotenza = this.potenzaAurum;
          prodAurum.push(caldaia);
        });
        resolve(prodAurum);
      });
    });

  }

  checked(id, tipologiaId): string {
    if (-1 !== this.form.sections.caldaie.findIndex(r => r.modibrepomcalId === id && r.tipologiaId === tipologiaId)) {
      return 'checked';
    } else {
      return '';
    }
  }

  update(event, prod, tipologiaId, tipoDesc): void {
    if (!event.checked) {
      this.checkValid(tipoDesc);
    }

    //controllare se è già checcato un altro elemento della stessa tipologia.
    let indTip = this.form.sections.caldaie.findIndex(r => r.tipologiaId === tipologiaId)
    if (indTip !== -1) {
      //devo uncheccare l'elemento    
      this.caldaie.get(tipoDesc).find(r => r.modibrepomcalId === this.form.sections.caldaie[indTip].modibrepomcalId && r.tipologiaId === tipologiaId).checked = false;
      this.manageDelete(this.form.sections.caldaie[indTip].modibrepomcalId, tipologiaId, false);
    }
    if (-1 === this.form.sections.caldaie.findIndex(r => r.modibrepomcalId === prod.modibrepomcalId && r.tipologiaId === tipologiaId)) {
      if (event.checked) {
        var newVal = new CaldaiaDto();
        newVal.tipologiaId = tipologiaId;
        newVal.modibrepomcalId = prod.modibrepomcalId;
        newVal.ordineId = this.form.idOrder;
        newVal.checked = true;
        this.form.sections.caldaie.push(newVal);
      }
    } else {
      this.manageDelete(prod.modibrepomcalId, tipologiaId, true);
    }
  }

  manageDelete(modibrepomcalId, tipologiaId, reload) {
    var fotoToDelete = this.form.sections.caldaie.filter(tr => (tr.modibrepomcalId === modibrepomcalId && tr.tipologiaId === tipologiaId));
    this.form.sections.caldaieToDelete.push(fotoToDelete[0]);
    this.form.sections.caldaie.splice(this.form.sections.caldaie.findIndex(r => r.modibrepomcalId === modibrepomcalId && r.tipologiaId === tipologiaId), 1);
    if (fotoToDelete[0].id) {
      this.removeCaldaia(fotoToDelete[0].id, reload);
    }
  }

  checkPotenza(event, tipologiaId, prodId, tipoDesc) {
    var ind = this.form.sections.caldaie.findIndex(r => r.modibrepomcalId === prodId && r.tipologiaId === tipologiaId);
    this.form.sections.caldaie[ind].potenzaibrepomcalId = event.value;
    this.checkValid(tipoDesc);
  }

  removeCaldaia(id, reload): void {
    this.caldaiaService.delete(JSON.stringify(id)).subscribe(res => {
      if (reload)
        this.getCaldaie();
    }, error => {
      console.error('Error => ', error);
    }
    );
  }

  validForm() {
    if (this.checkAURUM && this.checkSlim && this.checkFull && this.checkIbrid && this.checkPower)
      return this.validform.emit(true);
    else
      return this.validform.emit(false);
  }

  setAurum(value) {
    this.checkAURUM = value;
    this.validForm();
  }

  setSlim(value) {
    this.checkSlim = value;
    this.validForm();
  }

  setIbrid(value) {
    this.checkIbrid = value;
    this.validForm();
  }

  setFull(value) {
    this.checkFull = value;
    this.validForm();
  }

  setPower(value) {
    this.checkPower = value;
    this.validForm();
  }

  checkValid(tipoDesc) {
    if (tipoDesc === constants.TYPE_TIPOPRODOTTOSISTEMA_AURUM)
      this.setAurum(true);
    else if (tipoDesc === constants.TYPE_TIPOPRODOTTOSISTEMA_IBRID)
      this.setIbrid(true);
    else if (tipoDesc === constants.TYPE_TIPOPRODOTTOSISTEMA_SLIM_AURUM)
      this.setSlim(true);
    else if (tipoDesc === constants.TYPE_TIPOPRODOTTOSISTEMA_P_FULL)
      this.setFull(true);
    else if (tipoDesc === constants.TYPE_TIPOPRODOTTOSISTEMA_POWER)
      this.setPower(true);
  }
}
