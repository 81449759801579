<div class="pt-5 text-center mb-4">
	<!-- <img class="mb-3" alt="logo" title="remail" src="/assets/img/logo-remail.png"> -->
</div>

<div class="text-center mb-4">
	<h4>CREA NUOVO ORDINE</h4>
</div>


<div class="anagraficaContainer mb-4">

	<div class="row">
		<div class="col-6">
			<input matInput [(ngModel)]="form.idCliente"
				placeholder="Nominativo cliente" hidden="true">

			<mat-label>Nominativo Cliente: </mat-label>
			<span>{{form.cognome}}</span>
		</div>

	</div>


	<div class="row">
		<div class="col-6">
			<mat-form-field class="full-width"
				*ngIf="this.listBrand && this.listBrand.length > 0">
			<mat-label>Seleziona Brand</mat-label> <mat-select
				[(ngModel)]="form.idBrand" (ngModelChange)="changeModel($event, option)">
			<mat-option></mat-option> <mat-option
				*ngFor="let option of listBrand" [value]="option">
			{{option.descrizione}} </mat-option> </mat-select> <mat-hint class="text-danger"
				*ngIf="form.idBrand && !form.idBrand"> Campo
			obbligatorio </mat-hint> </mat-form-field>

		</div>

		<div class="col-6">
			<mat-form-field class="full-width"
				*ngIf="this.listProdotti && this.listProdotti.length>0 && form.idBrand">
			<mat-label>Seleziona Prodotto</mat-label> <mat-select
				[(ngModel)]="form.idProdotto"> <mat-option
				*ngFor="let option of listProdotti" [value]="option.id">
			{{option.descrizione}} </mat-option> </mat-select> <mat-hint class="text-danger"
				*ngIf="form.idProdotto && !form.idProdotto"> Campo
			obbligatorio </mat-hint> </mat-form-field>
		</div>
	</div>

	<hr />
	<div class="">

		<button type="submit" id="save-entity"
			[disabled]="!validateForm()"
				color="primary" class="btnMedium mr-2"  mat-flat-button (click)="save();">
			<span class="material-icons">save</span>
			&nbsp;<span>Crea nuovo ordine</span>
		</button>
	</div>

</div>
