<div id="accordionOne">

    <div id="headingOne">
        <h5 class="mb-0">

            <div class="text-center help75Bg row">
                <div class="col-6 text-right">NUOVO PIATTO DOCCIA
                    <mat-hint class="text-danger">*</mat-hint>
                </div>
                <div class="col-6 text-left">
                    <mat-radio-group labelPosition="after" class="inline" color="primary"
                        [(ngModel)]="optionPiattoDoccia.selected" (ngModelChange)="showDiv($event)">
                        <mat-radio-button class="mb-2 mr-2" [value]="true">SI</mat-radio-button>
                        <mat-radio-button class="mb-2 mr-2" [value]="false">NO</mat-radio-button>
                    </mat-radio-group>
                </div>


            </div>
        </h5>
    </div>

    <div *ngIf="isVisible" id="collapseSeven" aria-labelledby="headingOne" data-parent="#accordionOne">
        <div>

            <div class="">
                <div class="text-center help75Bg">
                    PIATTI EFFETTO PIETRA
                </div>
                <div class="hepl75Cont"
                    [ngClass]="{'onlyRedBorder': form.sections.piattoDoccia.colorepiateffpietraId && !form.sections.piattoDoccia.dimpiatteffpietraId}">
                    <div class="d-inline-block">
                        <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                            (ngModelChange)="changeModel($event)"
                            [(ngModel)]="form.sections.piattoDoccia.colorepiateffpietraId">
                            <mat-radio-button class="mb-2 mr-2" [value]="colore.id" *ngFor="let colore of listColore">
                                {{colore.descrizione}}</mat-radio-button>
                            <span class="undo material-icons" matTooltip="Cancella selezione"
                                *ngIf="form.sections.piattoDoccia.colorepiateffpietraId"
                                (click)="form.sections.piattoDoccia.colorepiateffpietraId = null; form.sections.piattoDoccia.dimpiatteffpietraId = null ">
                                backspace
                            </span>
                        </mat-radio-group>
                    </div>

                    <div *ngIf="form.sections.piattoDoccia.colorepiateffpietraId && listMisure && listMisure.length>0">
                        <hr />
                        <p>DIMENSIONE</p>
                        <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                            [(ngModel)]="form.sections.piattoDoccia.dimpiatteffpietraId">
                            <mat-radio-button class="mr-2" [value]="misure.id" *ngFor="let misure of listMisure">
                                {{misure.descrizione}}</mat-radio-button>
                        </mat-radio-group>
                        <div class="smallFont text-danger"
                            *ngIf="form.sections.piattoDoccia.colorepiateffpietraId && !form.sections.piattoDoccia.dimpiatteffpietraId">
                            Selezione obbligatoria
                        </div>
                    </div>
                </div>
            </div>

            <div class="">
                <div class="text-center help75Bg">
                    PIATTI STONE 3D
                </div>
                <div class="hepl75Cont"
                    [ngClass]="{'onlyRedBorder': form.sections.piattoDoccia.colorepiatstoneId && !form.sections.piattoDoccia.dimpiattstoneId}">
                    <div class="d-inline-block">
                        <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                            (ngModelChange)="changeModelStone($event)"
                            [(ngModel)]="form.sections.piattoDoccia.colorepiatstoneId">
                            <mat-radio-button class="mb-2 mr-2" [value]="colore.id"
                                *ngFor="let colore of listColoreStone">{{colore.descrizione}}</mat-radio-button>
                            <span class="undo material-icons" matTooltip="Cancella selezione"
                                *ngIf="form.sections.piattoDoccia.colorepiatstoneId"
                                (click)="form.sections.piattoDoccia.colorepiatstoneId = null;form.sections.piattoDoccia.dimpiattstoneId = null ">
                                backspace
                            </span>
                        </mat-radio-group>
                    </div>

                    <div
                        *ngIf="form.sections.piattoDoccia.colorepiatstoneId && listMisureStone && listMisureStone.length>0">
                        <hr />
                        <p>DIMENSIONE</p>
                        <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                            [(ngModel)]="form.sections.piattoDoccia.dimpiattstoneId">
                            <mat-radio-button class="mr-2" [value]="misure.id" *ngFor="let misure of listMisureStone">
                                {{misure.descrizione}}</mat-radio-button>
                        </mat-radio-group>
                        <div class="smallFont text-danger"
                            *ngIf="form.sections.piattoDoccia.colorepiatstoneId && !form.sections.piattoDoccia.dimpiattstoneId">
                            Selezione obbligatoria
                        </div>


                    </div>
                </div>
            </div>

        </div>
    </div>


</div>