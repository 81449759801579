import { Component,  Input,OnInit } from '@angular/core';

import { Trascodifica } from '../../../../../classes/trascodifica';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';
import { AbitazioneService} from '../../services/abitazione.service';
import { constants } from 'src/app/modules/classes/constants';
@Component({
  selector: 'app-dati-abitazione-cald',
  templateUrl: './dati-abitazione-cald.component.html',
  styleUrls: ['./dati-abitazione-cald.component.scss']
})
export class DatiAbitazioneCaldComponent implements OnInit {
  @Input() form: any;
  @Input() trascodifiche:Trascodifica[];
  tipiAbitazione:Trascodifica[];
  abitazione: any;
  tipiCannaFumaria:Trascodifica[];
  constructor(
		private  formController:FormControllerService,
		private abitazioneService:AbitazioneService,
	) { }

  ngOnInit(): void {
    //this.form.sections.abitazione.ordineId = this.form.idOrder;
    this.getAbitazione();
	this.tipiAbitazione = this.formController.getTrascodificaByCodice(constants.COD_TIPO_ABITAZIONE, this.trascodifiche);
	this.tipiCannaFumaria = this.formController.getTrascodificaByCodice(constants.COD_CANNA_FUMARIA, this.trascodifiche);
  }

  getAbitazione():void {
	
		this.abitazioneService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
			this.abitazione = res[0];
			if(this.abitazione !== undefined){
				this.form.sections.abitazione = this.abitazione;
			}
		})
	
  }	

}
