<div class="text-center blueBg">
    MISCELATORI
</div>
<div class="blueCont"> 
	<div class="row mbt-4">	
	    <div class="col-2"><mat-label>DOCCIA:</mat-label></div>
		<div class="col-10">
			<mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.miscelatore.docciaId">
				<mat-radio-button *ngFor="let val of doccia" class="mb-2 mr-2" [value]="val.id">{{val.descrizione}}</mat-radio-button>
		        <span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.miscelatore.docciaId" (click)="form.sections.miscelatore.docciaId = null">
		            backspace
		        </span>
			</mat-radio-group>
		</div>
	</div>
	<div class="row mbt-4">	
		<div class="col-2"><mat-label>VASCA:</mat-label></div>
		<div class="col-10">
			<mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.miscelatore.vascaId">
			<mat-radio-button *ngFor="let val of vasca" class="mb-2 mr-2" [value]="val.id">{{val.descrizione}}</mat-radio-button>
	        <span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.miscelatore.vascaId" (click)="form.sections.miscelatore.vascaId = null">
	            backspace
	        </span>
		</mat-radio-group>
		</div>
	</div>
	
	<div class="row mbt-4">	
		<div class="col-2"><mat-label>LAVABO:</mat-label></div>
		<div class="col-10">
			<mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.miscelatore.lavaboId">
				<mat-radio-button *ngFor="let val of lavabo" class="mb-2 mr-2" [value]="val.id">{{val.descrizione}}</mat-radio-button>
		        <span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.miscelatore.lavaboId" (click)="form.sections.miscelatore.lavaboId = null">
		            backspace
		        </span>
			</mat-radio-group>
		</div>
	</div>

	<div class="row mbt-4">	
		<div class="col-2"><mat-label>BIDET:</mat-label></div>
		<div class="col-10">
			<mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.miscelatore.bidetId">
				<mat-radio-button *ngFor="let val of bidet" class="mb-2 mr-2" [value]="val.id">{{val.descrizione}}</mat-radio-button>
		        <span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.miscelatore.bidetId" (click)="form.sections.miscelatore.bidetId = null">
		            backspace
		        </span>
		</mat-radio-group>
		</div>
	</div>
</div>
