import { stringMap } from 'aws-sdk/clients/backup';

export class fotovoltaicoProd{
    id: number;
    prodottoId:number;
    prodottoDescrizione:string;
    tagliakwId:number;
    tagliakwDescrizione:string;
    battkwId:number;
    battkwDescrizione:string;
    inverterkwId:number;
    inverterkwDescrizione:string;
    potenzakwId:number;
    potenzakwDescrizione:string;
    fotovoltaicoId:number;
    dettaglio?: string;
}