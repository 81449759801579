import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from '../../../../classes/trascodifica'
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { SanitariService } from '../services/sanitari/sanitari.service';
import { OptionsAvailableService } from 'src/app/services/optionsAvailable/options-available.service';
import { OptionsAvailable } from 'src/app/modules/classes/options-available';
import { constants } from 'src/app/modules/classes/constants';
@Component({
  selector: 'app-form-nuova-rubinetteria',
  templateUrl: './form-nuova-rubinetteria.component.html',
  styleUrls: ['./form-nuova-rubinetteria.component.scss']
})
export class FormNuovaRubinetteriaComponent implements OnInit {
	
  @Input() form: any;
  @Input() trascodifiche : Trascodifica[];	
  @Input() brand:string;

  tipiSerieRub : Trascodifica[];  
  rubTipiAsta : Trascodifica[];
  rubTipiCol : Trascodifica[];
  tipiMiscEst: Trascodifica[];
  serieTipiMiscEst : Trascodifica[];
  subLavaboLime : Trascodifica[];
  subBidetLime : Trascodifica[];
  viewSerieLime : boolean = false;
  subBidetTango : Trascodifica[];
  subLavaboTango : Trascodifica[];
  viewSerieTango : boolean = false;
  subLavaboStick : Trascodifica[];
  subBidetStick : Trascodifica[];
  viewSerieStick : boolean = false;
  modelListLime : Trascodifica[];
  modelListCristPrime: Trascodifica[];
  modelListTango : Trascodifica[];
  modelListStick : Trascodifica[];
  modelListLight: Trascodifica[];
  modelListCristElevation: Trascodifica[];
  miscListLime : Trascodifica[];
  miscListTango : Trascodifica[];
  miscListStick : Trascodifica[];
  subLavaboLight: Trascodifica[];
  subBidetLight : Trascodifica[];
  viewSerieLight : boolean = false;
  miscListLight: Trascodifica[];
  colListLight: Trascodifica[];
  viewSerieGrohe : boolean = false;
  subLavaboGrohe: Trascodifica[];
  subBidetGrohe : Trascodifica[];
  subLavaboCristPrime: Trascodifica[]; 
  subBidetCristPrime : Trascodifica[];
  viewSerieCristPrime : boolean = false;
  miscCristinaPrime: Trascodifica[];
  subLavaboCristElevation: Trascodifica[]; 
  subBidetCristElevation : Trascodifica[];
  viewSerieCristElevation : boolean = false;
  miscCristinaElevation: Trascodifica[];

  isVisible:boolean = false;
  collapse:boolean =false;
  options: Trascodifica[];
  optionNuovaRubinetteria: OptionsAvailable = {id:undefined, typeId:undefined,ordineId:undefined, selected:false};
  listOptionsOrder: OptionsAvailable[];
  classBg:string = "blueBg";
  classContent:string = "blueCont";
  
  constructor(
		private formController:FormControllerService,
		private sanitariService:SanitariService,
		private optionsService:OptionsAvailableService
	) { }

  ngOnInit(): void {
	if(this.brand === 'bagniHelp75'){
		this.classBg = "help75Bg";
		this.classContent = "hepl75Cont";
	}else if (this.brand === 'bagno-pratico'){
		this.classBg = "bagnoPraticoBg";
		this.classContent = "bagnoPraticoCont";	
	}
	
	this.options = this.formController.getTrascodificaByCodice( constants.TYPE_OPTIONS ,this.trascodifiche).filter(op => op.descrizione === constants.TYPE_NUOVA_RUBINETTERIA);
	this.getOption();
	
	this.sanitariService.findByKey(this.form.idOrder , 'idordineId.equals=').subscribe(res => {
			this.formController.mappingSanitariDTOtoForm(res[0], this.form.sections.nuoviProdotti);				
			this.tipiSerieRub = this.formController.getTrascodificaByCodice( constants.COD_SERIE_RUBINETTERIA , this.trascodifiche);
			this.rubTipiAsta = this.formController.getTrascodificaByCodice( constants.COD_TIPO_ASTA_COMBY , this.trascodifiche);
			this.rubTipiCol =  this.formController.getTrascodificaByCodice( constants.COD_TIPO_COLONNE_RUB , this.trascodifiche);
			this.tipiMiscEst = this.formController.getTrascodificaByCodice( constants.COD_TIPO_MISC_EST, this.trascodifiche);
			this.serieTipiMiscEst = this.formController.getTrascodificaByCodice( constants.COD_SERIE_MISC_EST , this.trascodifiche);
			this.modelListLime = this.formController.getTrascodificaByCodice( constants.COD_KIT_SERIE_LIME , this.trascodifiche);
			this.modelListTango = this.formController.getTrascodificaByCodice( constants.COD_KIT_SERIE_TANGO , this.trascodifiche);
			this.modelListCristElevation = this.formController.getTrascodificaByCodice( constants.COD_KIT_SERIE_CRIST_ELEVATION , this.trascodifiche);
			this.modelListStick = this.formController.getTrascodificaByCodice( constants.COD_KIT_SERIE_STICK , this.trascodifiche);	
			this.modelListLight = this.formController.getTrascodificaByCodice( constants.COD_KIT_SERIE_LIGHT, this.trascodifiche);		
			this.miscListLime = this.formController.getTrascodificaByCodice( constants.COD_MISC_SERIE_LIME , this.trascodifiche);
			this.miscListTango = this.formController.getTrascodificaByCodice( constants.COD_MISC_SERIE_TANGO , this.trascodifiche);
			this.miscListStick = this.formController.getTrascodificaByCodice( constants.COD_MISC_SERIE_STICK , this.trascodifiche);
			this.miscListLight = this.formController.getTrascodificaByCodice( constants.COD_MISC_SERIE_LIGHT , this.trascodifiche);
			this.miscCristinaPrime = this.formController.getTrascodificaByCodice( constants.COD_MISC_SERIE_CRISTINA_PRIME , this.trascodifiche);
			this.miscCristinaElevation = this.formController.getTrascodificaByCodice( constants.COD_MISC_SERIE_CRIST_ELEVATION , this.trascodifiche);
			this.colListLight = this.formController.getTrascodificaByCodice( constants.COD_COL_SERIE_LIGHT , this.trascodifiche);
			if(this.form.sections.nuoviProdotti.nuovaRubinetteria.id !== null && this.form.sections.nuoviProdotti.nuovaRubinetteria.id !== undefined){
				this.changeSerie(this.form.sections.nuoviProdotti.nuovaRubinetteria.id);
			}	
	  });
  }

  resetdocciaType(event): void{
    if (this.form.sections.nuoviProdotti.nuovaRubinetteria.docciaType.miscelatore) {
      delete this.form.sections.nuoviProdotti.nuovaRubinetteria.docciaType.miscelatore;
    }
  }


  changeSerie(event): void {
	// Lime
	this.subLavaboLime = this.trascodifiche.filter(
	  tr => (tr.codice === constants.COD_LIME_LAVABO) && (tr.parentId === event)
	);
	this.subBidetLime = this.trascodifiche.filter(
	  tr => (tr.codice === constants.COD_LIME_BIDET) && (tr.parentId === event)
	);
	
	if (this.subLavaboLime.length > 0 || this.subBidetLime.length > 0) {
	  this.viewSerieLime = true;
	  this.viewSerieTango = false;
	  this.viewSerieStick = false;
	  this.viewSerieLight = false;
	  this.viewSerieCristPrime = false;
	  this.viewSerieCristElevation = false;
	  this.viewSerieGrohe = false;
	} else {
	  // Tango
	  this.subLavaboTango = this.trascodifiche.filter(
		tr => (tr.codice === constants.COD_TANGO_LAVABO) && (tr.parentId === event)
	  );
	  this.subBidetTango = this.trascodifiche.filter(
		tr => (tr.codice === constants.COD_TANGO_BIDET) && (tr.parentId === event)
	  );
  
	  if (this.subLavaboTango.length > 0 || this.subBidetTango.length > 0) {
		this.viewSerieTango = true;
		this.viewSerieLime = false;
		this.viewSerieStick = false;
		this.viewSerieLight = false;
		this.viewSerieCristPrime = false;
		this.viewSerieCristElevation = false;
		this.viewSerieGrohe = false;
	  } else {
		// Stick
		this.subLavaboStick = this.trascodifiche.filter(
		  tr => (tr.codice === constants.COD_STICK_LAVABO) && (tr.parentId === event)
		);
		this.subBidetStick = this.trascodifiche.filter(
		  tr => (tr.codice === constants.COD_STICK_BIDET) && (tr.parentId === event)
		);
  
		if (this.subLavaboStick.length > 0 || this.subBidetStick.length > 0) {
		  this.viewSerieStick = true;
		  this.viewSerieLime = false;
		  this.viewSerieTango = false;
		  this.viewSerieLight = false;
		  this.viewSerieCristPrime = false;
		  this.viewSerieCristElevation = false;
		  this.viewSerieGrohe = false;
		} else {
		  // Light
		  this.subLavaboLight = this.trascodifiche.filter(
			tr => (tr.codice === constants.COD_LIGHT_LAVABO) && (tr.parentId === event)
		  );
		  this.subBidetLight = this.trascodifiche.filter(
			tr => (tr.codice === constants.COD_LIGHT_BIDET) && (tr.parentId === event)
		  );
  
		  if (this.subLavaboLight.length > 0 || this.subBidetLight.length > 0) {
			this.viewSerieLight = true;
			this.viewSerieStick = false;
			this.viewSerieLime = false;
			this.viewSerieTango = false;
			this.viewSerieCristPrime = false;
			this.viewSerieCristElevation = false;
			this.viewSerieGrohe = false;
		  } else {
			// Cristina Prime
			this.subLavaboCristPrime = this.trascodifiche.filter(
			  tr => (tr.codice === constants.COD_CRISTINA_PRIME_LAVABO) && (tr.parentId === event)
			);
			this.subBidetCristPrime = this.trascodifiche.filter(
			  tr => (tr.codice === constants.COD_CRISTINA_PRIME_BIDET) && (tr.parentId === event)
			);
  
			if (this.subLavaboCristPrime.length > 0 || this.subBidetCristPrime.length > 0) {
			  this.viewSerieCristPrime = true;
			  this.viewSerieLight = false;
			  this.viewSerieStick = false;
			  this.viewSerieLime = false;
			  this.viewSerieTango = false;
			  this.viewSerieCristElevation = false;
			  this.viewSerieGrohe = false;
			} else {
			  // Cristina Elevation
			  this.subLavaboCristElevation = this.trascodifiche.filter(
				tr => (tr.codice === constants.COD_CRISTINA_ELEVATION_LAVABO) && (tr.parentId === event)
			  );
			  this.subBidetCristElevation = this.trascodifiche.filter(
				tr => (tr.codice === constants.COD_CRISTINA_ELEVATION_BIDET) && (tr.parentId === event)
			  );
  
			  if (this.subLavaboCristElevation.length > 0 || this.subBidetCristElevation.length > 0) {
				this.viewSerieCristElevation = true;
				this.viewSerieLight = false;
				this.viewSerieStick = false;
				this.viewSerieLime = false;
				this.viewSerieTango = false;
				this.viewSerieCristPrime = false;
				this.viewSerieGrohe = false;
			  } else {
				// Grohe (no length check)
				this.subLavaboGrohe = this.trascodifiche.filter(
				  tr => (tr.codice === constants.COD_GROHE_LAVABO) && (tr.parentId === event)
				);
				this.subBidetGrohe = this.trascodifiche.filter(
				  tr => (tr.codice === constants.COD_GROHE_BIDET) && (tr.parentId === event)
				);
  
				this.viewSerieGrohe = true;
				this.viewSerieLight = false;
				this.viewSerieStick = false;
				this.viewSerieLime = false;
				this.viewSerieTango = false;
				this.viewSerieCristPrime = false;
				this.viewSerieCristElevation = false;
			  }
			}
		  }
		}
	  }
	}
  }

  resetNuovaRub(event): void{
		this.viewSerieStick = false;
		this.viewSerieLime = false;
		this.viewSerieTango = false;
		this.viewSerieLight = false;
		this.viewSerieGrohe = false;
		this.viewSerieCristPrime = false;
		this.viewSerieCristElevation = false;
  }  

  setAstaSingola(event): void {
    this.form.sections.nuoviProdotti.nuovaRubinetteria.astasingola = event.checked;
  }
  
  setAstaDoppia(event): void {
    this.form.sections.nuoviProdotti.nuovaRubinetteria.astadoppia = event.checked;
  }

  showDiv(value){    
	if(value ){
	  this.isVisible = true;
	}else{
	  this.isVisible = false;
	}
	this.form.sections.optionsAvailable.filter(op => op.typeId === this.options[0]?.id)[0].selected = value;
   
  }

  getOption(){
	this.optionsService.findByKey(this.form.idOrder,'ordineId.equals=').subscribe(res => { 
		if(res !== undefined){
		  this.listOptionsOrder = res;
		  let optionNuovaRubinetteria = res.filter(op => op.typeId === this.options[0].id)  
		  	if(optionNuovaRubinetteria !== undefined && optionNuovaRubinetteria.length > 0){
				this.isVisible = optionNuovaRubinetteria[0].selected;
				this.form.sections.optionsAvailable=res;
				this.optionNuovaRubinetteria = optionNuovaRubinetteria[0];
		  	}else{
				this.isVisible = false;
				this.optionNuovaRubinetteria = new OptionsAvailable();
				this.optionNuovaRubinetteria.ordineId = this.form.idOrder;
				this.optionNuovaRubinetteria.typeId = this.options[0].id;
				this.optionNuovaRubinetteria.selected = undefined;
				this.form.sections.optionsAvailable.push(this.optionNuovaRubinetteria);
		  	}
		  }
	  });
   }
}
