import { Component, OnInit, ViewChild } from '@angular/core';
import { OrdersService } from '../../../services/orders/orders.service';
import { FormControllerService } from '../../../services/controller/form-controller.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MainComponent } from '../../../main/main.component';
import { UserService } from '../../../services/user/user.service';
import { MatSort } from '@angular/material/sort';
import { constants } from '../../classes/constants';

@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.scss']
})
export class NewOrderComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loading: boolean = false;

  private path: string = '/new-order/';
  brands: any[];

  contracts: any;
  nameUser: any;
  sendedId: any;
  clonedList: any;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['idOrdine', 'nomeProdotto', 'brandDescrizione', 'dataordine', 'statopraticaDescrizione', 'clienteCf', 'actions'];

  constructor(
    private router: Router,
    private orderService: OrdersService,
    private mainCompo: MainComponent,
    private userService: UserService,
    private formController: FormControllerService,
  ) {
    this.brands = [];
    this.contracts = [];
  }

  ngOnInit(): void {
    if (this.userService.returnUserAut('ROLE_SELLER')) {
      this.getContracts();
    }
    if (this.userService.returnUserAut('ROLE_TECH')) {
      this.getSendedDossierId();
    }
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    const filterIng = (event.target as HTMLInputElement).value.toLowerCase();
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (event) {
      this.clonedList = [];
      this.contracts.forEach(c => {
        if (
          c.brandDescrizione?.toLowerCase().includes(filterIng) ||
          c.nomeProdotto?.toLowerCase().includes(filterIng) ||
          c.statopraticaDescrizione?.toLowerCase().includes(filterIng) ||
          c.prodottoCommessa?.toLowerCase().includes(filterIng) ||
          c.clienteCf?.toLowerCase().includes(filterIng) ||
          c.cognome?.toLowerCase().includes(filterIng) ||
          c.idordine?.toLowerCase().includes(filterIng)
        ) {
          this.clonedList.push(c);
        }
      });
      this.dataSource = new MatTableDataSource(this.clonedList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } else {
      this.dataSource = new MatTableDataSource(this.contracts);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  disabled(value): boolean {
    return (value !== constants.COMMESSA_BAGNI_ITALIANI && value !== constants.COMMESSA_ARIEL_STUFA_PELLET && value !== constants.COMMESSA_ARIEL_CALDAIE && value !== constants.COMMESSA_ARIEL_CONDIZIONATORI && value !== constants.COMMESSA_REMAIL_VASCA_IN_DOCCIA && value !== constants.COMMESSA_REMAIL_BAGNI_ITALIANI_HELP_75 && value !== constants.COMMESSA_ARIEL_FOTOVOLTAICO_PREVENTIVO
      && value !== constants.COMMESSA_ARIEL_IBRID && value !== constants.COMMESSA_BAGNO_PRATICO && value !== constants.COMMESSA_ARIEL_FOTOVOLTAICO && value !== constants.COMMESSA_ZUCCHETTI_MONTASCALE && value !== constants.COMMESSA_ARIEL_DEPURATORE && value !== constants.COMMESSA_LOREN_CUCINE && value !== constants.COMMESSA_ZUCCHETTI_POLTRONE
      && value !== constants.COMMESSA_MONDIAL_ALLARME);
  }

  goToOrder(value, idClient, idProduct, idOrder, isManuale: boolean): void {
    this.orderService.getSingleOrder(idOrder).subscribe(res => {
      if (res.idordine === undefined || res.idordine === null) {
        res.idordine = new Date().getTime();
        this.orderService.update(res).subscribe();
      }
    });

    localStorage.setItem('id_product', idProduct);

    if (idClient !== undefined && idClient !== null) {
      localStorage.setItem('id_client', idClient);
    } else {
      localStorage.removeItem('id_client');
    }

    if(isManuale){
      this.router.navigate([this.path + 'COMPILAZIONE-MANUALE/' + idOrder]);
    }else{
      if (value === constants.COMMESSA_BAGNI_ITALIANI) {
        //ariel stufe  
        this.router.navigate([this.path + 'BAGNI_ITALIANI/bagno/' + idOrder]);
      } else if (value === constants.COMMESSA_ARIEL_STUFA_PELLET) {
        //ariel stufe  
        this.router.navigate([this.path + 'ARIEL/stufe/' + idOrder]);
      } else if (value === constants.COMMESSA_ARIEL_CALDAIE) {
        //ariel caldaia  
        this.router.navigate([this.path + 'ARIEL/caldaia/' + idOrder]);
      } else if (value === constants.COMMESSA_ARIEL_CONDIZIONATORI) {
        //ariel condizionatore  
        this.router.navigate([this.path + 'ARIEL/condizionatore/' + idOrder]);
      } else if (value === constants.COMMESSA_REMAIL_VASCA_IN_DOCCIA) {
        //remail vasca in doccia      
        this.router.navigate([this.path + 'REMAIL/vascaindoccia/' + idOrder]);
      } else if (value === constants.COMMESSA_REMAIL_BAGNI_ITALIANI_HELP_75) {
        //bagni hel75
        this.router.navigate([this.path + 'BAGNI_ITALIANI/help75/' + idOrder]);
      } else if (value === constants.COMMESSA_ARIEL_FOTOVOLTAICO) {
        //ARIEL FOTOVOLTAICO
        this.router.navigate([this.path + 'ARIEL/fotovoltaico/' + idOrder]);
      } else if (value === constants.COMMESSA_ARIEL_IBRID) {
        this.router.navigate([this.path + 'ARIEL/pompacalore/' + idOrder]);
      } else if (value === constants.COMMESSA_BAGNO_PRATICO) {
        //BAGNO PRATICO
        this.router.navigate([this.path + 'BAGNO_PRATICO/bagno/' + idOrder]);
      } else if (value === constants.COMMESSA_ZUCCHETTI_MONTASCALE) {
        //ZUCCHETTI MONTASCALE
        this.router.navigate([this.path + 'ZUCCHETTI/montascale/' + idOrder]);
      } else if(value === constants.COMMESSA_ZUCCHETTI_POLTRONE){
        //ZUCCHETTI POLTRONE
        this.router.navigate([this.path + 'ZUCCHETTI/poltrone/' + idOrder])
      } else if (value === constants.COMMESSA_ARIEL_DEPURATORE) {
        //ARIEL DEPURATORE
        this.router.navigate([this.path + 'ARIEL/depuratore/' + idOrder]);
      } else if (value === constants.COMMESSA_CB_VASCA_IN_DOCCIA) {
        //CRISTALBOX VASCA IN DOCCIA
        this.router.navigate([this.path + 'CRISTALBOX/vascaindoccia/' + idOrder]);
      } else if (value === constants.COMMESSA_MONDIAL_ALLARME) {
        //MONDIAL ALLARME
        this.router.navigate([this.path + 'MONDIAL/allarme/' + idOrder]);
      } else if (value === constants.COMMESSA_LOREN_CUCINE) {
        //LOREN CUCINE
        this.router.navigate([this.path + 'LOREN/cucina/' + idOrder]);
      } 
    }
  }

  createOrder(id, gdlid): void {
    this.router.navigate(['create-order/' + id]);
    localStorage.removeItem('id_client');
    localStorage.setItem('id_gdl', gdlid);
  }

  getContracts(): any {
    this.loading = true;
    this.orderService.getOrders(this.mainCompo.loggedUser.id, 'size=10000&sort=id&statopraticaCodice.in=A&venditoreId.equals=').subscribe(res => {
      this.loading = false;
      this.contracts = res;
      this.clonedList = [...this.contracts];
      this.nameUser = this.mainCompo.loggedUser.firstName + ' ' + this.mainCompo.loggedUser.lastName;
      this.contracts.forEach(r => {
        r.statopraticaDescrizione = this.capitalize(r.statopraticaDescrizione);
        r.brandDescrizione = this.capitalize(r.brandDescrizione);
        r.nomeProdotto = this.formController.returnProdName(r.prodottoCommessa);
      });
      this.dataSource = new MatTableDataSource(this.contracts);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  capitalize(value): string {
    return this.formController.capitalize(value);
  }

  getSendedDossierId(): void {
    this.loading = true;
    this.orderService.getSendId('I').subscribe(res => {
      this.sendedId = res[0].id;
      this.getSendedDossiers();
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  getSendedDossiers(): void {
    this.orderService.getSendOrders(this.sendedId).subscribe(res => {
      this.contracts = res;
      this.dataSource = new MatTableDataSource(this.contracts);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  disabledByStatoPratica(codice) {
    if (codice === 'D') {
      return true;
    } else {
      return false;
    }
  }
}
