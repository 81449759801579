<div class="anagraficaContainer">
    <div class="text-left text-success font-weight-bold mb-4">
        Disegno caldaia esistente
    </div>
    <div class="row originalRow" *ngIf="trascodifiche && TIPOCALDAIAESISTENTE">
        <div class="col-12 mb-3">TIPO CALDAIA ESISTENTE</div>
        <div class="col-lg-6 mb-2 align-self-center">
            <mat-radio-group class="smalFt" [(ngModel)]="form.sections.disegnoCaldaia.tipoId">
                <mat-radio-button color="primary" class="mr-2 mb-3 d-block" *ngFor="let tipo of TIPOCALDAIAESISTENTE; let f = index;" [value]="tipo.id"  (click)="setDescrizioneTipoCaldaia(tipo.id);">
                    {{tipo.descrizione}}
                </mat-radio-button>
               	<span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.disegnoCaldaia.tipoId" (click)="form.sections.disegnoCaldaia.tipoId = null">
                      backspace
                </span>                
            </mat-radio-group>
            <div class="smallFont text-danger mb-3" *ngIf="!form.sections.disegnoCaldaia.tipoId">Campo obbligatorio</div>

        </div>
        <div class="col-lg-6 mb-2 align-self-center" *ngIf="form.sections.disegnoCaldaia.tipoDescrizione && form.sections.disegnoCaldaia.tipoDescrizione!=='NESSUNA'">
                <app-input-number
                    (onNumChange)="form.sections.disegnoCaldaia.altezza = $event"
                    [step]="0.1"
                    [num]="form.sections.disegnoCaldaia.altezza"
                    [decimal]="true"
                    [label]="'Altezza cm.'">
                </app-input-number>
            <div *ngIf="(!form.sections.disegnoCaldaia.altezza || form.sections.disegnoCaldaia.altezza==0 || form.sections.disegnoCaldaia.altezza<0.1)" class="mb-3 smallFont text-danger">Campo obbligatorio</div>

                <app-input-number
                    (onNumChange)="form.sections.disegnoCaldaia.larghezza = $event"
                    [step]="0.1"
                    [num]="form.sections.disegnoCaldaia.larghezza"
                    [decimal]="true"
                    [label]="'Larghezza cm.'">
                </app-input-number>
            <div *ngIf="!form.sections.disegnoCaldaia.larghezza || form.sections.disegnoCaldaia.larghezza==0 || form.sections.disegnoCaldaia.larghezza<0.1" class="mb-3 smallFont text-danger">Campo obbligatorio</div>

                <app-input-number
                    (onNumChange)="form.sections.disegnoCaldaia.profondita = $event"
                    [step]="0.1"
                    [num]="form.sections.disegnoCaldaia.profondita"
                    [decimal]="true"
                    [label]="'Profondità cm.'">
                </app-input-number>
            <div *ngIf="!form.sections.disegnoCaldaia.profondita || form.sections.disegnoCaldaia.profondita==0 || form.sections.disegnoCaldaia.profondita<0.1" class="mb-3 smallFont text-danger">Campo obbligatorio</div>

        </div>
    </div>
    <hr/>
    <div class="row originalRow">

        <div class="col-lg-6 align-self-center">
            <mat-radio-group class="smalFt" [(ngModel)]="form.sections.disegnoCaldaia.combustibileId">
                <mat-radio-button color="primary" class="mr-2 mb-1 d-block" *ngFor="let tipo of TIPOCOMBUSTIBILECALDAIAESISTENTE; let f = index;" [value]="tipo.id">
                    {{tipo.descrizione}}
                </mat-radio-button>
               	<span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.disegnoCaldaia.combustibileId" (click)="form.sections.disegnoCaldaia.combustibileId = null">
                      backspace
                </span>                 
            </mat-radio-group>
        </div>
        <div class="col-lg-6 mb-2 align-self-center">
            <app-input-number
                    (onNumChange)="form.sections.disegnoCaldaia.numtermo = $event"
                    [step]="1"
                    [num]="form.sections.disegnoCaldaia.numtermo"
                    [decimal]="false"
                    [label]="'N° termosifoni.'">
            </app-input-number>
        </div>
    </div>
</div>
