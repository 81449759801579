import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainComponent } from '../../../main/main.component';
import { BrandService } from '../../../services/brand/brand.service';
import { ProdottoService } from '../../../services/prodotto/prodotto.service';
import { OrdersService } from '../../../services/orders/orders.service';
import { Brand } from '../../classes/brand';
import { Prodotto } from '../../classes/prodotto';
import { Ordine } from '../../classes/ordine';
import { AnagraficaDTO } from '../../seller/bagni-italiani/main-bagni/classes/anagrafica';
import { constants } from '../../classes/constants';
@Component({
	selector: 'create-order',
	templateUrl: './create-order.component.html',
	styleUrls: ['./create-order.component.scss']
})
export class CreateOrderComponent implements OnInit {

	id: number;
	idGdl: number;

	form: any;

	listBrand: Brand[];
	listProdotti: Prodotto[] = [];
	prodottoSel: Prodotto;

	constructor(
		private route: ActivatedRoute,
		private brandService: BrandService,
		private prodottoService: ProdottoService,
		private ordineService: OrdersService,
		private router: Router,
		private mainCompo: MainComponent
	) {
		this.id = Number(this.route.snapshot.paramMap.get('id'));
		this.idGdl = Number(localStorage.getItem('id_gdl'));
		this.form = {
			idBrand: null,
			idProdotto: null,
			idCliente: null,
			nome: [],
			cognome: []
		};
	}


	ngOnInit(): void {
		this.ordineService.getSingleOrder(this.id).subscribe(res => { this.form.cognome = res.cognome, this.form.nome = res.nome });
		this.brandService.getBrands().subscribe(res => { this.listBrand = res });
	}

	changeModel(event): void {
		delete this.form.idProdotto;
		if (event && event.id !== undefined) {
			this.prodottoService.getProdottos(event.id, 'brandId.equals=').subscribe(
				res => {
					this.listProdotti = []; 
					res.forEach(product => {
						if(product.commessa !==  constants.COMMESSA_ARIEL_FOTOVOLTAICO_PREVENTIVO){
							this.listProdotti.push(product);
						}
					});
				})
		}
	}

	save(): void {
		var anagrafica = new AnagraficaDTO();
		anagrafica.nome = this.form.nome;
		anagrafica.cognome = this.form.cognome;
		//recupero la commessa
		this.prodottoService.getProdottos(this.form.idProdotto, "id.equals=").subscribe(res => {

			this.prodottoSel = res[0];

			//creo il nuovo ordine
			var order = this.createOrder(this.form.cognome, this.form.nome);
			this.ordineService.save(order).subscribe(res => {
				if (res.id !== undefined) {
					localStorage.setItem('id_product', String(order.prodottoId));
					//localStorage.removeItem('id_gdl');
					if (this.prodottoSel.commessa === constants.COMMESSA_BAGNI_ITALIANI) {
						//BAGNI ITALIANI BAGNI ITALIANI
						this.router.navigate(['/new-order/BAGNI_ITALIANI/bagno/' + res.id]);
					} else if (this.prodottoSel.commessa === constants.COMMESSA_ARIEL_STUFA_PELLET) {
						//ARIEL STUFE
						this.router.navigate(['new-order/ARIEL/stufe/' + res.id]);
					} else if (this.prodottoSel.commessa === constants.COMMESSA_ARIEL_CALDAIE) {
						//ARIEL CALDAIA
						this.router.navigate(['new-order/ARIEL/caldaia/' + res.id]);
					} else if (this.prodottoSel.commessa === constants.COMMESSA_ARIEL_CONDIZIONATORI) {
						//ARIEL CONDIZIONATORE
						this.router.navigate(['new-order/ARIEL/condizionatore/' + res.id]);
					} else if (this.prodottoSel.commessa === constants.COMMESSA_REMAIL_VASCA_IN_DOCCIA) {
						//REMAIL VASCA IN DOCCIA
						this.router.navigate(['new-order/REMAIL/vascaindoccia/' + res.id]);
					} else if (this.prodottoSel.commessa === constants.COMMESSA_REMAIL_BAGNI_ITALIANI_HELP_75) {
						//bagni hel75
						this.router.navigate(['new-order/BAGNI_ITALIANI/help75/' + res.id]);
					} else if (this.prodottoSel.commessa === constants.COMMESSA_ARIEL_FOTOVOLTAICO) {
						//ARIEL FOTOVOLTAICO
						this.router.navigate(['new-order/ARIEL/fotovoltaico/' + res.id]);
					} else if (this.prodottoSel.commessa === constants.COMMESSA_ARIEL_IBRID) {
						//ARIEL SISTEMI IBRIDI POMPA DI CALORE
						this.router.navigate(['new-order/ARIEL/pompacalore/' + res.id]);
					} else if (this.prodottoSel.commessa === constants.COMMESSA_BAGNO_PRATICO) {
						//BAGNO PRATICO
						this.router.navigate(['new-order/BAGNO_PRATICO/bagno/' + res.id]);
					} else if (this.prodottoSel.commessa === constants.COMMESSA_ZUCCHETTI_MONTASCALE) {
						//ZUCCHETTI MONTASCALE
						this.router.navigate(['/new-order/ZUCCHETTI/montascale/' + res.id]);
					} else if (this.prodottoSel.commessa === constants.COMMESSA_ARIEL_DEPURATORE) {
						//ARIEL DEPURATORE
						this.router.navigate(['/new-order/ARIEL/depuratore/' + res.id]);
					} else if (this.prodottoSel.commessa === constants.COMMESSA_CB_VASCA_IN_DOCCIA) {
						//CRISTALBOX VASCA IN DOCCIA
						this.router.navigate(['/new-order/CRISTALBOX/vascaindoccia/' + res.id]);
					} else if (this.prodottoSel.commessa === constants.COMMESSA_MONDIAL_ALLARME) {
						//MONDIAL ALLARME
						this.router.navigate(['/new-order/MONDIAL/allarme/' + res.id]);
					} else if (this.prodottoSel.commessa === constants.COMMESSA_LOREN_CUCINE) {
						//LOREN CUCINE
						this.router.navigate(['/new-order/LOREN/cucina/' + res.id]);
					} else if (this.prodottoSel.commessa === constants.COMMESSA_ZUCCHETTI_POLTRONE) {
						// ZUCCHETTI POLTRONE
						this.router.navigate(['/new-order/ZUCCHETTI/poltrone/' + res.id]);
					}
				}
			});
		});
	}
	
  private createOrder(cognome,nome): Ordine {
    return {
      ...new Ordine(),
      brandId: this.form.idBrand.id,
      prodottoId: this.form.idProdotto,
   	  idordine:new Date().getTime(),
      statopraticaId:2,
      venditoreId:this.mainCompo.loggedUser.id,
	  dataordine:new Date(),
      cognome:cognome,
	  nome:nome,
	  gdlid:this.idGdl,
    };
  }

  validateForm():boolean{
	var result = false;
	
	if(this.form.idBrand?.id !== null && this.form.idBrand?.id !== undefined && this.form.idProdotto !== null && this.form.idProdotto !== undefined){
		result = true;	
	}
	
	return result;
  }

}
