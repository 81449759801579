export class MiscelatoreDto {
	bidetDescrizione:	string;
	bidetId:number;
	docciaDescrizione:	string;
	docciaId:number;
	id:number;
	lavaboDescrizione:	string;
	lavaboId:number;
	ordineId:number;
	ordineIdordine:	string;
	vascaDescrizione:	string;
	vascaId:number;

}
