import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MainComponent } from 'src/app/main/main.component';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { UserService } from 'src/app/services/user/user.service';
import { constants } from '../../classes/constants';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';

@Component({
  selector: 'app-new-estimate',
  templateUrl: './new-estimate.component.html',
  styleUrls: ['./new-estimate.component.scss']
})
export class NewEstimateComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loading: boolean = false;

  private path:string = '/new-estimate/';
  brands: any[];
  
  contracts: any;
  nameUser: any;
  sendedId: any;
  clonedList: any;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['idOrdine', 'nomeProdotto', 'brandDescrizione', 'dataordine', 'statopraticaDescrizione', 'clienteCf', 'actions'];

  mockDataSource: MatTableDataSource<any>;

  constructor(
    private router: Router,
    private mainCompo: MainComponent,
    private userService: UserService,
    private orderService: OrdersService,
    private formController: FormControllerService,
  ) { }

  ngOnInit(): void {
      if (this.userService.returnUserAut(constants.TYPE_ROLE_TECH)) {
        this.getContracts();
      }
  }

  createNewOrder(): void {
    localStorage.removeItem('id_client');
    localStorage.removeItem('id_order');
    this.router.navigate(['create-estimate']);
  }

  goToOrder(value, idClient, idProduct, idOrder): void {
    this.orderService.getSingleOrder(idOrder).subscribe(res => {
      if (res.idordine === undefined || res.idordine === null) {
        res.idordine = new Date().getTime();
        this.orderService.update(res).subscribe();
      }
    });

    localStorage.setItem('id_product', idProduct);

    if (idClient !== undefined && idClient !== null) {
      localStorage.setItem('id_client', idClient);
    } else {
      localStorage.removeItem('id_client');
    }

    if (value === 'SFA-T00177-UT'){
      //ARIEL FOTOVOLTAICO
      this.router.navigate([this.path + 'ARIEL/fotovoltaico/' + idOrder]);
    }
  }

  getContracts(): any {
    this.loading = true;
      this.orderService.getOrders(this.mainCompo.loggedUser.id, 'size=10000&statopraticaCodice.in=A&venditoreId.equals=').subscribe(res => {
          this.loading = false;
          this.contracts = res;
          this.clonedList = [...this.contracts];
          this.nameUser = this.mainCompo.loggedUser.firstName + ' ' + this.mainCompo.loggedUser.lastName;
          this.contracts.forEach(r => {
            if(r.prodottoCommessa === constants.COMMESSA_ARIEL_FOTOVOLTAICO_PREVENTIVO){
              r.statopraticaDescrizione = this.capitalize(r.statopraticaDescrizione);
              r.brandDescrizione = this.capitalize(r.brandDescrizione);
              r.nomeProdotto = this.formController.returnProdName(r.prodottoCommessa);
            }
          });
          this.dataSource = new MatTableDataSource(this.contracts);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
      }, error => {
        this.loading = false;
        console.error('Error => ', error);
    });
  }

  capitalize(value): string {
      return this.formController.capitalize(value);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    const filterIng = (event.target as HTMLInputElement).value.toLowerCase();
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (event) {
      this.clonedList = [];
      this.contracts.forEach(c => {
        if (
          c.brandDescrizione?.toLowerCase().includes(filterIng) ||
          c.statopraticaDescrizione?.toLowerCase().includes(filterIng) ||
          c.prodottoCommessa?.toLowerCase().includes(filterIng) ||
          c.clienteCf?.toLowerCase().includes(filterIng) ||
          c.cognome?.toLowerCase().includes(filterIng) ||
          c.idordine?.toLowerCase().includes(filterIng)
        ) {
          this.clonedList.push(c);
        }
      });
      this.dataSource = new MatTableDataSource(this.clonedList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } else {
      this.dataSource = new MatTableDataSource(this.contracts);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  disabledByStatoPratica(codice) {
      if (codice === 'D') {
        return true;
      } else {
        return false;
      }
  }

  disabled(value): boolean {
      return (value !== 'SFA-T00055' && value !== 'SFA-T00067' && value !== 'SFA-T00050' && value !== 'SFA-T00065' && value !== 'SFA-T00052' && value !== 'SFA-T00169' && value !== 'SFA-T00177-UT' 
        && value !== constants.COMMESSA_ARIEL_IBRID && value !== constants.COMMESSA_BAGNO_PRATICO && value !== constants.COMMESSA_CB_VASCA_IN_DOCCIA);
  }
}

