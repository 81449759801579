import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { OrdersListComponent } from './orders-list/orders-list.component';
import { MaterialModule } from '../material/material.module';
import { MatSortModule } from '@angular/material/sort';
import { SearchOrdersComponent } from './search-orders/search-orders.component';
import it from '@angular/common/locales/it';
import { OrdersFrozenComponent } from './orders-frozen/orders-frozen.component';

registerLocaleData(it);
@NgModule({
  declarations: [OrdersListComponent, SearchOrdersComponent, OrdersFrozenComponent],
  imports: [
    CommonModule,
    MaterialModule,
    MatSortModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "it-IT" }, //replace "en-US" with your locale
    //otherProviders...
  ]
})
export class OrdersModule { }
