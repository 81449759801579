import { FinAnagrafica, Finanziamento, FinDatiOccupazione } from 'src/app/modules/classes/finanziamento/finanziamento-dto';
import { AnagraficaService } from '../../bagni-italiani/main-bagni/services/anagrafica/anagrafica.service';
import { OmaggioService } from '../../bagni-italiani/main-bagni/services/omaggio/omaggio.service';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { PagamentoDTO } from '../../bagni-italiani/main-bagni/classes/dati-tecnici';
import { AnagraficaDTO, AnagraficaForm } from 'src/app/modules/classes/anagrafica';
import { PoltronaService } from 'src/app/services/poltrone/poltrone.service';
import { AllegatoService } from 'src/app/services/allegato/allegato.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { BrandService } from 'src/app/services/brand/brand.service';
import { OmaggioDto } from 'src/app/modules/classes/omaggio-dto';
import { constants } from 'src/app/modules/classes/constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Ordine } from 'src/app/modules/classes/ordine';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
declare var jQuery: any;

@Component({
  selector: 'app-poltrone',
  templateUrl: './poltrone.component.html',
  styleUrls: ['./poltrone.component.scss']
})
export class PoltroneComponent implements OnInit {

  validAnagrafica: boolean = false;
  preventivoFound: boolean = true;
  showAnagrafica: boolean = false;
  openSign: boolean = false;
  checkContractNumber: boolean = false;
  showModalPrint: boolean;

  idCliente: number;
  currentStep: number = 0;
  timeLeft: any = 60;

  form: any;
  obj1: any;
  obj2: any;
  error: any;
  originalForm: any; 
  isLoaded: boolean = false;
  isFinanziamentoPayment: boolean = false;

  totalSteps: number[];
  trascodifiche: Trascodifica[];
  listFinanziarie: Trascodifica[];
  attivitaLavList: Trascodifica[] = [];
  
  ordine: Ordine;
  anagrafica: AnagraficaDTO;

  finanziamentoPdfHeader: { logoUrl: string, product: string, brand: string, } = {
		logoUrl: 'assets/img/loghi/zucchetti-logo_1.jpg', product: 'poltrone', brand: 'Zucchetti'}

  //utilizzata per verificare se almeno uno dei valori nelle proprietà di un oggetto è diverso da null e undefined.
  hasValues = (obj) => Object.values(obj).some(v => v !== null && typeof v !== 'undefined');

  constructor(
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private anagraficaService: AnagraficaService,
    private orderService: OrdersService,
    private formController: FormControllerService,
    private brandService: BrandService,
    private paymentService: PaymentService,
    private omaggioService: OmaggioService,
    private allegatoService: AllegatoService,
    private poltroneService: PoltronaService,
  ) { 
    this.validAnagrafica = false;
    this.form = {
      idOrder: Number(this.route.snapshot.paramMap.get('id')),
      anagrafica: new AnagraficaForm(),
      sections: {
        promo: null,
        prodotti: [],
        delProdotti: [],
        optionsPoltrone: [],
        omaggio : [new OmaggioDto()],
        pagamento: [new PagamentoDTO()],
        finanziamentoSections: {
					finanziamento: new Finanziamento(),
					finAnagrafica: new FinAnagrafica(),
					finDatiOccupazione: new FinDatiOccupazione(),
					finAnagraficaGarante: new FinAnagrafica(),
					finDatiOccupazioneGarante: new FinDatiOccupazione(),
					isAllegatoSaved: false
				},
      },
      ordine: null,
      isLoading: true
    }
    this.totalSteps = Array(8).fill(1).map((x, i) => i); // Attenzione, gestione dinamica nel metodo ( setStep() ) della parte relativa al finanziamento, che mostra lo step solo quando viene spuntato nel pagamento.
  }

  ngOnInit(): void {
    
    this.originalForm = JSON.parse(JSON.stringify(this.form));
    if (localStorage.getItem('id_client') !== null) {
      this.idCliente = Number(localStorage.getItem('id_client'));
      this.form.anagrafica.anagrafica.id = this.idCliente;
    }

    if (this.currentStep > 0) {
      $('.imgModulo').addClass('yesShow');
    } else {
      $('.imgModulo').removeClass('yesShow');
    }

    this.getOrder(this.form.idOrder);
  }

  getOrder(idOrder): void {
    this.orderService.getSingleOrder(idOrder).subscribe(res => {
      this.ordine = res;
      this.form.ordine = res;
      localStorage.setItem('id_product', res.prodottoId);

      if (res.clienteId !== undefined && res.clienteId !== null) {
        localStorage.setItem('id_client', res.clienteId);
        this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
      }

      localStorage.setItem('id_order', res.idordine);
    });

    this.brandService.getDataByProduct(Number(localStorage.getItem('id_product'))).subscribe(res => {
      this.trascodifiche = res;
      this.listFinanziarie = this.trascodifiche.filter((res: Trascodifica) => res.codice.includes(constants.CODICE_NOME_FINANZIARIE) && res.valido == true);
    });
    this.brandService.getDataByCodice(constants.CODICE_FIN_ATTIVITA_LAV).toPromise().then(res => this.attivitaLavList = res);
    this.isLoaded = true;
 }

 
  ngAfterViewInit(): void {
    console.log('origina form', this.originalForm);
  }

  ngDoCheck(): void {
    this.obj1 = JSON.stringify(this.originalForm);
    this.obj2 = JSON.stringify(this.form);
    if (this.obj1 !== this.obj2) {
      this.checkAnagrafica();
      this.originalForm = JSON.parse(JSON.stringify(this.form));
    }
  }

  saveModule(): void {
    $('body').removeClass('noScr');
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }

    if (this.currentStep === 0) {
      // Step salvataggio anagrafica
      this.anagrafica = this.form.anagrafica.anagrafica;
      if (this.anagrafica.id) {
        this.anagraficaService.update(JSON.stringify(this.anagrafica)).subscribe(res => {
          this.ordine.clienteId = res.id;
          this.ordine.cognome = res.cognome;
          this.ordine.nome = res.nome;
          this.orderService.update(JSON.stringify(this.ordine)).subscribe();
        }, error => {
          console.error('Error => ', error);
          this.openSnackBar('Errore in fase di salvataggio del modulo');
        });
      } else {
        this.anagraficaService.save(JSON.stringify(this.anagrafica)).subscribe(res => {
          this.ordine.clienteId = res.id;
          this.ordine.cognome = res.cognome;
          this.ordine.nome = res.nome;
          this.form.anagrafica.anagrafica.id = res.id;
          this.orderService.update(JSON.stringify(this.ordine)).subscribe();
      }, error => {
          console.error('Error => ', error);
          this.openSnackBar('Errore in fase di salvataggio del modulo');
      });
    }
   }else if (this.currentStep === 1) {
    this.orderService.update(JSON.stringify(this.form.ordine)).subscribe();
   }
   else if (this.currentStep === 2) {
    
      this.form.isLoading = false;

      this.orderService.update(JSON.stringify(this.form.ordine)).subscribe();
    
      const delProdotti = new Promise<void>((resolve, reject) => {
        const prodotti = this.form.sections.delProdotti;
        
        if (prodotti.length > 0) {
          const deletePromises = prodotti.map((product: any) => {
            if (product.id) {
              return this.poltroneService.delete(product.id).toPromise();
            }
            return Promise.resolve(); 
          });
    
          Promise.all(deletePromises)
            .then(() => {
              this.form.sections.delProdotti = [];
              resolve();
            })
            .catch(error => reject(error));
        } else {
          resolve();
        }
      });
    
      const saveProdotti = new Promise<void>((resolve, reject) => {
        const prodotti = this.form.sections.prodotti;
        
        if (prodotti.length > 0) {
          const savePromises = prodotti.flatMap((product: any) => 
            product.filteredProducts.map((filteredProduct: any) => {
              if (filteredProduct.coloreId) {

                if (filteredProduct.opzionePoltronas && filteredProduct.opzionePoltronas.length > 0) {
                  filteredProduct.opzionePoltronas.forEach((ele: any) => {
                    ele.tipoProdottoPoltronaId = filteredProduct.id;
                  });
                }
                
                if (filteredProduct.id) {
                  return this.poltroneService.update(filteredProduct).toPromise();
                } else {
                  return this.poltroneService.save(filteredProduct).toPromise();
                }
              }
              return Promise.resolve(); 
            })
          );
    
          Promise.all(savePromises)
            .then(() => resolve())
            .catch(error => reject(error));
        } else {
          resolve();
        }
      });
    
      Promise.all([delProdotti, saveProdotti])
        .then(() => {
          this.form.isLoading = true;
          //console.log('All operations completed successfully.');
        })
        .catch(error => {
          console.error('Errore durante il salvataggio poltrona:', error);
        });
      
  }else if (this.currentStep === 3) {
    //Step salvataggio omaggioDTO
    for (var i = 0; i < this.form.sections.omaggio.length; i++) {
      var omaggio: OmaggioDto = this.form.sections.omaggio[i];
      omaggio.ordineId = this.form.idOrder;
      if (omaggio.id === undefined || omaggio.id === null) {
        this.omaggioService.save(JSON.stringify(omaggio)).subscribe();
      } else {
        this.omaggioService.update(JSON.stringify(omaggio)).subscribe();
      }
    }
  }else if(this.currentStep === 4){
    // Step salvataggio pagamento
    if(this.hasValues(this.form.sections.pagamento[0])){
      this.form.sections.pagamento[0].ordineId = this.form.idOrder;
      const payment = this.form.sections.pagamento[0];
      if (payment.id === undefined || payment.id === null) {
        this.paymentService.save(JSON.stringify(payment)).subscribe((res:any) => {
          this.form.sections.pagamento[0] = res;
        });
      } else {
        this.paymentService.update(JSON.stringify(payment)).subscribe((res:any) => {
          this.form.sections.pagamento[0] = res;
          // se già presente un allegato finanziamento, ma cambio selezione cancella l'allegato.
          if(res.ordineId && res.tipopagsaldoDescrizione && !res.tipopagsaldoDescrizione.toUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)){
            this.allegatoService.getAllegati(res.ordineId).subscribe((allegati: any[]) => {
              allegati.forEach((allegato:any) => {
                if(allegato.tipoDescrizione.includes(constants.TYPE_DOC_FINANZIAMENTO_STEP)){
                  this.allegatoService.delete(allegato.id).subscribe();
                }
              })
            })
          }
        });
      }
    }
  }else if (this.currentStep === 5) {
    //Step salvataggio privacy
    this.orderService.update(JSON.stringify( this.form.ordine)).subscribe();
  } else if (this.currentStep === 6) {
    this.anagraficaService.update(JSON.stringify(this.form.anagrafica.anagrafica)).subscribe(res => {
      this.anagrafica = res;
      this.ordine.clienteId = res.id;
      this.ordine.cognome = res.cognome;
      this.ordine.nome = res.nome;
    }, error => {
      console.error('Error => ', error);
      this.openSnackBar('Errore in fase di salvataggio del modulo');
    });
  }
   this.setStep(this.currentStep + 1);
  }

  setStep(num): void {
    this.currentStep = num;
    if (this.currentStep > 0){
      $('.imgModulo').addClass('yesShow');
    } else {
      $('.imgModulo').removeClass('yesShow');
    }

    if(this.form.sections.pagamento[0]!.tipopagsaldoId){
      let tipoSaldo = this.formController.getTrascodificaById(this.form.sections.pagamento[0]!.tipopagsaldoId, this.trascodifiche);
      if( tipoSaldo && tipoSaldo.id && tipoSaldo.descrizione.toLocaleUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)){
        this.isFinanziamentoPayment = true;
        this.totalSteps = Array(8).fill(1).map((x, i) => i);
      }else {
        this.isFinanziamentoPayment = false;
        this.totalSteps = Array(7).fill(1).map((x, i) => i);
      }
    }

    this.scrollTop();
  }

  scrollTop(): void {
    $('html, body').animate({ scrollTop: 0 }, 300);
  }

  openSnackBar(text): void {
    this.snackbar.open(text, 'Ok', {
      duration: 4000
    });
  }

  stepController():boolean {
    let disabled = false;

    if(!this.validAnagrafica && this.currentStep === 0){
      disabled = true;
    }
    if(this.currentStep === 1 && ( this.form.sections.promo ? this.form.ordine.promoId == null : false)){
      disabled = true;
    }
    if(this.currentStep === 2 && (this.form.ordine.note && this.form.ordine.note?.length > 255)){
      disabled = true;
    }
    if(this.currentStep === 3 &&  !this.formController.validOmaggio(this.form)){
      disabled = true;
    }
    if(this.currentStep === 4 && !this.formController.validPagamento(this.form, true)){
      disabled = true;
    }
    if(this.currentStep === 5 && !this.validPrivacy()){
      disabled = true;
    }
    if(!this.validAnagrafica && this.currentStep === 6){
      disabled = true;
    }
    if (this.currentStep === 7) {
        disabled =  this.formController.validStepFinanziamento(this.form, this.attivitaLavList, this.listFinanziarie );
    }
    return disabled;
  }

  checkAnagrafica(): boolean {
    this.validAnagrafica = this.formController.checkAnagrafica(this.form);
    return this.validAnagrafica;
  }

  validPrivacy(): boolean {
    if(this.form.ordine === undefined || this.form.ordine === null){
     return false;
    } 
     if (!this.form.ordine.accettprivacy || !this.form.ordine.accettprivacynd || !this.form.ordine.accettprivacyrd) {
       return false;
     } else
       return true;
   }

   returnIdOrdine(): string {
    return localStorage.getItem('id_order');
  }

  togglePrintPreview(show: boolean): void {
    this.showModalPrint = show;
    jQuery('#printModal').modal({ backdrop: 'static', keyboard: false });
    jQuery('#printModal').modal('toggle');
  }

  startTimer(): any {
    const intervalId = setInterval(() => {
      if (this.timeLeft == 0 ){
    clearInterval(intervalId);
    // richiamo api per invio email
    this.orderService.sendEmail(this.form.idOrder).subscribe();
    jQuery('#timeoutModal').modal({backdrop: 'static', keyboard: false });
    jQuery('#timeoutModal').modal('toggle');

    setTimeout(function(){
      jQuery('#timeoutModal').modal('hide');
    }, 10000);

    setTimeout( () => {
           this.formController.goToDashboard();
      }, 11000);
    }
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }
    }, 1000);
    return intervalId;
  }

	edit() {
		this.currentStep = 0;
		this.openSign = false;
		this.orderService.getSingleOrder(this.form.idOrder).subscribe((res) => {
			res.statopraticaCodice = "A";
			res.statopraticaId = 2;
			this.orderService.update(JSON.stringify(res)).subscribe( res => {
				this.getOrder(this.form.idOrder);
			});
		})
	}

  reload(value: boolean) {
    this.openSign = value;
  }
}