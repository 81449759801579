import { Component, Input, OnInit } from '@angular/core';
import { AccessoriAllarmeDto } from 'src/app/modules/classes/allarme/accessoriAllarme.dto';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { AccessoriAllarmeService } from 'src/app/services/accessori-allarme/accessori-allarme.service';

@Component({
  selector: 'app-accessori-allarme',
  templateUrl: './accessori-allarme.component.html',
  styleUrls: ['./accessori-allarme.component.scss']
})
export class AccessoriAllarmeComponent implements OnInit {

  @Input() form: any;
  @Input() trascodificheAcc: Trascodifica[];

  orderId: number;
  accessori: AccessoriAllarmeDto[] = [];

  constructor(
    private accessoriAllarmeService: AccessoriAllarmeService
  ) { }

  ngOnInit(): void {
    this.orderId = this.form.idOrder;
    this.form.sections.accessoriAllarme = [];
    this.accessoriAllarmeService.findByKey(this.form.idOrder, 'ordineId.equals=').subscribe((res: AccessoriAllarmeDto[]) => {
      if (res && res.length > 0) {
        this.form.sections.accessoriAllarme = res;
      }
    })
  }

  updateQuantita(acc: AccessoriAllarmeDto, quantity: number): void {
    acc.qta = quantity;
  }

  getDescByTrascId(trascodificaId: number): string {
    return this.trascodificheAcc.find(el => el.id == trascodificaId)?.descrizione;
  }

  alreadySelected(value): boolean {
    const found = this.form.sections.accessoriAllarme.findIndex(r => r.id === value);
    return found !== - 1;
  }

  addAccessori(): void {
    this.form.sections.accessoriAllarme.push(new AccessoriAllarmeDto());
  }

  removeItem(i): void {
    if (this.form.sections.accessoriAllarme[i].id !== undefined) {
      this.accessoriAllarmeService.delete(this.form.sections.accessoriAllarme[i].id).subscribe();
    }
    this.form.sections.accessoriAllarme.splice(i, 1);
  }

  checkItem(event, i): void {
    if (event == null) {
      this.form.sections.accessoriAllarme[i] = new AccessoriAllarmeDto();
    }
  }
}
