import { Component } from '@angular/core';
import * as $ from 'jquery';
import {
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
declare const gtag: Function;
import { environment } from '../environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'order';

  constructor(
      public router: Router,
      private location: LocationStrategy
  ) {

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      /** START : Code to Track Page View  */
      gtag('event', 'page_view', {
        page_path: event.urlAfterRedirects
      });
      /** END */
    });

    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        $('body').removeClass('noScr');
        if (document.fullscreenElement) {
          document.exitFullscreen();
        }
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });
  }


}
