import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-remail-main',
  templateUrl: './remail-main.component.html',
  styleUrls: ['./remail-main.component.scss']
})
export class RemailMainComponent implements OnInit {

  constructor(private router: Router,) { }

  ngOnInit(): void {
  }

  goToProduct(value): void {
    this.router.navigate(['new-order/REAMAIL/' + value]);
  }

}
