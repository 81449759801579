<div id="headingOne"  class="text-center blueBg">
    <h5 class="mb-0 row">
        <div class="col-6 text-right">
            NUOVO BOX DOCCIA
            <mat-hint class="text-danger">*</mat-hint>
        </div>    
        <div class="col-6 text-left"><mat-radio-group labelPosition="after" class="inline" color="primary"
            [(ngModel)]="optionNuovoBoxDoccia.selected"
            (ngModelChange)="showDiv($event)">
            <mat-radio-button class="mb-2 mr-2" [value]="true">SI</mat-radio-button>
            <mat-radio-button class="mb-2 mr-2" [value]="false">NO</mat-radio-button>
        </mat-radio-group></div>            
    </h5>
</div>


<div class="blueCont" [ngClass]="{'onlyRedBorder': !validNuoviBox()}" *ngIf="isVisible">
    <div class="row" *ngFor="let element of form.sections.nuoviBox; let i = index;">
        <div class="col-3">
            <mat-form-field class="full-width">
                <mat-label>Modello</mat-label>
                <mat-select [(ngModel)]="element.modello.id" (ngModelChange)="checkModel($event, i)">
                    <mat-option>-- Modello --</mat-option>
                    <mat-option *ngFor="let model of boxes" [value]="model.id">{{model.descrizione}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-3" *ngIf="element.modello.id">
            <mat-form-field class="full-width">
                <mat-label>Cristalli</mat-label>
                <mat-select [(ngModel)]="element.cristalli.id">
                    <mat-option [value]="null">-- Cristallo --</mat-option>
                    <mat-option *ngFor="let cristallo of element.listCristalli" [value]="cristallo.id">
                        {{cristallo.descrizione}}</mat-option>
                </mat-select>
                <mat-hint class="text-danger" *ngIf="element.modello.id && !element.cristalli.id">
                    Campo obbligatorio
                </mat-hint>
            </mat-form-field>
        </div>
        <div class="col-3" *ngIf="element.cristalli.id">
            <mat-form-field class="full-width">
                <mat-label>Profilo</mat-label>
                <mat-select [(ngModel)]="element.profilo.id">
                    <mat-option [value]="null">-- Profilo --</mat-option>
                    <mat-option *ngFor="let profilo of element.listProfili" [value]="profilo.id">
                        {{profilo.descrizione}}</mat-option>
                </mat-select>
                <mat-hint class="text-danger" *ngIf="element.cristalli.id && !element.profilo.id">
                    Campo obbligatorio
                </mat-hint>
            </mat-form-field>
        </div>
        <div class="col-2" *ngIf="element.profilo.id">
            <mat-form-field class="full-width">
                <mat-label>Larghezza</mat-label>
                <mat-select [(ngModel)]="element.larghezza.id">
                    <mat-option>-- Larghezza --</mat-option>
                    <mat-option *ngFor="let larghezza of element.listLarghezze" [value]="larghezza.id">
                        {{larghezza.descrizione}}</mat-option>
                </mat-select>
                <mat-hint class="text-danger" *ngIf="element.profilo.id && !element.larghezza.id">
                    Campo obbligatorio
                </mat-hint>
            </mat-form-field>
        </div>

        <div class="col-4" *ngIf="element.modello.id && element.larghezza.id">
            <div class="mb-2">Ingresso</div>
            <mat-radio-group aria-labelledby="example-radio-group-label"
                [(ngModel)]="element.ingresso.id" color="primary">
                <mat-radio-button class="d-inline-block mr-2" *ngFor="let model of element.listIngressi"
                    [value]="model.id">
                    {{model.descrizione}}
                </mat-radio-button>
                <mat-hint class="text-danger" *ngIf="element.modello.id && !element.ingresso.id">
                  Campo obbligatorio
              </mat-hint>
            </mat-radio-group>

        </div>


        <div class="col-1 align-self-center" *ngIf="form.sections.nuoviBox.length >= 1">
            <button mat-icon-button color="warn" (click)="removeModel(i)">
                <mat-icon>cancel</mat-icon>
            </button>
        </div>
    </div>
    <div>
        <button mat-flat-button class="azur" (click)="addBox()">Aggiungi box</button>
    </div>


</div>