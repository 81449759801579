<div class="row">
    <div class="col-12">
        <div class="text-center blueBg">
            VASCA SELEZIONATA
        </div>
        <div class="blueCont">
            <div class="row" *ngIf="form.sections.vascaVenezia.dimensions">
                <div class="col-3">
                    VASCA NOVA {{returnDescription('VENEZIA_MISURA',form.sections.vascaVenezia.dimensions)}}
                </div>
                <div class="col-3">
                    <div *ngIf="form.sections.vascaVenezia.hydro">Con idromassaggio</div>
                </div>
                <div class="col-3"></div>
                <div class="col-3"></div>
            </div>

            <div class="row" *ngIf="form.sections.vascaEcube.dimensions">
                <div class="col-3">
                    VASCA ECUBE {{returnDescription('ECUBE_MISURA',form.sections.vascaEcube.dimensions)}}
                </div>
                <div class="col-3">
                    <div *ngIf="form.sections.vascaEcube.seduta">{{form.sections.vascaEcube.seduta}}</div>
                </div>
                <div class="col-3"></div>
                <div class="col-3"></div>
            </div>

            <div class="row" *ngIf="form.sections.vascaRoma.dimensions">
                <div class="col-3">
                    VASCA EXPRESS {{returnDescription('ROMA_MISURA',form.sections.vascaRoma.dimensions)}}
                </div>
                <div class="col-3">
                    <div *ngIf="form.sections.vascaRoma.seduta">{{form.sections.vascaRoma.seduta}}</div>
                </div>
                <div class="col-3"></div>
                <div class="col-3"></div>
            </div>

            <div class="row" *ngIf="form.sections.vascaVerona.dimensions">
                <div class="col-3">
                    VASCA OPEN {{returnDescription('VERONA_MISURA',form.sections.vascaVerona.dimensions)}}
                </div>
                <div class="col-3">
                    <div *ngIf="form.sections.vascaVerona.scarico">{{form.sections.vascaVerona.scarico}}</div>
                </div>
                <div class="col-3">
                    <div *ngIf="form.sections.vascaVerona.sportello">{{returnDescription('VERONA_SPORTELLO',form.sections.vascaVerona.sportello)}}</div>
                </div>
                <div class="col-3"><div *ngIf="form.sections.vascaVerona.seduta && form.sections.vascaVerona.seduta.length > 0">{{form.sections.vascaVerona.seduta}}</div></div>
            </div>

            <div class="row" *ngIf="form.sections.vascaFirenze.dimensions">
                <div class="col-3">
                    VASCA EMOTION {{returnDescription('FIRENZE_MISURA',form.sections.vascaFirenze.dimensions)}}
                </div>
                <div class="col-3">
                    <div *ngIf="form.sections.vascaFirenze.sportello">{{returnDescription('FIRENZE_SPORTELLO',form.sections.vascaFirenze.sportello)}}</div>
                </div>
                <div class="col-3">
                    <div>
							<span class="mr-2" *ngIf="form.sections.vascaFirenze.firenzepannellosx">
								<span class="material-icons" style="vertical-align:middle;color: #000">task_alt</span> Pannello Sx
							</span>
                        <span class="mr-2" *ngIf="form.sections.vascaFirenze.firenzepannellocentro">
								<span class="material-icons" style="vertical-align:middle;color: #000">task_alt</span> Pannello Dx
							</span>
                        <span class="mr-2" *ngIf="form.sections.vascaFirenze.firenzepannellodx">
								<span class="material-icons" style="vertical-align:middle;color: #000">task_alt</span> Pannello Dx
							</span>
                    </div>

                    <div>
							<span *ngIf="!form.sections.vascaFirenze.firenzepannelloattr" class="material-icons"
                                  style="color: #000"> radio_button_unchecked</span>
                        <span *ngIf="form.sections.vascaFirenze.firenzepannelloattr" class="material-icons"
                              style="color: #000">task_alt</span> Pannello attrezzato
                    </div>
                    <div>
                        <span *ngIf="form.sections.vascaFirenze.misurapassaggioporta">Misura passaggio porta cm: {{form.sections.vascaFirenze.misurapassaggioporta}}</span>
                    </div>
                </div>
                <div class="col-3">
                    <div *ngIf="form.sections.vascaFirenze.hydro">Con idromassaggio</div>
                </div>
            </div>
        </div>
    </div>
</div>
