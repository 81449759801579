import { Component, OnInit,Input } from '@angular/core';
import { Trascodifica } from '../../../../../classes/trascodifica';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';
import { CristalBoxService } from '../../../services/cristalbox.service';
@Component({
  selector: 'cristalbox-miscelatori',
  templateUrl: './miscelatori.component.html',
  styleUrls: ['./miscelatori.component.scss']
})
export class CristalBoxMiscelatoriComponent implements OnInit {

  @Input() form:any;
  @Input() trascodifiche:Trascodifica[];
  doccia:Trascodifica[];
  vasca:Trascodifica[];
  lavabo:Trascodifica[];
  bidet:Trascodifica[];

  constructor(private formController: FormControllerService,private cristalBoxService : CristalBoxService) { }

  ngOnInit(): void {
	
		this.cristalBoxService.findBykey('miscelatores' , 'ordineId.equals=',this.form.idOrder ,).subscribe(res => {
			if(res[0] !== undefined){
				this.form.sections.miscelatore = res[0];
			}
			this.doccia = this.formController.getTrascodificaByCodice('MISC_DOCCIA',this.trascodifiche);
			this.vasca = this.formController.getTrascodificaByCodice('MISC_VASCA' , this.trascodifiche);	
			this.lavabo = this.formController.getTrascodificaByCodice('MISC_LAVABO',this.trascodifiche);
			this.bidet = this.formController.getTrascodificaByCodice('MISC_BIDET' , this.trascodifiche);
		});
		

  }

}
