import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpService } from '../http.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class BrandService extends HttpService{

  constructor(
      public router: Router,
      public http: HttpClient
  ) {
    super(router, http);
  }

  getBrands(): Observable<any> {
    return this.http
               .get<any>(this.apiUrl + 'brands', this.setHttpOptions())
               .pipe(
                   retry(0),
                   catchError(this.handleError)
               );
  }

  getDataByProduct(id:number): Observable<any> {
    return this.http
               .get<any>(this.apiUrl + 'trascodificas?prodottoId.equals='+id+'&size=2000&valido.equals=true&sort=ordinamento', this.setHttpOptions())
               .pipe(
                   retry(0),
                   catchError(this.handleError)
               );
  }
  
  getDataByProductStufe(codice: string, id: number): Observable<any> {
    return this.http
               .get<any>(this.apiUrl + 'trascodificas?codice.equals=' + codice + '&prodottoId.equals=' + id + '&size=1000&sort=ordinamento', this.setHttpOptions())
               .pipe(
                   retry(0),
                   catchError(this.handleError)
               );
  }

  getDataByProductCaldaie(codice: string, id: number): Observable<any> {
    return this.http
               .get<any>(this.apiUrl + 'trascodificas?codice.equals=' + codice + '&prodottoId.equals=' + id + '&size=1000&sort=ordinamento', this.setHttpOptions())
               .pipe(
                   retry(0),
                   catchError(this.handleError)
               );
  }


  getDataByCodice(code:string): Observable<any> {
    return this.http
               .get<any>(this.apiUrl + 'trascodificas?codice.equals='+code+'&size=2000&valido.equals=true&sort=ordinamento', this.setHttpOptions())
               .pipe(
                   retry(0),
                   catchError(this.handleError)
               );
  }
  


}
