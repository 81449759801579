<div id="headingOne">
    <h5 class="mb-0">

        <div class="text-center blueBg">
            NUOVO PIATTO DOCCIA<br />
            PIATTO A BORDO STRETTO<br />
            in metacrilato colato con rinforzo in vetroresina COLORE BIANCO
        </div>

    </h5>
</div>

<div class="blueCont">
    <div class="">
        <p>Dimensione</p>
        <div class="d-inline-block">
            <mat-radio-group labelPosition="after" class="d-block" color="primary" aria-label="Tipo"
                [(ngModel)]="form.sections.piattoDoccia.stretto.id">
                <mat-radio-button class="mr-2" [value]="misure.id" *ngFor="let misure of listMisure">
                    {{misure.descrizione}}</mat-radio-button>
                <!-- <mat-radio-button class="mr-2" value="DIS.75X75.BS">75x75 (riseg. 5/5/5 cm)*</mat-radio-button>
                            <mat-radio-button class="mr-2" value="DIS.75X90.BS">75x90 (riseg. 5/5/5 cm)*</mat-radio-button>
                            <mat-radio-button class="mr-2" value="DIS.80X80.BS">80x80 (riseg. 5/5/5 cm)*</mat-radio-button>
                            <mat-radio-button class="mr-2" value="DIS.90X90.BS">90x90 (riseg. 5/5/5 cm)*</mat-radio-button>
                            <mat-radio-button class="mr-2" value="DIS.120X75.BS">120X75 (riseg. 7/7/7 cm)*</mat-radio-button> -->
                <span class="undo material-icons" matTooltip="Cancella selezione"
                    *ngIf="form.sections.piattoDoccia.stretto.id" (click)="form.sections.piattoDoccia.stretto = {}">
                    backspace
                </span>
            </mat-radio-group>
        </div>

    </div>


</div>

<div class="text-danger smallFont">
    * Tagli massimi dei bordi per lato (profondità, lunghezza sinistra, lunghezza destra) solo contro i muri. I nostri
    piatti
    doccia sono strutturati per essere tagliati in opera ed adattarsi alla misura della vasca.
</div>