export class SignerDTO {
    // Define properties here
    info: InfoDTO;
    signature_level: string;
    signature_authentication_mode: string;
    fields: DocumentDTO[];
    delivery_mode: string;
    constructor(info: InfoDTO, signature_level: string, signature_authentication_mode: string, fields: DocumentDTO[], delivery_mode: string) {
        this.info = info;
        this.signature_level = signature_level;
        this.signature_authentication_mode = signature_authentication_mode;
        this.fields = fields;
        this.delivery_mode = delivery_mode;
    }


}

export class InfoDTO {
    // Define properties here
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    locale: string;

    constructor(first_name: string, last_name: string, email: string, phone_number: string, locale: string) {
        this.first_name = first_name;
        this.last_name = last_name;
        this.email = email;
        this.phone_number = phone_number;
        this.locale = locale;
    }
}

export class DocumentDTO {
    // Define properties here
    document_id: string;
    type: string;
    page: number;
    width: number;
    height: number;
    x: number;
    y: number;

    constructor(document_id: string, type: string, page: number, x: number, y: number, width: number, height: number) {
        this.document_id = document_id;
        this.type = type;
        this.page = page;
        this.width = width;
        this.height = height;
        this.x = x;
        this.y = y;
    }

}

export class WebhookDTO {
    // Define properties here
    sandbox: boolean;
    auto_retry: boolean;
    enabled: boolean;
    subscribed_events: string[];
    scopes: string[];
    endpoint: string;
    description: string;

    constructor(sandbox: boolean, auto_retry: boolean, enabled: boolean, subscribed_events: string[], scopes: string[], endpoint: string, description: string) {
        this.sandbox = sandbox;
        this.auto_retry = auto_retry;
        this.enabled = enabled;
        this.subscribed_events = subscribed_events;
        this.scopes = scopes;
        this.endpoint = endpoint;
        this.description = description;
    }
}