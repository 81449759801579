import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from '../../../../classes/trascodifica';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
@Component({
  selector: 'app-riepilogo-vasche',
  templateUrl: './riepilogo-vasche.component.html',
  styleUrls: ['./riepilogo-vasche.component.scss']
})
export class RiepilogoVascheComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche : Trascodifica[];

  vasche: any;
  codes: any;

  constructor(
    private formController : FormControllerService
  ) {
    this.vasche = [
      {
        Cd_AR: 'VASCA.ROMA.102X65',
        Descrizione: 'VASCA ROMA 102X65'
      },
      {
        Cd_AR: 'VASCA.ROMA.105X65',
        Descrizione: 'VASCA ROMA 105X65'
      },
      {
        Cd_AR: 'VASCA.ROMA.105X70',
        Descrizione: 'VASCA ROMA 105x70'
      },
      {
        Cd_AR: 'VASCA.ROMA.120X70',
        Descrizione: 'VASCA ROMA 120x70'
      },
      {
        Cd_AR: 'VASCA.ROMA.120X75',
        Descrizione: 'VASCA ROMA 120X75'
      },
      {
        Cd_AR: 'VASCA.ROMA.140X65',
        Descrizione: 'VASCA ROMA 140x65'
      },
      {
        Cd_AR: 'VASCA.ROMA.140X70',
        Descrizione: 'VASCA ROMA 140x70'
      },
      {
        Cd_AR: 'VASCA.ROMA.150X70',
        Descrizione: 'VASCA ROMA 150x70'
      },
      {
        Cd_AR: 'VASCA.ROMA.155X65',
        Descrizione: 'VASCA ROMA 155X65'
      },
      {
        Cd_AR: 'VASCA.ROMA.160X65',
        Descrizione: 'VASCA ROMA 160x65'
      },
      {
        Cd_AR: 'VASCA.ROMA.160X70',
        Descrizione: 'VASCA ROMA 160x70'
      },
      {
        Cd_AR: 'VASCA.ROMA.170X70',
        Descrizione: 'VASCA ROMA 170x70'
      },
      {
        Cd_AR: 'VASCA.ROMA.172X77',
        Descrizione: 'VASCA ROMA 172X77'
      },
      {
        Cd_AR: 'VASCA.VERONA.120X70',
        Descrizione: 'VASCA VERONA 120X70'
      },
      {
        Cd_AR: 'VASCA.VERONA.140X65',
        Descrizione: 'VASCA VERONA 140X65'
      },
      {
        Cd_AR: 'VASCA.VERONA.140X70',
        Descrizione: 'VASCA VERONA 140X70'
      },
      {
        Cd_AR: 'VASCA.VERONA.150X70',
        Descrizione: 'VASCA VERONA 150X70'
      },
      {
        Cd_AR: 'VASCA.VERONA.160X65',
        Descrizione: 'VASCA VERONA 160X65'
      },
      {
        Cd_AR: 'VASCA.VERONA.160X70',
        Descrizione: 'VASCA VERONA 160X70'
      },
      {
        Cd_AR: 'VASCA.VERONA.170X70',
        Descrizione: 'VASCA VERONA 170X70'
      },
      {
        Cd_AR: 'NOV.170X70',
        Descrizione: 'Vasca VENEZIA 170x70 classica'
      },
      {
        Cd_AR: 'NOV.170X70IDRO',
        Descrizione: 'Vasca VENEZIA 170x70 con idromassaggio'
      },
      {
        Cd_AR: 'NOV.180X80',
        Descrizione: 'Vasca VENEZIA 180x80 classica'
      },
      {
        Cd_AR: 'NOV.180X80IDRO',
        Descrizione: 'Vasca VENEZIA 180x80 con idromassaggio'
      },
      {
        Cd_AR: 'VASCA.BUS1',
        Descrizione: 'VASCA FIRENZE 100x70 con paratia interna e miscelatore sopravasca'
      },
      {
        Cd_AR: 'VASCA.BUS2',
        Descrizione: 'VASCA FIRENZE 120x70 con paratia interna e miscelatore sopravasca'
      },
      {
        Cd_AR: 'VASCA.BUS3',
        Descrizione: 'VASCA FIRENZE 150x70 con paratia interna e miscelatore sopravasca'
      },
      {
        Cd_AR: 'VASCA.BUS4',
        Descrizione: 'VASCA FIRENZE 170x70 con paratia interna e miscelatore sopravasca'
      }
    ];

    this.codes = {
      IDROMASSAGGIOSI: 'Idromassaggio',
      IDROMASSAGGION: 'No idromassaggio',
      IDRO: 'Idromassaggio',
      SEDUTASX: 'Seduta a sinistra',
      SEDUTADX: 'Seduta a destra',
      SCARICOSX: 'Scarico a sinistra',
      SCARICODX: 'Scarico a destra',
      SPORTELLOSX: 'Sportello a sinistra',
      SPORTELLODX: 'Sportello a destra',
      SPORTELLOCENTRO: 'Sportello al centro',
      PANNELLISX: 'Pannelli a sinistra',
      PANNELLIDX: 'Pannelli a destra',
      PANNELLIN: 'Nessun pannello',
    };
  }

  ngOnInit(): void {
	
  }
  
  returnDescription(type:string,value:number): string {
	  let res = '';
	  var tras = 	this.formController.getTrascodificaByCodice(type , this.trascodifiche).filter(tr => tr.id === value);
	  if(tras.length > 0)
		  res = tras[0].descrizione;
	  return res	
  }
}
