<div class="loginPage">

    <div class="loginContainer">
        <div class="text-center mb-4">
            <img class="mb-3" alt="logo" title="remail" src="/assets/img/gdl.png">
            <div>
                <div *ngIf="loading || sending" class="text-center">
                    <p>Caricamento...</p>
                    <mat-progress-bar color="warn" mode="indeterminate"></mat-progress-bar>
                </div>
                <div *ngIf="!loading && errorKey && !errorSend">
                    <div class="mb-2">Il link di attivazione non è valido, ti preghiamo di riprovare.</div>
                    <hr/>
                    <div class="text-center">
                        <button (click)="goToRegistration()" mat-flat-button color="warn">
                            Registrati
                        </button>
                    </div>
                </div>
                <div *ngIf="!loading && errorSend">
                    <div class="mb-2">Si è verificato un problema, ti preghiamo di riprovare.</div>
                    <hr/>
                    <div class="text-center">
                        <button (click)="goToRegistration()" mat-flat-button color="warn">
                            Registrati
                        </button>
                    </div>
                </div>
                <div *ngIf="!loading && success">
                    <div class="mb-2">
                        Utente attivato correttamente.<br/>
                        Sarai reindirizzato alla pagina di login.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
