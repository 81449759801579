export class Allegato {
	id:number;
	ordineId:number;
	path:string;
	tipoDescrizione:string;
	tipoId:number;
	content:string;
	gdlId:string;
	name:string;
	dataCreazione:Date;
	size:number;
	originalName:string;
}
