import { Component, Input, OnInit } from '@angular/core';
import { AccessoriDepuratoreDto } from 'src/app/modules/classes/accessori-depuratore-dto';
import { constants } from 'src/app/modules/classes/constants';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { AccessoriDepuratoreService } from 'src/app/services/accessori-depuratore/accessori-depuratore.service';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
@Component({
  selector: 'app-accessori-depuratore',
  templateUrl: './accessori-depuratore.component.html',
  styleUrls: ['./accessori-depuratore.component.scss']
})
export class AccessoriDepuratoreComponent implements OnInit {

  @Input() form: any;
  @Input() trascodificheAcc: Trascodifica[];
  @Input() trascodificheAccGasatore: Trascodifica[];
  @Input() allTrascodifiche: Trascodifica[];

  orderId: number;
  accessori: AccessoriDepuratoreDto[] = [];
  frigoGasatoreTrascParent: Trascodifica[];
  cloneTrascodificheAcc: Trascodifica[];
  trascodificheAccGas: Trascodifica[];
  gasatoreAcc: Trascodifica;

  constructor(
    public formController: FormControllerService,
    private accessoriDepuratore: AccessoriDepuratoreService
  ) { }

  ngOnInit(): void {
    this.orderId = this.form.idOrder;

    this.cloneTrascodificheAcc = this.trascodificheAcc;
    
    const frigoGasatoreSI = this.allTrascodifiche.find(el => el.codice === constants.ACCESSORI_DEPURATORE_PARENT && el.descrizione.toUpperCase().includes('SI'));
    const frigoGasatoreNO = this.allTrascodifiche.find(el => el.codice === constants.ACCESSORI_DEPURATORE_PARENT && el.descrizione.toUpperCase().includes('NO'));

    if (!frigoGasatoreSI || !frigoGasatoreNO) {
        return;
    }

    this.accessoriDepuratore.findByKey(this.form.idOrder, 'ordineId.equals=').subscribe((res: AccessoriDepuratoreDto[]) => {
      if (res && res.length > 0) {
          let allSI = true;
          let allNO = true; 
          let integrateAccDepuratore;

          res.filter((dato: AccessoriDepuratoreDto) => {

              const trascodifica = this.trascodificheAcc.find(el => el.id === dato.tipologiaId);
  
              if (trascodifica) {
  
                  if (trascodifica.parentId === frigoGasatoreSI.id) {
                      allNO = false;
                  } else if (trascodifica.parentId === frigoGasatoreNO.id) {
                      allSI = false; 
                  }
              }
          });
  
          if (allSI) {
            let savedAccessoriDepuratore = res.filter(dato => 
              this.getDescByTrascId(dato.tipologiaId) && this.trascodificheAcc.some(el => el.id === dato.tipologiaId && el.parentId === frigoGasatoreSI.id)
            );
      
            let unsavedAccessoriDepuratore = this.trascodificheAcc.filter(el =>
              el.parentId === frigoGasatoreSI.id && !savedAccessoriDepuratore.some(dato => dato.tipologiaId === el.id)
            );
      
            integrateAccDepuratore = [...savedAccessoriDepuratore, ...unsavedAccessoriDepuratore.map(el => {
              return { ...new AccessoriDepuratoreDto(), ordineId: this.orderId, tipologiaId: el.id };
            })];
      
            integrateAccDepuratore.sort((a, b) => a.tipologiaId - b.tipologiaId);
            this.form.sections.accessoriDepuratore = integrateAccDepuratore;

            this.form.sections.isFrigoGasatore = true;

          } else if (allNO) {

            let savedAccessoriDepuratore = res.filter(dato => this.getDescByTrascId(dato.tipologiaId));
            
            let unsavedAccessoriDepuratore = this.trascodificheAcc.filter(el => 
              el.parentId === frigoGasatoreNO.id && !savedAccessoriDepuratore.some(dato => dato.tipologiaId === el.id)
            );
          
            integrateAccDepuratore = [...savedAccessoriDepuratore, ...unsavedAccessoriDepuratore.map(el => {
              return { ...new AccessoriDepuratoreDto(), ordineId: this.orderId, tipologiaId: el.id };
            })];
          
            this.form.sections.isFrigoGasatore = false;
          } 
  
          integrateAccDepuratore.sort((a, b) => a.tipologiaId - b.tipologiaId);
          this.form.sections.accessoriDepuratore = integrateAccDepuratore;
          
        } else {
            this.form.sections.isFrigoGasatore = false;
            this.trascodificheAcc = this.trascodificheAcc.filter(el => el.parentId !== frigoGasatoreSI.id);
            this.form.sections.accessoriDepuratore = this.trascodificheAcc.map(el => {
                return { ...new AccessoriDepuratoreDto(), ordineId: this.orderId, tipologiaId: el.id };
            });
        }
    });
  }

  updateQuantita(acc: AccessoriDepuratoreDto, quantity: number): void {
    acc.quantita = quantity;
  }

  getDescByTrascId(trascodificaId: number): string {
    return this.trascodificheAcc.find(el => el.id == trascodificaId)?.descrizione;
  }

  getDescByTrascIdAccGas(trascodificaId: number): string {
    return this.trascodificheAccGasatore.find(el => el.id == trascodificaId)?.descrizione;
  }
  
  changeRubinetti(isFrigoGasatoreChose:boolean): void {
    const frigoGasatoreSI = this.allTrascodifiche.find(el => el.codice === constants.ACCESSORI_DEPURATORE_PARENT && el.descrizione.toUpperCase().includes('SI'));
    const frigoGasatoreNO = this.allTrascodifiche.find(el => el.codice === constants.ACCESSORI_DEPURATORE_PARENT && el.descrizione.toUpperCase().includes('NO'));

    if(this.form.sections.accessoriDepuratore && this.form.sections.accessoriDepuratore.length > 0){
      this.form.sections.accessoriDepuratore.forEach((el:any) => {
        if(el.id){
          this.accessoriDepuratore.delete(el.id).subscribe()
        }
      })

      this.form.sections.accessoriDepuratore = [];
    }

    if(isFrigoGasatoreChose){
      this.trascodificheAcc = this.cloneTrascodificheAcc.filter(el => el.parentId == frigoGasatoreSI.id);
      this.form.sections.accessoriDepuratore = this.trascodificheAcc.map(el => {
          return { ...new AccessoriDepuratoreDto(), ordineId: this.orderId, tipologiaId: el.id };
      });
    }
    
    if(!isFrigoGasatoreChose){
      this.trascodificheAcc = this.cloneTrascodificheAcc.filter(el => el.parentId == frigoGasatoreNO.id);
      this.form.sections.accessoriDepuratore = this.trascodificheAcc.map(el => {
          return { ...new AccessoriDepuratoreDto(), ordineId: this.orderId, tipologiaId: el.id };
      });
    }

  }
}