import { Component } from '@angular/core';
import { FinanziamentoPrintService } from 'src/app/services/finanziamento-print/finanziamento-print.service';
import { FinAnagrafica, Finanziamento, FinDatiOccupazione } from 'src/app/modules/classes/finanziamento/finanziamento-dto';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import autoTable from 'jspdf-autotable'
import jsPDF from "jspdf";

@Component({
  selector: 'app-finanziamento-print',
  templateUrl: './finanziamento-print.component.html',
  styleUrls: ['./finanziamento-print.component.scss']
})
export class FinanziamentoPrintComponent {

  doc: jsPDF;
  creatingPdf:boolean = false;
  currentPdfPage:number = 1;
  pageNumber:number = 1;
  pageWidth:number = 297;
  yStartPage:number = 28; //dopo l'header
  img: string;
  title: string;
  ordine: any;
  pagamento: any;
  brand: string;

  color: number[];
 
  constructor(
          public finanziamentoPrintService: FinanziamentoPrintService,
          public formController: FormControllerService, 
        ) { }

  initPdf( img: string, title: string, brand?:string, product?:string): number {
    this.creatingPdf = true;

    this.finanziamentoPrintService.setCurrentPage(this.pageNumber);
    this.doc = new jsPDF();
    this.pageWidth = this.doc.internal.pageSize.getWidth();

    this.img = img;
    this.title = title;
    this.brand = brand;
    this.finanziamentoPrintService.createHeader(this.doc, this.img, this.title, this.brand, product);
    
    let y = this.yStartPage;
    
    return y;
  }


  createLine(y: number): number {
    y = this.finanziamentoPrintService.addLine(
      y,
      this.doc,
      this.ordine,
      this.img,
      this.title
    );
    this.finanziamentoPrintService.setCurrentY(y);
    return y;
  }

  genericTableFinanziariaSelected(doc?: any, idOrder?:string, finanziamento?: Finanziamento, y?:number, color?:string, finanziarieList?: Trascodifica[]) : void{
    
    let lastCellY;
    let isTableDrawn = false; 
    let isTableStartedNewPage = false; 

    const rows = [
      [['NOME'], [this.formController.getTrascodificaById(finanziamento.finanziariaId, finanziarieList).descrizione]],
    ]
    
    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: 'FINANZIARIA SELEZIONATA', colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: this.finanziamentoPrintService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
    },
    didDrawPage: (data: any) => {
      this.finanziamentoPrintService.createHeader(this.doc, this.img, this.title, this.brand);
        this.finanziamentoPrintService.writeFooterPage(doc, idOrder, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
            isTableDrawn = true; 
            isTableStartedNewPage = false; 
        } else {
            isTableStartedNewPage = true; 
            this.currentPdfPage++;
        }
    },
  });
     y = doc.lastAutoTable.finalY + 10
    this.finanziamentoPrintService.setCurrentY(y)
  }

  genericTableDatiAnagrafici(doc?: any, idOrder?:string, anagrafica?: FinAnagrafica, y?:number, color?:string, trascStato?: Trascodifica[]) : void{
    
    let lastCellY;
    let isTableDrawn = false; 
    let isTableStartedNewPage = false; 

    const rows = [
      [['CLIENTE'], [anagrafica?.nome + ' ' + anagrafica?.cognome]],
      [[anagrafica.codicefiscale.length === 16 ? 'CODICE FISCALE' : 'P.IVA'], [anagrafica.codicefiscale]],
      [['SESSO'], [anagrafica.sesso]],
      [['DATA DI NASCITA'], [this.finanziamentoPrintService.formatDate(anagrafica.datanascita)]],
      [['CITTA DI NASCITA'], [anagrafica.comunenascita]],
      [['STATO CIVILE'], [this.formController.getTrascodificaById(anagrafica.statocivileId, trascStato).descrizione]],
    ]
    
    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: 'DATI ANAGRAFICI', colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: this.finanziamentoPrintService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
    },
    didDrawPage: (data: any) => {
      this.finanziamentoPrintService.createHeader(this.doc, this.img, this.title, this.brand); 
        this.finanziamentoPrintService.writeFooterPage(doc, idOrder, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
            isTableDrawn = true; 
            isTableStartedNewPage = false; 
        } else {
            isTableStartedNewPage = true; 
            this.currentPdfPage++;
        }
    },
  });
     y = doc.lastAutoTable.finalY + 10
    this.finanziamentoPrintService.setCurrentY(y)
  }

  genericTableDatiResidenza(doc?: any, idOrder?:string, anagrafica?: FinAnagrafica, y?:number, color?:string, trascTipoAbi?: Trascodifica[]) : void{
    
    let lastCellY;
    let isTableDrawn = false; 
    let isTableStartedNewPage = false; 

    const rows = [
      [['INDIRIZZO'], ['via ' + anagrafica?.via + ' ,' + anagrafica?.civico]],
      [['CITTA'], [anagrafica.comune + ', ' + anagrafica.cap + ', ' + anagrafica.provincia]],
      [['TIPO ABITAZIONE'], [this.formController.getTrascodificaById(anagrafica.tipoabitazioneId, trascTipoAbi).descrizione ]],
      ((this.checkIndirizzoPrecedente(anagrafica) ? [['INDIRIZZO PRECEDENTE'], [anagrafica?.viaprec + ' ,' + anagrafica?.civicoprec]] : null )),
      ((this.checkIndirizzoPrecedente(anagrafica) ? [['CITTA PRECEDENTE'], [anagrafica.comuneprec + ', ' + anagrafica.capprec + ', ' + anagrafica.provinciaprec]] : null )),
      ((this.checkDomicilioDiversoResid(anagrafica) ? [['INDIRIZZO DOMICILIO'], [anagrafica?.viadomic + ' ,' + anagrafica?.civicodomic]] : null )),
      ((this.checkDomicilioDiversoResid(anagrafica) ? [['CITTA DOMICILIO'], [anagrafica.comunedomic + ', ' + anagrafica.capdomic + ', ' + anagrafica.provinciadomic]] : null )),
    ].filter(row => row !== null);
    
    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: 'DATI RESIDENZA', colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: this.finanziamentoPrintService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
    },
    didDrawPage: (data: any) => {
      this.finanziamentoPrintService.createHeader(this.doc, this.img, this.title, this.brand);
        this.finanziamentoPrintService.writeFooterPage(doc, idOrder, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
            isTableDrawn = true; 
            isTableStartedNewPage = false; 
        } else {
            isTableStartedNewPage = true; 
            this.currentPdfPage++;
        }
    },
  });
     y = doc.lastAutoTable.finalY + 10
    this.finanziamentoPrintService.setCurrentY(y)
  }

  checkIndirizzoPrecedente(anagrafica: FinAnagrafica): any {
    if(anagrafica.viaprec && anagrafica.civicoprec && anagrafica.comuneprec && anagrafica.capprec && anagrafica.provinciaprec){
      return anagrafica.viaprec && anagrafica.civicoprec && anagrafica.comuneprec && anagrafica.capprec && anagrafica.provinciaprec;
    }
    return false;
  }
  
  checkDomicilioDiversoResid(anagrafica: FinAnagrafica): any {
    if(anagrafica.viadomic && anagrafica.civicodomic && anagrafica.comunedomic && anagrafica.capdomic && anagrafica.provinciadomic){
      return anagrafica.viadomic && anagrafica.civicodomic && anagrafica.comunedomic && anagrafica.capdomic && anagrafica.provinciadomic;
    }
    return false;
  }

  
  genericTableRecapiti(doc?: any, idOrder?:string, anagrafica?: FinAnagrafica, y?:number, color?:string) : void{
    
    let lastCellY;
    let isTableDrawn = false; 
    let isTableStartedNewPage = false; 

    const rows = [
      [['TELEFONO'], [anagrafica?.tel]],
      [['INDIRIZZO E-MAIL'], [anagrafica.email]],
      ((anagrafica.telnd ? [['TELEFONO 2'], [anagrafica.telnd]] : null )),
      ((anagrafica.altrorecapito ? [['ALTRO RECAPITO'], [anagrafica.altrorecapito]] : null )),
    ].filter(row => row !== null);
    
    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: 'RECAPITI', colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: this.finanziamentoPrintService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
    },
    didDrawPage: (data: any) => {
      this.finanziamentoPrintService.createHeader(this.doc, this.img, this.title, this.brand); 
        this.finanziamentoPrintService.writeFooterPage(doc, idOrder, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
            isTableDrawn = true; 
            isTableStartedNewPage = false; 
        } else {
            isTableStartedNewPage = true; 
            this.currentPdfPage++;
        }
    },
  });
     y = doc.lastAutoTable.finalY + 10
    this.finanziamentoPrintService.setCurrentY(y)
  }

  genericDatiCittadinanza(doc?: any, idOrder?:string, anagrafica?: FinAnagrafica, y?:number, color?:string, trascCitta?: Trascodifica[]) : void{
    
    let lastCellY;
    let isTableDrawn = false; 
    let isTableStartedNewPage = false; 

    const rows = [
      ((this.formController.getTrascodificaById(anagrafica.tipologiacittadId, trascCitta)?.descrizione ? [['CITTADINANZA'], [this.formController.getTrascodificaById(anagrafica.tipologiacittadId, trascCitta)?.descrizione]] : null )),
      ((anagrafica.altracitt ? [['CITTADINANZA'], [anagrafica.altracitt]] : null )),
      ((anagrafica.tipologiaextraue ? [['CITTADINZA ENTRA EU'], [anagrafica.tipologiaextraue ? ' SI' : ' NO' ]] : null )),
      ((anagrafica.soggionoinizio ? [['SOGGIORNO IN ITALIA DAL'], [ this.finanziamentoPrintService.formatDate(anagrafica.soggionoinizio)]] : null )),
      ((anagrafica.soggionoscadenza ? [['DATA SCADENZA PERMESSO DI SOGGIORNO'], [this.finanziamentoPrintService.formatDate(anagrafica.soggionoscadenza)]] : null ))
    ].filter(row => row !== null);
    
    if(rows.length > 0){
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: 'DATI CITTADINANZA', colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: this.finanziamentoPrintService.returnCurrentY(),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.finanziamentoPrintService.createHeader(this.doc, this.img, this.title, this.brand); 
          this.finanziamentoPrintService.writeFooterPage(doc, idOrder, doc.internal.getNumberOfPages(), 6);
  
          if (data.pageNumber === data.pageCount) {
              isTableDrawn = true; 
              isTableStartedNewPage = false; 
          } else {
              isTableStartedNewPage = true; 
              this.currentPdfPage++;
          }
      },
    });
    }

     y = doc.lastAutoTable.finalY + 10
    this.finanziamentoPrintService.setCurrentY(y)
  }
  
  genericDatiOccupazione(doc?: any, idOrder?:string, datiOccupazione?: FinDatiOccupazione, y?:number, color?:string, trascAttLav?:Trascodifica[]) : void{
    
    let lastCellY;
    let isTableDrawn = false; 
    let isTableStartedNewPage = false; 

    const rows = [
      [["ATTIVITA' LAVORATIVA"], [this.formController.getTrascodificaById(datiOccupazione.attivitalavId, trascAttLav).descrizione]],
      ((datiOccupazione.datainizioocc ? [['DATA INIZIO OCCUPAZIONE'], [this.finanziamentoPrintService.formatDate(datiOccupazione.datainizioocc)]] : null )),
      ((datiOccupazione.tipoAssunzioneDescrizione ? [['TIPO ASSUNZIONE'], [datiOccupazione.tipoAssunzioneDescrizione ]] : null ))
    ].filter(row => row !== null);
    
    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: 'DATI OCCUPAZIONE', colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: this.finanziamentoPrintService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
    },
    didDrawPage: (data: any) => {
      this.finanziamentoPrintService.createHeader(this.doc, this.img, this.title, this.brand); 
        this.finanziamentoPrintService.writeFooterPage(doc, idOrder, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
            isTableDrawn = true; 
            isTableStartedNewPage = false; 
        } else {
            isTableStartedNewPage = true; 
            this.currentPdfPage++;
        }
    },
  });
     y = doc.lastAutoTable.finalY + 10
    this.finanziamentoPrintService.setCurrentY(y)
  }

  genericDatiDatoreDiLavoro(doc?: any, idOrder?:string, datiOccupazione?: FinDatiOccupazione, y?:number, color?:string) : void{
    
    let lastCellY;
    let isTableDrawn = false; 
    let isTableStartedNewPage = false; 

    const rows = [
      [['RAGIONE SOCIALE'], [datiOccupazione.ragionesocialedatore]],
      [['INDIRIZZO'], ['via ' + datiOccupazione?.via + ' ,' + datiOccupazione?.civico]],
      [['CITTA'], [datiOccupazione.comune + ', ' + datiOccupazione.cap + ', ' + datiOccupazione.provincia]],
      ((datiOccupazione.telefono ? [['TELEFONO'], [datiOccupazione.telefono ]] : null ))
    ].filter(row => row !== null);
    
    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: 'DATI DATORE DI LAVORO', colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: this.finanziamentoPrintService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
    },
    didDrawPage: (data: any) => {
      this.finanziamentoPrintService.createHeader(this.doc, this.img, this.title, this.brand);
        this.finanziamentoPrintService.writeFooterPage(doc, idOrder, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
            isTableDrawn = true; 
            isTableStartedNewPage = false; 
        } else {
            isTableStartedNewPage = true; 
            this.currentPdfPage++;
        }
    },
  });
     y = doc.lastAutoTable.finalY + 10
    this.finanziamentoPrintService.setCurrentY(y)
  }

  genericDatiReddito(doc?: any, idOrder?:string, datiOccupazione?: FinDatiOccupazione, y?:number, color?:string, trascTipoReddito?:Trascodifica[]) : void{
    
    let lastCellY;
    let isTableDrawn = false; 
    let isTableStartedNewPage = false; 

    const rows = [
      [['TIPO REDDITO'], [this.formController.getTrascodificaById(datiOccupazione.tipologiaredditoId, trascTipoReddito).descrizione]],
      ((datiOccupazione.nummensilita ? [["NUMERO MENSILITA'"], [datiOccupazione.nummensilita.toString() ]] : null )),
      [['TOTALE IMPORTO REDDITO'], [datiOccupazione.totalereddito + ' €']],
    ].filter(row => row !== null);
    
    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: 'DATI REDDITO', colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: this.finanziamentoPrintService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
    },
    didDrawPage: (data: any) => {
      this.finanziamentoPrintService.createHeader(this.doc, this.img, this.title, this.brand); 
        this.finanziamentoPrintService.writeFooterPage(doc, idOrder, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
            isTableDrawn = true; 
            isTableStartedNewPage = false; 
        } else {
            isTableStartedNewPage = true; 
            this.currentPdfPage++;
        }
    },
  });
     y = doc.lastAutoTable.finalY + 10
    this.finanziamentoPrintService.setCurrentY(y)
  }

  genericDatiPagamento(doc?: any, idOrder?:string, finanziamento?: Finanziamento, y?:number, color?:string) : void{
    
    let lastCellY;
    let isTableDrawn = false; 
    let isTableStartedNewPage = false; 

    const rows = [
      [['IBAN'], [finanziamento.iban]],
      [['BOLLETTINO'], [this.convertBoolValueNoValue(finanziamento.bollettino) ]],
    ].filter(row => row !== null && row[1].toString() !== '');
    
    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: 'DATI PAGAMENTO', colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: this.finanziamentoPrintService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
    },
    didDrawPage: (data: any) => {
      this.finanziamentoPrintService.createHeader(this.doc, this.img, this.title, this.brand);
        this.finanziamentoPrintService.writeFooterPage(doc, idOrder, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
            isTableDrawn = true; 
            isTableStartedNewPage = false; 
        } else {
            isTableStartedNewPage = true; 
            this.currentPdfPage++;
        }
    },
  });
     y = doc.lastAutoTable.finalY + 10
    this.finanziamentoPrintService.setCurrentY(y)
  }

  genericProspettoContabile(doc?: any, idOrder?:string, finanziamento?: Finanziamento, y?:number, color?:string) : void{
    
    let lastCellY;
    let isTableDrawn = false; 
    let isTableStartedNewPage = false; 

    const rows = [
      [['PREZZO TOTALE'], [finanziamento.prezzotot.toString() + ' €']],
      [['ACCONTO'], [finanziamento.acconto.toString() + ' €']],
      [['RESIDUO DA FINANZIARE'], [finanziamento.residuo.toString() + ' €']],
      [['CODICE TABELLA'], [finanziamento.codicetabella.toString()]],
      [['IMPORTO RATA'], [finanziamento.importorata.toString() + ' €']],
      [['NUMERO RATE'], [finanziamento.numrate.toString()]],
      /*[['TAN'], [finanziamento.tan.toString() + ' %']],
      [['TAEG'], [finanziamento.taeg.toString() + ' %']],*/
    ].filter(row => row !== null);
    
    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: 'PROSPETTO CONTABILE', colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: this.finanziamentoPrintService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
    },
    didDrawPage: (data: any) => {
      this.finanziamentoPrintService.createHeader(this.doc, this.img, this.title, this.brand); 
        this.finanziamentoPrintService.writeFooterPage(doc, idOrder, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
            isTableDrawn = true; 
            isTableStartedNewPage = false; 
        } else {
            isTableStartedNewPage = true; 
            this.currentPdfPage++;
        }
    },
  });
     y = doc.lastAutoTable.finalY + 10
    this.finanziamentoPrintService.setCurrentY(y)
  }

  // Garante ⬇

  genericTableDatiAnagraficiGarante(doc?: any, idOrder?:string, anagrafica?: FinAnagrafica, y?:number, color?:string, trascStato?: Trascodifica[]) : void{
    
    let lastCellY;
    let isTableDrawn = false; 
    let isTableStartedNewPage = false; 

    const rows = [
      [['CLIENTE'], [anagrafica?.nome + ' ' + anagrafica?.cognome]],
      [['CODICE FISCALE'], [anagrafica.codicefiscale]],
      [['SESSO'], [anagrafica.sesso]],
      [['DATA DI NASCITA'], [this.finanziamentoPrintService.formatDate(anagrafica.datanascita)]],
      [['CITTA DI NASCITA'], [anagrafica.comunenascita]],
      [['STATO CIVILE'], [this.formController.getTrascodificaById(anagrafica.statocivileId, trascStato).descrizione]],
    ]
    
    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: 'DATI ANAGRAFICI GARANTE', colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: this.finanziamentoPrintService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
    },
    didDrawPage: (data: any) => {
      this.finanziamentoPrintService.createHeader(this.doc, this.img, this.title, this.brand);
        this.finanziamentoPrintService.writeFooterPage(doc, idOrder, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
            isTableDrawn = true; 
            isTableStartedNewPage = false; 
        } else {
            isTableStartedNewPage = true; 
            this.currentPdfPage++;
        }
    },
  });
     y = doc.lastAutoTable.finalY + 10
    this.finanziamentoPrintService.setCurrentY(y)
  }

  genericTableDatiResidenzaGarante(doc?: any, idOrder?:string, anagrafica?: FinAnagrafica, y?:number, color?:string, trascTipoAbi?:Trascodifica[]) : void{
    
    let lastCellY;
    let isTableDrawn = false; 
    let isTableStartedNewPage = false; 

    const rows = [
      [['INDIRIZZO'], ['via ' + anagrafica?.via + ' ,' + anagrafica?.civico]],
      [['CITTA'], [anagrafica.comune + ', ' + anagrafica.cap + ', ' + anagrafica.provincia]],
      [['TIPO ABITAZIONE'], [this.formController.getTrascodificaById(anagrafica.tipoabitazioneId, trascTipoAbi).descrizione ]],
      ((this.checkIndirizzoPrecedente(anagrafica) ? [['INDIRIZZO PRECEDENTE'], [anagrafica?.viaprec + ' ,' + anagrafica?.civicoprec]] : null )),
      ((this.checkIndirizzoPrecedente(anagrafica) ? [['CITTA PRECEDENTE'], [anagrafica.comuneprec + ', ' + anagrafica.capprec + ', ' + anagrafica.provinciaprec]] : null )),
      ((this.checkDomicilioDiversoResid(anagrafica) ? [['INDIRIZZO DOMICILIO'], [anagrafica?.viadomic + ' ,' + anagrafica?.civicodomic]] : null )),
      ((this.checkDomicilioDiversoResid(anagrafica) ? [['CITTA DOMICILIO'], [anagrafica.comunedomic + ', ' + anagrafica.capdomic + ', ' + anagrafica.provinciadomic]] : null )),
    ].filter(row => row !== null);
    
    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: 'DATI RESIDENZA GARANTE', colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: this.finanziamentoPrintService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
    },
    didDrawPage: (data: any) => {
      this.finanziamentoPrintService.createHeader(this.doc, this.img, this.title, this.brand);
        this.finanziamentoPrintService.writeFooterPage(doc, idOrder, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
            isTableDrawn = true; 
            isTableStartedNewPage = false; 
        } else {
            isTableStartedNewPage = true; 
            this.currentPdfPage++;
        }
    },
  });
     y = doc.lastAutoTable.finalY + 10
    this.finanziamentoPrintService.setCurrentY(y)
  }

  genericTableRecapitiGarante(doc?: any, idOrder?:string, anagrafica?: FinAnagrafica, y?:number, color?:string) : void{
    
    let lastCellY;
    let isTableDrawn = false; 
    let isTableStartedNewPage = false; 

    const rows = [
      [['TELEFONO'], [anagrafica?.tel]],
      [['INDIRIZZO E-MAIL'], [anagrafica.email]],
      ((anagrafica.telnd ? [['TELEFONO 2'], [anagrafica.telnd]] : null )),
      ((anagrafica.altrorecapito ? [['ALTRO RECAPITO'], [anagrafica.altrorecapito]] : null )),
    ].filter(row => row !== null);
    
    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: 'RECAPITI GARANTE', colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: this.finanziamentoPrintService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
    },
    didDrawPage: (data: any) => {
      this.finanziamentoPrintService.createHeader(this.doc, this.img, this.title, this.brand);
        this.finanziamentoPrintService.writeFooterPage(doc, idOrder, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
            isTableDrawn = true; 
            isTableStartedNewPage = false; 
        } else {
            isTableStartedNewPage = true; 
            this.currentPdfPage++;
        }
    },
  });
     y = doc.lastAutoTable.finalY + 10
    this.finanziamentoPrintService.setCurrentY(y)
  }

  genericDatiCittadinanzaGarante(doc?: any, idOrder?:string, anagrafica?: FinAnagrafica, y?:number, color?:string, trascCitta?:Trascodifica[]) : void{
    
    let lastCellY;
    let isTableDrawn = false; 
    let isTableStartedNewPage = false; 

    const rows = [
      ((this.formController.getTrascodificaById(anagrafica.tipologiacittadId, trascCitta)?.descrizione ? [['CITTADINANZA'], [this.formController.getTrascodificaById(anagrafica.tipologiacittadId, trascCitta)?.descrizione]] : null )),
      ((anagrafica.altracitt ? [['CITTADINANZA'], [anagrafica.altracitt]] : null )),
      ((anagrafica.tipologiaextraue ? [['CITTADINZA ENTRA EU'], [anagrafica.tipologiaextraue ? ' SI' : ' NO' ]] : null )),
      ((anagrafica.soggionoinizio ? [['SOGGIORNO IN ITALIA DAL'], [ this.finanziamentoPrintService.formatDate(anagrafica.soggionoinizio)]] : null )),
      ((anagrafica.soggionoscadenza ? [['DATA SCADENZA PERMESSO DI SOGGIORNO'], [this.finanziamentoPrintService.formatDate(anagrafica.soggionoscadenza)]] : null ))
    ].filter(row => row !== null);
    
    if(rows.length > 0){
      autoTable(doc, {
        theme: 'grid',
        margin: { top: 30, bottom: 10, left: 10, right: 10 },
        headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
        head: [[{ content: 'DATI CITTADINANZA GARANTE', colSpan: 2, styles: { halign: 'center' } }]],
        body: rows,
        startY: this.finanziamentoPrintService.returnCurrentY(),
        didDrawCell: (data: any) => {
          lastCellY = data.cell.y + data.cell.height;
      },
      didDrawPage: (data: any) => {
        this.finanziamentoPrintService.createHeader(this.doc, this.img, this.title, this.brand); 
          this.finanziamentoPrintService.writeFooterPage(doc, idOrder, doc.internal.getNumberOfPages(), 6);
  
          if (data.pageNumber === data.pageCount) {
              isTableDrawn = true; 
              isTableStartedNewPage = false; 
          } else {
              isTableStartedNewPage = true; 
              this.currentPdfPage++;
          }
      },
    });
    }

     y = doc.lastAutoTable.finalY + 10
    this.finanziamentoPrintService.setCurrentY(y)
  }

  genericDatiOccupazioneGarante(doc?: any, idOrder?:string, datiOccupazione?: FinDatiOccupazione, y?:number, color?:string, trascAttLav?: Trascodifica[]) : void{
    
    let lastCellY;
    let isTableDrawn = false; 
    let isTableStartedNewPage = false; 

    const rows = [
      [["ATTIVITA' LAVORATIVA"], [this.formController.getTrascodificaById(datiOccupazione.attivitalavId, trascAttLav).descrizione]],
      ((datiOccupazione.datainizioocc ? [['DATA INIZIO OCCUPAZIONE'], [this.finanziamentoPrintService.formatDate(datiOccupazione.datainizioocc)]] : null )),
      ((datiOccupazione.tipoAssunzioneDescrizione ? [['TIPO ASSUNZIONE'], [datiOccupazione.tipoAssunzioneDescrizione ]] : null ))
    ].filter(row => row !== null);
    
    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: 'DATI OCCUPAZIONE', colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: this.finanziamentoPrintService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
    },
    didDrawPage: (data: any) => {
      this.finanziamentoPrintService.createHeader(this.doc, this.img, this.title, this.brand);
        this.finanziamentoPrintService.writeFooterPage(doc, idOrder, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
            isTableDrawn = true; 
            isTableStartedNewPage = false; 
        } else {
            isTableStartedNewPage = true; 
            this.currentPdfPage++;
        }
    },
  });
     y = doc.lastAutoTable.finalY + 10
    this.finanziamentoPrintService.setCurrentY(y)
  }

  genericDatiDatoreDiLavoroGarante(doc?: any, idOrder?:string, datiOccupazione?: FinDatiOccupazione, y?:number, color?:string) : void{
    
    let lastCellY;
    let isTableDrawn = false; 
    let isTableStartedNewPage = false; 

    const rows = [
      [['RAGIONE SOCIALE'], [datiOccupazione.ragionesocialedatore]],
      [['INDIRIZZO'], ['via ' + datiOccupazione?.via + ' ,' + datiOccupazione?.civico]],
      [['CITTA'], [datiOccupazione.comune + ', ' + datiOccupazione.cap + ', ' + datiOccupazione.provincia]],
      ((datiOccupazione.telefono ? [['TELEFONO'], [datiOccupazione.telefono ]] : null ))
    ].filter(row => row !== null);
    
    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: 'DATI DATORE DI LAVORO', colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: this.finanziamentoPrintService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
    },
    didDrawPage: (data: any) => {
      this.finanziamentoPrintService.createHeader(this.doc, this.img, this.title, this.brand); 
        this.finanziamentoPrintService.writeFooterPage(doc, idOrder, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
            isTableDrawn = true; 
            isTableStartedNewPage = false; 
        } else {
            isTableStartedNewPage = true; 
            this.currentPdfPage++;
        }
    },
  });
     y = doc.lastAutoTable.finalY + 10
    this.finanziamentoPrintService.setCurrentY(y)
  }

  genericDatiRedditoGarante(doc?: any, idOrder?:string, datiOccupazione?: FinDatiOccupazione, y?:number, color?:string, trascTipoReddito?:Trascodifica[]) : void{
   
    let lastCellY;
    let isTableDrawn = false; 
    let isTableStartedNewPage = false; 

    const rows = [
      [['TIPO REDDITO'], [this.formController.getTrascodificaById(datiOccupazione.tipologiaredditoId, trascTipoReddito).descrizione]],
      ((datiOccupazione.nummensilita ? [["NUMERO MENSILITA'"], [datiOccupazione.nummensilita.toString() ]] : null )),
      [['TOTALE IMPORTO REDDITO'], [datiOccupazione.totalereddito + ' €']],
    ].filter(row => row !== null);
    
    autoTable(doc, {
      theme: 'grid',
      margin: { top: 30, bottom: 10, left: 10, right: 10 },
      headStyles: { halign: 'center', fillColor: color, fontSize: 9 },
      head: [[{ content: 'DATI REDDITO', colSpan: 2, styles: { halign: 'center' } }]],
      body: rows,
      startY: this.finanziamentoPrintService.returnCurrentY(),
      didDrawCell: (data: any) => {
        lastCellY = data.cell.y + data.cell.height;
    },
    didDrawPage: (data: any) => {
      this.finanziamentoPrintService.createHeader(this.doc, this.img, this.title, this.brand);
        this.finanziamentoPrintService.writeFooterPage(doc, idOrder, doc.internal.getNumberOfPages(), 6);

        if (data.pageNumber === data.pageCount) {
            isTableDrawn = true; 
            isTableStartedNewPage = false; 
        } else {
            isTableStartedNewPage = true; 
            this.currentPdfPage++;
        }
    },
  });
     y = doc.lastAutoTable.finalY + 10
    this.finanziamentoPrintService.setCurrentY(y)
  }

  convertBoolValue(value: boolean): string {
    return value ? 'Si' : null;
  }

  convertBoolValueNoValue(value: boolean): string {
    return value ? 'Si' : 'No';
  }
}