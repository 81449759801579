import { Component, Input, OnInit } from '@angular/core';
import { constants } from 'src/app/modules/classes/constants';
import { Trascodifica } from '../../../../classes/trascodifica';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { OpereIdraulicheService } from '../services/opereIdrauliche/opereIdrauliche.service';
@Component({
  selector: 'app-form-piatto-effetto-pietra',
  templateUrl: './form-piatto-effetto-pietra.component.html',
  styleUrls: ['./form-piatto-effetto-pietra.component.scss']
})
export class FormPiattoEffettoPietraComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  misureDisp: any[];
  filtered: any[];
  listColore : Trascodifica[];
  listMisure : Trascodifica [];

  constructor(
    private opIdraulicheService: OpereIdraulicheService, 
    private formController:FormControllerService
  ) { }

  ngOnInit(): void {
		this.opIdraulicheService.findByKey(this.form.idOrder , 'ordineId.equals=','opereidrauliches').subscribe(res => {
				this.listColore = this.formController.getTrascodificaByCodice(constants.COLORE_PIATTI_EFF_PIE, this.trascodifiche);					
				this.form.sections.piattoEffettoPietra.coloreId = res[0] !== undefined ? res[0].colorepiateffpietraId : null;	
				if(this.form.sections.piattoEffettoPietra.coloreId !== undefined){
					this.changeModel(this.form.sections.piattoEffettoPietra.coloreId);
					this.form.sections.piattoEffettoPietra.dimensioneId = res[0] !== undefined ? res[0].dimpiatteffpietraId : null;
				}	
		})
  }

  changeModel(event): void {
    if (this.form.sections.piattoEffettoPietra.codice) {
      delete this.form.sections.piattoEffettoPietra.codice;
    }
	 this.listMisure =  this.trascodifiche.filter(tr => (tr.codice === constants.DIM_PIATTI_PIETR) && (tr.parentId === event));
  }
}
