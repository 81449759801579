export class MontascaleDto {
    id: number;
    note?: string;
    coloreguida?: string;
    tipologiarivestimento?: string;
    colorerivestimento?: string;
    curvaextran?: number;
    curven?: number;
    guidaextra?: number;
    puntocarica?: number;
    ordineId?: number;
    partenzaId?: string;
    arrivoId?: string;
    guidaId?: string;
    posizionejoyId?: string;
    arrivobassoguidaId?: string;
    arrivoaltoguidaId?: string;
    fermatagg?: string;
    partenzavert?: boolean;
    arrivobassevalue?: number;
    arrivoaltoguidavalue?: number;
    noteliberetipoarrivo?: string;
    ordineIdordine?: string;
    partenzaDescrizione?: string;
    arrivoDescrizione?: string;
    guidaDescrizione?: string;
    posizionejoyDescrizione?: string;
    arrivobassoguidaDescrizione?: string;
    arrivoaltoguidaDescrizione?: string;
}