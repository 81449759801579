
    <div class="pt-5 text-center mb-4"></div>
        <div class="row buttonRow mb-3">
            <div class="col-6">
                <button *ngIf="currentStep>0" [disabled]="currentStep == totalSteps.length && openSign" (click)="setStep(currentStep-1)"
                    class="btnMainColor btnColor" mat-flat-button>
                    < Indietro
                </button>           
            </div>
            <div class="col-6 text-right" *ngIf="currentStep < totalSteps.length">
                <button
                    *ngIf="(!(!preventivoId || preventivoId === '' || preventivoId === 0) && showAnagrafica) || showAnagrafica" [disabled]="generatingPdf || (!validAnagrafica && currentStep === 0) || (!validAnagrafica && currentStep === 4) || (currentStep === 3 && !validPrivacy()) || (currentStep === 2 && !formController.validPagamento(form)) || (currentStep === 1 &&  (formattedText ? formattedText.length : 0) >= 500)"
                        class="btnMainColor btnColor" mat-flat-button (click)="saveModule()">Avanti ></button>
                <div>
                    <div class="smallFont text-danger"
                        *ngIf="((!validAnagrafica && currentStep === 0) && (!(!preventivoId || preventivoId === '' || preventivoId === 0) && showAnagrafica)) || (currentStep === 2 && !this.formController.validPagamento(this.form))">
                            Compila tutti i campi obbligatori
                    </div>
                    <div class="smallFont text-danger" *ngIf="(currentStep === 3 && !validPrivacy())">
                        &egrave; necessario accettare tutte le informative per procedere alla firma
                    </div>
                </div>
                    </div>
                <div class="col-6" *ngIf="currentStep < totalSteps.length"></div>
            <div class="col-6 text-right">
        </div>
    </div>

    <div class="formContainer">
        <div class="text-center mb-4">
            <h4>
                <span class="imgModulo">
                    <img alt="" class="" src="assets/img/loghi/ARIEL.jpg"/>
                </span>
                Modulo d'ordine fotovoltaico Ariel
            </h4>
        </div>
      <hr/>
    <div class="moduleContainer mb-3">
        <div id="toPrint">
            <div class="" *ngIf="currentStep == 0">
                <div class="row" *ngIf="!showAnagrafica">
                    <div class="col-6">
                        <mat-form-field class="mb-2 full-width" appearance="standard">
                            <mat-label>Id Preventivo</mat-label>
                            <input matInput [(ngModel)]="preventivoId" (input)="onInsertPreventivo()" placeholder="Preventivo Id" type="number" required>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <button mat-flat-button class="btnMainColor btnColor" (click)="onSubmit()" [disabled]="!preventivoId || preventivoId === '' || preventivoId === 0"> Cerca </button>
                    </div>
                </div>
                <div class="row" *ngIf="!showAnagrafica && !preventivoFound && preventivoId !== undefined">
                    <a style="font-size:14px; color:#dc3545;margin-left: 10px;">Nessun contratto trovato !</a>
                </div>

                <app-anagrafica-sh *ngIf="showAnagrafica && preventivoFound" [brand]="'fotovoltaicoAriel'" [validAnagrafica]="validAnagrafica" [form]="form"></app-anagrafica-sh>
            </div>

            <div class="" *ngIf="currentStep == 1">
                <div class="text-center mb-3">
                </div>
                <div>
                    <div class="row originalRow">
                        <div class="col-12">
                            <div class="blue-border mb-2">
                            </div>
                            <a>Contratto di Vendita e Installazione del seguente impianto fotovoltaico così composto:</a>
                            <br><br>
                            <mat-form-field class="full-width" appearance="fill">
                                <textarea matInput [(ngModel)]="formattedText" rows="8"></textarea>
                            </mat-form-field>
                            <div>
                                <p>Caratteri: {{ formattedText ? formattedText.length + '/ 500' : '0/500' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 2">
                <div class="text-center mb-3">
                </div>
                <div>
                    <app-pay-one [form]="form" [brand]="'fotovoltaicoAriel'" [prodotto]="'fotovoltaico'" [trascodifiche] = "trascodifiche"></app-pay-one>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 3">
                <div class="text-center mb-3">

                </div>
                <div>
					<app-privacy [form]="form" [brand]="'fotovoltaicoAriel'"></app-privacy>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 4">
                <app-contacts-check [validAnagrafica]="validAnagrafica" [form]="form"></app-contacts-check>
            </div> 

            <div class="lastStep" *ngIf="currentStep == 5">

                <div class="text-center">

                    <div class="digitalSignatureButtons" *ngIf="currentStep == 5 && openSign" style="height: 124px">
                        <div class="signButton" (click)="edit()">
                            <span class="material-icons d-block">
                                edit
                            </span>
                            <span class="d-block mb-2">Modifica Contratto</span>
                        </div>
                    </div>

                    <app-print-fotovoltaico-ut [anagrafica]="anagrafica" [idOrder]="form.idOrder"
                        [trascodifiche]="trascodifiche" [form]="form"
                        (reload)="reload($event)">
                    </app-print-fotovoltaico-ut>
                </div>
              </div>
            </div>
          </div>
      </div>

    <div class="row buttonRow">
            <div class="col-6">
                <button *ngIf="currentStep>0" [disabled]="currentStep == totalSteps.length && openSign" (click)="setStep(currentStep-1)"
                    class="btnMainColor btnColor" mat-flat-button>
                    < Indietro
                </button> 
            </div>
            <div class="col-6 text-right" *ngIf="currentStep < totalSteps.length">
                <button
                    *ngIf="(!(!preventivoId || preventivoId === '' || preventivoId === 0) && showAnagrafica) || showAnagrafica" [disabled]="generatingPdf || (!validAnagrafica && currentStep === 0) || (!validAnagrafica && currentStep === 4) || (currentStep === 3 && !validPrivacy()) || (currentStep === 2 && !formController.validPagamento(form)) || (currentStep === 1 &&  (formattedText ? formattedText.length : 0) >= 500)"
                        class="btnMainColor btnColor" mat-flat-button (click)="saveModule()">Avanti ></button>
            <div>
                <div class="smallFont text-danger"
                    *ngIf="((!validAnagrafica && currentStep === 0) && (!(!preventivoId || preventivoId === '' || preventivoId === 0) && showAnagrafica)) || (currentStep === 2 && !this.formController.validPagamento(this.form))">
                        Compila tutti i campi obbligatori
                </div>
                <div class="smallFont text-danger" *ngIf="(currentStep === 3 && !validPrivacy())">
                    &egrave; necessario accettare tutte le informative per procedere alla firma
                </div>
            </div>
          </div>
        <div class="col-6" *ngIf="currentStep < totalSteps.length"></div>
    </div>

    <div class="stepper">
        <div class="row">
            <div class="stepCol align-self-center" *ngFor="let step of totalSteps">
                <div class="stepNumber" [ngClass]="
                {
                    'doneStep': currentStep > step,
                'currentStep': currentStep == step,
            'errorStep': (!validAnagrafica && currentStep === step) ||(currentStep === step && !validPrivacy()) || (currentStep === step && !this.formController.validPagamento(this.form))
            }">
                <span class="number">{{step+1}}</span>
            </div>
        </div>

        <div class="stepCol align-self-center">
            <div class="stepNumber" [ngClass]="{'currentStep': currentStep == totalSteps.length}">
                <span class="number">
                    <span class="material-icons">
                        check
                    </span>
                </span>
            </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="errorModal" tabindex="-1">
        <app-error-modal [error]="error"></app-error-modal>
    </div>