<div class="text-center {{classBg}}">
    PIANTINA NUOVO BAGNO
</div>
<div class="{{classContent}}" id="partTwo">
    <!--
    <div class="mb-3">
        <mat-radio-group [(ngModel)]="drawing" aria-label="Select an option" color="primary">
             <mat-radio-button class="mr-3" [value]="false">Carica un file</mat-radio-button>
            <mat-radio-button [value]="true">Disegna la piantina</mat-radio-button>
        </mat-radio-group>
    </div>
    -->

    <div *ngIf="!drawing">

        <form>
            <div class="form-group">
                <fieldset class="form-group">

                    <label for="file-up">
                        {{imageError}}
                        <div class="custom-file fileInputProfileWrap">
                            <input id="file-up" type="file" (change)="fileChangeEvent($event)" class="fileInputProfile">
                            <div class="img-space">

                                <ng-container *ngIf="isImageSaved; else elseTemplate">
                                    <img [src]="cardImageBase64" />
                                </ng-container>
                                <ng-template #elseTemplate>
                                    <img src="/assets/img/placeholder.jpeg" class="img-responsive">
                                </ng-template>

                            </div>

                        </div>
                    </label>
                </fieldset>
            </div>
            <button mat-button color="warn" (click)="removeImage()" *ngIf="isImageSaved">Rimuovi immagine</button>
        </form>
    </div>

    <div class="" *ngIf="drawing">

        <div class="paint">
            <div class="mb-3">
                <button class="mr-2" mat-icon-button (click)="clear()" matTooltip="Cancella">
                    <mat-icon>clear</mat-icon>
                </button>
                <button class="mr-2" mat-icon-button (click)="undo()" matTooltip="Indietro">
                    <mat-icon>undo</mat-icon>
                </button>
                <button class="mr-2" mat-icon-button (click)="redo()" matTooltip="Avanti">
                    <mat-icon>redo</mat-icon>
                </button>
                <button class="mr-2" mat-button (click)="toggleEdit()">
                    {{ active ? "Salva" : "Fai il disegno" }}
                </button>
                <button class="mr-2" mat-icon-button (click)="toggleUseEraser()" matTooltip="Gomma">
                    <mat-icon *ngIf="useEraser">edit</mat-icon>
                    <mat-icon *ngIf="!useEraser">auto_fix_off</mat-icon>
                </button>

                <input class="mr-2" type="color" [(ngModel)]="color" />
                <!--
                <input type="range" [(ngModel)]="thickness" min="{1}" max="{30}" step="{1}" />
                   -->
            </div>

            <div *ngIf="!isLoadingImage">
                <paintable [hidden]="!active"
                           #paintable
                           [width]="width"
                           [height]="height"
                           [active]="active"
                           [useEraser]="useEraser"
                           [thickness]="thickness"
                           [thicknessEraser]="15"
                           [color]="color"
                           [scaleFactor]="1"
                           [image]="image"
                           (onSave)="onSave($event)"
                           (onLongPress)="onLongPress()"
                >
                    <div class="canvas-inner">

                    </div>
                    <div id="canvasOverlay" [ngClass]="{'cancella':useEraser}"></div>
                </paintable>
            </div>
		<div *ngIf="!isLoadingImage">
            <div [hidden]="active">
                <img [src]="cardImageBase64">
            </div>
        </div>    
            
        </div>
        <button mat-button color="warn" (click)="removeImage()" *ngIf="isImageSaved">Rimuovi immagine</button>

    </div>


</div>
