import { Component, Input, OnInit } from '@angular/core';
import { constants } from 'src/app/modules/classes/constants';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { PoltroneDto } from 'src/app/modules/classes/zucchetti/poltrone/poltrone';
import { BrandService } from 'src/app/services/brand/brand.service';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { PoltronaService } from 'src/app/services/poltrone/poltrone.service';
@Component({
  selector: 'prodotti-poltrone',
  templateUrl: './prodotti-poltrone.component.html',
  styleUrls: ['./prodotti-poltrone.component.scss']
})
export class ProdottiPoltroneComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: any;

  poltroneList: Trascodifica[] = [];
  optionPoltrone: any[] = [];

  pesoPoltrone: Trascodifica[] = [];
  coloriPoltrona: Trascodifica[] = [];
  poltroneResponse:any[] = [];

  searchColore: string = '';

  constructor(
    private poltronaService: PoltronaService,
    private formController: FormControllerService,
    private brandService: BrandService,
  ) { }

  ngOnInit(): void {
    this.poltronaService.findByKey(this.form.idOrder, 'ordineId.equals=').subscribe(async (res) => {
      this.poltroneResponse = res;
      this.form.sections.optionsPoltrone = [];
      this.getTranscodifiche();
    });
  }
  
  getTranscodifiche(): void {
    this.brandService.getDataByProduct(Number(localStorage.getItem('id_product'))).subscribe(res => {
      this.trascodifiche = res;
      this.getPoltrone();
    });
  }

  getPoltrone(): void {
    this.formController.getTrascodificaByCodicPromise(constants.CODICE_TIPO_PRODOTTO_POLTRONE, this.trascodifiche).then((data) => {
      this.poltroneList = data.map((poltrona) => ({
        ...poltrona,
        filteredProducts: this.filterProductsByTrascId(poltrona.id)
      }));

      this.poltroneList.forEach((el:any) => {    
        if(el.filteredProducts.length == 0){
          el.filteredProducts.push((this.mapProdottoToForm(el, new PoltroneDto())));
        }
      });

      this.getOptionByPoltronaParent();
      this.getColoriPoltronaParent();

      this.form.sections.prodotti = this.poltroneList;
    });
  }

  getOptionByPoltronaParent(): void {
    this.poltroneList.forEach((el:any, index) => {
      this.optionPoltrone[index] = this.trascodifiche.filter((res: Trascodifica) => res.codice === constants.CODICE_OPZIONE_POLTRONA &&  res.parentId == el.id)
    });
  }

  getPesoByPoltronaParent(): void {
    this.poltroneList.forEach((el:any, index) => {
      this.pesoPoltrone[index] =  this.trascodifiche.filter((res: Trascodifica) => res.codice == constants.CODICE_OPZIONE_POLTRONA_PESO &&  res.parentId == el.id);
    });
  }

  getColoriPoltronaParent(): void {
    this.poltroneList.forEach((el:any, index) => {
      this.coloriPoltrona[index] =  this.trascodifiche.filter((res: Trascodifica) => res.codice == constants.CODICE_COLORI_POLTRONA &&  res.parentId == el.id);
    });
  }
  
  mapProdottoToForm(dto: PoltroneDto, form: PoltroneDto): PoltroneDto{
    if (dto !== undefined) {
      form.ordineId = this.form.idOrder;
      form.coloreId = dto.coloreId; 
      form.linea = dto.linea; 
      form.opzionePoltronas = dto.opzionePoltronas || []
      form.trascodificaId = dto.id;
    }
    return form;
  }

  convertTrascodifica(id: number) {
    const matchingTrascodifica = this.trascodifiche.find(el => el.id === id);
    if (matchingTrascodifica) {
      return matchingTrascodifica.descrizione + (matchingTrascodifica.note ? matchingTrascodifica.note : '');
    }
    return ''; 
  }


  onOptionsChange(event: any, poltrona: any, opzione: any, poltrIndex: number): void {
    // Check if opzionePoltronas exists, and initialize it if not
    if (!poltrona[poltrIndex]) {
      poltrona[poltrIndex] = { opzionePoltronas: [] };
    } else if (!poltrona[poltrIndex].opzionePoltronas) {
      poltrona[poltrIndex].opzionePoltronas = [];
    }
  
    const elemento = poltrona[poltrIndex].opzionePoltronas.find(
      (e: any) => e.tipologiaId === opzione.id
    );
  
    if (event.checked) {
      if (!elemento) {
        const newOption: any = {
          tipologiaId: opzione.id
        };
        poltrona[poltrIndex].opzionePoltronas.push(newOption);
      }
    } else {
      if (elemento) {
        const index = poltrona[poltrIndex].opzionePoltronas.findIndex(
          (op: any) => op.tipologiaId === opzione.id
        );
        if (index !== -1) {
          poltrona[poltrIndex].opzionePoltronas.splice(index, 1);
        }
      }
    }
  }
  
  
  isChecked(optionId: number, list:any, poltIndex:number): boolean {
    if(list && list.filteredProducts){
      let poltrona = list.filteredProducts[poltIndex];
      if(poltrona && poltrona.opzionePoltronas){      
        return poltrona.opzionePoltronas.some((el: any) => el.tipologiaId === optionId);
      }
    }
    
    return false;
  }
  
  filterProductsByTrascId(idTrasc: number) {
    return this.poltroneResponse.filter((el) => el.trascodificaId === idTrasc);
  }

  addPoltrona(p:any): void {
    const newPoltrona = new PoltroneDto();
    newPoltrona.ordineId = this.form.idOrder;
    newPoltrona.trascodificaId = p.id;
    p.filteredProducts.push(newPoltrona);
  }


  removePoltrona(poltrona: any, index: number): void {
  
    if(poltrona.id){
      this.form.sections.delProdotti.push(poltrona.filteredProducts[index]);
    }
    
    poltrona.filteredProducts.splice(index, 1);
  
    // tasto INSERT inline deve sempre una prima riga vuota
    if (poltrona.filteredProducts.length === 0) {
      poltrona.filteredProducts.push(new PoltroneDto({ordineId: this.form.idOrder, trascodificaId: poltrona.id}));
    }
  }

  isAddButtonDisabled(poltrona: any): boolean {
    return poltrona.filteredProducts.some(
      (p: any) => !p.coloreId
    );
  }

  includeColore(i: any): boolean {
    if (i !== null && i !== undefined) {
      if (!this.searchColore || this.searchColore.trim() === '') {
        return false; 
      } else {
        const searchString = String(this.searchColore).trim()?.toLowerCase();
        const inputString = String(i).toLowerCase();
        return !inputString.startsWith(searchString); 
      }
    }
    return true; 
  }
  
}
