import { Component, Input, OnInit } from '@angular/core';
import { AllarmeDTO } from 'src/app/modules/classes/allarme/allarme.dto';
import { AllarmeService } from 'src/app/services/allarme/allarme.service';

@Component({
  selector: 'app-modello',
  templateUrl: './modello.component.html',
  styleUrls: ['./modello.component.scss']
})
export class ModelloComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: any;
  @Input() trascodificheModello: any;
  
  allarmeTrasc: any[];
  allarmeOptionChoice: boolean = false;

  constructor(
    private allarmeService: AllarmeService
  ) { }

  ngOnInit(): void {
    this.initializeAllarme();
  }

  private initializeAllarme(): void {
    this.allarmeService.findByKey(this.form.idOrder, 'ordineId.equals=').subscribe(async res => {
      if (res[0]) {
        this.form.sections.allarme = res[0];
      } else {
        this.form.sections.allarme = new AllarmeDTO();
        this.form.sections.allarme.ordineId = this.form.idOrder;
        this.form.sections.allarme.tipologiaId = this.trascodificheModello[0].id;
      }

    });
  }
}