import { Component, Input, OnInit } from '@angular/core';
import { Omaggio } from '../classes/dati-tecnici';
import { Trascodifica } from '../../../../classes/trascodifica';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { OmaggioService } from '../services/omaggio/omaggio.service';
import { constants } from 'src/app/modules/classes/constants';
@Component({
  selector: 'app-form-omaggio',
  templateUrl: './form-omaggio.component.html',
  styleUrls: ['./form-omaggio.component.scss']
})
export class FormOmaggioComponent implements OnInit {
  
  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  @Input() prodotto: any;
  availableOmaggio: Trascodifica[];
  classBg: string = "blueBg";
  classContent: string = "blueCont";
  classButton: string = "azur";

  constructor(private formController: FormControllerService, private omaggioService: OmaggioService) { }

  ngOnInit(): void {
    if (this.prodotto === 'bagno-pratico') {
      this.classBg = "bagnoPraticoBg";
      this.classContent = "bagnoPraticoCont";
      this.classButton = "praticoButton";
    }

    this.form.sections.omaggio = [];
    this.omaggioService.findByKey(this.form.idOrder, 'ordineId.equals=').subscribe(res => {
      for (var i = 0; i < res.length; i++) {
        const omaggio = this.formController.mappingOmaggioDTOtoForm(res[i], new Omaggio());
        this.form.sections.omaggio.push(omaggio);
      }

      this.availableOmaggio = this.formController.getTrascodificaByCodice(constants.COD_OMAGGIO_TIPOLOGIA, this.trascodifiche);
    })
  }

  alreadySelected(value): boolean {
    const found = this.form.sections.omaggio.findIndex(r => r.id === value);
    return found !== - 1;
  }

  addOmaggio(): void {
    this.form.sections.omaggio.push(new Omaggio());
  }

  removeItem(i): void {
    if (this.form.sections.omaggio[i].id !== undefined) {
      this.omaggioService.delete(this.form.sections.omaggio[i].id).subscribe();
    }
    this.form.sections.omaggio.splice(i, 1);
  }

  checkItem(event, i): void {
    if (event == null) {
      this.form.sections.omaggio[i] = new Omaggio();
    }
  }

  validOmaggio(): boolean {
    let valid = true;
    if (this.form.sections.omaggio.length > 0) {
      for (let i = 0; i < this.form.sections.omaggio.length; i++) {
        if (valid) {
          if (this.form.sections.omaggio[i].tipologiaId &&
            this.form.sections.omaggio[i].quantita
          ) {
            valid = true;
          } else if (!this.form.sections.omaggio[i].tipologiaId && !this.form.sections.omaggio[i].quantita) {
            valid = true;
          } else {
            valid = false;
          }
        }
      }
    }
    return valid;
  }
}
