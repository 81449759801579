<div class="mb-3 text-center" style="text-transform: uppercase">
    Conferma email e numero di telefono del cliente prima di procedere con la firma digitale
</div>
<div class="anagraficaContainer mb-4" [ngClass]="{'onlyRedBorder': !validAnagrafica}">

    <div class="row">
        <div class="col-lg-6">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Email</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.email" placeholder="Email" required>
                <mat-hint class="text-danger" *ngIf="!formController.checkMail(form.anagrafica.anagrafica.email)">Email valida richiesta</mat-hint>
            </mat-form-field>
        </div>

        <div class="col-lg-6">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Telefono 1</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.tel1" placeholder="Telefono 1" required>
                <mat-hint class="text-danger" *ngIf="!formController.checkPhone(form.anagrafica.anagrafica.tel1)">Telefono valido richiesto</mat-hint>
            </mat-form-field>
        </div>


    </div>
</div>

