import { environment } from '../../../../../../../environments/environment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { AnagraficaService } from 'src/app/modules/seller/bagni-italiani/main-bagni/services/anagrafica/anagrafica.service';
import { PreviewComponent } from 'src/app/modules/shared/pdf/preview/preview.component';
import { PrintComponent } from 'src/app/modules/shared/pdf/print/print.component';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { FotovoltaicoService } from 'src/app/services/fotovoltaico/fotovoltaico.service';
import { HttpService } from 'src/app/services/http.service';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { PrintserviceService } from 'src/app/services/print/printservice.service';
import { fotovoltaicoProd } from '../../classes/fotovltaicoprod.model';
import 'jspdf-autotable';
declare var jQuery: any;

@Component({
  selector: 'app-print-fotovoltaico-ut',
  templateUrl: './print-fotovoltaico-ut.component.html',
  styleUrls: ['./print-fotovoltaico-ut.component.scss']
})
export class PrintFotovoltaicoUtComponent extends PrintComponent implements OnInit {

  @Input() idOrder: number;
  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  @Input() anagrafica: any;
  @Output('reload') reload: EventEmitter<any> = new EventEmitter<any>();

  generatingPdf: boolean = false;
  validAnagrafica: boolean = false;
  loadAll: boolean = false;
  sendingPdf: boolean = false;
  openSign: boolean = false;
  showFrame: boolean = false;
  showModalPrint: boolean = false;

  privacyText: string = '';
  urlFrame: string = '';

  timeLeft: number = 60;
  fotovoltaicoProduct: fotovoltaicoProd;

  ordine: any;
  prodotto: any;
  pagamento: any;
  error: any;
  member: any;

  rgbColorLine: number[] = [228, 157, 53];
  colorString: string = '#F5B448';

  integratedSignature = environment.integratedSignature;
  isApiV3 = environment.isV3;
  constructor(
    private router: Router,
    public formController: FormControllerService,
    public printService: PrintserviceService,
    private orderService: OrdersService,
    public dialog: MatDialog,
    private anagraficaService: AnagraficaService,
    private fotovoltaicoService: FotovoltaicoService,
    private httpService: HttpService,
  ) {
    super(printService);
  }

  ngOnInit(): void {
    this.getOrder(this.idOrder);
    this.getPagamento();
    this.checkAnagrafica();
    this.getProduct();
    this.getPrivacy();
    this.setLineColor(this.rgbColorLine)
  }

  getOrder(idOrder): void {
    this.orderService.getSingleOrder(idOrder).subscribe(res => {
      this.ordine = res;
      this.form.ordine = res;
      this.getAnagrafica();
    });
    this.loadAll = true;
  }

  private generatePdf(firma: boolean): void {
    let y = this.initPdf(this.ordine, this.anagrafica, '/assets/img/loghi/logo-ariel.jpg', 'Modulo d\'ordine fotovoltaico Ariel', this.colorString);

    this.printService.writeFooterPage(this.doc, this.ordine, this.currentPdfPage, 6);

    if (this.fotovoltaicoProduct) {
      const title = ['CONTRATTO DI VENDITA E INSTALLAZIONE DEL SEGUENTE IMPIANTO FOTOVOLTAICO COSÌ COMPOSTO: ']
      this.createTableText(title, this.fotovoltaicoProduct.dettaglio.trim(), y, this.doc, this.colorString);
    }

    if (this.pagamento) {
      this.createTablePagamento(this.doc, this.pagamento, y, 0, this.colorString)
    }

    if (this.privacyText !== '') {
      this.printService.addNewPage(this.doc, true, this.img, this.ordine, this.currentPdfPage, this.title)
      this.printPrivacyInformation(y);
    }

    this.creatingPdf = false;
    if (firma) {
      if (!this.integratedSignature) {
        this.createPdf(this.doc);
      } else {
        this.toggleSignPdf(this.doc);
      }
    } else {
      const pdfBlob = this.doc.output('blob');

      const pdfUrl = URL.createObjectURL(pdfBlob);
      this.openDialog(pdfUrl);
    }
  }

  private createPdf(pdf): void {
    const title = localStorage.getItem("id_order");
    const newPdf = btoa(pdf.output());
    const pages = this.printService.returnCurrentPage();
    const coordinates = this.printService.returnCoordinates();

    this.sendingPdf = true;
    this.openSign = true;
    pdf.setProperties({ title });

    this.formController
      .sendSignature(newPdf, this.idOrder, this.form, pages, coordinates, title, false)
      .then(
        (res) => {
          this.generatingPdf = false;
          this.sendingPdf = false;
          this.router.navigate(["new-estimate"]);
        },
        (error) => {
          this.generatingPdf = false;
          this.sendingPdf = false;
          this.ordine.statopraticaCodice = "A";
          this.error = error;
          jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
          jQuery("#errorModal").modal("toggle");
        }
      );
  }

  // Http method
  private getAnagrafica(): void {
    this.anagraficaService
      .findById(this.ordine.clienteId).subscribe((res) => {
        this.anagrafica = res;
        this.checkAnagrafica();
      });
  }

  private getProduct(): void {
    this.fotovoltaicoService.findByKey(this.idOrder, 'ordineId.equals=', 'fotovoltaicos').subscribe(res => {
      if (res[0]?.id) {
        this.fotovoltaicoService.findByKey(res[0]?.id, 'fotovoltaicoId.equals=', 'fotovoltaicoprods').subscribe(resProd => {
          if (resProd[0] && resProd[0].dettaglio) {
            this.fotovoltaicoProduct = resProd[0];
          }
        })
      }
    })
  }

  private getPagamento(): void {
    this.fotovoltaicoService.getPagamento(this.idOrder).subscribe((res) => {
      this.pagamento = res;
    });
  }

  private getPrivacy(): void {
    this.httpService.getPrivacy('assets/json/fotovoltaicoUt.json').subscribe((res) => {
      this.privacyText = res;
    });
  }

  private printPrivacyInformation(y: number): number {
    y = this.printService.returnCurrentY();
    this.printService.writePrivacyOne(this.doc, y, this.ordine, this.img, this.title, this.currentPdfPage, this.privacyText);
    return y;
  }

  private toggleSignPdf(pdf): void {
    const interval = this.startTimer();
    const title = localStorage.getItem("id_order");
    const pages = this.printService.returnCurrentPage();
    this.showModalPrint = !this.showModalPrint;
    this.openSign = true;
    this.showFrame = false;
    this.generatingPdf = true;
    this.urlFrame = '';
    jQuery("#signModal").modal({ backdrop: "static", keyboard: false });
    jQuery("#signModal").modal("toggle");

    if (this.isApiV3) {
      const newPdf = new Blob([pdf.output('blob')], { type: "application/pdf" });
      var split = this.printService.returnCoordinates().split(",");
      const coordinates = {
        width: 120,
        height: 37,
        x: split[0],
        y: split[3],
      }
      this.formController.manageSignature(newPdf, this.form.idOrder, this.form, pages, coordinates, title, environment.signGenericId).then((res: any) => {
        this.urlFrame = res.signers[0].signature_link + '&disable_domain_validation=true';
        setTimeout(() => {
          this.showFrame = true;
          this.sendingPdf = false;
          this.generatingPdf = false;

        }, 2000);
        clearInterval(interval);
      }, error => {
        this.generatingPdf = false;
        this.sendingPdf = false;
        this.ordine.statopraticaCodice = "A";
        clearInterval(interval);
        this.closeSignPdf();
        jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
        jQuery("#errorModal").modal("toggle");
      });
    } else {
      const newPdf = btoa(pdf.output());
      const coordinates = this.printService.returnCoordinates();
      this.formController
        .sendSignature(newPdf, this.form.idOrder, this.form, pages, coordinates, title, true)
        .then(
          (res) => {
            this.member = res.members[0].id;
            this.urlFrame =
              environment.apiUrl +
              "/procedure/sign?members=" +
              this.member +
              "&signatureUi=" +
              environment.signArielFotovoltaicoUt;  // serve per recuperare il modello del prodotto corretto. In modo che il frame vega visualizzato con i colori corretti.
            this.showFrame = true;
            this.sendingPdf = false;
            this.generatingPdf = false;

            clearInterval(interval);
          },
          (error) => {
            this.generatingPdf = false;
            this.sendingPdf = false;
            this.ordine.statopraticaCodice = "A";
            clearInterval(interval);
            jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
            jQuery("#errorModal").modal("toggle");
          }
        );
    }

  }

  public togglePrintPreview(): void {
    this.generatePdf(false);
  }

  private openDialog(pdf: any): void {
    this.dialog.open(PreviewComponent, {
      width: '800px',
      maxWidth: '100%',
      data: {
        pdf,
      },
    });
  }

  public returnIdOrdine(): string {
    return localStorage.getItem('id_order');
  }

  private checkAnagrafica(): boolean {
    this.validAnagrafica = this.formController.checkAnagrafica(this.form);
    return this.validAnagrafica;
  }

  private startTimer(): any {
    const intervalId = setInterval(() => {
      if (this.timeLeft === 0) {
        clearInterval(intervalId);
        // richiamo api per invio email
        this.orderService.sendEmail(this.form.idOrder).subscribe();
        jQuery('#timeoutModal').modal({ backdrop: 'static', keyboard: false });
        jQuery('#timeoutModal').modal('toggle');

        setTimeout(() => {
          jQuery('#timeoutModal').modal('hide');
        }, 10000);

        setTimeout(() => {
          this.formController.goToDashboardUfficioTecnico();
        }, 11000);
      }
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }
    }, 1000);
    return intervalId;
  }

  public closeSignPdf(): void {
    jQuery('#signModal').modal({ backdrop: 'static', keyboard: false });
    jQuery('#signModal').modal('toggle');
    this.reload.emit(this.openSign);
    this.setStateToActive();
    this.formController.removeFrame();
  }

  private setStateToActive() {
    this.orderService.getSingleOrder(this.idOrder).subscribe((res) => {
      this.ordine = res;
      this.ordine.statopraticaCodice = "A";
      this.orderService.update(JSON.stringify(this.ordine)).subscribe(res => {
        this.getOrder(this.idOrder);
      });
    })
  }

  private setLineColor(rgbColorLine) {
    this.color = rgbColorLine;
  }
}
