import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from '../../../../classes/trascodifica';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { NuoviAccessoriService } from '../services/nuoviAccessori/nuoviAccessori.service';
import { Accessori,NuoviAccessoriDTO } from '../classes/dati-tecnici';
import { constants } from 'src/app/modules/classes/constants';
@Component({
	selector: 'app-form-nuovi-accessori',
	templateUrl: './form-nuovi-accessori.component.html',
	styleUrls: ['./form-nuovi-accessori.component.scss']
})
export class FormNuoviAccessoriComponent implements OnInit {

	@Input() form: any;
	@Input() trascodifiche: Trascodifica[];
	@Input() brand:string;

	availableAccessories: Trascodifica[];
	classBg: string = 'blueBg';
	classContent: string = 'blueCont';

	constructor(
		private formController: FormControllerService,
		private nuoviAccessoriService : NuoviAccessoriService
	) { }

	ngOnInit(): void {
		if(this.brand === 'bagniHelp75'){
			this.classBg = 'help75Bg';
			this.classContent = 'hepl75Cont';
		}else if (this.brand === 'bagno-pratico'){
			this.classBg = 'bagnoPraticoBg';
			this.classContent = 'bagnoPraticoCont';
		}
		this.form.sections.nuoviAccessori = [];
		this.form.sections.nuoviAccessoriToDelete = [];
		this.nuoviAccessoriService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
			for (var i = 0; i < res.length; i++){
				const acc = this.formController.mappingNuoviAccessoriDTOtoForm(res[i] , new Accessori());
				this.form.sections.nuoviAccessori.push(acc);
			}
			this.availableAccessories = this.formController.getTrascodificaByCodice(constants.COD_NUOVI_ACCESSORI_TIPOLOGIA, this.trascodifiche);
			
			if(this.brand === 'bagniHelp75' && this.form.sections.nuoviAccessori.length === 0 ){
				//recupero in base alla descrizione i due accessori da preselezionare.
				let accAvail = this.availableAccessories.filter(tr => tr.descrizione === constants.TYPE_SEDILE_RIBALTABILE || tr.descrizione === constants.TYPE_MANIGLIA_SICUREZZA_BIANC);
				accAvail.forEach(el => {
					//se non ci sono ancora accessori allora devo aggiungere quelli di default
					let accDef = new NuoviAccessoriDTO();
					accDef.ordineId = this.form.idOrder;
					accDef.tipologiaId = el.id;
					this.form.sections.nuoviAccessori.push(accDef);			
				})
			}
		
		})
	}


	updateAccessori(event,id): void {
		if (-1 === this.form.sections.nuoviAccessori.findIndex(r => r.tipologiaId === id)) {
			if (event.checked) {
				var newVal = new Accessori();
				newVal.tipologiaId = id;
				this.form.sections.nuoviAccessori.push(newVal);
			}
		}else {
				var accToDelete = this.form.sections.nuoviAccessori.filter(tr => (tr.tipologiaId === id));
				this.form.sections.nuoviAccessoriToDelete.push(accToDelete[0]);
				this.form.sections.nuoviAccessori.splice(this.form.sections.nuoviAccessori.findIndex(r => r.tipologiaId === id), 1);
			}
	}
	
	
	checked(id):string{
		if (-1 !== this.form.sections.nuoviAccessori.findIndex(r => r.tipologiaId === id)) {
			return 'checked';
		}else{
			return '';
		}
	}
	
}
