<div id="headingOne">
    <h5 class="mb-0">
        <div class="text-center blueBg">
            PIATTI EFFETTO PIETRA
        </div>

    </h5>
</div>

<div class="blueCont"
    [ngClass]="{'onlyRedBorder': form.sections.piattoEffettoPietra.coloreId && !form.sections.piattoEffettoPietra.dimensioneId}">
    <div class="d-inline-block">
        <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
            (ngModelChange)="changeModel($event)" [(ngModel)]="form.sections.piattoEffettoPietra.coloreId">
            <mat-radio-button class="mb-2 mr-2" [value]="colore.id" *ngFor="let colore of listColore">
                {{colore.descrizione}}</mat-radio-button>
            <span class="undo material-icons" matTooltip="Cancella selezione"
                *ngIf="form.sections.piattoEffettoPietra.coloreId" (click)="form.sections.piattoEffettoPietra = {}">
                backspace
            </span>
        </mat-radio-group>
    </div>

    <div *ngIf="form.sections.piattoEffettoPietra.coloreId && listMisure && listMisure.length>0">
        <hr />
        <p>DIMENSIONE</p>
        <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
            [(ngModel)]="form.sections.piattoEffettoPietra.dimensioneId">
            <mat-radio-button class="mr-2" [value]="misure.id" *ngFor="let misure of listMisure">{{misure.descrizione}}
            </mat-radio-button>
        </mat-radio-group>
        <div class="smallFont text-danger"
            *ngIf="form.sections.piattoEffettoPietra.coloreId && !form.sections.piattoEffettoPietra.dimensioneId">
            Selezione obbligatoria
        </div>
    </div>
</div>