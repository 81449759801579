<div class="pt-5 text-center mb-4">
</div>

<div class="row buttonRow mb-3">
    <div class="col-6">
        <button *ngIf="currentStep>0" [disabled]="currentStep == totalSteps.length && openSign" (click)="setStep(currentStep-1)"
            class="btnMainColor btnColor" mat-flat-button>
            < Indietro</button>
    </div>

    <div class="col-6 text-right">
        <button [disabled]="generatingPdf || stepController()" *ngIf="currentStep < totalSteps.length"
            class="btnMainColor btnColor" mat-flat-button (click)="saveModule(false)">Avanti ></button>
        <div>
            <div class="smallFont text-danger" *ngIf="stepController() && (currentStep !== 7 && currentStep !== 9)">
                Compila tutti i campi obbligatori
            </div>
            <div class="smallFont text-danger" *ngIf="stepController() && currentStep === 7">
                &egrave; necessario accettare tutte le informative per procedere alla firma
            </div>
        </div>
    </div>

</div>

<div class="formContainer">
    <div class="text-center mb-4">
        <h4>
            <span class="imgModulo">
                <img alt="" class="" src="assets/img/loghi/logoPratico.jpg" />
            </span>
            Modulo d'ordine Bagno Pratico
        </h4>
    </div>
    <hr />
    <div class="moduleContainer mb-3" *ngIf="trascodifiche">
        <div id="toPrint">
            <div class="" *ngIf="currentStep == 0">
                <app-anagrafica-sh [validAnagrafica]="validAnagrafica" [brand]="'bagno-pratico'" [form]="form">
                </app-anagrafica-sh>
            </div>

            <div class="" *ngIf="currentStep == 1">
                <app-form-dati-tecnici-att [form]="form" [trascodifiche]="trascodifiche"
                    [brand]="'bagno-pratico'"></app-form-dati-tecnici-att>
            </div>
            <div class="" *ngIf="currentStep == 2">
                <app-pratico-rivestimenti (update)="toggleChange($event)" [mobileAttivo]="mobileAttivo" [form]="form"
                    [trascodifiche]="trascodifiche"></app-pratico-rivestimenti>
            </div>
            <div class="" *ngIf="currentStep == 3">
                <app-nuovi-sanitari-pratico [form]="form" [trascodifiche]="trascodifiche"></app-nuovi-sanitari-pratico>
            </div>
            <br>
            <div class="" *ngIf="currentStep == 3">
                <app-form-nuova-cassetta [form]="form" [trascodifiche]="trascodifiche"
                    [brand]="'bagno-pratico'"></app-form-nuova-cassetta>
            </div>
            <br>
            <div class="" *ngIf="currentStep == 3">
                <app-form-nuova-rubinetteria [form]="form" [trascodifiche]="trascodifiche" [brand]="'bagno-pratico'">
                </app-form-nuova-rubinetteria>
            </div>

            <div class="" *ngIf="currentStep == 4">
                <app-form-opere-idrauliche [form]="form" [trascodifiche]="trascodifiche"
                    [brand]="'bagno-pratico'"></app-form-opere-idrauliche>
                <div class="row originalRow">
                    <div class="col-12">
                        <div class="blue-border mb-2">
                            <mat-checkbox class="col-6" [(ngModel)]="form.sections.soffittoPareti.inb"
                                color="primary">IMBIANCATURA
                                SOFFITTO E PARETI SOPRA <br />LE PIASTRELLE CON PITTURA LAVABILE BIANCA</mat-checkbox>
                            <mat-checkbox class="col-6" [(ngModel)]="form.sections.soffittoPareti.scr"
                                color="primary">NECESSARIA
                                SCROSTATURA E RASATURA<br />SOFFITTO E PARETI</mat-checkbox>
                        </div>
                    </div>
                </div>
                <div class="row originalRow">
                    <div class="col-lg-6">
                        <div class="">
                            <app-form-nuovi-termosifoni-arredi [form]="form" [trascodifiche]="trascodifiche"
                                [brand]="'bagno-pratico'">
                            </app-form-nuovi-termosifoni-arredi>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <app-nuovo-piatto-doccia-pratico [form]="form"
                            [trascodifiche]="trascodifiche"></app-nuovo-piatto-doccia-pratico>
                    </div>
                </div>
                <div class="row originalRow">
                    <div class="col-lg-12">
                        <app-form-porta [form]="form" [trascodifiche]="trascodifiche"></app-form-porta>
                    </div>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 5">

                <app-form-nuovi-dati [form]="form" [prodotto]="'bagno-pratico'"></app-form-nuovi-dati>
                <div class="row">
                    <div class="col-lg-12">
                        <app-form-omaggio [form]="form" [trascodifiche]="trascodifiche"
                            [prodotto]="'bagno-pratico'"></app-form-omaggio>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb-2">
                        <app-box-doccia-pratico [form]="form" [trascodifiche]="trascodifiche"
                            [brand]="'bagno-pratico'"></app-box-doccia-pratico>
                    </div>
                    <app-form-nuovi-accessori [form]="form" [trascodifiche]="trascodifiche"
                    [brand]="'bagno-pratico'"></app-form-nuovi-accessori>
                </div>

                <app-form-sicurezza [form]="form" [trascodifiche]="trascodifiche"></app-form-sicurezza>
                <div class="row">
                    <div class="col-lg-6">
                        <app-form-piantina-attuale [form]="form"
                            [prodotto]="'bagno-pratico'"></app-form-piantina-attuale>
                    </div>
                    <div class="col-lg-6">
                        <app-form-piantina-nuovo [form]="form" [prodotto]="'bagno-pratico'"></app-form-piantina-nuovo>
                    </div>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 6">
                <app-pay-one [form]="form" [brand]="'bagno-pratico'" [trascodifiche]="trascodifiche"></app-pay-one>
            </div>

            <div class="lastStep" *ngIf="currentStep == 7">
                <app-privacy [form]="form" [brand]="'bagno-pratico'"></app-privacy>
            </div>

            <div *ngIf="currentStep == 8">
                <app-contacts-check [validAnagrafica]="validAnagrafica" [form]="form"></app-contacts-check>
            </div>

            <div class="" *ngIf="currentStep == 9 && isFinanziamentoPayment">
                <app-finanziamento [form]="form" [listFinanziarie]="listFinanziarie" [pdfDetails]="finanziamentoPdfHeader" [productColor]="'#2C539E'"></app-finanziamento>
            </div>

            <ng-container *ngIf="currentStep == 9 && !isFinanziamentoPayment; else nextStepWithFinance">
                <ng-container *ngTemplateOutlet="signatureAndPrint"></ng-container>
            </ng-container>
            
            <ng-template #nextStepWithFinance>
                <ng-container *ngIf="currentStep == 10">
                    <ng-container *ngTemplateOutlet="signatureAndPrint"></ng-container>
                </ng-container>
            </ng-template>
            
            <ng-template #signatureAndPrint>
                <div class="lastStep">
                    <div class="text-center">
                        <div class="digitalSignatureButtons" *ngIf="openSign" style="height: 124px">
                            <div class="signButton" (click)="edit()">
                                <span class="material-icons d-block">edit</span>
                                <span class="d-block mb-2">Modifica Contratto</span>
                            </div>
                        </div>
                        <div class="" *ngIf="form.idOrder">
                            <app-print-pratico [anagrafica]="anagrafica" [idOrder]="form.idOrder" [trascodifiche]="trascodifiche" [form]="form" (reload)="reload($event)"></app-print-pratico>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<div class="row buttonRow">
    <div class="col-6">
        <button *ngIf="currentStep>0" [disabled]="currentStep == totalSteps.length && openSign" class="btnMainColor btnColor"
            mat-flat-button (click)="setStep(currentStep-1)" mat-button>
            < Indietro</button>
    </div>

    <div class="col-6 text-right">

        <button [disabled]="generatingPdf || stepController()" *ngIf="currentStep < totalSteps.length"
            class="btnMainColor btnColor" mat-flat-button (click)="saveModule(false)">Avanti ></button>

            <div class="smallFont text-danger" *ngIf="stepController() && (currentStep !== 7 && currentStep !== 9)">
            Compila tutti i campi obbligatori
        </div>
        <div class="smallFont text-danger" *ngIf="stepController() && currentStep === 7">
            &egrave; necessario accettare tutte le informative per procedere alla firma
        </div>
    </div>

</div>

<div class="stepper">
    <div class="row">
        <div class="stepCol align-self-center" *ngFor="let step of totalSteps">
            <div class="stepNumber" [ngClass]="
            {
            'doneStep': currentStep > step,
            'currentStep': currentStep == step,
            'errorStep': (
                (!stepController() && currentStep == step) && currentStep > step)
            }">
                <span class="number">{{step+1}}</span>
            </div>
        </div>

        <div class="stepCol align-self-center">
            <div class="stepNumber" [ngClass]="
            {
            'currentStep': currentStep == totalSteps.length
            }">
                <span class="number">
                    <span class="material-icons">
                        check
                    </span>
                </span>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="confirmModal" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">Avviso sezione box doccia</h3>
                <button type="button" class="close" (click)="openConfirmBoxDoccia(false)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    Sei certo di selezionare più di un box doccia con cristalli e profili diversi?
                </div>
            </div>
            <div class="modal-footer">
                <button mat-button (click)="openConfirmBoxDoccia(false)">Annulla</button>
                <button mat-button (click)="openConfirmBoxDoccia(true)">Avanti ></button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="errorModal" tabindex="-1">
    <!--<app-error-modal [error]="error"></app-error-modal>-->
</div>

<div class="modal fade" id="printModal" tabindex="-1">
    <div class="modal-dialog modal-xxl modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">Anteprima contratto {{returnIdOrdine()}}</h3>
                <button type="button" class="close" (click)="togglePrintPreview(false)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="mt-5" *ngIf="trascodifiche && showModalPrint">
                    <app-print-pratico [trascodifiche]="trascodifiche" [form]="form"
                        [modale]="true"></app-print-pratico>
                </div>
            </div>
            <div class="modal-footer">
                <button mat-button (click)="togglePrintPreview(false)">Chiudi anteprima</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="timeoutModal" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">Tempo limite generazione contratto</h3>
                <button type="button" class="close" data-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    Richiesta presa in carico, il nostro Customer Care provveder&agrave; ad inviare al cliente il
                    contratto per la firma.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mt-5 fidexPdf" *ngIf="trascodifiche && generatingPdf">
    <app-print-pratico [trascodifiche]="trascodifiche" [form]="form" [page]="currentPrinting"></app-print-pratico>
</div>
