import { Component, OnInit, Input } from '@angular/core';
import { constants } from 'src/app/modules/classes/constants';
import { Trascodifica } from '../../../../../classes/trascodifica';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';
import { OpereIdraulicheService } from '../../../../bagni-italiani/main-bagni/services/opereIdrauliche/opereIdrauliche.service';
import { OptionsAvailable } from 'src/app/modules/classes/options-available';
import { OptionsAvailableService } from 'src/app/services/optionsAvailable/options-available.service';
@Component({
	selector: 'app-nuovopiattodoccia',
	templateUrl: './nuovopiattodoccia.component.html',
	styleUrls: ['./nuovopiattodoccia.component.scss']
})
export class NuovopiattodocciaComponent implements OnInit {

	@Input() form: any;
	@Input() trascodifiche: Trascodifica[];

	collapse:boolean = false;
	isVisible:boolean = false;
	piattostretto: boolean;
	listPiattoDocciaColor: Trascodifica[];
	listPiattoDocciaDim: Trascodifica[];
	listPiattoDocciaStrDim: Trascodifica[];
	listColore : Trascodifica[];
	listMisure: Trascodifica[];
  	options: Trascodifica[];
  	optionPiattoDoccia: OptionsAvailable = { id:undefined, typeId:undefined, ordineId:undefined, selected:false};
  	listOptionsOrder: OptionsAvailable[];
	
	constructor(
		private formController: FormControllerService,
		private opereIdraulicheService: OpereIdraulicheService,
		private optionsService: OptionsAvailableService) {
	}

	ngOnInit(): void {
		
		this.options = this.formController.getTrascodificaByCodice(constants.TYPE_OPTIONS, this.trascodifiche).filter(op => op.descrizione === constants.DESC_NUOVO_PIATTO_DOCCIA);
    	 this.getOption();
		 this.opereIdraulicheService.findByKey(this.form.idOrder, 'ordineId.equals=', 'opereidrauliches').subscribe(res => {
			if (res[0] != undefined) {
				this.form.sections.piattoDoccia = res[0];

				if (this.form.sections.piattoDoccia.colorepiattolargId !== undefined) {
					this.changeModel(this.form.sections.piattoDoccia.colorepiattolargId);
				}

				if (this.form.sections.piattoDoccia.misurapiattostrId) {
					this.changeStretto(true);
				}
			}

			this.listColore = this.formController.getTrascodificaByCodice(constants.COLORE_PIATTI_EFF_PIE, this.trascodifiche);				
			  this.form.sections.piattoDoccia.colorepiateffpietraId = res[0] !== undefined ? res[0].colorepiateffpietraId : null;	
				if(this.form.sections.piattoDoccia.colorepiateffpietraId !== undefined){
					this.changeModelEffPietra(this.form.sections.piattoDoccia.colorepiateffpietraId);
					this.form.sections.piattoDoccia.dimpiatteffpietraId = res[0] !== undefined ? res[0].dimpiatteffpietraId : null;
			  }
		});
		this.listPiattoDocciaColor = this.formController.getTrascodificaByCodice(constants.COLORE_PIATTO_LARGO, this.trascodifiche);
		this.listPiattoDocciaStrDim = this.formController.getTrascodificaByCodice(constants.DIM_PIATTO_STRETTO, this.trascodifiche);
	}

	changeModel(event): void {
		this.listPiattoDocciaDim = this.trascodifiche.filter(tr => (tr.codice === constants.DIM_PIATTO_LARGO) && (tr.parentId === event));
		if (event != null) {
			this.piattostretto = false;
			this.form.sections.piattoDoccia.misurapiattostrId = null;
		}
	}

	changeModelEffPietra(event): void {
		 this.listMisure =  this.trascodifiche.filter(tr => (tr.codice === constants.DIM_PIATTI_PIETR) && (tr.parentId === event));
	  }

	changeStretto(event): void {
		this.piattostretto = event;
		if (event != null) {
			this.form.sections.piattoDoccia.colorepiattolargId = null;
			this.form.sections.piattoDoccia.misurapiattolargId = null;
		}
	}

	showDiv(value){    
		if(value ){
		  this.isVisible = true;
		}else{
		  this.isVisible = false;
		}
		this.form.sections.optionsAvailable.filter(op => op.typeId === this.options[0].id)[0].selected = value;
	}
	
	getOption(){
		this.optionsService.findByKey(this.form.idOrder,'ordineId.equals=').subscribe(res => { 
			if(res !== undefined){
			  this.listOptionsOrder = res;			  
			  let optionNuovaCassetta = res.filter(op => op.typeId === this.options[0].id)  
			  if(optionNuovaCassetta !== undefined && optionNuovaCassetta.length > 0){
				this.isVisible = optionNuovaCassetta[0].selected;
				this.form.sections.optionsAvailable=res;			
				this.optionPiattoDoccia = optionNuovaCassetta[0];
	
			  }else{
				this.isVisible = false;
				this.optionPiattoDoccia = new OptionsAvailable();
				this.optionPiattoDoccia.ordineId = this.form.idOrder;
				this.optionPiattoDoccia.typeId = this.options[0].id;
				this.optionPiattoDoccia.selected = undefined;
				this.form.sections.optionsAvailable.push(this.optionPiattoDoccia);
			  }
			}
		});
	}

	resetPiattoDocciaEffPietra(): void {
		this.form.sections.piattoDoccia.colorepiateffpietraId = null;
		this.form.sections.piattoDoccia.dimpiatteffpietraId = null;
	}
}
