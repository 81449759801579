import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-bagni',
  templateUrl: './main-bagni.component.html',
  styleUrls: ['./main-bagni.component.scss']
})
export class MainBagniComponent implements OnInit {
  products: any[];
  constructor(
      private router: Router,
  ) {

  }

  ngOnInit(): void {
  }

  goToProduct(value): void {
    this.router.navigate(['new-order/BAGNI_ITALIANI/' + value]);
  }

}
