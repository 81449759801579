import { Component, OnInit,Input } from '@angular/core';
import { Trascodifica } from '../../../../../classes/trascodifica';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';
import { CristalBoxService } from '../../../services/cristalbox.service';
import { OptionsAvailable } from 'src/app/modules/classes/options-available';
import { OptionsAvailableService } from 'src/app/services/optionsAvailable/options-available.service';
import { constants } from 'src/app/modules/classes/constants';
@Component({
  selector: 'cristalbox-vasca',
  templateUrl: './vasca.component.html',
  styleUrls: ['./vasca.component.scss']
})
export class CristalBoxVascaComponent implements OnInit {
	
  @Input() form:any;
  @Input() trascodifiche:Trascodifica[];
  
  listSportello : Trascodifica[];
  listDimEmotion : Trascodifica[];
  listApeEmotion: Trascodifica[];
  listExpErog: Trascodifica[];
  listSunMisure: Trascodifica[];
  listSunIngresso: Trascodifica[];
  listSunProf: Trascodifica[];
  options: Trascodifica[];
  show100X70:boolean = false;
  show120X70:boolean = false;
  show150X70:boolean = false;
  show170X70:boolean = false;
  isVisible:boolean = false;
  collapse:boolean = false;
  optionVasca: OptionsAvailable = {id:undefined, typeId:undefined,ordineId:undefined, selected:false};
  
  constructor( private formController: FormControllerService,
	private cristalBoxService : CristalBoxService, 
	private optionsService:OptionsAvailableService
  ) { }

  ngOnInit(): void {
	
		this.cristalBoxService.findBykey('vascas' , 'ordineId.equals=',this.form.idOrder ,).subscribe(res => {
			if(res[0] !== undefined){
				this.form.sections.vasca = res[0];
			}
		});
		
		this.listSportello = this.formController.getTrascodificaByCodice(constants.COD_OPEN_POS_SPOR_EST , this.trascodifiche);
		this.listDimEmotion = this.formController.getTrascodificaByCodice(constants.COD_VASCA_EMOTION_DIM , this.trascodifiche);
		this.listExpErog = this.formController.getTrascodificaByCodice(constants.COD_VASCA_EXP_EROG , this.trascodifiche);
		this.listSunMisure = this.formController.getTrascodificaByCodice(constants.COD_DOCCIA_SUN_MIS , this.trascodifiche);
		this.listSunIngresso = this.formController.getTrascodificaByCodice(constants.COD_DOCCIA_SUN_ING , this.trascodifiche);
		this.listSunProf = this.formController.getTrascodificaByCodice(constants.COD_DOCCIA_SUN_PROF , this.trascodifiche);
		this.listApeEmotion = this.formController.getTrascodificaByCodice(constants.COD_VASCA_EMOTION_APE , this.trascodifiche);
		
		this.options = this.formController.getTrascodificaByCodice(constants.TYPE_OPTIONS, this.trascodifiche).filter(op => op.descrizione === 'VASCA');
		this.getOption(this.options[0].id).then(res => {
			if (res !== undefined && res.length > 0) {
			  this.isVisible = res[0].selected;
			  this.form.sections.optionsAvailable.push(res[0])
	  
			  this.form.sections.optionsAvailable = this.form.sections.optionsAvailable.filter((res: any) => res.id != undefined && res.id != null)
			  this.optionVasca = res[0];
			} else {
			  this.isVisible = false;
			  this.optionVasca = new OptionsAvailable();
			  this.optionVasca.ordineId = this.form.idOrder;
			  this.optionVasca.typeId = this.options[0].id;
			  this.optionVasca.selected = undefined;
			  this.form.sections.optionsAvailable.push(this.optionVasca);
			}
		  })
  }

  changeModel(event): void {
	//TODO capire come fare il controllo
	if(event === 1310){
		//Vasca 100x70
		this.show100X70 = true;
		this.show120X70 = false;
		this.show150X70 = false;
		this.show170X70 = false;
	}else if (event === 1311){
		//Vasca 120x70
		this.show120X70 = true;
		this.show100X70 = false;
		this.show150X70 = false;
		this.show170X70 = false;
	}else if (event === 1312){
		//Vasca 150x70
		this.show120X70 = false;
		this.show100X70 = false;
		this.show150X70 = true;
		this.show170X70 = false;
	}else if (event === 1313){
		//Vasca 170x70
		this.show120X70 = false;
		this.show100X70 = false;
		this.show150X70 = false;
		this.show170X70 = true;
	}
		
  }

  showDiv(value) {
    if (value) {
      this.isVisible = true;
    } else {
      this.isVisible = false;
    }

    this.form.sections.optionsAvailable.filter(op => op.typeId === this.options[0].id)[0].selected = value;

  }

  getOption(idOption:any):Promise<any>{

    return new Promise((resolve, reject) => {
      this.optionsService.findByKey(this.form.idOrder,'ordineId.equals=').subscribe(res => { 
        if(res !== undefined){
            let option = res.filter(op => op.typeId === idOption)  
            resolve(option);				          
          }
      });
     });
  }  
}


