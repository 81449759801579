import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BrandService } from 'src/app/services/brand/brand.service';
import { constants } from 'src/app/modules/classes/constants';
import { FinanagraficaService } from 'src/app/services/finAnagrafica/finanagrafica.service';
import { FinanziamentoService } from 'src/app/services/finanziamento/finanziamento.service';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { FindatioccupazioneService } from 'src/app/services/finDatiOccupazione/findatioccupazione.service';
import { FinanziamentoPrintComponent } from '../../../pdf/finanziamento-print/finanziamento-print.component';
import { FinAnagrafica, Finanziamento, FinDatiOccupazione } from 'src/app/modules/classes/finanziamento/finanziamento-dto';
import { FinanziamentoPrintService } from 'src/app/services/finanziamento-print/finanziamento-print.service';
import { AllegatoService } from '../../../../../services/allegato/allegato.service';
import { Allegato } from '../../../../classes/allegato';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TabelleFinanziarieService } from 'src/app/services/tabelle-finanziarie/tabelle-finanziarie.service';
import { TabelleFinanziarie } from 'src/app/modules/classes/finanziamento/tabelleFinanziarie-dto';
import { OmaggioService } from 'src/app/modules/seller/bagni-italiani/main-bagni/services/omaggio/omaggio.service';
import { UploadService } from 'src/app/services/uploadFiles/upload.service';
declare var jQuery: any;

interface CheckResult {
  value: boolean;
  color: string;
}

@Component({
  selector: 'form-finanziamento',
  templateUrl: './form-finanziamento.component.html',
  styleUrls: ['./form-finanziamento.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*', minHeight: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class FormFinanziamentoComponent extends FinanziamentoPrintComponent implements OnInit, OnChanges {

  @Input() form: any;
  @Input() finanziamento: Trascodifica;
  @Input() productColor: string;
  @Input() listFinanziarie: Trascodifica[];
  @Input() changeFinanziamento: any;
  @Input() pdfDetails: any;
  @Input() idOrder: string;

  idOrdine: number;
  gdlid: number;
  redditoString: string = '';
  redditoGaranteString: string = '';
  selectedCFoIVA: string = '';
  searchImportoProspetto: string;

  listStatoCivile: Trascodifica[] = [];
  tipoAbitazione: Trascodifica[] = [];
  cittadinanzaList: Trascodifica[] = [];
  tipoAssunzioneList: Trascodifica[] = [];
  tipoRedditoList: Trascodifica[] = [];
  attivitaLavList: Trascodifica[] = [];
  listTypeDoc: Trascodifica[] = [];
  codiciTabellaList: TabelleFinanziarie[] = [];
  valoriImportoList: TabelleFinanziarie[] = [];
  numeroRateList: TabelleFinanziarie[] = [];
  omaggi: any[] = [];
  province: any[];
  filtered: any[];
  validAnagrafica: boolean = false;
  errorColor: string = 'red';
  isloaded: boolean = false;
  anagraficaReadOnlyField: boolean = true;
  ibanVerificato: boolean = false;
  isGarante: boolean = false;
  isGaranteRequired: boolean = false;
  //isSave: boolean = false;
  expandedElement: any | null;
  oldAllegatoFinanziamento: any;

  popupErrorMap: any[] = [
    { attivita: constants.DESC_ATTIVITA_LAV_CASALINGA, finanziaria: constants.DESC_FINANZIARIA_SANTANDER, maxGaranzia: constants.GARANZIA_MAX_CASALINGA_SANTANDER },
    { attivita: constants.DESC_ATTIVITA_LAV_CASALINGA, finanziaria: constants.DESC_FINANZIARIA_COFIDIS, maxGaranzia: constants.GARANZIA_MAX_CASALINGA_COFIDIS },
    { attivita: constants.DESC_ATTIVITA_LAV_CASALINGA, finanziaria: constants.DESC_FINANZIARIA_COMPASS, maxGaranzia: constants.GARANZIA_MAX_CASALINGA_COMPASS }
  ];

  hasValues = (obj) => Object.values(obj).some(v => v !== null && typeof v !== "undefined");

  public constants = constants;

  constructor(
    public formController: FormControllerService,
    private finanziamentoService: FinanziamentoService,
    private finAnagraficaService: FinanagraficaService,
    private finDatiOccupazioneService: FindatioccupazioneService,
    private brandService: BrandService,
    private tabellaFinanziarieService: TabelleFinanziarieService,
    public finanziamentoPrintService: FinanziamentoPrintService,
    private allegatoService: AllegatoService,
    private omaggioService: OmaggioService,
    private snackBar: MatSnackBar,
    private uploadFilesService: UploadService
  ) {
    super(finanziamentoPrintService, formController);
  }

  ngOnInit(): void {
    this.expandedElement = 'collapseAnagrafica';
    this.province = this.formController.returnProvince();
    this.idOrdine = this.form.ordine.id;
    this.gdlid = this.form.ordine.gdlid;

    this.getAll();
  }

  ngOnChanges(changes: SimpleChanges): void {
    let value = changes['changeFinanziamento'].currentValue;
    if (value && typeof value == 'boolean') {
      this.codiciTabellaList = [];
      this.valoriImportoList = [];
      this.numeroRateList = [];
      this.resetForm();
    }
  }

  stepController(stepName: string, isGarante: boolean): boolean {
    if (!isGarante) {
      switch (stepName) {
        case 'collapseDatiResidenza': {
          return this.checkAnagrafica().value;
        }
        case 'collapseRecapiti': {
          return this.checkAnagrafica().value && this.checkDatiResidenza().value;
        }
        case 'collapseDatiCittadinanza': {
          return this.checkAnagrafica().value && this.checkDatiResidenza().value && this.checkRecapiti().value;
        }
        case 'collapseDatiOccupazione': {
          return this.checkAnagrafica().value && this.checkDatiResidenza().value && this.checkRecapiti().value && this.checkDatiCittadinanza().value;
        }
        case 'collapseDatiDatore': {
          return this.checkAnagrafica().value && this.checkDatiResidenza().value && this.checkRecapiti().value && this.checkDatiCittadinanza().value && this.checkDatiOccupazione().value;
        }
        case 'collapseDatiReddito': {
          return this.checkAnagrafica().value && this.checkDatiResidenza().value && this.checkRecapiti().value && this.checkDatiCittadinanza().value && this.checkDatiOccupazione().value
            && !this.formController.shouldShowPopupGaranzie(this.form, this.attivitaLavList, this.listFinanziarie) && (this.checkVisibilitySectionDatoreDiLavoro() ? this.checkDatiDatore().value : true);
        }
        case 'collapseDatiPagamento': {
          return this.checkAnagrafica().value && this.checkDatiResidenza().value && this.checkRecapiti().value && this.checkDatiCittadinanza().value && this.checkDatiOccupazione().value
            && !this.formController.shouldShowPopupGaranzie(this.form, this.attivitaLavList, this.listFinanziarie) && (this.checkVisibilitySectionDatoreDiLavoro() ? this.checkDatiDatore().value : true) && this.checkDatiReddito().value;
        }
        case 'collapseProspetto': {
          return this.checkAnagrafica().value && this.checkDatiResidenza().value && this.checkRecapiti().value && this.checkDatiCittadinanza().value && this.checkDatiOccupazione().value && !this.formController.shouldShowPopupGaranzie(this.form, this.attivitaLavList, this.listFinanziarie)
            && (this.checkVisibilitySectionDatoreDiLavoro() ? this.checkDatiDatore().value : true) && this.checkDatiReddito().value
            && !(this.formController.checkPagamentoBollettino(this.form, this.finanziamento.id, this.listFinanziarie, this.attivitaLavList)
              ? this.form.sections.finanziamentoSections.finanziamento.bollettino == null
              : this.form.sections.finanziamentoSections.finanziamento.iban?.trim() == '' || !this.formController.isValidIban(this.form.sections.finanziamentoSections.finanziamento.iban?.trim()))
        }
        default:
          return false;
      }
    } else {
      switch (stepName) {
        case 'collapseDatiResidenzaGarante': {
          return this.checkAnagraficaGarante().value;
        }
        case 'collapseRecapitiGarante': {
          return this.checkAnagraficaGarante().value && this.checkDatiResidenzaGarante().value;
        }
        case 'collapseDatiCittadinanzaGarante': {
          return this.checkAnagraficaGarante().value && this.checkDatiResidenzaGarante().value && this.checkRecapitiGarante().value;
        }
        case 'collapseDatiOccupazioneGarante': {
          return this.checkAnagraficaGarante().value && this.checkDatiResidenzaGarante().value && this.checkRecapitiGarante().value && this.checkDatiCittadinanzaGarante().value;
        }
        case 'collapseDatiDatoreGarante': {
          return this.checkAnagraficaGarante().value && this.checkDatiResidenzaGarante().value && this.checkRecapitiGarante().value && this.checkDatiCittadinanzaGarante().value && this.checkDatiOccupazioneGarante().value;
        }
        case 'collapseDatiRedditoGarante': {
          return this.checkAnagraficaGarante().value && this.checkDatiResidenzaGarante().value && this.checkRecapitiGarante().value && this.checkDatiCittadinanzaGarante().value && this.checkDatiOccupazioneGarante().value && (this.checkVisibilitySectionDatoreDiLavoroGarante() ? this.checkDatiDatoreGarante().value : true);
        }
        default:
          return false;
      }
    }
  }

  resetForm(): void {

    // svuoto campi prospetto, se cambio finanziaria quando non ho ancora salvato .finanziamento
    if (!this.form.sections.finanziamentoSections.finanziamento.id) {
      this.cleanObjectFinanziamento();
    }

    Promise.all([
      this.deleteFinAnagrafica(),
      this.deleteFinDatiOccupazione(),
      this.deleteFinAnagraficaGarante(),
      this.deleteFinDatiOccupazioneGarante(),
    ])
      .then(() => {
        this.deleteFinanziamento();
        return;
      })
      .then(() => {
        this.prepopulateDatiAnagrafica();

        //riporta l'utente al primo step
        this.toggle('collapseAnagrafica')

        this.isloaded = true;
      })
      .catch(error => {
        console.error('An error occurred:', error);
      });
  }

  save(): void {
    // controllo non bloccante
    /*if(this.checkOmaggi()){
      this.openModalTan0();
    }*/
  
    // controllo bloccante
    if (this.checkEta()) {
      this.openPopupEta();
    } else {

      // Previene il doppio click
      if (this.form.sections.finanziamentoSections.isSaveFinanziamento) return; 
      this.form.sections.finanziamentoSections.isSaveFinanziamento = true;

      this.saveOrUpdateFinanziamento()
        .then(() => {
          return Promise.all([
            this.saveOrUpdateFinAnagrafica(),
            this.saveOrUpdateFinDatiOccupazione()
          ]);
        })
        .then(() => {
          if (this.isGarante && this.form.sections.finanziamentoSections.finanziamento.id) {
            this.form.sections.finanziamentoSections.finAnagraficaGarante.finanziamentoId = this.form.sections.finanziamentoSections.finanziamento.id;
            this.form.sections.finanziamentoSections.finDatiOccupazioneGarante.finanziamentoId = this.form.sections.finanziamentoSections.finanziamento.id;
            this.form.sections.finanziamentoSections.finAnagraficaGarante.garante = true;
            this.form.sections.finanziamentoSections.finDatiOccupazioneGarante.garante = true;
  
            return Promise.all([
              this.saveOrUpdateFinAnagraficaGarante(),
              this.saveOrUpdateFinDatiOccupazioneGarante()
            ]);
          }
        })
        .then(() => {
          return Promise.all([
            this.generatePdf()
          ]);
        })
        .catch(error => {
          console.error('An error occurred:', error);
          this.formController.openSnackBar(constants.ERROR_GENERIC_API);
        });
    }
  }
  

  saveOrUpdateFinanziamento(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.hasValues(this.form.sections.finanziamentoSections.finanziamento)) {
        if (this.form.sections.finanziamentoSections.finanziamento.id) {
          this.finanziamentoService.update(this.form.sections.finanziamentoSections.finanziamento).subscribe(
            (res: any) => {
              this.form.sections.finanziamentoSections.finanziamento = res;
              this.form.sections.finanziamentoSections.finAnagrafica.finanziamentoId = res.id;
              this.form.sections.finanziamentoSections.finDatiOccupazione.finanziamentoId = res.id;
              resolve();
            },
            error => reject(error)
          );
        } else {
          this.finanziamentoService.save(this.form.sections.finanziamentoSections.finanziamento).subscribe(
            (res: any) => {
              this.form.sections.finanziamentoSections.finanziamento = res;
              this.form.sections.finanziamentoSections.finAnagrafica.finanziamentoId = res.id;
              this.form.sections.finanziamentoSections.finDatiOccupazione.finanziamentoId = res.id;
              resolve();
            },
            error => reject(error)
          );
        }
      } else {
        resolve();
      }
    });
  }

  saveOrUpdateFinAnagrafica(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.hasValues(this.form.sections.finanziamentoSections.finAnagrafica)) {
        if (this.form.sections.finanziamentoSections.finAnagrafica.id) {
          this.finAnagraficaService.update(this.form.sections.finanziamentoSections.finAnagrafica).subscribe(
            () => resolve(),
            error => reject(error)
          );
        } else {
          this.finAnagraficaService.save(this.form.sections.finanziamentoSections.finAnagrafica).subscribe(
            (res: any) => {
              this.form.sections.finanziamentoSections.finAnagrafica = res;
              resolve();
            },
            error => reject(error)
          );
        }
      } else {
        resolve();
      }
    });
  }

  saveOrUpdateFinDatiOccupazione(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.hasValues(this.form.sections.finanziamentoSections.finDatiOccupazione)) {
        if (this.form.sections.finanziamentoSections.finDatiOccupazione.id) {
          this.finDatiOccupazioneService.update(this.form.sections.finanziamentoSections.finDatiOccupazione).subscribe(
            () => resolve(),
            error => reject(error)
          );
        } else {
          this.finDatiOccupazioneService.save(this.form.sections.finanziamentoSections.finDatiOccupazione).subscribe(
            (res: any) => {
              this.form.sections.finanziamentoSections.finDatiOccupazione = res;
              resolve();
            },
            error => reject(error)
          );
        }
      } else {
        resolve();
      }
    });
  }

  saveOrUpdateFinAnagraficaGarante(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.hasValues(this.form.sections.finanziamentoSections.finAnagraficaGarante)) {
        if (this.form.sections.finanziamentoSections.finAnagraficaGarante.id) {
          this.finAnagraficaService.update(this.form.sections.finanziamentoSections.finAnagraficaGarante).subscribe(
            () => resolve(),
            error => reject(error)
          );
        } else {
          this.finAnagraficaService.save(this.form.sections.finanziamentoSections.finAnagraficaGarante).subscribe(
            (res: any) => {
              this.form.sections.finanziamentoSections.finAnagraficaGarante = res;
              resolve();
            },
            error => reject(error)
          );
        }
      } else {
        resolve();
      }
    });
  }

  saveOrUpdateFinDatiOccupazioneGarante(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.hasValues(this.form.sections.finanziamentoSections.finDatiOccupazioneGarante)) {
        if (this.form.sections.finanziamentoSections.finDatiOccupazioneGarante.id) {
          this.finDatiOccupazioneService.update(this.form.sections.finanziamentoSections.finDatiOccupazioneGarante).subscribe(
            () => resolve(),
            error => reject(error)
          );
        } else {
          this.finDatiOccupazioneService.save(this.form.sections.finanziamentoSections.finDatiOccupazioneGarante).subscribe(
            (res: any) => {
              this.form.sections.finanziamentoSections.finDatiOccupazioneGarante = res;
              resolve();
            },
            error => reject(error)
          );
        }
      } else {
        resolve();
      }
    });
  }

  checkFinanziamentoAttachment(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.allegatoService.getAllegati(this.idOrdine).subscribe((res: any[]) => {
        if (res && res.length > 0) {
          res.forEach((res: any) => {
            if (res.tipoDescrizione.includes(constants.TYPE_DOC_FINANZIAMENTO_STEP)) {
              this.oldAllegatoFinanziamento = res;
              this.form.sections.finanziamentoSections.isAllegatoSaved = true;
              resolve();
            }
          })
        }
      }, error => reject(error))
    });
  }

  checkFinanziamentoAlreadySave(): Promise<void> {
    return new Promise((resolve) => {
      if (this.form.sections.finanziamentoSections.finanziamento && this.form.sections.finanziamentoSections.finanziamento.id
        && this.form.sections.finanziamentoSections.finanziamento.codicetabella && this.form.sections.finanziamentoSections.finanziamento.importoFinanziaria
        && this.form.sections.finanziamentoSections.finanziamento.numrate) {
        this.populateProspettoSave()
        resolve();
      }
    });
  }

  async populateProspettoSave(): Promise<void> {
    try {
      await this.getCodiceTabella();
      await this.getImporti(this.form.sections.finanziamentoSections.finanziamento.codicetabella, true);
      await this.getNumeroRate(this.form.sections.finanziamentoSections.finanziamento.importoFinanziaria, true);
      await this.getLatestDataProspetto(this.form.sections.finanziamentoSections.finanziamento.numrate);
    } catch (error) {
      console.error("Errore durante l'esecuzione delle chiamate API:", error);
    }
  }

  getAll(): void {

    Promise.all([
      this.getFinAnagrafica(),
      this.getFinDatiOccupazione(),
      this.getTrascodifiche(),
      this.getOmaggi(),
    ])
      .then(() => {
        this.checkFinanziamentoAlreadySave();
        this.checkFinanziariaCompassGaranteRequired(this.form.sections.finanziamentoSections.finanziamento)
        this.checkFinanziamentoAttachment(); // controlla se c'e già un tipo documento relativo al FINANZIAMENTO per questo ordine
        this.prepopulateDatiAnagrafica();
        this.isloaded = true;
      })
      .catch(error => {
        console.error('An error occurred:', error);
      });
  }

  async generatePdf(): Promise<void> {
    let y = this.initPdf(
      this.pdfDetails.logoUrl,
      "Modulo finanziamento " + this.pdfDetails.product + " " + this.pdfDetails.brand,
      this.pdfDetails.brand,
      this.pdfDetails.product
    );

    this.finanziamentoPrintService.setCurrentY(y);

    // controlla se c'e già un tipo documento relativo al FINANZIAMENTO per questo ordine
    this.checkFinanziamentoAttachment();

    if (this.form.sections.finanziamentoSections.finanziamento && this.form.sections.finanziamentoSections.finanziamento.id) {
      // FINANZIARIA SELEZIONATA  
      this.genericTableFinanziariaSelected(this.doc, this.idOrder, this.form.sections.finanziamentoSections.finanziamento, y, this.productColor, this.listFinanziarie);
    }

    if (this.form.sections.finanziamentoSections.finAnagrafica && this.form.sections.finanziamentoSections.finAnagrafica.id) {
      // DATI ANAGRAFICI
      this.genericTableDatiAnagrafici(this.doc, this.idOrder, this.form.sections.finanziamentoSections.finAnagrafica, y, this.productColor, this.listStatoCivile);

      // DATI RESIDENZA 
      this.genericTableDatiResidenza(this.doc, this.idOrder, this.form.sections.finanziamentoSections.finAnagrafica, y, this.productColor, this.tipoAbitazione);

      // RECAPITI
      this.genericTableRecapiti(this.doc, this.idOrder, this.form.sections.finanziamentoSections.finAnagrafica, y, this.productColor);

      // DATI CITTADINANZA
      this.genericDatiCittadinanza(this.doc, this.idOrder, this.form.sections.finanziamentoSections.finAnagrafica, y, this.productColor, this.cittadinanzaList);
    }

    if (this.form.sections.finanziamentoSections.finDatiOccupazione && this.form.sections.finanziamentoSections.finDatiOccupazione.id) {
      // DATI OCCUPAZIONE
      this.genericDatiOccupazione(this.doc, this.idOrder, this.form.sections.finanziamentoSections.finDatiOccupazione, y, this.productColor, this.attivitaLavList);

      if (this.checkVisibilitySectionDatoreDiLavoro()) {
        // DATI DATORE DI LAVORO
        this.genericDatiDatoreDiLavoro(this.doc, this.idOrder, this.form.sections.finanziamentoSections.finDatiOccupazione, y, this.productColor);
      }

      // DATI REDDITO
      this.genericDatiReddito(this.doc, this.idOrder, this.form.sections.finanziamentoSections.finDatiOccupazione, y, this.productColor, this.tipoRedditoList);

      // DATI PAGAMENTO
      this.genericDatiPagamento(this.doc, this.idOrder, this.form.sections.finanziamentoSections.finanziamento, y, this.productColor);

      // PROSPETTO CONTABILE
      this.genericProspettoContabile(this.doc, this.idOrder, this.form.sections.finanziamentoSections.finanziamento, y, this.productColor);
    }

    if (this.form.sections.finanziamentoSections.finAnagraficaGarante && this.form.sections.finanziamentoSections.finAnagraficaGarante.id) {
      // DATI ANAGRAFICI GARANTE
      this.genericTableDatiAnagraficiGarante(this.doc, this.idOrder, this.form.sections.finanziamentoSections.finAnagraficaGarante, y, this.productColor, this.listStatoCivile);

      // DATI RESIDENZA GARANTE
      this.genericTableDatiResidenzaGarante(this.doc, this.idOrder, this.form.sections.finanziamentoSections.finAnagraficaGarante, y, this.productColor, this.tipoAbitazione);

      // RECAPITI GARANTE
      this.genericTableRecapitiGarante(this.doc, this.idOrder, this.form.sections.finanziamentoSections.finAnagraficaGarante, y, this.productColor);

      // DATI CITTADINANZA GARANTE
      this.genericDatiCittadinanzaGarante(this.doc, this.idOrder, this.form.sections.finanziamentoSections.finAnagraficaGarante, y, this.productColor, this.cittadinanzaList);
    }

    if (this.form.sections.finanziamentoSections.finDatiOccupazioneGarante && this.form.sections.finanziamentoSections.finDatiOccupazioneGarante.id) {
      // DATI OCCUPAZIONE GARANTE
      this.genericDatiOccupazioneGarante(this.doc, this.idOrder, this.form.sections.finanziamentoSections.finDatiOccupazioneGarante, y, this.productColor, this.attivitaLavList);

      if (this.checkVisibilitySectionDatoreDiLavoroGarante()) {
        // DATI DATORE DI LAVORO GARANTE
        this.genericDatiDatoreDiLavoroGarante(this.doc, this.idOrder, this.form.sections.finanziamentoSections.finDatiOccupazioneGarante, y, this.productColor);
      }

      // DATI REDDITO GARANTE
      this.genericDatiRedditoGarante(this.doc, this.idOrder, this.form.sections.finanziamentoSections.finDatiOccupazioneGarante, y, this.productColor, this.tipoRedditoList);
    }

    const pdfBlob = this.doc.output("blob");

    let typeDocFinanziaria: Trascodifica = this.listTypeDoc.find((t: Trascodifica) => t.descrizione.includes(constants.DESC_DOCUMENTEO_FINANZIAMENTO_STEP));

    let pdfFileName = `${typeDocFinanziaria.note}_${this.idOrder}_modulodatifinanziamento.pdf`;

    const pdfFile = new File([pdfBlob], pdfFileName, { type: 'application/pdf' });

    // debug - save immediately .pdf
    /*const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(pdfBlob);
    downloadLink.download = pdfFileName;
    downloadLink.click();*/

    // end debug

    let allegatoInfo: any = {
      tipoId: typeDocFinanziaria.id,
      ordineId: this.idOrdine,
      gdlId: this.gdlid
    };

    let allegatoData: any = { ...allegatoInfo, pdfFile };

    this.uploadFile(allegatoData, this.idOrder, allegatoData.pdfFile.name).then((allSave: Allegato) => {
      allSave.dataCreazione = this.formController.createDateAsUTC(new Date());

      if (this.oldAllegatoFinanziamento && this.oldAllegatoFinanziamento.id) {
        allSave.id = this.oldAllegatoFinanziamento.id;

        this.allegatoService.update(allSave).subscribe(res => {
          this.snackBar.open('Modulo finanziamento aggiornato correttamente', '', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          this.form.sections.finanziamentoSections.isSaveFinanziamento = false;
          this.form.sections.finanziamentoSections.isAllegatoSaved = true;
        });
      } else {
        this.allegatoService.save(allSave).subscribe(res => {
          this.snackBar.open('Modulo finanziamento salvato correttamente', '', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          this.form.sections.finanziamentoSections.isSaveFinanziamento = false;
          this.form.sections.finanziamentoSections.isAllegatoSaved = true;
        });
      }
    }, error => {
      this.snackBar.open("Errore durante l' elaborazione dei dati del modulo finanziamento. Riprovare più tardi.", '', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    });
  }

  uploadFile(file, idOrdineGdl, originalName): Promise<Allegato> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      
      formData.append('file', file.pdfFile);
      formData.append('gdlId', file.gdlId);
      formData.append('idOrdineGdl', idOrdineGdl);

      this.uploadFilesService.uploadFile(formData).subscribe(
        (response: any) => {
          const allegato = new Allegato();
          allegato.ordineId = file.ordineId;
          allegato.tipoId = file.tipoId;
          allegato.path = response;
          allegato.size = file.size;
          allegato.originalName = originalName;
          resolve(allegato);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getFinAnagrafica(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.form.sections.finanziamentoSections.finanziamento.id) {
        this.form.sections.finanziamentoSections.finAnagrafica.finanziamentoId = this.form.sections.finanziamentoSections.finanziamento.id;
        this.form.sections.finanziamentoSections.finAnagraficaGarante.finanziamentoId = this.form.sections.finanziamentoSections.finanziamento.id;
        this.finAnagraficaService.findByKey(this.form.sections.finanziamentoSections.finanziamento.id, 'finanziamentoId.equals=')
          .subscribe(
            (res: FinAnagrafica[]) => {
              if (res) {
                if (res.length > 0) {
                  res.forEach((el: FinAnagrafica) => {
                    if (!el.garante) {
                      this.form.sections.finanziamentoSections.finAnagrafica = el;
                    } else {
                      this.form.sections.finanziamentoSections.finAnagraficaGarante = el;
                      this.isGarante = this.form.sections.finanziamentoSections.finAnagraficaGarante.garante;
                    }
                  })
                }
              }

              resolve();
            },
            error => reject(error)
          );
      } else {
        resolve();
      }
    });
  }

  getFinDatiOccupazione(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.form.sections.finanziamentoSections.finanziamento.id) {
        this.form.sections.finanziamentoSections.finDatiOccupazione.finanziamentoId = this.form.sections.finanziamentoSections.finanziamento.id;
        this.finDatiOccupazioneService.findByKey(this.form.sections.finanziamentoSections.finanziamento.id, 'finanziamentoId.equals=')
          .subscribe(
            (res: FinDatiOccupazione[]) => {
              if (res) {
                if (res.length > 0) {
                  res.forEach((el: FinDatiOccupazione) => {
                    if (!el.garante) {
                      this.form.sections.finanziamentoSections.finDatiOccupazione = el;
                    } else {
                      this.form.sections.finanziamentoSections.finDatiOccupazioneGarante = el;
                    }
                  })
                }
              }
              if (this.isGarante) {
                this.form.sections.finanziamentoSections.finDatiOccupazioneGarante.garante = true;
              }
              resolve();
            },
            error => reject(error)
          );
      } else {
        resolve();
      }
    });
  }

  prepopulateDatiAnagrafica(): void {
    
    if(!this.form.sections.finanziamentoSections.finAnagrafica.id){
      this.form.sections.finanziamentoSections.finAnagrafica.nome = this.form.anagrafica.anagrafica.nome;
      this.form.sections.finanziamentoSections.finAnagrafica.cognome = this.form.anagrafica.anagrafica.cognome;
      this.form.sections.finanziamentoSections.finAnagrafica.codicefiscale = this.form.anagrafica.anagrafica.cf;
    }

    if (this.form.anagrafica.anagrafica.cf && this.form.anagrafica.anagrafica.cf.length == 16) {
      this.selectedCFoIVA = 'cf'
    } else {
      this.selectedCFoIVA = 'piva'
    }

    this.form.sections.finanziamentoSections.finAnagrafica.tel = this.form.anagrafica.anagrafica.tel1;
    this.form.sections.finanziamentoSections.finAnagrafica.telnd = this.form.anagrafica.anagrafica.tel2;
    this.form.sections.finanziamentoSections.finAnagrafica.email = this.form.anagrafica.anagrafica.email;
    if (!this.form.sections.finanziamentoSections.finAnagrafica.sesso) {
      this.form.sections.finanziamentoSections.finAnagrafica.sesso = 'M';
    }

    // Prospetto
    if (this.form.sections.pagamento[1] && this.form.sections.pagamento[1].id) {
      this.form.sections.finanziamentoSections.finanziamento.prezzotot = this.form.sections.pagamento[1].totaledovuto;
      this.form.sections.finanziamentoSections.finanziamento.acconto = this.form.sections.pagamento[1].caparraconf;
      this.form.sections.finanziamentoSections.finanziamento.residuo = this.form.sections.pagamento[1].saldoinizlav;
    } else if (this.form.sections.pagamento[0] && this.form.sections.pagamento[0].id) {
      this.form.sections.finanziamentoSections.finanziamento.prezzotot = this.form.sections.pagamento[0].totaledovuto;
      this.form.sections.finanziamentoSections.finanziamento.acconto = this.form.sections.pagamento[0].caparraconf;
      this.form.sections.finanziamentoSections.finanziamento.residuo = this.form.sections.pagamento[0].saldoinizlav;
    }

    Promise.all([
      this.checkEditableByFinanziariaName(this.form.sections.finanziamentoSections.finanziamento),
      this.checkFinanziariaCompassGaranteRequired(this.form.sections.finanziamentoSections.finanziamento),
      this.precompilazioneTipoReddito(),
      this.precompilazioneGaranteTipoReddito(),
      this.verificaIban()
    ])
      .then(() => {
        console.info('All save completed.');
      })
      .catch(error => {
        console.error('An error occurred:', error);
      });
  }

  getTrascodifiche(): Promise<void> {
    return new Promise((resolve, reject) => {
      let requests = [
        this.brandService.getDataByCodice(constants.CODICE_STATO_CIVILE).toPromise().then(res => this.listStatoCivile = res),
        this.brandService.getDataByCodice(constants.CODICE_FIN_TIPO_ABITAZIONE).toPromise().then(res => this.tipoAbitazione = res),
        this.brandService.getDataByCodice(constants.CODICE_FIN_CITADINANZA).toPromise().then(res => this.cittadinanzaList = res),
        this.brandService.getDataByCodice(constants.CODICE_FIN_ATTIVITA_LAV).toPromise().then(res => this.attivitaLavList = res),
        this.brandService.getDataByCodice(constants.CODICE_FIN_TIPO_ASSUNZIONE).toPromise().then(res => this.tipoAssunzioneList = res),
        this.brandService.getDataByCodice(constants.CODICE_FIN_TIPO_REDDITO).toPromise().then(res => this.tipoRedditoList = res),
        this.brandService.getDataByCodice(constants.CODICE_TIPO_DOC).toPromise().then(res => this.listTypeDoc = res),
      ];

      Promise.all(requests)
        .then(() => resolve())
        .catch(error => reject(error));
    });
  }

  getOmaggi(): Promise<void> {
    this.omaggi = [];
    return new Promise((resolve, reject) => {
      try {
        this.omaggioService.findByKey(this.form.idOrder, 'ordineId.equals=').subscribe({
          next: (res: any) => {
            for (let i = 0; i < res.length; i++) {
              const omaggio = res[i];
              this.omaggi.push(omaggio);
            }
            resolve();
          },
          error: (err) => {
            console.error("Errore durante il recupero degli omaggi:", err);
            reject(err);
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  deleteFinAnagrafica(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.form.sections.finanziamentoSections.finAnagrafica.id) {
        this.finAnagraficaService.delete(this.form.sections.finanziamentoSections.finAnagrafica.id)
          .subscribe((res: FinAnagrafica) => {
            this.form.sections.finanziamentoSections.finAnagrafica = new FinAnagrafica();
            resolve();
          },
            error => reject(error)
          );
      } else {
        resolve();
      }
    });
  }

  deleteFinDatiOccupazione(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.form.sections.finanziamentoSections.finDatiOccupazione.id) {
        this.finDatiOccupazioneService.delete(this.form.sections.finanziamentoSections.finDatiOccupazione.id)
          .subscribe((res: FinDatiOccupazione) => {
            this.form.sections.finanziamentoSections.finDatiOccupazione = new FinDatiOccupazione();
            resolve();
          },
            error => reject(error)
          );
      } else {
        resolve();
      }
    });
  }

  deleteFinanziamento(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.form.sections.finanziamentoSections.finanziamento.id) {
        this.finanziamentoService.delete(this.form.sections.finanziamentoSections.finanziamento.id)
          .subscribe(
            (res: Finanziamento) => {
              if (this.form.sections.finanziamentoSections.finanziamento) {
                this.cleanObjectFinanziamento();
              }
              resolve();
            },
            error => reject(error)
          );
      } else {
        resolve();
      }
    });
  }

  cleanObjectFinanziamento(): void {
    Object.keys(this.form.sections.finanziamentoSections.finanziamento).forEach(key => {
      if (key !== 'pagamentoId' && key !== 'finanziariaId' &&
        key !== 'finanziariaDescrizione' && key !== 'prezzotot' && key !== 'acconto' &&
        key !== 'residuo'
      ) {
        this.form.sections.finanziamentoSections.finanziamento[key] = null;
      }
    });
  }

  deleteFinAnagraficaGarante(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.form.sections.finanziamentoSections.finAnagraficaGarante.id) {
        this.finAnagraficaService.delete(this.form.sections.finanziamentoSections.finAnagraficaGarante.id)
          .subscribe((res: FinAnagrafica) => {
            this.form.sections.finanziamentoSections.finAnagraficaGarante = new FinAnagrafica();
            this.isGarante = false;
            resolve();
          },
            error => reject(error)
          );
      } else {
        resolve();
      }
    });
  }

  deleteFinDatiOccupazioneGarante(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.form.sections.finanziamentoSections.finDatiOccupazioneGarante.id) {
        this.finDatiOccupazioneService.delete(this.form.sections.finanziamentoSections.finDatiOccupazioneGarante.id)
          .subscribe((res: FinDatiOccupazione) => {
            this.form.sections.finanziamentoSections.finDatiOccupazioneGarante = new FinDatiOccupazione();
            this.isGarante = false;
            resolve();
          },
            error => reject(error)
          );
      } else {
        resolve();
      }
    });
  }

  checkEditableByFinanziariaName(finanziaria: Finanziamento): Promise<void> {
    return new Promise((resolve) => {
      this.anagraficaReadOnlyField = true; // Default: campo non modificabile
      const obj = this.formController.getTrascodificaById(finanziaria.finanziariaId, this.listFinanziarie);
  
      if (this.pdfDetails.product === 'poltrone') {
        // Per "poltrone", solo Santander rende il campo modificabile
        if (obj.descrizione.includes(constants.DESC_FINANZIARIA_SANTANDER)) {
          this.anagraficaReadOnlyField = false;
        }
      } else {
        // Per tutti gli altri casi, solo Cofidis rende il campo modificabile
        if (obj.descrizione.includes(constants.DESC_FINANZIARIA_COFIDIS)) {
          this.anagraficaReadOnlyField = false;
        }
      }
  
      this.isloaded = true;
      resolve();
    });
  }

  checkFinanziariaCompassGaranteRequired(finanziaria: Finanziamento): Promise<void> {

    return new Promise((resolve) => {
      this.isGaranteRequired = false; // Default: garante non richiesto.
      const obj = this.formController.getTrascodificaById(finanziaria.finanziariaId, this.listFinanziarie);
  
      let value = false;

      if(this.isGarante){
        value = true;
      }

      if (obj.descrizione.includes(constants.DESC_FINANZIARIA_COMPASS) && this.form.sections.finanziamentoSections.finanziamento.residuo > constants.GARANZIA_MAX_COMPASS) {
        value = true;
        this.isGaranteRequired = value;
      }

      this.form.sections.finanziamentoSections.finAnagraficaGarante.garante = value;
      this.form.sections.finanziamentoSections.finDatiOccupazioneGarante.garante = value;
      this.isGarante = value;
      resolve();
    });
  }
  
	checkAnagrafica(): any {
    let objBoolValue = this.formController.checkFinAnagrafica(this.form);
    let obj: any = {
      value: objBoolValue,
      color: objBoolValue ? this.productColor : this.errorColor
    }
    return obj;
  }

  checkAnagraficaGarante(): any {
    let objBoolValue = this.formController.checkFinAnagraficaGarante(this.form);
    let obj: any = {
      value: objBoolValue,
      color: objBoolValue ? this.productColor : this.errorColor
    }
    return obj;
  }

  checkDatiResidenza(): CheckResult {
    let objBoolValue = this.formController.checkFinDatiResidenza(this.form.sections.finanziamentoSections.finAnagrafica);
    let obj: CheckResult = {
      value: objBoolValue,
      color: objBoolValue ? this.productColor : this.errorColor
    }
    return obj;
  }

  checkDatiResidenzaGarante(): CheckResult {
    let objBoolValue = this.formController.checkFinDatiResidenzaGarante(this.form.sections.finanziamentoSections.finAnagraficaGarante);
    let obj: CheckResult = {
      value: objBoolValue,
      color: objBoolValue ? this.productColor : this.errorColor
    }
    return obj;
  }

  checkRecapiti(): CheckResult {
    let objBoolValue = this.formController.checkFinRecapiti(this.form.sections.finanziamentoSections.finAnagrafica);
    let obj: CheckResult = {
      value: objBoolValue,
      color: objBoolValue ? this.productColor : this.errorColor
    }
    return obj;
  }

  checkRecapitiGarante(): CheckResult {
    let objBoolValue = this.formController.checkFinRecapitiGarante(this.form.sections.finanziamentoSections.finAnagraficaGarante);
    let obj: CheckResult = {
      value: objBoolValue,
      color: objBoolValue ? this.productColor : this.errorColor
    }
    return obj;
  }

  checkCittadinanzaAltra(): boolean {
    if (this.form.sections.finanziamentoSections.finAnagrafica && this.form.sections.finanziamentoSections.finAnagrafica.tipologiacittadId && this.cittadinanzaList?.length) {
      let obj = this.formController.getTrascodificaById(this.form.sections.finanziamentoSections.finAnagrafica.tipologiacittadId, this.cittadinanzaList);
      if (obj && obj.descrizione.includes(constants.DESC_FIN_CITTADINANZA_ALTRA)) {
        return true;
      }
    }
    return false;
  }

  checkCittadinanzaAltraGarante(): boolean {
    if (this.form.sections.finanziamentoSections.finAnagraficaGarante && this.form.sections.finanziamentoSections.finAnagraficaGarante.tipologiacittadId && this.cittadinanzaList?.length) {
      let obj = this.formController.getTrascodificaById(this.form.sections.finanziamentoSections.finAnagraficaGarante.tipologiacittadId, this.cittadinanzaList);
      if (obj && obj.descrizione.includes(constants.DESC_FIN_CITTADINANZA_ALTRA)) {
        return true;
      }
    }
    return false;
  }

  checkDatiCittadinanza(): CheckResult {
    let obj: CheckResult = { value: false, color: '' };

    if (this.form.sections.finanziamentoSections.finAnagrafica.tipologiacittadId) {
      let cittadinanza = this.returnCittadinanzaSelected();

      if (cittadinanza && cittadinanza.includes(constants.DESC_FIN_CITTADINANZA_ALTRA)) {

        if (!this.form.sections.finanziamentoSections.finAnagrafica.altracitt) {
          obj.value = false;
          obj.color = this.errorColor;
          return obj;
        }

        // Se cittadinanza è "Altra" e "EXTRA UE" è true
        if (this.form.sections.finanziamentoSections.finAnagrafica.tipologiaextraue) {

          // Se i campi "soggionoinizio" e "soggionoscadenza" sono valorizzati
          if (!this.form.sections.finanziamentoSections.finAnagrafica.soggionoinizio || !this.form.sections.finanziamentoSections.finAnagrafica.soggionoscadenza) {
            obj.value = false;
            obj.color = this.errorColor;
            return obj;
          }
        }
      }
    }

    obj.value = true;
    obj.color = this.productColor;
    return obj;
  }


  checkDatiCittadinanzaGarante(): CheckResult {
    let obj: CheckResult = { value: false, color: '' };

    if (this.form.sections.finanziamentoSections.finAnagraficaGarante.tipologiacittadId) {
      let cittadinanza = this.returnCittadinanzaSelectedGarante();

      if (cittadinanza && cittadinanza.includes(constants.DESC_FIN_CITTADINANZA_ALTRA)) {

        if (!this.form.sections.finanziamentoSections.finAnagraficaGarante.altracitt) {
          obj.value = false;
          obj.color = this.errorColor;
          return obj;
        }

        // Se cittadinanza è "Altra" e "EXTRA UE" è true
        if (this.form.sections.finanziamentoSections.finAnagraficaGarante.tipologiaextraue) {

          // Se i campi "soggionoinizio" e "soggionoscadenza" sono valorizzati
          if (!this.form.sections.finanziamentoSections.finAnagraficaGarante.soggionoinizio || !this.form.sections.finanziamentoSections.finAnagraficaGarante.soggionoscadenza) {
            obj.value = false;
            obj.color = this.errorColor;
            return obj;
          }
        }
      }
    }

    obj.value = true;
    obj.color = this.productColor;
    return obj;
  }

  areSoggiornoFieldsRequired(): boolean {
    if (this.cittadinanzaList?.length) {
      let trascodifica = this.formController.getTrascodificaById(this.form.sections.finanziamentoSections.finAnagrafica.tipologiacittadId, this.cittadinanzaList);
      return trascodifica && trascodifica.descrizione.includes(constants.DESC_FIN_CITTADINANZA_ALTRA) && this.form.sections.finanziamentoSections.finAnagrafica.tipologiaextraue;
    }
  }

  areSoggiornoFieldsGaranteRequired(): boolean {
    if (this.cittadinanzaList?.length) {
      let trascodifica = this.formController.getTrascodificaById(this.form.sections.finanziamentoSections.finAnagraficaGarante.tipologiacittadId, this.cittadinanzaList);
      return trascodifica && trascodifica.descrizione.includes(constants.DESC_FIN_CITTADINANZA_ALTRA) && this.form.sections.finanziamentoSections.finAnagraficaGarante.tipologiaextraue;
    }
  }

  checkDatiOccupazione(): CheckResult {
    let obj: CheckResult = { value: false, color: '' };
    if (this.attivitaLavList?.length && this.form.sections.finanziamentoSections.finDatiOccupazione) {
      let objBoolValue = this.formController.checkFinDatiOccupazione(this.form.sections.finanziamentoSections.finDatiOccupazione, this.attivitaLavList);
      obj = {
        value: objBoolValue,
        color: objBoolValue ? this.productColor : this.errorColor
      }
    }
    return obj;
  }

  checkDatiOccupazioneGarante(): CheckResult {
    let obj: CheckResult = { value: false, color: '' };
    if (this.attivitaLavList?.length && this.form.sections.finanziamentoSections.finDatiOccupazioneGarante) {
      let objBoolValue = this.formController.checkFinDatiOccupazioneGarante(this.form.sections.finanziamentoSections.finDatiOccupazioneGarante, this.attivitaLavList);
      obj = {
        value: objBoolValue,
        color: objBoolValue ? this.productColor : this.errorColor
      }
    }
    return obj;
  }

  checkAttivitaLavorativa(): boolean {
    if (this.form.sections.finanziamentoSections.finDatiOccupazione && this.form.sections.finanziamentoSections.finDatiOccupazione.attivitalavId && this.attivitaLavList?.length) {
      return this.formController.checkAttivitaLavorativa(this.form.sections.finanziamentoSections.finDatiOccupazione.attivitalavId, this.attivitaLavList)
    }
  }

  checkAttivitaLavorativaGarante(): boolean {
    if (this.form.sections.finanziamentoSections.finDatiOccupazioneGarante && this.form.sections.finanziamentoSections.finDatiOccupazioneGarante.attivitalavId && this.attivitaLavList?.length) {
      return this.formController.checkAttivitaLavorativa(this.form.sections.finanziamentoSections.finDatiOccupazioneGarante.attivitalavId, this.attivitaLavList)
    }
  }

  checkDatiReddito(): CheckResult {
    let objBoolValue = this.formController.checkDatiReddito(this.form.sections.finanziamentoSections.finDatiOccupazione);
    let obj: CheckResult = {
      value: objBoolValue,
      color: objBoolValue ? this.productColor : this.errorColor
    };
    return obj;
  }

  checkDatiRedditoGarante(): CheckResult {
    let objBoolValue = this.formController.checkDatiRedditoGarante(this.form.sections.finanziamentoSections.finDatiOccupazioneGarante);
    let obj: CheckResult = {
      value: objBoolValue,
      color: objBoolValue ? this.productColor : this.errorColor
    };
    return obj;
  }

  checkDatiPagamento(): CheckResult {
    let objBoolValue = this.formController.checkDatiPagamento(this.form, this.finanziamento.id, this.listFinanziarie, this.attivitaLavList);
    let obj: any = {
      value: objBoolValue,
      color: objBoolValue ? this.productColor : this.errorColor
    }
    return obj;
  }

  verificaIban(): void {

    this.ibanVerificato = false;
    const iban = this.form.sections.finanziamentoSections.finanziamento.iban?.trim();

    if (this.formController.isValidIban(iban)) {
      this.ibanVerificato = true;
    } else {
      this.ibanVerificato = false;
    }
  }

  checkProspetto(): CheckResult {
    let objBoolValue = this.formController.checkProspetto(this.form.sections.finanziamentoSections.finanziamento);
    let obj: CheckResult = {
      value: objBoolValue,
      color: objBoolValue ? this.productColor : this.errorColor
    };
    return obj;
  }

  checkBollettino(): boolean {
    if (this.form.sections.finanziamentoSections.finDatiOccupazione.attivitalavId) {
      return this.formController.checkPagamentoBollettino(this.form, this.finanziamento.id, this.listFinanziarie, this.attivitaLavList)
    }
  }

  checkEta(): boolean {
    // Esempio:  data nascita: 01/06/1940  (84 anni), 24 rate = 2 anni... età a fine finaziamento= 86 anni
    if (this.form.sections.finanziamentoSections.finanziamento.numrate) {
      return this.formController.checkEta(this.form, this.finanziamento.id, this.listFinanziarie);
    } else {
      return true;
    }
  }

  checkOmaggi(): boolean {
    if (this.form.sections.finanziamentoSections.finanziamento.tan === 0) {
      if (this.omaggi?.length) {
        const noOmaggio = this.omaggi.find((el: any) => el.tipologiaDescrizione === constants.DESC_NO_OMAGGIO);
        return !noOmaggio;
      }
    }
    return false;
  }

  attivitaLavChange(): void {
    this.form.sections.finanziamentoSections.finDatiOccupazione.datainizioocc = null;
    this.form.sections.finanziamentoSections.finDatiOccupazione.tipoAssunzioneId = null;
    this.form.sections.finanziamentoSections.finDatiOccupazione.tipoAssunzioneDescrizione = null;
    this.form.sections.finanziamentoSections.finanziamento.bollettino = null;

    if (this.formController.shouldShowPopupGaranzie(this.form, this.attivitaLavList, this.listFinanziarie)) {
      this.openPopupGaranzie();
    }

    this.precompilazioneTipoReddito();
  }

  openPopupGaranzie(): void {
    if (this.formController.shouldShowPopupGaranzie(this.form, this.attivitaLavList, this.listFinanziarie)) {
      jQuery('#popupGaranzie').modal({ backdrop: 'static', keyboard: false });
      jQuery('#popupGaranzie').modal('show');
    }
  }

  openPopupEta(): void {
    jQuery('#popupGaranzieEta').modal({ backdrop: 'static', keyboard: false });
    jQuery('#popupGaranzieEta').modal('show');
  }

  openModalTan0(): void {
    jQuery('#popupTan0').modal({ backdrop: 'static', keyboard: false });
    jQuery('#popupTan0').modal('show');
  }

  precompilazioneTipoReddito(): void {
    if (this.form.sections.finanziamentoSections.finDatiOccupazione && this.tipoRedditoList?.length && this.attivitaLavList?.length) {
      let obj = this.formController.getTrascodificaById(this.form.sections.finanziamentoSections.finDatiOccupazione.attivitalavId, this.attivitaLavList);
      if ((obj && obj.descrizione.includes(constants.DESC_ATTIVITA_LAV_AUTO))) {
        let obj: Trascodifica = this.tipoRedditoList.find((t: Trascodifica) => t.descrizione.includes(constants.DESC_TIPO_REDD_LORDO));
        this.form.sections.finanziamentoSections.finDatiOccupazione.tipologiaredditoId = obj.id;
        this.redditoString = obj.descrizione;
      } else {
        let obj: Trascodifica = this.tipoRedditoList.find((t: Trascodifica) => t.descrizione.includes(constants.DESC_TIPO_REDD_NETTO));
        this.form.sections.finanziamentoSections.finDatiOccupazione.tipologiaredditoId = obj.id;
        this.redditoString = obj.descrizione;
      }
    }
  }

  attivitaLavGaranteChange(): void {
    this.form.sections.finanziamentoSections.finDatiOccupazioneGarante.datainizioocc = null;
    this.form.sections.finanziamentoSections.finDatiOccupazioneGarante.tipoAssunzioneId = null;
    this.form.sections.finanziamentoSections.finDatiOccupazioneGarante.tipoAssunzioneDescrizione = null;
    this.precompilazioneGaranteTipoReddito();
  }

  precompilazioneGaranteTipoReddito(): void {
    if (this.form.sections.finanziamentoSections.finDatiOccupazioneGarante && this.tipoRedditoList?.length && this.attivitaLavList?.length) {
      let obj = this.formController.getTrascodificaById(this.form.sections.finanziamentoSections.finDatiOccupazioneGarante.attivitalavId, this.attivitaLavList);
      if ((obj && obj.descrizione.includes(constants.DESC_ATTIVITA_LAV_AUTO))) {
        let obj: Trascodifica = this.tipoRedditoList.find((t: Trascodifica) => t.descrizione.includes(constants.DESC_TIPO_REDD_LORDO));
        this.form.sections.finanziamentoSections.finDatiOccupazioneGarante.tipologiaredditoId = obj.id;
        this.redditoGaranteString = obj.descrizione;
      } else {
        let obj: Trascodifica = this.tipoRedditoList.find((t: Trascodifica) => t.descrizione.includes(constants.DESC_TIPO_REDD_NETTO));
        this.form.sections.finanziamentoSections.finDatiOccupazioneGarante.tipologiaredditoId = obj.id;
        this.redditoGaranteString = obj.descrizione;
      }
    }
  }

  checkVisibilitySectionDatoreDiLavoro(): boolean {
    return this.formController.checkVisibilitySectionDatoreDiLavoro(this.form, this.attivitaLavList);
  }

  checkVisibilitySectionDatoreDiLavoroGarante(): boolean {
    return this.formController.checkVisibilitySectionDatoreDiLavoroGarante(this.form, this.attivitaLavList);
  }

  checkDatiDatore(): CheckResult {
    let obj: CheckResult = {
      value: false,
      color: this.errorColor
    };
    if (this.form.sections.finanziamentoSections.finDatiOccupazione) {
      let objBoolValue = this.formController.checkFinDatiDatore(this.form.sections.finanziamentoSections.finDatiOccupazione);
      obj.value = objBoolValue;
      obj.color = objBoolValue ? this.productColor : this.errorColor;
    }
    return obj;
  }

  checkDatiDatoreGarante(): CheckResult {
    let obj: CheckResult = {
      value: false,
      color: this.errorColor
    };
    if (this.form.sections.finanziamentoSections.finDatiOccupazioneGarante) {
      let objBoolValue = this.formController.checkFinDatiDatoreGarante(this.form.sections.finanziamentoSections.finDatiOccupazioneGarante);
      obj.value = objBoolValue;
      obj.color = objBoolValue ? this.productColor : this.errorColor;
    }
    return obj;
  }

  returnCittadinanzaSelected(): string {
    if (this.form.sections.finanziamentoSections.finAnagrafica.tipologiacittadId && this.cittadinanzaList?.length) {
      let trascodifica = this.formController.getTrascodificaById(this.form.sections.finanziamentoSections.finAnagrafica.tipologiacittadId, this.cittadinanzaList);
      if (trascodifica && trascodifica.descrizione) {
        return trascodifica.descrizione;
      }
    }
  }

  returnCittadinanzaSelectedGarante(): string {
    if (this.form.sections.finanziamentoSections.finAnagraficaGarante.tipologiacittadId && this.cittadinanzaList?.length) {
      let trascodifica = this.formController.getTrascodificaById(this.form.sections.finanziamentoSections.finAnagraficaGarante.tipologiacittadId, this.cittadinanzaList);
      if (trascodifica && trascodifica.descrizione) {
        return trascodifica.descrizione;
      }
    }
  }

  resetCittadinanza(): void {
    let obj = this.formController.getTrascodificaById(this.form.sections.finanziamentoSections.finAnagrafica.tipologiacittadId, this.cittadinanzaList);
    if (obj && !obj.descrizione.includes(constants.DESC_FIN_CITTADINANZA_ALTRA)) {
      this.form.sections.finanziamentoSections.finAnagrafica.soggionoinizio = null;
      this.form.sections.finanziamentoSections.finAnagrafica.soggionoscadenza = null;
      this.form.sections.finanziamentoSections.finAnagrafica.tipologiaextraue = null;
      this.form.sections.finanziamentoSections.finAnagrafica.soggionoinizio = null;
      this.form.sections.finanziamentoSections.finAnagrafica.soggionoscadenza = null;
      this.form.sections.finanziamentoSections.finAnagrafica.altracitt = '';
    }
  }

  resetCittadinanzaGarante(): void {
    let obj = this.formController.getTrascodificaById(this.form.sections.finanziamentoSections.finAnagraficaGarante.tipologiacittadId, this.cittadinanzaList);
    if (obj && !obj.descrizione.includes(constants.DESC_FIN_CITTADINANZA_ALTRA)) {
      this.form.sections.finanziamentoSections.finAnagraficaGarante.soggionoinizio = null;
      this.form.sections.finanziamentoSections.finAnagraficaGarante.soggionoscadenza = null;
      this.form.sections.finanziamentoSections.finAnagraficaGarante.tipologiaextraue = null;
      this.form.sections.finanziamentoSections.finAnagraficaGarante.soggionoinizio = null;
      this.form.sections.finanziamentoSections.finAnagraficaGarante.soggionoscadenza = null;
      this.form.sections.finanziamentoSections.finAnagraficaGarante.altracitt = '';
    }
  }

  changeValueGarante(value: any): void {
    this.expandedElement = '';
    if (!value) {
      jQuery('#deleteGarante').modal({ backdrop: 'static', keyboard: false });
      jQuery('#deleteGarante').modal('show');
    }
    this.form.sections.finanziamentoSections.finAnagraficaGarante.garante = value;
    this.form.sections.finanziamentoSections.finDatiOccupazioneGarante.garante = value;
    this.isGarante = value;
    this.expandedElement = 'collapseAnagraficaGarante';
  }

  toggle(element: string): void {
    this.expandedElement = this.expandedElement === element ? null : element;
  }

  confirmCleanGarante(): void {
    // se il garante è stato già salvato lo cancello e sbianco i campi
    if (this.form.sections.finanziamentoSections.finAnagraficaGarante.id || this.form.sections.finanziamentoSections.finDatiOccupazioneGarante.id) {
      if (!this.form.sections.finanziamentoSections.finAnagraficaGarante.garante && !this.form.sections.finanziamentoSections.finDatiOccupazioneGarante.garante) {
        Promise.all([
          this.deleteFinAnagraficaGarante(),
          this.deleteFinDatiOccupazioneGarante(),
        ])
          .then(() => {
            this.isloaded = true;
            jQuery('#deleteGarante').modal('hide');
          })
          .catch(error => {
            console.error('An error occurred:', error);
          });
      }
    } else {
      this.form.sections.finanziamentoSections.finAnagraficaGarante = new FinAnagrafica();
      this.form.sections.finanziamentoSections.finDatiOccupazioneGarante = new FinDatiOccupazione();
      jQuery('#deleteGarante').modal('hide')
    }
  }

  cancelChange(): void {
    this.changeValueGarante(true); // se vuoi annullare riporto il garante e true
    jQuery('#deleteGarante').modal('hide');
  }

  cancelGaranzieChange(): void {
    jQuery('#popupGaranzie').modal('hide');
  }

  closeModalEta(): void {
    jQuery('#popupGaranzieEta').modal('hide');
  }

  closeModalTan0(): void {
    jQuery('#popupTan0').modal('hide');
  }

  isValid(): boolean {
    const value = this.form.sections.finanziamentoSections.finAnagrafica.codicefiscale;
    if (this.selectedCFoIVA === 'cf') {
      return /[a-zA-Z0-9]/.test(value);
    } else if (this.selectedCFoIVA === 'piva') {
      return /[a-zA-Z0-9]/.test(value);
    }
    return false;
  }

  filter(value): any {
    const str = value.toLowerCase();
    this.filtered = [];
    this.province.forEach(r => {
      if (r.nome.toLowerCase().includes(str)) {
        this.filtered.push(r);
      }
    });
  }

  getValidationMessage(): string {
    if (this.selectedCFoIVA === 'cf') {
      return 'Codice Fiscale non valido';
    } else if (this.selectedCFoIVA === 'piva') {
      return 'Partita IVA non valida';
    }
    return 'Campo non valido';
  }

  returnYears(isGarante: boolean): number {
    if (!isGarante) {
      return this.formController.getAgeFromBirthDate(new Date(this.form.sections.finanziamentoSections.finAnagrafica.datanascita))
    } else {
      return this.formController.getAgeFromBirthDate(new Date(this.form.sections.finanziamentoSections.finAnagraficaGarante.datanascita))
    }
  }

  //Prospetto contabile guidato - select list
  async getCodiceTabella(): Promise<void> {
    return new Promise((resolve) => {
      this.tabellaFinanziarieService.getCodiciTabellaDistint(this.finanziamento.descrizione).subscribe(res => {
        this.codiciTabellaList = res;
        resolve();
      });
    });
  }

  async getImporti(codiceTabella: any, shipReset: boolean): Promise<void> {
    return new Promise((resolve) => {
      this.tabellaFinanziarieService.getImportoTabellaDistint(this.finanziamento.descrizione, codiceTabella).subscribe(res => {
        this.valoriImportoList = res;

        if (!shipReset) {
          // se cambi codice tabella, sbianco i dati che devono essere ricalcolati.
          this.numeroRateList = [];
          this.form.sections.finanziamentoSections.finanziamento.importoFinanziaria = null;
          this.form.sections.finanziamentoSections.finanziamento.numrate = null;
        }
        resolve();
      });
    });
  }

  includeImporto(i: any): boolean {
    if (i !== null && i !== undefined) {
      if (!this.searchImportoProspetto || this.searchImportoProspetto.trim() === '') {
        return false;
      } else {
        const searchString = String(this.searchImportoProspetto).trim();
        const inputString = String(i);
        return !inputString.startsWith(searchString);
      }
    }
    return true;
  }

  async getNumeroRate(importo: any, shipReset: boolean): Promise<void> {
    return new Promise((resolve) => {
      this.tabellaFinanziarieService.getNumRate(this.finanziamento.descrizione, this.form.sections.finanziamentoSections.finanziamento.codicetabella, importo).subscribe(res => {
        this.numeroRateList = res;

        if (!shipReset) {
          // se cambi l'importo, sbianco i dati che devono essere ricalcolati.
          this.form.sections.finanziamentoSections.finanziamento.numrate = null;
        }
        resolve();
      });
    });
  }

  async getLatestDataProspetto(nRate: any): Promise<void> {
    return new Promise((resolve) => {
      this.tabellaFinanziarieService.getLatestDataProspetto(
        this.finanziamento.descrizione,
        this.form.sections.finanziamentoSections.finanziamento.codicetabella,
        this.form.sections.finanziamentoSections.finanziamento.importoFinanziaria,
        nRate
      ).subscribe({
        next: (res) => {
          // Gestisci il caso di successo
          /*this.form.sections.finanziamentoSections.finanziamento.importorata = res.importoRate;
          this.form.sections.finanziamentoSections.finanziamento.tan = res.tan;
          this.form.sections.finanziamentoSections.finanziamento.taeg = res.taeg;*/
          resolve();
        },
        error: (err) => {
          if (err.detail.includes('unique result')) {
            console.error('Error while retrieving financial data.');
            console.error('There is more than one result for this combination (financial-name, table-code, financial-amount, number-of-installments)');
          }

          resolve();
        }
      });
    });
  }
}