import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from '../../../../classes/trascodifica'
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { OptionsAvailable } from 'src/app/modules/classes/options-available';
import { OptionsAvailableService } from 'src/app/services/optionsAvailable/options-available.service';
import { constants } from 'src/app/modules/classes/constants';
@Component({
  selector: 'app-form-nuova-cassetta',
  templateUrl: './form-nuova-cassetta.component.html',
  styleUrls: ['./form-nuova-cassetta.component.scss']
})
export class FormNuovaCassettaComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche:Trascodifica[];
  @Input() brand:string;

  tipoCassettaWc : Trascodifica[];
  cassettaWcMuretto: Trascodifica[];
  tipoPlaccawc : Trascodifica[];
  colorePlaccawc:Trascodifica[];
  tipoMonoliteWc: Trascodifica[];
  tipoMonoliteBidet: Trascodifica[];

  isVisible:boolean  = false;
  collapse:boolean=false;
  options: Trascodifica[];
  optionNuovaCassetta: OptionsAvailable = {id:undefined, typeId:undefined,ordineId:undefined, selected:false};
  listOptionsOrder: OptionsAvailable[];
  classBg:string = 'blueBg';
  classContent:string = 'blueCont';

  constructor(
		public formController : FormControllerService,
    private optionsService:OptionsAvailableService
	) { }

  ngOnInit(): void {
    if(this.brand === 'bagniHelp75'){
      this.classBg = 'help75Bg';
      this.classContent = 'hepl75Cont';
    }else if(this.brand === 'bagno-pratico'){
      this.classBg = 'bagnoPraticoBg';
      this.classContent = 'bagnoPraticoCont';
    } 

    this.options = this.formController.getTrascodificaByCodice( constants.TYPE_OPTIONS ,this.trascodifiche).filter(op => op.descrizione === constants.TYPE_NUOVA_CASSETTA);
    this.getOption();

		this.tipoCassettaWc = this.formController.getTrascodificaByCodice( constants.COD_TIPO_CASSETTA_WC , this.trascodifiche);
		this.tipoPlaccawc = this.formController.getTrascodificaByCodice( constants.COD_TIPO_PLACCA_WC , this.trascodifiche);
    this.cassettaWcMuretto = this.formController.getTrascodificaByCodice( constants.COD_CASSETTA_WC_MURETTO , this.trascodifiche);
		this.colorePlaccawc = this.formController.getTrascodificaByCodice( constants.COD_COLORE_PLACCA_WC , this.trascodifiche);
    this.tipoMonoliteWc = this.formController.getTrascodificaByCodice( constants.COD_TIPO_CASSETTA_WC_MONOLITE , this.trascodifiche);
    this.tipoMonoliteBidet = this.formController.getTrascodificaByCodice( constants.COD_TIPO_CASSETTA_BIDET_MONOLITE , this.trascodifiche);
  }

  changeWcModel(event): void{
    this.removePlacca();
  }

  removePlacca(): void {
    if (this.form.sections.nuoviProdotti.nuovaCassettaWc.placcaColorId) {
      delete this.form.sections.nuoviProdotti.nuovaCassettaWc.placcaColorId;
    }
    if (this.form.sections.nuoviProdotti.nuovaCassettaWc.placcaTypeId) {
      delete this.form.sections.nuoviProdotti.nuovaCassettaWc.placcaTypeId;
    }

    delete this.form.sections.nuoviProdotti.nuovaCassettaWc.cassettamonolitewc;
    delete this.form.sections.nuoviProdotti.nuovaCassettaWc.cassettamonolitebidet;
  }

  showDiv(value){    
		if(value ){
		    this.isVisible = true;
		 }else{
		    this.isVisible = false;
		}
		this.form.sections.optionsAvailable.filter(op => op.typeId === this.options[0]?.id)[0].selected = value;
	}
	
	  getOption(){
		this.optionsService.findByKey(this.form.idOrder,'ordineId.equals=').subscribe(res => { 
			if(res !== undefined){
			    this.listOptionsOrder = res;
			    let optionNuovaCassetta = res.filter(op => op.typeId === this.options[0].id)  
			      if(optionNuovaCassetta !== undefined && optionNuovaCassetta.length > 0){
				      this.isVisible = optionNuovaCassetta[0].selected;
				      this.form.sections.optionsAvailable=res;
				      this.optionNuovaCassetta = optionNuovaCassetta[0];
			  }else{
				  this.isVisible = false;
				  this.optionNuovaCassetta = new OptionsAvailable();
				  this.optionNuovaCassetta.ordineId = this.form.idOrder;
				  this.optionNuovaCassetta.typeId = this.options[0].id;
				  this.optionNuovaCassetta.selected = undefined;
				  this.form.sections.optionsAvailable.push(this.optionNuovaCassetta);
			  }
			}
		});
	}
}