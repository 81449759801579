import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user/user.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  username: string;
  email: string;
  loading: boolean;
  constructor(
      private router: Router,
      private snackBar: MatSnackBar,
      private authService: AuthenticationService,
      private userService: UserService,
  ) { }

  ngOnInit(): void {
  }

  forgotAction(): void {
    const body =  this.email;
    this.authService.forgotForm(body).subscribe(response => {
      this.loading = false;
      this.snackBar.open('Richiesta inviata', 'ok', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      // tslint:disable-next-line:max-line-length
    }, error => {
      this.loading = false;
      this.snackBar.open('ERROR', 'ok', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: 'snackerror'
      });
      console.error('Error => ', error);
    });
  }
  
  validEmail(email): boolean{
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

}
