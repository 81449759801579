import { Component, Input, OnInit } from '@angular/core';
import { CaldaiaService } from '../services/caldaia.service';
import { BrandService } from '../../../../../../services/brand/brand.service';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';
import { Trascodifica } from '../../../../../classes/trascodifica';

@Component({
  selector: 'app-disegno-caldaia',
  templateUrl: './disegno-caldaia.component.html',
  styleUrls: ['./disegno-caldaia.component.scss']
})
export class DisegnoCaldaiaComponent implements OnInit {
  disegnoCaldaiaResponse: any;
  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  TIPOCALDAIAESISTENTE: Trascodifica[];
  TIPOCOMBUSTIBILECALDAIAESISTENTE: Trascodifica[];
  constructor(
      private caldaiaService: CaldaiaService,
      private brandService: BrandService,
      private formController: FormControllerService,
  ) { }

  ngOnInit(): void {
    this.getDettaglio();
  }

  getDettaglio(): void {
    this.caldaiaService.getDisegnoCaldaia(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
      this.disegnoCaldaiaResponse = res[0];
      if (this.disegnoCaldaiaResponse !== undefined) {
        this.form.sections.disegnoCaldaia = this.disegnoCaldaiaResponse;
      }
      //this.form.sections.disegnoCaldaia.ordineId = this.form.idOrder;
      this.getTIPOCALDAIAESISTENTE();
    });
  }
  getTIPOCALDAIAESISTENTE(): void {
    this.TIPOCALDAIAESISTENTE = undefined;
    this.formController.getTrascodificaByCodicPromise('TIPOCALDAIAESISTENTE', this.trascodifiche).then(data => {
      this.TIPOCALDAIAESISTENTE = data;
      this.getTIPOCOMBUSTIBILECALDAIAESISTENTE();
    });
  }
  getTIPOCOMBUSTIBILECALDAIAESISTENTE(): void {
    // tslint:disable-next-line:max-line-length
    this.TIPOCOMBUSTIBILECALDAIAESISTENTE = undefined;
    this.formController.getTrascodificaByCodicPromise('TIPOCOMBUSTIBILECALDAIAESISTENTE', this.trascodifiche).then(data => {
      this.TIPOCOMBUSTIBILECALDAIAESISTENTE = data;
    });
  }

  updateNumber(event): void {
    console.log('test', event);
  }

  setDescrizioneTipoCaldaia(value:number): void {
     let res = this.formController.getTrascodificaById(value,this.TIPOCALDAIAESISTENTE);
     if(res !== undefined || res !== null)
      this.form.sections.disegnoCaldaia.tipoDescrizione = res.descrizione; 
  }

}
