<div class="text-center blueBg">GROHE</div>
<div class="blueCont">
	<div class="">
		<div class="d-inline-block">
			<!--<mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.nuoviProdotti.grohe.id">
                  <mat-radio-button class="mb-2 mr-2" [value]="grohe.id"  *ngFor="let grohe of tipiGrohe">{{grohe.descrizione}}</mat-radio-button>
                
                 <mat-radio-button class="mb-2 mr-2" value="MISC.LAV.G">Lavabo</mat-radio-button>
                <mat-radio-button class="mr-2" value="MISC.BIDET.G">Bidet</mat-radio-button>
                <mat-radio-button class="mr-2" value="MISC.VASCA.G">Vasca</mat-radio-button>
                <mat-radio-button class="mr-2" value="MISC.DOCCIA.G">Doccia</mat-radio-button> 
                <span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.nuoviProdotti.grohe.id" (click)="form.sections.nuoviProdotti.grohe = {}">
                    backspace
                </span>
            </mat-radio-group>-->

			<mat-checkbox value="{{grohe.id}}" class="inline" class="mb-2 mr-2"
				*ngFor="let grohe of tipiGrohe;let i = index;" color="primary"
				(change)="updateGrohe($event,grohe.id)"
				[checked]="checked(grohe.id)"> {{grohe.descrizione}}
			</mat-checkbox>
		</div>

	</div>

	<div class="row">
		<div class="col-12">
			<mat-form-field class="full-width" appearance="fill">
			<mat-label>Note</mat-label> <textarea matInput
				[(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.notelibere"></textarea> </mat-form-field>
		</div>
	</div>

</div>
