<div class="zucchettiMontascaleBg">ARRIVO IN BASSO DELLA GUIDA</div>
<div class="zucchettiMontascaleCont">
    <div class="container" *ngIf="listFirstLineArrivoInBassoTrasc.length && listSecondLineArrivoInBassoTrasc.length && listThirdLineArrivoInBassoTrasc.length">
        <div class="row">
          <div class="col-lg-3 col-md-3 col mb-3" *ngFor="let arrivoInBasso of listFirstLineArrivoInBassoTrasc;">
                <app-arrivo-item 
                    [arrivoGuidaType]="'arrivoBasso'"
                    [element]="arrivoInBasso" 
                    [form]="form" 
                    [listTrasc]="listFirstLineArrivoInBassoTrasc" 
                    [showInputLine]="showIsInput(arrivoInBasso)" 
                    [unitOfMeasure]="showMeasurementInput(arrivoInBasso)"
                    [underDescription]="filtedUnderBoxDescription(arrivoInBasso)"
                ></app-arrivo-item>
          </div>
        </div>
        
        <div class="row">
            <div class="col-lg-4 col-md-4 col mb-3" *ngFor="let arrivoInBasso of listSecondLineArrivoInBassoTrasc;">
                <app-arrivo-item 
                    [arrivoGuidaType]="'arrivoBasso'"
                    [element]="arrivoInBasso"
                    [form]="form"
                    [listTrasc]="listSecondLineArrivoInBassoTrasc"
                    [showInputLine]="showIsInput(arrivoInBasso)"
                    [unitOfMeasure]="showMeasurementInput(arrivoInBasso)"
                    [underDescription]="filtedUnderBoxDescription(arrivoInBasso)"
                ></app-arrivo-item>
            </div>
          </div>
    
          <div class="row">
            <div class="col-lg-4 col-md-4 col mb-3" *ngFor="let arrivoInBasso of listThirdLineArrivoInBassoTrasc;">
                <app-arrivo-item 
                    [arrivoGuidaType]="'arrivoBasso'"
                    [element]="arrivoInBasso" 
                    [form]="form" 
                    [listTrasc]="listThirdLineArrivoInBassoTrasc" 
                    [showInputLine]="showIsInput(arrivoInBasso)" 
                    [unitOfMeasure]="showMeasurementInput(arrivoInBasso)"
                    [underDescription]="filtedUnderBoxDescription(arrivoInBasso)"
                ></app-arrivo-item>
            </div>
          </div>
      </div>
</div>