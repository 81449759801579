import { Component, OnInit , Input} from '@angular/core';

import {OrdersService} from '../../../services/orders/orders.service';
@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  @Input() form: any;
  @Input() brand: any;
  constructor(public ordineService: OrdersService) { }

  ngOnInit(): void {
	this.ordineService.getSingleOrder(this.form.idOrder).subscribe(res => {
		this.form.ordine = res;
	})
	
  }
}
