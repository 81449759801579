<div class="loginPage">

    <div class="loginContainer">
        <div class="text-center mb-4">
            <img class="mb-3" alt="logo" title="remail" src="/assets/img/gdl.png">
            <div>
                <div *ngIf="loading || sending" class="text-center">
                    <p>Caricamento...</p>
                    <mat-progress-bar color="warn" mode="indeterminate"></mat-progress-bar>
                </div>
                <div *ngIf="!loading && errorKey && !errorSend">
                    <div class="mb-2">Il link per il recupero della password non è valido, ti preghiamo di riprovare.</div>
                    <hr/>
                    <div class="text-center">
                        <button (click)="goToForgot()" mat-flat-button color="warn">
                            Modifica password <span class="material-icons">login</span>
                        </button>
                    </div>
                </div>
                <div *ngIf="!loading && errorSend">
                    <div class="mb-2">Si è verificato un problema, ti preghiamo di riprovare.</div>
                    <hr/>
                    <div class="text-center">
                        <button (click)="goToForgot()" mat-flat-button color="warn">
                            Modifica password <span class="material-icons">login</span>
                        </button>
                    </div>
                </div>
                <div *ngIf="!loading && !errorKey && !errorSend">
                    <div class="intro-login">
                        <h4>Imposta la tua nuova password:</h4>
                    </div>
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Password</mat-label>
                        <input matInput [(ngModel)]="password" [type]="hide ? 'password' : 'text'" required>
                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                    </mat-form-field>

                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Conferma password</mat-label>
                        <input matInput [(ngModel)]="passwordConfirm" [type]="hideConfirm ? 'password' : 'text'" required>
                        <button mat-icon-button matSuffix (click)="hideConfirm = !hideConfirm" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                    </mat-form-field>
                    <p class="mt-0 text-left text-danger small" *ngIf="passwordConfirm != password">
                        Le password non coincidono
                    </p>
                    <div class="text-left mb-3" style="font-size:10px;" *ngIf="password">
                        <div>
                            <span style="font-size:11px;vertical-align: middle">La password deve contenere almeno 4 lettere, due numeri ed un carattere speciale</span>
                        </div>
                        <span style="vertical-align: middle;font-size:14px;color:red;" class="material-icons" *ngIf="barValue <= 66">
                            close
                        </span>
                        <span style="vertical-align: middle;font-size:14px;color:green;" class="material-icons" *ngIf="barValue > 66">
                            done
                        </span>
                        <span style="vertical-align: middle">Forza della password</span>
                        <mat-progress-bar mode="determinate" [color]="color" [value]="barValue"></mat-progress-bar>
                    </div>
                    <hr/>
                    <div class="text-center">
                        <button class="mr-2" *ngIf="!loading" mat-flat-button color="warn" (click)="goToLogin()">
                            Torna al login <span class="material-icons">login</span>
                        </button>
                        <button *ngIf="!loading" mat-flat-button color="primary" (click)="sendNewPassword()" [disabled]="measureStrength(password) < 40 || !(password || passwordConfirm) || passwordConfirm != password">
                            Modifica password
                        </button>
                        <mat-progress-bar *ngIf="loading" mode="indeterminate" color="warn"></mat-progress-bar>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
