import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrdersService } from '../../../services/orders/orders.service';
import { FormControllerService } from '../../../services/controller/form-controller.service';
import { SignatureService } from '../../../services/signature/signature.service';
import { Router } from '@angular/router';
import { constants } from '../../classes/constants';
import { UserService } from 'src/app/services/user/user.service';
@Component({
  selector: 'app-search-orders',
  templateUrl: './search-orders.component.html',
  styleUrls: ['./search-orders.component.scss']
})
export class SearchOrdersComponent implements OnInit {
  
  nameClient: string;
  idOrder: string;
  displayedColumns: string[] = ['idOrdine', 'nomeProdotto', 'brandDescrizione', 'dataordine', 'clienteCf', 'email', 'tel1', 'statopraticaDescrizione', 'statoAttServDescrizione', 'actions'];
  dataSource: MatTableDataSource<any>;
  loading: boolean;
  contracts: any;
  nameUser: any;
  listStatoPratica: any;
  
  private path = '/new-order/';
  private preventivoPath = '/new-estimate/';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private orderService: OrdersService, 
    private snackBar: MatSnackBar, 
    public userService: UserService,
    public formController: FormControllerService, 
    private router: Router, 
    public signatureService: SignatureService
  ) { }

  public constants = constants;

  ngOnInit(): void {
  }

  search(): void {
    if (this.nameClient && (!this.idOrder || this.idOrder.trim() == '')) {
      this.orderService.getOrders(this.nameClient, "size=1000&cognome.contains=").subscribe(res => {
        this.contracts = res;
        this.applyTransformationsToContracts(this.contracts);
        this.setTableData(this.contracts);
      }, error => {
        this.snackBar.open("Errore nel recupero ordine", '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      });
    } else if ((!this.nameClient || this.nameClient.trim() == '') && this.idOrder) {
      this.orderService.getOrdersByOrderIdOrGdlId(this.idOrder, "?id=").subscribe(res => {
        this.contracts = res;
        this.applyTransformationsToContracts(this.contracts);
        this.setTableData(this.contracts);
      }, error => {
        this.snackBar.open("Errore nel recupero ordine tramite idOrdine", '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      });
    } else if (this.nameClient && this.idOrder && this.idOrder.trim() != '' && this.nameClient != '') {
      this.orderService.getOrdersByOrderIdOrGdlIdOrClient(this.idOrder, this.nameClient).subscribe(res => {
        this.contracts = res;
        this.applyTransformationsToContracts(this.contracts);
        this.setTableData(this.contracts);
      }, error => {
        this.snackBar.open("Errore nel recupero ordine", '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      });
    }
  }

  applyTransformationsToContracts(contracts: any[]): void {
    contracts.forEach(r => {
      r.statopraticaDescrizione = this.formController.capitalize(r.statopraticaDescrizione);
      r.brandDescrizione = this.formController.capitalize(r.brandDescrizione);
      r.nomeProdotto = this.formController.returnProdName(r.prodottoCommessa);
    });
  }

  setTableData(contracts: any[]): void {
    this.dataSource = new MatTableDataSource(contracts);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  goToOrder(value, idClient, idProduct, idOrder, isManuale: boolean): void {
    localStorage.setItem('id_product', idProduct);

    if (idClient !== undefined && idClient !== null) {
      localStorage.setItem('id_client', idClient);
    } else {
      localStorage.removeItem('id_client');
    }

    if(isManuale){
      this.router.navigate([this.path + 'COMPILAZIONE-MANUALE/' + idOrder]);
    }else{
      if (value === constants.COMMESSA_BAGNI_ITALIANI) {
        //BAGNI ITALIANI BAGNI ITALIANI
        this.router.navigate([this.path + 'BAGNI_ITALIANI/bagno/' + idOrder]);
      } else if (value === constants.COMMESSA_ARIEL_STUFA_PELLET) {
        //ARIEL STUFE
        this.router.navigate([this.path + 'ARIEL/stufe/' + idOrder]);
      } else if (value === constants.COMMESSA_ARIEL_CALDAIE) {
        //ARIEL CALDAIA
        this.router.navigate([this.path + 'ARIEL/caldaia/' + idOrder]);
      } else if (value === constants.COMMESSA_ARIEL_CONDIZIONATORI) {
        //ARIEL CONDIZIONATORE
        this.router.navigate([this.path + 'ARIEL/condizionatore/' + idOrder]);
      } else if (value === constants.COMMESSA_REMAIL_VASCA_IN_DOCCIA) {
        //REMAIL vasca in doccia      
        this.router.navigate([this.path + 'REMAIL/vascaindoccia/' + idOrder]);
      } else if (value === constants.COMMESSA_REMAIL_BAGNI_ITALIANI_HELP_75) {
        //BAGNI HELP75
        this.router.navigate(['new-order/BAGNI_ITALIANI/help75/' + idOrder]);
      } else if (value === constants.COMMESSA_ARIEL_FOTOVOLTAICO) {
        //ARIEL FOTOVOLTAICO
        this.router.navigate([this.path + 'ARIEL/fotovoltaico/' + idOrder]);
      } else if (value === constants.COMMESSA_ARIEL_IBRID) {
        //ARIEL SISTEMI IBRIDI POMPA DI CALORE
        this.router.navigate([this.path + 'ARIEL/pompacalore/' + idOrder]);
      } else if (value === constants.COMMESSA_BAGNO_PRATICO) {
        //BAGNI ITALIANI BAGNO PRATICO
        this.router.navigate([this.path + 'BAGNO_PRATICO/bagno/' + idOrder]);
      } else if (value === constants.COMMESSA_ARIEL_FOTOVOLTAICO_PREVENTIVO) {
        //ARIEL FOTOVOLTAICO
        this.router.navigate([this.preventivoPath + 'ARIEL/fotovoltaico/' + idOrder])
      } else if (value === constants.COMMESSA_ARIEL_DEPURATORE) {
        //ARIEL DEPURATORE
        this.router.navigate([this.path + 'ARIEL/depuratore/' + idOrder]);
      } else if (value === constants.COMMESSA_ZUCCHETTI_MONTASCALE) {
        //ZUCCHETTI MONTASCALE
        this.router.navigate([this.path + 'ZUCCHETTI/montascale/' + idOrder]);
      } else if (value === constants.COMMESSA_CB_VASCA_IN_DOCCIA) {
         //CRISTALBOX VASCA IN DOCCIA
        this.router.navigate([this.path + 'CRISTALBOX/vascaindoccia/' + idOrder]);
      } else if (value === constants.COMMESSA_MONDIAL_ALLARME) {
        //MONDIAL ALLARME
        this.router.navigate([this.path + 'MONDIAL/allarme/' + idOrder]);
      }else if (value === constants.COMMESSA_ZUCCHETTI_POLTRONE) {
        // ZUCCHETTI POLTRONE
        this.router.navigate([this.path + 'ZUCCHETTI/poltrone/' + idOrder]);
      }else if (value === constants.COMMESSA_LOREN_CUCINE) {
        //LOREN CUCINE
        this.router.navigate([this.path + 'LOREN/cucina/' + idOrder]);
      }
    }
  }


  active(idOrder): void {

    this.orderService.getSingleOrder(idOrder).subscribe(res => {
      res.statopraticaId = 2;
      this.orderService.update(res).subscribe(ris => {
        this.search();
      });
    });
  }

  disabledDownload(element): boolean {

    if (element.idfile == null || element.idfile == undefined) {
      return true;
    } else if (element.statopraticaCodice !== 'I' && element.statopraticaCodice !== 'F' && element.statopraticaCodice !== 'R' && (element.statopraticaCodice == 'D' && element.dataftp == null)) {
      return true;
    } else {
      return false;
    }
  }

  uploadDocument(id, gdlid): void {
    this.router.navigate(['upload-document/' + id + '/' + gdlid]);
    localStorage.removeItem('id_client');
  }

}
