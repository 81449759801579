import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from '../../../../classes/trascodifica';
import { OpereIdraulicheService } from '../services/opereIdrauliche/opereIdrauliche.service';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { OpereIdrauliche, OpereIdraulicheDTO, PiattoDoccia, SoffittoPareti } from '../classes/dati-tecnici';
import { constants } from 'src/app/modules/classes/constants';
@Component({
	selector: 'app-form-opere-idrauliche',
	templateUrl: './form-opere-idrauliche.component.html',
	styleUrls: ['./form-opere-idrauliche.component.scss']
})
export class FormOpereIdraulicheComponent implements OnInit {

	@Input() form: any;
	@Input() trascodifiche: Trascodifica[];
	@Input() brand: string;

	sostOpIdr: Trascodifica[];
	listDimPiatoDoccia: Trascodifica[];
	classBg = "blueBg";
	classContent = "blueCont";

	constructor(
		private opIdraulicheService: OpereIdraulicheService,
		private formController: FormControllerService
	) { }

	ngOnInit(): void {
		if(this.brand === 'bagno-pratico'){
			this.classBg = "bagnoPraticoBg";
			this.classContent = "bagnoPraticoCont";
		}

		this.form.sections.opereIdraulicheSost = [];
		this.form.sections.opereIdraulicheSostToDelete = [];
		this.opIdraulicheService.findByKey(this.form.idOrder, 'ordineId.equals=', 'opereidrauliches').subscribe(res => {
			this.formController.mappingOpDTOtoForm(res[0], this.form.sections);
		})

		this.opIdraulicheService.findByKey(this.form.idOrder, 'ordineId.equals=', 'opereidrsosts').subscribe(res => {
			for (var i = 0; i < res.length; i++) {
				const op = this.formController.mappingOpereIdrSostDTOtoForm(res[i], new OpereIdrauliche());
				this.form.sections.opereIdraulicheSost.push(op);
			}

			this.sostOpIdr = this.formController.getTrascodificaByCodice(constants.COD_TIPO_SOST_OP_IDR, this.trascodifiche);
		})
	}

	mappingPiattoLargoDTOtoForm(dto: OpereIdraulicheDTO, form: PiattoDoccia): PiattoDoccia {
		if (dto !== undefined) {
			form.largo.coloreId = dto.colorepiattolargId;
			form.largo.dimensioneId = dto.misurapiattolargId;
			form.stretto.id = dto.misurapiattostrId;
		}
		return form;
	}

	mappingSoffittoParetiDTOtoForm(dto: OpereIdraulicheDTO, form: SoffittoPareti): SoffittoPareti {
		if (dto !== undefined) {
			form.inb = dto.imbsoffitto;
			form.scr = dto.necscrostatura;
		}
		return form;
	}

	update(event, id): void {
		if (-1 === this.form.sections.opereIdraulicheSost.findIndex(r => r.tipologiaId === id)) {
			if (event.checked) {
				var newVal = new OpereIdrauliche();
				newVal.tipologiaId = id;
				this.form.sections.opereIdraulicheSost.push(newVal);
			}
		} else {
			var toDelete = this.form.sections.opereIdraulicheSost.filter(tr => (tr.tipologiaId === id));
			this.form.sections.opereIdraulicheSostToDelete.push(toDelete[0]);
			this.form.sections.opereIdraulicheSost.splice(this.form.sections.opereIdraulicheSost.findIndex(r => r.tipologiaId === id), 1);
		}
	}

	checked(id): string {
		if (-1 !== this.form.sections.opereIdraulicheSost.findIndex(r => r.tipologiaId === id)) {
			return 'checked';
		} else {
			return '';
		}
	}
}
