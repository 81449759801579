import { Injectable } from '@angular/core';
import { HttpService } from '../../../../../services/http.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class Help75PrintService extends HttpService {

    constructor(
        public router: Router,
        public http: HttpClient
    ) {
        super(router, http);
    }


    getSingleOrder(data): Observable<any> {
        return this.http.get<any>(this.apiUrl + 'ordines/' + data, this.setHttpOptions())
            .pipe(
                retry(0),
                catchError(this.handleError)
            );
    }

    getAnagraficaCliente(data): Observable<any> {
        return this.http.get<any>(this.apiUrl + 'clientes/' + data, this.setHttpOptions())
            .pipe(
                retry(0),
                catchError(this.handleError)
            );
    }

    getDatiAttuali(data): Observable<any> {
        return this.http.get<any>(this.apiUrl + 'datitecnicivascas?ordineId.equals=' + data, this.setHttpOptions())
            .pipe(
                retry(0),
                catchError(this.handleError)
            );
    }

    getDatiTecniciAttuali(data): Observable<any> {
        return this.http.get<any>(this.apiUrl + 'datitecnicis?ordineId.equals=' + data, this.setHttpOptions())
            .pipe(
                retry(0),
                catchError(this.handleError)
            );
    }

    getDatiTecniciNuovi(data): Observable<any> {
        return this.http.get<any>(this.apiUrl + 'nuovidatitecnicis?ordineId.equals=' + data, this.setHttpOptions())
            .pipe(
                retry(0),
                catchError(this.handleError)
            );
    }

    getDisegnoTecnico(data): Observable<any> {
        return this.http.get<any>(this.apiUrl + 'disegnotecnicos?ordineId.equals=' + data, this.setHttpOptions())
            .pipe(
                retry(0),
                catchError(this.handleError)
            );
    }
    // https://admintest.ordinigdl.com/api/disegnotecnicos?ordineId.equals=5256  (disegno tecnico)

    getNuovoRivGres(data): Observable<any> {
        return this.http.get<any>(this.apiUrl + 'gres?ordineId.equals=' + data, this.setHttpOptions())
            .pipe(
                retry(0),
                catchError(this.handleError)
            );
    }
    // https://admintest.ordinigdl.com/api/gres?ordineId.equals=5256 (nuovo rivestimento gres)

    getNuovoPiattoDocciaEPiattiPietra(data): Observable<any> {
        return this.http.get<any>(this.apiUrl + 'opereidrauliches?ordineId.equals=' + data, this.setHttpOptions())
            .pipe(
                retry(0),
                catchError(this.handleError)
            );
    }
    // https://admintest.ordinigdl.com/api/opereidrauliches?ordineId.equals=5256 (nuovo piatto doccia e piatti effetto pietra)

    getOmaggio(data): Observable<any> {
        return this.http.get<any>(this.apiUrl + 'omaggios?ordineId.equals=' + data, this.setHttpOptions())
            .pipe(
                retry(0),
                catchError(this.handleError)
            );
    }
    // https://admintest.ordinigdl.com/api/omaggios?ordineId.equals=5256 (omaggio)

    getOptionsAvailable(data): Observable<any> {
        return this.http.get<any>(this.apiUrl + 'options-availables?ordineId.equals=' + data, this.setHttpOptions())
            .pipe(
                retry(0),
                catchError(this.handleError)
            );
    }
    // https://admintest.ordinigdl.com/api/options-availables?ordineId.equals=5256

    getSanitari(data): Observable<any> {
        return this.http.get<any>(this.apiUrl + 'sanitaris?idordineId.equals=' + data, this.setHttpOptions())
            .pipe(
                retry(0),
                catchError(this.handleError)
            );
    }
    // https://admintest.ordinigdl.com/api/sanitaris?idordineId.equals=5256

    getNuoviSanitari(data): Observable<any> {
        return this.http.get<any>(this.apiUrl + 'nuovisanitaris?ordineId.equals=' + data, this.setHttpOptions())
            .pipe(
                retry(0),
                catchError(this.handleError)
            );
    }
    // https://admintest.ordinigdl.com/api/nuovisanitaris?ordineId.equals=5256

    getBoxDoccia(data): Observable<any> {
        return this.http.get<any>(this.apiUrl + 'boxdoccias?ordineId.equals=' + data, this.setHttpOptions())
            .pipe(
                retry(0),
                catchError(this.handleError)
            );
    }
    // https://admintest.ordinigdl.com/api/boxdoccias?ordineId.equals=5256

    getPagamento(data): Observable<any> {
        return this.http.get<any>(this.apiUrl + 'pagamentos?ordineId.equals=' + data, this.setHttpOptions())
            .pipe(
                retry(0),
                catchError(this.handleError)
            );
    }
    // https://admintest.ordinigdl.com/api/pagamentos?ordineId.equals=5256
}
