
<div class="loginPage">

  <div class="loginContainer">
        <div class="text-center mb-4">
          <img class="mb-3" alt="logo" title="remail" src="/assets/img/gdl.png">
          <div class="intro-login">
            <h4>ENTRA NEL TUO PROFILO PERSONALE</h4>
            <p>Visualizza la lista dei tuoi appuntamenti</p>
            <p>Compila gli ordini per i prodotti GDL e fai firmare digitalmente i contratti</p>
          </div>

          <hr/>
          <p>Per accedere inserisci i tuoi dati</p>
        </div>

        <mat-form-field class="full-width mb-2" appearance="outline">
          <mat-label>Username</mat-label>
          <input type="text" matInput [(ngModel)]="username" required>
          <button mat-button *ngIf="username" matSuffix mat-icon-button aria-label="Clear" (click)="username=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
         <!--  <p class="mt-0 text-danger small" *ngIf="!validEmail(username)">
              Inserisci un indirizzo email valido
          </p> -->

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput [(ngModel)]="password" [type]="hide ? 'password' : 'text'" required>
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
        <div class="forgot text-right">
            <p><a routerLink="/forgot">Hai dimenticato la tua password?</a></p>
            <p><a routerLink="/registration">Non possiedi un account? Registrati</a></p>
        </div>
        <hr/>
        <div class="text-center">
          <button *ngIf="!loading" mat-flat-button color="warn" (click)="login()" [disabled]="!username || !password">
            Accedi <span class="material-icons">login</span>
          </button>
          <mat-progress-bar *ngIf="loading" mode="indeterminate" color="warn"></mat-progress-bar>
        </div>

      </div>

</div>

