<div class="zucchettiPoltroneBg"> PRODOTTI </div>
<div class="zucchettiPoltroneCont">
<div *ngIf="trascodifiche.length">
    <div class="product-container" *ngFor="let poltrona of poltroneList; let i = index">
        <div class="product-header" [innerHTML]="poltrona.descrizione">
        </div>
        <div class="product-details" [innerHTML]="poltrona.note">
        </div>
            <div class="row" *ngFor="let p of poltrona.filteredProducts; let poltrIndex = index">
                <div class="col-12 col-md-4">
                    <mat-form-field class="w-100 formField selectCustom" appearance="outline"  
                        (click)="searchColore = ''">
                        <mat-label>Colore</mat-label> 
                        <mat-select [(ngModel)]="p.coloreId" placeholder="Colore">
                          <div class="searchInSelect">
                            <input type="text" [(ngModel)]="searchColore" placeholder="cerca"/>
                          </div>
                          <mat-option *ngFor="let colore of coloriPoltrona[i]" [value]="colore.id" [hidden]="includeColore(colore.descrizione)">
                            {{ colore.descrizione }}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-12 col-md-4">
                    <mat-form-field class="full-width">
                        <mat-label>Note</mat-label>
                        <input matInput type="text" [(ngModel)]="p.linea">
                    </mat-form-field>                
                </div>
                
                <div class="col-12 col-md-4 d-flex justify-content-center justify-content-md-start align-items-center">     
                    <button mat-flat-button class="zucchettiButton" [disabled]="isAddButtonDisabled(poltrona) || (poltrona.filteredProducts.length != poltrIndex + 1)" (click)="addPoltrona(poltrona)">Aggiungi</button>

                    <button mat-flat-button class="zucchettiButtonWarn"  (click)="removePoltrona(poltrona, poltrIndex)">Cancella</button>
                </div> 

                <div class="col-12">
                    <div class="product-options" *ngIf="optionPoltrone[i].length">
                        OPTIONAL: &nbsp;&nbsp;
            
                        <div *ngFor="let optionPoltrona of optionPoltrone[i]; let opInd = index">
                            <mat-checkbox class="mr-2" color="primary" value 
                                (change)="onOptionsChange($event, poltrona.filteredProducts, optionPoltrona, poltrIndex)"  
                                [checked]="isChecked(optionPoltrona.id, poltrona, poltrIndex)"
                                [disabled]="!p.coloreId"
                                >
                              {{ convertTrascodifica(optionPoltrona.id) }}
                           </mat-checkbox>
                        </div>
                    </div>
                </div>

                <br>
                <hr *ngIf="poltrona.filteredProducts.length != poltrIndex + 1">
            </div>
            
            <div class="row d-flex justify-content-end">
                <div class="col-12 col-md-4">
                </div>
            </div>
       </div>
    </div>
  </div>

  <div class="zucchettiPoltroneCont">
        <app-note-sh [nota]="form.ordine.note" (onNotaEmit)="form.ordine.note = $event" 
        [maxLength]="255" [minLine]="3"> 
    </app-note-sh>
  </div>

