import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from '../../../../../classes/trascodifica';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';
import { CristalBoxService } from '../../../services/cristalbox.service';
@Component({
  selector: 'cristalbox-nuovidatitecnici',
  templateUrl: './nuovidatitecnici.component.html',
  styleUrls: ['./nuovidatitecnici.component.scss']
})
export class CristalBoxNuovidatitecniciComponent implements OnInit {
  
  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  @Input() brand:string;
  constructor
  (
      private formController: FormControllerService,
      private cristalBoxService: CristalBoxService,
  ) { }

  ngOnInit(): void {
    this.getVasca();
  }
  
  getVasca(): void {
    this.cristalBoxService.getNuoviDatiTecVasca(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
      if (res[0]){
        this.form.sections.nuovidatitecnici = res[0];
      }
    });
  }

  setMuroSx(event): void {
    this.form.sections.nuovidatitecnici.murosx = event.checked;
  }
  setMuroDx(event): void {
    this.form.sections.nuovidatitecnici.murodx = event.checked;
  }
}
