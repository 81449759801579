<div class="text-center blueBg">
    ACCESSORI
</div>
<div class="blueCont" [ngClass]="{'onlyRedBorder': !validAccessori()}">
    <div class="row" *ngFor="let accessorio of form.sections.accessoriBox; let i = index;">
        <div class="col-8">
            <mat-form-field class="full-width">
                <mat-label>Accessorio</mat-label>
                <mat-select [(ngModel)]="accessorio.tipologiaId" (ngModelChange)="checkItem($event, i)">
                    <mat-option>-- Accessorio --</mat-option>
                    <mat-option [disabled]="alreadySelected(acc.id)" *ngFor="let acc of availableAccessories" [value]="acc.id">
                        {{acc.descrizione}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-4" *ngIf="accessorio.tipologiaId">
            <app-input-number
                    (onNumChange)="accessorio.quantita = $event"
                    [step]="1"
                    [num]="accessorio.quantita"
                    [decimal]="false"
                    [payment]="false"
                    [small]="false"
                    label="Quantità"></app-input-number>
            <div class="smallFont text-danger" *ngIf="accessorio.tipologiaId && !accessorio.quantita">
                Campo obbligatorio
            </div>
            <!--
            <mat-form-field class="full-width">
                <mat-label>Quantità</mat-label>
                <input matInput type="number" min="1" placeholder="" [(ngModel)]="accessorio.quantita">
                <mat-hint class="text-danger" *ngIf="accessorio.tipologiaId && !accessorio.quantita">
                    Campo obbgligatorio
                </mat-hint>
            </mat-form-field>
            -->
        </div>
        <div class="col-2" *ngIf="form.sections.accessoriBox.length > 1">
            <button mat-icon-button color="warn" (click)="removeItem(i)">
                <mat-icon>cancel</mat-icon>
            </button>
        </div>
    </div>
    <div>
        <button mat-flat-button class="azur" (click)="addAccessorio()">Aggiungi accessorio</button>
    </div>

</div>
