import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;
  hide: boolean;
  loading: boolean;
  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private authService: AuthenticationService,
    private userService: UserService,
  ) {
    this.hide = true;
    this.loading = false;
  }

  ngOnInit(): void {
    this.authService.logout();
  }

  login(): void {
    this.loading = true;
    if (!this.password) { return; }
    const body = {
      username: this.username,
      password: this.password
    };
    this.authService.loginForm(body).subscribe(response => {
      this.loading = false;
      this.authService.setUser(response);
      this.getUserDetails();
      // tslint:disable-next-line:max-line-length
    }, error => {
      this.loading = false;
      this.snackBar.open(error, 'ok', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: 'snackerror'
      });
      console.error('Error => ', error);
    });
  }

  getUserDetails(): void {
    this.userService.getUser(this.username).subscribe(res => {
      this.authService.setProfile(res);
      // tslint:disable-next-line:max-line-length
    }, error => {
      this.loading = false;
      this.snackBar.open(error, 'ok', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: 'snackerror'
      });
      console.error('Error => ', error);
    });
  }

  validEmail(email): boolean{
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

}
