<div *ngIf="arrivoGuidaType === 'arrivoBasso'">
    <div class="row originalRow">
        <div class="col-2">
            <mat-radio-group labelPosition="before" color="primary" aria-label="Tipo"
            [(ngModel)]="form.sections.montascale.arrivobassoguidaId" (click)="changeBassoModel(element)"> 
            <mat-radio-button class="d-inline-block mr-2 colorLabel" [value]="element.id">
            </mat-radio-button> 
        </mat-radio-group>
        </div>
        <div class="col-10">
            {{element.descrizione}} 
            <input *ngIf="(form.sections.montascale.arrivobassoguidaId === element.id) && showInputLine" matInput type="number" min="0" step="0.1" [(ngModel)]="form.sections.montascale.arrivobassevalue">
            <a *ngIf="(form.sections.montascale.arrivobassoguidaId === element.id) && unitOfMeasure">{{unitOfMeasure}}</a>
        </div>  
    </div>
    
    <div class="row originalRow">
        <div class="col-lg-12 mb-2 align-self-center">
            <div class="col-lg-12 mb-2 align-self-center">
                <img src="{{element.note}}" alt="immagine della guida" class="img-fluid" style="object-fit: cover;"/>
                <br>     
                <hr>
                <span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.montascale.arrivobassoguidaId && form.sections.montascale.arrivobassoguidaId === element.id" (click)="cleanBassoItem()"> 
                    backspace 
                </span> 
                <br>
                <a>{{underDescription}}</a>  
            </div>
        </div> 
    </div>
</div>

<div *ngIf="arrivoGuidaType === 'arrivoAlto'">
    <div class="row originalRow">
        <div class="col-2">
            <mat-radio-group labelPosition="before" color="primary" aria-label="Tipo"
            [(ngModel)]="form.sections.montascale.arrivoaltoguidaId" (click)="changeAltoModel(element)"> 
            <mat-radio-button class="d-inline-block mr-2 colorLabel" [value]="element.id">
            </mat-radio-button> 
        </mat-radio-group>
        </div>
        <div class="col-10">
            {{element.descrizione}} 
            <input *ngIf="(form.sections.montascale.arrivoaltoguidaId === element.id) && showInputLine" matInput type="number" min="0" step="0.1" [(ngModel)]="form.sections.montascale.arrivoaltoguidavalue">
            <a *ngIf="(form.sections.montascale.arrivoaltoguidaId === element.id) && unitOfMeasure">{{unitOfMeasure}}</a>
        </div>  
    </div>
    
    <div class="row originalRow">
        <div class="col-lg-12 mb-2 align-self-center">
            <div class="col-lg-12 mb-2 align-self-center">
                <img src="{{element.note}}" alt="immagine della guida" class="img-fluid" style="object-fit: cover;"/>
                <br>     
                <hr>
                <span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.montascale.arrivoaltoguidaId && form.sections.montascale.arrivoaltoguidaId === element.id" (click)="cleanAltoItem()"> 
                    backspace 
                </span> 
                <br>
                <a>{{underDescription}}</a>  
            </div>
        </div> 
    </div>
</div>