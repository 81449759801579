import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from '../../../../classes/trascodifica'
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { GroheService } from '../services//grohe/grohe.service';
import { Grohe } from '../classes/dati-tecnici';
import { constants } from 'src/app/modules/classes/constants';
@Component({
	selector: 'app-form-grohe',
	templateUrl: './form-grohe.component.html',
	styleUrls: ['./form-grohe.component.scss']
})
export class FormGroheComponent implements OnInit {
	
	@Input() form: any;
	@Input() trascodifiche: Trascodifica[];

	tipiGrohe: Trascodifica[];
	constructor(
		private formController: FormControllerService,
		private groheService: GroheService
	) { }

	ngOnInit(): void {
		this.form.sections.grohe = [];
		this.groheService.findByKey(this.form.idOrder, 'ordineId.equals=').subscribe(res => {
			for (var i = 0; i < res.length; i++){
				const grohe = this.formController.mappingNuoviAccessoriDTOtoForm(res[i] , new Grohe());
				this.form.sections.grohe.push(grohe);
			}
			this.tipiGrohe = this.formController.getTrascodificaByCodice(constants.COD_TIPO_GROHE, this.trascodifiche);
		})
	}

	updateGrohe(event, id): void {
			if(- 1 === this.form.sections.grohe.findIndex(r => r.tipologiaId === id)) {
			if (event.checked) {
				var newVal = new Grohe();
				newVal.tipologiaId = id;
				this.form.sections.grohe.push(newVal);
			}
		}else {
			var groheToDelete = this.form.sections.grohe.filter(tr => (tr.tipologiaId === id));
			this.form.sections.groheToDelete.push(groheToDelete[0]);
			this.form.sections.grohe.splice(this.form.sections.grohe.findIndex(r => r.tipologiaId === id), 1);
		}
	}

	checked(id): string {
		if (-1 !== this.form.sections.grohe.findIndex(r => r.tipologiaId === id)) {
			return 'checked';
		} else {
			return '';
		}
	}
}
