<div class="row originalRow">
    <div class="col-lg-6 mb-4" *ngIf="form.sections.hiddenSectionCondition.dettagliInstallazione">
        <div class="anagraficaContainer">
            <div class="text-left text-success font-weight-bold mb-4">
                DETTAGLI INSTALLAZIONE
            </div>

            <div *ngIf="trascodifiche">
                <div>
                    <mat-checkbox color="primary" [(ngModel)]="form.sections.accStufa.installazionestand" [checked]="true">Installazione standard in canna fumaria esistente</mat-checkbox>
                </div>
                <div class="d-inline-block mr-2">Intubamento canna fumaria, piani</div>
                    <app-input-number
                        (onNumChange)="form.sections.accStufa.intubamentocannapiani = $event"
                        [step]="1"
                        [num]="form.sections.accStufa.intubamentocannapiani"
                        [decimal]="false"
                        [payment]="false"
                        [small]="true"
                        label="">
                    </app-input-number>
                <div *ngIf="!form.sections.accStufa.intubamentocannapiani && form.sections.accStufa.intubamentocannapiani!=0" class="mb-3 smallFont text-danger">Campo obbligatorio</div>

                <div class="mb-2">
                    <div>
                        <mat-checkbox color="primary" [(ngModel)]="form.sections.accStufa.accessotettobotola" [checked]="true">Accesso a tetto da botola/scala/altro</mat-checkbox>
                    </div>
                </div>

                <div class="d-inline-block mr-2">Installazione standard creando canna fumaria esterna di piano numero</div>
                    <app-input-number
                        (onNumChange)="form.sections.accStufa.instalstandesterna = $event"
                        [step]="1"
                        [num]="form.sections.accStufa.instalstandesterna"
                        [decimal]="false"
                        [payment]="false"
                        [small]="true"
                        label="piano n°">
                    </app-input-number>
                <div *ngIf="!form.sections.accStufa.instalstandesterna && form.sections.accStufa.instalstandesterna!=0" class="mb-3 smallFont text-danger">Campo obbligatorio</div>

                <br/>
                <div class="d-inline-block mr-2">Installazione standard creando canna fumaria inerna di piano numero</div>
                    <app-input-number
                        (onNumChange)="form.sections.accStufa.instalstandinterna = $event"
                        [step]="1"
                        [num]="form.sections.accStufa.instalstandinterna"
                        [decimal]="false"
                        [payment]="false"
                        [small]="true"
                        label="piano n°">
                    </app-input-number>
                <div *ngIf="!form.sections.accStufa.instalstandinterna && form.sections.accStufa.instalstandinterna!=0" class="mb-3 smallFont text-danger">Campo obbligatorio</div>

                <br/>
                <div>
                    <mat-checkbox color="primary" [(ngModel)]="form.sections.accStufa.nocannaFumBio" [checked]="true">{{constants.LABEL_NO_CANNA_FUM_BIO}}</mat-checkbox>
                </div>

                <br/>
                <div class="d-inline-block mr-2">Foro soletta </div>
                    <app-input-number
                        (onNumChange)="form.sections.accStufa.forosolettaqta = $event"
                        [step]="1"
                        [num]="form.sections.accStufa.forosolettaqta"
                        [decimal]="false"
                        [payment]="false"
                        [small]="true"
                        label="q.tà">
                    </app-input-number>
                <div *ngIf="(!form.sections.accStufa.forosolettaqta && form.sections.accStufa.forosolettaqta!=0) || form.sections.accStufa.forosolettaqta<0" class="mb-3 smallFont text-danger">Campo obbligatorio</div>

                <br/>
                <div class="d-inline-block mr-2">Foro cemento armato/pietra q.tà</div>
                <app-input-number
                        (onNumChange)="form.sections.accStufa.forocementoqta = $event"
                        [step]="1"
                        [num]="form.sections.accStufa.forocementoqta"
                        [decimal]="false"
                        [payment]="false"
                        [small]="true"
                        label="q.tà"></app-input-number>
                <div *ngIf="(!form.sections.accStufa.forocementoqta && form.sections.accStufa.forocementoqta!=0) || form.sections.accStufa.forocementoqta<0" class="mb-3 smallFont text-danger">Campo obbligatorio</div>

                <br/>
                <div class="d-inline-block mr-2">Foro cemento armato/pietra cm</div>
                    <app-input-number
                        (onNumChange)="form.sections.accStufa.forocementocm = $event"
                        [step]="0.1"
                        [num]="form.sections.accStufa.forocementocm"
                        [decimal]="true"
                        [payment]="false"
                        [small]="true"
                        label="cm">
                    </app-input-number>
                <div *ngIf="(!form.sections.accStufa.forocementocm && form.sections.accStufa.forocementocm!=0) || form.sections.accStufa.forocementocm<0" class="mb-3 smallFont text-danger">Campo obbligatorio</div>

                <br/>
                <div class="d-inline-block mr-2">Foro muro </div>
                    <app-input-number
                        (onNumChange)="form.sections.accStufa.foromuroqta = $event"
                        [step]="1"
                        [num]="form.sections.accStufa.foromuroqta"
                        [decimal]="false"
                        [payment]="false"
                        [small]="true"
                        label="q.tà">
                    </app-input-number>
                <div *ngIf="(!form.sections.accStufa.foromuroqta && form.sections.accStufa.foromuroqta!=0) || form.sections.accStufa.foromuroqta<0" class="mb-3 smallFont text-danger">Campo obbligatorio</div>

                <hr/>
                <div>
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>Note</mat-label>
                        <textarea matInput placeholder="Note..." [(ngModel)]="form.sections.accStufa.note"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-6 mb-4">
        <div class="anagraficaContainer">
            <div class="text-left text-success font-weight-bold mb-4">
               ACCESSORI
            </div>
            <div *ngIf="trascodifiche">
                <div class="">
                    <div>
                        <mat-checkbox color="primary" [(ngModel)]="form.sections.accStufa.ponteggio" [checked]="true">Ponteggio da Mt.3 a Mt.6</mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox color="primary" [(ngModel)]="form.sections.accStufa.cestaidr" [checked]="true">Cesta idraulica oltre Mt.6</mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox color="primary" [(ngModel)]="form.sections.accStufa.suoloPubblico" [checked]="true">Suolo pubblico</mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox color="primary" [(ngModel)]="form.sections.accStufa.scambiatore" [checked]="true">Scambiatore</mat-checkbox>
                        <div class="smallFont mb-3">
                            (da usare quanto il Cliente vuole produrre acqua calda tecnica anche con generatore di calore esistente)
                        </div>
                    </div>
                    <div>
                        <mat-checkbox color="primary" [(ngModel)]="form.sections.accStufa.kitAttraversamento" [checked]="true">Kit attraversamento tetti in legno</mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox color="primary" [(ngModel)]="form.sections.accStufa.contributotraspTerm" [checked]="true">Contributo di trasporto termostufa</mat-checkbox>
                    </div>

                    <div>
                        <mat-form-field class="full-width">
                            <mat-label>Puffer</mat-label>
                            <mat-select [(ngModel)]="form.sections.accStufa.pufferId">
                                <mat-option [value]="undefined">- - puffer - -</mat-option>
                                <mat-option *ngFor="let option of tipiPuffer" [value]="option.id">{{option.descrizione}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-checkbox color="primary" [(ngModel)]="form.sections.accStufa.aspiracenere" [checked]="true">Aspiracenere</mat-checkbox>
                    </div>

                    <div>
                        <mat-checkbox color="primary" [(ngModel)]="form.sections.accStufa.modulowifi" [checked]="true">Modulo Wi-Fi</mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox color="primary" [(ngModel)]="form.sections.accStufa.pelletomaggio" [checked]="true">600 Kg di pellet in omaggio (40 sacchi) consegnati al piano strada</mat-checkbox>
                        <div class="smallFont">
                            <ul>
                                <li>La consegna avviene al piano strada e non è previsto nessun servizio di facchimaggio;</li>
                                <li>La consegna del pellet avviene ad avvenuto pagamento integrale dalla fattura e normalmente entro 7 giorni dalla data di installazione della stufa;</li>
                                <li>In caso di "località disagiata", strada impervia o difficilmente raggiungibile il pellet non potrà essere consegnato e l'omaggio verrà sostituito con altro omaggio di pari valore a scelta del Cliente tra quelli disponibili.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>