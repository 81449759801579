import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class OrdersService extends HttpService{

  constructor(
      public router: Router,
      public http: HttpClient
  ) {
    super(router, http);
  }

  getOrders(data , key): Observable<any> {
	if(key === null || key === undefined){
	    return this.http
           .get<any>(this.apiUrl + 'ordines', this.setHttpOptions())
           .pipe(
               retry(0),
               catchError(this.handleError)
           );
	}else{
	    return this.http
           .get<any>(this.apiUrl + 'ordines?'+ key + data, this.setHttpOptions())
           .pipe(
               retry(0),
               catchError(this.handleError)
           );
	  }
  }

  getOrdersByOrderIdOrGdlId(data , key) {
    return this.http
    .get<any>(this.apiUrl + 'ordines/search' + key + data , this.setHttpOptions())
    .pipe(
        retry(0),
        catchError(this.handleError)
    );
  }

  getOrdersByOrderIdOrGdlIdOrClient(id, cognome) {
    return this.http
    .get<any>(this.apiUrl + `ordines/searchByIdOrGdlIdAndClient?id=${id}&cognome=${cognome}` , this.setHttpOptions())
    .pipe(
        retry(0),
        catchError(this.handleError)
    );
  }
  
	getSendId(data): Observable<any> {
		return this.http.get<any>(this.apiUrl + 'statopraticas?codice.equals=' + data, this.setHttpOptions())
       .pipe(
           retry(0),
           catchError(this.handleError)
       );
	}

	getSendOrders(data): Observable<any> {
		return this.http.get<any>(this.apiUrl + 'ordines?statopraticaId.equals=' + data, this.setHttpOptions())
		.pipe(
		   retry(0),
		   catchError(this.handleError)
		);
	}

	getSingleOrder(data): Observable<any> {
		return this.http.get<any>(this.apiUrl + 'ordines/' + data, this.setHttpOptions())
		           .pipe(
			           retry(0),
			           catchError(this.handleError)
		           );
	}

  getCheckNordine(data): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'ordines?idordine.equals=' + data, this.setHttpOptions())
    .pipe(
      retry(0),
      catchError(this.handleError)
    );  
  }
	
	save(ordine: any): Observable<any> {			
		return this.http.post<any>(this.apiUrl + 'ordines', ordine, this.setHttpOptions()).pipe(
			retry(0),
			catchError(err => this.handleError(err))
		);
	}
	
	update(ordine: any): Observable<any> {			
		return this.http.put<any>(this.apiUrl + 'ordines', ordine, this.setHttpOptions()).pipe(
			retry(0),
			catchError(err => this.handleError(err))
		);
	}
	
  sendEmail(idOrdine): Observable<any> {
	
	    return this.http
           .get<any>(this.apiUrl + 'ordinesSend/'+  idOrdine, this.setHttpOptions())
           .pipe(
               retry(0),
               catchError(this.handleError)
           );

  }
}
