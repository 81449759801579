
<div class="text-center">
	<b>INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI - FEA OTP</b>
</div>
<br />
<div class="text-left">GDL S.p.A., con sede in via Orbetello, 54/D
	- 10148 - Torino (TO),email: privacy@gdlspa.it, (di seguito
	"Titolare"), in qualit&agrave; di titolare del trattamento dei dati
	personali fornisce la presente informativa (di seguito "informativa")
	per il trattamento dei dati dei clienti (di seguito "interessati") che
	intendono aderire al servizio FEA OTP, in conformit&agrave; con il
	Regolamento (UE) 679/2016 e s.m.i ("GDPR"), con il D.Lgs. 196/2003 e
	successive modificazioni nonch&egrave; ai provvedimenti del Garante per
	la Protezione dei Dati Personali (di seguito, congiuntamente, la
	"normativa privacy applicabile").</div>
<br />
<br />
<div class="text-left">
	1. <u>quali dati personali verranno trattati?</u>
</div>
<div class="text-left">Il Titolare tratta dati anagrafici
	dell'interessato, tra i quali nome e cognome, codice fiscale, nonch
	&egrave; dati di contatto, tra i quali il numero di telefono cellulare
	e indirizzo di posta elettronica.</div>
<br />
<div class="text-left">
	2. <u>per quali finalit&agrave; verranno trattati i dati personali?</u>
</div>
<div class="text-left">
	Il trattamento dei dati personali degli interessati &egrave; effettuato
	per dar seguito alla richiesta di adesione al servizio di FEA OTP nonch
	&egrave; per gestire ed eseguire le richieste di assistenza
	dell'interessato ed adempiere agli obblighi imposti da disposizioni di
	legge e regolamentari cui il Titolare &egrave; tenuto in funzione
	dell'attivit&agrave; esercitata in qualit&agrave; di erogatore del
	servizio di firma elettronica avanzata.<br /> Nello specifico, i dati
	personali degli Interessati saranno trattati per:<br /> a.
	l'esecuzione degli obblighi derivanti dalla fornitura del servizio di
	FEA OTP e dalle richieste di contatto e assistenza: i dati personali
	dell'interessato sono trattati per dar corso alle attivit&agrave;
	preliminari e conseguenti alla richiesta di adesione al servizio di FEA
	OTP, alla gestione delle richieste di contatto e assistenza,
	nonch&egrave; per l'adempimento di ogni altro obbligo di legge. La base
	giuridica di tale trattamento &egrave; costituita dall'adempimento
	delle prestazioni inerenti all'erogazione del servizio di FEA OTP (base
	giuridica contrattuale), dalla necessit&agrave; di fornire
	all'interessato il supporto nonch&egrave; il rispetto degli obblighi di
	legge.<br /> b. la sicurezza informatica: i dati di contatto forniti
	dall'interessato potranno essere utilizzati dal Titolare qualora, a
	seguito della violazione di sicurezza che comporta accidentalmente o in
	modo illecito la distruzione, la perdita, la modifica, la divulgazione
	non autorizzata o l'accesso ai dati personali trattati, sussista un
	rischio elevato per i diritti e le libert&agrave; degli Interessati
	stessi. La base giuridica di tale trattamento &egrave; il rispetto
	degli obblighi di legge.<br /> c. la prevenzione delle frodi e la
	tutela del Titolare o di terzi in sede giudiziaria: i dati personali
	forniti dagli interessati potranno essere trattati dal Titolare per
	perseguire il proprio legittimo interesse in tema di riduzione e
	monitoraggio delle frodi nonch&egrave; per la comunicazione di reati
	all'autorit&agrave; giudiziaria. Le basi giuridiche di tale trattamento
	sono il rispetto di obblighi di legge ed il legittimo interesse del
	Titolare ad effettuare trattamenti inerenti a finalit&agrave; di tutela
	del patrimonio aziendale e dei sistemi informatici.<br />
</div>
<br />
<div class="text-left">
	3. <u>A chi vengono comunicati i dati personali?</u>
</div>
<div class="text-left">I dati personali oggetto di trattamento non
	verranno diffusi. I dati potrebbero inoltre essere comunicati alle
	categorie di soggetti di seguito indicati, i quali potranno agire, a
	seconda dei casi, come titolari autonomi del trattamento o come
	responsabili esterni del trattamento.</div>
<div class="text-left">
	a. Terzi fornitori di servizi di assistenza e consulenza per il
	Titolare in ambito tecnologico, contabile, amministrativo, legale,
	assicurativo, IT;<br /> b. Societ&agrave; facenti parte del Gruppo GDL
	S.p.A.;<br /> c. Intermediari assicurativi che operano in virt&ugrave;
	di un accordo di distribuzione con la GDL S.p.A.<br /> d.
	Societ&agrave; che forniscono servizi di assistenza agli Utenti;<br />
	e. Soggetti e autorit&agrave; il cui diritto di accesso ai dati
	personali degli interessati &egrave; espressamente riconosciuto dalla
	legge, da regolamenti o da provvedimenti emanati dalle autorit&agrave;
	competenti.<br />
</div>
<div class="text-left">
	Si precisa a tal riguardo che i dati personali saranno trattati dalla
	societ&agrave; <b>Yousign SAS</b>, 8 all&egrave;e Henri Pigis - 14 000
	Caen - Francia - P.IVA FR61794513986 in qualit&agrave; di responsabile
	del trattamento ex art. 28 GDPR per le attivit&agrave; di cui al
	Servizio di Firma Elettronica Avanzata FEA OTP.
</div>
<br />
<div class="text-left">
	4. <u>Dove vengono trattati i dati personali?</u>
</div>
<div class="text-left">I Suoi dati personali potranno essere
	trasferiti all'estero, prevalentemente verso paesi Europei. I dati
	personali possono tuttavia essere trasferiti verso paesi extra europei.
	Ogni trasferimento di dati avviene nel rispetto della normativa
	applicabile ed adottando misure atte a garantire i necessari livelli di
	sicurezza. Si precisa a tal riguardo che i trasferimenti verso Paesi
	extra europei - in assenza di decisioni di adeguatezza della
	Commissione Europea - avvengono sulla base delle Clausole Contrattuali
	Tipo emanate dalla Commissione medesima quale garanzia del corretto
	trattamento. Potr&agrave; in ogni caso sempre contattare GDL S.p.A. al
	fine di avere esatte informazioni circa il trasferimento dei Suoi dati
	ed il luogo specifico di loro collocazione.</div>
<br />
<div class="text-left">
	5. <u>Per quanto tempo vengono conservati i dati personali?</u>
</div>
<div class="text-left">I dati personali acquisiti dal Titolare in
	relazione all'erogazione del servizio sono conservati secondo i termini
	previsti dalla normativa vigente al fine esclusivo di garantire gli
	adempimenti specifici, e comunque per un periodo non superiore ai 20
	anni decorrenti dalla sottoscrizione attraverso servizio di FEA OTP. I
	dati personali forniti dall'interessato in relazione alle richieste di
	contatto e di assistenza saranno trattati per il tempo indispensabile
	al perseguimento delle specifiche finalit&agrave;.</div>
<br />
<div class="text-left">
	6. <u>Che cosa succede se l'interessato non fornisce i dati
		personali necessari ai fini dell'erogazione del servizio di FEA OTP?</u>
</div>
<div class="text-left">La raccolta e il trattamento dei dati
	personali &egrave; necessario per dar seguito alle prestazioni
	richieste nonch&egrave; all'erogazione del servizio di FEA OTP. Qualora
	l'interessato non fornisca i dati personali espressamente previsti come
	necessari, il titolare non potr&agrave; erogare il servizio e dar
	seguito agli adempimenti derivanti dal servizio medesimo.</div>
<br />
<div class="text-left">
	7. <u>Qual &egrave; la fonte da cui hanno origine i dati personali?
	</u>
</div>
<div class="text-left">I dati personali sono ottenuti presso
	l'interessato.</div>
<br />
<div class="text-left">
	8. <u>Quali sono i diritti dell'Interessato?</u>
</div>
<div class="text-left">Ai sensi della normativa privacy
	applicabile, gli interessati hanno il diritto di:</div>
<div class="text-left">
	a. Ottenere conferma dell'esistenza dei dati personali che li
	riguardano e la loro comunicazione in forma intellegibile;<br /> b.
	Conoscere l'origine dei dati personali, la finalit&agrave; del
	trattamento e le relative modalit&agrave;, cos&igrave; come la logica
	sottesa al trattamento effettuato attraverso mezzi automatizzati;<br />
	c. Richiedere l'aggiornamento, rettifica o integrazione dei dati
	personali;<br /> d. Ottenere la cancellazione, anonimizzazione o il
	blocco dei dati personali trattati in violazione di legge e opporsi,
	per motivi legittimi, al trattamento;<br /> e. Revocare in qualsiasi
	momento il consenso al trattamento, senza pregiudizio della
	liceit&agrave; del trattamento effettuato sulla base del medesimo
	consenso anteriormente alla revoca;<br /> f. Ottenere una limitazione
	del trattamento nel caso in cui (a) gli Interessati contestino
	l'accuratezza dei dati personali, per un periodo che consenta al
	Titolare di verificarne l'accuratezza; (b) il trattamento sia illecito
	e gli Interessati si oppongano alla cancellazione dei dati personali e
	richiedano, in alternativa, la limitazione del trattamento; (c) il
	Titolare non abbia necessit&agrave; di trattare i dati personali degli
	Interessati per il conseguimento delle finalit&agrave; del trattamento,
	ma il trattamento sia necessario agli Interessati per la tutela
	giudiziaria dei propri diritti; (d) gli Interessati si siano opposti al
	trattamento e sia pendente la valutazione circa il bilanciamento degli
	interessi degli Interessati coinvolti e del titolare;<br /> g. Opporsi
	al trattamento dei dati personali;<br /> h. Ricevere in un formato
	strutturato, di uso comune e leggibile da un dispositivo automatico i
	dati personali che li riguardano e che sono stati forniti al Titolare;
	nonch&egrave;, previa richiesta, il diritto di trasmettere tali dati a
	un altro titolare del trattamento (c.d. portabilit&agrave; dei dati);<br />
	i. Inoltrare un reclamo dinanzi all'autorit&agrave; per la protezione
	dei dati personali, ovvero ad altro soggetto competente in materia, se
	ne ricorrono i presupposti;<br /> j. Conoscere i destinatari o le
	categorie di destinatari a cui i dati personali sono stati o saranno
	comunicati, in particolare se destinatari di paesi terzi o
	organizzazioni internazionali.<br />
</div>
<br />
<div class="text-left">Per l'esercizio dei Suoi diritti o avere
	maggiori informazioni pu&ograve; rivolgersi a GDL S.p.A. alla seguente
	mail: privacy@gdlspa.it .</div>
<br />

<div class="text-left">
	<div class="col-md-4 col-4">
		<mat-radio-group labelPosition="after" class="" color="primary"
			aria-label="Tipo" [(ngModel)]="form.ordine.accettprivacy">
		<mat-radio-button class="mb-2 mr-2" [value]="true">Si</mat-radio-button>
		<mat-radio-button class="mr-2" [value]="false">No</mat-radio-button> </mat-radio-group>
	</div>
</div>


<div style="padding: 10px 0;">
	<div class="text-center">
		<b>MODULO DI ACQUISIZIONE DEL CONSENSO AL TRATTAMENTO DEI DATI PERSONALI<br />
		CONSENSO AL TRATTAMENTO DEI DATI RELATIVI ALLA SALUTE</b>
	</div>
	<div class="mb-2 text-left">
		Il/La sottoscritto/a interessato/a, ricevuta, ai sensi dell'art. 13
		del Regolamento (UE) 2016/679 del Parlamento Europeo e del Consiglio
		del 27 aprile 2016 ("Regolamento Generale sulla Protezione dei Dati")
		l'Informativa Estesa relativa al trattamento dei dati personali,
		compresi i Dati Relativi alla Salute, sottoscrivendo il presente
		documento presta il consenso al trattamento dei Dati Relativi alla
		Salute, ai fini dell'esecuzione del Contratto e/o del rispetto di
		adempimenti fiscali e/o di legge connessi alla conclusione e/o
		esecuzione del Contratto (vedi sezione 10 dell'Informativa Estesa).<br />
		Il conferimento dei Dati Relativi alla Salute &egrave; facoltativo: non
		esiste alcun obbligo di legge o di contratto che preveda a carico
		dell'interessato/a l'obbligo di fornire tali dati. Tuttavia, nel caso
		in cui il conferimento di tali dati e il consenso al loro trattamento
		siano necessari al fine di concludere il Contratto e/o per dare
		esecuzione alle obbligazioni nascenti dal Contratto a carico delle
		parti, l'eventuale mancato conferimento di tali dati e/o l'eventuale
		mancato consenso esplicito al trattamento di tali dati comporter&agrave; per
		l'interessato/a l'impossibilit&agrave; di concludere il Contratto. Nel caso
		in cui, invece, il conferimento dei Dati Relativi alla Salute e il
		consenso al loro trattamento siano necessari al fine di soddisfare una
		richiesta dell'interessato/a, anche legata all'eventuale regime
		fiscale applicabile al Contratto, l'eventuale mancato conferimento di
		tali dati e/o l'eventuale mancato consenso esplicito al trattamento di
		tali dati non comporter&agrave; alcuna conseguenza sulla possibilit&agrave; per
		l'interessato/a di concludere il Contratto, ma comporter&agrave;
		l'impossibilit&agrave; per l'interessato/a di vedere soddisfatta la sua
		richiesta (e, quindi, per esempio, di vedere applicato al Contratto il
		regime fiscale agevolato richiesto).<br /> Nel caso di mancata
		prestazione del consenso al trattamento dei Dati Relativi alla Salute,
		i Dati Relativi alla Salute eventualmente gi&agrave; forniti
		dall'interessato saranno immediatamente cancellati.<br />
		L'interessato/a potr&agrave; revocare il consenso, contattando GDL
		all'indirizzo e-mail privacy@gdlspa.it o chiamando il numero
		011.2248510.<br /> GDL conserver&agrave; i Dati Relativi alla Salute per
		un arco di tempo non superiore al conseguimento delle finalit&agrave; per le
		quali sono trattati, fermi gli ulteriori periodi di conservazione
		consentiti dalla legge o dal Regolamento.<br /> I Dati Relativi alla
		Salute eventualmente conferiti non saranno trattati per finalit&agrave; di
		marketing.<br /> La revoca del consenso non pregiudica la liceit&agrave;
		del trattamento basato sul consenso prestato prima della revoca.
	</div>
	<br />
	<div class="text-left">
		<div class="col-md-4 col-4">
			<mat-radio-group labelPosition="after" class="" color="primary"
				aria-label="Tipo" [(ngModel)]="form.ordine.accettprivacynd">
			<mat-radio-button class="mb-2 mr-2" [value]="true">Si</mat-radio-button>
			<mat-radio-button class="mr-2" [value]="false">No</mat-radio-button>
			</mat-radio-group>
		</div>
	</div>
</div>
<br />
<div style="padding: 10px 0;">
	<div class="text-center"><b>CONSENSO PER FINALITA' DI MARKETING
		GENERICO</b></div>
	<div class="text-left">
		Il/La sottoscritto/a interessato/a, ricevuta, ai sensi dell'art. 13
		del Regolamento (UE) 2016/679 del Parlamento Europeo e del Consiglio
		del 27 aprile 2016 ("Regolamento Generale sulla Protezione dei Dati")
		l'Informativa Estesa relativa al trattamento dei dati personali,
		presta il consenso al trattamento dei dati personali (esclusi i Dati
		Relativi alla Salute) al fine dell'invio da parte di GDL di
		comunicazioni commerciali, riferite a prodotti e/o servizi propri e/o
		di terzi, e per lo svolgimento di ricerche di mercato, anche dirette a
		valutare il grado di soddisfazione dei clienti (customer
		satisfaction), per telefono, e-mail o con altre modalit&agrave;
		automatizzate (es: applicazioni) (vedi sezione 5 dell'Informativa
		Estesa) ("Finalit&agrave; di Marketing Generico").<br /> L'interessato/a
		potr&agrave; revocare in ogni momento il consenso prestato e/o opporsi, in
		qualsiasi momento, al trattamento dei suoi dati per Finalit&agrave; di
		Marketing Generico scrivendo a GDL all'indirizzo e-mail privacy@gdlspa.it
		o chiamando il numero 011.2248510.<br /> Il conferimento dei dati
		per la Finalit&agrave; di Marketing Generico &egrave; facoltativo: non esiste alcun
		obbligo legale o contrattuale a carico dell'interessato/a di fornire
		e/o di prestare il consenso al trattamento dei suoi dati personali per
		tale finalit&agrave;. La mancata comunicazione dei dati per la finalit&agrave; di
		Marketing Generico e/o la mancata prestazione del consenso a tale
		trattamento e/o la revoca di tale consenso e/o l'esercizio del diritto
		di opposizione non comportano alcuna conseguenza sulla possibilit&agrave;
		dell'interessato/a di concludere il Contratto.<br /> Si segnala
		all'interessato/a quanto segue:<br /> • per la finalit&agrave; di
		Marketing Generico, GDL utilizza dati personali raccolti inizialmente
		per altre finalit&agrave; e, segnatamente, per concludere il Contratto e
		dare esecuzione alle obbligazioni nascenti dal Contratto a carico
		delle parti; tale trattamento ulteriore &egrave; consentito, in quanto basato
		sul consenso dell'interessato/a;<br /> • la revoca del consenso non
		pregiudica la liceit&agrave; del trattamento basato sul consenso prestato
		prima della revoca;<br /> • l'opposizione e/o la revoca manifestata
		attraverso modalit&agrave; automatizzate di contatto (es. e-mail) si estende
		anche all'invio di comunicazioni mediante modalit&agrave; tradizionali (es.
		mediante telefono), fatta salva la possibilit&agrave; per l'interessato/a di
		esercitare tale diritto in parte, ossia opponendosi, ad esempio, al
		solo invio di comunicazioni promozionali effettuato tramite strumenti
		automatizzati;<br /> • nel caso in cui l'interessato/a si opponga
		al trattamento dei suoi dati per Finalit&agrave; di Marketing Generico e/o
		revochi il consenso al trattamento per Finalit&agrave; di Marketing
		Generico, i suoi dati non saranno pi&ugrave; trattati da GDL per tale
		finalit&agrave; e saranno conservati da GDL solo nel caso in cui sussista
		un'altra base giuridica (come, per esempio, l'accertamento,
		l'esercizio o la difesa di un diritto in sede giudiziaria o
		amministrativa);<br /> • per la Finalit&agrave; di Marketing Generico,
		GDL tratter&agrave; i dati dell'interessato/a sino alla revoca del consenso
		e/o all'esercizio del diritto di opposizione e, in ogni caso, non
		oltre 24 mesi dalla raccolta dei dati, riservandosi la facolt&agrave;, prima
		della scadenza di tale termine, di chiedere all'interessato/a il
		rinnovo del consenso e/o l'aggiornamento dei dati.
	</div><br/>
	<div class="text-left">
		<div class="col-md-4 col-4">
			<mat-radio-group labelPosition="after" class="" color="primary"
				aria-label="Tipo" [(ngModel)]="form.ordine.accettprivacyrd">
			<mat-radio-button class="mb-2 mr-2" [value]="true">Si</mat-radio-button>
			<mat-radio-button class="mr-2" [value]="false">No</mat-radio-button>
			</mat-radio-group>
		</div>
	</div>	
</div>