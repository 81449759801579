import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainComponent } from 'src/app/main/main.component';
import { BrandService } from 'src/app/services/brand/brand.service';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { ProdottoService } from 'src/app/services/prodotto/prodotto.service';
import { AnagraficaDTO } from '../../classes/anagrafica';
import { Brand } from '../../classes/brand';
import { Ordine } from '../../classes/ordine';
import { Prodotto } from '../../classes/prodotto';
@Component({
  selector: 'app-create-estimate',
  templateUrl: './create-estimate.component.html',
  styleUrls: ['./create-estimate.component.scss']
})
export class CreateEstimateComponent implements OnInit {

	id: number;
	idGdl:number;

	form: any;

	listBrand: Brand[] = [];
	listProdotti: Prodotto[] = [];
	prodottoSel: Prodotto;

	constructor(
		private route: ActivatedRoute,
		private brandService: BrandService,
		private prodottoService: ProdottoService,
		private ordineService:OrdersService,
		private mainCompo:MainComponent,
		private router: Router,
	) {
		this.id = Number(this.route.snapshot.paramMap.get('id'));
		this.idGdl = Number(localStorage.getItem('id_gdl'));
		this.form = {
			idBrand: null,
			idProdotto: null,
			idCliente : null,
			nome: [],
			cognome:[]
		};
	}


	ngOnInit(): void {
		/*this.ordineService.getSingleOrder(this.id)
			.subscribe( res => {
				this.form.cognome = res.cognome, 
				this.form.nome = res.nome 
			});*/
			
		this.brandService.getBrands().subscribe(res => { 
      
			res.forEach(brand => {
				if(brand.codice === 'ARIEL'){
					this.listBrand.push(brand);
				}
			})
		})
	}

	changeModel(event): void {
		delete this.form.idProdotto;
		this.listProdotti = [];
		if (event !== undefined) {
			this.prodottoService.getProdottos(event, 'brandId.equals=').subscribe(
				res => { 
					res.forEach(product => {
						if(product.commessa ===  "SFA-T00177-UT"){
							this.listProdotti.push(product);
						}
					})
			})
		}
	}

	save():void{
		var anagrafica = new AnagraficaDTO();
		anagrafica.nome = this.form.nome;
		anagrafica.cognome = this.form.cognome;
		//recupero la commessa
		this.prodottoService.getProdottos(this.form.idProdotto ,"id.equals=").subscribe(res => {

		this.prodottoSel = res[0];
					
		//creo il nuovo preventivo
		var order = this.createOrder(this.form.cognome,this.form.nome);		
			
      	this.ordineService.save(order).subscribe( res => {
			if(res.id !== undefined){
			    localStorage.setItem('id_product',String( order.prodottoId));
				localStorage.removeItem('id_gdl');
					if (this.prodottoSel.commessa === 'SFA-T00177-UT'){
						//ARIEL FOTOVOLTAICO
						this.router.navigate(['new-estimate/ARIEL/fotovoltaico/' + res.id]);
					}
				}
			});
		});
	}
	
	
  private createOrder(cognome,nome): Ordine {
    return {
      ...new Ordine(),
      brandId: this.form.idBrand,
      prodottoId: this.form.idProdotto,
   	  idordine:new Date().getTime(),
      statopraticaId:2,
      venditoreId:this.mainCompo.loggedUser.id,
	  dataordine:new Date(),
      cognome: '',
	  nome: '',
	  gdlid:this.idGdl,
    };
  }


  validateForm():boolean{
	var result = false;
	
	if(this.form.idBrand !== null && this.form.idBrand !== undefined && this.form.idProdotto !== null && this.form.idProdotto !== undefined){
		result = true;	
	}
	
	return result;
  }

}
