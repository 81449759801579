export class Trascodifica{
	id: number;
	codice:string;
	descrizione:string;
	parentId:number;
	qta:number;
	note:string;
	prodottoId?: number;
	promoId?: number;
	ordinamento?:number;
	valido?:boolean;
}
