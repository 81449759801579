import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../../../../services/http.service'
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {
	catchError,
	retry,
} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class BoxdocciaService extends HttpService {
	public url = this.apiUrl + 'boxdoccias';
	constructor(
		public router: Router,
		public http: HttpClient
	) {
		super(router, http);
	}

	save(box: any): Observable<any> {
		return this.http.post<any>(this.apiUrl + 'boxdoccias', box, this.setHttpOptions()).pipe(
			retry(0),
			catchError(err => this.handleError(err))
		);
	}

	update(box: any): Observable<any> {
		return this.http.put<any>(this.apiUrl + 'boxdoccias', box, this.setHttpOptions()).pipe(
			retry(0),
			catchError(err => this.handleError(err))
		);
	}

	findByKey(data: any, key: string): Observable<any> {
		return this.http
			.get<any>(this.apiUrl + 'boxdoccias?' + key + data, this.setHttpOptions())
			.pipe(
				retry(0),
				catchError(this.handleError)
			);
	}

	delete(id: any): Observable<any> {
		return this.http.delete<any>(`${this.url}/${id}`, this.setHttpOptions())
			.pipe(
				retry(0),
				catchError(this.handleError)
			);
	}

}