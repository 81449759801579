<div class="row mb-3">
    <div class="col-6">
        <div class="row smallFont">
            <div class="col-12">
                <div><b>G.D.L. S.p.A.</b></div>
                <div>
                    Via Orbetello, 54/D<br />
                    10148 TORINO<br />
                    tel. 011 22.48.515 / fax 011 22.95.425<br />
                    {{email}}<br />
                    IBAN: IT 30 J 02008 01119 000100689984
                </div>
            </div>
        </div>
    </div>
    <div class="col-3">
        <mat-form-field class="mb-2 full-width" appearance="standard">
            <mat-label>Contratto N.</mat-label>
            <input matInput disabled placeholder="N° contratto" [ngModel]="form.ordine?.idordine"
                (ngModelChange)="form.ordine.idordine=$event">
        </mat-form-field>

        <mat-form-field class="full-width" appearance="standard">
            <mat-label>Data</mat-label>
            <input (click)="picker.open()" [(ngModel)]="form.anagrafica.infoContratto.data" matInput
                [matDatepicker]="picker" [min]="minDate" required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-3">
        <mat-form-field class="mb-2 full-width" appearance="standard">
            <mat-label>Consulente</mat-label>
            <input matInput [(ngModel)]="form.anagrafica.infoContratto.consulente" placeholder="consulente" required>
            <mat-hint class="text-danger" *ngIf="!form.anagrafica.infoContratto.consulente">Nome consulente
                richiesto</mat-hint>
        </mat-form-field>
    </div>
</div>

<div class="text-center mb-3">
    CAPITOLATO FORNITURA
</div>
<div class="anagraficaContainer mb-4" [ngClass]="{'onlyRedBorder': !validAnagrafica}">
    <div class="row">
        <div class="col-6">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Nome</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.nome" placeholder="Nome" required>
                <mat-hint class="text-danger" *ngIf="!form.anagrafica.anagrafica.nome">Nome richiesto</mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Cognome</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.cognome" placeholder="Cognome" required>
                <mat-hint class="text-danger" *ngIf="!form.anagrafica.anagrafica.cognome">Cognome richiesto</mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Email</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.email" placeholder="Email" required>
                <mat-hint class="text-danger" *ngIf="!formController.checkMail(form.anagrafica.anagrafica.email)">Email
                    valida richiesta</mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Telefono 1</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.tel1" placeholder="Telefono 1" type="number"
                    required>
                <mat-hint class="text-danger"
                    *ngIf="!formController.checkPhone(form.anagrafica.anagrafica.tel1)">Telefono valido
                    richiesto</mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Telefono 2</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.tel2" placeholder="Telefono 2" type="number">
            </mat-form-field>

            <mat-form-field class="full-width" appearance="standard" *ngIf="selectedCFoIVA === 'piva'" style="margin-top: 12px;">
                <mat-label>{{ 'Ragione sociale ' }}</mat-label>
                <input matInput type="text" [(ngModel)]="form.anagrafica.anagrafica.ragionesociale" 
                  [required]="selectedCFoIVA === 'piva'"
                  placeholder="Ragione sociale">
                <mat-hint class="text-danger" *ngIf="!form.anagrafica.anagrafica.ragionesociale && selectedCFoIVA === 'piva'">Ragione sociale
                    richiesta
                </mat-hint>
            </mat-form-field>

            <div *ngIf="selectedCFoIVA === 'cf'">
                <ng-container *ngTemplateOutlet="indirizzoInstallazione"></ng-container>
            </div>
        </div>

        <div class="col-6">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Comune</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.citta" placeholder="Comune" required>
                <mat-hint class="text-danger" *ngIf="!form.anagrafica.anagrafica.citta">Comune richiesto</mat-hint>
            </mat-form-field>

            <div class="row rowMargin">
                <div class="col-8">
                    <mat-form-field class="full-width" appearance="standard">
                        <mat-label>Via</mat-label>
                        <input matInput [(ngModel)]="form.anagrafica.anagrafica.indirizzo" placeholder="Via" required>
                        <mat-hint class="text-danger" *ngIf="!form.anagrafica.anagrafica.indirizzo">Via
                            richiesta</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field class="full-width" appearance="standard">
                        <mat-label>Civico</mat-label>
                        <input matInput [(ngModel)]="form.anagrafica.anagrafica.numero" placeholder="Civico" required>
                        <mat-hint class="text-danger" *ngIf="!form.anagrafica.anagrafica.numero">Civico
                            richiesta</mat-hint>
                    </mat-form-field>
                </div>
            </div>

            <div class="row rowMargin">
                <div class="col-4">
                    <mat-form-field class="full-width" appearance="standard">
                        <mat-label>Cap</mat-label>
                        <input matInput [(ngModel)]="form.anagrafica.anagrafica.cap" type="number" placeholder="Cap"
                            required>
                        <mat-hint class="text-danger" *ngIf="!form.anagrafica.anagrafica.cap">Cap richiesto</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field class="full-width" appearance="standard">
                        <mat-label>Provincia</mat-label>
                        <input type="text" required placeholder="Provincia" aria-label="Provincia" matInput
                            [(ngModel)]="form.anagrafica.anagrafica.provincia" [matAutocomplete]="auto"
                            (ngModelChange)="filter(form.anagrafica.anagrafica.provincia)">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let provincia of filtered" [value]="provincia.sigla">
                                {{provincia.nome}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-hint class="text-danger" *ngIf="!form.anagrafica.anagrafica.provincia">Provincia
                            richiesta</mat-hint>
                    </mat-form-field>
                </div>

                <div class="col-4">
                    <br />
                    <mat-checkbox class="mt-2" color="primary" [(ngModel)]="form.anagrafica.anagrafica.ztl"
                        [checked]="form.anagrafica.anagrafica.ztl">
                        ZTL.
                    </mat-checkbox>
                </div>
            </div>

            <br>
            <mat-radio-group class="full-width" appearance="standard" [(ngModel)]="selectedCFoIVA" (ngModelChange)="resetInput()">
                <mat-radio-button value="cf">Codice Fiscale</mat-radio-button>
                <mat-radio-button value="piva">Partita IVA</mat-radio-button>
              </mat-radio-group>
            <br>

            <mat-form-field class="full-width" appearance="standard" style="margin-top: 12px;">
                <mat-label>{{ selectedCFoIVA === 'cf' ? 'Codice Fiscale  ' : 'Partita IVA  ' }}</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica[selectedCFoIVA]" (input)="onChangeValue(form.anagrafica.anagrafica[selectedCFoIVA])"
                  [required]="selectedCFoIVA === 'cf'"
                  placeholder="{{ selectedCFoIVA === 'cf' ? 'Codice Fiscale' : 'Partita IVA' }}">
                <mat-hint class="text-danger" *ngIf="!isValid(selectedCFoIVA)">{{ getValidationMessage(selectedCFoIVA) }}</mat-hint>
                <mat-hint align="end" *ngIf="form.anagrafica.anagrafica.cf"> {{selectedCFoIVA === 'cf' ? form.anagrafica.anagrafica.cf.length + ' / 16' : form.anagrafica.anagrafica.cf.length + ' / 11' }}</mat-hint>
            </mat-form-field>

            <div *ngIf="selectedCFoIVA === 'piva'">
                <ng-container *ngTemplateOutlet="indirizzoInstallazione"></ng-container>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="form.anagrafica.anagrafica.diversoind">
        <div class="col-12">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Indirizzo installazione</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.indirizzoinst"
                    [required]="form.anagrafica.anagrafica.diversoind" placeholder="Indirizzo installazione">
            </mat-form-field>
        </div>
    </div>
</div>

<div class="text-center">
    G.D.L. S.p.A. (con sede in Via Orbetello, 54/D, 10148 Torino, CF: 10062800015, {{this.email}}, {{this.sito}},
    iscritta alla Camera di Commercio di Torino, n° registro imprese TO 1102436) qui rappresentata dall'agente
    <mat-form-field class="mr-2 ml-2" appearance="standard">
        <input matInput [(ngModel)]="form.anagrafica.agente" required placeholder="Agente">
    </mat-form-field>
    all'uopo autorizzato e munito di specifici poteri rappresentativi
</div>


<ng-template #indirizzoInstallazione>
    <div>
        <br><br>
        <mat-checkbox class="mt-2" color="primary" [(ngModel)]="form.anagrafica.anagrafica.diversoind"
            (change)="resetNewAddress($event.checked)" [checked]="form.anagrafica.anagrafica.diversoind">
            Diverso indirizzo installazione
        </mat-checkbox>
    </div>
</ng-template>
