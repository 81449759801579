import {
  Component,
  AfterViewInit,
  ViewChild,
  OnInit,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MainComponent } from '../../../main/main.component';
import { OrdersService } from '../../../services/orders/orders.service';
import { FormControllerService } from '../../../services/controller/form-controller.service';
import { SignatureService } from '../../../services/signature/signature.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss']
})
export class OrdersListComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['idOrdine', 'nomeProdotto', 'brandDescrizione', 'dataordine', 'clienteCf', 'statopraticaDescrizione', 'actions'];
  dataSource: MatTableDataSource<any>;
  loading: boolean;
  contracts: any;
  nameUser: any;
  listStatoPratica: any;
  clonedList: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isApiV3 = environment.isV3;
  constructor(
    private router: Router,
    private orderService: OrdersService,
    private mainCompo: MainComponent,
    private formController: FormControllerService,
    private signatureService: SignatureService,
  ) {

  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    if (this.mainCompo.loggedUser) {
      this.getContracts();
    }
  }

  getContracts(): any {
    this.loading = true;

    this.orderService.getOrders(this.mainCompo.loggedUser.id, 'size=10000&statopraticaCodice.in=I&statopraticaCodice.in=W&statopraticaCodice.in=F&statopraticaCodice.in=R&statopraticaCodice.in=FU&venditoreId.equals=').subscribe(res => {
      this.loading = false;
      this.contracts = res;
      this.clonedList = [...this.contracts];
      this.nameUser = this.mainCompo.loggedUser.firstName + ' ' + this.mainCompo.loggedUser.lastName;
      this.contracts.forEach(r => {
        r.statopraticaDescrizione = this.capitalize(r.statopraticaDescrizione);
        r.brandDescrizione = this.capitalize(r.brandDescrizione);
        r.nomeProdotto = this.returnProdName(r.prodottoCommessa);
      });
      this.dataSource = new MatTableDataSource(this.contracts);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }


  capitalize(value): string {
    return this.formController.capitalize(value);
  }

  /* downloadPdf(idordine, idFile, idSignatureRequest): void {
     let link;
     if (this.isApiV3) {
       this.signatureService.downloadPdf(idSignatureRequest, idFile).subscribe(res => {
         const blob = new Blob([res], { type: "application/pdf" });
         const data = window.URL.createObjectURL(blob);
         link = document.createElement("a");
         link.href = data;
       });
     } else {
       this.signatureService.downoloadFile(idFile).subscribe(res => {
         if (res !== undefined) {
           link = document.createElement("a");
           link.href = 'data:application/octet-stream;base64,' + res;
         }
       })
     }
 
     link.download = idordine + ".pdf";
     link.click();
 
   }*/

  disabledDownload(element): boolean {
    if (element !== 'I' && element !== 'F' && element !== 'R') {
      return true;
    } else {
      return false;
    }

  }

  returnProdName(opt): string {
    return this.formController.returnProdName(opt);
  }

  createOrder(id, gdlid): void {
    this.router.navigate(['create-order/' + id]);
    localStorage.removeItem('id_client');
    localStorage.setItem('id_gdl', gdlid);
  }

  uploadDocument(id, gdlid): void {
    this.router.navigate(['upload-document/' + id + '/' + gdlid]);
    localStorage.removeItem('id_client');
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    const filterIng = (event.target as HTMLInputElement).value.toLowerCase();
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (event) {
      this.clonedList = [];
      this.contracts.forEach(c => {
        if (
          c.brandDescrizione?.toLowerCase().includes(filterIng) ||
          c.cognome?.toLowerCase().includes(filterIng) ||
          c.nomeProdotto?.toLowerCase().includes(filterIng) ||
          c.idordine?.toLowerCase().includes(filterIng)
        ) {
          this.clonedList.push(c);
        }
      });
      this.dataSource = new MatTableDataSource(this.clonedList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } else {
      this.dataSource = new MatTableDataSource(this.contracts);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

}
