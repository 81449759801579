import { Component, OnInit } from '@angular/core';
import { BrandService } from 'src/app/services/brand/brand.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-tech-dashboard',
  templateUrl: './tech-dashboard.component.html',
  styleUrls: ['./tech-dashboard.component.scss']
})
export class TechDashboardComponent implements OnInit {
  currentUser: any;

  constructor(
    private brandService: BrandService,
    public userService: UserService,
) {
  this.currentUser = JSON.parse(localStorage.getItem('or_profile'));
}

ngOnInit(): void {
}

}
