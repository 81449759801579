import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ariel-main',
  templateUrl: './ariel-main.component.html',
  styleUrls: ['./ariel-main.component.scss']
})
export class ArielMainComponent implements OnInit {
  products: any[];
  constructor(
      private router: Router,
  ) {
    this.products = [
        {
          name: 'Stufe a pellet',
          path: 'stufe-pellet'
        },
        {
          name: 'Climatizzatori',
          path: 'climatizzatori'
        },
        {
          name: 'Caldaie',
          path: 'caldaie'
        },
      ];
  }

  ngOnInit(): void {
  }

  goToProduct(value): void {
    this.router.navigate(['new-order/ARIEL/' + value]);
  }
}
