import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpService } from '../http.service';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { TabelleFinanziarie } from 'src/app/modules/classes/finanziamento/tabelleFinanziarie-dto';


@Injectable({
  providedIn: 'root'
})
export class TabelleFinanziarieService extends HttpService {
  
  endpoint: string = 'tabellefinanziarie';
  endpointCodiceTabelleDistint: string = 'codici-tabelle-distint';
  endpointImportoTabelleDistint: string = 'importo-tabelle-distint'
  endpointNrateTabelle: string = 'nrate-tabelle';
  endpointAllDataTabelle: string = 'all-data-tabelle';

  constructor(
    public router: Router,
    public http: HttpClient
  ) {
    super(router, http);
  }

  save(montascale: TabelleFinanziarie): Observable<any> {			
		return this.http.post<any>(this.apiUrl + this.endpoint, montascale, this.setHttpOptions()).pipe(
			retry(0),
			catchError(err => this.handleError(err))
		);
	}

  update(montascale: any): Observable<any> {			
		return this.http.put<any>(this.apiUrl + this.endpoint, montascale, this.setHttpOptions()).pipe(
			retry(0),
			catchError(err => this.handleError(err))
		);
	}
	
	findByKey(data:any , key:string): Observable<any>{
	    return this.http
           .get<any>(this.apiUrl + this.endpoint+'?'+key+data ,this.setHttpOptions())
           .pipe(
               retry(0),
               catchError(this.handleError)
           );
	}

  getCodiciTabellaDistint(finanziaria:any): Observable<any>{
    return this.http
         .get<any>(this.apiUrl + this.endpoint + "/" + this.endpointCodiceTabelleDistint+'?'+ 'finanziariaName=' + finanziaria ,this.setHttpOptions())
         .pipe(
             retry(0),
             catchError(this.handleError)
         );
  }

  getImportoTabellaDistint(finanziaria:any, codiceTabella:any): Observable<any>{
    return this.http
         .get<any>(this.apiUrl + this.endpoint + "/" + this.endpointImportoTabelleDistint+'?'+ 'finanziariaName=' + finanziaria + "&" + "codiceTabella=" + codiceTabella ,this.setHttpOptions())
         .pipe(
             retry(0),
             catchError(this.handleError)
         );
  }

  getNumRate(finanziaria:any, codiceTabella:any, importo:any): Observable<any>{
    return this.http
         .get<any>(this.apiUrl + this.endpoint + "/" + this.endpointNrateTabelle+'?'+ 'finanziariaName=' + finanziaria + "&" + "codiceTabella=" + codiceTabella + "&" + "importoContratto=" + importo,this.setHttpOptions())
         .pipe(
             retry(0),
             catchError(this.handleError)
         );
  }

  getLatestDataProspetto(finanziaria:any, codiceTabella:any, importo:any, numeroRate:any): Observable<any>{
    return this.http
         .get<any>(this.apiUrl + this.endpoint + "/" + this.endpointAllDataTabelle+'?'+ 'finanziariaName=' + finanziaria + "&" + "codiceTabella=" + codiceTabella 
            + "&" + "importoContratto=" + importo + "&" + "numeroRate=" + numeroRate,this.setHttpOptions())
         .pipe(
             retry(0),
             catchError(this.handleError)
         );
  }
  
	
	delete(id: any): Observable<any> {
		return this.http.delete<any>(this.apiUrl+this.endpoint+'/'+id, this.setHttpOptions())
			.pipe(
				retry(0),
				catchError(this.handleError)
			);
	}
}
