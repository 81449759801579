import { Component, Input, OnInit } from '@angular/core';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';
import {InstallazioneAccessoriService} from '../../services/installazione-accessori.service';
import { Trascodifica } from '../../../../../classes/trascodifica';
import {InstallazioneAccessoriDto} from '../../classes/installazione-accessori-dto';
import { constants } from 'src/app/modules/classes/constants';
@Component({
  selector: 'app-installazione-accessori',
  templateUrl: './installazione-accessori.component.html',
  styleUrls: ['./installazione-accessori.component.scss']
})
export class InstallazioneAccessoriComponent implements OnInit {
  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  installazioneAcc: InstallazioneAccessoriDto;
  listaPosizioneUE: Trascodifica[];
  constructor(
		private formController: FormControllerService,
		private installazioneAccService: InstallazioneAccessoriService,
	) { }

  ngOnInit(): void {
	// this.form.sections.installazioneAcc.ordineId = this.form.idOrder;
	this.getInstallazioneAcc();
	this.listaPosizioneUE = this.formController.getTrascodificaByCodice(constants.COD_POSIZIONE_UE, this.trascodifiche);
  }


  getInstallazioneAcc(): void {

    this.installazioneAccService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
      this.installazioneAcc = res[0];

      if (this.installazioneAcc !== undefined) {
        this.form.sections.installazioneAcc = this.installazioneAcc;
		}
    });

  }

  controlloStaffe(event): void {
  	if (event === false) {
	    delete this.form.sections.installazioneAcc.posizioneueId;
	    delete this.form.sections.installazioneAcc.qtaue;
    }
  }

  controlloInstPred(event): void {
  	if (event === false) {
	    delete this.form.sections.installazioneAcc.qtainstconpred;
    }
  }

  controlloInstSenza(event): void {
  	if (event === false) {
	    delete this.form.sections.installazioneAcc.qtainstsenzapred;
    }
  }
	
	controlloPompaSenza(event): void {
		if (event === false) {
			delete this.form.sections.installazioneAcc.numpompecondensa;
		}
	}

}
