import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  loggedUser: any;
  useRole:string = '';
  redirectTo:string = '';

  constructor(
      private router: Router,
      private authService: AuthenticationService,
  )
  {
    this.useRole = this.authService.returnUserRole();
    switch (this.useRole) {
      case 'ROLE_TECH':{
        this.redirectTo = '/tech_dashboard';
      }
      break;
      default: {
        this.redirectTo = '/dashboard';
      }
    }
  }

  ngOnInit(): void {
    this.loggedUser = JSON.parse(localStorage.getItem('or_profile'));
    $(window).scroll(() =>  {
      const scroll = $(window).scrollTop();

      // >=, not <=
      if (scroll >= 10) {
        // clearHeader, not clearheader - caps H
        $('.logImg').addClass('miniLogo');
      } else {
        $('.logImg').removeClass('miniLogo');
      }

      if (scroll >= 150) {
        $('.toTop').addClass('visibleToTop');
      } else {
        $('.toTop').removeClass('visibleToTop');
      }

    });
    /*
    setInterval(() => {
      this.noBackPlease();
    }, 50);
     */
  }

  noBackPlease(): void {
    if (!window.location.href.includes('#')) {
      window.location.href += '#';
      // making sure we have the fruit available for juice....
      // 50 milliseconds for just once do not cost much (^__^)
      setTimeout(() => {
        window.location.href += '!';
      }, 50);
    }
  }

  checkRole(obg): boolean {
    return this.authService.checkRole(this.loggedUser);
}

  getRoute(): boolean {
    return this.router.url === '/dashboard';
  }

  toDashboard(): boolean {
    return this.router.url === '/new-order' || this.router.url === '/orders-list';
  }

  goBack(): void{
    window.history.back();
  }

  scrollTop(): void {
    $('html, body').animate({ scrollTop: 0 }, 300);
  }

  showBrands(): void {
    $('.logoFooter').toggleClass('logoFooterVisible');
    $('.logoFooterBackDrop').toggleClass('showBackDrop');
  }

}
