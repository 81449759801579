import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { CaldaiaService } from '../services/caldaia.service';
import { BrandService } from '../../../../../../services/brand/brand.service';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';

@Component({
  selector: 'app-dettaglio-caldaia',
  templateUrl: './dettaglio-caldaia.component.html',
  styleUrls: ['./dettaglio-caldaia.component.scss']
})
export class DettaglioCaldaiaComponent implements OnInit {
  dettaglioResponse: any;
  @Input() form: any;
  constructor(
      private caldaiaService: CaldaiaService,
      private brandService: BrandService,
      private formController: FormControllerService,
  ) { }

  ngOnInit(): void {
    this.getDettaglio();
  }

  getDettaglio(): void {
    this.caldaiaService.getDettaglioCaldaia(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
      this.dettaglioResponse = res[0];
      if (this.dettaglioResponse?.id) {
        this.form.sections.dettaglioCaldaia = this.dettaglioResponse;
      }
      //this.form.sections.dettaglioCaldaia.ordineId = this.form.idOrder;
    });
  }

}
