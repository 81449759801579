<div class="zucchettiMontascaleBg">RILIEVO MISURE SCHIENA E SEDILE</div>
<div class="zucchettiMontascaleCont">
    <div class="container">
        <div class="row">
            <!-- Prima colonna -->
            <div class="col-sm-4 col-12">
                <div class="d-flex flex-column align-items-center">
                    <div class="mb-2">
                        <div class="customBlock">
                            <img src="assets/img/zucchetti/schiena1.jpg" style="object-fit: cover;"/>
                        </div>
                    </div>
                    <div class="mb-2">
                      <div class="customBlock">
                        <app-input-number
                            (onNumChange)="form.sections.montascaleMisure.distanzaschienaginocchio = $event"
                            [step]="0.1"
                            [num]="form.sections.montascaleMisure.distanzaschienaginocchio"
                            [decimal]="true"
                            [payment]="false"
                            [small]="false"
                            label="Schiena al ginocchio cm:">
                        </app-input-number>
                            <label>La distanza dalla schiena al
                                ginocchio deve essere compresa
                                tra {{constants.MIN_SCHIENA_GINOCCHIO_MISURA_MONTASCALE}} mm e i {{constants.MAX_SCHIENA_GINOCCHIO_MISURA_MONTASCALE}} mm
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Seconda colonna -->
            <div class="col-sm-4 col-12">
                <div class="d-flex flex-column align-items-center">
                    <div class="mb-2">
                        <div class="customBlock">
                            <img src="assets/img/zucchetti/schiena2.jpg" style="object-fit: cover;"/>
                        </div>
                    </div>
                    <div class="mb-2">
                      <div class="customBlock">
                        <app-input-number
                            (onNumChange)="form.sections.montascaleMisure.distanzasediletesta = $event"
                            [step]="0.1"
                            [num]="form.sections.montascaleMisure.distanzasediletesta"
                            [decimal]="true"
                            [payment]="false"
                            [small]="false"
                            label="Sedile alla testa cm:">
                        </app-input-number>
                            <label>La distanza tra la schiena e la testa
                                deve essere compresa tra {{constants.MIN_SCHIENA_TESTA_MISURA_MONTASCALE}} mm e
                                i {{constants.MAX_SCHIENA_TESTA_MISURA_MONTASCALE}} mm
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Terza colonna -->
            <div class="col-sm-4 col-12">
                <div class="d-flex flex-column align-items-center">
                    <div class="mb-2">
                        <div class="customBlock">
                            <img src="assets/img/zucchetti/schiena3.jpg" style="object-fit: cover;"/>
                        </div>
                    </div>
                    <div class="mb-2">
                      <div class="customBlock">
                        <app-input-number
                            (onNumChange)="form.sections.montascaleMisure.distanzaschienafronte = $event"
                            [step]="0.1"
                            [num]="form.sections.montascaleMisure.distanzaschienafronte"
                            [decimal]="true"
                            [payment]="false"
                            [small]="false"
                            label="Schiena alla fronte cm:">
                        </app-input-number>
                            <label>La distanza tra la parte posteriore
                                e quella anteriore deve essere
                                compresa tra {{constants.MIN_SCHIENA_FRONTE_MISURA_MONTASCALE}} mm e i {{constants.MAX_SCHIENA_FRONTE_MISURA_MONTASCALE}} mm
                            </label>
                      </div>
                    </div>
                </div>
            </div>
        </div>

        <br>
        <div class="row align-items-center">
            <div class="col-auto">
                <a>PESO DEL CLIENTE:</a>
            </div>
            <div class="col">
                <app-input-number
                    (onNumChange)="form.sections.montascaleMisure.pesocliente = $event"
                    [step]="0.1"
                    [num]="form.sections.montascaleMisure.pesocliente"
                    [decimal]="true"
                    [payment]="false"
                    [small]="true"
                    label="Kg">
                </app-input-number>
            </div>
        </div>
    </div>
</div>