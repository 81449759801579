import { Component, Input, OnInit } from '@angular/core';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { constants } from '../../../../../classes/constants';
@Component({
  selector: 'app-arrivo-in-alto-della-guida',
  templateUrl: './arrivo-in-alto-della-guida.component.html',
  styleUrls: ['./arrivo-in-alto-della-guida.component.scss']
})
export class ArrivoInAltoDellaGuidaComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: any;
  listTipoArrivoInAltoTrasc: Trascodifica[] = [];
  listFirstLineArrivoInAltoTrasc: Trascodifica[] = [];
  listSecondLineArrivoInAltoTrasc: Trascodifica[] = [];

  constructor(private formController:FormControllerService) { }

  ngOnInit(): void {
    this.listTipoArrivoInAltoTrasc = this.formController.getTrascodificaByCodice(constants.MONTASCALE_TIPO_ARRIVO_ALTO_GUIDA , this.trascodifiche);

    this.listTipoArrivoInAltoTrasc.forEach((el, index) => {
      if(index <= 2){
        //inserisco i primi 3 elementi
        this.listFirstLineArrivoInAltoTrasc.push(el);  
      }else if(index > 2 && index <= 5){
        //inserisco i prossimi 3 elementi
        this.listSecondLineArrivoInAltoTrasc.push(el);
      }
    });
  }
  
  showIsInput(element: Trascodifica): boolean {
    const allowedDescriptions = [
      constants.MONTASCALE_STANDAR_SUL_PIANEROTTOLO_MINIMO_200MM,
      constants.MONTASCALE_TIPO_ARRIVO_ALTO_CON_CURVA_DI_PARCHEGGIO_A_ORIZZ
    ];
  
    return allowedDescriptions.includes(element.descrizione);
  }

  showMeasurementInput(element: Trascodifica): string {
    const allowedDescriptions = [
      constants.MONTASCALE_TIPO_ARRIVO_ALTO_CON_CURVA_DI_PARCHEGGIO_A_ORIZZ
    ];
  
    if(allowedDescriptions.includes(element.descrizione)){
      if(element.descrizione === constants.MONTASCALE_STANDAR_SUL_PIANEROTTOLO_MINIMO_200MM){
        return 'mm';
      }else if(element.descrizione === constants.MONTASCALE_TIPO_ARRIVO_ALTO_CON_CURVA_DI_PARCHEGGIO_A_ORIZZ){
        return '°';
      }else {
        return '';
      }
    }
  }

  filtedUnderBoxDescription(element: Trascodifica): string {
    const allowedDescriptions = [
      constants.MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_180
    ];

    if(allowedDescriptions.includes(element.descrizione)){
      if( element.descrizione === constants.MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_180){
          return 'In questo specifico caso gli ingombri sono soggetti a un incremento se la ringhiera o il muretto hanno uno spessore ridotto';
      }else{
          return '';
      }
    }
  }
}
