export class Signature{
	name:string;
	type:string;
	description:string;
	content:string;	
}


export class Procedure {
	name:string;
	description:string;
	members:Member[];
	config:Config;
	"start" : true;
	subscribers: Subscribers[];
}

export class Subscribers{
	email:string;
	group:string;
}

export class Member{
	firstname : string;
    lastname: string;
	email: string;
	phone: string;
	type:string;
	position:number;
	fileObjects:File[];
}


export class File{
	file :string;
	page:number;
	position: string;
	mention: string;
	mention2: string;
}


export class Config{
	email:Email;
	webhook: Webhook;
}

export class Email{
	"procedure.finish" : Started[];	
}


export class Started{
	subject: string;
	message: string;
	to: string[];
	fromName : string;
}

export class Webhook{
	"member.finished" : MemberFinished[];
	"procedure.refused" : MemberFinished[];
}

export class MemberFinished{
	url: string;
	method: string;
	headers:Header;
	
}

export class Header{
	"X-Custom-Header" : string;
}
