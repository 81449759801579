<div class="pt-5 text-center mb-4">
</div>

<div class="text-center mb-4">
    <h4>RICERCA ORDINE</h4>
</div>

<div class="anagraficaContainer mb-4">

    <div class="row">
        <div class="col-6">
            <mat-form-field class="full-width">
                <mat-label>Id ordine</mat-label>
                <input matInput [(ngModel)]="idOrder" placeholder="Id ordine">
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-6">
            <mat-form-field class="full-width">
                <mat-label>Nominativo del cliente</mat-label>
                <input matInput [(ngModel)]="nameClient" placeholder="Nominativo del cliente">
            </mat-form-field>
        </div>
    </div>
    <hr />

    <div>
        <button type="submit" id="save-entity" [disabled]="!(nameClient || idOrder )" color="primary"
            class="btnMedium mr-2" mat-flat-button (click)="search();">
            <span class="material-icons">search</span>
            &nbsp;<span>Cerca ordine</span>
        </button>
    </div>


    <div class="with-border">
        <div class="tableCont">
            <table class="customTable" mat-table [dataSource]="dataSource" matSort matSortActive="dataordine"
                matSortDirection="desc">

                <ng-container matColumnDef="idOrdine">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Id Ordine</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.idordine}}</td>
                </ng-container>

                <ng-container matColumnDef="brandDescrizione">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Brand </th>
                    <td mat-cell *matCellDef="let element"> {{element.brandDescrizione}} </td>
                </ng-container>

                <ng-container matColumnDef="dataordine">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Data </th>
                    <td mat-cell *matCellDef="let element"> {{element.dataordine | date: 'dd/MM/yyyy'}} </td>
                </ng-container>

                <ng-container matColumnDef="statopraticaDescrizione">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Stato pratica </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.statopraticaDescrizione}}</td>
                </ng-container>

                <ng-container matColumnDef="statoAttServDescrizione">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="centered-header"> Ativazione Servizi </th>
                    <td mat-cell *matCellDef="let element" class="centered-cell">
                        {{element.attivazioneServizi?.statorichiestaDescrizione ? element.attivazioneServizi?.statorichiestaDescrizione  : '-'}}</td>
                </ng-container>

                <ng-container matColumnDef="nomeProdotto" sticky>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Prodotto </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.nomeProdotto}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="clienteCf">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nominativo Cliente </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.cognome}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.email}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="tel1">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Telefono </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.tel1}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="stato">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Stato </th>
                    <td mat-cell *matCellDef="let element">
                        <span style="font-size:30px;vertical-align: middle"
                            [ngClass]="{'text-info':element.statopraticaDescrizione === 'ATTIVA', 'text-success':element.statopraticaDescrizione === 'FIRMATA', 'text-warning':element.statopraticaDescrizione === 'in_approvazione', 'text-danger':element.statopraticaDescrizione === 'respinto'}">&bull;{{element.statopraticaDescrizione}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="lastClSearch actionCss"> </th> 
                    <td mat-cell *matCellDef="let element" class="lastClSearch actionCss">
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                              <mat-icon>more_vert</mat-icon>
                            </button>
                          
                            <mat-menu #menu="matMenu">
          
                              <button mat-menu-item 
                                (click)="goToOrder(element.prodottoCommessa, element.clienteId, element.prodottoId, element.id, element.flgManualOrder)"
                                *ngIf="element.statopraticaCodice === 'A'">
                                <span class="material-icons">open_in_new</span> Compila
                              </button>

                              <!--<button mat-menu-item
							    (click)="goToOrder(element.prodottoCommessa, element.clienteId, element.prodottoId, element.id, true)"
                                *ngIf="element.statopraticaCodice === 'A'">
							    <mat-icon>open_in_new</mat-icon> Compilazione cartacea
					  	      </button>-->

                              <button mat-menu-item (click)="active(element.id)"
                                *ngIf="element.statopraticaCodice === 'W' && !element.flgManualOrder">
                                <span class="material-icons">autorenew</span> Riattiva
                              </button>

                              <button mat-menu-item (click)="active(element.id)"
                                *ngIf="element.statopraticaCodice === 'D' && !element.dataftp && !element.flgManualOrder">
                                <span class="material-icons">autorenew</span> Riattiva
                              </button>

                              <button mat-menu-item
                                (click)="formController.openPdf(element.flgV3,element.idfile,element.idProcedure)"
                                *ngIf="element.idfile && element.statopraticaCodice !== 'W' && !element.flgManualOrder">
                                <span class="material-icons">preview</span> Anteprima
                              </button>

                              <button mat-menu-item
                                (click)="uploadDocument(element.id,element.gdlid)"
                                *ngIf="element.statopraticaCodice === 'F' || element.statopraticaCodice === 'I' || element.statopraticaCodice === 'D'">
                                <span class="material-icons">open_in_new</span> Upload file
                              </button>
   
                              <button mat-menu-item
                                (click)="formController.downloadPdf(element.flgV3,element.idordine,element.idfile,element.idProcedure, constants.CONTRATTO_ORDINE, element.id, element.flgManualOrder)"
                                *ngIf="element.idfile && element.statopraticaCodice !== 'W' && !element.flgManualOrder">
                                <span class="material-icons">cloud_download</span> Download
                              </button>

                              <button mat-menu-item
                                (click)="formController.downloadCertificatePdf(element.flgV3, element.idordine,element.idMember,element.idProcedure, constants.CERTIFICATO_FIRMA_ORDINE)"
                                *ngIf="element.idMember && element.statopraticaCodice !== 'W' && !element.flgManualOrder">
                                <span class="material-icons">cloud_download</span> Certificato
                              </button>

                              <button mat-menu-item
                                (click)="formController.downloadPdf(element.flgV3,element.idordine,element.attivazioneServizi.idfile,element.attivazioneServizi.idProcedure, constants.CONTRATTO_ATT_SERV, element.id, element.flgManualOrder)"
                                *ngIf="element.attivazioneServizi?.idfile && element.attivazioneServizi?.statorichiestaCodice !== 'W' && !element.flgManualOrder">
                                <span class="material-icons">cloud_download</span> Attivazione servizi
                              </button>

                              <button mat-menu-item
                                (click)="formController.downloadCertificatePdf(element.flgV3, element.idordine,element.attivazioneServizi?.idMember,element.attivazioneServizi.idProcedure, constants.CERTIFICATO_MODULO_ATT_SERV)"
                                *ngIf="element.attivazioneServizi?.idMember && element.attivazioneServizi?.statorichiestaCodice !== 'W'  && !element.flgManualOrder">
                                <span class="material-icons">cloud_download</span> Certificato attivazione servizi
                              </button>
                            </mat-menu>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
    </div>