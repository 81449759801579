import { environment } from "../../../../../../../environments/environment";
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { PrintserviceService } from 'src/app/services/print/printservice.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { PreviewComponent } from 'src/app/modules/shared/pdf/preview/preview.component';
import { BrandService } from 'src/app/services/brand/brand.service';
import { AnagraficaService } from 'src/app/modules/seller/bagni-italiani/main-bagni/services/anagrafica/anagrafica.service';
import { AbitazioneService } from '../../services/abitazione.service';
import { StufeService } from '../services/stufe.service';
import { OmaggioService } from 'src/app/modules/seller/bagni-italiani/main-bagni/services/omaggio/omaggio.service';
import { HttpService } from 'src/app/services/http.service';
import { PrintComponent } from "src/app/modules/shared/pdf/print/print.component";
import { OmaggioDto } from "src/app/modules/classes/omaggio-dto";
import { DettaglioStufe } from "src/app/modules/classes/dettaglio-stufe";
import { AccessoristufaDto } from "src/app/modules/classes/accessoristufa-dto";
import { AnagraficaDTO } from "src/app/modules/classes/anagrafica";
import { DtoStufe } from "src/app/modules/classes/stufe";
declare var jQuery: any;

@Component({
	selector: 'app-print',
	templateUrl: './print.component.html',
	styleUrls: ['./print.component.scss']
})
export class StufePrintComponent extends PrintComponent implements OnInit {

	@Input() form: any;
	@Input() trascodifiche: Trascodifica[];
	@Input() modale: boolean;
	@Input() page: number;
	@Output("reload") reload: EventEmitter<any> = new EventEmitter<any>();

	idOrdine: number;
	desiredWidth: number;
	proportionalHeight: number;
	timeLeft: number = 60;

	showFrame: boolean = false;
	generatingPdf: boolean = false;
	validAnagrafica: boolean = false;
	openSign: boolean = false;
	sendingPdf: boolean = false;
	showModalPrint: boolean = false;
	loadAll: boolean = false;

	noteStufa: string = "";
	noteInstallazione: string = "";
	urlFrame: string = "";
	privacyText: string = "";
	colorString: string = "#008000";

	anagrafica: AnagraficaDTO;
	accStufa: AccessoristufaDto;

	stufe: DtoStufe[];
	dettaglioStufa: DettaglioStufe;
	omaggio: OmaggioDto[];
	defineColumn: string[] = [];

	member: any;
	abitazione: any;
	error: any;
	disegno: any;

	rgbColorLine: number[] = [0, 128, 0];
	integratedSignature = environment.integratedSignature;
	isApiV3 = environment.isV3;
	constructor(
		private router: Router,
		public dialog: MatDialog,
		private stufeService: StufeService,
		private abitazioneService: AbitazioneService,
		public printService: PrintserviceService,
		public formController: FormControllerService,
		private anagraficaService: AnagraficaService,
		private omaggioService: OmaggioService,
		private orderService: OrdersService,
		private paymentService: PaymentService,
		private brandService: BrandService,
		private httpService: HttpService
	) {
		super(printService);
	}

	ngOnInit(): void {
		this.idOrdine = this.form.idOrder;
		this.color = this.rgbColorLine
		this.getOrder();

		this.defineColumn = [
			"Modello",
			"Vol. Risc. Max.",
			"Quantità",
			"Colore"
		];
	}

	getOrder(): void {
		this.orderService.getSingleOrder(this.idOrdine).subscribe((res) => {
			this.ordine = res;
			this.form.ordine = res;
			this.brandService
				.getDataByProduct(Number(localStorage.getItem("id_product")))
				.subscribe((resp: any) => {
					this.trascodifiche = resp;
					this.getAnagrafica();
					this.getStufeOrder();
					this.getPagamento();
				});
		})
		this.loadAll = true;
	}

	generatePdf(firma: boolean): void {
		let y = this.initPdf(this.ordine, this.anagrafica, "/assets/img/loghi/ARIEL.jpg", "Modulo d'ordine stufe Ariel", this.colorString);

		y = this.printService.returnCurrentY()
		this.printService.writeFooterPage(this.doc, this.ordine, this.currentPdfPage, 6);

		if (this.stufe && this.stufe.length) {
			let tableTitle = 'STUFE A PELLET';

			let stufeFiltrate = this.stufe.filter(item => {
				if (item.modstufepelletDescrizione && item.volriscDescrizione && item.coloreDescrizione && item.quantita) {
					return item.modstufepelletDescrizione && item.volriscDescrizione && item.coloreDescrizione && item.quantita;
				}
			});

			if (stufeFiltrate) {
				this.genericStufaProductTable(this.doc, stufeFiltrate, tableTitle, this.defineColumn, y, this.colorString)
			}


			tableTitle = 'STUFE A BIOETANOLO';

			stufeFiltrate = this.stufe.filter(item => {
				if (item.modstufebioDescrizione && item.volriscDescrizione && item.coloreDescrizione && item.quantita) {
					return item.modstufebioDescrizione && item.volriscDescrizione && item.coloreDescrizione && item.quantita;
				}
			});

			if (stufeFiltrate) {
				let column = [];
				column = [...this.defineColumn];
				column.push("Bollino sconto");
				this.genericStufeBioPrint(this.doc, stufeFiltrate, tableTitle, column, y, this.colorString)
			}

			tableTitle = 'TERMOSTUFE A PELLET';

			stufeFiltrate = this.stufe.filter(item => {
				if (item.modtermopelletDescrizione && item.volriscDescrizione && item.coloreDescrizione && item.quantita) {
					return item.modtermopelletDescrizione && item.volriscDescrizione && item.coloreDescrizione && item.quantita;
				}
			});

			if (stufeFiltrate) {
				this.genericStufaProductTable(this.doc, stufeFiltrate, tableTitle, this.defineColumn, y, this.colorString)
			}

			tableTitle = 'CALDAIE A PELLET';

			stufeFiltrate = this.stufe.filter(item => {
				if (item.modcaldpelletDescrizione && item.volriscDescrizione && item.coloreDescrizione && item.quantita) {
					return item.modcaldpelletDescrizione && item.volriscDescrizione && item.coloreDescrizione && item.quantita;
				}
			});

			if (stufeFiltrate) {
				this.genericStufaProductTable(this.doc, stufeFiltrate, tableTitle, this.defineColumn, y, this.colorString)
			}

			tableTitle = 'STUFE A LEGNA';

			stufeFiltrate = this.stufe.filter(item => {
				if (item.modstufelegnaDescrizione && item.volriscDescrizione && item.coloreDescrizione && item.quantita) {
					return item.modstufelegnaDescrizione && item.volriscDescrizione && item.coloreDescrizione && item.quantita;
				}
			});

			if (stufeFiltrate) {
				this.genericStufaProductTable(this.doc, stufeFiltrate, tableTitle, this.defineColumn, y, this.colorString)
			}
		}

		if (this.dettaglioStufa && this.dettaglioStufa?.manteneregen) {
			const title = "MANTENERE GENERATORE DI CALORE ESISTENTE PER ACQUA CALDA SANITARIA";
			this.createTableTextNoheader(title, this.dettaglioStufa?.manteneregen, this.printService.returnCurrentY(), this.doc, this.rgbColorLine);
		}

		if (this.noteStufa && this.noteStufa !== '') {
			const title = "NOTE";
			this.createTableTextNoheader(title, this.noteStufa, this.printService.returnCurrentY(), this.doc, this.rgbColorLine);
		}

		if (this.disegno) {
			const tableTitle = 'DISEGNO TECNICO';
			this.genericPrintImages(this.doc, this.printService.returnCurrentY(), this.disegno, null, tableTitle, null, this.desiredWidth, this.proportionalHeight, this.colorString, this.color);
		}

		if (this.abitazione) {
			const tableTitle = 'DATI ABITAZIONE';
			this.genericStufaDatiAbitazioneTable(this.doc, this.abitazione, this.printService.returnCurrentY(), tableTitle, this.colorString)
		}

		if (this.accStufa) {
			const tableTitle = 'DETTAGLI INSTALLAZIONE';
			this.genericStufaDettagliInstallazioneTable(this.doc, this.accStufa, this.printService.returnCurrentY(), tableTitle, this.colorString);
		}

		if (this.accStufa && this.accStufa.note && this.accStufa.note !== '') {
			const title = "NOTE";
			this.createTableTextNoheader(title, this.accStufa.note, this.printService.returnCurrentY(), this.doc, this.rgbColorLine);
		}

		if (this.accStufa) {
			const tableTitle = 'ACCESSORI';
			this.genericStufaAccessoriTable(this.doc, this.accStufa, this.printService.returnCurrentY(), tableTitle, this.colorString);
		}

		if (this.omaggio?.length > 0) {
			this.genericOmaggio(this.doc, this.omaggio, this.printService.returnCurrentY(), this.colorString);
		}

		if (this.pagamento) {
			this.createTablePagamento(this.doc, this.pagamento, this.printService.returnCurrentY(), 0, this.colorString);
		}

		this.printPrivacyInformation(y);

		this.creatingPdf = false;
		if (firma) {
			if (!this.integratedSignature) {
				this.createPdf(this.doc);
			} else {
				this.toggleSignPdf(this.doc);
			}
		} else {
			const pdfBlob = this.doc.output("blob");
			// Crea un URL dall'oggetto Blob
			const pdfUrl = URL.createObjectURL(pdfBlob);
			this.openDialog(pdfUrl);
		}
	}

	// Print method	

	private printPrivacyInformation(y: number): number {
		this.printService.addNewPage(this.doc, true, this.img, this.ordine, this.currentPdfPage, this.title)
		y = this.printService.returnCurrentY();
		this.printService.writePrivacyOne(this.doc, y, this.ordine, this.img, this.title, this.currentPdfPage, this.privacyText);
		return y;
	}

	// Http
	private getAnagrafica(): void {
		this.anagraficaService
			.findById(this.ordine.clienteId)
			.subscribe((res) => {
				this.anagrafica = res;
				this.checkAnagrafica();
			});
		this.getAbitazione();
	}

	private getAbitazione() {
		this.abitazioneService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe((res) => {
			this.abitazione = res[0];
			this.getDisegno(res[0]);
		});
	}

	private getStufeOrder() {
		this.stufeService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe((res) => {
			this.stufe = res;
			this.getAccStufa();
			this.getDettaglioStufaAndNote();
			this.getOmaggio();
		})
	}

	private getAccStufa(): void {
		this.stufeService.getAccStufa(this.idOrdine, 'ordineId.equals=').subscribe(res => {
			this.accStufa = res[0];
			this.noteInstallazione = res[0]?.note;
		});
	}

	private getDettaglioStufaAndNote(): void {
		this.stufeService.getDettaglio(this.idOrdine, 'ordineId.equals=').subscribe(res => {
			this.dettaglioStufa = res[0];
			this.noteStufa = res[0]?.note;
		});
	}

	private getOmaggio(): void {
		this.omaggioService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe(res => {
			this.omaggio = res;
		})
	}

	private getPagamento(): void {
		this.paymentService.findByKey(this.idOrdine, 'ordineId.equals=').subscribe((res) => {
			this.pagamento = res;
		});
		this.getPrivacy();
	}

	private getPrivacy(): void {
		this.httpService.getPrivacy('assets/json/stufe.json').subscribe((res) => {
			this.privacyText = res;
		});
	}

	// Others method
	private openDialog(pdf: any): void {
		this.dialog.open(PreviewComponent, {
			width: "800px",
			maxWidth: "100%",
			data: {
				pdf,
			},
		});
	}

	private checkAnagrafica(): boolean {
		this.validAnagrafica = this.formController.checkAnagrafica(this.form);
		return this.validAnagrafica;
	}

	private getDisegno(obj): void {
		this.disegno = "data:" + obj.disegnotecnicoContentType + ";base64," + obj.disegnotecnico;

		const img = new Image();
		img.src = this.disegno;
		img.onload = () => {
			const originalWidth = img.width;
			const originalHeight = img.height;

			this.desiredWidth = 70; // Larghezza desiderata in unità del PDF
			this.proportionalHeight =
				(this.desiredWidth * originalHeight) / originalWidth;
		};
	}

	private createPdf(pdf): void {
		this.sendingPdf = true;
		this.openSign = true;
		const title = localStorage.getItem("id_order");
		pdf.setProperties({
			title,
		});

		const newPdf = btoa(pdf.output());
		const pages = this.printService.returnCurrentPage();
		const coordinates = this.printService.returnCoordinates();
		this.formController
			.sendSignature(
				newPdf,
				this.idOrdine,
				this.form,
				pages,
				coordinates,
				title,
				false
			)
			.then(
				(res) => {
					this.generatingPdf = false;
					this.sendingPdf = false;
					this.router.navigate(["order-list"]);
				},
				(error) => {
					this.generatingPdf = false;
					this.sendingPdf = false;
					this.ordine.statopraticaCodice = "A";
					this.error = error;
					jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
					jQuery("#errorModal").modal("toggle");
				}
			);
	}

	returnIdOrdine(): string {
		return localStorage.getItem("id_order");
	}

	togglePrintPreview(): void {
		this.generatePdf(false);
	}

	toggleSignPdf(pdf): void {
		const interval = this.startTimer();
		const title = localStorage.getItem("id_order");
		const pages = this.printService.returnCurrentPage();
		this.showModalPrint = !this.showModalPrint;
		this.openSign = true;
		this.showFrame = false;
		this.generatingPdf = true;
		this.urlFrame = "";
		jQuery("#signModal").modal({ backdrop: "static", keyboard: false });
		jQuery("#signModal").modal("toggle");

		if (this.isApiV3) {
			const newPdf = new Blob([pdf.output('blob')], { type: "application/pdf" });
			var split = this.printService.returnCoordinates().split(",");
			const coordinates = {
				width: 120,
				height: 37,
				x: split[0],
				y: split[3],
			}
			this.formController.manageSignature(newPdf, this.form.idOrder, this.form, pages, coordinates, title, environment.signGenericId).then((res: any) => {
				this.urlFrame = res.signers[0].signature_link + '&disable_domain_validation=true';
				setTimeout(() => {
					this.showFrame = true;
					this.sendingPdf = false;
					this.generatingPdf = false;

				}, 2000);
				clearInterval(interval);
			}, error => {
				this.generatingPdf = false;
				this.sendingPdf = false;
				this.ordine.statopraticaCodice = "A";
				clearInterval(interval);
				this.closeSignPdf();
				jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
				jQuery("#errorModal").modal("toggle");
			});
		} else {
			const newPdf = btoa(pdf.output());
			const coordinates = this.printService.returnCoordinates();
			this.formController
				.sendSignature(
					newPdf,
					this.form.idOrder,
					this.form,
					pages,
					coordinates,
					title,
					true
				)
				.then(
					(res) => {
						this.member = res.members[0].id;
						this.urlFrame =
							environment.apiUrl +
							"/procedure/sign?members=" +
							this.member +
							"&signatureUi=" +
							environment.signAriel; // serve per recuperare il modello del prodotto corretto. In modo che il frame vega visualizzato con i colori corretti.
						this.showFrame = true;
						this.sendingPdf = false;
						this.generatingPdf = false;

						clearInterval(interval);
						// this.router.navigate(['order-list']);
					},
					(error) => {
						this.generatingPdf = false;
						this.sendingPdf = false;
						this.ordine.statopraticaCodice = "A";
						clearInterval(interval);
						jQuery("#errorModal").modal({ backdrop: "static", keyboard: false });
						jQuery("#errorModal").modal("toggle");
					}
				);
		}

	}

	closeSignPdf(): void {
		jQuery("#signModal").modal({ backdrop: "static", keyboard: false });
		jQuery("#signModal").modal("toggle");
		this.reload.emit(this.openSign);
		this.setStateToActive();
		this.formController.removeFrame();
	}

	private setStateToActive() {

		this.orderService.getSingleOrder(this.idOrdine).subscribe((res) => {
			this.ordine = res;
			this.ordine.statopraticaCodice = "A";
			this.orderService.update(JSON.stringify(this.ordine)).subscribe(res => {
				this.getOrder();
			});
		})
	}

	startTimer(): any {
		const intervalId = setInterval(() => {
			if (this.timeLeft === 0) {
				clearInterval(intervalId);
				// richiamo api per invio email
				this.orderService.sendEmail(this.form.idOrder).subscribe();
				jQuery("#timeoutModal").modal({ backdrop: "static", keyboard: false });
				jQuery("#timeoutModal").modal("toggle");

				setTimeout(() => {
					jQuery("#timeoutModal").modal("hide");
				}, 10000);

				setTimeout(() => {
					this.formController.goToDashboard();
				}, 11000);
			}
			if (this.timeLeft > 0) {
				this.timeLeft--;
			} /* else {
		  this.timeLeft = 10;
		}*/
		}, 1000);
		return intervalId;
	}
}